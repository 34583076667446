import classNames from 'classnames';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components.
import Button from 'components/Button/Button';
import Faq from 'components/Faq/Faq';
import { HelpDeskForm } from './HelpDeskForm';

// Core.
import { TAXONOMY_FAQ_ROOT_ID } from 'core/constants';

// Store.
import { closeRail, openRail } from 'store/rail/actions';
import { isRailOpenSelector } from 'store/rail/selectors';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { faqTermsSelector } from 'store/taxonomy/selectors';
import { getSite } from 'utils/site';
import { rollupOrganizationEnumSelector } from 'store/user/selectors';
import { RollupOrganizationEnums } from 'core/models';

export const HelpDeskPage = (): ReactElement => {
  const dispatch = useDispatch();
  const faq = useSelector(faqTermsSelector);
  const isOpen: boolean = useSelector(isRailOpenSelector);

  // fall back to getSite() here since this screen needs to be workable even if your login is messed up
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector) || getSite();
  const isNars = rollupOrganizationEnum === RollupOrganizationEnums.NARS;

  useEffect(() => {
    dispatch(getTaxonomyTermById(TAXONOMY_FAQ_ROOT_ID));
  }, [dispatch]);

  return (
    <>
      <section className="form-container">
        <div className="form-title-container">
          <div className="title">
            <div className="eyebrow">Contact Support</div>
            <h4>
              Let Us Know Your Questions or Comments.
              <Button className="btn-faq" onClick={() => dispatch(openRail())}>
                FAQ
              </Button>
            </h4>
          </div>
        </div>
        <HelpDeskForm />
      </section>
      {!isNars && (
        <aside className={classNames('rail-container', 'rail-container--75', { open: isOpen })}>
          <Faq
            faqs={faq}
            faqArea="HelpDesk"
            faqTitle="Helpful Links"
            handleClose={() => dispatch(closeRail())}
            isLinks
            rollupOrganizationEnum={rollupOrganizationEnum}
          />
        </aside>
      )}
    </>
  );
};
