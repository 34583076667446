import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { uniqueId, isNumber } from 'lodash';

// Components
import CustomTooltip from 'components/Tooltip/Tooltip';

// Core + Store
import {
  CollaborationTypes,
  IBoardCredits,
  ILearnerSearchActivity,
  ITaxonomyTerm,
  IUserOrganization,
} from 'core/models';
import { organizationKindSelector } from 'store/user/selectors';
import { amaCreditTermIdSelector } from 'store/taxonomy/selectors';

// Styles
import { GenericLearnerDetails } from '../GenericLearnerDetails';

interface IProps {
  learnerDetails: ILearnerSearchActivity[];
  refreshLearnerData: () => Promise<void>;
}

export const LearnerDetail = (props: IProps): ReactElement => {
  const { learnerDetails, refreshLearnerData } = props;

  // Selectors
  const org: IUserOrganization = useSelector(organizationKindSelector);
  const amaCreditTerm: ITaxonomyTerm = useSelector(amaCreditTermIdSelector);

  if (!learnerDetails?.length || !org) {
    return null;
  }
  const singleBoardDetailRecord = learnerDetails[0];
  const collaborationType = singleBoardDetailRecord?.collaborationType;
  const isStateBoard = collaborationType === CollaborationTypes.CREDITS_BY_STATE_OR_PROVINCE;
  const boardName = isStateBoard ? 'CME Credits' : singleBoardDetailRecord?.boardName;
  const board = singleBoardDetailRecord?.board;
  const learnerId = singleBoardDetailRecord?.learnerId;
  const boardHeading = isStateBoard ? boardName : `${boardName} (${board})`;

  return (
    <GenericLearnerDetails
      learnerDetails={learnerDetails}
      heading={boardHeading}
      learnerId={learnerId}
      refreshLearnerData={refreshLearnerData}
      buildCompletionDetails={(completion) => {
        const { credits: creditsList, totalCredits } = completion;
        const totalCreditsVal: number = isStateBoard
          ? creditsList?.find(({ creditTypeId }) => creditTypeId === amaCreditTerm?.id)?.credits ||
            creditsList?.[0]?.credits
          : totalCredits;
        const hasTotalCredits = !!totalCreditsVal;
        return (
          <>
            <span>Total: {hasTotalCredits ? totalCreditsVal : 0}</span>
            {!isStateBoard &&
              creditsList?.map(
                (creditDetail: IBoardCredits): ReactElement => {
                  const { abbreviation, credits: creditsEarned, name } = creditDetail;
                  const creditsKey = uniqueId();
                  return (
                    isNumber(creditsEarned) && (
                      <span key={creditsKey}>
                        <CustomTooltip tooltipText={name}>
                          {abbreviation}: {creditsEarned}
                        </CustomTooltip>
                      </span>
                    )
                  );
                },
              )}
          </>
        );
      }}
      buildCompletionLabel={(completion: ILearnerSearchActivity) => completion?.board ?? completion?.rems ?? 'CME'}
    />
  );
};
