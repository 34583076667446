import { ReactElement, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SaveAltRounded } from '@material-ui/icons';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { Dictionary } from 'ts-essentials';
import noop from 'lodash/noop';
import sortBy from 'lodash/sortBy';

// Components
import { LoadingCards } from 'components/LoadingCard';
import { ManualDownloadProviderFileLink } from 'components/ContinuousImprovement/ManualDownladProviderFileLink';

// Styles
import './uploadhistory.module.scss';

// Store + Core
import { getActivityProviderFilesById, getLearnerProviderFilesById } from 'store/providerFiles/actions';
import {
  activityProviderFilesSelector,
  learnerProviderFilesSelector,
  providerFilesLoadingSelector,
} from 'store/providerFiles/selectors';
import { IProviderFile, IProviderFileChild } from 'core/models';
import { BatchProcessRefreshTime } from 'core/enums';
import { BatchFileProgress, BatchTaxonomyLoader } from '../BatchFileProgress';

interface IProps {
  fileType: 'activity' | 'learners';
}

export const UploadHistory = (props: IProps): ReactElement => {
  const { fileType } = props;
  const dispatch = useDispatch();
  const interval = useRef(null);

  // Redux state.
  const activityFiles: IProviderFile[] = useSelector(activityProviderFilesSelector);
  const learnerFiles: IProviderFile[] = useSelector(learnerProviderFilesSelector);
  const isLoading: boolean = useSelector(providerFilesLoadingSelector);

  const files = fileType === 'activity' ? activityFiles : fileType === 'learners' ? learnerFiles : null;

  // API call.
  const GetHistoryData = () => {
    const getAndSetActivityFiles = async (): Promise<void> => {
      await dispatch(getActivityProviderFilesById());
    };
    const getAndSetLearnerFiles = async (): Promise<void> => {
      await dispatch(getLearnerProviderFilesById());
    };
    if (fileType === 'activity') {
      getAndSetActivityFiles().then(noop);
    }
    if (fileType === 'learners') {
      getAndSetLearnerFiles().then(noop);
    }
  };

  useEffect(() => {
    return () => {
      if (interval.current) {
        clearTimeout(interval.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!interval.current) {
      GetHistoryData();
      interval.current = setInterval(() => {
        GetHistoryData();
      }, BatchProcessRefreshTime.timer);
    } else {
      if (files && files.length && files.filter((file) => file.children.length > 0).length === files.length) {
        clearInterval(interval.current);
      }
    }
  }, [dispatch, files]);
  // Render something while _initially_ loading
  if (isLoading && !files?.length) return <LoadingCards />;

  // Null check.
  if (!files?.length) return null;

  // Strip the time off of each date entry for grouping.
  files.forEach((file: IProviderFile): string => (file.date = moment(file.updatedDate).format('MM/DD/YYYY')));

  // Group the result by month/date/year.
  const filesByDate: Dictionary<IProviderFile[]> = groupBy(files, 'date');

  // Sort them by most recent at the top.
  const orderedDates = {};
  Object.keys(filesByDate)
    .sort(function (a: string, b: string) {
      return new Date(a).valueOf() - new Date(b).valueOf();
    })
    .forEach((key: string): void => {
      orderedDates[key] = filesByDate[key];
    });

  const dateKeys: string[] = Object.keys(orderedDates).reverse();

  return (
    <>
      <BatchTaxonomyLoader />
      {dateKeys.map(
        (date: string): ReactElement => {
          const formattedDate: string = moment(date).format('MMM D, YYYY');

          return (
            <div className="history-upload" key={formattedDate}>
              <h5>{formattedDate}</h5>
              <ul className="uploaded-file-list">
                {/* Sort again by most recent at the top. */}
                {sortBy(filesByDate[date], ({ updatedDate }) => updatedDate)
                  .reverse()
                  .map(
                    (providerFile: IProviderFile): ReactElement => {
                      const {
                        children,
                        fileName,
                        id,
                        recordsAdded,
                        recordsDeleted,
                        recordsRejected,
                        recordsUpdated,
                      } = providerFile;

                      const areRecordsProcessed =
                        !!recordsAdded || !!recordsDeleted || !!recordsRejected || !!recordsUpdated;

                      return (
                        <li className="uploaded-file" key={id}>
                          <ManualDownloadProviderFileLink fileName={fileName} id={id}>
                            <div className="uploaded-file-name">
                              <SaveAltRounded />
                              {fileName}
                            </div>
                          </ManualDownloadProviderFileLink>
                          <>
                            {children.length ? (
                              <div className="uploaded-file-meta">
                                {!!recordsAdded && (
                                  <div className="tag tag--added">
                                    <div className="label-text">{recordsAdded} Added</div>
                                  </div>
                                )}
                                {!!recordsUpdated && (
                                  <div className="tag tag--updated">
                                    <div className="label-text">{recordsUpdated} Updated</div>
                                  </div>
                                )}
                                {!!recordsDeleted && (
                                  <div className="tag tag--deleted">
                                    <div className="label-text">{recordsDeleted} Deleted</div>
                                  </div>
                                )}
                                {(!areRecordsProcessed || !!recordsRejected) && (
                                  <div className="tag tag--rejected">
                                    <div className="label-text">{recordsRejected} Rejected</div>
                                  </div>
                                )}
                                <div>
                                  {sortBy(children, ({ updatedDate }) => updatedDate)
                                    .reverse()
                                    .map(
                                      (file: IProviderFileChild): ReactElement => {
                                        const { bulkUploadResult, createdDate, id, fileName } = file;
                                        const formattedDateConcat: string = moment(createdDate).format(
                                          'YYYY_MM_DD_H_mm_s',
                                        );
                                        const displayValue = `${bulkUploadResult}_${formattedDateConcat}`;

                                        return (
                                          <div className="uploaded-file-download" key={id}>
                                            <ManualDownloadProviderFileLink fileName={fileName} id={id}>
                                              {displayValue}
                                            </ManualDownloadProviderFileLink>
                                          </div>
                                        );
                                      },
                                    )}
                                </div>
                              </div>
                            ) : (
                              <div className="uploaded-file-status">
                                <BatchFileProgress file={providerFile} />
                              </div>
                            )}
                          </>
                        </li>
                      );
                    },
                  )}
              </ul>
            </div>
          );
        },
      )}
    </>
  );
};
