import { ThunkAction } from 'redux-thunk';

// Core.
import { PARSAction } from 'core/models';

// Services.
import { HelpDeskService } from 'services/HelpDeskService';

// Store.
import { AppState } from 'store';
import { popToast } from 'store/toast/actions';
import { errorToastOptions, successToastOptions } from 'store/toast/constants';

// Types.
import {
  HelpDeskAddAttachment,
  HelpDeskRemoveAttachment,
  HelpDeskResetAttachment,
  HELP_DESK_ADD_ATTACHMENT,
  HELP_DESK_REMOVE_ATTACHMENT,
  HELP_DESK_RESET_ATTACHMENT,
  IHelpDeskTicketRequest,
} from './types';
import { handleServerError } from '../../globals/utils/handleServerError';

export const helpDeskAddAttachmentsAction = (files: File[], attachmentBlobIds: string[]): HelpDeskAddAttachment => ({
  payload: files.reduce((obj, file, index) => {
    obj[file.name] = attachmentBlobIds[index];
    return obj;
  }, {}),
  type: HELP_DESK_ADD_ATTACHMENT,
});

export const helpDeskRemoveAttachmentAction = (file: File): HelpDeskRemoveAttachment => ({
  payload: file.name,
  type: HELP_DESK_REMOVE_ATTACHMENT,
});

export const helpDeskResetAttachmentAction = (): HelpDeskResetAttachment => ({
  type: HELP_DESK_RESET_ATTACHMENT,
});

export const submitTicket = (
  ticket: IHelpDeskTicketRequest,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch): Promise<void> => {
  try {
    await HelpDeskService.submitTicket(ticket);
    dispatch(popToast({ ...successToastOptions, message: <>Message sent successfully.</> }));
  } catch (error) {
    handleServerError({ error, thunkName: 'submitTicket' });
    dispatch(popToast({ ...errorToastOptions, message: <>Total attachment file size must be under 15MB.</> }));

    // Rethrows error so component can react.
    throw error;
  }
};

export const uploadAttachments = (
  files: File[] = [],
): ThunkAction<Promise<[File[], File[]]>, AppState, null, PARSAction> => async (
  dispatch,
): Promise<[File[], File[]]> => {
  const attachmentBlobIds = [];
  const failedFiles = [];
  const uploadedFiles = [];

  // Attempt to upload each file.
  for (const file of files) {
    try {
      const attachmentBlobId = await HelpDeskService.uploadAttachment(file);
      attachmentBlobIds.push(attachmentBlobId);
      uploadedFiles.push(file);
    } catch (error) {
      handleServerError({ error, thunkName: 'uploadAttachments' });
      failedFiles.push(file);
    }
  }

  // Add uploaded files to state.
  dispatch(helpDeskAddAttachmentsAction(uploadedFiles, attachmentBlobIds));

  // Show a success toast for uploaded files.
  if (uploadedFiles.length > 0) {
    dispatch(
      popToast({
        ...successToastOptions,
        message: (
          <>
            {uploadedFiles.length} file{uploadedFiles.length > 1 ? 's' : ''} attached.
          </>
        ),
      }),
    );
  }

  // Show an error toast for failed files.
  if (failedFiles.length > 0) {
    dispatch(
      popToast({
        ...errorToastOptions,
        message: (
          <>
            {failedFiles.length} file{failedFiles.length > 1 ? 's' : ''} failed to attach.
          </>
        ),
      }),
    );
  }

  return [uploadedFiles, failedFiles];
};

export const removeAttachment = (file: File): ThunkAction<void, AppState, null, PARSAction> => (dispatch): void => {
  dispatch(helpDeskRemoveAttachmentAction(file));
};

export const resetAttachments = (): ThunkAction<void, AppState, null, PARSAction> => (dispatch): void => {
  dispatch(helpDeskResetAttachmentAction());
};
