import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { KeyboardBackspaceRounded } from '@material-ui/icons';

export interface IPageNav {
  path: string;
  label: string;
}

export const PageNavigation = (props: IPageNav): ReactElement => {
  const { path, label } = props;
  return (
    <div className="page-navigation">
      <Link className="tertiary" to={path}>
        <KeyboardBackspaceRounded className="tertiary-icon-back" />
        {label}
      </Link>
    </div>
  );
};
