import { BaseApiService, IJsonPatchDocumentOperation } from './baseApiService';
import { IContentOutline } from './models';

interface IDictionary<TValue> {
  [key: string]: TValue;
}

export interface ICommercialSupportSource {
  source: string;
  amountGiven?: number;
  hasInKindSupport?: boolean;
}

export interface IBoardMocDetails {
  practiceIds?: string[];
  typesOfCreditIds?: string[];
  mocPointsGiven?: number;
  contentOutlines?: IContentOutline[];
}

export enum LearnerLocationType {
  None = 0,
  InPerson = 1,
  Online = 2,
  Other = 4,
}

export interface IBaseLearner {
  title: string;
  typeId: string;
  locationType?: LearnerLocationType;
  startDate: Date;
  endDate: Date;
  country?: string;
  city?: string;
  stateOrProvince?: string;
  postalCode?: string;
  credits?: IDictionary<number>;
  includeInCmeFinder?: boolean;
  description?: boolean;
  isRestrictedAudience?: boolean;
  participationFeeTypeId?: string;
  detailsUrl?: string;
  isMoc?: boolean;
  isMips?: boolean;
  isRems?: boolean;
  supportedRemsIds?: string[];
  internalId?: string;
  isJointlyProvided?: boolean;
  jointProviderIds?: string[];
  outcomesMeasured?: string[];
  hasCommercialSupport?: boolean;
  commercialSupportSources?: ICommercialSupportSource[];
  markedForCommendationCriteria?: boolean;
  commendationCriteriaIds?: string[];
  boardMocDetails?: IDictionary<IBoardMocDetails>;
  mocCreditDeadline?: Date;
  mocProgramAttestation?: boolean;
  remsRpcId?: string;
  isRemsCompliant?: boolean;
  learnerCounts?: IDictionary<number>;
}

export interface ILearner extends IBaseLearner {
  id: string;
  printableId: string;
}

export type ICreateLearnerRequest = IBaseLearner;

export interface IUpdateLearnerRequest extends IBaseLearner {
  id: string;
}

export class LearnerApiService extends BaseApiService {
  public async createLearnerAsync(createLearnerRequests: ICreateLearnerRequest[]): Promise<ILearner[]> {
    const createdLearner: ILearner[] = await super.postAsync('/api/learner', createLearnerRequests);

    createdLearner.forEach(this.formatLearnerFromServer);

    return createdLearner;
  }

  public async patchLearnerAsync(id: string, patchRequest: IJsonPatchDocumentOperation[]): Promise<void> {
    await super.patchAsync(`/api/learner/${id}`, patchRequest);
  }

  public async updateLearnerAsync(updateLearnerRequest: IUpdateLearnerRequest): Promise<void> {
    await super.putAsync(`/api/learner/${updateLearnerRequest.id}`, updateLearnerRequest);
  }

  public async patchCompletionAsync(route: string, patchRequest: IJsonPatchDocumentOperation[]): Promise<void> {
    await super.patchAsync(route, patchRequest);
  }

  private formatLearnerFromServer(learner: ILearner): void {
    const currentLocationType: LearnerLocationType | string | undefined = learner.locationType;
    const currentStartDate: Date | string | undefined = learner.startDate;
    const currentEndDate: Date | string | undefined = learner.endDate;

    if (currentLocationType === null || currentLocationType === undefined) {
      learner.locationType = LearnerLocationType.None;
    } else if (typeof currentLocationType === 'string') {
      const fullLocationTypeString: string = currentLocationType;
      const locationTypeStrings: string[] = fullLocationTypeString.split(', ');
      let finalLocationType: LearnerLocationType = LearnerLocationType.None;

      locationTypeStrings.forEach(
        (locationTypeString) => (finalLocationType += LearnerLocationType[locationTypeString]),
      );
      learner.locationType = finalLocationType;
    }

    if (currentStartDate === null || currentStartDate === undefined) {
      learner.startDate = null as any;
    } else if (typeof currentStartDate === 'string') {
      learner.startDate = new Date(currentStartDate);
    }

    if (currentEndDate === null || currentEndDate === undefined) {
      learner.endDate = null as any;
    } else if (typeof currentEndDate === 'string') {
      learner.endDate = new Date(currentEndDate);
    }
  }
}
