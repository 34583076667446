import { Reducer } from 'redux';
import { MODAL_CLOSE, MODAL_OPEN, ModalActionTypes, IModalState } from './types';

const initialState: IModalState = {
  isModalOpen: false,
};

const modalReducer: Reducer<IModalState, ModalActionTypes> = (
  state = initialState,
  action: ModalActionTypes,
): IModalState => {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        isModalOpen: true,
      };
    case MODAL_CLOSE:
      return {
        ...state,
        isModalOpen: false,
      };
    default:
      return state;
  }
};

export default modalReducer;
