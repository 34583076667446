import { ReactElement, useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import DescriptionIcon from '@material-ui/icons/Description';
import { WarningRounded } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';
import { FieldProps, useField } from 'formik';
import noop from 'lodash/noop';

// Components
import { UploadProgress } from './UploadProgress';
import { DropzoneTarget } from './DropzoneTarget';

// Styles
import './file-uploader.module.scss';

// Store + Core
import { FileUpload } from 'core/fileUploads';
import { DEFAULT_UPLOAD_TYPES } from 'core/constants';
import { isActivityCreatingSelector } from 'store/activity/selectors';
import { isLearnerCreatingSelector } from 'store/learner/selectors';

interface IFileUploaderProps extends FieldProps {
  fileTypes?: FileUpload[];
  name: string;
  onClearFile(): void;
  postError?: string;
  showDropZoneSubTitle: boolean;
  required?: boolean;
  uploadedFile?: any;
  uploadPercentage: number;
}

export const FileUploader = (props: IFileUploaderProps): ReactElement => {
  const [uploadError, setUploadError] = useState<string>(null);

  // Selectors
  const isActivityCreating: boolean = useSelector(isActivityCreatingSelector);
  const isLearnerCreating: boolean = useSelector(isLearnerCreatingSelector);

  const isCreating: boolean = isActivityCreating || isLearnerCreating;

  const {
    fileTypes = DEFAULT_UPLOAD_TYPES,
    form,
    name,
    showDropZoneSubTitle,
    onClearFile,
    postError,
    uploadedFile,
    uploadPercentage,
  } = props;
  const field = useField<any>(name);

  const handleChange = ({ acceptedFiles, fileRejections }): void => {
    // Always reset error state on drop.
    setUploadError(null);

    if (fileRejections.length >= 1) {
      const { message } = fileRejections[0].errors[0];
      // Set upload error.
      setUploadError(message);
    } else if (acceptedFiles.length >= 1) {
      // Reset upload error.
      setUploadError(null);

      // Files array only gets populated if it is listed in the `accept` array.
      form.setFieldValue('file', acceptedFiles, true);
      form.submitForm().then(noop);
    }
  };

  const isSuccessfullyUploaded = uploadPercentage === 100;
  const uploadMessage: 'Complete: ' | 'Uploading: ' = isSuccessfullyUploaded ? 'Complete: ' : 'Uploading: ';

  return (
    <>
      <Dropzone
        {...field}
        accept={fileTypes}
        multiple={false}
        onDrop={(acceptedFiles: File[], fileRejections: FileRejection[]) =>
          handleChange({ acceptedFiles, fileRejections })
        }
      >
        {({ getRootProps, getInputProps, acceptedFiles }) => (
          <div className="file-uploader">
            {uploadedFile && (
              <div className="file-uploader-file">
                <DescriptionIcon />
                <span className="filename">{uploadedFile[0].path}</span>
                <IconButton aria-label="clear" color="inherit" size="small" className="clear" onClick={onClearFile}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </div>
            )}
            {postError ||
              (uploadError && (
                <div className="error">
                  <div className="message">
                    <WarningRounded />
                    {postError || uploadError}
                  </div>
                </div>
              ))}
            {!!uploadPercentage && isCreating ? (
              <section className={classNames('progress-container', { active: !!acceptedFiles.length })}>
                <UploadProgress uploadMessage={uploadMessage} uploadPercentage={uploadPercentage} />
              </section>
            ) : (
              <section className={classNames('file-uploader-input', { active: !acceptedFiles.length })}>
                <DropzoneTarget
                  rootProps={{ ...getRootProps() }}
                  showSubTitle={showDropZoneSubTitle}
                  inputProps={{ ...getInputProps() }}
                />
              </section>
            )}
          </div>
        )}
      </Dropzone>
    </>
  );
};
