import { ContentTag, PARSAction } from 'core/models';

export interface ContentTagsState {
  tooltip: string;
  contentTagName: string;
  parentContentTagId: string;
  childContentTags: ContentTag[];
  parentContentTag: ContentTag;
}

export const CLEAR_CONTENT_TAGS = 'CLEAR_CONTENT_TAGS';

export const GET_CONTENT_TAGS = 'GET_CONTENT_TAGS';
export const GET_CONTENT_TAGS_SUCCESS = 'GET_CONTENT_TAGS_SUCCESS';
export const GET_CONTENT_TAGS_FAILURE = 'GET_CONTENT_TAGS_FAILURE';
export const GET_CONTENT_TAGS_BY_ID = 'GET_CONTENT_TAGS_BY_ID';
export const GET_CONTENT_TAGS_BY_ID_SUCCESS = 'GET_CONTENT_TAGS_BY_ID_SUCCESS';
export const GET_CONTENT_TAGS_BY_ID_FAILURE = 'GET_CONTENT_TAGS_BY_ID_FAILURE';

interface ClearContentTags extends PARSAction<undefined, typeof CLEAR_CONTENT_TAGS> {
  type: typeof CLEAR_CONTENT_TAGS;
}

interface GetContentTags extends PARSAction<undefined, typeof GET_CONTENT_TAGS> {
  type: typeof GET_CONTENT_TAGS;
}

interface GetContentTagsSuccess extends PARSAction<ContentTag[], typeof GET_CONTENT_TAGS_SUCCESS> {
  type: typeof GET_CONTENT_TAGS_SUCCESS;
}

interface GetContentTagsFailure extends PARSAction<Error, typeof GET_CONTENT_TAGS_FAILURE> {
  type: typeof GET_CONTENT_TAGS_FAILURE;
}

interface GetContentTagsById extends PARSAction<undefined, typeof GET_CONTENT_TAGS_BY_ID> {
  type: typeof GET_CONTENT_TAGS_BY_ID;
}

interface GetContentTagsByIdSuccess extends PARSAction<ContentTag, typeof GET_CONTENT_TAGS_BY_ID_SUCCESS> {
  type: typeof GET_CONTENT_TAGS_BY_ID_SUCCESS;
}

interface GetContentTagsByIdFailure extends PARSAction<Error, typeof GET_CONTENT_TAGS_BY_ID_FAILURE> {
  type: typeof GET_CONTENT_TAGS_BY_ID_FAILURE;
}

export type ContentTagsActionTypes =
  | ClearContentTags
  | GetContentTags
  | GetContentTagsSuccess
  | GetContentTagsFailure
  | GetContentTagsById
  | GetContentTagsByIdSuccess
  | GetContentTagsByIdFailure;
