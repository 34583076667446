import { FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import Button from 'components/Button/Button';
import styles from '../AdminPopupStyle/AdminPopupStyle.module.scss';
import { ButtonVariant } from 'core/enums';
import { createUser } from '../../../../store/user/actions';
import { Grid } from '@material-ui/core';

interface ICreateWebServiceAccountForm {
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  formDetails: any;
}

export const CreateWebServiceAccountForm = (props: ICreateWebServiceAccountForm) => {
  const { formDetails, onClose, onSuccess } = props;
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (): Promise<void> => {
      const result = dispatch(createUser(formDetails));
      if (result) {
        onClose();
        onSuccess?.();
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <fieldset className={styles.fieldset}>
          <section className={styles.section}>
            <div>Are you sure you want to Create a Webservice Account with this information?</div>
            <Grid item md={12}>
              <span className={styles.bold}>Provider: </span>
              <span>{`${formDetails.provider.businessId} - ${formDetails.provider.organizationName}`}</span>
            </Grid>
            <Grid item md={12}>
              <span className={styles.bold}>Email: </span>
              <span>{formDetails.email}</span>
            </Grid>
            <Grid item md={12}>
              <span className={styles.bold}>First Name: </span>
              <span>{formDetails.firstName}</span>
            </Grid>
            <Grid item md={12}>
              <span className={styles.bold}>Last Name: </span>
              <span>{formDetails.lastName}</span>
            </Grid>
            <div className={`${styles.required}`}>Store this email and password in Keeper before continuing</div>
            <footer className={styles.footer}>
              <Button className={styles.button} onClick={onClose} variant={ButtonVariant.Secondary}>
                Cancel
              </Button>
              <Button
                className={styles.button}
                disabled={formik.isSubmitting}
                isSubmitting={formik.isSubmitting}
                type="submit"
                variant={ButtonVariant.Primary}
              >
                Submit
              </Button>
            </footer>
          </section>
        </fieldset>
      </form>
    </FormikProvider>
  );
};
