export enum ButtonVariant {
  Primary = 1,
  Secondary = 2,
  Tertiary = 3,
  Icon = 4,
  Label = 5,
  Danger = 6,
  Ghost = 7,
}

export enum ServerErrorCodes {
  CONFLICT__PROVIDER_FILE = 'CONFLICT__PROVIDER_FILE',
  BAD_REQUEST__FILE__RECORD_COUNT__INVALID = 'BAD_REQUEST__FILE__RECORD_COUNT__INVALID',
}

export enum OrgTypes {
  DEFAULT = 'defaultOrganizationContext',
  CURRENT = 'currentOrganizationContext',
}

export enum ToastType {
  TOAST_WARNING = 'TOAST_WARNING',
  TOAST_ALERT = 'TOAST_ALERT',
  TOAST_INFO = 'TOAST_INFO',
  TOAST_SUCCESS = 'TOAST_SUCCESS',
}

export enum TraceLevel {
  Off,
  Error,
  Warning,
  Info,
  Verbose,
}

export enum FacetTypeEnum {
  Array,
  Boolean,
  Date,
  Range,
  Text,
  Typeahead,
}

export enum StatusEnum {
  ACCEPTED = 'Accepted',
  ACTIVE = 'Active',
  CLOSED = 'Closed',
  DELETE_NOT_PROCESSED = 'Delete Not Processed',
  DELETE_SUBMITTED = 'Delete Submitted',
  DRAFT = 'Draft',
  NOT_PROCESSED = 'Not Processed',
  READY_TO_CLOSE = 'Ready to Close',
  REJECTED = 'Rejected',
  SUBMITTED = 'Submitted',
}

export enum FileTypesEnum {
  CSV = '.csv',
  PNG = '.png',
  TAB = '.tab',
  TSV = '.tsv',
  TXT = '.txt',
  XLS = '.xls',
  XLSX = '.xlsx',
  XML = '.xml',
}

export enum ToastLocationEnum {
  Top,
  Bottom,
}

export enum RepeaterEnum {
  REPEAT_TYPEAHEAD = 'RepeatTypeahead',
  REPEAT_MULTI_TYPEAHEAD = 'RepeatMultiTypeahead',
  REPEAT_INPUT = 'RepeatInput',
}

export enum BreakpointsEnum {
  xxs = '321px',
  xs = '415px',
  sm = '768px',
  md = '992px',
  lg = '1024px',
  xl = '1280px',
  xxl = '1440px',
}

export enum OrgEnum {
  ACCME = 'ACCME',
  ANCC = 'ANCC',
}

export enum BatchProcessRefreshTime {
  timer = 15000,
}

export enum ProviderSubmissionType {
  Activity = 1,
  Learner = 2,
}

export enum ProviderSubmissionChannel {
  API = 'API',
  ExcelBatch = 'ExcelBatch',
  TabBatch = 'TabBatch',
  XmlBatch = 'XmlBatch',
  Unknown = 'Unknown',
}

export enum CommandType {
  Guid = 'Guid',
  Date = 'Date',
  Time = 'Time',
  DateTime = 'DateTime',
  Bit = 'Bit',
  Int = 'Int',
  Decimal = 'Decimal',
  String = 'String',
}
