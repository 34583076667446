import { Reducer } from 'redux';
import {
  ContentTagsActionTypes,
  CLEAR_CONTENT_TAGS,
  GET_CONTENT_TAGS,
  GET_CONTENT_TAGS_FAILURE,
  GET_CONTENT_TAGS_SUCCESS,
  GET_CONTENT_TAGS_BY_ID,
  // GET_CONTENT_TAGS_BY_ID_FAILURE,
  GET_CONTENT_TAGS_BY_ID_SUCCESS,
  ContentTagsState,
} from './types';

const initialState: ContentTagsState = {
  tooltip: '',
  contentTagName: '',
  parentContentTagId: '',
  childContentTags: [],
  parentContentTag: undefined,
};

const taxonomyReducer: Reducer<ContentTagsState, ContentTagsActionTypes> = (
  state = initialState,
  action: ContentTagsActionTypes,
): ContentTagsState => {
  switch (action.type) {
    case GET_CONTENT_TAGS:
      return {
        ...state,
      };
    case GET_CONTENT_TAGS_SUCCESS:
      return {
        ...state,
        childContentTags: [...action.payload],
      };
    case GET_CONTENT_TAGS_FAILURE:
      return {
        ...state,
      };
    case GET_CONTENT_TAGS_BY_ID:
      return {
        ...state,
      };
    case GET_CONTENT_TAGS_BY_ID_SUCCESS:
      return {
        ...state,
        childContentTags: !state.childContentTags
          ? [action.payload]
          : [
              ...state.childContentTags?.filter(
                ({ parentContentTagId }) => parentContentTagId !== action.payload?.parentContentTagId,
              ),
              action.payload,
            ],
      };
    case CLEAR_CONTENT_TAGS:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default taxonomyReducer;
