import { Reducer } from 'redux';
import { DownloadFileActionTypes, DOWNLOAD_FILE, DOWNLOAD_FILE_FAILURE, DOWNLOAD_FILE_SUCCESS } from './types';

interface IDownloadFileState {
  error: string;
  isDownloading: boolean;
  isSuccess: boolean;
}

const initialState: IDownloadFileState = {
  error: null,
  isDownloading: false,
  isSuccess: false,
};

const fileDownloadReducer: Reducer<IDownloadFileState, DownloadFileActionTypes> = (
  state = initialState,
  action: DownloadFileActionTypes,
): IDownloadFileState => {
  switch (action.type) {
    case DOWNLOAD_FILE:
      return {
        ...state,
        isDownloading: true,
      };
    case DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        isDownloading: false,
        isSuccess: true,
      };
    case DOWNLOAD_FILE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isDownloading: false,
        isSuccess: false,
      };
    default:
      return state;
  }
};

export default fileDownloadReducer;
