import { Reducer } from 'redux';
import { RailActionTypes, RAIL_OPEN, RAIL_CLOSE, RailState } from './types';

const initialState: RailState = {
  railOpen: false,
};

const railReducer: Reducer<RailState, RailActionTypes> = (state = initialState, action: RailActionTypes): RailState => {
  switch (action.type) {
    case RAIL_OPEN:
      return {
        ...state,
        railOpen: true,
      };
    case RAIL_CLOSE:
      return {
        ...state,
        railOpen: false,
      };
    default:
      return state;
  }
};

export default railReducer;
