import { FocusEvent, ReactElement, useCallback } from 'react';
import { Select, MenuItem, FormControl, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMoreRounded, CheckCircleRounded, AddRounded } from '@material-ui/icons';
import { useField } from 'formik';
import { omit } from 'lodash';

const useStyles = makeStyles({
  root: {
    width: '100%',
    '& div.Mui-disabled': {
      background: '#ccc',
    },
    '&:visited': {
      outline: '2px solid #007bbd',
    },
    '&:focus': {
      outline: '2px solid #007bbd',
    },
    '&:focus-visible': {
      outline: '2px solid #007bbd',
    },
  },
  icon: {
    fill: '#373737',
  },
  iconOpen: {
    fill: '#007bbd',
  },
  paper: {
    borderRadius: 0,
    boxShadow: 'none',
    padding: 0,
  },
  list: {
    backgroundColor: '#fff',
    border: '0.03125rem solid #dedede',
    borderRadius: 0,
    padding: 0,
  },
  listPlusIcon: {
    border: 0,
    borderRadius: '50%',
    boxShadow: '1px 3px 8px 2px #00000019',
    color: '#82bc00',
    height: '1.5rem',
    minWidth: '1.5rem',
    width: '1.5rem',
  },
  listSelectedIcon: {
    color: '#82bc00',
  },
});

export interface IDropDownProps {
  afterChange?: () => void;
  className?: string;
  displayEmpty?: boolean;
  fullWidth?: boolean;
  icons?: boolean;
  idProp?: string;
  isDisabled?: boolean;
  prefixField?: string;
  items?: any[];
  labelProp?: string;
  name?: string;
  onBlur?: (event: FocusEvent) => void;
  onChange?: (event) => void;
  onClose?: () => void;
  onOpen?: () => void;
  placeholder?: string;
  required?: boolean;
}

export interface IDropdownType {
  Id: string;
  Title: string;
}

const Dropdown = (props: IDropDownProps): ReactElement => {
  const {
    afterChange,
    className,
    displayEmpty,
    fullWidth,
    isDisabled,
    prefixField,
    items,
    icons,
    labelProp,
    idProp,
    required,
    placeholder,
    name,
    onBlur,
    onChange,
    onClose,
    onOpen,
  } = props;
  const classes = useStyles();
  const idKey = idProp || 'id';
  const labelKey = labelProp || 'name';

  const [field] = useField(name);
  const selection = field?.value;

  const handleChange = useCallback(
    (e) => {
      if (typeof onChange !== 'undefined') {
        onChange(e);
      } else {
        field.onChange(e);
      }
      afterChange?.();
    },
    [afterChange, field, onChange],
  );

  return (
    <>
      <FormControl classes={{ root: classes.root }} disabled={isDisabled} fullWidth={fullWidth}>
        <Select
          className={className}
          classes={omit(classes, ['listSelectedIcon', 'listPlusIcon', 'list', 'paper'])}
          defaultValue=""
          disabled={isDisabled}
          displayEmpty={displayEmpty}
          fullWidth={fullWidth}
          aria-required={required}
          variant="outlined"
          MenuProps={{
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom',
            },
            classes: {
              list: classes.list,
              paper: classes.paper,
            },
            getContentAnchorEl: null,
          }}
          IconComponent={ExpandMoreRounded}
          inputProps={{
            classes: {
              icon: classes.icon,
              iconOpen: classes.iconOpen,
            },
            title: selection || labelKey,
          }}
          {...field}
          value={selection || ''}
          onBlur={onBlur || field.onBlur}
          onChange={handleChange}
          onClose={onClose}
          onOpen={onOpen}
        >
          {placeholder && (
            <MenuItem disabled value="">
              {placeholder}
            </MenuItem>
          )}
          {items?.map((item) => {
            return (
              <MenuItem key={item[idKey]} value={item[idKey]}>
                {icons && (
                  <ListItemIcon>
                    {selection === item[idKey] ? (
                      <CheckCircleRounded classes={{ root: classes.listSelectedIcon }} />
                    ) : (
                      <AddRounded classes={{ root: classes.listPlusIcon }} />
                    )}
                  </ListItemIcon>
                )}
                {prefixField ? (item[prefixField] || '') + ' - ' + (item[labelKey] || '') : item[labelKey]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default Dropdown;
