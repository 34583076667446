import sortBy from 'lodash/sortBy';
import {
  BoardLearnerCollectionFields,
  IBCTBoardLearnerCollectionField,
  ILearnerCompletionOption,
} from 'layouts/pages/bct/types';

interface IArgs {
  desiredOrder: ILearnerCompletionOption[];
  preSortedBoardLearnerCollectionFields: IBCTBoardLearnerCollectionField[];
}

/**
 * @function sortedBoardLearnerCollectionFields
 * @param desiredOrder
 * @param preSortedBoardLearnerCollectionFields
 * @description - Takes a non guaranteed order of a collection and returns a guaranteed order.
 * @returns IBCTBoardLearnerCollectionField[]
 * @example
 * preSortedBoardLearnerCollectionFields =
 *  [{
 *   collectionType: "Collected",
 *   fieldType: "Last Name",
 *   taxonomyTermIds: []
 * },
 * {
 *   collectionType: "Collected",
 *   fieldType: "First Name",
 *   taxonomyTermIds: []
 * }];
 *
 * desiredOrder =
 *  [{
 *   fieldType: "First Name",
 * },
 * {
 *   fieldType: "Last Name",
 * }];
 *
 * @output [{
 *   collectionType: "Collected",
 *   fieldType: "First Name",
 *   taxonomyTermIds: []
 * },
 * {
 *   collectionType: "Collected",
 *   fieldType: "Last Name",
 *   taxonomyTermIds: []
 * }];
 */
export const sortedBoardLearnerCollectionFields = ({
  desiredOrder,
  preSortedBoardLearnerCollectionFields,
}: IArgs): IBCTBoardLearnerCollectionField[] =>
  sortBy(preSortedBoardLearnerCollectionFields, (field): number =>
    desiredOrder
      .map(({ fieldType }: IBCTBoardLearnerCollectionField): BoardLearnerCollectionFields => fieldType)
      .indexOf(field.fieldType),
  );
