const TOOLBAR_OPTIONS = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],

  // Out-dent/In-dent.
  [{ indent: '-1' }, { indent: '+1' }],

  // Custom dropdown sizing.
  [{ size: ['small', false, 'large', 'huge'] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  // Dropdown with defaults from theme.
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],

  // Remove formatting button.
  ['clean'],
];

export const MODULES = {
  toolbar: TOOLBAR_OPTIONS,
};
