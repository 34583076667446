import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

// Components
import Button from 'components/Button/Button';

// Styles
import styles from './index.module.scss';

// Core + Store
import { isLearnerCreatingSelector } from 'store/learner/selectors';
import { isActivityCreatingSelector } from 'store/activity/selectors';

// Types
import { IButtonProps } from '../types';
type IProps = IButtonProps;

export const PROCEED_LABEL = 'Yes, Upload File';
export const CANCEL_LABEL = "No, Don't Upload";

export const DuplicateFileDetected = (props: IProps): ReactElement => {
  const { onCancel, onProceed } = props;

  // Selectors
  const isActivityCreating: boolean = useSelector(isActivityCreatingSelector);
  const isLearnerCreating: boolean = useSelector(isLearnerCreatingSelector);

  const isDisabled = isActivityCreating || isLearnerCreating;

  return (
    <>
      <p className={styles.text}>
        The file has the same name and size of a previously uploaded file and could be a duplicate.
      </p>
      <span className={styles.bold}>Are you sure you want to proceed with this upload?</span>
      <div className={classNames(styles['button-row'], 'modal-button-row')}>
        <Button className="primary" disabled={isDisabled} onClick={onProceed}>
          {PROCEED_LABEL}
        </Button>
        <Button className="secondary" disabled={isDisabled} onClick={onCancel}>
          {CANCEL_LABEL}
        </Button>
      </div>
    </>
  );
};
