import { BCTSteps, IBCTLearnerDate, IBCTSpecialty, ITaxonomyTerm } from 'core/models';
import { ReactElement } from 'react';

export enum BoardTypes {
  CERTIFYING_BOARD = 'CertifyingBoard',
  STATE_BOARD = 'StateBoard', // Also referred to as Licensing board
  REMS = 'Rems',
}

export interface IBCTSteps {
  [BCTSteps.ACTIVITY_REGISTRATION_DETAILS]: ReactElement;
  [BCTSteps.CONTENT_BLUEPRINT_DETAILS]: ReactElement;
  [BCTSteps.CREDIT_TYPE_DETAILS]: ReactElement;
  [BCTSteps.LEARNER_COMPLETION_DETAILS]: ReactElement;
  [BCTSteps.ORGANIZATION_DETAILS]: ReactElement;
}

export enum BoardIntegrationTypes {
  A = 'A',
  B = 'B',
  C = 'C',
}

export enum BoardCreditTypeSubmissionTypes {
  ALONE = 'Alone',
  WITH_ANY_OTHER_CREDIT_TYPE = 'WithAnyOtherCreditType',
  WITH_SPECIFIC_CREDIT_TYPES = 'WithSpecificCreditTypes',
}

export enum BoardLearnerCollectionFields {
  ACCME_ACTIVITY_ID = 'ACCME Activity ID',
  BOARD_ID = 'Board ID',
  BOARD_NAME = 'Board Name',
  COMPLETION_DATE = 'Completion date',
  CREDIT_TYPE = 'Credit type',
  CREDITS_EARNED_PER_CREDIT_TYPE = 'Credits earned (per credit type)',
  DATE_OF_BIRTH = 'Date of Birth (MM/DD)',
  DEA_REGISTRATION = 'DEA Registration',
  EMAIL_ADDRESS = 'Email address',
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  LICENSE_ID = 'License ID',
  MEDICAL_SCHOOL = 'Medical School',
  MOBILE_NUMBER = 'Mobile number',
  NPI = 'NPI',
  PRACTICE_AREA = 'Practice Area',
  PRACTICE_STATE = 'Practice State',
  PRESCRIBED_IN_PAST_12_MONTHS = 'Prescribed in past 12 months',
  PROFESSION = 'Profession',
  PROVIDER_LEARNER_ID = 'Provider Learner ID',
  STATE_OF_LICENSURE = 'State of licensure',
  SURGICAL_PROCEDURES = 'Surgical Procedures',
  TIME_IN_PRACTICE = 'Time in Practice',
  ULID = 'ULID',
}

export enum CollectionTypes {
  NOT_COLLECTED = 'Not Collected',
  OPTIONAL = 'Optional',
  REQUIRED = 'Required',
}

export enum StatusTypes {
  ACTIVE = 'Active',
  ARCHIVED = 'Archived',
  INACTIVE = 'Inactive',
}

export type ILearnerCompletionOption = Omit<IBCTBoardLearnerCollectionField, 'collectionType' | 'taxonomyTermIds'>;

export interface IBCTCreditType {
  abbreviation: string;
  activityEndDate: Date;
  activityStartDate: Date;
  boardCreditTypeRequiredCreditTypeIds: string[];
  boardCreditTypeSubmissionType: BoardCreditTypeSubmissionTypes;
  default: boolean;
  earnsCredit: boolean;
  id: string;
  isAdditive: boolean;
  learnerCompletionEndDate: Date;
  learnerCompletionStartDate: Date;
  mustMatchCmeAmount: boolean;
  name: string;
  organizationName: string;
  registrationEndDate: Date;
  registrationStartDate: Date;
  sortOrder: number;
  visibleToProviders: boolean;
  xmlLanguage: string;
  xmlLanguageUnits: string;
}

export interface IBCTBoardLearnerCollectionField {
  collectionType: CollectionTypes;
  fieldType: BoardLearnerCollectionFields;
  taxonomyTermIds: string[];
  taxonomyTerm?: string;
}

export interface IBoardActivityType {
  allowRepeatParticipation: boolean;
  endDate?: Date;
  id: string;
  maxCreditValidation: boolean;
  startDate?: Date;
}

export interface IBoardAdditionalFields {
  id?: string;
  question: string;
  sortOrder?: number;
}

export interface IBCTRegularExpression {
  errorMessage: string;
  regularExpression: string;
}

export interface IBCTRemsRegularExpression extends IBCTRegularExpression {
  startDate: Date;
  endDate: Date;
  label: string;
}

export type IContentOutline = Pick<ITaxonomyTerm, 'id' | 'name' | 'sortOrder' | 'tag' | 'terms'>;

export interface IBCTBoard {
  abbreviation: string;
  activityStatus: StatusTypes;
  allowLearnerCompletionDatesAfterCreditClaimDate: boolean;
  attestationText: string;
  boardActivityAdditionalFields: IBoardAdditionalFields[];
  boardFsmbAliases: string[];
  boardActivityTypes: IBoardActivityType[];
  boardIntegrationType: BoardIntegrationTypes;
  boardLastLearnerCompletionDates: IBCTLearnerDate[];
  boardLastLearnerCompletionRemovalDates: IBCTLearnerDate[];
  boardLearnerCollectionFields: IBCTBoardLearnerCollectionField[];
  boardLearnerIdRegularExpressions: IBCTRegularExpression[];
  boardRemsIdRegularExpressions: IBCTRemsRegularExpression[];
  businessId: string;
  contentOutlines: IContentOutline[];
  creditIncrement: number;
  creditTypes: IBCTCreditType[];
  cycleLengthYears: number;
  enableMaximumCreditValidation: boolean;
  id: string;
  learnerStatus: StatusTypes;
  maximumContentOutlines: number;
  minimumContentOutlines: number;
  name: string;
  providerActivityIdRequired: boolean;
  remsTaxonomyTermId: string;
  sendErrorEmailsToProviders: boolean;
  showActivitiesWithNoLearnersReported: boolean;
  showInPhysicianApp: boolean;
  specialties: IBCTSpecialty[];
  states: string[];
  totalCreditRequired: number;
  type: BoardTypes;
  usesContentOutlines: boolean;
}

export interface IBCTStepProps {
  prevStep?: BCTSteps;
  nextStep?: BCTSteps;
}
