import { AppState } from 'store';
import { UserProfile } from './types';
import { IAuth0User, IUserOrganization, OrganizationKinds, RollupOrganizationEnums } from 'core/models';
import { createSelector } from 'reselect';
import { OrgEnum } from 'core/enums';

export const userProfileSelector = ({ userState }: AppState): UserProfile => userState?.userProfile;
export const auth0UserSelector = ({ userState }: AppState): IAuth0User => userState?.auth0User;
export const isLoadingProfileSelector = ({ userState }: AppState): boolean => userState?.isLoading;

// If a `currentProviderContext` is supplied, we must use that IUserOrganization as the contextual organization for the user's session
export const currentOrganizationContextSelector = ({ userState }: AppState): IUserOrganization =>
  userState?.currentOrganizationContext;
export const currentProviderContextSelector = ({ userState }: AppState): IUserOrganization =>
  userState?.currentProviderContext;

export const currentContextualOrg = ({ userState }: AppState): IUserOrganization =>
  userState?.currentProviderContext || userState?.currentOrganizationContext;

export const rollupOrganizationEnumSelector = createSelector(
  currentOrganizationContextSelector,
  currentProviderContextSelector,
  (currentOrganizationContext: IUserOrganization, currentProviderContext): RollupOrganizationEnums | undefined =>
    currentProviderContext
      ? currentProviderContext?.rollupOrganizationEnum
      : currentOrganizationContext?.rollupOrganizationEnum,
);

export const isAccmeUserSelector = ({ userState }: AppState): boolean => {
  const defaultOrganizationContext = userState?.defaultOrganizationContext;
  const currentOrganizationContext = userState?.currentOrganizationContext;
  const defaultOrgName = defaultOrganizationContext?.organizationName;
  const selectedOrgName = currentOrganizationContext?.organizationName;
  return defaultOrgName?.toUpperCase() === OrgEnum.ACCME || selectedOrgName?.toUpperCase() === OrgEnum.ACCME;
};

// Convenience boolean for if the `currentOrganizationContext` is an ACCME Staff organization
export const isDefaultOrgAccmeStaffSelector = ({ userState }: AppState): boolean => {
  const defaultOrganizationContext = userState?.defaultOrganizationContext;
  const organizationName = defaultOrganizationContext?.organizationName;
  const securityGroups = defaultOrganizationContext?.securityGroups;
  const isAccme = organizationName?.toUpperCase() === OrgEnum.ACCME;
  const isStaff = securityGroups?.includes('staff');
  return isAccme && isStaff;
};

export const organizationKindSelector = ({ userState }: AppState): IUserOrganization =>
  userState?.currentProviderContext || userState?.currentOrganizationContext || ({} as IUserOrganization);

export const isBoardUserSelector = createSelector(
  organizationKindSelector,
  (currentOrg: IUserOrganization): boolean => currentOrg?.organizationKind === OrganizationKinds.BOARD,
);

export const isStaffUserSelector = createSelector(
  organizationKindSelector,
  (currentOrg) =>
    currentOrg?.organizationKind === OrganizationKinds.SPECIAL_ORGANIZATION ||
    currentOrg?.organizationKind === OrganizationKinds.STAFF,
);

export const staffRollupOrganizationEnumsSelector = createSelector(
  userProfileSelector,
  (currentUser) => currentUser.staffRollupOrganizationEnums,
);

export const isProviderUserSelector = createSelector(
  organizationKindSelector,
  (currentOrg) => currentOrg?.organizationKind === OrganizationKinds.PROVIDER,
);

export const isAccreditorUserSelector = createSelector(
  organizationKindSelector,
  (currentOrg) => currentOrg?.organizationKind === OrganizationKinds.ACCREDITOR,
);

export const currentOrganizationKindSelector = createSelector(
  organizationKindSelector,
  (currentOrg) => currentOrg?.organizationKind,
);
