// Components
import { PageNavigation, PageTitle } from 'components/PageComponents';
import { ReactElement } from 'react';
import { BatchLearnerValidationHistoryDetail } from './details';

export const BatchLearnerValidationHistoryPage = (): ReactElement => {
  return (
    <section className="form-container">
      <PageNavigation path="/learners/validation/batch" label="Batch Upload Learner Validation" />
      <PageTitle eyebrow="upload history" title="View and download previous batch files and error reports." />
      <div className="form-instructions">
        Open your Results file to review the learner validation results in the last column of the file. Possible results
        are:
        <ol>
          <li>The learner is matched, and you can submit completion data for this learner.</li>
          <li>
            There is no learner matching the information you provided. Please try the validation again by entering more
            data about the learner to see if a match can be made.
          </li>
          <li>
            Multiple learners were matched to the data you provided. Please try the validation again by entering more
            data about the learner to see if a single match can be made.
          </li>
        </ol>
      </div>
      <BatchLearnerValidationHistoryDetail />
    </section>
  );
};
