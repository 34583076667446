/**
 * Capitalize the first letter of a word.
 * @param word
 */
export const capitalizeWord = (word: string): string => {
  if (word != null) {
    word = word.charAt(0).toUpperCase() + word.slice(1);
    return word;
  } else {
    return '';
  }
};
