import { useAuth0 } from '@auth0/auth0-react';
import { keys, orderBy } from 'lodash';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Core
import {
  TAXONOMY_COMMENDATION_CRITERIA_ROOT_ID,
  TAXONOMY_OUTCOMES_MEASURED_ROOT_ID,
  TAXONOMY_REMS_ROOT_ID,
  TAXONOMY_SUPPORT_SOURCES_ROOT_ID,
  TAXONOMY_PARTICIPATION_FEE_ROOT_ID,
  TAXONOMY_ACTIVITY_CREDIT_ROOT_ID,
  TAXONOMY_ACTIVITY_PHARMACY_TOPIC_ID,
  TAXONOMY_ACTIVITY_PHARMACY_TYPE_ID,
  TAXONOMY_ACTIVITY_PHARMACY_CONTENT_ID,
  TAXONOMY_ACTIVITY_PHARMACY_CONTENT_STATE_ID,
  TAXONOMY_ACTIVITY_PHARMACY_CERTIFICATION_ID,
  TAXONOMY_FAQ_ROOT_ID,
  CONTENT_TAG_CME_ROOT_ID,
} from 'core/constants';
import {
  Activity,
  ActivityFormStateValues,
  ActivityType,
  IActivityValidatorBlueprint,
  IBoardSlim,
  IDictionary,
  IDisabledContext,
  ITaxonomyTerm,
  IUserOrganization,
  RollupOrganizationEnums,
} from 'core/models';

// Store
import { getActivity, getActivityTypes, patchActivity, getNextSequenceNumber } from 'store/activity/actions';
import {
  activityDetailSelector,
  activityEditIndexSelector,
  activityTypeLearnerReportGroupingsSelector,
  activityTypesSelector,
  activityTypesWithLearnerCreditValidationSelector,
} from 'store/activity/selectors';
import { boardSelector, configuredBoardsDictionarySelector, configuredBoardsSelector } from 'store/board/selectors';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import {
  amaCreditTermIdSelector,
  commercialSupportSourcesDictionarySelector,
  creditTypesDictionarySelector,
  pharmacyCreditTermIdSelector,
  taxonomyRootTermsSelector,
} from 'store/taxonomy/selectors';
import { organizationKindSelector } from 'store/user/selectors';
import { getBoardById } from 'store/board/actions';

// Components
import EditActivityForm from './EditForm';
import { LoadingCards } from 'components/LoadingCard';

// Misc
import { transformActivityFormValues } from './util';
import { buildActivityValidationSchemas } from 'layouts/pages/activity/forms/validation/validationSchemas';
import { IBCTBoard } from 'layouts/pages/bct/types';
import { recursivePatch } from 'utils';
import { buildDisabledFieldContext, IDisabledContextBlueprint } from '../add-activity/utils/buildDisabledFieldContext';

// Context
import { DisabledContext } from 'components/forms/DisabledContext';

interface IActivityParams {
  id?: string;
}

const EditContainer = (): ReactElement => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const { id: requestedId = '' } = useParams<IActivityParams>();
  const history = useHistory();
  const activityDetail: Activity = useSelector(activityDetailSelector);
  const activityTypes: ActivityType[] = useSelector(activityTypesSelector);
  const activityTypeGroups: ActivityType[][] = useSelector(activityTypeLearnerReportGroupingsSelector);
  const activityTypesWithLearnerCreditValidation: string[] = useSelector(
    activityTypesWithLearnerCreditValidationSelector,
  );
  const activityEditIndex: number = useSelector(activityEditIndexSelector);
  const terms: IDictionary<ITaxonomyTerm> = useSelector(taxonomyRootTermsSelector);
  const commercialSupportSourcesDictionary: IDictionary<ITaxonomyTerm> = useSelector(
    commercialSupportSourcesDictionarySelector,
  );
  const creditTypesDictionary: IDictionary<ITaxonomyTerm> = useSelector(creditTypesDictionarySelector);
  const allBoards: IBoardSlim[] = useSelector(boardSelector);
  const configuredBoards: IBCTBoard[] = useSelector(configuredBoardsSelector);
  const configurationDictionary: IDictionary<IBCTBoard> = useSelector(configuredBoardsDictionarySelector);
  const organization: IUserOrganization = useSelector(organizationKindSelector);
  const amaCreditTerm: ITaxonomyTerm = useSelector(amaCreditTermIdSelector);
  const pharmacyCreditTerm: ITaxonomyTerm = useSelector(pharmacyCreditTermIdSelector);
  const learnerEarliestCompletion = activityDetail?.learnerEarliestCompletionDate;
  const learnerLatestCompletion = activityDetail?.learnerLatestCompletionDate;
  const learnerMaximumCreditAmounts = activityDetail?.learnerMaximumCreditAmounts;
  // TODO: Confirm this is the field BR36 refers to
  const orgId = organization?.businessId;
  const maximumCertifyingBoardCredits = activityDetail?.maximumCertifyingBoardCredits;
  const boardLearnersReportedDictionary: IDictionary<boolean> = {};
  const remsLearnersReported = activityDetail?.hasRemsLearnersReported;
  const status = activityDetail?.status;
  const boardMocDetails = activityDetail?.boardMocDetails;
  const boardRemsDetails = activityDetail?.boardRemsDetails;
  const hasLearners = activityDetail?.hasIndividualLearners;
  const selectedActivityType = activityDetail?.typeId;

  keys(maximumCertifyingBoardCredits)?.forEach((boardKey: string): void => {
    boardLearnersReportedDictionary[boardKey] = true;
  });

  const disabledFieldBlueprint: IDisabledContextBlueprint = {
    activity: activityDetail,
    activityTypeGroups,
    activityTypes,
    configuredBoards,
  };
  const disabledContextShape: IDisabledContext = buildDisabledFieldContext(disabledFieldBlueprint);
  const validationBlueprint: IActivityValidatorBlueprint = {
    activityTypeGroups,
    activityTypesWithLearnerCreditValidation,
    amaCreditTerm,
    boardLearnersReportedDictionary,
    boardMocDetails,
    configuredBoards,
    creditTypesDictionary,
    hasLearners,
    learnerEarliestCompletion,
    learnerLatestCompletion,
    learnerMaximumCreditAmounts,
    maximumCertifyingBoardCredits,
    orgId,
    pharmacyCreditTerm,
    remsLearnersReported,
    selectedActivityType,
    status,
  };

  const validationSchemas = buildActivityValidationSchemas(validationBlueprint, activityDetail?.rollupOrganizationEnum);
  const dictionaries = {
    commercialSupportSourcesDictionary,
    creditTypesDictionary,
  };

  const termSetIds: string[] = [
    TAXONOMY_COMMENDATION_CRITERIA_ROOT_ID,
    TAXONOMY_OUTCOMES_MEASURED_ROOT_ID,
    TAXONOMY_REMS_ROOT_ID,
    TAXONOMY_SUPPORT_SOURCES_ROOT_ID,
    TAXONOMY_PARTICIPATION_FEE_ROOT_ID,
    TAXONOMY_ACTIVITY_CREDIT_ROOT_ID,
    TAXONOMY_ACTIVITY_PHARMACY_TOPIC_ID,
    TAXONOMY_ACTIVITY_PHARMACY_TYPE_ID,
    TAXONOMY_ACTIVITY_PHARMACY_CONTENT_ID,
    TAXONOMY_ACTIVITY_PHARMACY_CONTENT_STATE_ID,
    TAXONOMY_ACTIVITY_PHARMACY_CERTIFICATION_ID,
    TAXONOMY_FAQ_ROOT_ID,
  ];

  const boardIds = [...keys(boardMocDetails), ...keys(boardRemsDetails)];

  // Taxonomy Initialization Hook
  useEffect(() => {
    termSetIds.forEach((id) => {
      if (!terms[id] && isAuthenticated) {
        dispatch(getTaxonomyTermById(id));
      }
    });
  }, []);

  useEffect(() => {
    // Silently get the sequence number
    if (
      activityDetail?.rollupOrganizationEnum === RollupOrganizationEnums.JA &&
      !activityDetail.pharmacySequenceNumber
    ) {
      dispatch(getNextSequenceNumber([activityDetail]));
    }
  }, [activityEditIndex]);

  useEffect(() => {
    if (!activityTypes || activityTypes?.length < 1) {
      dispatch(getActivityTypes());
    }
    if (activityDetail?.id !== requestedId) {
      dispatch(getActivity(requestedId));
    } else {
      const missingBoardIds = boardIds?.filter((boardId) => !configurationDictionary?.[boardId]);
      // Pick up any required data based on MOC/REMS selections
      Promise.all(missingBoardIds.map(async (boardId) => await dispatch(getBoardById(boardId))));
    }
  }, [activityEditIndex, activityDetail]);

  const handleSubmit = async (values: ActivityFormStateValues) => {
    // Filter blank string and undefined from Pharmacy live date
    if (values.pharmacyLiveDates?.length > 0) {
      values.pharmacyLiveDates = values.pharmacyLiveDates.filter((x) => !!x);
    }
    const transformedFormValues: Partial<Activity> = transformActivityFormValues({
      amaCreditId: amaCreditTerm?.id,
      configDictionary: configurationDictionary,
      values,
    });
    const { id } = activityDetail;
    const omitKeys = [
      'boardMocDetailsSelection',
      'commercialInKindSupportSources',
      'commercialMonetarySupportSources',
      'commercialSupportSourceKind',
      'formTemplate',
      'hasBeenInvoiced',
      'hasIndividualLearners',
      'hasRemsLearnersReported',
      'hasReviewHistory',
      'id',
      'isDeleted',
      'isTargetAudiencePharmacy',
      'isTargetAudiencePharmacyTech',
      'isRemsCompliant',
      'isUnderReview',
      'learnerCounts',
      'learnerEarliestCompletionDate',
      'learnerLatestCompletionDate',
      'learnerMaximumCreditAmounts',
      'maximumCertifyingBoardCreditTypeCredits',
      'maximumCertifyingBoardCredits',
      'printableId',
      'status',
      'supportedRemsIds',
      'tempDatesAndLocation',
      `contentTagsTop-${CONTENT_TAG_CME_ROOT_ID}`,
    ];
    const transformedPatchValues = recursivePatch<Partial<Activity>>({
      baseObject: activityDetail,
      omitList: omitKeys,
      updatedObject: transformedFormValues,
    });

    await dispatch(
      patchActivity(id, transformedPatchValues, () => {
        history.push(`/activities/detail/${requestedId}`);
        window.scroll(0, 0);
      }),
    );
  };

  const missingBoardIds = boardIds?.filter((boardId) => !configurationDictionary?.[boardId]);

  if (
    activityDetail?.id !== requestedId ||
    missingBoardIds?.length ||
    termSetIds.some((termSetId) => !terms[termSetId])
  ) {
    return (
      <section className="form-container">
        <LoadingCards count={4} />
      </section>
    );
  }
  return (
    <DisabledContext.Provider value={disabledContextShape}>
      <EditActivityForm
        activity={activityDetail}
        boards={allBoards}
        editIndex={activityEditIndex}
        schemas={validationSchemas}
        terms={terms}
        types={orderBy(activityTypes, 'displayOrder')}
        dictionaries={dictionaries}
        handleSubmit={handleSubmit}
        idParam={requestedId}
      />
    </DisabledContext.Provider>
  );
};

export default EditContainer;
