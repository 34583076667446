import {
  CLEAR_BOARDS,
  GET_BOARDS,
  GET_BOARDS_FAILURE,
  GET_BOARDS_SUCCESS,
  GET_BOARD_BY_ID,
  GET_BOARD_BY_ID_FAILURE,
  GET_BOARD_BY_ID_SUCCESS,
} from './types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store';
import { BoardService } from '../../services/BoardService';
import { handleServerError } from '../../globals/utils/handleServerError';
import { PARSAction, IBoardSlim } from 'core/models';
import { IBCTBoard } from 'layouts/pages/bct/types';

export const getBoardsAction = (): PARSAction<undefined, typeof GET_BOARDS> => ({
  type: GET_BOARDS,
});

export const getBoardsSuccessAction = (payload: IBoardSlim[]): PARSAction<IBoardSlim[], typeof GET_BOARDS_SUCCESS> => {
  return {
    payload,
    type: GET_BOARDS_SUCCESS,
  };
};

export const getBoardsFailureAction = (payload: Error): PARSAction<Error, typeof GET_BOARDS_FAILURE> => {
  return {
    payload,
    type: GET_BOARDS_FAILURE,
  };
};

export const getBoardByIdAction = (): PARSAction<undefined, typeof GET_BOARD_BY_ID> => ({
  type: GET_BOARD_BY_ID,
});

export const getBoardByIdSuccessAction = (
  payload: IBCTBoard,
): PARSAction<IBCTBoard, typeof GET_BOARD_BY_ID_SUCCESS> => {
  return {
    payload,
    type: GET_BOARD_BY_ID_SUCCESS,
  };
};

export const getBoardByIdFailureAction = (payload: Error): PARSAction<Error, typeof GET_BOARD_BY_ID_FAILURE> => {
  return {
    payload,
    type: GET_BOARD_BY_ID_FAILURE,
  };
};

export const clearBoards = (): PARSAction<undefined, typeof CLEAR_BOARDS> => ({
  type: CLEAR_BOARDS,
});

export const getAllBoards = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(getBoardsAction());
  try {
    const boardService = new BoardService();
    const result: Array<IBoardSlim> = await boardService.getAllBoardsAsync();
    await dispatch(getBoardsSuccessAction(result));
  } catch (error) {
    handleServerError({ error, thunkName: 'getAllBoards' });
    await dispatch(getBoardsFailureAction(error));
  }
};

export const getBoardById = (id: string): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
  getState,
) => {
  const { boardState } = getState();
  // Check if we already have this board's details loaded
  const isExistingBoard = !!boardState?.configuredBoards?.find(({ id: boardId }: IBCTBoard): boolean => boardId === id);
  if (isExistingBoard) {
    return;
  }
  const boardService = new BoardService();
  await dispatch(getBoardByIdAction());
  try {
    const result = await boardService.getBoardById(id);
    await dispatch(getBoardByIdSuccessAction(result));
  } catch (error) {
    handleServerError({ error, thunkName: 'getBoardById' });
    await dispatch(getBoardByIdFailureAction(error));
  }
};
