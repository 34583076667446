import { AxiosResponse } from 'axios';
import { API } from 'core';
import { ServerRoute } from 'core/api';

export class SmsSummaryReportFileService {
  /**
   * Retrieves a list of Sms Summary Report Files.
   *
   * @returns {Promise<Array<string>>} - A promise that resolves to an array of file names.
   */
  public static async getSmsSummaryReportFileList(): Promise<Array<string>> {
    const route: ServerRoute = ServerRoute.forAction('SmsSummaryReports/list');
    const result: AxiosResponse = await API.getFullResponse(route, undefined, { responseType: 'json' });
    return result.data;
  }

  /**
   * Retrieves an Sms Summary Report File located in the current organizations sms-reports directory.
   *
   * @returns {Promise<Blob>} - A Promise that resolves with the retrieved file as a Blob object.
   */
  public static async getSmsSummaryReportFileInDirectory(fileName: string): Promise<Blob> {
    const route: ServerRoute = ServerRoute.forAction('SmsSummaryReports/fileInDirectory', fileName);
    const result: AxiosResponse = await API.getFullResponse(route, undefined, { responseType: 'blob' });
    return result.data;
  }

  /**
   * Retrieves all files in the current organizations sms-reports directory as a zip.
   *
   * @returns {Promise<Blob>} - A Promise that resolves with the retrieved zip as a Blob object.
   */
  public static async getFilesInDirectoryPathAsZip(): Promise<Blob> {
    const route: ServerRoute = ServerRoute.forAction('SmsSummaryReports/directory');
    const result: AxiosResponse = await API.getFullResponse(route, undefined, { responseType: 'arraybuffer' });
    return result.data;
  }

  /**
   * Returns whether the current user is authorized to view SMS Summary Reports Page.
   *
   * @returns {Promise<boolean>} - A Promise that resolves with the authorization boolean.
   */
  public static async getSmsSummaryReportAuthorization(): Promise<boolean> {
    const route: ServerRoute = ServerRoute.forAction('SmsSummaryReports/authorization');
    const result: AxiosResponse = await API.getFullResponse(route, undefined, { responseType: 'json' });
    return result.data;
  }
}
