import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components
import { Tab } from 'components/Tab';
import { ReactComponent as AccmeLogo } from 'globals/images/accme-logo.svg';
import { ReactComponent as JALogo } from 'globals/images/ja-logo.svg';

// Store
import { ITaxonomyTerm, RollupOrganizationEnums } from 'core/models';
import { rollupOrganizationEnumSelector } from 'store/user/selectors';
import { getSite } from 'utils/site';

// Types
interface INavProps {
  items: ITaxonomyTerm[];
}

const ANCCLogo = () => (
  // ANCC logo has no built-in padding, so add a class to add some in CSS
  <img className="ancc-logo" src={`${process.env.PUBLIC_URL}/logo-ancc-header-transparent.png`} alt="ANCC" />
);

export const Nav = (props: INavProps): ReactElement => {
  const { items = [] } = props;
  let rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);

  // #6122 If the rollup org did not get set then the current user probably has an inactive organization,
  // we should still show the correct logo based off of the 'PARS' or 'NARS' site.
  if (rollupOrganizationEnum === undefined) {
    rollupOrganizationEnum = getSite();
  }

  return (
    <>
      <div className="logo">
        <Link to="/">
          {rollupOrganizationEnum === RollupOrganizationEnums.JA ? (
            <JALogo />
          ) : rollupOrganizationEnum === RollupOrganizationEnums.NARS ? (
            <ANCCLogo />
          ) : (
            <AccmeLogo />
          )}
        </Link>
      </div>
      <nav>
        {items.map((term: ITaxonomyTerm) => (
          <Tab key={term.id} title={term.name} link={term.description || ''} exact={false} />
        ))}
      </nav>
    </>
  );
};
