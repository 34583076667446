import { Component } from 'react';
import { ExpandMoreRounded, KeyboardArrowRightRounded } from '@material-ui/icons';
import classNames from 'classnames';
import './showhide.module.scss';

export interface IShowHideState {
  open: boolean;
}

export interface IShowHideProps {
  title: string;
}

export default class Expander extends Component<IShowHideProps, IShowHideState> {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  public render(): React.ReactElement<IShowHideProps> {
    const { title, children } = this.props;
    const { open } = this.state;

    const itemClass: string = classNames('showhide-item', {
      'showhide-item--active': open,
    });

    return (
      <div className="showhide">
        <div className={itemClass}>
          <button className="showhide-item-heading" aria-expanded={open} onClick={() => this.setState({ open: !open })}>
            <div className="showhide-item-title">
              {open ? <ExpandMoreRounded /> : <KeyboardArrowRightRounded />}
              <span>
                {open ? 'Hide ' : 'Show '}
                {title}
              </span>
            </div>
          </button>
          <div className="showhide-item-content">{children}</div>
        </div>
      </div>
    );
  }
}
