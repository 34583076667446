import * as PARS from '../core';
import { CacheService } from './../services';

export abstract class TokenService {
  static getAccessToken() {
    const accessToken: string | null = CacheService.get<string>(PARS.Constants.CACHE_PARS_ACCESS_TOKEN);
    return accessToken;
  }

  static setAccessToken(token: string) {
    CacheService.set(PARS.Constants.CACHE_PARS_ACCESS_TOKEN, token);
  }
}
