import { BaseApiService } from './baseApiService';
import { ILeaernerValidationProviderFile, IProviderFile } from './models';

export class ProviderFilesApiService extends BaseApiService {
  public async getActivityBatchUploadHistoryAsync(): Promise<Array<IProviderFile>> {
    return await super.getAsync('/api/ProviderFiles/activitybatchuploadhistory');
  }

  public async getActivityContentsAsync(id: string): Promise<Blob> {
    return await super.getDownloadAsync(`/api/ProviderFiles/${id}/contents`);
  }

  public async getLearnerBatchUploadHistoryAsync(): Promise<Array<IProviderFile>> {
    return await super.getAsync('api/ProviderFiles/learnercompletionbatchuploadhistory');
  }

  public async getLearnerContentsAsync(id: string): Promise<Blob> {
    return await super.getDownloadAsync(`/api/ProviderFiles/${id}/contents`);
  }

  public async getLearnerValidationBatchUploadHistoryAsync(): Promise<Array<ILeaernerValidationProviderFile>> {
    return await super.getAsync('api/ProviderFiles/learnervalidationbatchuploadhistory');
  }

  public async getLearnerValidationContentsAsync(id: string): Promise<Blob> {
    return await super.getDownloadAsync(`/api/ProviderFiles/${id}/learnervalidationcontents`);
  }
}
