import { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import { useParams } from 'react-router';
import moment from 'moment';
import { keys, max, noop } from 'lodash';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import { useMediaQuery } from '@material-ui/core';

// Components
import Button from 'components/Button/Button';
import { AddLearnerForm } from '../forms/AddLearnerForm';
import { RecentLearnerCard } from './RecentLearnerCard';
import Faq from 'components/Faq/Faq';
import { LoadingCards } from 'components/LoadingCard';

// Core + Store
import { BreakpointsEnum } from 'core/enums';
import {
  Activity,
  ActivityType,
  BoardMocDetails,
  IBoardRemsDetails,
  IDictionary,
  IFormInputOption,
  ILearnerCompletion,
  IStateAndProvince,
  ITaxonomyTerm,
  PARSAction,
} from 'core/models';
import {
  TAXONOMY_ACTIVITY_CREDIT_ROOT_ID,
  TAXONOMY_FAQ_ROOT_ID,
  TAXONOMY_MEDICAL_SCHOOLS_ROOT_ID,
  TAXONOMY_DEA_REGISTRATION_ROOT_ID,
  TAXONOMY_PRACTICE_AREA_ROOT_ID,
  TAXONOMY_TIME_IN_PRACTICE_ROOT_ID,
  TAXONOMY_PROFESSION_ROOT_ID,
} from 'core/constants';
import { configuredBoardsDictionarySelector, configuredBoardsSelector } from 'store/board/selectors';
import {
  amaCreditTermIdSelector,
  medicalSchoolTermsSelector,
  taxonomyRootTermsSelector,
} from 'store/taxonomy/selectors';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { getBoardById } from 'store/board/actions';
import { activityDetailSelector, activityTypesSelector } from 'store/activity/selectors';
import { getActivity, getActivityTypes } from 'store/activity/actions';
import { learnerStateSelector } from 'store/learner/selectors';
import { getRecentLearnerCompletions } from 'store/learner/actions';
import { closeRail, openRail } from 'store/rail/actions';
import { isRailOpenSelector } from 'store/rail/selectors';
import { ITypeaheadOption } from 'components/ContinuousImprovement/Typeahead';
import { LearnerState } from 'store/learner/types';
import { BoardTypes, IBCTBoard } from 'layouts/pages/bct/types';
import { statesAndProvincesSelector } from 'store/locations/selectors';
import { getStatesAndProvinces } from 'store/locations/actions';

// Misc
import {
  buildBoardOptions,
  generateCreditOptionsDictionary,
  ICreditTypeFormOption,
  mapTermToFormInputOption,
} from './utils';

// Styles
import styles from './index.module.scss';

export const AddLearnerPage = (): ReactElement => {
  // Hooks
  const dispatch = useDispatch();
  const { activityId: activityIdParam } = useParams<{ activityId: string }>();
  const activityDetail: Activity = useSelector(activityDetailSelector);
  const activityTypes: ActivityType[] = useSelector(activityTypesSelector);
  const amaCreditTerm: ITaxonomyTerm = useSelector(amaCreditTermIdSelector);
  const isOpen: boolean = useSelector(isRailOpenSelector);
  const learnerState: LearnerState = useSelector(learnerStateSelector);
  const statesAndProvinces: IStateAndProvince[] = useSelector(statesAndProvincesSelector);
  const medicalSchools: ITaxonomyTerm[] = useSelector(medicalSchoolTermsSelector);
  const taxonomyRoot: IDictionary<ITaxonomyTerm> = useSelector(taxonomyRootTermsSelector);
  const allBoards: IBCTBoard[] = useSelector(configuredBoardsSelector);
  const modernBoardsDictionary: IDictionary<IBCTBoard> = useSelector(configuredBoardsDictionarySelector);
  const isMedBreakpoint = useMediaQuery(`(min-width:${BreakpointsEnum.md})`);
  const professionTerms: ITaxonomyTerm[] = taxonomyRoot?.[TAXONOMY_PROFESSION_ROOT_ID]?.terms;
  const timeInPracticeTerms: ITaxonomyTerm[] = taxonomyRoot?.[TAXONOMY_TIME_IN_PRACTICE_ROOT_ID]?.terms;
  const practiceAreaTerms: ITaxonomyTerm[] = taxonomyRoot?.[TAXONOMY_PRACTICE_AREA_ROOT_ID]?.terms;
  const deaTerms: ITaxonomyTerm[] = taxonomyRoot?.[TAXONOMY_DEA_REGISTRATION_ROOT_ID]?.terms;
  const rems: IBCTBoard[] = useMemo(
    () => allBoards?.filter(({ type }: IBCTBoard): boolean => type === BoardTypes.REMS),
    [allBoards],
  );
  const mocBoards: IBCTBoard[] = useMemo(
    () => allBoards?.filter(({ type }: IBCTBoard): boolean => type === BoardTypes.CERTIFYING_BOARD),
    [allBoards],
  );
  const remsIds: string[] = rems?.map(({ id }: IBCTBoard): string => id);
  const mocBoardIds: string[] = mocBoards?.map(({ id }: IBCTBoard): string => id);

  const sortedMedicalSchools: ITypeaheadOption[] = useMemo(() => {
    return medicalSchools
      ?.map(({ id, name }: ITaxonomyTerm): ITypeaheadOption => ({ id, label: name }))
      .sort((optionA: ITypeaheadOption, optionB: ITypeaheadOption): number => {
        if (optionA.label > optionB.label) {
          return 1;
        }
        if (optionA.label < optionB.label) {
          return -1;
        }
        return 0;
      });
  }, [medicalSchools]);

  // If Specialty Boards and Rems taxonomy available, construct the options list for Board Provider dropdown
  const boardMocDetails: IDictionary<BoardMocDetails> = activityDetail?.boardMocDetails;
  const boardRemsDetails: IDictionary<IBoardRemsDetails> = activityDetail?.boardRemsDetails;
  const activityMocBoards: string[] = keys(boardMocDetails);
  const activityRemsIds: string[] = keys(boardRemsDetails);
  const mocBoardsList: IFormInputOption[] = buildBoardOptions({
    filterList: [...activityMocBoards],
    moc: mocBoards,
  });
  const remsList: IFormInputOption[] = buildBoardOptions({
    filterList: [...activityRemsIds],
    rems,
  });
  const activityBoardIds: string[] = activityMocBoards?.concat(activityRemsIds);

  const boardCreditOptionsDictionary: IDictionary<ICreditTypeFormOption[]> = generateCreditOptionsDictionary({
    activityBoardIds,
    boardMocDetails,
    modernBoardsDictionary,
  });

  // Construct a Dictionary of Form Input Options for board taxonomy fields
  const deaRegistrationOptions: IFormInputOption[] = deaTerms?.map(mapTermToFormInputOption);
  const practiceAreaOptions: IFormInputOption[] = practiceAreaTerms?.map(mapTermToFormInputOption);
  const professionOptions: IFormInputOption[] = professionTerms?.map(mapTermToFormInputOption);
  const timeInPracticeOptions: IFormInputOption[] = timeInPracticeTerms?.map(mapTermToFormInputOption);

  // Construct Form Input Options for medical schools
  const medicalSchoolOptions: ITypeaheadOption[] = sortedMedicalSchools;

  // Recent Learners for right rail
  const { recentLearners } = learnerState;
  const faqs: ITaxonomyTerm[] = taxonomyRoot?.[TAXONOMY_FAQ_ROOT_ID]?.terms;

  const activityType: ActivityType = activityTypes?.find(
    ({ id }: ActivityType): boolean => id === activityDetail?.typeId,
  );
  const dateFormat = 'MM/DD/YYYY';
  const formattedStartDate: string = moment(activityDetail?.startDate).format(dateFormat);
  const formattedEndDate: string = moment(activityDetail?.endDate).format(dateFormat);
  const formattedClaimDate: string =
    activityDetail?.mocCreditDeadline && moment(activityDetail?.mocCreditDeadline).format(dateFormat);

  const amaCreditValue: number = activityDetail?.credits?.[amaCreditTerm?.id];
  const maxMocCreditValue: number = max(
    Object.values(activityDetail?.boardMocDetails ?? {}).map((m) => m.mocPointsGiven ?? 0),
  );

  const isMissingConfigs: boolean = activityBoardIds?.some(
    (boardId: string): boolean => !modernBoardsDictionary?.[boardId],
  );

  const isLoading: boolean =
    !statesAndProvinces?.length ||
    !medicalSchools?.length ||
    !faqs?.length ||
    !activityTypes?.length ||
    !deaTerms?.length ||
    !practiceAreaTerms?.length ||
    !professionTerms?.length ||
    !timeInPracticeTerms?.length ||
    isMissingConfigs;

  const getAddLearnerPageMetadata = async (): Promise<void> => {
    const metadataPromises: ThunkAction<Promise<void>, unknown, null, PARSAction<unknown>>[] = [];

    // If missing AMA Term Taxonomy, fetch credit terms
    if (!amaCreditTerm) {
      metadataPromises.push(dispatch(getTaxonomyTermById(TAXONOMY_ACTIVITY_CREDIT_ROOT_ID)));
    }

    // If missing FAQs taxonomy, fetch them
    if (!faqs || !faqs?.length) {
      metadataPromises.push(dispatch(getTaxonomyTermById(TAXONOMY_FAQ_ROOT_ID)));
    }

    // If missing Medical School taxonomy, fetch it
    if (!medicalSchools || !medicalSchools?.length) {
      metadataPromises.push(dispatch(getTaxonomyTermById(TAXONOMY_MEDICAL_SCHOOLS_ROOT_ID)));
    }

    // If missing Profession taxonomy, fetch it
    if (!professionTerms || !professionTerms?.length) {
      metadataPromises.push(dispatch(getTaxonomyTermById(TAXONOMY_PROFESSION_ROOT_ID)));
    }

    // If missing Time in Practice taxonomy, fetch it
    if (!timeInPracticeTerms || !timeInPracticeTerms?.length) {
      metadataPromises.push(dispatch(getTaxonomyTermById(TAXONOMY_TIME_IN_PRACTICE_ROOT_ID)));
    }

    // If missing Practice Area taxonomy, fetch it
    if (!practiceAreaTerms || !practiceAreaTerms?.length) {
      metadataPromises.push(dispatch(getTaxonomyTermById(TAXONOMY_PRACTICE_AREA_ROOT_ID)));
    }

    // If missing DEA Registration taxonomy, fetch it
    if (!deaTerms || !deaTerms?.length) {
      metadataPromises.push(dispatch(getTaxonomyTermById(TAXONOMY_DEA_REGISTRATION_ROOT_ID)));
    }

    // If missing states/provinces, fetch them
    if (!statesAndProvinces || !statesAndProvinces?.length) {
      metadataPromises.push(dispatch(getStatesAndProvinces()));
    }

    // If missing Activity Types, fetch them
    if (!activityTypes || !activityTypes?.length) {
      metadataPromises.push(dispatch(getActivityTypes()));
    }

    await Promise.all(metadataPromises);
  };

  // Initial load, get recent learner completions
  useEffect(() => {
    dispatch(getRecentLearnerCompletions(activityIdParam));
    getAddLearnerPageMetadata();
  }, []);

  useEffect(() => {
    if (activityDetail?.id !== activityIdParam) {
      dispatch(getActivity(activityIdParam));
    } else {
      const missingConfigIds: string[] = activityBoardIds?.filter(
        (boardId: string): boolean => !modernBoardsDictionary?.[boardId],
      );

      const metadataPromises: ThunkAction<Promise<void>, unknown, null, PARSAction<unknown>>[] = missingConfigIds
        ? missingConfigIds?.map((boardId: string) => dispatch(getBoardById(boardId)))
        : [];

      Promise.all(metadataPromises).then(noop);
    }
  }, [dispatch, activityDetail, activityIdParam]);

  if (activityDetail?.id !== activityIdParam) {
    return (
      <section className="form-container">
        <LoadingCards count={3} />
      </section>
    );
  }
  return (
    <>
      <section className="form-container">
        <div className={classNames('form-title-container', styles['learner-form-title-container'])}>
          <h4 className="title">
            <div className="eyebrow">Add learners</div>
            Learners for "{activityDetail?.title}"
          </h4>
          <div className={classNames(styles['caption-text'], styles['activity-info'])}>
            <ul className={styles['activity-info-row']}>
              <li>
                <span className={styles.bold}>{activityType?.title}</span>
              </li>
              <li>
                Activity ID: {activityDetail?.printableId} | Internal ID: {activityDetail?.internalId || 'None'}
              </li>
            </ul>
            <ul className={styles['activity-info-row']}>
              <li>
                {formattedStartDate} - {formattedEndDate}
              </li>
              {formattedClaimDate ? <li>Credit Claim Date: {formattedClaimDate}</li> : null}
              {amaCreditValue ? <li>AMA PRA Cat 1 Max Credits™: {amaCreditValue}</li> : null}
              {amaCreditValue && maxMocCreditValue ? <li>MOC Max Credits: {maxMocCreditValue}</li> : null}
            </ul>
          </div>
          {!isMedBreakpoint && (
            <div className={styles['aside-controls']}>
              <Button className={styles['recent-learner-btn']} onClick={() => dispatch(openRail())}>
                <span>Recently Entered Learners</span>
              </Button>
            </div>
          )}
        </div>
        <AddLearnerForm
          boardConfigs={modernBoardsDictionary}
          boardCreditOptions={boardCreditOptionsDictionary}
          deaRegistrationOptions={deaRegistrationOptions}
          isLoadingForm={isLoading}
          medicalSchoolOptions={medicalSchoolOptions}
          mocBoardList={mocBoardsList}
          practiceAreaOptions={practiceAreaOptions}
          professionOptions={professionOptions}
          remsIds={remsIds}
          remsList={remsList}
          specialtyBoardIds={mocBoardIds}
          timeInPracticeOptions={timeInPracticeOptions}
        />
      </section>
      <aside className={classNames('rail-container rail-container--75', { open: isOpen })}>
        {isOpen ? (
          <Button className="close" aria-label="close" onClick={() => dispatch(closeRail())}>
            <CloseIcon />
          </Button>
        ) : null}
        <Faq
          faqTitle="FAQ"
          faqs={faqs}
          faqArea="Completions"
          handleClose={() => dispatch(closeRail())}
          rollupOrganizationEnum={activityDetail?.rollupOrganizationEnum}
        />
        <h5 className={styles['recent-learners-header']}>Recently Entered Learners</h5>
        <div className={styles['recent-learners-rail']}>
          <ul className={styles['recent-learners']}>
            {recentLearners?.length ? (
              recentLearners.map(
                (recentLearner: ILearnerCompletion, idx: number): ReactElement => {
                  const { id, completionDate, universalLearnerId, firstName, lastName } = recentLearner;

                  return (
                    <li
                      className={classNames(styles['recent-learner-card'], {
                        [styles['recent-learner-card-first']]: idx === 0,
                      })}
                      key={idx}
                    >
                      <RecentLearnerCard
                        id={id}
                        activityId={activityIdParam}
                        firstName={firstName}
                        lastName={lastName}
                        learnerCompletionDate={completionDate}
                        learnerId={universalLearnerId}
                      />
                    </li>
                  );
                },
              )
            ) : (
              <li className={styles['recent-learner-card-first']}>None</li>
            )}
          </ul>
        </div>
      </aside>
    </>
  );
};
