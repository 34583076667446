import { IAttestation } from 'core/models';
import { AppState } from 'store';

/**
 * @function attestationSelector
 * @param attestationState
 * @description - attestation information
 * @returns PARS.Models.Attestation
 */
export const attestationSelector = ({ attestationState }: AppState): IAttestation => attestationState?.attestation;

/**
 * @function isAttestLoadingSelector
 * @param attestationState
 * @description - is attest loading flag
 * @returns boolean
 */
export const isAttestLoadingSelector = ({ attestationState }: AppState): boolean => attestationState?.isAttestLoading;
