import { KeyboardBackspaceRounded } from '@material-ui/icons';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

// Store.
import { IDashboardLink } from 'store/dashboard/types';
import { CommendationCriteriaModal } from './CommendationCriteriaModal';

// Styles.
import styles from './DashboardFooterSection.module.scss';

interface IDashboardFooterSectionProps {
  links: IDashboardLink[];
}

export const DashboardFooterSection = (props: IDashboardFooterSectionProps): ReactElement => {
  const { links = [] } = props;
  const [modal, setModal] = useState<ReactElement>();

  const DashboardLinkModals = useMemo(
    () => ({
      '/commendation-criteria': <CommendationCriteriaModal isOpen onClose={() => setModal(undefined)} />,
    }),
    [],
  );

  const handleLinkClick = useCallback(
    (e) => {
      const href = e.target.getAttribute('href');
      const DashboardModal = DashboardLinkModals[href];

      if (DashboardModal) {
        e.preventDefault();
        setModal(DashboardModal);
      }
    },
    [DashboardLinkModals],
  );

  return (
    links.length > 0 && (
      <footer className={styles.footer}>
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              <Link className={styles.link} to={link.href} onClick={handleLinkClick}>
                {link.label}
                <KeyboardBackspaceRounded />
              </Link>
            </li>
          ))}
        </ul>
        {modal}
      </footer>
    )
  );
};
