import { createSelector } from 'reselect';

// Core + Store
import { AppState } from 'store';
import { IBoardSlim, IDictionary } from 'core/models';
import { IBCTBoard } from 'layouts/pages/bct/types';

export const boardSelector = ({ boardState }: AppState): IBoardSlim[] => boardState.boards;

export const configuredBoardsSelector = ({ boardState }: AppState): IBCTBoard[] => boardState.configuredBoards;

export const configuredBoardsDictionarySelector = createSelector(configuredBoardsSelector, (boards: IBCTBoard[]) => {
  const configDictionary: IDictionary<IBCTBoard> = {};
  boards?.forEach((board: IBCTBoard) => (configDictionary[board.id] = board));
  return configDictionary;
});
