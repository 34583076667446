import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';

// Components
import { NoResultsCard } from 'components/NoResultsCard';
import { BoardListTable } from './BoardListTable';
import { LoadingCards } from 'components/LoadingCard';

// Core + Store
import { getBoardList } from 'store/bct/actions';
import { boardListSelector, isLoadingBCTSelector } from 'store/bct/selectors';

// Types
import { IBoardSlim } from 'core/models';

export const BoardListPage = (): ReactElement => {
  const dispatch = useDispatch();

  // Selectors.
  const boardList: IBoardSlim[] = useSelector(boardListSelector);
  const isLoading: boolean = useSelector(isLoadingBCTSelector);

  useEffect(() => {
    if (!boardList) {
      dispatch(getBoardList());
    }
  }, [boardList, dispatch]);

  // Show this when we're waiting for results.
  const isPending = isLoading && <LoadingCards />;

  // Show this when we don't have any results.
  const noResults: ReactElement = !!boardList?.length && <NoResultsCard />;

  // Show this when we have results.
  const hasResults = !!boardList?.length && <BoardListTable results={boardList} />;

  return (
    <Grid component="section" className="summary-container">
      <Grid item xs={12} alignContent="space-between">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <h1 className="title">Board List</h1>
          {hasResults && `${boardList.length} results`}
        </Box>
      </Grid>

      {/* Render the main content. */}
      <Grid item xs={12}>
        {hasResults || isPending || noResults}
      </Grid>
    </Grid>
  );
};
