import { Action, AnyAction } from 'redux';
import { AxiosError } from 'axios';
import { isEqual } from 'lodash';
import { LearnerService } from 'services/LearnerService';
import {
  ADD_LEARNER_COMPLETION,
  ADD_LEARNER_COMPLETION_FAILURE,
  ADD_LEARNER_COMPLETION_SUCCESS,
  BATCH_LEARNER,
  BATCH_LEARNER_CLEAR,
  BATCH_LEARNER_FAILURE,
  BATCH_LEARNER_PROGRESS,
  BATCH_LEARNER_SUCCESS,
  BULK_DELETE_LEARNERS,
  BULK_DELETE_LEARNERS_FAILURE,
  BULK_DELETE_LEARNERS_SUCCESS,
  CREATE_LEARNER,
  CREATE_LEARNER_FAILURE,
  CREATE_LEARNER_SUCCESS,
  DOWNLOAD_LEARNER_ACTIVITIES,
  DOWNLOAD_LEARNER_ACTIVITIES_FAILURE,
  DOWNLOAD_LEARNER_ACTIVITIES_SUCCESS,
  EXPORT_LEARNER_ACTIVITIES,
  EXPORT_LEARNER_SEARCH,
  EXPORT_LEARNER_SEARCH_FAILURE,
  EXPORT_LEARNER_SEARCH_SUCCESS,
  EXPORT_LEARNERS,
  GET_LEARNER_ACTIVITIES,
  GET_LEARNER_ACTIVITIES_FAILURE,
  GET_LEARNER_ACTIVITIES_SUCCESS,
  GET_LEARNER_COMPLETION,
  GET_LEARNER_COMPLETION_FAILURE,
  GET_LEARNER_COMPLETION_SUCCESS,
  GET_LEARNER_SEARCH,
  GET_LEARNER_SEARCH_FAILURE,
  GET_LEARNER_SEARCH_SUCCESS,
  GET_LEARNER_TYPES,
  GET_LEARNER_TYPES_SUCCESS,
  GET_RECENT_LEARNER_COMPLETIONS,
  GET_RECENT_LEARNER_COMPLETIONS_FAILURE,
  GET_RECENT_LEARNER_COMPLETIONS_SUCCESS,
  MATCH_LEARNER_BOARDS,
  MATCH_LEARNERS,
  MATCH_LEARNERS_FAILURE,
  MATCH_LEARNERS_SUCCESS,
  PATCH_LEARNER,
  PATCH_LEARNER_FAILURE,
  PATCH_LEARNER_SUCCESS,
  RESET_LEARNER_COMPLETION,
  RESET_LEARNER_MATCHES,
  RESET_LEARNER_SEARCH_RESPONSE,
  UPDATE_LEARNER,
  UPDATE_LEARNER_ACTIVITIES_PAGINATION_STATE,
  UPDATE_LEARNER_COMPLETION,
  UPDATE_LEARNER_COMPLETION_FAILURE,
  UPDATE_LEARNER_COMPLETION_SUCCESS,
  UPDATE_LEARNER_FAILURE,
  UPDATE_LEARNER_SEARCH,
  UPDATE_LEARNER_SEARCH_ACTIVITIES,
  UPDATE_LEARNER_SEARCH_STATE_PROPS,
  UPDATE_LEARNER_SUCCESS,
  BATCH_LEARNER_VALIDATION,
  BATCH_LEARNER_VALIDATION_PROGRESS,
  BATCH_LEARNER_VALIDATION_SUCCESS,
  BATCH_LEARNER_VALIDATION_FAILURE,
  BATCH_LEARNER_VALIDATION_CLEAR,
  QUEUE_MOC_LEARNER,
  PROCESS_BOARD_CREDIT,
  ADMIN_DELETE_LEARNER,
} from './types';
import { ThunkAction } from 'redux-thunk';
import { AppState, store } from 'store';
import {
  CreateLearnerRequest,
  IArrayBufferDownload,
  IActivityLearnerSearchRequest,
  IActivityLearnerSearchResponse,
  IActivityLearnerSearchStateProps,
  IActivityLearnerSummarySearch,
  ILearnerCompletion,
  IBatchActivityRequest,
  IJsonPatchDocumentOperation,
  ILearnerSearchRequest,
  ILearnerMatchingModel,
  Learner,
  LearnerType,
  PARSAction,
  UpdateLearnerRequest,
  ILearnerMatchResponse,
  IActivityLearnerSummarySearchResponse,
  ICompletionBoard,
  ILearnerSearchActivity,
  CompletionSourceEnum,
  IActivityLearnerCompletion,
  ILearnerExportRequest,
  IQueueMocLearnerRequest,
  IProcessBoardCreditRequest,
  IAdminDeleteLearnerChildCompletionRequest,
} from 'core/models';
import { popToast } from '../toast/actions';
import {
  errorToastOptions,
  errorStickyToastOptions,
  infoToastOptions,
  successToastOptions,
  warningToastOptions,
} from '../toast/constants';
import { ActivityService } from 'services/ActivityService';
import { closeModal, openModal } from '../modal/actions';
import { ServerErrorCodes } from 'core/enums';
import {
  DEFAULT_RESULTS_PER_PAGE,
  MESSAGE_DEFAULT_FILE_HAS_TOO_MANY_ROWS_TO_PROCESS,
  MESSAGE_DEFAULT_UPLOAD_COMPLETE,
  TAGS_ENUM,
  TAXONOMY_BATCH_MESSAGES_ID,
} from 'core/constants';
import { handleServerError } from '../../globals/utils/handleServerError';
import { getActivities } from '../activity/actions';
import { TaxonomyService } from 'services/TaxonomyService';
import { filterTaxonomyByRollupOrganizationEnum } from 'globals/utils/filterTaxonomyByOrg';

// action creators
export const createLearnerAction = (requests: CreateLearnerRequest[]): AnyAction => ({
  payload: requests,
  type: CREATE_LEARNER,
});

export const createLearnerSuccessAction = (learner: Learner[]): AnyAction => ({
  payload: learner,
  type: CREATE_LEARNER_SUCCESS,
});

export const createLearnerFailureAction = (error: Error): AnyAction => ({
  payload: error,
  type: CREATE_LEARNER_FAILURE,
});

export const batchLearnerClearAction = (): Action => ({
  type: BATCH_LEARNER_CLEAR,
});

export const batchLearnerAction = (file: { file: File }): AnyAction => ({
  payload: file,
  type: BATCH_LEARNER,
});

export const batchLearnerProgressAction = (percentage: number): AnyAction => ({
  payload: percentage,
  type: BATCH_LEARNER_PROGRESS,
});

export const batchLearnerSuccessAction = (): Action => ({
  type: BATCH_LEARNER_SUCCESS,
});

export const batchLearnerFailureAction = (errorMessage: string): AnyAction => ({
  error: errorMessage,
  type: BATCH_LEARNER_FAILURE,
});

export const batchLearnerValidationClearAction = (): Action => ({
  type: BATCH_LEARNER_VALIDATION_CLEAR,
});

export const batchLearnerValidationAction = (file: { file: File }): AnyAction => ({
  payload: file,
  type: BATCH_LEARNER_VALIDATION,
});

export const batchLearnerValidationProgressAction = (percentage: number): AnyAction => ({
  payload: percentage,
  type: BATCH_LEARNER_VALIDATION_PROGRESS,
});

export const batchLearnerValidationSuccessAction = (): Action => ({
  type: BATCH_LEARNER_VALIDATION_SUCCESS,
});

export const batchLearnerValidationFailureAction = (errorMessage: string): AnyAction => ({
  error: errorMessage,
  type: BATCH_LEARNER_VALIDATION_FAILURE,
});

export const getLearnerTypesAction = (): Action => ({
  type: GET_LEARNER_TYPES,
});

export const getLearnerTypesSuccessAction = (activityTypes: LearnerType[]): AnyAction => ({
  payload: activityTypes,
  type: GET_LEARNER_TYPES_SUCCESS,
});

export const patchLearnerAction = (id: string, request: IJsonPatchDocumentOperation[]): AnyAction => ({
  payload: { id, request },
  type: PATCH_LEARNER,
});

export const patchLearnerSuccessAction = (): AnyAction => ({
  payload: undefined,
  type: PATCH_LEARNER_SUCCESS,
});

export const patchLearnerFailureAction = (error: Error): AnyAction => ({
  payload: error,
  type: PATCH_LEARNER_FAILURE,
});

export const updateLearnerAction = (request: UpdateLearnerRequest): AnyAction => ({
  payload: request,
  type: UPDATE_LEARNER,
});

export const updateLearnerSuccessAction = (): AnyAction => ({
  payload: undefined,
  type: UPDATE_LEARNER_SUCCESS,
});

export const updateLearnerFailureAction = (error: Error): AnyAction => ({
  payload: error,
  type: UPDATE_LEARNER_FAILURE,
});

export const updateLearnerActivitiesSearch = (learnerSearchActivities: IActivityLearnerSummarySearch): AnyAction => ({
  payload: learnerSearchActivities,
  type: UPDATE_LEARNER_SEARCH_ACTIVITIES,
});

export const getLearnerActivitiesAction = (learnerActivities: IActivityLearnerSummarySearch): AnyAction => ({
  payload: learnerActivities,
  type: GET_LEARNER_ACTIVITIES,
});

export const getLearnerActivitiesSuccess = (searchResponse: IActivityLearnerSummarySearchResponse): AnyAction => ({
  payload: searchResponse,
  type: GET_LEARNER_ACTIVITIES_SUCCESS,
});

export const getLearnerActivitiesFailure = (errorMessage: string): AnyAction => ({
  payload: errorMessage,
  type: GET_LEARNER_ACTIVITIES_FAILURE,
});

export const exportLearnerActivitiesAction = (): Action => ({
  type: EXPORT_LEARNER_ACTIVITIES,
});

export const exportLearnersAction = (): Action => ({
  type: EXPORT_LEARNERS,
});

export const updateLearnerActivitiesPaginationState = (payload: IActivityLearnerSearchStateProps): AnyAction => ({
  payload,
  type: UPDATE_LEARNER_ACTIVITIES_PAGINATION_STATE,
});

export const downloadLearnerActivitiesAction = (id: string): AnyAction => ({
  payload: id,
  type: DOWNLOAD_LEARNER_ACTIVITIES,
});

export const downloadLearnerActivitiesSuccessAction = (file: IArrayBufferDownload): AnyAction => ({
  payload: file,
  type: DOWNLOAD_LEARNER_ACTIVITIES_SUCCESS,
});

export const downloadLearnerActivitiesFailureAction = (message: string): AnyAction => ({
  payload: message,
  type: DOWNLOAD_LEARNER_ACTIVITIES_FAILURE,
});

export const getLearnerSearchAction = (request: ILearnerSearchRequest): AnyAction => ({
  payload: request,
  type: GET_LEARNER_SEARCH,
});

export const getLearnerSearchSuccessAction = (learner: IActivityLearnerSearchResponse): AnyAction => ({
  payload: learner,
  type: GET_LEARNER_SEARCH_SUCCESS,
});

export const getLearnerSearchFailureAction = (errorMessage: string): AnyAction => ({
  payload: errorMessage,
  type: GET_LEARNER_SEARCH_FAILURE,
});

export const resetLearnerCompletion = (): AnyAction => ({
  type: RESET_LEARNER_COMPLETION,
});

export const resetLearnerSearchResponse = (): Action => ({
  type: RESET_LEARNER_SEARCH_RESPONSE,
});

export const updateLearnerSearchStateProps = (payload: IActivityLearnerSearchStateProps): AnyAction => ({
  payload,
  type: UPDATE_LEARNER_SEARCH_STATE_PROPS,
});

export const updateLearnerSearch = (learnerSearch: IActivityLearnerSummarySearch): AnyAction => ({
  payload: learnerSearch,
  type: UPDATE_LEARNER_SEARCH,
});

export const matchLearnersAction = (payload: ILearnerMatchingModel): AnyAction => ({
  payload,
  type: MATCH_LEARNERS,
});

export const matchLearnersSuccessAction = (payload: ILearnerMatchResponse[]): AnyAction => ({
  payload,
  type: MATCH_LEARNERS_SUCCESS,
});

export const matchLearnersFailureAction = (errorMessage: string): AnyAction => ({
  payload: errorMessage,
  type: MATCH_LEARNERS_FAILURE,
});

export const matchLearnerBoardsAction = (payload: ILearnerMatchingModel): AnyAction => ({
  payload,
  type: MATCH_LEARNER_BOARDS,
});

export const resetLearnerMatches = (): AnyAction => ({
  type: RESET_LEARNER_MATCHES,
});

export const addLearnerCompletionAction = (): AnyAction => ({
  type: ADD_LEARNER_COMPLETION,
});

export const addLearnerCompletionSuccessAction = (): AnyAction => ({
  type: ADD_LEARNER_COMPLETION_SUCCESS,
});

export const addLearnerCompletionFailureAction = (payload?: AxiosError): AnyAction => ({
  payload,
  type: ADD_LEARNER_COMPLETION_FAILURE,
});

export const getRecentLearnerCompletionsAction = (): Action => ({
  type: GET_RECENT_LEARNER_COMPLETIONS,
});

export const getRecentLearnerCompletionsSuccessAction = (payload: ILearnerCompletion[]): AnyAction => ({
  payload,
  type: GET_RECENT_LEARNER_COMPLETIONS_SUCCESS,
});

export const getRecentLearnerCompletionsFailureAction = (payload: string): AnyAction => ({
  payload,
  type: GET_RECENT_LEARNER_COMPLETIONS_FAILURE,
});

export const getLearnerCompletionAction = (): AnyAction => ({
  type: GET_LEARNER_COMPLETION,
});

export const getLearnerCompletionSuccessAction = (payload: ILearnerCompletion): AnyAction => ({
  payload,
  type: GET_LEARNER_COMPLETION_SUCCESS,
});

export const getLearnerCompletionFailureAction = (payload: string): AnyAction => ({
  payload,
  type: GET_LEARNER_COMPLETION_FAILURE,
});

export const updateLearnerCompletionAction = (): Action => ({
  type: UPDATE_LEARNER_COMPLETION,
});

export const updateLearnerCompletionSuccessAction = (): Action => ({
  type: UPDATE_LEARNER_COMPLETION_SUCCESS,
});

export const updateLearnerCompletionFailureAction = (payload: string): AnyAction => ({
  payload,
  type: UPDATE_LEARNER_COMPLETION_FAILURE,
});

export const exportLearnerSearchAction = (): Action => ({
  type: EXPORT_LEARNER_SEARCH,
});

export const exportLearnerSearchSuccessAction = (): Action => ({
  type: EXPORT_LEARNER_SEARCH_SUCCESS,
});

export const exportLearnerSearchFailureAction = (payload: string): AnyAction => ({
  payload,
  type: EXPORT_LEARNER_SEARCH_FAILURE,
});

export const downloadLearnerSearchAction = (id: string): AnyAction => ({
  payload: id,
  type: DOWNLOAD_LEARNER_ACTIVITIES,
});

export const downloadLearnerSearchSuccessAction = (file: IArrayBufferDownload): AnyAction => ({
  payload: file,
  type: DOWNLOAD_LEARNER_ACTIVITIES_SUCCESS,
});

export const downloadLearnerSearchFailureAction = (message: string): AnyAction => ({
  payload: message,
  type: DOWNLOAD_LEARNER_ACTIVITIES_FAILURE,
});

export const bulkDeleteLearnersAction = (): AnyAction => ({
  type: BULK_DELETE_LEARNERS,
});

export const bulkDeleteLearnersSuccessAction = (): AnyAction => ({
  type: BULK_DELETE_LEARNERS_SUCCESS,
});
export const bulkDeleteLearnersFailureAction = (error): AnyAction => ({
  error,
  type: BULK_DELETE_LEARNERS_FAILURE,
});

export const queueMocLearnerAction = (request: IQueueMocLearnerRequest): AnyAction => ({
  payload: request,
  type: QUEUE_MOC_LEARNER,
});

export const processBoardCreditAction = (request: IProcessBoardCreditRequest): AnyAction => ({
  payload: request,
  type: PROCESS_BOARD_CREDIT,
});

export const adminDeleteLearnerChildCompletionAction = (
  request: IAdminDeleteLearnerChildCompletionRequest,
): AnyAction => {
  return {
    payload: request,
    type: ADMIN_DELETE_LEARNER,
  };
};

// thunk actions
export const createLearner = (
  requests: CreateLearnerRequest[],
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(createLearnerAction(requests));
  try {
    const learner: Learner[] = await LearnerService.create(requests);
    await dispatch(createLearnerSuccessAction(learner));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'createLearner' });
    await dispatch(createLearnerFailureAction(error));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const getLearnerSearch = (
  payload?: IActivityLearnerSummarySearch,
  shouldResetPageReset = true,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  const { learnerSearchRequest } = store.getState().learnerState;

  const newSearchRequest: IActivityLearnerSummarySearch = { ...learnerSearchRequest, ...payload };

  // Always reset to page 1 except from an explicit pagination call.
  if (shouldResetPageReset) {
    await dispatch(onUpdateLearnerSearchStateProps({ ...newSearchRequest, page: 1 }));
    return;
  }
  await dispatch(getLearnerSearchAction({ ...newSearchRequest, ...payload }));
  await dispatch(popToast({ ...infoToastOptions, message: <>Searching...</> }));

  try {
    const learnerActivities = await LearnerService.getLearnerSearch({ ...newSearchRequest, ...payload });
    await dispatch(getLearnerSearchSuccessAction(learnerActivities));
    await dispatch(popToast({ ...successToastOptions, message: <>Learner activity search success</> }));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getLearnerSearch' });
    await getLearnerSearchFailureAction(errorMessage);
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const bulkDeleteLearners = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
  getState,
) => {
  const { learnerSearchRequest, learnerSearchStateProps } = getState()?.learnerState || {};

  // The boardId is the last item in the `route` key.
  const payload = learnerSearchStateProps?.selectedLearners?.map(
    ({
      stateAbbreviation,
      completionId,
      completionSource,
      completionIdentifier,
      route,
    }: ILearnerSearchActivity): IActivityLearnerCompletion => ({
      boardId: completionSource === CompletionSourceEnum.BOARD ? route.split('/').pop() : null,
      completionId,
      remsId: completionSource === CompletionSourceEnum.REMS ? route.split('/').pop() : null,
      stateAbbreviation: completionSource === CompletionSourceEnum.STATE ? stateAbbreviation : null,
      completionSource,
      completionIdentifier,
    }),
  );

  dispatch(bulkDeleteLearnersAction());

  try {
    await LearnerService.bulkDeleteLearners({ activityId: learnerSearchRequest?.activityGuid, payload });
    dispatch(bulkDeleteLearnersSuccessAction());
    // Update the activities query.
    await dispatch(getActivities());
    await dispatch(getLearnerSearch());
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'bulkDeleteLearners' });
    dispatch(bulkDeleteLearnersFailureAction(errorMessage));
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const batchLearner = ({
  file,
  shouldForce,
  rollupOrganizationEnum,
}: IBatchActivityRequest): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  // Null check.
  if (!file) {
    return dispatch(popToast({ ...errorToastOptions, message: <>No file found, please try again.</> }));
  }
  // Tell the user we started the upload.
  dispatch(popToast({ ...infoToastOptions, message: <>Uploading...</> }));

  const batchMessages = await new TaxonomyService().getByIdAsync(TAXONOMY_BATCH_MESSAGES_ID);
  const terms = filterTaxonomyByRollupOrganizationEnum(batchMessages?.terms, rollupOrganizationEnum);

  // Start the upload.
  dispatch(batchLearnerAction(file));
  try {
    await LearnerService.batch(file, shouldForce);
    // Tell the user that the file successfully uploaded.
    dispatch(batchLearnerSuccessAction());

    const successMessage =
      terms?.filter((i) => i.tag === TAGS_ENUM.BATCH__MESSAGES__AFTER_UPLOAD)[0]?.description ||
      MESSAGE_DEFAULT_UPLOAD_COMPLETE;
    dispatch(popToast({ ...infoToastOptions, autoHideDuration: undefined, message: <>{successMessage}</> }));

    // Close the modal when done.
    dispatch(closeModal());
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'batchLearner' });
    console.warn('batch learner upload error: ', errorMessage);
    // Warn the user that a duplicate file was detected - 409 server error.
    if (error?.response?.data?.errorCode === ServerErrorCodes.CONFLICT__PROVIDER_FILE) {
      dispatch(batchLearnerFailureAction(ServerErrorCodes.CONFLICT__PROVIDER_FILE));
      dispatch(popToast({ ...warningToastOptions, message: <>We have detected a duplicate file.</> }));
      // Open the modal for them.
      dispatch(openModal());
    } else if (error?.response?.data?.errorCode === ServerErrorCodes.BAD_REQUEST__FILE__RECORD_COUNT__INVALID) {
      dispatch(batchLearnerFailureAction(ServerErrorCodes.BAD_REQUEST__FILE__RECORD_COUNT__INVALID));
      const fileTooBigMessage =
        terms?.filter((i) => i.tag === TAGS_ENUM.BATCH__MESSAGES__FILE_TOO_BIG)[0]?.description ||
        MESSAGE_DEFAULT_FILE_HAS_TOO_MANY_ROWS_TO_PROCESS;
      dispatch(popToast({ ...errorStickyToastOptions, message: <>{fileTooBigMessage}</> }));
    } else {
      // Tell the user we have a failure.
      dispatch(batchLearnerFailureAction(errorMessage));
      dispatch(
        popToast({
          ...errorToastOptions,
          message: <>{errorMessage ?? 'Something went wrong. Please try again later.'}</>,
        }),
      );
    }
  }
};

export const batchLearnerValidation = ({
  file,
  rollupOrganizationEnum,
}: IBatchActivityRequest): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  // Null check.
  if (!file) {
    return dispatch(popToast({ ...errorToastOptions, message: <>No file found, please try again.</> }));
  }
  // Tell the user we started the upload.
  dispatch(popToast({ ...infoToastOptions, message: <>Uploading...</> }));

  const batchMessages = await new TaxonomyService().getByIdAsync(TAXONOMY_BATCH_MESSAGES_ID);
  const terms = filterTaxonomyByRollupOrganizationEnum(batchMessages?.terms, rollupOrganizationEnum);

  // Start the upload.
  dispatch(batchLearnerAction(file));
  try {
    await LearnerService.learnervalidationbatch(file);
    // Tell the user that the file successfully uploaded.
    dispatch(batchLearnerSuccessAction());

    const successMessage =
      terms?.filter((i) => i.tag === TAGS_ENUM.BATCH__MESSAGES__AFTER_UPLOAD)[0]?.description ||
      MESSAGE_DEFAULT_UPLOAD_COMPLETE;
    dispatch(popToast({ ...infoToastOptions, autoHideDuration: undefined, message: <>{successMessage}</> }));

    // Close the modal when done.
    dispatch(closeModal());
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'batchLearner' });
    console.warn('batch learner validation upload error: ', errorMessage);
    // Warn the user that a duplicate file was detected - 409 server error.
    if (error?.response?.data?.errorCode === ServerErrorCodes.CONFLICT__PROVIDER_FILE) {
      dispatch(batchLearnerValidationFailureAction(ServerErrorCodes.CONFLICT__PROVIDER_FILE));
      dispatch(popToast({ ...warningToastOptions, message: <>We have detected a duplicate file.</> }));
      // Open the modal for them.
      dispatch(openModal());
    } else if (error?.response?.data?.errorCode === ServerErrorCodes.BAD_REQUEST__FILE__RECORD_COUNT__INVALID) {
      dispatch(batchLearnerFailureAction(ServerErrorCodes.BAD_REQUEST__FILE__RECORD_COUNT__INVALID));
      const fileTooBigMessage =
        terms?.filter((i) => i.tag === TAGS_ENUM.BATCH__MESSAGES__FILE_TOO_BIG)[0]?.description ||
        MESSAGE_DEFAULT_FILE_HAS_TOO_MANY_ROWS_TO_PROCESS;
      dispatch(popToast({ ...errorStickyToastOptions, message: <>{fileTooBigMessage}</> }));
    } else {
      // Tell the user we have a failure.
      dispatch(batchLearnerFailureAction(errorMessage));
      dispatch(
        popToast({
          ...errorToastOptions,
          message: <>{errorMessage ?? 'Something went wrong. Please try again later.'}</>,
        }),
      );
    }
  }
};

export const getLearnerTypes = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(getLearnerTypesAction());
  try {
    const activityTypes = await LearnerService.getTypes();
    await dispatch(getLearnerTypesSuccessAction(activityTypes));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getLearnerTypes' });
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const patchLearner = (
  id: string,
  request: IJsonPatchDocumentOperation[],
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(patchLearnerAction(id, request));
  try {
    await LearnerService.patch(id, request);
    await dispatch(patchLearnerSuccessAction());
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'patchLearner' });
    await dispatch(patchLearnerFailureAction(error));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const updateLearner = (
  request: UpdateLearnerRequest,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(updateLearnerAction(request));
  try {
    await LearnerService.update(request);
    await dispatch(updateLearnerSuccessAction());
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'updateLearner' });
    await dispatch(updateLearnerFailureAction(error));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const getLearnerActivities = (
  payload?: IActivityLearnerSummarySearch,
  shouldResetPageReset = true,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  const { learnerSummarySearchRequest } = store.getState().learnerState;

  const newSearchRequest: IActivityLearnerSummarySearch = { ...learnerSummarySearchRequest, ...payload };

  // Always reset to page 1 except from an explicit pagination call.
  if (shouldResetPageReset) {
    await dispatch(onUpdateLearnerActivitiesPaginationState({ ...newSearchRequest, page: 1 }));
    return;
  }
  await dispatch(getLearnerActivitiesAction(newSearchRequest));
  await dispatch(popToast({ ...infoToastOptions, message: <>Searching...</> }));

  try {
    const learnerActivities = await LearnerService.getLearnerSummarySearch(newSearchRequest);
    await dispatch(getLearnerActivitiesSuccess(learnerActivities));
    await dispatch(popToast({ ...successToastOptions, message: <>Learner activity search success</> }));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getLearnerActivities' });
    await getLearnerActivitiesFailure(errorMessage);
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const onUpdateLearnerActivitiesPaginationState = (
  props: IActivityLearnerSearchStateProps,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch, getState) => {
  const { learnerSummarySearchRequest } = getState().learnerState;

  await dispatch(updateLearnerActivitiesPaginationState({ ...learnerSummarySearchRequest, ...props }));
  await dispatch(
    getLearnerActivities(
      {
        ...props,
        skip: (learnerSummarySearchRequest.top || DEFAULT_RESULTS_PER_PAGE) * (props.page - 1),
      },
      false,
    ),
  );
};

export const exportAllLearnerActivities = (
  payload: ILearnerSearchRequest,
): ThunkAction<void, AppState, null, PARSAction> => (dispatch) => {
  try {
    dispatch(exportLearnerActivitiesAction());
    LearnerService.export(payload);
    dispatch(
      popToast({
        ...successToastOptions,
        message: <>An email will be sent to you with a link to download your exported activites.</>,
      }),
    );
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'exportAllLearnerActivities' });
    dispatch(
      popToast({
        ...errorToastOptions,
        message: <>{errorMessage}</>,
      }),
    );
  }
};

export const exportAllLearners = (payload: ILearnerExportRequest): ThunkAction<void, AppState, null, PARSAction> => (
  dispatch,
) => {
  try {
    dispatch(exportLearnersAction());
    LearnerService.exportLearners(payload);
    dispatch(
      popToast({
        ...successToastOptions,
        message: <>An email will be sent to you with a link to download your exported learners.</>,
      }),
    );
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'exportAllLearners' });
    dispatch(
      popToast({
        ...errorToastOptions,
        message: <>{errorMessage}</>,
      }),
    );
  }
};

export const onUpdateLearnerSearchStateProps = (
  props: IActivityLearnerSearchStateProps,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch, getState) => {
  const { learnerSearchRequest } = getState().learnerState;

  await dispatch(updateLearnerSearchStateProps({ ...learnerSearchRequest, ...props }));

  await dispatch(
    getLearnerSearch(
      {
        ...props,
        skip: (learnerSearchRequest.top || DEFAULT_RESULTS_PER_PAGE) * (props.page - 1),
      },
      false,
    ),
  );
};

export const onExportLearnerActivities = (
  payload: IActivityLearnerSearchRequest,
): ThunkAction<void, AppState, null, PARSAction> => (dispatch) => {
  try {
    dispatch(exportLearnerActivitiesAction());
    LearnerService.export(payload);
    dispatch(
      popToast({
        ...successToastOptions,
        message: <>An email will be sent to you with a link to download your exported activities.</>,
      }),
    );
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'onExportLearnerActivities' });
    dispatch(
      popToast({
        ...errorToastOptions,
        message: <>{errorMessage}</>,
      }),
    );
  }
};

export const onDownloadLearnerActivities = (id: string): ThunkAction<void, AppState, null, PARSAction> => async (
  dispatch,
) => {
  popToast({ ...infoToastOptions, message: <>Downloading Learner Activities...</> });
  await dispatch(downloadLearnerActivitiesAction(id));
  try {
    const downloadedFile = await ActivityService.download(id);
    await dispatch(downloadLearnerActivitiesSuccessAction(downloadedFile));
    popToast({ ...successToastOptions, message: <>Successfully downloaded</> });
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'onDownloadLearnerActivities' });
    await dispatch(downloadLearnerActivitiesFailureAction(errorMessage));
    await dispatch(
      popToast({
        ...errorToastOptions,
        message: <>This report was generated by a different user and is not accessible.</>,
      }),
    );
  }
};

export const matchLearners = (
  matchLearnersPayload: ILearnerMatchingModel,
): ThunkAction<void, AppState, null, PARSAction> => async (dispatch, getState) => {
  const { learnerState } = getState();
  const { isMatchingLearners, matchLearnersQuery } = learnerState;
  // First check for existing API calls or unchanged match payloads
  if (isMatchingLearners || isEqual(matchLearnersQuery, matchLearnersPayload)) {
    return;
  }
  await dispatch(matchLearnersAction(matchLearnersPayload));
  try {
    const learnerMatches = await LearnerService.matchLearners(matchLearnersPayload);
    await dispatch(matchLearnersSuccessAction(learnerMatches || []));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'matchLearners' });
    await dispatch(matchLearnersFailureAction(errorMessage));
  }
};

export const addLearnerCompletion = (
  payload: ILearnerCompletion,
  activityId: string,
): ThunkAction<void, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(addLearnerCompletionAction());
  try {
    await LearnerService.createLearnerCompletion(payload, activityId);
    await dispatch(addLearnerCompletionSuccessAction());
    dispatch(popToast({ ...successToastOptions, message: <>Learner added successfully</> }));
  } catch (error) {
    const { clientErrorMessage } = handleServerError({ error, thunkName: 'addLearnerCompletion' });
    await dispatch(addLearnerCompletionFailureAction(error));
    dispatch(popToast({ ...errorToastOptions, message: <>{clientErrorMessage}</> }));
  }
};

export const getRecentLearnerCompletions = (
  activityId: string,
): ThunkAction<void, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(getRecentLearnerCompletionsAction());
  try {
    const recentCompletions: ILearnerCompletion[] = await LearnerService.getRecentLearnerCompletions(activityId);
    await dispatch(getRecentLearnerCompletionsSuccessAction(recentCompletions));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getRecentLearnerCompletions' });
    await dispatch(getRecentLearnerCompletionsFailureAction(errorMessage));
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const getLearnerCompletion = (
  activityId: string,
  completionId: string,
): ThunkAction<void, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(getLearnerCompletionAction());
  try {
    const response: ICompletionBoard = await LearnerService.getLearnerCompletion(activityId, completionId);
    await dispatch(getLearnerCompletionSuccessAction(response));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getLearnerCompletion' });
    await dispatch(getLearnerCompletionFailureAction(errorMessage));
  }
};

export const updateLearnerCompletion = ({
  route,
  payload,
}: {
  route: string;
  payload: IJsonPatchDocumentOperation[];
}): ThunkAction<void, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(updateLearnerCompletionAction());
  try {
    await LearnerService.patchLearnerCompletion(route, payload);
    await dispatch(updateLearnerCompletionSuccessAction());
    await dispatch(getLearnerSearch());
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'updateLearnerCompletion' });
    await dispatch(updateLearnerCompletionFailureAction(errorMessage));
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const exportLearnerSearch = (): ThunkAction<void, AppState, null, PARSAction> => async (dispatch, getState) => {
  const { learnerSearchRequest } = getState().learnerState;
  await dispatch(exportLearnerSearchAction());
  try {
    await LearnerService.exportLearners(learnerSearchRequest);
    await dispatch(exportLearnerSearchSuccessAction());
    dispatch(popToast({ ...successToastOptions, message: <>Learner export in process...</> }));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'exportLearnerSearch' });
    await dispatch(exportLearnerSearchFailureAction(errorMessage));
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const downloadLearnerSearch = (id: string): ThunkAction<void, AppState, null, PARSAction> => async (
  dispatch,
) => {
  popToast({ ...infoToastOptions, message: <>Downloading Learner Search...</> });
  await dispatch(downloadLearnerSearchAction(id));
  try {
    const downloadedFile = await LearnerService.download(id);
    await dispatch(downloadLearnerSearchSuccessAction(downloadedFile));
    popToast({ ...successToastOptions, message: <>Successfully downloaded</> });
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'downloadLearnerSearch' });
    await dispatch(downloadLearnerSearchFailureAction(errorMessage));
    await dispatch(
      popToast({
        ...errorToastOptions,
        message: <>This report was generated by a different user and is not accessible.</>,
      }),
    );
  }
};

export const queueMocLearner = (
  request: IQueueMocLearnerRequest,
): ThunkAction<Promise<unknown | boolean>, AppState, null, PARSAction> => async (
  dispatch,
): Promise<unknown | boolean> => {
  await dispatch(queueMocLearnerAction(request));
  try {
    const response = await LearnerService.queueMocLearner(request);
    if (response?.error?.errorCode) {
      await dispatch(popToast({ ...errorToastOptions, message: <>{response?.error?.message}</> }));
      return false;
    } else {
      await dispatch(
        popToast({
          ...successToastOptions,
          message: <>MOC Learner queued - {response?.recordsAffected ?? 0} records affected</>,
        }),
      );
      return true;
    }
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'queueMocLearner' });
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
    return false;
  }
};

export const processBoardCredit = (
  request: IProcessBoardCreditRequest,
): ThunkAction<Promise<unknown | boolean>, AppState, null, PARSAction> => async (
  dispatch,
): Promise<unknown | boolean> => {
  await dispatch(processBoardCreditAction(request));
  try {
    const response = await LearnerService.processBoardCredit(request);
    if (response?.error?.errorCode) {
      await dispatch(popToast({ ...errorToastOptions, message: <>{response?.error?.message}</> }));
      return false;
    } else {
      await dispatch(
        popToast({
          ...successToastOptions,
          message: <>Board Credit Processed - {response?.recordsAffected ?? 0} records affected</>,
        }),
      );
      return true;
    }
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'processBoardCredit' });
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
    return false;
  }
};

export const adminDeleteLearnerChildCompletion = (
  request: IAdminDeleteLearnerChildCompletionRequest,
): ThunkAction<Promise<unknown | boolean>, AppState, null, PARSAction> => async (
  dispatch,
): Promise<unknown | boolean> => {
  await dispatch(adminDeleteLearnerChildCompletionAction(request));
  try {
    const response = await LearnerService.adminDeleteLearnerChildCompletion(request);
    if (response?.error?.errorCode) {
      await dispatch(popToast({ ...errorToastOptions, message: <>{response?.error?.message}</> }));
      return false;
    } else {
      await dispatch(
        popToast({
          ...successToastOptions,
          message: <>Learner Completion Deleted - {response?.recordsAffected ?? 0} records affected</>,
        }),
      );
      return true;
    }
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'adminDeleteLearnerChildCompletion' });
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
    return false;
  }
};
