import { ReactElement, ReactNode, useCallback } from 'react';
import { NavLink } from 'react-router-dom';

// Hooks.
import { useClickEvent } from 'hooks';

interface ITabProps {
  children?: ReactNode;
  exact?: boolean;
  link: string;
  title: string;
}

export const Tab = (props: ITabProps): ReactElement => {
  const { children, title, link, exact } = props;

  const fireNavigationEvent = useClickEvent({ Event: 'Navigation', EventCategory: 'Navigation' });

  const handleClick = useCallback(
    (e) => {
      fireNavigationEvent({ EventAction: e.target.textContent, EventLabel: e.currentTarget.getAttribute('href') });
    },
    [fireNavigationEvent],
  );

  return (
    <NavLink to={link} exact={exact} activeClassName="active" onClick={handleClick}>
      {children}
      <span>{title}</span>
    </NavLink>
  );
};
