import { ReactElement } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Types
interface IProps {
  isOpen: boolean;
}

export const LoadingOverlay = (props: IProps): ReactElement => {
  const { isOpen } = props;
  const useStyles = makeStyles({
    root: {
      backgroundColor: '#bfcdd4',
      color: '#007bbd',
    },
  });
  const classes: Record<'root', string> = useStyles(props);

  return (
    <Backdrop classes={classes} open={isOpen}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
