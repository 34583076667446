import { Field } from 'formik';
import React, { ReactElement, useCallback } from 'react';
import './pillcheckbox.module.scss';
export interface IInputItem {
  Title: string;
  Id: string;
  Tooltip?: string;
}
export interface IPillCheckboxProps {
  items?: IInputItem[];
  name: string;
}

const PillCheckbox = (props: IPillCheckboxProps): ReactElement => {
  const { items, name } = props;

  // Handles Enter and Spacebar.
  const handleKeyDown = useCallback((e) => {
    if ([13, 32].includes(e.keyCode)) {
      e.preventDefault();
      e.target.click();
    }
  }, []);

  return (
    <>
      {items?.map((item: IInputItem) => {
        const { Id, Title, Tooltip } = item;
        return (
          <React.Fragment key={Id}>
            <Field type="checkbox" id={Id} value={Id} name={name} className="form-input-checkbox pill" />
            {/* Adds tabIndex={0} because label is not focusable by default */}
            <label htmlFor={Id} onKeyDown={handleKeyDown} tabIndex={0} {...(Tooltip ? { title: Tooltip } : {})}>
              {Title}
            </label>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default PillCheckbox;
