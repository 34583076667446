import { Reducer } from 'redux';

import {
  GET_ACTIVITY_PROVIDER_FILES,
  GET_ACTIVITY_PROVIDER_FILES_FAILURE,
  GET_ACTIVITY_PROVIDER_FILES_LOADING,
  GET_ACTIVITY_PROVIDER_FILES_SUCCESS,
  GET_LEAERNER_VALIDATION_PROVIDER_FILES,
  GET_LEAERNER_VALIDATION_PROVIDER_FILES_FAILURE,
  GET_LEAERNER_VALIDATION_PROVIDER_FILES_LOADING,
  GET_LEAERNER_VALIDATION_PROVIDER_FILES_SUCCESS,
  GET_LEARNER_PROVIDER_FILES,
  GET_LEARNER_PROVIDER_FILES_FAILURE,
  GET_LEARNER_PROVIDER_FILES_LOADING,
  GET_LEARNER_PROVIDER_FILES_SUCCESS,
  ProviderFilesActionTypes,
  ProviderFilesState,
} from './types';

import { API } from 'core';

const initialState: ProviderFilesState = {
  activityFiles: [],
  isLoading: false,
  learnerFiles: [],
  learnerValidationFiles: [],
};

const providerFilesReducer: Reducer<ProviderFilesState, ProviderFilesActionTypes> = (
  state = initialState,
  action: ProviderFilesActionTypes,
): ProviderFilesState => {
  switch (action.type) {
    case GET_ACTIVITY_PROVIDER_FILES:
      return {
        ...state,
      };

    case GET_ACTIVITY_PROVIDER_FILES_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case GET_ACTIVITY_PROVIDER_FILES_SUCCESS:
      return {
        ...state,
        activityFiles: action.payload,
      };

    case GET_ACTIVITY_PROVIDER_FILES_FAILURE:
      return {
        ...state,
        error: API.getErrorMessage((action.payload || {}) as Error),
      };

    case GET_LEAERNER_VALIDATION_PROVIDER_FILES:
      return {
        ...state,
      };

    case GET_LEAERNER_VALIDATION_PROVIDER_FILES_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case GET_LEAERNER_VALIDATION_PROVIDER_FILES_SUCCESS:
      return {
        ...state,
        learnerValidationFiles: action.payload,
      };

    case GET_LEAERNER_VALIDATION_PROVIDER_FILES_FAILURE:
      return {
        ...state,
        error: API.getErrorMessage((action.payload || {}) as Error),
      };

    case GET_LEARNER_PROVIDER_FILES:
      return {
        ...state,
      };

    case GET_LEARNER_PROVIDER_FILES_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case GET_LEARNER_PROVIDER_FILES_SUCCESS:
      return {
        ...state,
        learnerFiles: action.payload,
      };

    case GET_LEARNER_PROVIDER_FILES_FAILURE:
      return {
        ...state,
        error: API.getErrorMessage((action.payload || {}) as Error),
      };

    default:
      return state;
  }
};

export default providerFilesReducer;
