import { ReactElement } from 'react';
import { Field, FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Fab, Grid, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

// Components
import DatePicker from 'components/DatePicker/DatePicker';

// Styles
import styles from 'components/DatePicker/DatePickerRepeater/index.module.scss';

// Types
interface IProps {
  formikKey: string;
}

export const CutoffDateRepeater = (props: IProps): ReactElement => {
  const { formikKey } = props;
  const { setFieldTouched, values } = useFormikContext();

  return (
    <FieldArray
      name={formikKey}
      render={(arrayHelpers: FieldArrayRenderProps): ReactElement => {
        return (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              {values?.[formikKey]?.map((_: ReactElement, idx: number) => {
                return (
                  <Grid container key={idx} spacing={3}>
                    <Grid item>
                      <Field name={`${formikKey}.${idx}.year`}>
                        {({ field }) => (
                          <DatePicker field={field} format="YYYY" formikKey={formikKey} views={['year']} />
                        )}
                      </Field>
                    </Grid>
                    <Grid item>
                      <Field name={`${formikKey}.${idx}.cutoffDate`}>
                        {({ field }) => <DatePicker field={field} formikKey={formikKey} timeOfDay="endOfDay" />}
                      </Field>
                      <IconButton aria-label="remove date" onClick={() => arrayHelpers.remove(idx)}>
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid item className={styles.container}>
              <div className={styles.add}>
                <Fab
                  aria-label="add another instance"
                  color="inherit"
                  onClick={() => {
                    arrayHelpers.push('');
                    setFieldTouched(formikKey, true);
                  }}
                  size="small"
                >
                  <AddIcon color="inherit" />
                </Fab>
                <span className={styles.text}>Add Another Instance</span>
              </div>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};
