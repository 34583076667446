import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import moment from 'moment/moment';
import { FormikProvider, useFormik } from 'formik';

// Components
import Button from 'components/Button/Button';
import { HighlightBox } from 'components/HighlightBox';

// Styles
import styles from '../UpdateLearnersForm/index.module.scss';

// Store + Core
import { Activity } from 'core/models';
import { activityDetailSelector } from 'store/activity/selectors';
import { clearActivityDetail, deleteActivity, getActivity } from 'store/activity/actions';

// Types
import { ButtonVariant } from 'core/enums';
type IProps = {
  activityId: string;
  id: string;
  title: string;
  callback?(): void;
  endDate: Date;
  onClose?(): void;
  startDate: Date;
};
export const DeleteActivityForm = (props: IProps): ReactElement => {
  const dispatch = useDispatch();
  const { activityId, callback, endDate, startDate, title, id, onClose } = props;

  // Create a user friendly date string.
  const dates = `${moment(startDate).format('LL')} - ${moment(endDate).format('LL')}`;

  // Get the active activityDetail
  const activityDetail: Activity = useSelector(activityDetailSelector);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (_, { setSubmitting }) => {
      // Make the API request.
      const result = await dispatch(deleteActivity(id));

      // We are done submitting.
      await setSubmitting(false);

      if (!result) {
        return;
      }

      // Close the modal.
      await onClose?.();

      // Callback.
      await callback?.();
    },
  });

  const { handleSubmit, isSubmitting } = formik;

  // Setup.
  useEffect(() => {
    if (!activityDetail) {
      // Get the current activity.
      dispatch(getActivity(id));
    }
  }, [activityDetail, dispatch, id]);

  // Unmount.
  useEffect(() => {
    // Clear the activity details on unmount.
    return () => dispatch(clearActivityDetail());
  }, [dispatch]);

  // Wait to have data before rendering anything.
  if (!activityDetail)
    return (
      <div className={styles['loading-wrapper']}>
        <CircularProgress />
      </div>
    );

  return (
    <>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{`Activity ID ${activityId} | ${dates}`}</p>
      {activityDetail.uanPharmacy && <p className={styles.description}>UAN: {activityDetail.uanPharmacy}</p>}
      {activityDetail.uanPharmacyTech && <p className={styles.description}>UAN: {activityDetail.uanPharmacyTech}</p>}
      {activityDetail.hasIndividualLearners ? (
        <>
          <HighlightBox variant="danger">
            <Box display="flex" component="p">
              <WarningRounded />
              <Box ml={2}>Cannot delete an activity with learners reported.</Box>
            </Box>
          </HighlightBox>
          <Box display="flex" justifyContent="flex-end" className={styles['button-row']}>
            <div className={styles['submit-button-container']}>
              <Button variant={ButtonVariant.Primary} onClick={onClose}>
                Okay
              </Button>
            </div>
          </Box>
        </>
      ) : (
        <>
          <div className={styles['sub-heading']}>Are you sure you want to delete this activity?</div>
          <FormikProvider value={formik}>
            <form onSubmit={handleSubmit}>
              <div className="modal-button-row">
                <Button className="primary" disabled={isSubmitting} type="submit">
                  Yes, Delete
                </Button>
                <Button className="secondary" disabled={isSubmitting} onClick={onClose}>
                  No, Don't Delete
                </Button>
              </div>
            </form>
          </FormikProvider>
        </>
      )}
    </>
  );
};
