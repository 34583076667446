import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { KeyboardBackspaceRounded } from '@material-ui/icons';
import classNames from 'classnames';

import { useLoadData } from 'hooks/useLoadData';

// Styles
import { FormikProvider, useFormik } from 'formik';
import { LoadingCards } from 'components/LoadingCard';
import InputBlock from 'components/global/inputBlock/inputBlock';
import { AdminCommandsService } from 'services/AdminCommandsService';
import styles from './AdminCommands.module.scss';
import { AdminCommandDetail } from './command-detail';
import Dropdown from 'components/Dropdown/Dropdown';
import Button from 'components/Button/Button';
import { popToast } from 'store/toast/actions';
import { useDispatch } from 'react-redux';
import { errorToastOptions, successToastOptions } from 'store/toast/constants';
import {
  SP_IDENTIFY_STUCK_LCCS,
  SP_IDENTIFY_STUCK_MOCS,
  SP_QUEUE_MOC_ACTIVITY,
  SP_QUEUE_MOC_LEARNER,
} from 'core/constants';

export interface IFormModel {
  commandArguments: { [key: string]: string | number | boolean };
  commandName: string;
}

const detailContentClass = styles['detail-content'];
const detailGroupClass = styles['detail-group'];
const detailGroupLabelClass = styles['detail-group-label'];
const excludedStoredProcedures = [
  SP_IDENTIFY_STUCK_LCCS,
  SP_IDENTIFY_STUCK_MOCS,
  SP_QUEUE_MOC_ACTIVITY,
  SP_QUEUE_MOC_LEARNER,
];

export const AdminCommandsPage = (): ReactElement => {
  const dispatch = useDispatch();
  const [submissionError, setSubmissionError] = useState(false);
  const formik = useFormik<IFormModel>({
    initialValues: {
      commandArguments: {},
      commandName: undefined,
    },
    onSubmit: async ({ commandArguments, commandName }) => {
      try {
        setSubmissionError(false);
        await AdminCommandsService.invokeCommandAsync(commandName, commandArguments);
        dispatch(popToast({ ...successToastOptions, message: <>Successfully executed {commandName}.</> }));
      } catch (err) {
        dispatch(popToast({ ...errorToastOptions, message: <>An error occurred while executing {commandName}.</> }));
        setSubmissionError(true);
      }
    },
    validate: (values) => {
      const errors: { [key: string]: string } = {};
      if (!values.commandName) {
        errors.commandName = 'Command Name is required';
      }
      return errors;
    },
  });
  const { isValid, resetForm, isSubmitting } = formik;
  const { values } = formik;

  const getter = useCallback(async () => {
    const results = await AdminCommandsService.listAllCommandsAsync();
    // 5196 - exclude specific stored procedures
    return results.filter((name) => !excludedStoredProcedures.includes(name)).map((name) => ({ id: name, name }));
  }, []);

  const { data, error, isLoading } = useLoadData<{ id: string; name: string }[]>('getAdminCommands', getter);

  useEffect(() => {
    if (values.commandName) {
      resetForm({
        values: { commandArguments: {}, commandName: values.commandName },
      });
    }
  }, [values.commandName, resetForm]);

  return (
    <>
      <div className="form-container">
        <div className="page-navigation">
          <Link className="tertiary" to="/admin">
            <KeyboardBackspaceRounded className="tertiary-icon-back" />
            Admin
          </Link>
        </div>
        <div className="form-title-container">
          <div className={classNames('title', styles.title)}>
            <h4>Admin Commands</h4>
          </div>
        </div>
        {isLoading ? (
          <LoadingCards count={3} />
        ) : data ? (
          <div className={styles.formContainer}>
            <h5>Command Name</h5>
            <FormikProvider value={formik}>
              {data && (
                <InputBlock name="commandName">
                  <Dropdown
                    className={styles.input}
                    fullWidth
                    items={data}
                    name="commandName"
                    placeholder="Select a Command"
                    required
                  />
                </InputBlock>
              )}
              {formik.values.commandName && (
                <AdminCommandDetail formikKey="commandArguments" commandName={formik.values.commandName} />
              )}
              <div>
                <Button
                  disabled={!isValid || isSubmitting}
                  isSubmitting={isSubmitting}
                  className="primary"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </Button>
              </div>
            </FormikProvider>
          </div>
        ) : (
          <div className={detailGroupClass}>
            <h5 className={detailGroupLabelClass}>Error</h5>
            <div className={detailContentClass}>{error || 'Unknown error'}</div>
          </div>
        )}
        {submissionError && (
          <div className={detailGroupClass}>
            <h5 className={detailGroupLabelClass}>Error</h5>
            <div className={detailContentClass}>
              An error occurred while executing the stored procedure {values.commandName}. Please try again or check
              Application Insights for further details.
            </div>
          </div>
        )}
      </div>
    </>
  );
};
