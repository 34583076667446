import { ReactElement } from 'react';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Box, Fab, Grid, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import get from 'lodash/get';

// Components
import { FormikInputField, FormikNumberField } from '../ContinuousImprovement/PureFormElements';

// Styles
import styles from './TextboxRepeater.module.scss';

// Types
interface IProps {
  disabled?: boolean;
  formikKey: string;
  labelPrompt?: string;
  payloadValue?: string;
}
export const TextboxRepeater = (props: IProps): ReactElement => {
  const { disabled, formikKey, labelPrompt, payloadValue } = props;
  const { setFieldTouched, values } = useFormikContext();
  const label: string = labelPrompt && `${labelPrompt}:`;
  const formikAccess: string[] = get(values, formikKey);

  return (
    <FieldArray
      name={formikKey}
      render={(arrayHelpers: FieldArrayRenderProps): ReactElement => {
        return (
          <Grid container>
            {formikAccess?.map(
              (item: string, idx: number): ReactElement => {
                return (
                  <Grid container spacing={3} component={Box} display="flex" key={idx}>
                    <Grid item xs={7} sm={8}>
                      <FormikInputField
                        label={`${label ?? ''} ${idx + 1}`}
                        name={item}
                        formikKey={`${formikKey}.${idx}.${payloadValue}`}
                      />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <FormikNumberField
                        formikKey={`${formikKey}.${idx}.sortOrder`}
                        label="Sort Order"
                        min={1}
                        step={1}
                      />
                    </Grid>
                    <Grid component={Box} alignSelf="center" mt={3} ml={0}>
                      <IconButton aria-label="remove" onClick={(): void => arrayHelpers.remove(idx)} size="small">
                        <ClearIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              },
            )}
            <Box display="flex" pt={2} pb={2}>
              <Box mr={2}>
                <Fab
                  aria-label="add another instance"
                  className={classNames(
                    {
                      [styles.disabled]: disabled,
                    },
                    styles['add-btn'],
                  )}
                  disabled={disabled}
                  onClick={(): void => {
                    arrayHelpers.push({ sortOrder: formikAccess.length + 1 });
                    setFieldTouched(formikKey, true);
                  }}
                  size="small"
                >
                  <AddIcon color="inherit" />
                </Fab>
              </Box>
              <Box pt={0.5}>Add Another Instance</Box>
            </Box>
          </Grid>
        );
      }}
    />
  );
};
