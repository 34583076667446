import { isNumber, groupBy, max, sumBy } from 'lodash';
import {
  CollaborationTypes,
  IActivityLearnerSearchResult,
  IBoardSlim,
  ILearnerCreditDetails,
  ILearnerSearchActivity,
} from 'core/models';

// Extract the learner result details from the search result
export const transformLearnerSearchResults = (
  learnerResults: IActivityLearnerSearchResult[],
): ILearnerSearchActivity[] => {
  const learnerDetailsData = learnerResults?.map((result) => ({
    ...result.document,
  }));

  return learnerDetailsData;
};

// Organize Learner Details into a 2D Array by Certifying Board
export const splitLearnerDetailsByCertifyingBoards = (
  learnerDetails: ILearnerSearchActivity[],
  specialtyBoards: IBoardSlim[],
): ILearnerSearchActivity[][] => {
  const boardAbbreviations: string[] = specialtyBoards?.map((board) => board.abbreviation);
  return boardAbbreviations
    ?.map((abbr: string): ILearnerSearchActivity[] =>
      learnerDetails?.filter(
        ({ board, collaborationType }: ILearnerSearchActivity): boolean =>
          board === abbr && collaborationType === CollaborationTypes.CREDITS_BY_CERTIFYING_BOARD,
      ),
    )
    ?.filter((details: ILearnerSearchActivity[]): boolean => details?.length !== 0);
};

// Organize Learner Details into a 2D Array by State Board
export const splitLearnerDetailsByStateBoards = (
  learnerDetails: ILearnerSearchActivity[],
): ILearnerSearchActivity[][] => {
  const stateCompletionEntries: ILearnerSearchActivity[] = learnerDetails?.filter(
    ({ collaborationType }) => collaborationType === CollaborationTypes.CREDITS_BY_STATE_OR_PROVINCE,
  );
  return [stateCompletionEntries];
};

export const splitLearnerDetailsByRems = (learnerDetails: ILearnerSearchActivity[]): ILearnerSearchActivity[] => {
  return learnerDetails?.filter(({ collaborationType }) => collaborationType === CollaborationTypes.CREDITS_BY_REMS);
};

// Get the Total Credits and a Dictionary of credits by board and by credit name
export const transformCreditsPerBoard = (
  learnerDetailsByBoard: ILearnerSearchActivity[][],
): ILearnerCreditDetails[] => {
  const CME_KEY = 'CME';
  const allCreditsByCompletions: ILearnerCreditDetails[][] = [];
  learnerDetailsByBoard?.forEach((learnerDetails) => {
    const learnerDetailsByCompletionId = groupBy(learnerDetails, 'completionId');
    Object.values(learnerDetailsByCompletionId).forEach((val) => {
      const completionLearnerCredits: ILearnerCreditDetails[] = [];
      allCreditsByCompletions.push(completionLearnerCredits);

      const credits: number[] = [];
      val?.forEach(({ board = '', collaborationType, credits: learnerCredits, totalCredits }) => {
        const isStateBoard = collaborationType === CollaborationTypes.CREDITS_BY_STATE_OR_PROVINCE;
        const boardAbbr = isStateBoard ? CME_KEY : board;
        const existingCreditsIndex: number = completionLearnerCredits?.findIndex(
          ({ boardAbbr: boardAbbreviation }) => boardAbbr === boardAbbreviation,
        );
        const creditsEntry: ILearnerCreditDetails = completionLearnerCredits?.[existingCreditsIndex] || {
          boardAbbr,
          credits: {},
          totalCredits: 0,
        };
        if (isStateBoard) {
          learnerCredits.forEach((c) => {
            credits.push(c.credits);
          });
          creditsEntry.totalCredits = max(credits);
        } else {
          if (isNumber(totalCredits)) {
            creditsEntry.totalCredits += totalCredits;
          }
          learnerCredits?.forEach(({ credits, name }) => {
            if (isNumber(credits)) {
              if (!creditsEntry.credits[name]) {
                creditsEntry.credits[name] = credits;
              } else {
                creditsEntry.credits[name] += credits;
              }
            }
          });
        }
        if (existingCreditsIndex < 0) {
          completionLearnerCredits.push(creditsEntry);
        }
      });
    });
  });

  const allCredits: ILearnerCreditDetails[] = [];
  const mergedCreditCompletions: ILearnerCreditDetails[] = allCreditsByCompletions.flat(1);
  const groupByBoardAbbr = groupBy(mergedCreditCompletions, 'boardAbbr');

  Object.values(groupByBoardAbbr).forEach((val) => {
    const learnerCredits: ILearnerCreditDetails = {};
    if (val.length > 0) {
      learnerCredits.boardAbbr = val[0].boardAbbr;
      learnerCredits.credits = {};
      val.forEach((v) => {
        Object.entries(v.credits).forEach(([key, value]) => {
          learnerCredits.credits[key] = (learnerCredits.credits[key] ?? 0) + value;
        });
      });
      learnerCredits.totalCredits = sumBy(val, 'totalCredits');
      allCredits.push(learnerCredits);
    }
  });

  return allCredits;
};
