import { Fragment, ReactElement } from 'react';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

// Components
import { SessionInputs } from './SessionInputs';
import Button from '../Button/Button';
import { HighlightBox } from '../HighlightBox';

// Styles
import styles from './LocationDetailsRepeater.module.scss';

// Types
interface IProps {
  isDisabled?: boolean;
  formikKey: string;
  shouldShowRepeater?: boolean;
  shouldShowLocationInformation?: boolean;
}

export const LocationDetailsRepeater = (props: IProps): ReactElement => {
  const { formikKey, isDisabled, shouldShowRepeater, shouldShowLocationInformation } = props;
  const { values } = useFormikContext();

  const detailsArray = values?.[formikKey];
  const shouldShowWarning: boolean = detailsArray?.length > 1 && !isDisabled;
  const removeBtnText = shouldShowLocationInformation ? 'Remove these location details' : 'Remove these dates';

  return (
    <FieldArray
      name={formikKey}
      render={(arrayHelpers: FieldArrayRenderProps) => (
        <>
          {detailsArray?.map(
            (_: ReactElement, idx: number): ReactElement => {
              const isLastInArray: boolean = detailsArray?.length !== idx + 1;

              return (
                <Fragment key={idx}>
                  <SessionInputs
                    isDisabled={isDisabled}
                    formikKey={`${formikKey}.${idx}`}
                    shouldShowLocationInformation={shouldShowLocationInformation}
                  />
                  {isLastInArray && <hr />}
                  {isDisabled ||
                    (idx !== 0 && (
                      <Button disabled={isDisabled} aria-label={removeBtnText} onClick={() => arrayHelpers.remove(idx)}>
                        <ClearIcon />
                        {removeBtnText}
                      </Button>
                    ))}
                </Fragment>
              );
            },
          )}
          {shouldShowWarning && (
            <HighlightBox>
              <p>
                <span>Please note:</span> Once you save this draft you will no longer be able to edit this page while
                creating your draft(s).
              </p>
            </HighlightBox>
          )}
          {isDisabled ||
            (shouldShowRepeater && (
              <div
                className={styles.add}
                role="button"
                onClick={(): void => {
                  if (!isDisabled) arrayHelpers.push('');
                }}
              >
                <Fab aria-label="add another instance" color="inherit" disabled={isDisabled} size="small">
                  <AddIcon color="inherit" />
                </Fab>
                Add Another Instance
              </div>
            ))}
        </>
      )}
    />
  );
};
