import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosInstance } from './api';
import PARSConfiguration from './configuration';

export const BASE_PATH = PARSConfiguration.api.replace(/\/+$/, '');

//  https://docs.microsoft.com/en-us/aspnet/core/web-api/jsonpatch?view=aspnetcore-3.1
//  https://tools.ietf.org/html/rfc6902.
export interface IJsonPatchDocumentOperation {
  path: string;
  op: string;
  from?: string;
  value?: any;
}

export abstract class BaseApiService {
  constructor(private basePath: string = BASE_PATH, private axios: AxiosInstance = axiosInstance) {}

  protected async deleteAsync(url: string): Promise<void> {
    const config: AxiosRequestConfig = this.getConfiguration();

    await this.axios.delete(url, config);
  }

  protected async getAsync<TResponse>(url: string, extendConfig?: AxiosRequestConfig): Promise<TResponse> {
    const config: AxiosRequestConfig = this.getConfiguration(extendConfig);

    return (await this.axios.get(url, config)).data;
  }

  protected async getDownloadAsync(url: string): Promise<Blob> {
    const config: AxiosRequestConfig = this.getConfiguration();
    const { data } = await this.axios.get(url, { ...config, responseType: 'blob' });

    return data;
  }

  protected async patchAsync<TResponse>(url: string, data: IJsonPatchDocumentOperation[]): Promise<TResponse> {
    const config: AxiosRequestConfig = this.getConfiguration();

    return (await this.axios.patch(url, data, config)).data;
  }

  protected async postAsync<TPayload, TResponse>(url: string, data: TPayload): Promise<TResponse> {
    const config: AxiosRequestConfig = this.getConfiguration();

    return (await this.axios.post(url, data, config)).data;
  }

  protected async putAsync<TPayload, TResponse>(url: string, data: TPayload): Promise<TResponse> {
    const config: AxiosRequestConfig = this.getConfiguration();

    return (await this.axios.put(url, data, config)).data;
  }

  private getConfiguration(extendConfig: AxiosRequestConfig = {}): AxiosRequestConfig {
    const config: AxiosRequestConfig = {
      baseURL: this.basePath,
    };

    return {
      ...config,
      ...extendConfig,
    };
  }
}
