import classNames from 'classnames';
import { ReactElement } from 'react';
import CloseIcon from '@material-ui/icons/Close';

// Styles
import styles from './faq.module.scss';

// Core + Store
import { ITaxonomyTerm, RollupOrganizationEnums } from 'core/models';
import { filterTaxonomyByRollupOrganizationEnum } from 'globals/utils/filterTaxonomyByOrg';

interface IFaqProps {
  handleClose: () => void;
  faqTitle: string;
  faqs: ITaxonomyTerm[];
  faqArea: string;
  isLinks?: boolean;
  isIgnoreFiltering?: boolean;
  rollupOrganizationEnum?: RollupOrganizationEnums;
}

const Faq = (props: IFaqProps): ReactElement => {
  const { handleClose, faqTitle, faqs = [], faqArea, isLinks, isIgnoreFiltering, rollupOrganizationEnum } = props;
  const renderedFaqs: ITaxonomyTerm[] = isIgnoreFiltering
    ? faqs
    : filterTaxonomyByRollupOrganizationEnum(faqs, rollupOrganizationEnum).filter(
        ({ metadataText }) => metadataText === faqArea,
      );

  return (
    <div className="faq-content">
      <button className="close" aria-label="close" onClick={handleClose}>
        <CloseIcon />
      </button>
      {faqTitle && <h5>{faqTitle}</h5>}
      {!!renderedFaqs?.length && (
        <ul className={classNames({ links: isLinks })}>
          {renderedFaqs.map(({ description, id, name }) => (
            <li key={id}>
              {!isLinks && <span className={styles.bold}>{name}</span>}
              <div dangerouslySetInnerHTML={{ __html: description || '' }} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Faq;
