import { Drawer } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import values from 'lodash/values';
import { ReactElement } from 'react';

// Store.
import { DashboardNotificationCategories, IDashboardNotification } from 'store/dashboard/types';
import { DashboardNotification } from '../DashboardNotification';

// Styles.
import styles from './DashboardNotificationsDrawer.module.scss';

interface IDashboardNotificationsDrawerProps {
  notifications?: IDashboardNotification[];
  isOpen?: boolean;
  onClose?: VoidFunction;
}

export const DashboardNotificationsDrawer = (props: IDashboardNotificationsDrawerProps): ReactElement => {
  const { isOpen, notifications = [], onClose } = props;

  return (
    <Drawer anchor="right" classes={{ paper: styles.drawer }} onClose={onClose} open={isOpen}>
      <section className={styles.content}>
        <header className={styles.header}>
          <button className={styles.close} onClick={onClose} type="button">
            <CloseOutlined />
          </button>
        </header>
        {notifications?.length > 0 ? (
          values(DashboardNotificationCategories).map((category, categoryIndex) => {
            const categoryNotifications = notifications.filter((notification) => notification.category === category);
            return (
              categoryNotifications.length > 0 && (
                <section className={styles.section} key={categoryIndex}>
                  <h2 className={styles.title}>{category}</h2>
                  <ul className={styles.list}>
                    {categoryNotifications.map((notification, idx) => (
                      <DashboardNotification key={notification.id || idx} notification={notification} />
                    ))}
                  </ul>
                </section>
              )
            );
          })
        ) : (
          <section className={styles.section}>
            <strong>You're all caught up!</strong>
            <br />
            <strong>No new notifications to review.</strong>
          </section>
        )}
      </section>
    </Drawer>
  );
};
