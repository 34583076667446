import { PropsWithChildren, ReactElement } from 'react';
import classNames from 'classnames';

import styles from './HighlightBox.module.scss';

export const HighlightBox = (props: PropsWithChildren<{ variant?: string }>): ReactElement => {
  const { children, variant } = props;
  return (
    <div
      className={classNames(
        {
          [styles.danger]: variant === 'danger',
          [styles.warning]: variant === 'warning',
        },
        styles['highlight-box'],
      )}
    >
      {children}
    </div>
  );
};
