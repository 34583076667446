import { ReactChild, ReactElement } from 'react';
import { useDispatch } from 'react-redux';

// Store
import { downloadProviderFile } from 'store/fileDownload/actions';

// Types
export interface IManualDownloadLinkProps {
  children: ReactChild;
  fileName: string;
  id: string;
}

export const ManualDownloadProviderFileLink = (props: IManualDownloadLinkProps): ReactElement => {
  const { fileName, id, children } = props;
  const dispatch = useDispatch();

  const handleDownload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    event.preventDefault();
    dispatch(downloadProviderFile({ fileName, id }));
  };

  return (
    <a download onClick={handleDownload} href={fileName} role="button" style={{ display: 'inline-block' }}>
      {children}
    </a>
  );
};
