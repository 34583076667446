import { ReactElement, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { KeyboardBackspaceRounded } from '@material-ui/icons';
import classNames from 'classnames';

import { ProviderSubmissionLog } from 'core/models';
import Button from 'components/Button/Button';
import { useLoadData } from 'hooks/useLoadData';
import { ProviderSubmissionLogsQuery, ProviderSubmissionLogsService } from 'services/ProviderSubmissionLogsService';
import { ITypeaheadOption } from 'components/ContinuousImprovement/Typeahead';

// Styles
import styles from './ProviderSubmissionLogs.module.scss';
import { ProviderSelect } from 'components/UserControl/ProviderSelect';
import { FormikProvider, useFormik, Field } from 'formik';
import DatePicker from 'components/DatePicker/DatePicker';
import {
  Grid,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { LoadingCards } from 'components/LoadingCard';
import { orderBy, sumBy } from 'lodash';
import { buildProviderSubmissionLogsSchema } from './validationSchema';
import InputBlock from 'components/global/inputBlock/inputBlock';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIMEZONE } from 'core/constants';
import { FormikRadioField } from 'components/ContinuousImprovement/PureFormElements';
import { ProviderSubmissionType, ProviderSubmissionChannel } from 'core/enums';

interface IFormModel {
  providerSubmissionType: string;
  provider: string;
  startDate: Date;
  endDate: Date;
}

enum ProviderSubmissionTypeFormValue {
  Both = 'both',
  Learners = 'learners',
  Activities = 'activities',
}

const detailContentClass = styles['detail-content'];
const detailGroupClass = styles['detail-group'];
const detailGroupLabelClass = styles['detail-group-label'];

const RenderSubmissionChannelCell = ({ submissionChannel }: { submissionChannel: ProviderSubmissionChannel }) => {
  let value;
  switch (submissionChannel) {
    case ProviderSubmissionChannel.ExcelBatch:
      value = 'Excel Batch';
      break;
    case ProviderSubmissionChannel.TabBatch:
      value = 'Tab Batch';
      break;
    case ProviderSubmissionChannel.XmlBatch:
      value = 'XML Batch';
      break;
    default:
      value = submissionChannel;
      break;
  }

  return <TableCell>{value}</TableCell>;
};

export const ProviderSubmissionLogsPage = (): ReactElement => {
  const [formData, setFormData] = useState<IFormModel>();

  const getter = useCallback(async () => {
    if (!formData) return;
    let providerSubmissionType;
    if (formData.providerSubmissionType === ProviderSubmissionTypeFormValue.Learners) {
      providerSubmissionType = ProviderSubmissionType.Learner;
    } else if (formData.providerSubmissionType === ProviderSubmissionTypeFormValue.Activities) {
      providerSubmissionType = ProviderSubmissionType.Activity;
    }
    const params: ProviderSubmissionLogsQuery = {
      endDate: formData.endDate,
      providerId: formData.provider,
      providerSubmissionType,
      startDate: formData.startDate,
    };
    return await ProviderSubmissionLogsService.getData(params);
  }, [formData]);

  const { data, error, isLoading } = useLoadData<ProviderSubmissionLog[]>('getProviderSubmissionLogs', getter);

  const formik = useFormik<IFormModel>({
    enableReinitialize: true,
    initialValues: {
      endDate: undefined,
      provider: undefined,
      providerSubmissionType: ProviderSubmissionTypeFormValue.Both,
      startDate: undefined,
    },
    onSubmit: (values: IFormModel) => setFormData(values),
    validationSchema: buildProviderSubmissionLogsSchema(),
  });

  const { errors, touched, isValid, dirty, handleBlur } = formik;

  const handleProviderChange = (_, value: ITypeaheadOption) => {
    const field: keyof IFormModel = 'provider';
    formik.setFieldValue(field, value.id);
  };

  const handleProviderClear = () => {
    const field: keyof IFormModel = 'provider';
    formik.setFieldValue(field, null);
  };

  const summaryMetrics = useMemo(() => {
    const metrics = {
      activitiesAdded: 0,
      activitiesDeleted: 0,
      activitiesRejected: 0,
      activitiesUpdated: 0,
      learnersMatched: 0,
      learnersNotMatched: 0,
      learnersSubmitted: 0,
      totalActivitiesSubmitted: 0,
    };
    if (data?.length > 0 ?? false) {
      metrics.activitiesAdded = sumBy(data, 'activitiesAdded');
      metrics.activitiesDeleted = sumBy(data, 'activitiesDeleted');
      metrics.activitiesRejected = sumBy(data, 'activitiesRejected');
      metrics.activitiesUpdated = sumBy(data, 'activitiesUpdated');
      metrics.learnersMatched = sumBy(data, 'learnersMatched');
      metrics.learnersNotMatched = sumBy(data, 'learnersNotMatched');
      metrics.learnersSubmitted = sumBy(data, 'learnersSubmitted');

      metrics.totalActivitiesSubmitted =
        metrics.activitiesAdded + metrics.activitiesUpdated + metrics.activitiesDeleted + metrics.activitiesRejected;
    }
    return metrics;
  }, [data]);

  const learnersOnly = formData?.providerSubmissionType === ProviderSubmissionTypeFormValue.Learners;
  const activitiesOnly = formData?.providerSubmissionType === ProviderSubmissionTypeFormValue.Activities;

  return (
    <>
      <div className="form-container">
        <div className="page-navigation">
          <Link className="tertiary" to="/admin">
            <KeyboardBackspaceRounded className="tertiary-icon-back" />
            Admin
          </Link>
        </div>
        <div className="form-title-container">
          <div className={classNames('title', styles.title)}>
            <h4>Provider Submission Logs</h4>
          </div>
        </div>
        <div className={styles.formContainer}>
          <FormikProvider value={formik}>
            <InputBlock errors={errors} name="provider" touched={touched} title="Provider">
              <div className={styles.providerField}>
                <ProviderSelect beginWithPristineForm onChange={handleProviderChange} onClear={handleProviderClear} />
              </div>
            </InputBlock>
            <InputBlock
              errors={errors}
              name="providerSubmissionType"
              touched={touched}
              title="Provider Submission Type"
            >
              <RadioGroup aria-label="provider submission type">
                <Grid container spacing={2}>
                  <Grid item>
                    <FormikRadioField
                      payloadValue={ProviderSubmissionTypeFormValue.Both}
                      name="Both"
                      formikKey="providerSubmissionType"
                    />
                  </Grid>
                  <Grid item>
                    <FormikRadioField
                      payloadValue={ProviderSubmissionTypeFormValue.Activities}
                      name="Activities"
                      formikKey="providerSubmissionType"
                    />
                  </Grid>
                  <Grid item>
                    <FormikRadioField
                      payloadValue={ProviderSubmissionTypeFormValue.Learners}
                      name="Learners"
                      formikKey="providerSubmissionType"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </InputBlock>
            <div className={styles.formFieldGrid}>
              <div>
                <InputBlock errors={errors} name="startDate" touched={touched} title="Start Date">
                  <Field label="Start Date" name="startDate">
                    {({ field }) => (
                      <DatePicker
                        onBlur={handleBlur}
                        className={styles.input}
                        field={field}
                        formikKey="startDate"
                        timeOfDay="midnight"
                      />
                    )}
                  </Field>
                </InputBlock>
              </div>
              <div>
                <InputBlock errors={errors} name="endDate" touched={touched} title="End Date">
                  <Field label="End Date" name="endDate">
                    {({ field }) => (
                      <DatePicker
                        onBlur={handleBlur}
                        className={styles.input}
                        field={field}
                        formikKey="endDate"
                        timeOfDay="endOfDay"
                      />
                    )}
                  </Field>
                </InputBlock>
              </div>
            </div>
            <div className={styles.submit}>
              <Button disabled={!dirty || !isValid} className="primary" onClick={() => formik.handleSubmit()}>
                Submit
              </Button>
            </div>
          </FormikProvider>
        </div>
        {isLoading ? (
          <LoadingCards count={3} />
        ) : data ? (
          <div>
            {activitiesOnly && (
              <div className={detailGroupClass}>
                <h5 className={detailGroupLabelClass}>Total Activities Submitted</h5>
                <div className={detailContentClass}>{summaryMetrics.totalActivitiesSubmitted}</div>
              </div>
            )}
            {learnersOnly && (
              <div className={detailGroupClass}>
                <h5 className={detailGroupLabelClass}>Total Learners Submitted</h5>
                <div className={detailContentClass}>{summaryMetrics.learnersSubmitted}</div>
              </div>
            )}
            <TableContainer role="grid">
              <Table className={styles.table} size="small">
                <TableHead className={styles.tableHeader}>
                  <TableRow>
                    <TableCell>Provider Name</TableCell>
                    <TableCell>Submission Type</TableCell>
                    <TableCell>Submission Channel</TableCell>
                    <TableCell>Submitted Date</TableCell>
                    {!activitiesOnly && (
                      <>
                        <TableCell align="right">Learners Submitted</TableCell>
                        <TableCell align="right">Learners Matched</TableCell>
                        <TableCell align="right">Learners Not Matched</TableCell>
                      </>
                    )}
                    {!learnersOnly && (
                      <>
                        <TableCell align="right">Activities Added</TableCell>
                        <TableCell align="right">Activities Updated</TableCell>
                        <TableCell align="right">Activities Deleted</TableCell>
                        <TableCell align="right">Activities Rejected</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderBy(data, ({ createdDate }) => moment.utc(createdDate), 'desc').map((i, ix) => (
                    <TableRow key={ix}>
                      <TableCell>{i.providerName}</TableCell>
                      <TableCell>{i.providerSubmissionType}</TableCell>
                      <RenderSubmissionChannelCell submissionChannel={i.providerSubmissionChannel} />
                      <TableCell title={moment.utc(i.createdDate).tz(DEFAULT_TIMEZONE).format('MM-DD-YYYY h:mma')}>
                        {moment(i.createdDate).format(DEFAULT_DATE_FORMAT)}
                      </TableCell>
                      {!activitiesOnly && (
                        <>
                          <TableCell align="right">{i.learnersSubmitted}</TableCell>
                          <TableCell align="right">{i.learnersMatched}</TableCell>
                          <TableCell align="right">{i.learnersNotMatched}</TableCell>
                        </>
                      )}
                      {!learnersOnly && (
                        <>
                          <TableCell align="right">{i.activitiesAdded}</TableCell>
                          <TableCell align="right">{i.activitiesUpdated}</TableCell>
                          <TableCell align="right">{i.activitiesDeleted}</TableCell>
                          <TableCell align="right">{i.activitiesRejected}</TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow className={styles.tableRowBold}>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    {!activitiesOnly && (
                      <>
                        <TableCell align="right">{summaryMetrics.learnersSubmitted}</TableCell>
                        <TableCell align="right">{summaryMetrics.learnersMatched}</TableCell>
                        <TableCell align="right">{summaryMetrics.learnersNotMatched}</TableCell>
                      </>
                    )}
                    {!learnersOnly && (
                      <>
                        <TableCell align="right">{summaryMetrics.activitiesAdded}</TableCell>
                        <TableCell align="right">{summaryMetrics.activitiesUpdated}</TableCell>
                        <TableCell align="right">{summaryMetrics.activitiesDeleted}</TableCell>
                        <TableCell align="right">{summaryMetrics.activitiesRejected}</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        ) : (
          formData && (
            <div className={detailGroupClass}>
              <h5 className={detailGroupLabelClass}>Error</h5>
              <div className={detailContentClass}>{error || 'Unknown error'}</div>
            </div>
          )
        )}
      </div>
    </>
  );
};
