import { API } from 'core';
import { ServerRoute } from 'core/api';
import { IDashboard, IDashboardBanner, IDashboardMessage } from 'store/dashboard/types';

export class DashboardService {
  public static getDashboard = (): Promise<IDashboard> => {
    const route: ServerRoute = ServerRoute.forAction('dashboard');
    return API.get(route);
  };

  public static getDashboardBanners = (organizationId: string): Promise<IDashboardBanner[]> => {
    const route: ServerRoute = ServerRoute.forAction('dashboard', `alerts/${organizationId}`);
    return API.get(route);
  };

  public static getDashboardMessage = (): Promise<IDashboardMessage> => {
    const route: ServerRoute = ServerRoute.forAction('dashboard', 'cta');
    return API.get(route);
  };
}
