import { IAttestation, PARSAction } from 'core/models';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AttestationService } from 'services/AttestationService';
import { AppState } from 'store';
import { popToast } from 'store/toast/actions';
import { errorToastOptions } from 'store/toast/constants';
import {
  ATTEST,
  ATTEST_FAILURE,
  ATTEST_SUCCESS,
  GET_ATTESTATION,
  GET_ATTESTATION_FAILURE,
  GET_ATTESTATION_SUCCESS,
} from './types';
import { handleServerError } from 'globals/utils/handleServerError';

// action creators
export const getAttestationAction = (): AnyAction => ({
  type: GET_ATTESTATION,
});

export const getAttestationSuccessAction = (attestation: IAttestation): AnyAction => ({
  payload: attestation,
  type: GET_ATTESTATION_SUCCESS,
});

export const getAttestationFailureAction = (error: Error): AnyAction => ({
  payload: error,
  type: GET_ATTESTATION_FAILURE,
});

export const attestAction = (): AnyAction => ({
  type: ATTEST,
});

export const attestSuccessAction = (): AnyAction => ({
  type: ATTEST_SUCCESS,
});

export const attestFailureAction = (error: Error): AnyAction => ({
  payload: error,
  type: ATTEST_FAILURE,
});

// thunk actions
export const getAttestation = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(getAttestationAction());
  try {
    const attestation: IAttestation = await AttestationService.get();
    await dispatch(getAttestationSuccessAction(attestation));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getAttestation' });
    await dispatch(getAttestationFailureAction(error));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const attest = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(attestAction());
  try {
    await AttestationService.attest();
    await dispatch(attestSuccessAction());
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'attest' });
    await dispatch(attestFailureAction(error));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};
