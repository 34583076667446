import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store';
import {
  DOWNLOAD_SYNONYM_FILE,
  DOWNLOAD_SYNONYM_FILE_FAILURE,
  DOWNLOAD_SYNONYM_FILE_SUCCESS,
  UPLOAD_SYNONYM_FILE,
  UPLOAD_SYNONYM_FILE_PROGRESS,
  UPLOAD_SYNONYM_FILE_FAILURE,
  UPLOAD_SYNONYM_FILE_SUCCESS,
  SynonymMapName,
  DownloadSynonymFileFailure,
  DownloadSynonymFileSuccess,
  DownloadSynonymFile,
  UploadSynonymFile,
  UploadSynonymFileProgress,
  UploadSynonymFileSuccess,
  UploadSynonymFileFailure,
  ClearUploadedSynonymFile,
  CLEAR_UPLOADED_SYNONYM_FILE,
} from './types';
import { SynonymFileService } from 'services/SynonymFileService';
// @ts-ignore: This package isn't typescript-friendly
import { saveAs } from 'filesaver.js-npm';
import { popToast } from 'store/toast/actions';
import { errorToastOptions, infoToastOptions, successToastOptions } from 'store/toast/constants';
import { handleServerError } from '../../globals/utils/handleServerError';

export const downloadStartedAction = (mapName: SynonymMapName): DownloadSynonymFile => {
  return {
    payload: {
      mapName,
    },
    type: DOWNLOAD_SYNONYM_FILE,
  };
};

export const downloadSuccessAction = (mapName: SynonymMapName): DownloadSynonymFileSuccess => {
  return {
    payload: {
      mapName,
    },
    type: DOWNLOAD_SYNONYM_FILE_SUCCESS,
  };
};

export const downloadFailureAction = (error: string): DownloadSynonymFileFailure => {
  return {
    payload: error,
    type: DOWNLOAD_SYNONYM_FILE_FAILURE,
  };
};

export const uploadProgressAction = (percentage: number): UploadSynonymFileProgress => ({
  payload: percentage,
  type: UPLOAD_SYNONYM_FILE_PROGRESS,
});

export const uploadStartedAction = (mapName: SynonymMapName, file: File): UploadSynonymFile => {
  return {
    payload: {
      mapName,
      file,
    },
    type: UPLOAD_SYNONYM_FILE,
  };
};

export const clearUploadedAction = (): ClearUploadedSynonymFile => {
  return {
    type: CLEAR_UPLOADED_SYNONYM_FILE,
  };
};

export const uploadSuccessAction = (mapName: SynonymMapName): UploadSynonymFileSuccess => {
  return {
    payload: {
      mapName,
    },
    type: UPLOAD_SYNONYM_FILE_SUCCESS,
  };
};

export const uploadFailureAction = (error: string): UploadSynonymFileFailure => {
  return {
    payload: error,
    type: UPLOAD_SYNONYM_FILE_FAILURE,
  };
};

export const downloadSynonymFile = (mapName: SynonymMapName): ThunkAction<void, AppState, null, Action> => async (
  dispatch,
) => {
  await dispatch(downloadStartedAction(mapName));

  // Tell the user there we started downloading.
  await dispatch(popToast({ ...infoToastOptions, message: <>Downloading...</> }));

  try {
    const { file, type, filename } = await SynonymFileService.download(mapName);

    if (file) {
      // Setup blob file.
      const userFile = new Blob([file], { type });

      // Save to user's download folder.
      saveAs(userFile, filename || `synonyms-${mapName}.xlsx`);

      // Success.
      await dispatch(downloadSuccessAction(mapName));

      // Tell the user there was success.
      await dispatch(popToast({ ...successToastOptions, message: <>Downloaded successfully.</> }));
    }
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'downloadSynonymFile' });
    await dispatch(downloadFailureAction(errorMessage));

    // Tell the user there was an error.
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const uploadSynonymFile = (
  mapName: SynonymMapName,
  file: File,
): ThunkAction<Promise<boolean>, AppState, null, Action> => async (dispatch) => {
  // Null check.
  if (!file) {
    dispatch(popToast({ ...errorToastOptions, message: <>No file found, please try again.</> }));
    return false;
  }
  // Tell the user we started the upload.
  dispatch(popToast({ ...infoToastOptions, message: <>Uploading...</> }));

  // Start the upload.
  dispatch(uploadStartedAction(mapName, file));
  try {
    await SynonymFileService.upload(mapName, { file });
    // Tell the user that the file successfully uploaded.
    dispatch(uploadSuccessAction(mapName));

    dispatch(popToast({ ...infoToastOptions, autoHideDuration: undefined, message: <>Uploaded successfully.</> }));

    // Caller will close the modal
    return true;
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'batchLearner' });
    console.warn(`synonym map ${mapName} upload error: `, errorMessage);
    // Tell the user we have a failure.
    dispatch(uploadFailureAction(errorMessage));
    dispatch(popToast({ ...errorToastOptions, message: <>Something went wrong. Please try again later.</> }));
    return false;
  }
};
