import { RollupOrganizationEnums } from 'core/models';
import { Helmet } from 'react-helmet';
import { getSite } from 'utils/site';

const publicUrl = process.env.PUBLIC_URL;

export const SiteHeadManager: React.FC = () => {
  const site = getSite();
  let title = 'ACCME | PARS';
  let description = 'Program and Activity Reporting';
  let icons = [
    <link key={1} rel="icon" href={`${publicUrl}/favicon-pars.ico`} />,
    <meta key={2} property="og:image" content={`${publicUrl}/logo1200x627.png`} />,
    <link key={3} rel="apple-touch-icon" href={`${publicUrl}/logo192.png`} />,
  ];
  if (site === RollupOrganizationEnums.NARS) {
    title = 'ANCC | NARS';
    description = 'Nurse Activity Reporting System';
    icons = [
      <link key={1} rel="icon" href={`${publicUrl}/favicon-ancc.ico`} />,
      <meta key={2} property="og:image" content={`${publicUrl}/logo-ancc-360x200.png`} />,
      <link key={3} rel="apple-touch-icon" href={`${publicUrl}/logo-ancc-192.png`} />,
    ];
  }
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {icons}
    </Helmet>
  );
};
