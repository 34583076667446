import { IBoard, IBoardSlim, PARSAction } from 'core/models';
import { IBCTBoard } from 'layouts/pages/bct/types';

export interface BoardsState {
  boards: IBoardSlim[];
  configuredBoards: IBCTBoard[];
  specialtyBoards: Array<IBoard>;
}

/** @deprecated */
export const GET_BOARDS_SPECIALTY = 'GET_BOARDS_SPECIALTY';
/** @deprecated */
export const GET_BOARDS_SPECIALTY_SUCCESS = 'GET_BOARDS_SPECIALTY_SUCCESS';
/** @deprecated */
export const GET_BOARDS_SPECIALTY_FAILURE = 'GET_BOARDS_SPECIALTY_FAILURE';

export const CLEAR_BOARDS = 'CLEAR_BOARDS';

export const GET_BOARDS = 'GET_BOARDS';
export const GET_BOARDS_SUCCESS = 'GET_BOARDS_SUCCESS';
export const GET_BOARDS_FAILURE = 'GET_BOARDS_FAILURE';
export const GET_BOARD_BY_ID = 'GET_BOARD_BY_ID';
export const GET_BOARD_BY_ID_SUCCESS = 'GET_BOARD_BY_ID_SUCCESS';
export const GET_BOARD_BY_ID_FAILURE = 'GET_BOARD_BY_ID_FAILURE';

/** @deprecated */
interface GetSpecialtyBoards extends PARSAction<undefined, typeof GET_BOARDS_SPECIALTY> {
  type: typeof GET_BOARDS_SPECIALTY;
}

/** @deprecated */
interface GetSpecialtyBoardsSuccess extends PARSAction<Array<IBoard>, typeof GET_BOARDS_SPECIALTY_SUCCESS> {
  type: typeof GET_BOARDS_SPECIALTY_SUCCESS;
}

/** @deprecated */
interface GetSpecialtyBoardsFailure extends PARSAction<Error, typeof GET_BOARDS_SPECIALTY_FAILURE> {
  type: typeof GET_BOARDS_SPECIALTY_FAILURE;
}

interface ClearBoards extends PARSAction<undefined, typeof CLEAR_BOARDS> {
  type: typeof CLEAR_BOARDS;
}

interface GetBoards extends PARSAction<undefined, typeof GET_BOARDS> {
  type: typeof GET_BOARDS;
}

interface GetBoardsSuccess extends PARSAction<IBoardSlim[], typeof GET_BOARDS_SUCCESS> {
  type: typeof GET_BOARDS_SUCCESS;
}

interface GetBoardsFailure extends PARSAction<Error, typeof GET_BOARDS_FAILURE> {
  type: typeof GET_BOARDS_FAILURE;
}

interface GetBoardById extends PARSAction<undefined, typeof GET_BOARD_BY_ID> {
  type: typeof GET_BOARD_BY_ID;
}

interface GetBoardByIdSuccess extends PARSAction<IBCTBoard, typeof GET_BOARD_BY_ID_SUCCESS> {
  type: typeof GET_BOARD_BY_ID_SUCCESS;
}

interface GetBoardByIdFailure extends PARSAction<Error, typeof GET_BOARD_BY_ID_FAILURE> {
  type: typeof GET_BOARD_BY_ID_FAILURE;
}

export type BoardActionTypes =
  | ClearBoards
  | GetSpecialtyBoards
  | GetSpecialtyBoardsSuccess
  | GetSpecialtyBoardsFailure
  | GetBoards
  | GetBoardsSuccess
  | GetBoardsFailure
  | GetBoardById
  | GetBoardByIdSuccess
  | GetBoardByIdFailure;
