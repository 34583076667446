import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { KeyboardBackspaceRounded } from '@material-ui/icons';

// Components
import { Modal } from 'components/ContinuousImprovement/Modal';
import ShowHide from 'components/ShowHide/ShowHide';
import { DuplicateFileDetected } from 'components/ContinuousImprovement/Modal/templates/duplicate-file-detected';

// Store + Core

import { LearnerValidationToolBatchForm } from '../../forms/LearnerValidationToolBatchForm';
import { closeModal } from 'store/modal/actions';
import { isModalOpenSelector } from 'store/modal/selectors';
import { learnerFileSelector } from 'store/learner/selectors';
import { batchLearnerValidation, batchLearnerValidationClearAction } from 'store/learner/actions';
import { ManualDownloadLink } from 'components/ContinuousImprovement/ManualDownloadLink';
import { LEARNER_VALIDATION_TOOL_TEMPLATE_ID_MAP } from 'store/fileDownload/types';

// Types

export const LearnerValidationToolBatchPage = (): ReactElement => {
  const dispatch = useDispatch();
  const isModalOpen: boolean = useSelector(isModalOpenSelector);
  const file: { file: File } = useSelector(learnerFileSelector);
  const downloadRoute = '/leaernervalidationtool/batches/downloadtemplate';

  const onProceed = () =>
    dispatch(
      batchLearnerValidation({
        file: { file } as { file },
        shouldForce: true,
      }),
    );

  const onCancel = (): void => {
    dispatch(batchLearnerValidationClearAction());
    dispatch(closeModal());
  };

  return (
    <section className="form-container">
      <div className="form-title-container">
        <Link className="tertiary" to="/learners/validation/batch/history">
          View Upload History
          <KeyboardBackspaceRounded className="tertiary-icon" />
        </Link>
        <h4 className="title">
          <div className="eyebrow">Batch Upload For Learner Validation</div>
          Validate learner identities
        </h4>
      </div>
      <div className="form-flex-row">
        <div className="form-column">
          <ShowHide title="Help">
            <div className="help-container">
              <div className="help-list">
                <ul>
                  <li className="help-item help-item--xls">
                    <div className="heading">Excel</div>
                    <p>Upload your Excel file from one of our standard templates.</p>
                    <div className="batch-templates">
                      <ul>
                        <li>
                          <ManualDownloadLink
                            fileName={LEARNER_VALIDATION_TOOL_TEMPLATE_ID_MAP['0']}
                            to={downloadRoute}
                            id="lvtbatch"
                            map={LEARNER_VALIDATION_TOOL_TEMPLATE_ID_MAP}
                          >
                            <p>Batch Learner Validation Excel File</p>
                          </ManualDownloadLink>

                          <p>Use for learner validation</p>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </ShowHide>
        </div>
      </div>
      <div className="form-flex-row">
        <div className="form-column grid-4">
          <h5>1&#41; Prepare your file for upload.</h5>
          <div className="batch-templates">
            <div className="heading">Batch Templates</div>
            <ul>
              <li>
                <ManualDownloadLink
                  fileName={LEARNER_VALIDATION_TOOL_TEMPLATE_ID_MAP['0']}
                  to={downloadRoute}
                  id="lvtbatch"
                  map={LEARNER_VALIDATION_TOOL_TEMPLATE_ID_MAP}
                >
                  <p>Batch Learner Validation Excel File</p>
                </ManualDownloadLink>
                <p>Use for learner validation</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="form-column">
          <h5>2&#41; Upload your completed file.</h5>
          <LearnerValidationToolBatchForm />
        </div>
        <Modal isOpen={isModalOpen} onClose={() => dispatch(closeModal)} title="Potential duplicate file detected">
          <DuplicateFileDetected onCancel={onCancel} onProceed={onProceed} />
        </Modal>
      </div>
    </section>
  );
};
