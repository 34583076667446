import { Theme, withStyles } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    color: '#373737',
    padding: `0 ${theme.spacing(4.5)}px`,
    '& > ul': {
      paddingLeft: `${theme.spacing(2)}px`,
      '& > li': {
        marginBottom: `${theme.spacing(2)}px`,
      },
    },
    '&:last-child': {
      paddingBottom: `${theme.spacing(6)}px`,
    },
  },
}))(MuiDialogContent);
