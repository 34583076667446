import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Stepper } from 'components/Stepper';
import { IStepProps } from 'components/Stepper/Step';

// Styles
import styles from './BCTPageStyles.module.scss';

// Forms
import {
  ActivityRegistrationDetailsForm,
  ContentBlueprintDetailsForm,
  CreditTypeDetailsForm,
  LearnerCompletionDetailsForm,
  OrganizationDetailsForm,
  SuccessForm,
} from './forms';

// Core + Store
import { BCTSteps } from 'core/models';
import { currentBCTStepSelector, currentBoardSelector } from 'store/bct/selectors';
import { resetBCT, setBCTStep } from 'store/bct/actions';
import { BoardIntegrationTypes, BoardTypes, IBCTBoard, IBCTSteps } from './types';

export const BCTPage = (): ReactElement => {
  const dispatch = useDispatch();

  // Reset bct when leaving the page.
  useEffect(() => {
    // Comment out for local development and HMR.
    return () => dispatch(resetBCT());
  }, [dispatch]);

  // Selectors.
  const currentStep: BCTSteps = useSelector(currentBCTStepSelector);
  const currentBoard: IBCTBoard = useSelector(currentBoardSelector);

  // Board Type
  const isRems: boolean = currentBoard?.type === BoardTypes.REMS;
  const isLicensing: boolean = currentBoard?.type === BoardTypes.STATE_BOARD;
  const isIntTypeA: boolean = currentBoard?.boardIntegrationType === BoardIntegrationTypes.A;

  // Setup steps.
  const allSteps = Object.values(BCTSteps).map(
    (step: BCTSteps): IStepProps => ({
      label: step,
      stepStatus: currentStep === step && 'active',
      visible: true,
    }),
  );

  const handleClickStep = (label: BCTSteps): void => {
    dispatch(setBCTStep(label));
  };

  // PrevStep/NextStep props are dynamic. These functions give us flexibility to control what the prev/next steps are
  // based on the type of board that is currently selected.
  const creditTypeDetailsNextStep = (): BCTSteps => {
    if (isIntTypeA) return null;
    return BCTSteps.LEARNER_COMPLETION_DETAILS;
  };

  const activityRegistrationNextStep = (): BCTSteps => {
    if (isRems) return creditTypeDetailsNextStep();
    if (isLicensing) return creditTypeDetailsNextStep();
    return BCTSteps.CONTENT_BLUEPRINT_DETAILS;
  };

  const creditTypeDetailsPrevStep = (): BCTSteps => {
    if (isLicensing) return BCTSteps.ACTIVITY_REGISTRATION_DETAILS;
    return BCTSteps.CONTENT_BLUEPRINT_DETAILS;
  };

  const learnerCompletionDetailsPrevStep = (): BCTSteps => {
    if (isRems) return BCTSteps.ACTIVITY_REGISTRATION_DETAILS;
    if (isLicensing) return BCTSteps.ACTIVITY_REGISTRATION_DETAILS;
    return BCTSteps.CREDIT_TYPE_DETAILS;
  };

  const stepsMap: IBCTSteps = {
    [BCTSteps.ACTIVITY_REGISTRATION_DETAILS]: (
      <ActivityRegistrationDetailsForm
        nextStep={activityRegistrationNextStep()}
        prevStep={BCTSteps.ORGANIZATION_DETAILS}
      />
    ),
    [BCTSteps.CONTENT_BLUEPRINT_DETAILS]: (
      <ContentBlueprintDetailsForm
        prevStep={BCTSteps.ACTIVITY_REGISTRATION_DETAILS}
        nextStep={BCTSteps.CREDIT_TYPE_DETAILS}
      />
    ),
    [BCTSteps.CREDIT_TYPE_DETAILS]: (
      <CreditTypeDetailsForm prevStep={creditTypeDetailsPrevStep()} nextStep={creditTypeDetailsNextStep()} />
    ),
    [BCTSteps.LEARNER_COMPLETION_DETAILS]: (
      <LearnerCompletionDetailsForm prevStep={learnerCompletionDetailsPrevStep()} />
    ),
    [BCTSteps.ORGANIZATION_DETAILS]: <OrganizationDetailsForm nextStep={BCTSteps.ACTIVITY_REGISTRATION_DETAILS} />,
  };

  const filteredSteps = () => {
    // Start with all the steps
    let steps = allSteps;
    if (isRems) {
      // We don't render Content Blueprint Details & Credit Type Details if the board type is REMS.
      steps = steps.filter(
        (step: IStepProps): boolean =>
          step.label !== BCTSteps.CREDIT_TYPE_DETAILS && step.label !== BCTSteps.CONTENT_BLUEPRINT_DETAILS,
      );
    }
    if (isLicensing) {
      // We don't render Content Blueprint Details or Credit Type Details if the board type is STATE_BOARD.
      steps = steps.filter((step: IStepProps): boolean => {
        return step.label !== BCTSteps.CONTENT_BLUEPRINT_DETAILS && step.label !== BCTSteps.CREDIT_TYPE_DETAILS;
      });
    }
    if (isIntTypeA) {
      // 4381 - we don't render learner completion details for a Cert Board with Integration Type A
      steps = steps.filter((step: IStepProps): boolean => {
        return step.label !== BCTSteps.LEARNER_COMPLETION_DETAILS;
      });
    }

    return steps;
  };

  return (
    <>
      <section className="form-container">
        {currentBoard && stepsMap[currentStep] && <Stepper steps={filteredSteps()} handleClick={handleClickStep} />}
        {stepsMap[currentStep] && <h1 className={styles.title}>Board Configuration Tool</h1>}
        {stepsMap[currentStep] || <SuccessForm />}
      </section>
    </>
  );
};
