import { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components.
import { Modal } from 'components/ContinuousImprovement';
import { CommendationAccordion } from './CommendationAccordion';

// Core.
import { TAXONOMY_COMMENDATION_CRITERIA_ROOT_ID } from 'core/constants';

// Globals.
import { filterTaxonomyByOrg } from 'globals/utils/filterTaxonomyByOrg';

// Store.
import { allCommendationCriterionSelector } from 'store/taxonomy/selectors';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { currentOrganizationContextSelector } from 'store/user/selectors';

interface ICommendationCriteriaModalProps {
  isOpen?: boolean;
  onClose?: VoidFunction;
}

export const CommendationCriteriaModal = (props: ICommendationCriteriaModalProps): ReactElement => {
  const { isOpen, onClose } = props;
  const dispatch = useDispatch();
  const commendationCriteria = useSelector(allCommendationCriterionSelector);
  const currentOrganization = useSelector(currentOrganizationContextSelector);

  const headings = useMemo(
    () => filterTaxonomyByOrg({ organization: currentOrganization, terms: commendationCriteria }),
    [commendationCriteria, currentOrganization],
  );

  useEffect(() => {
    if (!commendationCriteria) {
      dispatch(getTaxonomyTermById(TAXONOMY_COMMENDATION_CRITERIA_ROOT_ID));
    }
  }, [dispatch, commendationCriteria]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="About Commendation Criteria">
      {headings?.map((term) => (
        <CommendationAccordion key={term.id} term={term} />
      ))}
    </Modal>
  );
};
