/* eslint-disable sort-keys */
import * as yup from 'yup';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { Field, FormikProvider, useFormik } from 'formik';
import { KeyboardBackspaceRounded } from '@material-ui/icons';
import classNames from 'classnames';

import Button from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';
import { ButtonVariant } from 'core/enums';

import { Accreditation, IAccreditationType } from 'core/models';

import { handleServerError } from 'globals/utils/handleServerError';
import { popToast } from 'store/toast/actions';
import { errorToastOptions, successToastOptions } from 'store/toast/constants';

import { useAccountManagerInfo } from '../hooks';

// style
import styles from './AddEditAccreditation.module.scss';
import fieldStyles from 'components/ContinuousImprovement/PureFormElements/FormikFields.module.scss';
import { AccreditationService } from 'services/Accreditation';
import { useLoadData } from 'hooks/useLoadData';
import { CircularProgress, Grid } from '@material-ui/core';
import InputBlock from 'components/global/inputBlock/inputBlock';
import DatePicker from 'components/DatePicker/DatePicker';
import { filterItemsByRollupOrganizationEnum } from 'globals/utils/filterItemsByRollupOrgCode';

// SchemaOf (not SchemaObject) is used for our version of yup - https://github.com/jquense/yup/blob/v0.32.8/docs/typescript.md
const addEditSchema: yup.SchemaOf<
  Omit<Accreditation, 'progressReportDueDate' | 'effectiveDate' | 'expirationDate'>
> = yup.object({
  id: yup.string(),
  accreditationTypeId: yup.string().required('Accreditation Type is required'),
  accreditationType: yup.string(),
  progressReportDueDate: yup.date().typeError(''),
  effectiveDate: yup.date().required('Effective Date is required').typeError(''),
  expirationDate: yup.date().required('Expiration Date is required').typeError(''),
});

export const AccountManagerAddEditAccreditationPage = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { canEdit, organization, isAnccOrganization } = useAccountManagerInfo();

  const { id } = useParams<{ id: string }>();
  const [existingAccreditation, setExistingAccreditation] = useState<Accreditation | null>(null);

  const { data } = useLoadData(
    'AccreditationType',
    useCallback(async () => {
      return await AccreditationService.getAccreditationTypes();
    }, [id]),
  );

  let accreditationTypes: IAccreditationType[] = filterItemsByRollupOrganizationEnum(
    data,
    organization.rollupOrganizationEnum,
  );

  accreditationTypes = isAnccOrganization
    ? accreditationTypes
    : accreditationTypes.filter(
        (item) => item.description !== 'Accreditation' && item.description !== 'Accreditation with Distinction',
      );

  useEffect(() => {
    (async () => {
      if (id) {
        try {
          const accreditation = await AccreditationService.getAccreditation(id);
          setExistingAccreditation(accreditation);
        } catch (error) {
          dispatch(popToast({ ...errorToastOptions, message: <>{handleServerError({ error }).errorMessage}</> }));
          history.push('/account-manager');
        }
      }
    })();
  }, [id, dispatch]);

  const formik = useFormik<Accreditation>({
    enableReinitialize: true,
    initialValues: { ...(existingAccreditation || {}) } as Accreditation,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { accreditationTypeId, effectiveDate, expirationDate, progressReportDueDate } = values;
        const baseReq: Omit<Accreditation, 'id' | 'accreditationType'> = {
          accreditationTypeId,
          effectiveDate,
          expirationDate,
          progressReportDueDate,
        };
        const req = {
          ...baseReq,
          providerId: organization.id,
        };
        if (id) {
          await AccreditationService.updateAccreditationInformation(id, req);
          dispatch(popToast({ ...successToastOptions, message: <>Successfully updated.</> }));
          history.push('/account-manager');
        } else {
          await AccreditationService.addAccreditationInformation(req);
          dispatch(popToast({ ...successToastOptions, message: <>Successfully created.</> }));

          resetForm();
        }
      } catch (error) {
        dispatch(popToast({ ...errorToastOptions, message: <>{handleServerError({ error }).errorMessage}</> }));
      }
    },
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: addEditSchema,
  });
  const { isValid, errors, touched, isSubmitting, handleBlur, submitForm } = formik;

  if (!canEdit) {
    return <Redirect to="/account-manager" />;
  }

  return (
    <div className="form-container">
      <div className="page-navigation">
        <Link className="tertiary" to="/account-manager">
          <KeyboardBackspaceRounded className="tertiary-icon-back" />
          Account Management
        </Link>
      </div>
      <div className="form-title-container">
        <div className={classNames('title', styles.title)}>
          <h4>{id ? 'Edit' : 'Add New'} Accreditation Decision</h4>
        </div>
      </div>
      <FormikProvider value={formik}>
        <div className="static-form">
          <Grid item xs={12} md={9} lg={6} className={styles.input}>
            <label className={fieldStyles.label} htmlFor="addressCountry">
              Accreditation Type
            </label>
            <Dropdown
              items={accreditationTypes}
              name="accreditationTypeId"
              placeholder="Accreditation Type"
              idProp="id"
              labelProp="description"
            />
            <InputBlock errors={errors} name="progressReportDueDate" touched={touched} title="Progress Report Due Date">
              <Field label="Progress Report Due Date" name="progressReportDueDate">
                {({ field }) => (
                  <DatePicker
                    onBlur={handleBlur}
                    className={styles.input}
                    field={field}
                    formikKey="progressReportDueDate"
                    timeOfDay="midnight"
                  />
                )}
              </Field>
            </InputBlock>
            <InputBlock errors={errors} name="effectiveDate" touched={touched} title="Effective Date">
              <Field label="Effective Date" name="effectiveDate">
                {({ field }) => (
                  <DatePicker
                    onBlur={handleBlur}
                    className={styles.input}
                    field={field}
                    formikKey="effectiveDate"
                    timeOfDay="midnight"
                  />
                )}
              </Field>
            </InputBlock>
            <InputBlock errors={errors} name="expirationDate" touched={touched} title="Expiration Date">
              <Field label="Expiration Date" name="expirationDate">
                {({ field }) => (
                  <DatePicker
                    onBlur={handleBlur}
                    className={styles.input}
                    field={field}
                    formikKey="expirationDate"
                    timeOfDay="midnight"
                  />
                )}
              </Field>
            </InputBlock>
          </Grid>
          <Grid item xs={12} md={9} lg={6}>
            <div className={styles['submit-button-container']}>
              <Button
                disabled={!isValid || isSubmitting}
                onClick={submitForm}
                startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
                variant={ButtonVariant.Primary}
              >
                Save Changes
              </Button>
            </div>
          </Grid>
        </div>
      </FormikProvider>
    </div>
  );
};
