import { ReactElement } from 'react';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';

// Types
import { FormikTextFieldProps } from '../types';

export const FormikTextField = ({ formikKey, ...props }: FormikTextFieldProps): ReactElement => {
  const [field, meta] = useField<string>(formikKey);
  const { name, onChange, value } = field;
  const { error, touched } = meta;

  return (
    <TextField
      className="form-input"
      error={touched && !!error}
      helperText={meta ? error : ''}
      id={name}
      name={name}
      onChange={onChange}
      value={value}
      {...props}
    />
  );
};
