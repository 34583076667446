import {
  DeleteRounded,
  Description,
  EditRounded,
  FileCopyRounded,
  Restore,
  TextFields,
  VisibilityRounded,
} from '@material-ui/icons';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components
import Button from 'components/Button/Button';

// Store + Core
import { ButtonVariant } from 'core/enums';
import { isAccreditorUserSelector, isBoardUserSelector, isStaffUserSelector } from 'store/user/selectors';

interface IProps {
  activityId: string;
  id: string;
  isClosed?: boolean;
  isDisabled: boolean;
  isDeleted: boolean;
  isSelectedForReviewStaffOrAccreditor?: boolean;
  isStaffOrAccreditor?: boolean;
  onCopy(boolean): void;
  onDelete(): void;
  onRestore(): void;
  onDeselectForReview?(): void;
  onEnterOutcome?(): void;
  onSelectForReview?(): void;
  title: string;
}

export const UtilityBtns = (props: IProps): ReactElement => {
  const {
    activityId,
    id,
    isClosed,
    isDisabled,
    isDeleted,
    isSelectedForReviewStaffOrAccreditor,
    isStaffOrAccreditor,
    onCopy,
    onDelete,
    onDeselectForReview,
    onEnterOutcome,
    onRestore,
    onSelectForReview,
    title,
  } = props;

  // Selectors
  const isBoardUser: boolean = useSelector(isBoardUserSelector);
  const isStaffUser: boolean = useSelector(isStaffUserSelector);
  const isAccreditorUser: boolean = useSelector(isAccreditorUserSelector);
  const isDisabledUser: boolean = isStaffUser || isAccreditorUser || isBoardUser;

  const ViewLink = (): ReactElement => (
    <Link aria-label={`View ${title}: ${activityId}`} className="btn label" to={`/activities/detail/${id}`}>
      <VisibilityRounded />
      View
    </Link>
  );

  // A Board user can only view an activity.
  if (isBoardUser) return <ViewLink />;

  return (
    <>
      <ViewLink />
      {!isDeleted && !isClosed && (
        <Link aria-label={`Edit ${title}: ${activityId}`} className="btn label" to={`/activities/detail/${id}`}>
          <EditRounded />
          Edit
        </Link>
      )}

      <Button
        aria-label={`Copy ${title}: ${activityId}`}
        disabled={isDisabledUser}
        variant={ButtonVariant.Label}
        onClick={(): void => onCopy(true)}
      >
        <FileCopyRounded />
        Copy
      </Button>

      {/* No one can delete closed activities. */}
      {isClosed
        ? null
        : !isDeleted &&
          !isSelectedForReviewStaffOrAccreditor && (
            <Button
              aria-label={`Delete ${title}: ${activityId}`}
              disabled={isDisabled}
              variant={ButtonVariant.Label}
              onClick={onDelete}
            >
              <DeleteRounded />
              Delete
            </Button>
          )}
      {isDeleted && (
        <Button aria-label={`Restore ${title}: ${activityId}`} variant={ButtonVariant.Label} onClick={onRestore}>
          <Restore />
          Restore
        </Button>
      )}
      {isStaffOrAccreditor && (
        <>
          {isSelectedForReviewStaffOrAccreditor && (
            <>
              <Button onClick={onEnterOutcome} variant={ButtonVariant.Label}>
                <TextFields />
                Enter/Edit Outcome
              </Button>
              <Button onClick={onDeselectForReview} variant={ButtonVariant.Label}>
                <Description />
                Deselect For Review
              </Button>
            </>
          )}
          <Button onClick={onSelectForReview} variant={ButtonVariant.Label}>
            <Description />
            Select For Review
          </Button>
        </>
      )}
    </>
  );
};
