import { PowerBIEmbed } from 'powerbi-client-react';
import * as models from 'powerbi-models';
import { ReactElement, useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';

// Components
import { HighlightBox } from 'components/HighlightBox';
import { LoadingOverlay } from 'components/LoadingOverlay';

// Hooks.
import { useQueryString } from 'hooks';

// Store.
import { getPowerReportById, getReportsByWorkspaceId } from 'store/report/actions';
import {
  isLoadingReportSelector,
  isLoadingReportsSelector,
  reportSelector,
  reportsSelector,
} from 'store/report/selectors';
import { currentOrganizationContextSelector } from 'store/user/selectors';

// Styles.
import './report.module.scss';

export const ReportViewPage = (): ReactElement => {
  const { userId, reportId } = useParams<{ reportId: string; userId: string }>();
  const { endDate, startDate } = useQueryString<{ endDate: string; startDate: string }>();
  const dispatch = useDispatch();
  const reports = useSelector(reportsSelector);
  const report = useSelector(reportSelector);
  const isLoadingReports = useSelector(isLoadingReportsSelector);
  const isLoadingReport = useSelector(isLoadingReportSelector);
  const currentOrganization = useSelector(currentOrganizationContextSelector);

  const filters = useMemo(() => {
    const raw = reports?.find((r) => r.reportId === reportId)?.parameterAssignment || '';
    const processed = raw
      .replace('@BusinessId', currentOrganization?.businessId)
      .replace('@StartDate', startDate)
      .replace('@EndDate', endDate);
    return processed ? JSON.parse(processed) : [];
  }, [currentOrganization, endDate, reportId, reports, startDate]);

  // Need to get all report metadata for filters.
  useEffect(() => {
    if (!reports && currentOrganization) {
      dispatch(
        getReportsByWorkspaceId(
          currentOrganization.businessId,
          currentOrganization.organizationKind,
          currentOrganization.rollupOrganizationEnum,
        ),
      );
    }
  }, [dispatch, currentOrganization, reports]);

  // using useLayoutEffect here makes sure we start the load and immediately re-render with the updated state *before* the user sees anything
  // if we don't do this, we'll initially render with report and reports both null and not loading, which shows the error box, then immediately start loading and show the spinner
  useLayoutEffect(() => {
    dispatch(getPowerReportById(userId, reportId));
  }, [dispatch, userId, reportId]);

  if (isLoadingReport || isLoadingReports) {
    return <LoadingOverlay isOpen />;
  }

  return (
    <div>
      {report && reports ? (
        <PowerBIEmbed
          embedConfig={{
            accessToken: report.embedToken.token,
            embedUrl: report.embedReport && report.embedReport[0]?.embedUrl,
            filters,
            id: report.embedReport && report.embedReport[0]?.reportId,
            settings: {
              background: models.BackgroundType.Transparent,
            },
            tokenType: models.TokenType.Embed,
            type: 'report',
          }}
          cssClassName="report-style-class"
        />
      ) : (
        <HighlightBox variant="danger">
          <Box display="flex" component="p">
            <WarningRounded />
            <Box ml={2}>Cannot load report.</Box>
          </Box>
        </HighlightBox>
      )}
    </div>
  );
};
