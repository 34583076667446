import React, { ReactElement, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import { Box, CircularProgress } from '@material-ui/core';

// Components
import Button from 'components/Button/Button';

// Actions
import { toggleCloseActivity } from 'store/activity/actions';

// Core + Store
import { ActivitySearchResultActivity } from 'core/models';
import { StatusEnum } from 'core/enums';
import { HighlightBox } from 'components/HighlightBox';
import { WarningRounded } from '@material-ui/icons';

type IProps = {
  callback?(): void;
  onClose?(): void;
  selectedActivities: ActivitySearchResultActivity[];
};
export const BulkCloseActivitiesForm = (props: IProps): ReactElement => {
  const dispatch = useDispatch();
  const { callback, onClose, selectedActivities } = props;
  const [failedToClose, setFailedToClose] = useState<string[]>([]);
  const activitiesIdsFailedToClose = useMemo(() => {
    return selectedActivities
      .filter((a) => failedToClose.includes(a.key))
      .map((a) => a.id)
      .sort();
  }, [failedToClose, selectedActivities]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (_: FormikValues, { setSubmitting }) => {
      setFailedToClose([]);
      const failed: string[] = ((await dispatch(
        toggleCloseActivity({
          activityIds: selectedActivities
            .map((activity: ActivitySearchResultActivity): string => activity.key || '')
            .filter((id: string): boolean => id !== ''),
          status: StatusEnum.READY_TO_CLOSE,
        }),
      )) as any) as string[];
      // I'm not clear why the cast above is necessary, I think it has to do with the exact type of `useDispatch` not being aware of our rootstate, but the thunk is?

      setFailedToClose(failed);

      // We are done submitting.
      await setSubmitting(false);

      if (failed.length === 0) {
        // Close the modal.
        await onClose();

        // Update the redux state.
        await callback?.();
      }
    },
  });

  const { handleSubmit, isSubmitting } = formik;

  return failedToClose.length ? (
    <>
      <HighlightBox variant="danger">
        <Box display="flex" component="p">
          <WarningRounded />
          <Box ml={2}>
            The following {activitiesIdsFailedToClose.length}{' '}
            {activitiesIdsFailedToClose.length === 1 ? 'activity' : 'activities'} could not be closed:
            <ul>
              {activitiesIdsFailedToClose.map((id, ix) => (
                <li key={ix}>{id}</li>
              ))}
            </ul>
          </Box>
        </Box>
      </HighlightBox>
      <div className="modal-button-row">
        <Button className="primary" onClick={onClose}>
          Ok
        </Button>
      </div>
    </>
  ) : (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        {`Are you sure you want to close ${selectedActivities.length} item(s)?`}
        <div className="modal-button-row">
          <Button
            className="primary"
            disabled={isSubmitting}
            type="submit"
            startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
          >
            Yes, Close
          </Button>
          <Button className="secondary" disabled={isSubmitting} onClick={onClose}>
            No, Don't Close
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};
