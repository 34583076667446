import { CheckRounded, Info, ReportProblemRounded } from '@material-ui/icons';
import { ReactElement } from 'react';

export enum DashboardNotificationSeverity {
  Error = 'error',
  Info = 'info',
  Success = 'success',
}

// TODO: There's got to be a better way to type this.
export interface IDashboardNotificationSeverityIconTypes {
  [DashboardNotificationSeverity.Error](any): ReactElement;
  [DashboardNotificationSeverity.Info](any): ReactElement;
  [DashboardNotificationSeverity.Success](any): ReactElement;
}

// TODO: There's got to be a better way to type this.
export const DashboardNotificationSeverityIcons: IDashboardNotificationSeverityIconTypes = {
  [DashboardNotificationSeverity.Error]: (props): ReactElement => <ReportProblemRounded {...props} />,
  [DashboardNotificationSeverity.Info]: (props): ReactElement => <Info {...props} />,
  [DashboardNotificationSeverity.Success]: (props): ReactElement => <CheckRounded {...props} />,
};

export enum DashboardNotificationCategories {
  Activities = 'Activities',
  Learners = 'Learners',
  Program = 'Program',
}

export interface DashboardState {
  banners: IDashboardBanner[];
  data: IDashboard;
  isLoading: boolean;
  isLoadingBanners: boolean;
  isLoadingMessage: boolean;
  message: IDashboardMessage;
}

export interface IDashboard {
  banners: IDashboardBanner[];
  asOf: Date;
  blocks: IDashboardBlock[];
  messages: IDashboardMessage[];
  notifications: IDashboardNotification[];
}

export interface IDashboardBanner {
  id: string;
  isJa: boolean;
  isNars: boolean;
  content: string;
  linkText: string;
  linkUrl: string;
}

export interface IDashboardBlock {
  links?: IDashboardLink[];
  sections: DashboardSection[];
}

type DashboardSection = IDashboardBreakdownSection | IDashboardChartSection | IDashboardMetricSection;

export enum DashboardSectionTypes {
  Breakdown = 'breakdown',
  Chart = 'chart',
  Metric = 'metric',
}

export interface IDashboardSection {
  label?: string;
  toggleable?: boolean;
}

export interface IDashboardBreakdownSection extends IDashboardSection {
  data: IDashboardBreakdownData[];
  type: DashboardSectionTypes.Breakdown;
}

export interface IDashboardBreakdownData {
  color: string;
  link: string;
  name: string;
  y: number;
}

export interface IDashboardChartSection extends IDashboardSection {
  chartData: Partial<Highcharts.Options>;
  type: DashboardSectionTypes.Chart;
}

export interface IDashboardMetricSection extends IDashboardSection {
  description: string;
  type: DashboardSectionTypes.Metric;
  value?: string;
}

export interface IDashboardLink {
  label: string;
  href: string;
}

export interface IDashboardMessage {
  content: string;
  endDate?: Date;
  id: string;
  isJa: boolean;
  isNars: boolean;
  linkText: string;
  linkUrl: string;
  startDate?: Date;
}

export interface IDashboardMessageAction {
  link: string;
  text: string;
}

export interface IDashboardNotification {
  actions: IDashboardNotificationAction[];
  category: DashboardNotificationCategories;
  description: string;
  id: string;
  severity: DashboardNotificationSeverity;
}

export interface IDashboardNotificationAction {
  link: string;
  text: string;
}

// Dashboard actions.
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';

// Banner actions.
export const GET_DASHBOARD_BANNERS = 'GET_DASHBOARD_BANNERS';
export const GET_DASHBOARD_BANNERS_SUCCESS = 'GET_DASHBOARD_BANNERS_SUCCESS';
export const GET_DASHBOARD_BANNERS_FAILURE = 'GET_DASHBOARD_BANNERS_FAILURE';

// Message actions.
export const GET_DASHBOARD_MESSAGE = 'GET_DASHBOARD_MESSAGE';
export const GET_DASHBOARD_MESSAGE_SUCCESS = 'GET_DASHBOARD_MESSAGE_SUCCESS';
export const GET_DASHBOARD_MESSAGE_FAILURE = 'GET_DASHBOARD_MESSAGE_FAILURE';

// Dismiss actions.
export const DISMISS_DASHBOARD_BANNER = 'DISMISS_DASHBOARD_BANNER';
export const DISMISS_DASHBOARD_MESSAGE = 'DISMISS_DASHBOARD_MESSAGE';
export const DISMISS_DASHBOARD_NOTIFICATION = 'DISMISS_DASHBOARD_NOTIFICATION';
