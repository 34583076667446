import { AxiosRequestConfig } from 'axios';
import { BaseApiService, IJsonPatchDocumentOperation } from './baseApiService';
import { StatusEnum } from './enums';
import {
  HasPharmacyContentTags,
  HasPharmacyRecertifications,
  HasStateContentTags,
  IContentOutline,
  INextSequenceNumber,
} from './models';

interface IDictionary<TValue> {
  [key: string]: TValue;
}

export interface ICommercialSupportSource {
  source: string;
  amountGiven?: number;
  hasInKindSupport?: boolean;
}

export interface IBoardMocDetails {
  practiceIds?: Array<string>;
  typesOfCreditIds?: Array<string>;
  mocPointsGiven?: number;
  contentOutlines?: Array<IContentOutline>;
}

export enum ActivityLocationType {
  None = 0,
  InPerson = 1,
  Online = 2,
  Other = 4,
}

export interface IBaseActivity {
  title: string;
  typeId: string;
  locationType?: string[];
  startDate: Date;
  endDate: Date;
  country?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  credits?: IDictionary<number>;
  includeInCmeFinder?: boolean;
  description?: string;
  isRestrictedAudience?: boolean;
  participationFeeTypeId?: string;
  detailsUrl?: string;
  isMoc?: boolean;
  isMips?: boolean;
  isRems?: boolean;
  supportedRemsIds?: Array<string>;
  internalId?: string;
  isJointlyProvided?: boolean;
  jointProviderIds?: Array<string>;
  outcomesMeasured?: Array<string>;
  hasCommercialSupport?: boolean;
  hasPharmacyRecertifications?: HasPharmacyRecertifications;
  hasPharmacyContentTags?: HasPharmacyContentTags;
  hasStateContentTags?: HasStateContentTags;
  commercialSupportSources?: Array<ICommercialSupportSource>;
  markedForCommendationCriteria?: boolean;
  commendationCriteriaIds?: Array<string>;
  boardMocDetails?: IDictionary<IBoardMocDetails>;
  mocCreditDeadline?: Date;
  mocProgramAttestation?: boolean;
  remsRpcId?: string;
  isRemsCompliant?: boolean;
  learnerCounts?: IDictionary<number>;
  status?: StatusEnum;
  programs?: Array<string>;
  type?: string;
  activityId?: string;
  contentTags?: string[];
  pharmacyContentTagTaxonomyTerms?: string[];
  pharmacyContentTagStateTaxonomyTerms?: string[];
  pharmacyRecertificationTaxonomyTerms?: string[];
}

export interface IActivity extends IBaseActivity {
  id: string;
  printableId: string;
  providerName: string;
  createdDate: Date;
}

export type ICreateActivityRequest = IBaseActivity;

export interface IUpdateActivityRequest extends IBaseActivity {
  id: string;
}

export class ActivitiesApiService extends BaseApiService {
  public async createActivitiesAsync(
    createActivitiesRequests: Array<ICreateActivityRequest>,
  ): Promise<Array<IActivity>> {
    const createdActivities: Array<IActivity> = await super.postAsync('/api/activities', createActivitiesRequests);

    createdActivities.forEach(this.formatActivityFromServer);

    return createdActivities;
  }

  public async patchActivityAsync(id: string, patchRequest: IJsonPatchDocumentOperation[]): Promise<void> {
    await super.patchAsync(`/api/activities/${id}`, patchRequest);
  }

  public async updateActivityAsync(updateActivityRequest: IUpdateActivityRequest): Promise<IActivity> {
    const updatedActivity: IActivity = await super.putAsync(
      `/api/activities/${updateActivityRequest.id}`,
      updateActivityRequest,
    );

    this.formatActivityFromServer(updatedActivity);

    return updatedActivity;
  }

  private formatActivityFromServer(activity: IActivity): void {
    const currentLocationType = activity.locationType;
    const currentStartDate: Date | string | undefined = activity.startDate;
    const currentEndDate: Date | string | undefined = activity.endDate;

    if (currentLocationType === null || currentLocationType === undefined) {
      activity.locationType = [ActivityLocationType.None.toString()];
    }

    if (currentStartDate === null || currentStartDate === undefined) {
      activity.startDate = null as any;
    } else if (typeof currentStartDate === 'string') {
      activity.startDate = new Date(currentStartDate);
    }

    if (currentEndDate === null || currentEndDate === undefined) {
      activity.endDate = null as any;
    } else if (typeof currentEndDate === 'string') {
      activity.endDate = new Date(currentEndDate);
    }
  }

  public async getNextSequenceNumber(year: number): Promise<INextSequenceNumber> {
    const config: AxiosRequestConfig = { params: { year } };
    return await super.getAsync('/api/activities/nextsequencenumber', config);
  }
}
