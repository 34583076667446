import { ThunkAction } from 'redux-thunk';
import {
  GET_ACTIVITY_PROVIDER_FILES,
  GET_ACTIVITY_PROVIDER_FILES_FAILURE,
  GET_ACTIVITY_PROVIDER_FILES_SUCCESS,
  GET_ACTIVITY_PROVIDER_FILES_LOADING,
  GET_LEARNER_PROVIDER_FILES,
  GET_LEARNER_PROVIDER_FILES_FAILURE,
  GET_LEARNER_PROVIDER_FILES_SUCCESS,
  GET_LEARNER_PROVIDER_FILES_LOADING,
  GET_LEAERNER_VALIDATION_PROVIDER_FILES,
  GET_LEAERNER_VALIDATION_PROVIDER_FILES_SUCCESS,
  GET_LEAERNER_VALIDATION_PROVIDER_FILES_LOADING,
  GET_LEAERNER_VALIDATION_PROVIDER_FILES_FAILURE,
} from './types';

// Core + Store
import { AppState } from 'store';
import { PARSAction, IProviderFile, ILeaernerValidationProviderFile } from 'core/models';
import { popToast } from 'store/toast/actions';
import { ProviderFilesApiService } from 'core/providerFilesApiService';
import { errorToastOptions } from '../toast/constants';
import { Action, AnyAction } from 'redux';
import { handleServerError } from '../../globals/utils/handleServerError';

export const getActivityProviderFiles = (): Action => ({
  type: GET_ACTIVITY_PROVIDER_FILES,
});

export const getActivityProviderFilesSuccess = (activityFiles: IProviderFile[]): AnyAction => ({
  payload: activityFiles,
  type: GET_ACTIVITY_PROVIDER_FILES_SUCCESS,
});

export const getActivityProviderFilesLoading = (isLoading: boolean): AnyAction => ({
  payload: isLoading,
  type: GET_ACTIVITY_PROVIDER_FILES_LOADING,
});

export const getActivityProviderFilesFailure = (errorMessage: string): AnyAction => ({
  payload: errorMessage,
  type: GET_ACTIVITY_PROVIDER_FILES_FAILURE,
});

export const getLearnerProviderFiles = (): Action => ({
  type: GET_LEARNER_PROVIDER_FILES,
});

export const getLearnerProviderFilesSuccess = (learnerFiles: IProviderFile[]): AnyAction => ({
  payload: learnerFiles,
  type: GET_LEARNER_PROVIDER_FILES_SUCCESS,
});

export const getLearnerProviderFilesLoading = (isLoading: boolean): AnyAction => ({
  payload: isLoading,
  type: GET_LEARNER_PROVIDER_FILES_LOADING,
});

export const getLearnerProviderFilesFailure = (errorMessage: string): AnyAction => ({
  payload: errorMessage,
  type: GET_LEARNER_PROVIDER_FILES_FAILURE,
});

export const getLeaernerValidationProviderFiles = (): Action => ({
  type: GET_LEAERNER_VALIDATION_PROVIDER_FILES,
});

export const getLeaernerValidationProviderFilesSuccess = (
  learnerValidationFiles: ILeaernerValidationProviderFile[],
): AnyAction => ({
  payload: learnerValidationFiles,
  type: GET_LEAERNER_VALIDATION_PROVIDER_FILES_SUCCESS,
});

export const getLeaernerValidationProviderFilesLoading = (isLoading: boolean): AnyAction => ({
  payload: isLoading,
  type: GET_LEAERNER_VALIDATION_PROVIDER_FILES_LOADING,
});

export const getLeaernerValidationProviderFilesFailure = (errorMessage: string): AnyAction => ({
  payload: errorMessage,
  type: GET_LEAERNER_VALIDATION_PROVIDER_FILES_FAILURE,
});

// Thunk Actions
export const getActivityProviderFilesById = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
  getState,
) => {
  // Loading: update redux state.
  await dispatch(getActivityProviderFiles());
  await dispatch(getActivityProviderFilesLoading(true));
  try {
    // API fetch.
    const route: ProviderFilesApiService = new ProviderFilesApiService();
    const files: IProviderFile[] = await route.getActivityBatchUploadHistoryAsync();

    // Start loading.
    await dispatch(getActivityProviderFilesLoading(true));

    // Success: update redux state.
    await dispatch(getActivityProviderFilesSuccess(files));
  } catch (error) {
    handleServerError({ error, thunkName: 'getActivityProviderFilesById' });
    // Error: update redux state.
    await dispatch(getActivityProviderFilesFailure(error));

    // Tell the user there was an error..
    await dispatch(popToast({ ...errorToastOptions, message: <>{getState().providerFiles.error}</> }));
  }

  // Done loading.
  await dispatch(getActivityProviderFilesLoading(false));
};

export const getLeaernerValidationProviderFilesById = (): ThunkAction<
  Promise<void>,
  AppState,
  null,
  PARSAction
> => async (dispatch, getState) => {
  // Loading: update redux state.
  await dispatch(getLeaernerValidationProviderFiles());
  await dispatch(getLeaernerValidationProviderFilesLoading(true));
  try {
    // API fetch.
    const route: ProviderFilesApiService = new ProviderFilesApiService();
    const files: ILeaernerValidationProviderFile[] = await route.getLearnerValidationBatchUploadHistoryAsync();

    // Start loading.
    await dispatch(getLeaernerValidationProviderFilesLoading(true));

    // Success: update redux state.
    await dispatch(getLeaernerValidationProviderFilesSuccess(files));
  } catch (error) {
    handleServerError({ error, thunkName: 'getLeaernerValidationProviderFilesById' });
    // Error: update redux state.
    await dispatch(getLeaernerValidationProviderFilesFailure(error));

    // Tell the user there was an error..
    await dispatch(popToast({ ...errorToastOptions, message: <>{getState().providerFiles.error}</> }));
  }

  // Done loading.
  await dispatch(getLeaernerValidationProviderFilesLoading(false));
};

export const getLearnerProviderFilesById = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
  getState,
) => {
  // Loading: update redux state.
  await dispatch(getLearnerProviderFiles());
  await dispatch(getLearnerProviderFilesLoading(true));
  try {
    // API fetch.
    const route: ProviderFilesApiService = new ProviderFilesApiService();
    const files: IProviderFile[] = await route.getLearnerBatchUploadHistoryAsync();

    // Start loading.
    await dispatch(getLearnerProviderFilesLoading(true));

    // Success: update redux state.
    await dispatch(getLearnerProviderFilesSuccess(files));
  } catch (error) {
    handleServerError({ error, thunkName: 'getLearnerProviderFilesById' });
    // Error: update redux state.
    await dispatch(getLearnerProviderFilesFailure(error));

    // Tell the user there was an error..
    await dispatch(popToast({ ...errorToastOptions, message: <>{getState().providerFiles.error}</> }));
  }

  // Done loading.
  await dispatch(getLearnerProviderFilesLoading(false));
};
