// Libs
import { keys } from 'lodash';
import moment from 'moment';

// Core
import {
  BoardMocDetails,
  IAddLearnersFormModel,
  ICertifyingBoardFormModel,
  ICompletionBoard,
  IDictionary,
  IFormInputOption,
  ILearnerCompletion,
  ILearnerCredits,
  ILearnerMatchingModel,
  ITaxonomyTerm,
} from 'core/models';
import { BoardTypes, IBCTBoard, IBCTCreditType } from 'layouts/pages/bct/types';

interface IBoardsAndRems {
  moc?: IBCTBoard[];
  rems?: IBCTBoard[];
  filterList: string[]; // Used to filter out board/collabs that aren't part of an activity
}

type CreditsFilterBuilder = IBCTCreditType & {
  boardCreditIdDictionary: IDictionary<string[]>;
  boardId: string;
};

interface ICreditOptionsBuilder {
  activityBoardIds: string[];
  boardMocDetails: IDictionary<BoardMocDetails>;
  modernBoardsDictionary: IDictionary<IBCTBoard>;
}

interface ISubmitLearnerDetails {
  creditId: string;
  addLearnersFormModel: IAddLearnersFormModel;
  nonRemsBoardIds: string[];
  remsBoardIds: string[];
  universalLearnerId: string;
}

/**
 * @function buildBoardOptions
 * @description Convert Boards + REMS into an Input Options array
 *
 * @param IBoardsAndRems
 * @returns IFormInputOption[]
 */
export const buildBoardOptions = ({ moc = [], rems = [], filterList }: IBoardsAndRems): IFormInputOption[] => {
  if (!rems && !moc) return [];

  const combinedMocRems: IBCTBoard[] = [...moc, ...rems];

  return combinedMocRems
    ?.map(({ id, name }: IBCTBoard): IFormInputOption => ({ Id: id, Title: name }))
    ?.filter(({ Id }) => filterList?.includes(Id));
};

/**
 * @function mapTermToFormInputOption
 * @description Convert a Taxonomy term into an Input Option
 *
 * @param ITaxonomyTerm
 * @returns IFormInputOption
 */
export const mapTermToFormInputOption = ({
  id = '',
  name = '',
}: Pick<ITaxonomyTerm, 'id' | 'name'>): IFormInputOption => ({
  Id: id,
  Title: name,
});

export interface ICreditTypeFormOption
  extends IFormInputOption,
    Pick<IBCTCreditType, 'learnerCompletionEndDate' | 'learnerCompletionStartDate'> {}

const generateCreditOptionsFromArray = (creditTypes: IBCTCreditType[] = []): ICreditTypeFormOption[] => {
  return creditTypes.map(
    ({
      id,
      default: isDefault,
      organizationName,
      learnerCompletionEndDate,
      learnerCompletionStartDate,
    }: IBCTCreditType): ICreditTypeFormOption => ({
      Id: id,
      Title: organizationName,
      isDefault,
      isDisabled: isDefault,
      learnerCompletionEndDate,
      learnerCompletionStartDate,
    }),
  );
};

// Filter board credits by board per activity credits + configured credits (can't filter by date here because we don't have completion date available)
const filterCreditTypes = ({ boardCreditIdDictionary, boardId, id }: CreditsFilterBuilder): boolean => {
  // If the creditType is not included in this activity, return false
  if (!boardCreditIdDictionary?.[boardId]?.includes(id)) return false;
  return true;
};

// Combine the activity's credit types and the board configurations into a filtered dictionary of credit type options
export const generateCreditOptionsDictionary = ({
  activityBoardIds,
  boardMocDetails,
  modernBoardsDictionary,
}: ICreditOptionsBuilder): IDictionary<ICreditTypeFormOption[]> => {
  const activityConfiguredBoards: IBCTBoard[] = activityBoardIds
    ?.map((boardId) => modernBoardsDictionary[boardId])
    ?.filter((val: IBCTBoard) => !!val);

  const boardCreditIdDictionary = {};
  activityBoardIds?.forEach(
    (boardId) => (boardCreditIdDictionary[boardId] = boardMocDetails?.[boardId]?.typesOfCreditIds || []),
  );
  const boardCreditOptionsDictionary: IDictionary<ICreditTypeFormOption[]> = {};

  activityConfiguredBoards?.forEach(({ creditTypes, id }) => {
    boardCreditOptionsDictionary[id] = generateCreditOptionsFromArray([
      ...creditTypes?.filter((creditType: IBCTCreditType): boolean =>
        filterCreditTypes({ ...creditType, boardCreditIdDictionary, boardId: id }),
      ),
    ]);
  });
  return boardCreditOptionsDictionary;
};

const generateLearnerCredits = (learnerCredits: IDictionary<number>): IDictionary<ILearnerCredits> => {
  const learnerCreditsDictionary: IDictionary<ILearnerCredits> = {};
  const creditsKeys = keys(learnerCredits);
  creditsKeys?.forEach((key) => {
    if (!key.toLowerCase().includes('credit')) {
      learnerCreditsDictionary[key] = { credits: learnerCredits[key], selected: true };
    }
  });
  return learnerCreditsDictionary;
};

const splitDateAsDob = (dateMoment: moment.Moment) => [dateMoment.month() + 1, dateMoment.date()];
const testDateIsValid = (date: Date): boolean => !!new Date(date).getDate();

// Transform addLearnersFormModel into the submit model
export const transformAddLearnerFormSubmitValues = ({
  addLearnersFormModel,
  creditId,
  nonRemsBoardIds,
  remsBoardIds,
  universalLearnerId,
}: ISubmitLearnerDetails): ILearnerCompletion => {
  const {
    certifyingBoards,
    credits,
    completionDate,
    dateOfBirth,
    firstName,
    lastName,
    licenseId,
    medicalSchoolOption,
    stateName,
  } = addLearnersFormModel;

  const stateAbbreviation: string = stateName?.id;
  const cmeCredit: number = credits?.cmeCredit as number;
  const isCmeCreditChecked: boolean = credits?.creditscmeCredit as boolean;

  const creditsByStateOrProvince: IDictionary<ICompletionBoard> =
    stateAbbreviation && isCmeCreditChecked
      ? {
          [stateAbbreviation]: {
            learnerCredits: {
              [creditId]: {
                credits: cmeCredit,
                providerCreditId: creditId,
                selected: isCmeCreditChecked,
              },
            },
            licenseId,
          },
        }
      : {};
  // If the Date constructor fails to make a valid date, this will be false
  const isDobValidDateString = testDateIsValid(dateOfBirth);
  const [birthMonth, birthDay] = dateOfBirth && isDobValidDateString ? splitDateAsDob(moment(dateOfBirth)) : [];
  const selectedBoardsDictionary: IDictionary<ICompletionBoard> = {};
  certifyingBoards?.forEach(
    (board: ICertifyingBoardFormModel) =>
      (selectedBoardsDictionary[board.certifyingBoard] = {
        deaRegistrationTypeId: board.deaRegistration,
        learnerCredits: generateLearnerCredits(board.learnerCredits),
        // we keep the learner id for certifying boards (boardId) in a different field than the one for REMS (providerLearnerId), because the rules for them are different (boardId will be empty string for REMS)
        learnerId: board.boardId || board.providerLearnerId,
        performsSurgicalProcedures: board.surgicalProcedures,
        practiceAreaId: board.practiceArea,
        practiceStateOrProvince: board.practiceState,
        prescribedInPast12Months: board.prescribedInPast12Months,
        professionId: board.profession,
        timeInPracticeId: board.timeInPractice,
        totalCredits: board.totalCredits,
      }),
  );

  // Split up the REMS and Non REMS boards
  const remsBoardsSelected: string[] = remsBoardIds?.filter((id) => selectedBoardsDictionary[id]) || [];
  const nonRemsBoardsSelected: string[] = nonRemsBoardIds?.filter((id) => selectedBoardsDictionary[id]) || [];
  const remsCredits: IDictionary<ICompletionBoard> = {};
  remsBoardsSelected.forEach((board) => (remsCredits[board] = selectedBoardsDictionary[board]));
  const nonRemsCredits: IDictionary<ICompletionBoard> = {};
  nonRemsBoardsSelected.forEach((board) => (nonRemsCredits[board] = selectedBoardsDictionary[board]));
  const completionDateISO: string = moment(completionDate).toISOString();

  return {
    completionDate: completionDate ? completionDateISO : '',
    creditsByCertifyingBoard: { ...nonRemsCredits },
    creditsByRems: { ...remsCredits },
    creditsByStateOrProvince,
    dateOfBirthDay: birthDay,
    dateOfBirthMonth: birthMonth,
    firstName,
    lastName,
    medicalSchoolId: medicalSchoolOption?.id,
    universalLearnerId,
  };
};

export const getMatchPayloadFromFormValues = (
  {
    certifyingBoards,
    credits,
    dateOfBirth,
    firstName,
    lastName,
    licenseId,
    medicalSchoolOption,
    npi,
    stateName,
  }: IAddLearnersFormModel,
  boardConfigs: IDictionary<IBCTBoard>,
): ILearnerMatchingModel => {
  const boardIds: IDictionary<string> = {};
  certifyingBoards?.forEach(({ certifyingBoard, boardId }) => {
    if (boardConfigs?.[certifyingBoard]?.type !== BoardTypes.REMS) {
      if (certifyingBoard) {
        boardIds[certifyingBoard] = boardId || null;
      }
    }
  });

  // If the Date constructor fails to make a valid date, this will be false
  const isDobValidDateString = testDateIsValid(dateOfBirth);
  const [birthMonth, birthDay] = dateOfBirth && isDobValidDateString ? splitDateAsDob(moment(dateOfBirth)) : [];
  return {
    birthDay,
    birthMonth,
    boardIds,
    firstName,
    includeCme: !!credits?.creditscmeCredit,
    lastName,
    licenseId,
    medicalSchoolName: medicalSchoolOption?.label,
    npi,
    stateName: stateName?.label,
  };
};
