import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { KeyboardBackspaceRounded } from '@material-ui/icons';

// Components
import ShowHide from 'components/ShowHide/ShowHide';
import { ManualDownloadLink } from 'components/ContinuousImprovement/ManualDownloadLink';
import { Modal } from 'components/ContinuousImprovement/Modal';
import {
  CANCEL_LABEL,
  DuplicateFileDetected,
  PROCEED_LABEL,
} from 'components/ContinuousImprovement/Modal/templates/duplicate-file-detected';
import { PrepareUploadActivitiesModal } from 'components/ContinuousImprovement/Modal/templates/prepare-upload-activities';
import { BatchActivityForm } from 'layouts/pages/activity/forms/batchActivityForm';

// Hooks.
import { useClickEvent, useLoadEvent } from 'hooks';

// Store + Core
import {
  ACTIVITY_TEMPLATE_ID_MAP,
  ACTIVITY_TEMPLATE_JA_ID_MAP,
  ACTIVITY_TEMPLATE_NARS_ID_MAP,
  ACTIVITY_TEMPLATE_NARS_IA_ID_MAP,
} from 'store/fileDownload/types';
import { downloadFile } from 'store/fileDownload/actions';
import { rollupOrganizationEnumSelector, userProfileSelector } from 'store/user/selectors';
import { isModalOpenSelector } from 'store/modal/selectors';
import { closeModal } from 'store/modal/actions';
import { batchActivity, batchActivityClearAction } from 'store/activity/actions';
import { activityFileSelector } from 'store/activity/selectors';
import { taxonomyTabDelimSelector, taxonomyBatchHelpSelector } from 'store/taxonomy/selectors';
import { TAXONOMY_TAB_DELIM_SUPPORT_ID, TAXONOMY_BATCH_HELP_ROOT_ID, TAGS_ENUM } from 'core/constants';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { RollupOrganizationEnums, IUser } from 'core/models';

interface IBatchActivityParams {
  type?: string;
}

const VIEW_UPLOAD_HISTORY_LABEL = 'View Upload History';

const Excel = 'Excel';
const ExcelIA = 'ExcelIA';
const ExcelMOC = 'ExcelMOC';
const Tab = 'Tab';
type TEMPLATE_TYPES = typeof ExcelMOC | typeof ExcelIA | typeof Excel | typeof Tab;

const PARS_TEMPLATE_IDS: Partial<Record<TEMPLATE_TYPES, keyof typeof ACTIVITY_TEMPLATE_ID_MAP>> = {
  Excel: '1',
  ExcelMOC: '0',
  Tab: '2',
};

const JA_TEMPLATE_IDS: Partial<Record<TEMPLATE_TYPES, keyof typeof ACTIVITY_TEMPLATE_JA_ID_MAP>> = {
  Excel: '4',
  ExcelMOC: '3',
  Tab: '5',
};

const NARS_TEMPLATE_IDS: Partial<Record<TEMPLATE_TYPES, keyof typeof ACTIVITY_TEMPLATE_NARS_ID_MAP>> = {
  Excel: '6',
};

const NARS_TEMPLATE_IA_IDS: Partial<Record<TEMPLATE_TYPES, keyof typeof ACTIVITY_TEMPLATE_NARS_IA_ID_MAP>> = {
  Excel: '6',
  ExcelIA: '7',
};

const BatchActivityPage = (): ReactElement => {
  const taxonomyTabDelim = useSelector(taxonomyTabDelimSelector);
  const taxonomyBatchHelpTerms = useSelector(taxonomyBatchHelpSelector);
  const { pathname } = useLocation();
  const { type = '' } = useParams<IBatchActivityParams>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTaxonomyTermById(TAXONOMY_TAB_DELIM_SUPPORT_ID));
    dispatch(getTaxonomyTermById(TAXONOMY_BATCH_HELP_ROOT_ID));
  }, [dispatch]);

  const fireLoadEvent = useLoadEvent({ PageName: 'Activity Batch Upload', PageType: 'Activity Upload' });
  const fireClickEvent = useClickEvent({ Event: 'Activity Upload', EventCategory: 'Activity Upload' });

  useEffect(() => {
    fireLoadEvent();
  }, [fireLoadEvent]);

  // Selectors.
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  // NARS TODO: clean up the use of isJA here to work better with NARS too
  const isJa = rollupOrganizationEnum === RollupOrganizationEnums.JA;
  const isNARS = rollupOrganizationEnum === RollupOrganizationEnums.NARS;
  const isModalOpen: boolean = useSelector(isModalOpenSelector);
  const file: { file: File } = useSelector(activityFileSelector);
  const userProfile: IUser = useSelector(userProfileSelector);

  // Constants.
  const downloadRoute = '/activities/batches/downloadtemplate';

  let matchTemplate: any;
  let matchTemplateIds: any;

  switch (rollupOrganizationEnum) {
    case RollupOrganizationEnums.NARS:
      if (userProfile.currentProvider?.isApproverOfActivities) {
        matchTemplate = ACTIVITY_TEMPLATE_NARS_IA_ID_MAP;
        matchTemplateIds = NARS_TEMPLATE_IA_IDS;
      } else {
        matchTemplate = ACTIVITY_TEMPLATE_NARS_ID_MAP;
        matchTemplateIds = NARS_TEMPLATE_IDS;
      }
      break;
    case RollupOrganizationEnums.JA:
      matchTemplate = ACTIVITY_TEMPLATE_JA_ID_MAP;
      matchTemplateIds = JA_TEMPLATE_IDS;
      break;
    case RollupOrganizationEnums.PARS:
      matchTemplate = ACTIVITY_TEMPLATE_ID_MAP;
      matchTemplateIds = PARS_TEMPLATE_IDS;
      break;
  }

  const excel = matchTemplateIds[Excel];
  const excelIa = matchTemplateIds[ExcelIA];
  const excelMoc = matchTemplateIds[ExcelMOC];
  const tab = matchTemplateIds[Tab];

  useEffect(() => {
    if (pathname?.includes(downloadRoute) && matchTemplate[type]) {
      dispatch(downloadFile({ controller: downloadRoute, id: type, matchTemplate }));
    }
  });

  const onProceed = useCallback(() => {
    dispatch(
      batchActivity({
        file: { file } as { file },
        shouldForce: true,
      }),
    );
    fireClickEvent({ EventAction: 'Duplicates', EventLabel: PROCEED_LABEL });
  }, [dispatch, file, fireClickEvent]);

  const onCancel = useCallback(() => {
    dispatch(batchActivityClearAction());
    dispatch(closeModal());
    fireClickEvent({ EventAction: 'Duplicates', EventLabel: CANCEL_LABEL });
  }, [dispatch, fireClickEvent]);

  const excelTag = isJa
    ? TAGS_ENUM.BATCH_HELP__JA_ACTIVITY_EXCEL_INSTRUCTIONS
    : TAGS_ENUM.BATCH_HELP__PARS_ACTIVITY_EXCEL_INSTRUCTIONS;
  const xmlTag = isJa
    ? TAGS_ENUM.BATCH_HELP__JA_ACTIVITY_XML_INSTRUCTIONS
    : TAGS_ENUM.BATCH_HELP__PARS_ACTIVITY_XML_INSTRUCTIONS;
  const excelInstructions = taxonomyBatchHelpTerms?.terms.filter((lt) => lt.tag === excelTag)[0]?.description;
  const xmlInstructions = taxonomyBatchHelpTerms?.terms.filter((lt) => lt.tag === xmlTag)[0]?.description;
  const tabInstructions = isJa
    ? 'https://www.jointaccreditation.org/sites/default/files/765_20211116_JA_Activity_TabDelimited_Instructions.pdf'
    : 'https://accme.org/publications/PARS/accme-pars-tab-delimited-instructions-2016-and-greater';
  const ceCme = isNARS ? 'CE' : 'CME';

  return (
    <section className="form-container">
      <div className="form-title-container">
        <Link
          className="tertiary"
          onClick={() =>
            fireClickEvent({ EventAction: VIEW_UPLOAD_HISTORY_LABEL, EventLabel: '/actvities/batch/history' })
          }
          to="/activities/batch/history"
        >
          {VIEW_UPLOAD_HISTORY_LABEL}
          <KeyboardBackspaceRounded className="tertiary-icon" />
        </Link>
        <h4 className="title">
          <div className="eyebrow">Batch Upload Activities</div>
          Add, update or delete activities
        </h4>
      </div>
      <div className="form-flex-row">
        <div className="form-column">
          <ShowHide title="Help">
            <div className="help-container">
              {isNARS ? (
                <h5>
                  Please visit the NCPD Learning Communities for detailed instructions on how to batch upload or use the
                  XML file.
                </h5>
              ) : (
                <h5>The following methods of preparing batch activities are available:</h5>
              )}
              <div hidden={isNARS} className="help-list">
                <ul>
                  {(excel || excelMoc || excelIa) && (
                    <li className="help-item help-item--xls">
                      <div className="heading">Excel</div>
                      <p>Upload an Excel file using one of the templates below.</p>
                      <a
                        className="tertiary tertiary--small"
                        href={excelInstructions}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Get the Detailed Excel Instructions
                        <KeyboardBackspaceRounded className="tertiary-icon" />
                      </a>
                      <div className="batch-templates">
                        <ul>
                          {excel && (
                            <li>
                              <ManualDownloadLink to={downloadRoute} id={excel} map={matchTemplate}>
                                <>
                                  <p>Batch Activity Excel File</p>
                                  <p>Use for {ceCme}</p>
                                </>
                              </ManualDownloadLink>
                            </li>
                          )}
                          {excelIa && (
                            <li>
                              <ManualDownloadLink to={downloadRoute} id={excelIa} map={matchTemplate}>
                                <>
                                  <p>Batch Individual Activity Excel File</p>
                                  <p>Use for {ceCme}</p>
                                </>
                              </ManualDownloadLink>
                            </li>
                          )}
                          {excelMoc && (
                            <li>
                              <ManualDownloadLink to={downloadRoute} id={excelMoc} map={matchTemplate}>
                                <>
                                  <p>Batch Activity with MOC Excel</p>
                                  <p>Use for {ceCme} and MOC</p>
                                </>
                              </ManualDownloadLink>
                            </li>
                          )}
                        </ul>
                      </div>
                    </li>
                  )}
                  {tab && taxonomyTabDelim?.description === 'On' && (
                    <li className="help-item help-item--td">
                      <div className="heading">Tab Delimited</div>
                      <p>Upload a tab delimited file generated from an Excel template.</p>
                      <a
                        className="tertiary tertiary--small"
                        href={tabInstructions}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Get the Detailed Tab Delimited Instructions
                        <KeyboardBackspaceRounded className="tertiary-icon" />
                      </a>
                      <div className="batch-templates">
                        <ul>
                          <li>
                            <ManualDownloadLink to={downloadRoute} id={tab} map={matchTemplate}>
                              <>
                                <p>Batch Activity Tab Delimited File</p>
                                <p>Use for {ceCme}</p>
                              </>
                            </ManualDownloadLink>
                          </li>
                        </ul>
                      </div>
                    </li>
                  )}
                  <li className="help-item help-item--xml">
                    <div className="heading">XML</div>
                    <p>
                      Upload XML files formatted to conform with the Medical Education Metrics (MEMS) XML created by
                      MedBiquitous Consortium. Technical knowledge of XML is required to use this batch upload feature.
                    </p>
                    <a
                      className="tertiary tertiary--small"
                      href={xmlInstructions}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Get the Detailed XML Instructions
                      <KeyboardBackspaceRounded className="tertiary-icon" />
                    </a>
                    <li
                      style={{ color: '#373737', fontSize: '0.75rem', letterSpacing: '0.025rem', lineHeight: 1.66667 }}
                    >
                      Use for {ceCme} {!isNARS ? 'and MOC' : ''}
                    </li>
                  </li>
                </ul>
              </div>
            </div>
          </ShowHide>
        </div>
      </div>
      <div className="form-flex-row">
        <div className="form-column grid-4">
          <PrepareUploadActivitiesModal />
          <div className="batch-templates">
            <div className="heading">Batch Templates</div>
            <ul>
              {excel && (
                <li>
                  <ManualDownloadLink to={downloadRoute} id={excel} map={matchTemplate}>
                    <>
                      <p>Batch Activity Excel File</p>
                      <p>Use for {ceCme}</p>
                    </>
                  </ManualDownloadLink>
                </li>
              )}
              {excelIa && (
                <li>
                  <ManualDownloadLink to={downloadRoute} id={excelIa} map={matchTemplate}>
                    <>
                      <p>Batch Individual Activity Excel File</p>
                      <p>Use for {ceCme}</p>
                    </>
                  </ManualDownloadLink>
                </li>
              )}
              {excelMoc && (
                <li>
                  <ManualDownloadLink to={downloadRoute} id={excelMoc} map={matchTemplate}>
                    <>
                      <p>Batch Activity with MOC Excel File</p>
                      <p>Use for {ceCme} and MOC</p>
                    </>
                  </ManualDownloadLink>
                </li>
              )}
              {tab && taxonomyTabDelim?.description === 'On' && (
                <li>
                  <ManualDownloadLink to={downloadRoute} id={tab} map={matchTemplate}>
                    <>
                      <p>Tab Delimited File</p>
                      <p>Use for {ceCme} </p>
                    </>
                  </ManualDownloadLink>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="form-column">
          <h5>2&#41; Upload your completed file.</h5>
          <BatchActivityForm />
        </div>
        <Modal isOpen={isModalOpen} onClose={() => dispatch(closeModal)} title="Potential duplicate file detected">
          <DuplicateFileDetected onCancel={onCancel} onProceed={onProceed} />
        </Modal>
      </div>
    </section>
  );
};

export default BatchActivityPage;
