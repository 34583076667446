import sortBy from 'lodash/sortBy';

import { CacheService } from '.';
import { TaxonomyApiService } from 'core/taxonomyApiService';
import { ITaxonomyTerm } from 'core/models';
import { CACHE_TAXONOMY_TERM_EXPIRY_MINS, CACHE_TAXONOMY_TERM_PREFIX } from 'core/constants';

export class TaxonomyService {
  private taxonomyApiService: TaxonomyApiService;

  constructor() {
    this.taxonomyApiService = new TaxonomyApiService();
  }

  public async getByIdAsync(termId: string): Promise<ITaxonomyTerm> {
    const cacheKey = `${CACHE_TAXONOMY_TERM_PREFIX}${termId}`;
    const cachedTerm = CacheService.get<ITaxonomyTerm>(cacheKey);
    if (cachedTerm) {
      return cachedTerm;
    }

    const term = await this.taxonomyApiService.getTermById(termId);
    TaxonomyService.sortChildren(term);
    CacheService.set(cacheKey, term, CACHE_TAXONOMY_TERM_EXPIRY_MINS);
    return term;
  }

  private static sortChildren(taxonomyTerm: ITaxonomyTerm) {
    return sortBy(taxonomyTerm?.terms || [], ['sortOrder', 'name']);
  }
}
