/* eslint-disable sort-keys */
import { ReactElement, useCallback, useState, useMemo, ChangeEvent, useEffect } from 'react';
import * as yup from 'yup';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Grid } from '@material-ui/core';
import { FormikProvider, useFormik } from 'formik';
import { KeyboardBackspaceRounded } from '@material-ui/icons';
import classNames from 'classnames';
import Button from 'components/Button/Button';
import { useAccountManagerInfo } from '../hooks';
import { ButtonVariant, OrgEnum } from 'core/enums';

// style
import styles from './EditOrganization.module.scss';
import fieldStyles from 'components/ContinuousImprovement/PureFormElements/FormikFields.module.scss';
import {
  IOrganizationProvider,
  IOrganizationType,
  IOrganizationStatus,
  RollupOrganizationEnums,
  IUserOrganization,
  OrganizationKinds,
} from 'core/models';
import { FormikInputField } from 'components/ContinuousImprovement/PureFormElements';
import { userProfileSelector } from 'store/user/selectors';
import { useCountries, useStates } from 'store/locations/hooks';
import Dropdown from 'components/Dropdown/Dropdown';
import { popToast } from 'store/toast/actions';
import { errorToastOptions, successToastOptions } from 'store/toast/constants';
import { handleServerError } from 'globals/utils/handleServerError';
import { getCurrentUser } from 'store/user/actions';
import { CacheService } from 'services';
import { Constants } from 'core';
import { OrganizationProfileService } from 'services/OrganizationProfileService';
import { filterItemsByRollupOrganizationEnum } from 'globals/utils/filterItemsByRollupOrgCode';
import { useLoadData } from 'hooks/useLoadData';
import { FormikNumberRangeInputField } from 'components/ContinuousImprovement/PureFormElements/FormikNumberRangeInputField';
import { sortBy } from 'lodash';

const editSchema: yup.SchemaOf<
  Omit<
    IOrganizationProvider,
    | 'businessId'
    | 'id'
    | 'organizationKind'
    | 'childOrganizationIds'
    | 'customerId'
    | 'rollupOrganizationEnum'
    | 'securityGroups'
    | 'isPrimaryContact'
    | 'organizationNotes'
    | 'organizationStatus'
    | 'accreditorOrApprover'
    | 'addressLine2'
    | 'faxNumberCountryCode'
    | 'faxNumberAreaCode'
    | 'faxNumberLocal'
    | 'organizationType'
    | 'webAddress'
    | 'isApproverOfActivities'
    | 'organizationStatusId'
    | 'providerType'
    | 'addressCity'
    | 'addressState'
    | 'addressCountry'
    | 'addressZipCode'
    | 'phoneNumberCountryCode'
    | 'phoneNumberAreaCode'
    | 'phoneNumberLocal'
    | 'accreditorApproverId'
    | 'accreditorName'
    | 'accreditorBusinessId'
  >
> = yup.object({
  organizationName: yup.string().required('Organization Name is required'),
  organizationStatusId: yup.string().required('Organization Status is required'),
  organizationTypeId: yup.string().required('Organization Type is required'),
  providerType: yup.string().required('Provider Type is required'),
  addressLine1: yup.string().required('Address is required'),
  phoneNumberAreaCode: yup
    .string()
    .max(3, 'Phone number area code can be maximum 3 characters')
    .required('Phone number country area is required'),
});

export const AccountManagerEditOrganizationPage = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const [isAccreditorApproverDisabled, setIsAccreditorApproverDisabled] = useState(false);
  const [accreditorApproverValueState, setAccreditorApproverValueState] = useState('');
  const countries = sortBy(useCountries(), 'countryName');
  const states = useStates();
  const dispatch = useDispatch();
  const history = useHistory();
  const [existingProvider, setExistingProvider] = useState<IOrganizationProvider | null>(null);
  const { canEdit, organization, isAnccOrganization } = useAccountManagerInfo();
  const userProfile = useSelector(userProfileSelector);
  const isANCC: boolean = organization?.businessId === 'ANCC';

  const { data: organizationTypes } = useLoadData(
    'OrganizationTypes',
    useCallback(async () => {
      return await OrganizationProfileService.getOrganizationTypes();
    }, [id]),
  );

  const { data: organizationStatus } = useLoadData(
    'OrganizationStatus',
    useCallback(async () => {
      return OrganizationProfileService.getOrganizationStatus();
    }, [id]),
  );

  const filteredOrganizationTypes: IOrganizationType[] = filterItemsByRollupOrganizationEnum(
    organizationTypes,
    organization.rollupOrganizationEnum,
  );

  const anccOrganizationType: IOrganizationType[] = [];
  anccOrganizationType.push({
    id: existingProvider?.organizationTypeId,
    description: organization?.organizationType,
    rollupOrganizationCode: 8,
  });

  const filteredOrganizationStatus: IOrganizationStatus[] = filterItemsByRollupOrganizationEnum(
    organizationStatus,
    organization.rollupOrganizationEnum,
  );

  const anccOrganizationStatus: IOrganizationStatus[] = [];
  anccOrganizationStatus.push({
    id: existingProvider?.organizationStatusId,
    name: organization?.organizationStatus,
    rollupOrganizationCode: 8,
  });

  const organizationOptions = useMemo(
    () =>
      sortBy(
        userProfile?.primaryUserOrganizations.filter(
          (org) => org.rollupOrganizationEnum === RollupOrganizationEnums.NARS,
        ) || [],
        'organizationName',
        'businessId',
      ),
    [userProfile?.primaryUserOrganizations],
  );

  const anccOrganization: IUserOrganization = organizationOptions.find((o) => o.businessId === OrgEnum.ANCC);

  const handleAccreditorApprover = (event: ChangeEvent<HTMLInputElement>): void => {
    const value: string = event.target.value;
    formik.setFieldValue('providerType', value);

    if (value === 'Approver of Providers') {
      setIsAccreditorApproverDisabled(true);
      formik.setFieldValue('accreditorApproverId', anccOrganization.id);
    } else {
      if (!isAnccOrganization) {
        setAccreditorApproverForNonAncc();
      } else {
        setIsAccreditorApproverDisabled(false);
        formik.setFieldValue('accreditorApproverId', '');
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (!isAnccOrganization) {
        setAccreditorApproverForNonAncc();
      }
      if (id) {
        try {
          const provider = await OrganizationProfileService.getOrganizationById(id);
          setExistingProvider(provider);

          if (organization.organizationKind === OrganizationKinds.PROVIDER) {
            setAccreditorApproverValueState(`${provider?.accreditorBusinessId} - ${provider?.accreditorName}`);
          } else {
            organization?.accreditorOrApprover
              ? setAccreditorApproverValueState(`ANCC - ${organization?.accreditorOrApprover}`)
              : setAccreditorApproverValueState(`ANCC - ${provider?.organizationName}`);
          }
        } catch (error) {
          dispatch(popToast({ ...errorToastOptions, message: <>{handleServerError({ error }).errorMessage}</> }));
          history.push('/account-manager');
        }
      }
    })();
  }, [id, dispatch]);

  function setAccreditorApproverForNonAncc() {
    const nonAnccOrganization: IUserOrganization = organizationOptions.find((o) => o.id === organization.id);
    if (nonAnccOrganization) {
      formik.setFieldValue('accreditorApproverId', nonAnccOrganization.id);
      setIsAccreditorApproverDisabled(true);
    }
  }

  const organizationNameElement: HTMLElement = document.getElementById('organizationName');
  if (organizationNameElement && isAccreditorApproverDisabled && !isANCC) {
    organizationNameElement.style.background = '#ccc';
  }

  const formik = useFormik<IOrganizationProvider>({
    enableReinitialize: true,
    initialValues: { ...existingProvider } as IOrganizationProvider,
    onSubmit: async (values) => {
      try {
        const {
          organizationName,
          organizationTypeId,
          organizationStatusId,
          accreditorName,
          accreditorBusinessId,
          addressLine1,
          addressLine2,
          addressCity,
          addressState,
          addressCountry,
          phoneNumberAreaCode,
          phoneNumberLocal,
          addressZipCode,
          webAddress,
        } = values;
        const baseReq: IOrganizationProvider = {
          organizationTypeId,
          organizationStatusId,
          organizationName,
          accreditorName,
          accreditorBusinessId,
          addressLine1,
          addressLine2,
          addressCity,
          addressState,
          addressZipCode,
          addressCountry,
          phoneNumberAreaCode,
          phoneNumberLocal,
          id: '',
          businessId: '',
          organizationKind: 'Accreditor',
          childOrganizationIds: [],
          webAddress,
        };
        await OrganizationProfileService.updateOrganizationProfile(id, baseReq);
        dispatch(popToast({ ...successToastOptions, message: <>Successfully updated organization.</> }));
        history.push('/account-manager');
        CacheService.remove(Constants.CACHE_USER_CURRENT);
        await dispatch(getCurrentUser());
      } catch (error) {
        dispatch(popToast({ ...errorToastOptions, message: <>{handleServerError({ error }).errorMessage}</> }));
      }
    },
    validationSchema: editSchema,
  });

  const getProviderTypes = () => {
    if (!isAnccOrganization) {
      return [
        'Accredited Approver',
        'Accredited Provider',
        'Approved Provider',
        'Approver of Providers',
        'Accredited International Provider',
      ].map((i) => ({
        id: i,
        name: i,
      }));
    } else {
      if (isANCC) {
        return ['Accreditor'].map((i) => ({
          id: i,
          name: i,
        }));
      } else {
        return ['Approved Provider'].map((i) => ({
          id: i,
          name: i,
        }));
      }
    }
  };

  const { isValid, isSubmitting, submitForm } = formik;
  const isDisabledOnEdit = !!id;

  if (!canEdit) {
    return <Redirect to="/404" />;
  }

  return (
    <div className="form-container">
      <div className="page-navigation">
        <Link className="tertiary" to="/account-manager">
          <KeyboardBackspaceRounded className="tertiary-icon-back" />
          Account Manager
        </Link>
      </div>
      <div className="form-title-container">
        <div className={classNames('title', styles.title)}>
          <h4>Edit Organization</h4>
        </div>
      </div>
      <FormikProvider value={formik}>
        <div className="static-form" />
        <Grid item xs={12} sm={6} className={styles.input}>
          <FormikInputField label="Organization Name *" formikKey="organizationName" disabled={!isANCC} />
          <label className={fieldStyles.label} htmlFor="organizationTypeId">
            Organization Type *
          </label>
          <Dropdown
            items={!isANCC ? filteredOrganizationTypes : anccOrganizationType}
            name="organizationTypeId"
            placeholder="Organization Type"
            idProp="id"
            labelProp="description"
            isDisabled={!isANCC}
          />
          <div className="MuiFormHelperText-root Mui-error">{formik.errors.organizationTypeId}</div>
          <label className={fieldStyles.label} htmlFor="organizationStatusId">
            Organization Status *
          </label>
          <Dropdown
            items={!isANCC ? filteredOrganizationStatus : anccOrganizationStatus}
            name="organizationStatusId"
            placeholder="Organization Status"
            idProp="id"
            labelProp="name"
            isDisabled={!isANCC}
          />
          <div className="MuiFormHelperText-root Mui-error">{formik.errors.organizationStatusId}</div>
          <label className={fieldStyles.label} htmlFor="title">
            Provider Type *
          </label>
          <Dropdown
            items={getProviderTypes()}
            name="providerType"
            placeholder="Provider Type"
            isDisabled={isDisabledOnEdit}
            onChange={handleAccreditorApprover}
          />
          <div className="MuiFormHelperText-root Mui-error">{formik.errors.providerType}</div>
          <div>
            <label className={fieldStyles.label} htmlFor="accreditorApprover">
              Accreditor/Approver *
            </label>
            <div className="form-input" style={{ margin: '0' }}>
              <input
                aria-invalid="false"
                disabled
                style={{ backgroundColor: '#ccc' }}
                id="accreditorApprover"
                name="accreditorApprover"
                type="text"
                aria-label="input"
                value={accreditorApproverValueState}
                onChange={(e) => setAccreditorApproverValueState(e.target.value)}
              />
            </div>
          </div>
          <FormikInputField label="Street" formikKey="addressLine1" />
          <FormikInputField label="" formikKey="addressLine2" />
          <FormikInputField label="City" formikKey="addressCity" />
          <label className={fieldStyles.label} htmlFor="addressState">
            State
          </label>
          <Dropdown
            items={states}
            name="addressState"
            placeholder="Address State"
            idProp="isoStateCode"
            labelProp="stateName"
          />
          <FormikInputField label="Zip Code" formikKey="addressZipCode" />
          <label className={fieldStyles.label} htmlFor="title">
            Countries
          </label>
          <Dropdown
            items={countries}
            name="addressCountry"
            placeholder="Address Country"
            idProp="isoCountryCode"
            labelProp="countryName"
          />
          <FormikNumberRangeInputField label="Phone Area Code *" formikKey="phoneNumberAreaCode" />
          <FormikInputField label="Phone Local" formikKey="phoneNumberLocal" />
          <FormikInputField label="Web Address" formikKey="webAddress" />
        </Grid>
        <Grid item xs={12} md={9} lg={6}>
          <div className={styles['submit-button-container']}>
            <Button
              disabled={!isValid || isSubmitting}
              onClick={submitForm}
              startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
              variant={ButtonVariant.Primary}
            >
              Save Changes
            </Button>
          </div>
        </Grid>
      </FormikProvider>
    </div>
  );
};
