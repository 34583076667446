import { FormLabel, Grid } from '@material-ui/core';
import { Field, FormikProvider, useFormik } from 'formik';
import { ReactElement, useEffect } from 'react';

// Components.
import Button from 'components/Button/Button';
import { Modal } from 'components/ContinuousImprovement';
import DatePicker from 'components/DatePicker/DatePicker';

// Core.
import { ButtonVariant } from 'core/enums';

// Hooks.
import { useLoadEvent } from 'hooks';

// Styles.
import styles from './ReportDateModal.module.scss';

export interface IReportDateRange {
  endDate: Date;
  startDate: Date;
}

interface IReportDateModalProps {
  onClose?(): void;
  onSubmit?(dateRange: IReportDateRange): void;
  report: {
    name: string;
  };
}

export const ReportDateModal = (props: IReportDateModalProps): ReactElement => {
  const { onClose, onSubmit, report } = props;
  const { name } = report;

  const fireLoadEvent = useLoadEvent({ PageName: 'Reports Modal', PageType: 'Reports' });

  useEffect(() => {
    fireLoadEvent();
  }, [fireLoadEvent]);

  const formik = useFormik({
    initialValues: {
      endDate: new Date(),
      startDate: new Date(),
    },
    onSubmit,
  });

  return (
    <Modal isOpen onClose={onClose} title={`${name} Report`}>
      <div className={styles.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p>Select your date range below.</p>
          </Grid>
          <Grid item xs={12}>
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormLabel component="div">From</FormLabel>
                    <Field name="startDate">
                      {({ field }) => <DatePicker field={field} formikKey="startDate" timeOfDay="midnight" />}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel component="div">To</FormLabel>
                    <Field name="endDate">
                      {({ field }) => <DatePicker field={field} formikKey="endDate" timeOfDay="endOfDay" />}
                    </Field>
                  </Grid>
                  <Grid container item justify="flex-end" xs={12}>
                    <Button type="submit" variant={ButtonVariant.Primary}>
                      View Report
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </FormikProvider>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};
