import CircularProgress from '@material-ui/core/CircularProgress';
import { KeyboardBackspaceRounded } from '@material-ui/icons';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components.
import Button from 'components/Button/Button';

// Core.
import { DEFAULT_DATE_FORMAT } from 'core/constants';
import { IAttestation, RollupOrganizationEnums } from 'core/models';

// Hooks.
import { useClickEvent, useLoadEvent } from 'hooks';

// Store.
import { attest, getAttestation } from 'store/attestation/actions';
import { attestationSelector, isAttestLoadingSelector } from 'store/attestation/selectors';
import { rollupOrganizationEnumSelector } from '../../../../store/user/selectors';

// Styles.
import styles from './index.module.scss';
import moment from 'moment';

const ACCEPT_TERMS_LABEL = 'Accept Terms';

const DashboardAgreementPage: React.FC = () => {
  const dispatch = useDispatch();

  // Redux state.
  const attestation: IAttestation = useSelector(attestationSelector);
  const isAttestLoading: boolean = useSelector(isAttestLoadingSelector);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  const isNars = rollupOrganizationEnum === RollupOrganizationEnums.NARS;

  const fireLoadEvent = useLoadEvent({ PageName: 'Agreement', PageType: 'Agreement' });
  const fireClickEvent = useClickEvent({ Event: 'Agreement', EventCategory: 'Agreement' });

  useEffect(() => {
    if (!attestation || !isAttestLoading) {
      fireLoadEvent();
    }
  }, [attestation, fireLoadEvent, isAttestLoading]);

  const handleAcceptTerms = useCallback(async () => {
    setIsSubmitting(true);
    try {
      fireClickEvent({ EventAction: ACCEPT_TERMS_LABEL });
      await dispatch(attest());
    } finally {
      try {
        await dispatch(getAttestation());
      } finally {
        setIsSubmitting(false);
      }
    }
  }, [dispatch, fireClickEvent]);

  useEffect(() => {
    if (!attestation && !isAttestLoading) {
      dispatch(getAttestation());
    }
  }, [attestation, dispatch, isAttestLoading]);

  // Don't return anything if we don't have the attestation retrieved yet
  if (!attestation) {
    return (
      <div className={styles['loading-wrapper']}>
        <CircularProgress />
      </div>
    );
  }

  const { heading, subHeading, content, isAttested, startDate, endDate, updatedDate, email } = attestation;
  const now = new Date();

  const isAttestButtonEnabled = !isAttested && startDate <= now && endDate >= now;

  /**
   * Renders the text for an accepted agreement based off of the rollup org and agreement date.
   * @returns {ReactElement} The accepted agreement component.
   */
  const renderAgreementAcceptedText = (): ReactElement => {
    if (!isNars || (isNars && updatedDate.getUTCFullYear() !== 2024)) {
      return (
        <div style={{ marginRight: 'auto' }}>
          {`Annual agreement accepted on ${moment(updatedDate).format(DEFAULT_DATE_FORMAT)} by ${email}`}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <section className="form-container">
        <div className="page-navigation">
          <Link className="tertiary" to="/dashboard">
            <KeyboardBackspaceRounded className="tertiary-icon-back" />
            Dashboard
          </Link>
        </div>
        <div className="form-title-container">
          <h4 className="title">
            <div className="eyebrow">Annual Agreement</div>
            {heading}
            <div className="caption-text">{subHeading}</div>
          </h4>
        </div>
        <div className="form-group active">
          <div className="agreement-body" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        <div className="button-row">
          {!isAttestButtonEnabled && email != null ? renderAgreementAcceptedText() : null}
          <div className="cta-container">
            <Button
              className="primary"
              disabled={!isAttestButtonEnabled || isSubmitting}
              onClick={handleAcceptTerms}
              isSubmitting={isSubmitting}
            >
              {ACCEPT_TERMS_LABEL}
            </Button>
          </div>
        </div>
      </section>
      <aside className="rail-container" />
    </>
  );
};

export default DashboardAgreementPage;
