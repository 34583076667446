import { ReactElement } from 'react';
import { useField } from 'formik';
import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import classNames from 'classnames';

// Styles
import styles from '../FormikFieldsInputRange.module.scss';

export type FormikNumberRangeInputFieldProps = {
  formikKey: string;
  label?: string;
  readOnly?: boolean;
  noOffset?: boolean;
  placeholder?: string;
  maxLength?: number;
  onChange?: (event) => void;
  startIcon?: ReactElement;
} & TextFieldProps;

export const FormikNumberRangeInputField = ({
  formikKey,
  helperText = '',
  label,
  noOffset,
  placeholder,
  maxLength,
  startIcon,
  onChange,
  required,
  ...props
}: FormikNumberRangeInputFieldProps): ReactElement => {
  const [field, meta] = useField<string>(formikKey);
  const { name } = field;
  const { error } = meta;
  const inputClass = classNames(
    {
      [styles.offset]: !label && !noOffset,
      [styles['start-adornment']]: !!startIcon,
      [styles['number-to-text']]: true,
    },
    styles.input,
  );

  return (
    <>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </label>
      )}
      <TextField
        className={inputClass}
        error={!!error}
        helperText={error || helperText}
        id={name}
        InputProps={{
          disableUnderline: true,
          inputProps: { maxLength: maxLength },
          'aria-label': field.name,
          readOnly: props.readOnly,
          startAdornment: startIcon && <InputAdornment position="start">{startIcon}</InputAdornment>,
          value: field.value || '',
        }}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        type="number"
        {...props}
        {...field}
      />
    </>
  );
};
