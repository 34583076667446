import { FormEvent, MouseEvent, ReactElement } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { KeyboardBackspaceRounded } from '@material-ui/icons';

interface IButtonRowProps {
  backLabel?: string;
  ctaLabel?: string;
  isCtaDisabled?: boolean;
  isSubmitting?: boolean;
  isValid: boolean;
  onBackClick?(): void;
  onClick?(event?: MouseEvent | FormEvent<HTMLFormElement>): Promise<void> | void;
  showBack?: boolean;
}

const ButtonRow = (props: IButtonRowProps): ReactElement => {
  const { isCtaDisabled, isSubmitting, showBack, onBackClick, onClick, backLabel, ctaLabel, isValid } = props;

  return (
    <div className="button-row">
      {showBack && (
        <Button
          className="tertiary"
          disabled={isSubmitting}
          onClick={onBackClick}
          startIcon={<KeyboardBackspaceRounded />}
          title={backLabel || 'go back'}
        >
          {backLabel || 'Back'}
        </Button>
      )}
      <div className="cta-container">
        <Button
          className="primary"
          disabled={!isValid || isSubmitting || isCtaDisabled}
          onClick={onClick}
          startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
          title={ctaLabel || 'save draft and continue'}
          type="submit"
        >
          {ctaLabel || 'Save Draft and Continue'}
        </Button>
      </div>
    </div>
  );
};

export default ButtonRow;
