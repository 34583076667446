import { Reducer } from 'redux';

// Core.
import { PARSAction } from 'core/models';

// Types.
import {
  DashboardState,
  DISMISS_DASHBOARD_BANNER,
  DISMISS_DASHBOARD_NOTIFICATION,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_BANNERS,
  GET_DASHBOARD_BANNERS_SUCCESS,
  GET_DASHBOARD_BANNERS_FAILURE,
  GET_DASHBOARD,
  GET_DASHBOARD_MESSAGE,
  GET_DASHBOARD_MESSAGE_SUCCESS,
  GET_DASHBOARD_MESSAGE_FAILURE,
  DISMISS_DASHBOARD_MESSAGE,
  IDashboardMessage,
} from './types';

const initialState = {
  banners: undefined,
  data: undefined,
  isLoading: false,
  isLoadingBanners: false,
  isLoadingMessage: false,
  message: undefined,
};

export const dashboardReducer: Reducer<DashboardState, PARSAction> = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD:
      return { ...state, isLoading: true };
    case GET_DASHBOARD_SUCCESS:
      return { ...state, data: action.payload || {}, isLoading: false };
    case GET_DASHBOARD_FAILURE:
      return { ...state, isLoading: false };
    case GET_DASHBOARD_BANNERS:
      return { ...state, isLoadingBanners: true };
    case GET_DASHBOARD_BANNERS_SUCCESS:
      return { ...state, banners: action.payload || [], isLoadingBanners: false };
    case GET_DASHBOARD_BANNERS_FAILURE:
      return { ...state, isLoadingBanners: false };
    case GET_DASHBOARD_MESSAGE:
      return { ...state, isLoadingMessage: true };
    case GET_DASHBOARD_MESSAGE_SUCCESS:
      return { ...state, isLoadingMessage: false, message: action.payload };
    case GET_DASHBOARD_MESSAGE_FAILURE:
      return { ...state, isLoadingMessage: false };
    case DISMISS_DASHBOARD_BANNER:
      return {
        ...state,
        data: {
          ...state.data,
          banners: state.data?.banners?.filter((banner) => banner.id !== action.payload),
        },
      };
    case DISMISS_DASHBOARD_MESSAGE: {
      // If the message is the same as what we are dismissing we "reset" the message to undefined.
      // This clears out the message. We check local-state to see if we render the message block.
      const newMessage = state?.message?.id === action.payload ? undefined : state.message;

      return {
        ...state,
        message: newMessage as IDashboardMessage,
      };
    }
    case DISMISS_DASHBOARD_NOTIFICATION:
      return {
        ...state,
        data: {
          ...state.data,
          notifications: state.data?.notifications?.filter((notification) => notification.id !== action.payload),
        },
      };
    default:
      return state;
  }
};
