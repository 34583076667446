import { createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  // @ts-ignore
  export type ComponentNameToClassKey = overridesNameToClassKey;
}

export const commonTheme = createMuiTheme({
  overrides: {
    MuiFab: {
      sizeSmall: {
        height: 24,
        minHeight: 24,
        width: 24,
      },
    },
    MuiFormControl: {
      root: {
        width: 'inherit',
      },
    },
    MuiFormHelperText: {
      contained: {
        margin: '.25rem .75rem 0',
      },
      root: {
        '&.Mui-error': {
          color: '#bd2e2a',
        },
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: '#bd2e2a',
      },
      root: {
        color: '#62695e',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 1.5,
        lineHeight: 1.67,
        textTransform: 'uppercase',
      },
    },
    MuiIconButton: {
      root: {
        color: 'inherit',
      },
    },
    MuiButtonBase: {
      root: {
        outline: 'revert',
        '&:visited': {
          outline: '2px solid #007bbd',
        },
        '&:focus': {
          outline: '2px solid #007bbd',
        },
        '&:focus-visible': {
          outline: '2px solid #007bbd',
        },
        '.site-ja': {
          '&:visited': {
            outline: '2px solid #00aea9',
          },
          '&:focus': {
            outline: '2px solid #00aea9',
          },
          '&:focus-visible': {
            outline: '2px solid #00aea9',
          },
        },
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        color: '#373737',
      },
      root: {
        color: '#82bc00',
        '.site-ja &': {
          color: '#00aea9',
        },
      },
    },
    MuiInputBase: {
      formControl: {
        'label + &': {
          marginTop: 20,
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'unset',
      },
    },
    MuiMenuItem: {
      root: {
        borderBottom: '0.03125rem solid #dedede',
        color: '#373737',
        fontFamily: ['"Open Sans"', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        padding: '0.625rem 1rem',
        whiteSpace: 'normal',
        '&:last-child': {
          borderBottom: 0,
        },
        '&$selected': {
          background: '#fff !important',
        },
      },
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: 0,
      },
      input: {
        backgroundColor: 'transparent',
        color: '#373737',
        '.Mui-error &': {
          color: '#bd2e2a',
        },
        '.Mui-error.Mui-focused &': {
          color: '#373737',
        },
        padding: '0.5rem 0.25rem',
      },
      notchedOutline: {
        border: 0,
        '&:hover': {
          border: '2px solid #007bbd',
        },
      },
      root: {
        backgroundColor: '#fff',
        border: '1px solid #dedede',
        borderRadius: 12,
        boxShadow: 'inset 0 1px 3px 0 #00000014',

        color: '#373737',
        fontFamily: ['"Open Sans"', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        height: '58px',
        padding: 0,
        '&.MuiInputBase-multiline': {
          height: 'auto',
          minHeight: '58px',
        },
        '&:hover': {
          border: '2px solid #007bbd',
          '.site-ja &': {
            borderColor: '#00838f',
          },
        },
        '&.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
          padding: '0.5625rem',
          paddingRight: '0.875rem !important',
        },
        '&.Mui-focused': {
          border: '2px solid #007bbd',
          '& > input.MuiOutlinedInput-input': {
            height: '95%',
            outline: 'revert',
            padding: '0 0.5rem',
          },
          '.site-ja &': {
            borderColor: '#00838f',
          },
          '[aria-expanded="true"] &': {
            '& > .MuiInputAdornment-positionEnd': {
              color: '#007bbd',
              transform: 'rotate(180deg)',
              '.site-ja &': {
                color: '#00838f',
              },
            },
          },
        },
        '&.Mui-error': {
          border: '2px solid  #bd2e2a',
          color: '#bd2e2a',
        },
      },
    },
    // @ts-ignore
    MuiPagination: {
      root: {
        display: 'flex',
        justifyContent: 'center',
        '& .MuiPaginationItem-page': {
          color: '#373737',
          fontSize: 12,
          '&.Mui-selected': {
            background: '#fff',
            color: '#663573',
          },
        },
      },
    },
    // @ts-ignore
    MuiPickersDay: {
      current: {
        color: '#007bbd',
        '.site-ja &': {
          color: '#00748c',
        },
      },
      daySelected: {
        backgroundColor: '#007bbd',
        '.site-ja &': {
          backgroundColor: '#00748c',
        },
        '&:hover': {
          backgroundColor: '#0a6799',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  props: {
    MuiIconButton: {
      'aria-label': 'button',
    },
    MuiInputBase: {
      inputProps: { 'aria-label': 'input' },
    },
  },

  typography: {
    fontFamily: ['"Open Sans"', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
  },
});
