import { ReactElement } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useFormikContext } from 'formik';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';
import moment from 'moment';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import startCase from 'lodash/startCase';

// Components
import Button from 'components/Button/Button';

// Store + Core
import { IFacetAccordion } from 'core/constants';
import { FacetTypeEnum, StatusEnum } from 'core/enums';
import { isProviderUserSelector, organizationKindSelector } from 'store/user/selectors';

// Utils
import { calculateStatusTitle } from 'utils';

// Types
import { SearchRequest } from '../../types';

interface IProps {
  callback?(): void;
  defaultFacets: IFacetAccordion[];
}

const DATE_FORMAT = 'MMM Do, YYYY';

export const FiltersSelected = (props: IProps): ReactElement => {
  const { callback, defaultFacets } = props;
  const { values, setFieldValue, setValues } = useFormikContext<SearchRequest>();

  // Selectors.
  const { organizationKind } = useSelector(organizationKindSelector);
  const isProvider: boolean = useSelector(isProviderUserSelector);

  const onRemoveArrayValue = ({
    payloadValue,
    value,
  }: Pick<IFacetAccordion, 'payloadValue'> & { value: string }): void => {
    const payloadValues = values[payloadValue];

    // If the length in formik values is 1, remove the object from `values`, otherwise just remove the one from the array.
    payloadValues.length === 1
      ? setValues(omit(values, payloadValue))
      : setFieldValue(
          payloadValue,
          payloadValues.filter((val: string): boolean => val !== value),
        );

    // API call.
    callback?.();
  };

  const onRemoveBooleanValue = ({ payloadValue }: Pick<IFacetAccordion, 'payloadValue'>): void => {
    setValues(omit(values, payloadValue));

    // API call.
    callback?.();
  };

  const onRemoveTextValue = ({ payloadValue }: Pick<IFacetAccordion, 'payloadValue'>): void => {
    setValues(omit(values, payloadValue));

    // API call.
    callback?.();
  };

  const onRemoveStartDate = ({ payloadStartValue }: Pick<IFacetAccordion, 'payloadStartValue'>): void => {
    setValues(omit(values, payloadStartValue));

    // API call.
    callback?.();
  };

  const onRemoveEndDate = ({ payloadEndValue }: Pick<IFacetAccordion, 'payloadEndValue'>): void => {
    setValues(omit(values, payloadEndValue));

    // API call.
    callback?.();
  };

  const onRemoveRangeEndValue = ({ rangeEndValue }: Pick<IFacetAccordion, 'rangeEndValue'>): void => {
    setValues(omit(values, rangeEndValue));

    // API call.
    callback?.();
  };

  const onRemoveRangeStartValue = ({ rangeStartValue }: Pick<IFacetAccordion, 'rangeStartValue'>): void => {
    setValues(omit(values, rangeStartValue));

    // API call.
    callback?.();
  };

  return (
    <>
      {defaultFacets.map(
        (facet: IFacetAccordion): ReactElement => {
          const {
            displayValue,
            type,
            payloadValue,
            payloadEndValue,
            payloadStartValue,
            organizationKinds,
            rangeEndValue,
            rangeStartValue,
          } = facet;
          const shouldDisplay = organizationKinds.includes(organizationKind);

          switch (type) {
            case FacetTypeEnum.Array:
              // Check to make sure the value is an array for queryStrings.
              if (!isArray(values?.[payloadValue])) return null;
              return (
                shouldDisplay &&
                values?.[payloadValue]?.map(
                  (value: StatusEnum, idx: number): ReactElement => {
                    // We show `Submitted` as a status if the user is a Provider instead of `Not Processed`.
                    // A Provider user should never see `Not Processed`.
                    const facetValue: StatusEnum = calculateStatusTitle({ isProvider, statusTitle: value });

                    return (
                      <Button
                        className="pill"
                        key={`${payloadValue}-${idx}`}
                        onClick={(): void => onRemoveArrayValue({ payloadValue, value: facetValue })}
                      >
                        {facetValue} <CloseIcon />
                      </Button>
                    );
                  },
                )
              );
            case FacetTypeEnum.Boolean:
              // Check to make sure the value is a boolean for queryStrings.
              if (!isBoolean(values?.[payloadValue])) return null;
              return (
                shouldDisplay &&
                values?.[payloadValue] !== undefined && (
                  <Button
                    className="pill"
                    key={payloadValue}
                    onClick={(): void => onRemoveBooleanValue({ payloadValue })}
                  >
                    {displayValue}: {values[payloadValue] ? 'Yes' : 'No'} <CloseIcon />
                  </Button>
                )
              );
            case FacetTypeEnum.Date: {
              return (
                <>
                  {shouldDisplay && values?.[payloadStartValue] && (
                    <Button
                      className="pill"
                      key={values[payloadStartValue]}
                      onClick={(): void => onRemoveStartDate({ payloadStartValue })}
                    >
                      Start {displayValue}: {moment(values[payloadStartValue]).format(DATE_FORMAT)} <CloseIcon />
                    </Button>
                  )}
                  {shouldDisplay && values?.[payloadEndValue] && (
                    <Button
                      className="pill"
                      key={values[payloadEndValue]}
                      onClick={(): void => onRemoveEndDate({ payloadEndValue })}
                    >
                      End {displayValue}: {moment(values[payloadEndValue]).format(DATE_FORMAT)} <CloseIcon />
                    </Button>
                  )}
                </>
              );
            }
            case FacetTypeEnum.Range: {
              return (
                <>
                  {shouldDisplay && values?.[rangeStartValue]?.toString() && (
                    <Button
                      className="pill"
                      key={values[rangeStartValue]}
                      onClick={(): void => onRemoveRangeStartValue({ rangeStartValue })}
                    >
                      {startCase(rangeStartValue)}: {values[rangeStartValue]} <CloseIcon />
                    </Button>
                  )}
                  {shouldDisplay && values?.[rangeEndValue]?.toString() && (
                    <Button
                      className="pill"
                      key={values[rangeEndValue]}
                      onClick={(): void => onRemoveRangeEndValue({ rangeEndValue })}
                    >
                      {startCase(rangeEndValue)}: {values?.[rangeEndValue]} <CloseIcon />
                    </Button>
                  )}
                </>
              );
            }
            case FacetTypeEnum.Text: {
              return (
                <>
                  {shouldDisplay && values?.[payloadValue] && (
                    <Button
                      className="pill"
                      key={values[payloadStartValue]}
                      onClick={(): void => onRemoveTextValue({ payloadValue })}
                    >
                      {`${displayValue}: ${values[payloadValue]}`}
                      <CloseIcon />
                    </Button>
                  )}
                </>
              );
            }
            case FacetTypeEnum.Typeahead: {
              // If it's a string, remove the value.
              if (!isArray(values?.[payloadValue])) {
                return (
                  <>
                    {shouldDisplay && values?.[payloadValue] && (
                      <Button
                        className="pill"
                        key={values[payloadStartValue]}
                        onClick={(): void => onRemoveTextValue({ payloadValue })}
                      >
                        {`${displayValue}: ${values[payloadValue]}`}
                        <CloseIcon />
                      </Button>
                    )}
                  </>
                );
              }
              // Else, it's an array. Go through and remove the correct one.
              return (
                <>
                  {shouldDisplay &&
                    values?.[payloadValue]?.map(
                      (value: string, idx: number): ReactElement => (
                        <Button
                          className="pill"
                          key={`${payloadValue}-${idx}`}
                          onClick={(): void => onRemoveArrayValue({ payloadValue, value })}
                        >
                          {value} <CloseIcon />
                        </Button>
                      ),
                    )}
                </>
              );
            }
            default:
              return null;
          }
        },
      )}
    </>
  );
};
