import { ReactElement } from 'react';
import { Step, IStepProps } from './Step';

import './stepper.module.scss';

export interface IStepperProps {
  steps?: Array<IStepProps>;
  handleClick?: (label: string) => void;
}

export const Stepper = (props: IStepperProps): ReactElement => {
  const { steps, handleClick } = props;
  return (
    <ul className="stepper">
      {steps?.map(
        (step: IStepProps): ReactElement => {
          return (
            <Step
              onClick={() => handleClick(step.label)}
              label={step.label}
              stepStatus={step.stepStatus}
              key={step.label}
              visible={step.visible}
            />
          );
        },
      )}
    </ul>
  );
};
