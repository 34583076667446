// Libs
import { omit, keys, uniq, xor, get, isArray } from 'lodash';

// Core
import { IJsonPatchDocumentOperation } from 'core/models';

interface IRecursivePatch<T extends Record<string, unknown>> {
  basePath?: string[];
  baseObject: T;
  omitList?: string[];
  patchList?: IJsonPatchDocumentOperation[];
  updatedObject: T;
}

export const recursivePatch = <T extends Record<string, unknown>>({
  basePath = [],
  baseObject,
  omitList = [],
  patchList = [],
  updatedObject,
}: IRecursivePatch<T>): IJsonPatchDocumentOperation[] => {
  const initBase = omit(baseObject, omitList);
  const initUpdated = omit(updatedObject, omitList);
  const baseKeys = keys(initBase);
  const updatedKeys = keys(initUpdated);
  const combinedUniqueKeys = uniq([...baseKeys, ...updatedKeys]);
  combinedUniqueKeys.forEach((baseKey) => {
    // Get the key-value from base object and updated object
    const baseValue = get(baseObject, [baseKey]);
    const updatedValue = get(updatedObject, [baseKey]);
    // If both values are exactly equal, skip creating a patch entry
    if (baseValue !== updatedValue) {
      // One of the two objects MUST contain a defined value for the key in order to continue, otherwise skip this key
      if (baseValue !== undefined || updatedValue !== undefined) {
        const patchPath = `${basePath.length ? '/' : ''}${basePath.join('/')}/${baseKey}`;
        if (baseValue !== undefined && updatedValue === undefined) {
          patchList.push({ op: 'remove', path: patchPath });
        } else if (baseValue === undefined && updatedValue !== undefined) {
          patchList.push({ op: 'add', path: patchPath, value: updatedValue });
        } else {
          // If both are defined and the value's type is a primitive or an array, replace
          if (typeof baseValue !== 'object' || isArray(baseValue)) {
            if (isArray(baseValue)) {
              if (xor(baseValue, updatedValue as unknown[])?.length) {
                patchList.push({ op: 'replace', path: patchPath, value: updatedValue });
              }
            } else {
              patchList.push({ op: 'replace', path: patchPath, value: updatedValue });
            }
          }
          // If both are defined and the value's type is an object, begin recursive patch
          if (
            typeof baseValue === 'object' &&
            !isArray(baseValue) &&
            typeof updatedValue === 'object' &&
            !isArray(updatedValue)
          ) {
            recursivePatch({
              baseObject: baseValue as Record<string, unknown>,
              basePath: [...basePath, baseKey],
              patchList,
              updatedObject: updatedValue as Record<string, unknown>,
            });
          }
        }
      }
    }
  });
  return patchList;
};
