import { ComponentType, ReactElement, ReactNode, useCallback, useEffect } from 'react';
import { Route, RouteProps, RouteComponentProps, useLocation, Redirect, matchPath } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import noop from 'lodash/noop';

// Components.
import { LoadingOverlay } from 'components/LoadingOverlay';
import { Header } from 'components/Header';
import { Toast } from 'components/Toast';

// Core.
import Configuration from 'core/configuration';
import { ARMATURE_CUSTOMER_QUERY_PARAM } from 'core/constants';
import { IUserOrganization, OrganizationKind, OrganizationKinds } from 'core/models';

// Services.
import { CacheService, TokenService } from 'services';
import { AnalyticsService } from 'services/AnalyticsService';

// Store.
import { userSuccessAction, getCurrentUser } from 'store/user/actions';
import {
  currentOrganizationContextSelector,
  currentProviderContextSelector,
  isLoadingProfileSelector,
  organizationKindSelector,
} from 'store/user/selectors';
import { eatToast, popToast } from 'store/toast/actions';
import { errorStickyToastOptions } from 'store/toast/constants';

// Types.
export interface IAuthenticatedLayoutRouteProps extends RouteProps {
  component?: ComponentType<any>;
  hideSubnav?: boolean;
  isBoardUserAllowed?: boolean;
  isStaffOnly?: boolean;
  layout: ComponentType<any>;
  redirectTo?: string;
  render?: (props: RouteComponentProps<any>) => ReactNode;
  allowInvalidUser?: boolean;
}

const UNRESTRICTED_PATHS = [
  '/',
  '/404',
  '/dashboard',
  '/activities',
  '/activities/summary',
  '/activities/detail/:id',
  '/learners',
  '/learners/summary',
  '/learners/summary/detail/:id',
  '/learners/search',
  '/learners/validation',
  '/learners/validation/batch',
  '/learners/validation/batch/history',
  '/learners/:learnerId/learnercompletions/:completionId',
  '/learners/:activityId/learnercompletions/bylearner/:ulid',
  '/learners/:activityId/learnercompletions/bycompletion/:completionId',
  '/contact-support',
  '/reports',
  '/reports/summaryreports',
  '/reports/view/:userId/:reportId',
  '/account-manager',
  '/account-manager/users/add',
  '/account-manager/users/edit/:id',
  '/account-manager/edit-organization/:id',
  '/account-manager/provider/add',
  '/account-manager/provider/edit/:id',
];

const AuthenticatedLayoutRoute = (props: IAuthenticatedLayoutRouteProps): ReactElement => {
  const {
    allowInvalidUser = false,
    component: Component,
    hideSubnav,
    isBoardUserAllowed = true,
    isStaffOnly,
    layout: Layout,
    redirectTo,
    render,
    ...rest
  } = props;
  const { getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();

  const dispatch = useDispatch();
  const location = useLocation();

  // Selectors
  const currentOrganization: IUserOrganization = useSelector(currentOrganizationContextSelector);
  const currentProvider: IUserOrganization = useSelector(currentProviderContextSelector);
  const organizationKind: OrganizationKind = useSelector(organizationKindSelector)?.organizationKind;
  const isProfileLoading: boolean = useSelector(isLoadingProfileSelector);
  const isStaff: boolean =
    organizationKind === OrganizationKinds.SPECIAL_ORGANIZATION || organizationKind === OrganizationKinds.STAFF;
  const isBoardUser: boolean = organizationKind === OrganizationKinds.BOARD;
  const isProviderSelected = !!currentProvider || currentOrganization?.organizationKind === OrganizationKinds.PROVIDER;
  const isPathRestricted = !matchPath(location.pathname, { exact: true, path: UNRESTRICTED_PATHS })?.isExact;
  const isUserOnRestrictedPath =
    isPathRestricted && !isProviderSelected && !isProfileLoading && !(isStaffOnly && isStaff);

  const getAccessToken = useCallback(async () => {
    // Sequencing is important. Get the Access Token and persist it
    // Then dispatch the getCurrentUser action, which relies on the Access Token
    const token: string = await getAccessTokenSilently({
      audience: Configuration.authAudience,
      scope: Configuration.authScope,
    });
    TokenService.setAccessToken(token);

    // Check the query string for armature.
    const { search } = location;
    const searchParams = new URLSearchParams(search);
    const customerId = searchParams.get(ARMATURE_CUSTOMER_QUERY_PARAM);
    let isRedirecting = false;

    // Get user.
    if (!currentOrganization?.id) {
      isRedirecting = await ((dispatch(getCurrentUser(customerId)) as unknown) as Promise<boolean>);
    }

    // If customerId was used (and we aren't already being redirected due to getCurrentUser), reload the page without query string.
    if (customerId && !isRedirecting) {
      window.location.href = window.location.href.split('?')[0];
    }
    // we don't want to re-run this if currentOrganization changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getAccessTokenSilently, location]);

  useEffect(() => {
    if (isUserOnRestrictedPath) {
      dispatch(popToast({ ...errorStickyToastOptions, message: <>Please select a provider to continue</> }));
      return;
    }
    dispatch(eatToast());
  }, [isUserOnRestrictedPath]);

  useEffect(() => {
    // If the user is not loading and is not authenticated then redirect them out.
    if (!isAuthenticated && !isLoading) {
      const { pathname, search } = location;

      // Clear cache to prevent session leaks.
      CacheService.clear();

      // Store the URI to redirect to after logging in.
      CacheService.set('redirectToUri', `${pathname}${search}`);

      loginWithRedirect({
        appState: {
          returnTo: 'redirectToUri',
        },
      }).then(noop);
    }

    // If we have a user, that is a success.
    if (user) {
      AnalyticsService.setUser(user.sub); // this will get overwritten as soon as we have a real ID from our DB, so it's ok that it's the auth0 ID
      dispatch(userSuccessAction(user));
    }
    // If the user is authed and we are not loading, get the access token.
    if (isAuthenticated && !isLoading) getAccessToken().then(noop);
  }, [getAccessToken, isAuthenticated, isLoading, loginWithRedirect, user, dispatch, location]);

  const handleRenderLayoutOrRedirect = (props): ReactElement => {
    // In order to clear the loader, we need to not be loading and be authed (but don't need to be valid - we handle valid separately)
    const isLoadedAndAuthed = !!(!isLoading && isAuthenticated);
    const isValidUser = !!currentOrganization?.id;

    // Render this while loading.
    const loadingComponent: ReactElement = <LoadingOverlay isOpen />;
    const blankLayout: ReactElement = (
      <>
        <Header hideSubnav />
        <Toast />
      </>
    );

    const layout: ReactElement = (
      <Layout hideSubnav={hideSubnav}>{Component ? <Component {...props} /> : render ? render(props) : null}</Layout>
    );

    if (isLoadedAndAuthed) {
      AnalyticsService.trackPageView();
    }

    // If the user is not a provider or does not have a valid provider selected
    if (isLoadedAndAuthed && isUserOnRestrictedPath) {
      return blankLayout;
    }

    // Redirect if the user is a board user and is not allowed.
    if (isLoadedAndAuthed && !isBoardUserAllowed && !!isBoardUser) {
      return <Redirect to="/404" />;
    }

    // Redirect if the user is not staff.
    if (isLoadedAndAuthed && !isStaff && !!isStaffOnly) {
      return <Redirect to="/404" />;
    }

    // If an explicit `redirectTo` is provided this will:
    // - Go through the auth flow
    // - Render the correct layout
    // - Update the URL
    if (isLoadedAndAuthed && redirectTo) {
      return (
        <>
          <Redirect to={redirectTo} />
          {layout}
        </>
      );
    }

    // If the Auth0 SDK has loaded and the user is authenticated, load the route.
    // Otherwise, render a loading placeholder.
    else if (isLoadedAndAuthed && !isProfileLoading) {
      return isValidUser || allowInvalidUser ? layout : blankLayout;
    } else {
      return loadingComponent;
    }
  };

  return <Route {...rest} render={handleRenderLayoutOrRedirect} />;
};

export default AuthenticatedLayoutRoute;
