import {
  TAXONOMY_ACTIVITY_CREDIT_ROOT_ID,
  TAXONOMY_ACTIVITY_PHARMACY_TOPIC_ID,
  TAXONOMY_ACTIVITY_PHARMACY_TYPE_ID,
  TAXONOMY_ACTIVITY_PHARMACY_CONTENT_ID,
  TAXONOMY_ACTIVITY_PHARMACY_CONTENT_STATE_ID,
  TAXONOMY_ACTIVITY_PHARMACY_CERTIFICATION_ID,
  TAXONOMY_COMMENDATION_CRITERIA_ROOT_ID,
  TAXONOMY_OUTCOMES_MEASURED_ROOT_ID,
  TAXONOMY_PARTICIPATION_FEE_ROOT_ID,
  TAXONOMY_REMS_ROOT_ID,
  TAXONOMY_SUPPORT_SOURCES_ROOT_ID,
} from 'core/constants';

export const termSetIds: string[] = [
  TAXONOMY_COMMENDATION_CRITERIA_ROOT_ID,
  TAXONOMY_OUTCOMES_MEASURED_ROOT_ID,
  TAXONOMY_REMS_ROOT_ID,
  TAXONOMY_SUPPORT_SOURCES_ROOT_ID,
  TAXONOMY_PARTICIPATION_FEE_ROOT_ID,
  TAXONOMY_ACTIVITY_CREDIT_ROOT_ID,
  TAXONOMY_ACTIVITY_PHARMACY_TYPE_ID,
  TAXONOMY_ACTIVITY_PHARMACY_CONTENT_ID,
  TAXONOMY_ACTIVITY_PHARMACY_CONTENT_STATE_ID,
  TAXONOMY_ACTIVITY_PHARMACY_CERTIFICATION_ID,
  TAXONOMY_ACTIVITY_PHARMACY_TOPIC_ID,
];

export const defaultAddFormProps = {
  commendationCriteriaIds: [],
  description: '',
  detailsUrl: '',
  hasCommercialSupport: 'hasCommercialSupport-false',
  includeInCmeFinder: 'includeInCmeFinder-true',
  internalId: '',
  isMips: 'isMips-false',
  isMoc: 'isMoc-false',
  isRems: 'isRems-false',
  hasStateContentTags: 'stateContentTagNotSure0',
  hasPharmacyRecertifications: 'noPharmCert0',
  hasPharmacyContentTags: 'noPharmContentTag0',
  contentTags: [],
  pharmacyContentTagTaxonomyTerms: [],
  pharmacyContentTagStateTaxonomyTerms: [],
  pharmacyRecertificationTaxonomyTerms: [],
  markedForCommendationCriteria: 'markedForCommendationCriteria-false',
  remsRpcId: '',
  tempDatesAndLocation: [{}], // Leave as an array with an initial empty object to pre-populate the dates on page 1
  title: '',
  isIndividualActivity: false,
  iaOrganizationName: '',
};
