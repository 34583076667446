import { ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import Configuration from 'core/configuration';
import { KeyboardBackspaceRounded } from '@material-ui/icons';
import { useMediaQuery } from '@material-ui/core';

// Services
import { CacheService } from 'services';

// Styles
import './login.module.scss';

// Constants
import { BreakpointsEnum } from 'core/enums';

const LogoutContainer = (): ReactElement => {
  const { isAuthenticated, logout } = useAuth0();
  const isMedBreakpoint = useMediaQuery(`(max-width:${BreakpointsEnum.sm})`);

  const handleLogOut = () => {
    if (isAuthenticated) {
      logout({ returnTo: Configuration.authRedirectUri });
      CacheService.clear();
    }
  };

  return (
    <>
      <button
        className={classNames('user-auth tertiary', { 'in-menu': isAuthenticated })}
        onClick={() => handleLogOut()}
        style={isMedBreakpoint ? { color: 'white' } : null}
      >
        Log Out <KeyboardBackspaceRounded className="tertiary-icon" />
      </button>
    </>
  );
};

export default LogoutContainer;
