import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { FilterListRounded } from '@material-ui/icons';
import noop from 'lodash/noop';

// Components
import BulkActions from 'components/Summary/BulkActions';
import { LearnerSearchTable } from 'components/LearnerSearchTable';
import { NoResultsCard } from 'components/NoResultsCard';
import { LearnerSearchFacets } from 'components/ContinuousImprovement/Facets/LearnerSearchFacets';
import Button from 'components/Button/Button';
import { SearchPagination } from 'components/SearchPagination';
import { LoadingCards } from 'components/LoadingCard';

// Hooks
import { useClickEvent, useQueryString } from 'hooks';

// Core + Store
import {
  exportAllLearners,
  exportLearnerSearch,
  getLearnerSearch,
  onUpdateLearnerSearchStateProps,
  resetLearnerSearchResponse,
  updateLearnerSearch,
  updateLearnerSearchStateProps,
} from 'store/learner/actions';
import {
  learnerSearchCurrentPageSelector,
  learnerSearchRequestSelector,
  learnerSearchResponseSelector,
  learnerSearchResultsSelector,
  learnerSearchTotalCountSelector,
  searchLeanerResultsLoadingSelector,
  searchLearnerSelectedSelector,
} from 'store/learner/selectors';
import { Constants } from 'core';
import { isRailOpenSelector } from 'store/rail/selectors';
import { IActivityLearnerSearchRequest, IActivityLearnerSearchResponse, ILearnerSearchActivity } from 'core/models';
import { closeRail, openRail } from 'store/rail/actions';

// Data + Helpers
import { INITIAL_LEARNER_SEARCH_FACETS } from 'components/ContinuousImprovement/Facets/LearnerSearchFacets/constants';
import { perPageMenuItems } from './perPageMenuItems';
import { sortLearnerMenu } from './sortMenuItems';

// Utils
import { getPaginationCount } from 'globals/utils/getPaginationCount';
import { downloadMenuItems, DOWNLOAD_ALL_LEARNERS, DOWNLOAD_FILTERED_LEARNERS } from './downloadMenuItems';

// Types
import { SearchRequest } from 'components/ContinuousImprovement/Facets/types';

export const LearnerSearchPage = (): ReactElement => {
  const dispatch = useDispatch();
  const urlSearchPayload = useQueryString<SearchRequest>();
  const fireDownloadEvent = useClickEvent({ Event: 'Learner Search', EventCategory: 'Download' });

  // Redux states.
  const searchResponse: IActivityLearnerSearchResponse = useSelector(learnerSearchResponseSelector);
  const searchRequest: IActivityLearnerSearchRequest = useSelector(learnerSearchRequestSelector);
  const learnerActivities: ILearnerSearchActivity[] = useSelector(learnerSearchResultsSelector);
  const learnerSearchTotalCount = useSelector(learnerSearchTotalCountSelector);
  const selectedLearners: ILearnerSearchActivity[] = useSelector(searchLearnerSelectedSelector);
  const currentPage = useSelector(learnerSearchCurrentPageSelector);
  const isLoading: boolean = useSelector(searchLeanerResultsLoadingSelector);
  const isFacetsOpen = useSelector(isRailOpenSelector);

  // Sorting functions.
  const perPage = (top: number) => dispatch(getLearnerSearch({ top }));
  const sortByBoard = () => dispatch(getLearnerSearch({ sortBy: Constants.SORT_BY_BOARD }));
  const sortByLearnerName = () => dispatch(getLearnerSearch({ sortBy: Constants.SORT_BY_LEARNER_NAME }));
  const sortByLearnerId = () => dispatch(getLearnerSearch({ sortBy: Constants.SORT_BY_LEARNER_ID }));
  const sortByActivityTitle = () => dispatch(getLearnerSearch({ sortBy: Constants.SORT_BY_LEARNER_ACTIVITY_TITLE }));
  const sortByActivityId = () => dispatch(getLearnerSearch({ sortBy: Constants.SORT_BY_LEARNER_ACTIVITY_ID }));
  const sortByTotalCredits = () => dispatch(getLearnerSearch({ sortBy: Constants.SORT_BY_TOTAL_CREDITS }));
  const sortByStatus = () => dispatch(getLearnerSearch({ sortBy: Constants.SORT_BY_STATUS }));

  const toggleSortAscending = () => dispatch(getLearnerSearch({ sortAscending: !searchRequest.sortAscending }));

  // Pagination functions.
  const goToPage = (_, newPage: number) => dispatch(onUpdateLearnerSearchStateProps({ page: newPage }));

  useEffect(() => {
    dispatch(getLearnerSearch(urlSearchPayload, false));

    return async (): Promise<void> => {
      Promise.all([
        await dispatch(updateLearnerSearch(INITIAL_LEARNER_SEARCH_FACETS)),
        await dispatch(resetLearnerSearchResponse()),
        await dispatch(updateLearnerSearchStateProps({ page: 1 })),
      ]).then(noop);
    };
  }, [dispatch]);

  // Export/Download functions.
  const onDownloadAllLearners = useCallback(() => {
    fireDownloadEvent({ EventAction: DOWNLOAD_ALL_LEARNERS });

    dispatch(exportAllLearners({}));
  }, [dispatch, fireDownloadEvent]);

  const onDownloadFilteredLearners = useCallback(() => {
    fireDownloadEvent({ EventAction: DOWNLOAD_FILTERED_LEARNERS });
    dispatch(exportLearnerSearch());
  }, [dispatch, fireDownloadEvent]);

  // Show this when we're waiting for results.
  const isPending = isLoading && <LoadingCards />;

  // Show this when we have results.
  const hasResults: ReactElement = !!learnerActivities?.length && (
    <LearnerSearchTable
      selectedAll={selectedLearners?.length === searchResponse?.results?.length}
      results={learnerActivities}
    />
  );

  // Show this when we don't have any results.
  const noResults: ReactElement = !learnerActivities?.length && <NoResultsCard />;

  // Pagination count selected.
  const paginationCount: number = getPaginationCount({
    perPageCount: searchRequest.top,
    totalCount: learnerSearchTotalCount,
  });

  return (
    <>
      <aside className={classNames('rail-container rail-container--full', { open: isFacetsOpen })}>
        <LearnerSearchFacets
          handleClose={() => dispatch(closeRail())}
          initialValues={{ ...INITIAL_LEARNER_SEARCH_FACETS, ...searchRequest, ...urlSearchPayload }}
        />
      </aside>
      <section className="summary-container">
        <div className="form-title-container">
          <h4 className="title">Learner Search</h4>
        </div>
        {/* Render the facets for mobile. */}
        <div className="summary-refiners mobile">
          <Button className="btn-addfilter" onClick={() => dispatch(openRail())}>
            <FilterListRounded /> Add Filters
          </Button>
        </div>
        {/* Filters the main content. */}
        <BulkActions
          count={learnerSearchTotalCount}
          countLabel={`Learner${learnerSearchTotalCount !== 1 ? 's' : ''}`}
          perPageMenuProps={perPageMenuItems({ perPage, searchRequest })}
          sortAscending={searchRequest.sortAscending}
          sortAscendingAction={toggleSortAscending}
          downloadMenuProps={downloadMenuItems({
            onDownloadAllLearners,
            onDownloadFilteredLearners,
          })}
          hasDownloadMenu
          isSingleOrNoResult={learnerSearchTotalCount < 2}
          isNoResults={learnerSearchTotalCount < 1}
          sortMenuProps={sortLearnerMenu({
            sortBy: searchRequest.sortBy,
            sortByActivityId,
            sortByActivityTitle,
            sortByBoard,
            sortByLearnerId,
            sortByLearnerName,
            sortByStatus,
            sortByTotalCredits,
          })}
        />
        {/* Render the main content. */}
        {hasResults || isPending || noResults}
        {/* Pagination */}
        <SearchPagination
          paginationCount={paginationCount}
          currentPage={currentPage}
          goToPage={goToPage}
          totalCount={learnerSearchTotalCount}
        />
      </section>
    </>
  );
};
