import camelCase from 'lodash/camelCase';
import { IValidationException } from 'core/models';
import { clientErrorMessageMap } from './clientErrorMessageMap';

const SEPARATOR = '__';
const ERROR_FALLBACK_MSG = 'Please provide a new value.';

export const handleValidationException = (validationException: IValidationException): [string, string] => {
  const { errorCode, message = ERROR_FALLBACK_MSG } = validationException;
  const splitException: string[] = errorCode.split(SEPARATOR);

  if (splitException.length === 4) {
    // (tuple val1): Index 2 is always the field that errored.
    // (tuple val2): Override the error message from the server if in the map; otherwise use the server message.
    return [camelCase(splitException[2]), clientErrorMessageMap[errorCode] || message];
  }

  return [undefined, message];
};

/**
 * Error codes
 *
 * Error codes are generated on the backend and are spaced with `__`.
 * Below is an example of a potential error code with an explanation of each index.
 *
 * 'BAD_REQUEST__BOARD__ACTIVITY_START_DATE__REQUIRED'
 *      ^(0)      ^(1)         ^(2)            ^(3)
 *
 * Possible values for each index + description:
 * (0):
 *   -- INTERNAL_SERVER_ERROR = 500: SOMETHING BAD HAPPENED ON THE SERVER, NOT RECOVERABLE - TRY AGAIN LATER
 *   -- NOT_FOUND__(ACTIVITY/BOARD ETC) = 404: CANNOT FIND THIS ENTITY
 *   -- BAD_REQUEST = 400: YOU DID SOMETHING BAD, SERVER DID NOT, RECOVERABLE. MAKE A CHANGE THEN TRY AGAIN
 * (1):
 *   -- [*ANY*]: THE SERVER ENDPOINT THAT ERRORED
 * (2):
 *   -- [*ANY*]: THE FORMIK KEY THAT ERRORED
 * (3):
 *   -- INVALID = OUT OF RANGE (EXAMPLE: DATES ARE OUT OF RANGE)
 *   -- MISMATCH = ID INSIDE A PAYLOAD DOES NOT MATCH THE ID ON A ROUTE
 *   -- REQUIRED = NOT PROVIDED BUT SHOULD BE
 *   -- NOT_EDITABLE = USER IS TRYING TO CHANGE A VALUE OF A FIELD THAT IS PROTECTED
 */
