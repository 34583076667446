import { ReactElement, useEffect, useRef } from 'react';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';

// Types
import { FormikNumberFieldProps } from '../types';

export const FormikNumberField = ({
  ariaLabel,
  defaultValue,
  forceUpdateDefaultValue,
  formikKey,
  max,
  min,
  step = 0.25,
  watermark,
  ...props
}: FormikNumberFieldProps): ReactElement => {
  const [field, meta, helpers] = useField<string>(formikKey);
  const { name, onChange } = field;
  const { error, touched } = meta;

  const numberInputRef = useRef(null);

  // Update the defaultValue
  useEffect(() => {
    if (forceUpdateDefaultValue) {
      helpers.setValue(defaultValue?.toString(), true);
    }

    // Helpers isn't stable so it should be add to deps.
  }, [defaultValue, forceUpdateDefaultValue]);

  useEffect(() => {
    const ignoreScroll = (e) => {
      e.preventDefault();
    };

    const currentInputRef = numberInputRef.current;
    if (currentInputRef) {
      currentInputRef.addEventListener('wheel', ignoreScroll);
      return () => {
        currentInputRef.removeEventListener('wheel', ignoreScroll);
      };
    }
  }, []);

  return (
    <TextField
      className="form-input form-input--small disable-scroll"
      defaultValue={defaultValue}
      error={touched && !!error}
      helperText={meta && touched ? error : ''}
      id={formikKey}
      InputProps={{
        inputProps: {
          'aria-label': ariaLabel,
          max,
          min,
          step,
        },
        placeholder: watermark,
      }}
      name={name}
      onChange={onChange}
      type="number"
      ref={numberInputRef}
      {...field}
      {...props}
    />
  );
};
