import {
  TAXONOMY_FAQ_ROOT_ID,
  TAXONOMY_ACTIVITY_REVIEW_TYPES_ID,
  TAGS_ENUM,
  TAXONOMY_ACTIVITY_CERTIFYING_BOARD_ROOT_ID,
  TAXONOMY_ACTIVITY_CREDIT_ROOT_ID,
  TAXONOMY_ACTIVITY_PHARMACY_TOPIC_ID,
  TAXONOMY_ACTIVITY_PHARMACY_TYPE_ID,
  TAXONOMY_COMMENDATION_CRITERIA_ROOT_ID,
  TAXONOMY_FOOTER_ID,
  TAXONOMY_LEARNER_TYPES_ROOT_ID,
  TAXONOMY_MEDICAL_SCHOOLS_ROOT_ID,
  TAXONOMY_NAVIGATION_ROOT_ID,
  TAXONOMY_OUTCOMES_MEASURED_ROOT_ID,
  TAXONOMY_PARTICIPATION_FEE_ROOT_ID,
  TAXONOMY_REMS_ROOT_ID,
  TAXONOMY_SUPPORT_SOURCES_ROOT_ID,
  TAXONOMY_ACTIVITY_REVIEW_OUTCOMES_ID,
  TAXONOMY_HELP_DESK_ID,
  TAXONOMY_BATCH_ROOT_ID,
  TAXONOMY_BATCH_MESSAGES_ID,
  TAXONOMY_TAB_DELIM_SUPPORT_ID,
  TAXONOMY_BATCH_HELP_ROOT_ID,
  ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL_TAGS,
} from 'core/constants';

import { createSelector } from 'reselect';
import { AppState } from '../index';

import { IDictionary, ITaxonomyTerm, IUserOrganization } from 'core/models';
import { TaxonomyState } from './types';
import { organizationKindSelector } from 'store/user/selectors';
import { filterTaxonomyByOrg } from 'globals/utils/filterTaxonomyByOrg';

/*************
 * Raw Taxonomy State
 *************/
/**
 * @function taxonomyStateSelector
 * @param taxonomyState
 * @description - raw redux taxonomy state
 * @returns TaxonomyState
 */
export const taxonomyStateSelector = ({ taxonomyState }: AppState): TaxonomyState => taxonomyState;

export const taxonomyRootTermsSelector = createSelector(
  taxonomyStateSelector,
  (taxonomyState: TaxonomyState) => taxonomyState?.terms,
);

/**
 * @function getTaxonomyStateById
 * @param id - Taxonomy root ID constant
 * @description - return the taxonomy for a specific term; useful for detecting missing taxonomy data
 */
export const getTaxonomyStateById = (id = ''): ((AppState) => ITaxonomyTerm) => ({
  taxonomyState,
}: AppState): ITaxonomyTerm => {
  if (id) {
    return taxonomyState?.terms[id];
  }
};

/******************
 * Outcome Measured
 *****************/
/**
 * @function outcomeMeasureSelector
 * @param taxonomyState
 * @description - raw redux selector that returns the outcome measure object.
 * @returns ITaxonomyTerm
 */
export const outcomeMeasureSelector = ({ taxonomyState }: AppState): ITaxonomyTerm =>
  taxonomyState.terms[TAXONOMY_OUTCOMES_MEASURED_ROOT_ID];

export const outcomeMeasureChildTermsSelector = createSelector(
  outcomeMeasureSelector,
  (outcome: ITaxonomyTerm) => outcome?.terms || [],
);

/***************
 * Credit
 **************/
/**
 * @function creditSelector
 * @param taxonomyState
 * @description - raw redux selector that returns the credit object.
 * @returns ITaxonomyTerm
 */
export const creditSelector = ({ taxonomyState }: AppState): ITaxonomyTerm =>
  taxonomyState.terms[TAXONOMY_ACTIVITY_CREDIT_ROOT_ID];

/**
 * @function creditChildTermsSelector
 * @description - Returns all of the credit terms
 * @returns ITaxonomyTerm[]
 */
export const creditChildTermsSelector = createSelector(creditSelector, (credit: ITaxonomyTerm) => credit?.terms || []);

/**
 * @function creditCertifyingBoardSelector
 * @param taxonomyState
 * @description - raw redux selector that returns the certifying board object.
 * @returns ITaxonomyTerm
 */
export const creditCertifyingBoardSelector = ({ taxonomyState }: AppState): ITaxonomyTerm =>
  taxonomyState.terms[TAXONOMY_ACTIVITY_CERTIFYING_BOARD_ROOT_ID];

/**
 * @function pharmacyCreditTermIdSelector
 * @description - Returns the term that has the tag of `ACTIVITY_CREDIT_TYPE__PHARMACY`
 * @returns ITaxonomyTerm
 */
export const pharmacyCreditTermIdSelector = createSelector(creditChildTermsSelector, (terms: ITaxonomyTerm[]) =>
  terms.find(({ tag }: ITaxonomyTerm): boolean => tag === TAGS_ENUM.ACTIVITY_CREDIT_TYPE__PHARMACY),
);

/**
 * @function amaCreditTermIdSelector
 * @description - Returns the term that has the tag of `AMA`
 * @returns ITaxonomyTerm
 */
export const amaCreditTermIdSelector = createSelector(creditChildTermsSelector, (terms: ITaxonomyTerm[]) =>
  terms.find(({ tag }: ITaxonomyTerm): boolean => tag === TAGS_ENUM.ACTIVITY_CREDIT_TYPE__AMA_PRA_CATEGORY1),
);
/**
 * @function ipceCreditTermIdSelector
 * @description - Returns the term that has the tag of `IPCE`
 * @returns ITaxonomyTerm
 */
export const ipceCreditTermIdSelector = createSelector(creditChildTermsSelector, (terms: ITaxonomyTerm[]) =>
  terms.find(({ tag }) => tag === TAGS_ENUM.ACTIVITY_CREDIT_TYPE__IPCE_CREDITS),
);

/**
 * @function anccContactHoursCreditTermSelector
 * @description - Returns the term that has the tag of `ANCC_CONTACT_HOURS`
 * @returns ITaxonomyTerm
 */
export const anccContactHoursCreditTermSelector = createSelector(creditChildTermsSelector, (terms: ITaxonomyTerm[]) =>
  terms.find(({ tag }) => tag === TAGS_ENUM.ACTIVITY_CREDIT_TYPE__ANCC_CONTACT_HOURS),
);

/**
 * @function obceCreditLevelCreditTermsSelector
 * @description - Returns the terms that has the tag of `OB_CE_CREDIT_LEVEL`
 * @returns ITaxonomyTerm[]
 */
export const obceCreditLevelCreditTermsSelector = createSelector(creditChildTermsSelector, (terms: ITaxonomyTerm[]) =>
  terms.filter(({ tag }) => ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL_TAGS.includes(tag)),
);

/**
 * @function creditTypesDictionarySelector
 * @description - IDictionary of Credit Types terms by id ex. { [id]: term }
 * @returns IIDictionary<ITaxonomyTerm>
 */
export const creditTypesDictionarySelector = createSelector(
  creditChildTermsSelector,
  (creditTypeTerms: ITaxonomyTerm[]): IDictionary<ITaxonomyTerm> => {
    const creditTypesDictionary: IDictionary<ITaxonomyTerm> = {};
    creditTypeTerms?.forEach((term) => (creditTypesDictionary[term.id] = term));
    return creditTypesDictionary;
  },
);

/*************
 * Navigation
 *************/
/**
 * @function navigationSelector
 * @param taxonomyState
 * @description - raw redux selector that returns the navigation object.
 * @returns ITaxonomyTerm
 */
export const navigationSelector = ({ taxonomyState }: AppState): ITaxonomyTerm =>
  taxonomyState.terms[TAXONOMY_NAVIGATION_ROOT_ID];

/**
 * @function navigationTermsSelector
 * @description - Returns all the navigation terms.
 * @returns ITaxonomyTerm[]
 */
export const navigationTermsSelector = createSelector(navigationSelector, (nav: ITaxonomyTerm) => nav?.terms || []);

/****************
 * Learner Types
 ****************/
/**
 * @function allLearnerTypeTermsSelector
 * @param taxonomyState
 * @description - raw redux selector of learner type terms.
 * @returns ITaxonomyTerm[]
 */
export const allLearnerTypeTermsSelector = ({ taxonomyState }: AppState): ITaxonomyTerm[] =>
  taxonomyState?.terms[TAXONOMY_LEARNER_TYPES_ROOT_ID]?.terms;

export const rootNavTermSelector = ({ taxonomyState }: AppState): ITaxonomyTerm =>
  taxonomyState?.terms[TAXONOMY_NAVIGATION_ROOT_ID];

/****************
 * REMS
 ****************/
/**
 * @function allRemsTermsSelector
 * @param taxonomyState
 * @description - raw redux selector of REMS terms.
 * @returns ITaxonomyTerm[]
 */
export const allRemsTermsSelector = ({ taxonomyState }: AppState): ITaxonomyTerm[] =>
  taxonomyState?.terms[TAXONOMY_REMS_ROOT_ID]?.terms;

/****************
 * Participation Fees
 ****************/
/**
 * @function allParticipationFeesSelector
 * @param taxonomyState
 * @description - raw redux selector of Participation Fee terms.
 * @returns ITaxonomyTerm[]
 */
export const allParticipationFeesSelector = ({ taxonomyState }: AppState): ITaxonomyTerm[] =>
  taxonomyState?.terms[TAXONOMY_PARTICIPATION_FEE_ROOT_ID]?.terms;

/****************
 * Commercial Support Sources
 ****************/
/**
 * @function allCommercialSupportSourcesSelector
 * @param taxonomyState
 * @description - raw redux selector of Commercial Support Sources terms.
 * @returns ITaxonomyTerm[]
 */
export const allCommercialSupportSourcesSelector = ({ taxonomyState }: AppState): ITaxonomyTerm[] =>
  taxonomyState?.terms[TAXONOMY_SUPPORT_SOURCES_ROOT_ID]?.terms;

/**
 * @function commercialSupportSourcesDictionarySelector
 * @description - IDictionary of Commercial Support Sources terms by id ex. { [id]: term }
 * @returns IIDictionary<ITaxonomyTerm>
 */
export const commercialSupportSourcesDictionarySelector = createSelector(
  allCommercialSupportSourcesSelector,
  (commercialSupportSourcesTerms: ITaxonomyTerm[]): IDictionary<ITaxonomyTerm> => {
    const commercialSupportSourcesDictionary: IDictionary<ITaxonomyTerm> = {};
    commercialSupportSourcesTerms?.forEach((term) => (commercialSupportSourcesDictionary[term.id] = term));
    return commercialSupportSourcesDictionary;
  },
);

/****************
 * Pharmacy Topics
 ****************/
/**
 * @function allPharmacyTopicsSelector
 * @param taxonomyState
 * @description - raw redux selector of Pharmacy Topics terms.
 * @returns ITaxonomyTerm[]
 */
export const allPharmacyTopicsSelector = ({ taxonomyState }: AppState): ITaxonomyTerm[] =>
  taxonomyState?.terms[TAXONOMY_ACTIVITY_PHARMACY_TOPIC_ID]?.terms;

/**
 * @function pharmacyTopicsDictionarySelector
 * @description - IDictionary of Pharmacy Topics terms by id ex. { [id]: term }
 * @returns IIDictionary<ITaxonomyTerm>
 */
export const pharmacyTopicsDictionarySelector = createSelector(
  allPharmacyTopicsSelector,
  (pharmacyTopicsTerms: ITaxonomyTerm[]): IDictionary<ITaxonomyTerm> => {
    const pharmacyTopicsDictionary: IDictionary<ITaxonomyTerm> = {};
    pharmacyTopicsTerms?.forEach((term) => (pharmacyTopicsDictionary[term.id] = term));
    return pharmacyTopicsDictionary;
  },
);

/**************************
 * Pharmacy Activity Types
 *************************/
/**
 * @function allPharmacyActivityTypesSelector
 * @param taxonomyState
 * @description - raw redux selector of Pharmacy Activity Types terms.
 * @returns ITaxonomyTerm[]
 */
export const allPharmacyActivityTypesSelector = ({ taxonomyState }: AppState): ITaxonomyTerm[] =>
  taxonomyState?.terms[TAXONOMY_ACTIVITY_PHARMACY_TYPE_ID]?.terms;

/**
 * @function pharmacyActivityTypesDictionarySelector
 * @description - IDictionary of Pharmacy Activity Types terms by id ex. { [id]: term }
 * @returns IIDictionary<ITaxonomyTerm>
 */
export const pharmacyActivityTypesDictionarySelector = createSelector(
  allPharmacyActivityTypesSelector,
  (pharmacyActivityTypesTerms: ITaxonomyTerm[]): IDictionary<ITaxonomyTerm> => {
    const pharmacyActivityTypesDictionary: IDictionary<ITaxonomyTerm> = {};
    pharmacyActivityTypesTerms?.forEach((term) => (pharmacyActivityTypesDictionary[term.id] = term));
    return pharmacyActivityTypesDictionary;
  },
);

/****************
 * Commendation Criterion
 ****************/
/**
 * @function allCommendationCriterionSelector
 * @param taxonomyState
 * @description - raw redux selector of Commercial Support Sources terms.
 * @returns ITaxonomyTerm[]
 */
export const allCommendationCriterionSelector = ({ taxonomyState }: AppState): ITaxonomyTerm[] =>
  taxonomyState?.terms[TAXONOMY_COMMENDATION_CRITERIA_ROOT_ID]?.terms;

/**
 * @function commendationCriterionChildrenDictionarySelector
 * @description - IDictionary of Commendation Criteria child terms by id ex. { [childId]: childTerm }
 * @returns IIDictionary<ITaxonomyTerm>
 */
export const commendationCriterionChildrenDictionarySelector = createSelector(
  allCommendationCriterionSelector,
  (commendationCriterionTerms: ITaxonomyTerm[]): IDictionary<ITaxonomyTerm> => {
    const commendationCriterionDictionary: IDictionary<ITaxonomyTerm> = {};
    commendationCriterionTerms?.forEach(({ terms = [] }: ITaxonomyTerm) => {
      terms?.forEach((childTerm) => (commendationCriterionDictionary[childTerm.id] = childTerm));
    });
    return commendationCriterionDictionary;
  },
);

/****************
 * Outcomes Measured
 ****************/
/**
 * @function allOutcomesMeasuredSelector
 * @param taxonomyState
 * @description - raw redux selector of Outcomes Measured terms.
 * @returns ITaxonomyTerm[]
 */
export const allOutcomesMeasuredSelector = ({ taxonomyState }: AppState): ITaxonomyTerm[] =>
  taxonomyState?.terms[TAXONOMY_OUTCOMES_MEASURED_ROOT_ID]?.terms;

/**
 * @function learnerKnowledgeMeasuredOutcomeSelector
 * @description - Returns the measured outcome that has the tag of `LEARNER_KNOWLEDGE`
 * @returns ITaxonomyTerm
 */
export const learnerKnowledgeMeasuredOutcomeSelector = createSelector(
  allOutcomesMeasuredSelector,
  (terms: ITaxonomyTerm[]) => terms?.find(({ tag }) => tag === TAGS_ENUM.MEASURED_OUTCOMES__LEARNER_KNOWLEDGE),
);

/**
 * @function outcomesMeasuredFormattedDictionarySelector
 * @description - formatted IDictionary of Outcomes Measured strings ex. `parentTerm - childTerm.
 * @returns IIDictionary<string>
 */
export const outcomesMeasuredFormattedDictionarySelector = createSelector(
  allOutcomesMeasuredSelector,
  (outcomesMeasuredTerms: ITaxonomyTerm[]): IDictionary<string> => {
    const outcomesMeasuredFormattedDictionary: IDictionary<string> = {};
    outcomesMeasuredTerms?.length &&
      outcomesMeasuredTerms?.forEach(({ name: parentName, terms }: ITaxonomyTerm): void => {
        terms?.length &&
          terms?.forEach(({ name: childName, id }: ITaxonomyTerm): void => {
            outcomesMeasuredFormattedDictionary[id] = `${parentName} - ${childName}`;
          });
      });
    return outcomesMeasuredFormattedDictionary;
  },
);

/**************
 * Medical School
 ***********/
/**
 * @function medicalSchoolTermsSelector
 * @param taxonomyState
 * @description - raw redux selector of Medical School terms.
 * @returns ITaxonomyTerm[]
 */
export const medicalSchoolTermsSelector = ({ taxonomyState }: AppState): ITaxonomyTerm[] =>
  taxonomyState?.terms[TAXONOMY_MEDICAL_SCHOOLS_ROOT_ID]?.terms;

/**************
 * Footer
 ***********/
/**
 * @function footerTermsSelector
 * @param taxonomyState
 * @description - raw redux selector of Footer terms.
 * @returns ITaxonomyTerm[]
 */
export const footerTermsSelector = ({ taxonomyState }: AppState): ITaxonomyTerm[] =>
  taxonomyState?.terms[TAXONOMY_FOOTER_ID]?.terms;

/**
 * @function getFooterTermsSelector
 * @description - gets all footer terms based on rollupOrganizationEnum.
 * @returns ITaxonomyTerm[]
 */
export const getFooterTermsSelector = createSelector(
  organizationKindSelector,
  footerTermsSelector,
  (organization: IUserOrganization, terms: ITaxonomyTerm[]) => filterTaxonomyByOrg({ organization, terms }),
);

export const activityReviewTypesSelector = ({ taxonomyState }: AppState): ITaxonomyTerm[] =>
  taxonomyState?.terms[TAXONOMY_ACTIVITY_REVIEW_TYPES_ID]?.terms;

export const activityReviewOutcomesSelector = ({ taxonomyState }: AppState): ITaxonomyTerm[] =>
  taxonomyState?.terms[TAXONOMY_ACTIVITY_REVIEW_OUTCOMES_ID]?.terms;

export const faqTermsSelector = ({ taxonomyState }: AppState): ITaxonomyTerm[] =>
  taxonomyState?.terms[TAXONOMY_FAQ_ROOT_ID]?.terms;

export const helpDeskTermsSelector = ({ taxonomyState }: AppState): ITaxonomyTerm[] =>
  taxonomyState?.terms[TAXONOMY_HELP_DESK_ID]?.terms;

export const taxonomyBatchSelector = ({ taxonomyState }: AppState): ITaxonomyTerm =>
  taxonomyState.terms[TAXONOMY_BATCH_ROOT_ID];

export const taxonomyBatchMessagesSelector = ({ taxonomyState }: AppState): ITaxonomyTerm =>
  taxonomyState.terms[TAXONOMY_BATCH_MESSAGES_ID];

export const taxonomyTabDelimSelector = ({ taxonomyState }: AppState): ITaxonomyTerm =>
  taxonomyState.terms[TAXONOMY_TAB_DELIM_SUPPORT_ID];

export const taxonomyBatchHelpSelector = ({ taxonomyState }: AppState): ITaxonomyTerm =>
  taxonomyState?.terms[TAXONOMY_BATCH_HELP_ROOT_ID];
