import { createSelector } from 'reselect';

import { allParticipationFeesSelector, allRemsTermsSelector } from 'store/taxonomy/selectors';
import { AppState } from '..';

// Types
import {
  Activity,
  ActivitySearchResultActivity,
  ActivityType,
  Facet,
  IActivityReviewHistory,
  IActivitySearchRequest,
  IActivitySearchResponse,
  IActivitySearchStateProps,
  IDictionary,
  ITaxonomyTerm,
  RollupOrganizationEnums,
} from 'core/models';
import { StatusEnum } from 'core/enums';
import { ACTIVITY_TYPE_ABBREVIATION_ENUM } from 'core/constants';
import { ActivityState } from './types';

/**
 * @function activitySelector
 * @param activitiesState
 * @description - Overall activity state object
 * @returns activitiesState
 */
export const activitySelector = ({ activitiesState }: AppState): ActivityState => activitiesState;
/**
 * @function activityEditIndexSelector
 * @param activitiesState
 * @description - selected template index to edit the current activity
 * @returns number
 */
export const activityEditIndexSelector = ({ activitiesState }: AppState): number => activitiesState?.editIndex;

/****************
 * Activity Detail
 ****************/
/**
 * @function activityDetailSelector
 * @param activitiesState
 * @description - raw redux selector of Activity Detail.
 * @returns Activity
 */
export const activityDetailSelector = ({ activitiesState }: AppState): Activity => activitiesState?.activityDetail;

export const activitiesSearchingSelector = createSelector(
  activitySelector,
  (state: ActivityState): boolean => state?.isSearchingActivities,
);

export const activitySearchingSelector = createSelector(
  activitySelector,
  (state: ActivityState): boolean => state?.isSearchingActivity,
);

/**
 * @function supportedRemsIdsSelector
 * @description - list of Support Rems Ids for the Activity Detail
 * @returns string[]
 */
export const supportedRemsIdsSelector = createSelector(
  activityDetailSelector,
  (activityDetail: Activity): string[] => activityDetail?.supportedRemsIds || [],
);

/**
 * @function supportedRemsIdsSelector
 * @description - list of Support Rems Ids for the Activity Detail
 * @returns any
 */
export const activityDetailStatusSelector = createSelector(
  activityDetailSelector,
  (activityDetail: Activity): StatusEnum => activityDetail?.status,
);

export const activityDetailRollupOrganizationEnumSelector = createSelector(
  activityDetailSelector,
  (activityDetail: Activity): RollupOrganizationEnums | undefined => activityDetail?.rollupOrganizationEnum,
);

export const hasIndividualLearnersSelector = createSelector(
  activityDetailSelector,
  (activityDetail: Activity): boolean => activityDetail.hasIndividualLearners,
);

/**
 * @function getSupportedRemsIdSelector
 * @description - finds the matching REMS Term in Taxonomy
 * @returns ITaxonomyTerm
 */
export const getSupportedRemsIdSelector = createSelector(
  [supportedRemsIdsSelector, allRemsTermsSelector],
  (supportedRemsIds: string[] = [], remsTerms: ITaxonomyTerm[] = []): ITaxonomyTerm =>
    remsTerms?.find((remsTerm) => !!supportedRemsIds?.find((remsId) => remsTerm.id === remsId)),
);

/**
 * @function participationFeeTypeIdSelector
 * @description - termId of the Participation Fee for the Activity Detail
 * @returns string
 */
export const participationFeeTypeIdSelector = createSelector(
  activityDetailSelector,
  (activityDetail: Activity): string => activityDetail?.participationFeeTypeId || '',
);

/**
 * @function getParticipationFeeNameSelector
 * @description - finds the matching Participation Fee Term's name in Taxonomy
 * @returns string
 */
export const getParticipationFeeNameSelector = createSelector(
  [participationFeeTypeIdSelector, allParticipationFeesSelector],
  (participationFeeTypeId = '', participationFeeTerms: ITaxonomyTerm[] = []): string =>
    participationFeeTerms?.find((feeTerm) => feeTerm.id === participationFeeTypeId)?.name || '',
);

export const activityTypesSelector = ({ activitiesState }: AppState): ActivityType[] => activitiesState?.activityTypes;

/**
 * @function rssSelector
 * @param taxonomyState
 * @description - gets the rss
 */
export const rssSelector = createSelector(activityTypesSelector, (activityTypes) =>
  activityTypes.find((activityType: ActivityType) => activityType.abbreviation === ACTIVITY_TYPE_ABBREVIATION_ENUM.RSS),
);

/**
 * @function liveCourseSelector
 * @param taxonomyState
 * @description - gets the liveCourseSelector
 */
export const liveCourseSelector = createSelector(activityTypesSelector, (activityTypes) =>
  activityTypes.find((activityType: ActivityType) => activityType.abbreviation === ACTIVITY_TYPE_ABBREVIATION_ENUM.C),
);

/**
 * @function activityTypeLearnerReportGroupingsSelector
 * @param activityTypesSelector
 * @returns 2D Array of type groupings for distinguishing which types can be interchanged after learners are reported
 */
export const activityTypeLearnerReportGroupingsSelector = createSelector(
  activityTypesSelector,
  (activityTypes): ActivityType[][] => {
    const typeCheckGroupOne = [
      ACTIVITY_TYPE_ABBREVIATION_ENUM.C,
      ACTIVITY_TYPE_ABBREVIATION_ENUM.RSS,
      ACTIVITY_TYPE_ABBREVIATION_ENUM.EM,
      ACTIVITY_TYPE_ABBREVIATION_ENUM.PI,
    ];
    const typeCheckGroupTwo = [
      ACTIVITY_TYPE_ABBREVIATION_ENUM.O,
      ACTIVITY_TYPE_ABBREVIATION_ENUM.LFT,
      ACTIVITY_TYPE_ABBREVIATION_ENUM.TIW,
      ACTIVITY_TYPE_ABBREVIATION_ENUM.MR,
      ACTIVITY_TYPE_ABBREVIATION_ENUM.CML,
      ACTIVITY_TYPE_ABBREVIATION_ENUM.JN,
      ACTIVITY_TYPE_ABBREVIATION_ENUM.ISL,
    ];

    const typeGroupOne = typeCheckGroupOne
      ?.map((typeAbbr) => activityTypes?.find((type) => type.abbreviation === typeAbbr))
      .filter((val) => val);
    const typeGroupTwo = typeCheckGroupTwo
      ?.map((typeAbbr) => activityTypes?.find((type) => type.abbreviation === typeAbbr))
      .filter((val) => val);

    return [typeGroupOne, typeGroupTwo];
  },
);

/**
 * @function activityTypesWithCreditLimitSelector
 * @param activityTypesSelector
 * @returns Array of type ids which limit max credits to CME (AMA) credit value
 */
export const activityTypesWithCreditLimitSelector = createSelector(
  activityTypesSelector,
  (activityTypes: ActivityType[]): string[] => {
    const typesWithMaxCredits: ACTIVITY_TYPE_ABBREVIATION_ENUM[] = [
      ACTIVITY_TYPE_ABBREVIATION_ENUM.C,
      ACTIVITY_TYPE_ABBREVIATION_ENUM.RSS,
      ACTIVITY_TYPE_ABBREVIATION_ENUM.EM,
      ACTIVITY_TYPE_ABBREVIATION_ENUM.PI,
    ];
    return activityTypes
      ?.filter(({ abbreviation }: ActivityType): boolean => typesWithMaxCredits.includes(abbreviation))
      ?.map(({ id }: ActivityType): string => id);
  },
);

/**
 * @function activityTypesWithLearnerCreditValidationSelector
 * @param activityTypesSelector
 * @returns Array of type ids which limit learners to the total credits on the activity
 * @description BUSINESS RULE 29
 *
 * Individualized Learners Added: The number of credits can be adjusted, but cannot be lower than the amount of credit reported for learners.
 * If the credit needs to be adjusted to a greater amount, there are no restrictions. If the credit needs to be lower, PARS should check the
 * records for the activity. EX: An activity has been registered for 20 credits but needs to be lowered to 15. If a learner was reported for
 * a sum of 19 points across all submissions in that activity, PARS should not allow this until the learner has been adjusted/deleted. If the
 * highest amount of points awarded was 14, the system would allow the change. These rules should be in effect for the following activity
 * types: Course, Regularly Scheduled Series, Enduring Material, Performance Improvement
 *
 * For certain activity types which allow credit in excess of the total credit available this can be ignored: Internet Searching and Learning,
 * Learning from Teaching, Test Item Writing, Manuscript Review, Committee Learning, Journal-based CE, Other"
 */
export const activityTypesWithLearnerCreditValidationSelector = createSelector(
  activityTypesSelector,
  (activityTypes: ActivityType[]): string[] => {
    const typesWithMaxCredits: ACTIVITY_TYPE_ABBREVIATION_ENUM[] = [
      ACTIVITY_TYPE_ABBREVIATION_ENUM.C,
      ACTIVITY_TYPE_ABBREVIATION_ENUM.RSS,
      ACTIVITY_TYPE_ABBREVIATION_ENUM.EM,
      ACTIVITY_TYPE_ABBREVIATION_ENUM.PI,
    ];
    return activityTypes
      ?.filter(({ abbreviation }: ActivityType): boolean => typesWithMaxCredits.includes(abbreviation))
      ?.map(({ id }: ActivityType): string => id);
  },
);

/**
 * @function learnerCountsSelector
 * @param activitiesState
 */
export const learnerCountsSelector = ({ activitiesState }: AppState): IDictionary<number> =>
  activitiesState?.activityDetail?.learnerCounts;

/**
 * @function isDeletedSelector
 * @param activitiesState
 */
export const isDeletedSelector = ({ activitiesState }: AppState): boolean =>
  activitiesState?.activityDetail?.isDeleted || false;

/**
 * @function isDeletingSelector
 * @param activitiesState
 */
export const isDeletingSelector = ({ activitiesState }: AppState): boolean => activitiesState?.isDeleting || false;

/**
 * @function searchRequestSelector
 * @param activitiesState
 */
export const searchRequestSelector = ({ activitiesState }: AppState): IActivitySearchRequest =>
  activitiesState?.searchRequest;

export const isDeletedActivitiesShowingSelector = createSelector(
  searchRequestSelector,
  ({ isDeleted }): boolean => isDeleted,
);

/**
 * @function searchResponseSelector
 * @param activitiesState
 */
export const searchResponseSelector = ({ activitiesState }: AppState): IActivitySearchResponse =>
  activitiesState?.searchResponse;

/**
 * @function searchTotalCountSelector
 * @param activitiesState
 */
export const searchTotalCountSelector = createSelector(
  searchResponseSelector,
  (searchResponse: IActivitySearchResponse) => searchResponse?.totalCount,
);

/**
 * @function activitySearchFacetsSelector
 * @param activitiesState
 */
export const activitySearchFacetsSelector = createSelector(
  searchResponseSelector,
  (searchResponse: IActivitySearchResponse) => searchResponse?.facets,
);

export const activitySearchJointProviderFacetsSelector = createSelector(
  activitySearchFacetsSelector,
  (facet: Facet) => facet?.['JointProviders/Name'],
);

export const activitySearchStateFacetsSelector = createSelector(
  activitySearchFacetsSelector,
  (facet: Facet) => facet?.State,
);

export const activityCountryFacetsSelector = createSelector(
  activitySearchFacetsSelector,
  (facet: Facet) => facet?.Country,
);

/**
 * @function searchStatePropsSelector
 * @param activitiesState
 */
export const searchStatePropsSelector = ({ activitiesState }: AppState): IActivitySearchStateProps =>
  activitiesState?.searchStateProps;

/**
 * @function selectedActivitiesSelector
 * @returns A list of the selected activities
 */
export const selectedActivitiesSelector = createSelector(
  searchStatePropsSelector,
  (activity: IActivitySearchStateProps): ActivitySearchResultActivity[] => activity.selectedActivities || [],
);

/**
 * @function activitySearchCurrentPageSelector
 * @param activityState
 * @returns Current page of pagination
 */
export const activitySearchCurrentPageSelector = createSelector(
  searchStatePropsSelector,
  (state: IActivitySearchStateProps) => state.page,
);

/********************
 * Current Activity
 ********************/
/**
 * @function currentActivitiesSelector
 * @param activitiesState
 */
export const currentActivitiesSelector = ({ activitiesState }: AppState): Activity[] =>
  activitiesState?.currentActivities;

export const currentActivityIdSelector = createSelector(
  currentActivitiesSelector,
  (currentActivities: Activity[] | undefined) => currentActivities[0]?.printableId,
);

/**
 * @function selectedActivitiesIdsSelector
 * @returns A list of the selected activity ids (named as key)
 */
export const selectedActivitiesIdsSelector = createSelector(
  selectedActivitiesSelector,
  (activities: ActivitySearchResultActivity[]): string[] =>
    activities.map(({ key }: ActivitySearchResultActivity) => key),
);

/**
 * @function uploadPercentageSelector
 * @param activitiesState
 */
export const uploadPercentageSelector = ({ activitiesState }: AppState): number =>
  activitiesState?.progressPercentage || 0;

export const isActivityCreatingSelector = createSelector(activitySelector, ({ isCreating }): boolean => isCreating);

/**
 * @function activityErrorSelector
 * @param activitiesState
 */
export const activityErrorSelector = ({ activitiesState }: AppState): string => activitiesState?.error;

/**
 * @function activityFileSelector
 * @param activitiesState
 */
export const activityFileSelector = ({ activitiesState }: AppState): { file: File } => activitiesState?.file;

/**
 * @function activityReviewHistorySelector
 * @param activitiesState
 */
export const activityReviewHistorySelector = ({
  activitiesState,
}: AppState): {
  isLoadingReviewHistory: boolean;
  reviewHistory: IActivityReviewHistory[];
} => ({
  isLoadingReviewHistory: activitiesState.isLoadingReviewHistory,
  reviewHistory: activitiesState?.reviewHistory,
});
