import { ForwardedRef, forwardRef, ReactElement, useEffect, useImperativeHandle } from 'react';
import { useFormikContext } from 'formik';
import { RangeStatic } from 'quill';
import { useQuill } from 'react-quilljs';
import get from 'lodash/get';

// Styles
import 'quill/dist/quill.snow.css';
import styles from './RichTextEditor.module.scss';

// Constants
import { MODULES } from './constants';

// Types
import { IActivityRegistrationDetails } from '../initialActivityRegistrationDetailsForm';
interface IProps {
  formikKey: string;
}

export const RichTextEditor = forwardRef(
  (props: IProps, ref: ForwardedRef<void>): ReactElement => {
    const { formikKey } = props;
    const { setFieldValue, values } = useFormikContext<IActivityRegistrationDetails>();
    const { quill, quillRef } = useQuill({ modules: MODULES });
    const defaultHTML: string = get(values, formikKey, null);

    // This allows the parent to call blur when needed. As of this version of React Quill, blur events don't take place
    // when clicking a button directly after the quill-editor.
    useImperativeHandle(ref, () => ({
      blur: (): void => setFieldValue(formikKey, quill.root.innerHTML),
    }));

    useEffect(() => {
      // Load the default attestationText.
      // Quill will try to focus to the text area when there is content in it on load.
      if (defaultHTML) {
        quill?.clipboard?.dangerouslyPasteHTML(defaultHTML);
        quill?.blur();
      }
    }, [defaultHTML, quill]);

    // Quill will try to focus to the text area when there is content in it on load; manually scroll to top when loading.
    useEffect(() => {
      window.scroll(0, 0);
    }, [quill]);

    useEffect(() => {
      // Only save back to the formik model once we blur the text area.
      // This helps performance tremendously and avoid unnecessary re-renders.
      quill?.on('selection-change', (range: RangeStatic, oldRange: RangeStatic): void => {
        if (range === null && oldRange !== null) {
          setFieldValue(formikKey, quill.root.innerHTML);
        }
      });
    }, [formikKey, quill, setFieldValue]);

    return (
      <div className={styles.editor}>
        <div ref={quillRef} />
      </div>
    );
  },
);
