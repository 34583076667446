import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { AddRounded, RemoveRounded } from '@material-ui/icons';

// Components
import { BooleanCheckboxes } from './BooleanCheckboxes';
import { Checkboxes } from './Checkboxes';
import { Date } from './Date';
import { Range } from './Range';
import { TextInput } from './TextInput';
import { TypeaheadFacet } from './Typeahead';

// Store + Core
import { Facet } from 'core/models';
import { IFacetAccordion } from 'core/constants';
import { FacetTypeEnum } from 'core/enums';
import { organizationKindSelector, rollupOrganizationEnumSelector } from 'store/user/selectors';

// Types
import { SearchRequest } from '../../types';
interface IProps {
  defaultFacets: IFacetAccordion[];
  facets: Facet;
  searchRequest: SearchRequest;
  updateSearch?(): void;
}

const useStyles = makeStyles(() =>
  createStyles({
    accordion: {
      '&:before': {
        display: 'none',
      },
      background: 'transparent',
      borderRadius: '0 !important',
      boxShadow: 'none',
      padding: 0,
    },
    details: {
      flexDirection: 'column',
    },
    summary: {
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      padding: 0,
    },
  }),
);

export const FacetsAccordion = (props: IProps): ReactElement => {
  const { defaultFacets, facets, searchRequest } = props;
  const [expanded, setExpanded] = useState<string[]>([]);

  // Selectors.
  const { organizationKind } = useSelector(organizationKindSelector);
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);

  // MUI Styles.
  const classes = useStyles();

  return (
    <>
      {defaultFacets.map(
        (facet: IFacetAccordion, idx: number): ReactElement => {
          const { displayValue, responseValue, type, organizationKinds, rollupOrganizationEnums } = facet;

          const isExpanded = !!(
            expanded.find((_: string): boolean => _ === displayValue) && facets?.[responseValue]?.length !== 0
          );
          const onToggleExpand = (): void =>
            !isExpanded
              ? setExpanded([...expanded, displayValue])
              : setExpanded(expanded.filter((_: string): boolean => _ !== displayValue));

          const isDisabled = !facets?.[responseValue]?.length;
          const expandIcon: ReactElement = expanded.find((_: string): boolean => _ === displayValue) ? (
            <RemoveRounded />
          ) : (
            <AddRounded />
          );

          const commonProps = {
            classes,
            expandIcon,
            facet,
            facets,
            isDisabled,
            isExpanded,
            onToggleExpand,
            searchRequest,
          };

          const componentMap = {
            [FacetTypeEnum.Array]: <Checkboxes key={idx} {...commonProps} />,
            [FacetTypeEnum.Boolean]: <BooleanCheckboxes key={idx} {...commonProps} />,
            [FacetTypeEnum.Date]: <Date key={idx} {...commonProps} isDisabled={!facets} />,
            [FacetTypeEnum.Text]: <TextInput key={idx} {...commonProps} isDisabled={!facets} />,
            [FacetTypeEnum.Range]: <Range key={idx} {...commonProps} isDisabled={!facets} />,
            [FacetTypeEnum.Typeahead]: <TypeaheadFacet key={idx} {...commonProps} isDisabled={!facets} />,
          };

          const shouldDisplay: boolean =
            organizationKinds.includes(organizationKind) &&
            (!rollupOrganizationEnums ||
              rollupOrganizationEnums.length === 0 ||
              rollupOrganizationEnums.includes(rollupOrganizationEnum));

          if (shouldDisplay) {
            return componentMap?.[type] || null;
          }
          return null;
        },
      )}
    </>
  );
};
