const URL_REGEX = /^https?:\/\//;

interface ICustomUrl {
  pathname: string | null;
}
export type IValidateUrl = ICustomUrl | string;

/**
 * @function validateUrl
 * @param {string} textVal
 * @return {IValidateUrl}
 * @description - Returns a modified url string. If the textVal passed is the same as the current route we are on,
 *                we want to strip off the domain and return the pathname so that the SPA can handle the routing.
 *                Otherwise, we will return the full textVal so that we can render an external link.
 */
export const validateUrl = (textVal: string): IValidateUrl => {
  // Check to see if we have a valid URL
  if (!textVal.match(URL_REGEX)) return { pathname: '/' };

  // Verify the current location.
  const currentLocation = document.createElement('a');
  currentLocation.href = window?.location?.href;

  // Verify the passed location.
  const passedLocation = document.createElement('a');
  passedLocation.href = textVal;

  // If the current domain location is not the same as what is passed, we should return the passed text value.
  if (currentLocation.hostname !== passedLocation.hostname) return textVal;

  // Otherwise we return the SPA path.
  return {
    pathname: passedLocation.pathname[0] !== '/' ? `/${passedLocation.pathname}` : passedLocation.pathname,
  };
};
