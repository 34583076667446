import { ReactElement } from 'react';
import { Pagination } from '@material-ui/lab';

// Components
import { HighlightBox } from 'components/HighlightBox';

// Constants
import { AZURE_SEARCH_MAX } from 'core/constants';

// Types
interface IProps {
  currentPage: number;
  goToPage(_, newPage: number): void;
  paginationCount: number;
  totalCount: number;
}
export const SearchPagination = (props: IProps): ReactElement => {
  const { currentPage, goToPage, paginationCount, totalCount } = props;

  // We don't need to render anything if there are no pages.
  if (paginationCount <= 1) return null;

  return (
    <>
      <Pagination siblingCount={0} count={paginationCount} page={currentPage} onChange={goToPage} />

      {/* If the pagination count exceeds the azure max let the user know. */}
      {totalCount > AZURE_SEARCH_MAX && (
        <HighlightBox variant="info">
          <p>
            We have limited your {totalCount.toLocaleString('en-US')} results to{' '}
            {paginationCount.toLocaleString('en-US')} pages. You can export your search query to see up to{' '}
            {AZURE_SEARCH_MAX.toLocaleString('en-US')} results.
          </p>
        </HighlightBox>
      )}
    </>
  );
};
