import moment from 'moment';
import { ILearnerIdMatchField, ILearnerMatchResponse, IMatchedLearner, LearnerIdMatchSource } from 'core/models';

const stateMatchSources: LearnerIdMatchSource[] = [
  'DiplomateState',
  'FSMB',
  'StateBoardCompletion',
  'StateBoardProfile',
];
const boardMatchSources: LearnerIdMatchSource[] = [
  'DiplomateBoard',
  'CertifyingBoardCompletion',
  'CertifyingBoardProfile',
];

/**
 * @function transformMatchedLearnerResponse
 * @description Transforms the match response data into a manageable shape
 * @param matchedLearners ILearnerMatchResponse[]
 * @returns IMatchedLearner[]
 */
export const transformMatchedLearnerResponse = (matchedLearners: ILearnerMatchResponse[]): IMatchedLearner[] => {
  return matchedLearners.map(
    ({
      dobDates,
      dobMonths,
      firstNames,
      key,
      lastNames,
      learnerIds,
      medicalSchools,
      npis,
    }: ILearnerMatchResponse): IMatchedLearner => {
      const firstName = firstNames?.find(({ name }) => !!name)?.name as string;
      const lastName = lastNames?.find(({ name }) => !!name)?.name as string;
      const dobMonth = dobMonths?.find(({ name }) => !!name)?.name as number;
      const dobDay = dobDates?.find(({ name }) => !!name)?.name as number;
      const dateOfBirth = dobMonth >= 1 && dobDay >= 1 && moment({ day: dobDay, month: dobMonth - 1 }).toDate();
      const ulid = key;
      const medicalSchoolName = medicalSchools?.find(({ name }) => !!name)?.name as string;
      const npi = npis?.find(({ name }) => !!name)?.name as string;
      const learnerStateBoards: ILearnerIdMatchField[] = learnerIds?.filter(
        (learnerIdRecord: ILearnerIdMatchField): boolean =>
          stateMatchSources.includes(learnerIdRecord.source) &&
          !!learnerIdRecord.abbreviation &&
          !!learnerIdRecord.learnerId,
      );

      const learnerMocBoards: ILearnerIdMatchField[] = learnerIds?.filter(
        (learnerIdRecord: ILearnerIdMatchField): boolean =>
          boardMatchSources.includes(learnerIdRecord.source) &&
          !!learnerIdRecord.abbreviation &&
          !!learnerIdRecord.boardId,
      );
      return {
        dateOfBirth,
        firstName,
        lastName,
        learnerMocBoards,
        learnerStateBoards,
        medicalSchoolName,
        npi,
        ulid,
      };
    },
  );
};
