import { handleServerError } from 'globals/utils/handleServerError';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { popToast } from 'store/toast/actions';
import { errorToastOptions } from 'store/toast/constants';

// simple async loader - not as powerful as SWR (ie. no cache), but is way less code to use that wiring up a new page to Redux
// This should only be used for simple pages where Redux doesn't provide value (ie. admin status pages)
// ** MAKE SURE `getter` IS STABLE ** - ie. use `useCallback` around it if you have to - the data will reload if it's identity changes
export const useLoadData = <TData extends unknown>(
  name: string,
  getter: () => Promise<TData>,
): { data?: TData; error?: string; isLoading: boolean; refresh: () => void } => {
  const [refreshKey, setRefreshKey] = useState(0);
  const refresh = useCallback(() => setRefreshKey((i) => i + 1), []);

  const [data, setData] = useState<TData | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const result = await getter();
        setError(undefined);
        setData(result);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        const { errorMessage } = handleServerError({ error, thunkName: name });

        // Tell the user there was an error.
        await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));

        setData(undefined);
        setError(errorMessage);
        setIsLoading(false);
      }
    })();
  }, [name, getter, dispatch, refreshKey]);

  return { data, error, isLoading, refresh };
};
