import { IProgramSummary, PARSAction } from 'core/models';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ProgramSummaryService } from 'services/ProgramSummaryService';
import { AppState } from 'store';
import { popToast } from 'store/toast/actions';
import { errorToastOptions, successToastOptions } from 'store/toast/constants';
import {
  GET_PROGRAM_SUMMARY,
  GET_PROGRAM_SUMMARY_FAILURE,
  GET_PROGRAM_SUMMARY_SUCCESS,
  REENABLE_PROGRAM_SUMMARY,
  REENABLE_PROGRAM_SUMMARY_FAILURE,
  REENABLE_PROGRAM_SUMMARY_SUCCESS,
  SAVE_PROGRAM_SUMMARY,
  SAVE_PROGRAM_SUMMARY_FAILURE,
  SAVE_PROGRAM_SUMMARY_SUCCESS,
} from './types';
import { handleServerError } from '../../globals/utils/handleServerError';

export const getProgramSummaryAction = (): AnyAction => ({
  type: GET_PROGRAM_SUMMARY,
});

export const getProgramSummarySuccessAction = (payload: IProgramSummary): AnyAction => ({
  payload,
  type: GET_PROGRAM_SUMMARY_SUCCESS,
});

export const getProgramSummaryFailureAction = (payload: string): AnyAction => ({
  payload,
  type: GET_PROGRAM_SUMMARY_FAILURE,
});

export const saveProgramSummaryAction = (payload: IProgramSummary): AnyAction => ({
  payload,
  type: SAVE_PROGRAM_SUMMARY,
});

export const saveProgramSummarySuccessAction = (): AnyAction => ({
  type: SAVE_PROGRAM_SUMMARY_SUCCESS,
});

export const saveProgramSummaryFailureAction = (payload: string): AnyAction => ({
  payload,
  type: SAVE_PROGRAM_SUMMARY_FAILURE,
});

export const reEnableProgramSummaryAction = (): AnyAction => ({
  type: REENABLE_PROGRAM_SUMMARY,
});

export const reEnableProgramSummarySuccessAction = (): AnyAction => ({
  type: REENABLE_PROGRAM_SUMMARY_SUCCESS,
});

export const reEnableProgramSummaryFailureAction = (): AnyAction => ({
  type: REENABLE_PROGRAM_SUMMARY_FAILURE,
});

export const getProgramSummary = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(getProgramSummaryAction());
  try {
    const response = await ProgramSummaryService.get();
    await dispatch(getProgramSummarySuccessAction(response));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getProgramSummary' });
    await dispatch(getProgramSummaryFailureAction(errorMessage));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const saveProgramSummary = (
  payload: IProgramSummary,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  const confirmationMethod = payload.isDraft ? 'Summary draft saved' : 'Summary submitted';

  await dispatch(saveProgramSummaryAction(payload));
  try {
    await ProgramSummaryService.post(payload);
    await dispatch(saveProgramSummarySuccessAction());
    await dispatch(popToast({ ...successToastOptions, message: <>{confirmationMethod}</> }));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'saveProgramSummary' });
    await dispatch(saveProgramSummaryFailureAction(errorMessage));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

/**
 * Re-Enable the Program Summary.
 */
export const reEnableProgramSummary = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
) => {
  dispatch(reEnableProgramSummaryAction());

  try {
    await ProgramSummaryService.reEnableProgramSummary();
    dispatch(reEnableProgramSummarySuccessAction());
    dispatch(popToast({ ...successToastOptions, message: <>Program Summary has been re-enabled.</> }));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'reEnableProgramSummary' });
    dispatch(reEnableProgramSummaryFailureAction());
    dispatch(
      popToast({
        ...errorToastOptions,
        message: <>Unable to re-enable Program Summary, please contact IT. ({errorMessage})</>,
      }),
    );
  }
};
