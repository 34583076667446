import { PropsWithChildren, ReactElement } from 'react';
import { Box, Container, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    height: '50vh',
  },
});

// Types
type IProps = PropsWithChildren<{
  headingText?: string;
}>;

export const NoResultsCard = (props: IProps): ReactElement => {
  const { children, headingText } = props;
  const classes = useStyles();

  return (
    <Container>
      <Box m={3} mt={5}>
        <Paper classes={classes}>
          <Box
            alignItems="center"
            css={{ height: '100%' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            {headingText && <h2>{headingText}</h2>}
            {children || <Typography>No Results</Typography>}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};
