import { IProgramSummary } from 'core/models';
import { ProgramSummaryApiService } from 'core/programSummaryApiService';

export class ProgramSummaryService {
  private static programSummaryApiService: ProgramSummaryApiService = new ProgramSummaryApiService();

  public static async get(): Promise<IProgramSummary> {
    return await this.programSummaryApiService.get();
  }

  public static async post(payload: IProgramSummary): Promise<void> {
    return await this.programSummaryApiService.post(payload);
  }

  public static async reEnableProgramSummary(): Promise<void> {
    return await this.programSummaryApiService.reEnableProgramSummary();
  }
}
