import { IDisabledContext } from 'core/models';
import { Context, createContext } from 'react';

/**
 * For use via forms to share which fields to disable
 * 1. Construct an object of similar shape to your form model
 * 2. For each field name, supply an IDisabledState object
 * 3. Consume the context and use lodash.get(context, fieldName) to find the appropriate disabled state
 */
export const DisabledContext: Context<IDisabledContext> = createContext<IDisabledContext>(null);
