import { orderBy } from 'lodash';

import { IBoardSlim } from 'core/models';
import { BoardIntegrationTypes, BoardTypes } from 'layouts/pages/bct/types';

import { useBoardList } from './useBoardList';

// convert the BoardSlim list of boards to a simple id/name pair of MOC C-type boards used by the admin commands
export const useAdminCommandMocCTypeBoardList = (): { id: string; name: string }[] => {
  const boards: IBoardSlim[] = useBoardList();

  return orderBy(boards || [], 'abbreviation')
    .filter((i) => i.type === BoardTypes.CERTIFYING_BOARD && i.boardIntegrationType === BoardIntegrationTypes.C)
    .map((i) => ({ id: i.abbreviation, name: i.abbreviation }));
};
