import { ReactElement } from 'react';

// Components.
import { Chart } from 'components/Chart';
import { DashboardSection } from '../DashboardSection';

// Types.
import { IDashboardChartSection } from 'store/dashboard/types';
import Highcharts from 'highcharts';

// 5513_update_financial format
const currencyDataLabelFormatter = function () {
  const formattedValue = '$' + Highcharts.numberFormat(this.y, 0, '.', ',');
  return formattedValue;
};

const currencyTooltipFormatter = function () {
  const formattedValue =
    '<b>' + this.x + '</b><br/>' + this.series.name + ': $' + Highcharts.numberFormat(this.y, 0, '.', ',') + '<br/>';
  return formattedValue;
};

export const DashboardChartSection = (props: IDashboardChartSection): ReactElement => {
  const { chartData, label, toggleable } = props;

  if (label === 'Financial Data') {
    chartData.plotOptions = {
      column: {
        ...chartData.plotOptions?.column,
        dataLabels: {
          enabled: true,
          formatter: currencyDataLabelFormatter,
        },
      },
    };
    chartData.tooltip = {
      ...chartData.tooltip,
      formatter: currencyTooltipFormatter,
    };
  }

  return (
    <DashboardSection label={label} toggleable={toggleable}>
      <Chart options={chartData} />
    </DashboardSection>
  );
};
