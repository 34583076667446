import { PropsWithChildren, ReactElement, useEffect } from 'react';

// Components
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { Toast } from 'components/Toast';

interface IProps {
  hideSubnav?: boolean;
}

export const AuthenticatedLayout = (props: PropsWithChildren<IProps>): ReactElement => {
  const { children, hideSubnav } = props;

  useEffect(() => {
    // Scroll to top when the route has changed.
    window.scroll(0, 0);
  });

  return (
    <>
      <Header hideSubnav={hideSubnav} />
      <main className="main_layout">{children}</main>
      <Toast />
      <Footer />
    </>
  );
};
