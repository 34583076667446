import { PARSAction, IProviderFile, ILeaernerValidationProviderFile } from 'core/models';

// Action Types
export const GET_ACTIVITY_PROVIDER_FILES = 'GET_ACTIVITY_PROVIDER_FILES';
export const GET_ACTIVITY_PROVIDER_FILES_FAILURE = 'GET_ACTIVITY_PROVIDER_FILES_FAILURE';
export const GET_ACTIVITY_PROVIDER_FILES_LOADING = 'GET_ACTIVITY_PROVIDER_FILES_LOADING';
export const GET_ACTIVITY_PROVIDER_FILES_SUCCESS = 'GET_ACTIVITY_PROVIDER_FILES_SUCCESS';

export const GET_LEARNER_PROVIDER_FILES = 'GET_LEARNER_PROVIDER_FILES';
export const GET_LEARNER_PROVIDER_FILES_FAILURE = 'GET_LEARNER_PROVIDER_FILES_FAILURE';
export const GET_LEARNER_PROVIDER_FILES_LOADING = 'GET_LEARNER_PROVIDER_FILES_LOADING';
export const GET_LEARNER_PROVIDER_FILES_SUCCESS = 'GET_LEARNER_PROVIDER_FILES_SUCCESS';

export const GET_LEAERNER_VALIDATION_PROVIDER_FILES = 'GET_LEAERNER_VALIDATION_PROVIDER_FILES';
export const GET_LEAERNER_VALIDATION_PROVIDER_FILES_FAILURE = 'GET_LEAERNER_VALIDATION_PROVIDER_FILES_FAILURE';
export const GET_LEAERNER_VALIDATION_PROVIDER_FILES_LOADING = 'GET_LEAERNER_VALIDATION_PROVIDER_FILES_LOADING';
export const GET_LEAERNER_VALIDATION_PROVIDER_FILES_SUCCESS = 'GET_LEAERNER_VALIDATION_PROVIDER_FILES_SUCCESS';

// Interfaces
export interface ProviderFilesState {
  activityFiles: IProviderFile[];
  learnerFiles: IProviderFile[];
  learnerValidationFiles: ILeaernerValidationProviderFile[];
  error?: Error;
  isLoading: boolean;
}

interface GetActivityProviderFiles extends PARSAction<undefined, typeof GET_ACTIVITY_PROVIDER_FILES> {
  type: typeof GET_ACTIVITY_PROVIDER_FILES;
}

interface GetActivityProviderFilesSuccess extends PARSAction<undefined, typeof GET_ACTIVITY_PROVIDER_FILES_SUCCESS> {
  type: typeof GET_ACTIVITY_PROVIDER_FILES_SUCCESS;
}

interface GetActivityProviderFilesFailure extends PARSAction<undefined, typeof GET_ACTIVITY_PROVIDER_FILES_FAILURE> {
  type: typeof GET_ACTIVITY_PROVIDER_FILES_FAILURE;
}

interface GetActivityProviderFilesLoading extends PARSAction<undefined, typeof GET_ACTIVITY_PROVIDER_FILES_LOADING> {
  type: typeof GET_ACTIVITY_PROVIDER_FILES_LOADING;
}

interface GetLearnerValidationProviderFiles
  extends PARSAction<undefined, typeof GET_LEAERNER_VALIDATION_PROVIDER_FILES> {
  type: typeof GET_LEAERNER_VALIDATION_PROVIDER_FILES;
}

interface GetLeaernerValidationProviderFilesSuccess
  extends PARSAction<undefined, typeof GET_LEAERNER_VALIDATION_PROVIDER_FILES_SUCCESS> {
  type: typeof GET_LEAERNER_VALIDATION_PROVIDER_FILES_SUCCESS;
}

interface GetLearnerValidationProviderFilesFailure
  extends PARSAction<undefined, typeof GET_LEAERNER_VALIDATION_PROVIDER_FILES_FAILURE> {
  type: typeof GET_LEAERNER_VALIDATION_PROVIDER_FILES_FAILURE;
}

interface GetLearnerValidationProviderFilesLoading
  extends PARSAction<undefined, typeof GET_LEAERNER_VALIDATION_PROVIDER_FILES_LOADING> {
  type: typeof GET_LEAERNER_VALIDATION_PROVIDER_FILES_LOADING;
}

interface GetLearnerProviderFiles extends PARSAction<undefined, typeof GET_LEARNER_PROVIDER_FILES> {
  type: typeof GET_LEARNER_PROVIDER_FILES;
}

interface GetLearnerProviderFilesSuccess extends PARSAction<undefined, typeof GET_LEARNER_PROVIDER_FILES_SUCCESS> {
  type: typeof GET_LEARNER_PROVIDER_FILES_SUCCESS;
}

interface GetLearnerProviderFilesFailure extends PARSAction<undefined, typeof GET_LEARNER_PROVIDER_FILES_FAILURE> {
  type: typeof GET_LEARNER_PROVIDER_FILES_FAILURE;
}

interface GetLearnerProviderFilesLoading extends PARSAction<undefined, typeof GET_LEARNER_PROVIDER_FILES_LOADING> {
  type: typeof GET_LEARNER_PROVIDER_FILES_LOADING;
}

// Export actionType
export type ProviderFilesActionTypes =
  | GetActivityProviderFiles
  | GetActivityProviderFilesFailure
  | GetActivityProviderFilesLoading
  | GetActivityProviderFilesSuccess
  | GetLearnerProviderFiles
  | GetLearnerProviderFilesFailure
  | GetLearnerProviderFilesLoading
  | GetLearnerProviderFilesSuccess
  | GetLearnerValidationProviderFiles
  | GetLearnerValidationProviderFilesFailure
  | GetLearnerValidationProviderFilesLoading
  | GetLeaernerValidationProviderFilesSuccess;
