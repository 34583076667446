import { Accordion, AccordionDetails, AccordionSummary, createStyles, makeStyles } from '@material-ui/core';
import { AddRounded, RemoveRounded } from '@material-ui/icons';
import { ReactElement, useState } from 'react';

// Core.
import { ITaxonomyTerm } from 'core/models';

// Styles.
import styles from './CommendationAccordion.module.scss';

interface ICommendationAccordionProps {
  term: ITaxonomyTerm;
}

const useStyles = makeStyles(() =>
  createStyles({
    accordion: {
      '&:before': {
        display: 'none',
      },
      background: 'transparent',
      borderRadius: '0 !important',
      boxShadow: 'none',
      padding: 0,
    },
    details: {
      flexDirection: 'column',
      paddingBottom: 20,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 20,
    },
    summary: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '0 8px 0 0',
    },
  }),
);

export const CommendationAccordion = (props: ICommendationAccordionProps): ReactElement => {
  const { term } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const classes = useStyles();

  return (
    <Accordion key={term.id} className={classes.accordion} onChange={() => setIsExpanded(!isExpanded)}>
      <AccordionSummary
        className={classes.summary}
        expandIcon={isExpanded ? <RemoveRounded className={styles.icon} /> : <AddRounded className={styles.icon} />}
      >
        <strong className={styles.summary}>{term.name}</strong>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {term?.terms?.length ? (
          <ul className={styles.list}>
            {term.terms?.map((childTerm) => (
              <li key={childTerm.id}>
                <span>
                  <strong>{childTerm.name}</strong> - {childTerm.description}
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <>{term.description}</>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
