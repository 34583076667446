import { ReactElement, useEffect } from 'react';
import { FieldArray, FormikProvider, FormikValues, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

// Components
import ButtonRow from 'layouts/pages/activity/forms/buttonRow';
import { ILabelType, MultiSelectObject } from 'components/Typeahead/MultiSelectObject';
import { CreditType } from './CreditType';
import { LoadingCards } from 'components/LoadingCard';

// Styles
import pageStyles from '../../BCTPageStyles.module.scss';
import formStyles from '../FormStyles.module.scss';

// Store + Core
import { getBoardById, postBCTStep, setBCTStep } from 'store/bct/actions';
import { ITaxonomyTerm } from 'core/models';
import { currentBoardSelector, isLoadingBCTSelector } from 'store/bct/selectors';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { TAXONOMY_ACTIVITY_CERTIFYING_BOARD_ROOT_ID, TAXONOMY_ACTIVITY_CREDIT_ROOT_ID } from 'core/constants';
import { creditCertifyingBoardSelector, creditSelector } from 'store/taxonomy/selectors';

// Utils
import { initialCreditTypeDetailsForm } from './initialCreditTypeDetails';

// Types
import { BoardTypes, IBCTBoard, IBCTCreditType, IBCTStepProps } from '../../types';

export const CreditTypeDetailsForm = (props: IBCTStepProps): ReactElement => {
  const { prevStep, nextStep } = props;
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  // Selectors.
  const currentBoard: IBCTBoard = useSelector(currentBoardSelector);
  const isLoading: boolean = useSelector(isLoadingBCTSelector);
  const creditCertifyingBoard: ITaxonomyTerm[] = useSelector(creditCertifyingBoardSelector)?.terms;
  const activityCredit: ITaxonomyTerm[] = useSelector(creditSelector)?.terms;

  const isLicensing: boolean = currentBoard?.type === BoardTypes.STATE_BOARD;

  const currentCreditTypes = isLicensing
    ? // Leave the includes because of the trademark symbol.
      activityCredit?.filter(({ name }: ITaxonomyTerm): boolean => !!name.includes('AMA PRA'))
    : creditCertifyingBoard;

  // Fetch board and credit types.
  useEffect(() => {
    const getData = async (): Promise<void> => {
      const boardType = currentBoard?.type;

      // Fetch taxonomy term-set based on which board is selected.
      const boardDispatch =
        boardType === BoardTypes.CERTIFYING_BOARD
          ? dispatch(getTaxonomyTermById(TAXONOMY_ACTIVITY_CERTIFYING_BOARD_ROOT_ID))
          : dispatch(getTaxonomyTermById(TAXONOMY_ACTIVITY_CREDIT_ROOT_ID));

      await Promise.all([dispatch(getBoardById(id)), boardDispatch]);
    };
    getData();
  }, [currentBoard?.type, dispatch, id]);

  // Form setup.
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialCreditTypeDetailsForm(currentBoard),
    // API call.
    onSubmit: async (values: FormikValues): Promise<void> => {
      await dispatch(postBCTStep({ nextStep, values: { ...currentBoard, ...values } }));

      // Scroll to top when done.
      await window.scroll(0, 0);
    },
  });

  const { handleSubmit, resetForm, isValid, isSubmitting, values } = formik;

  const handleBackClick = (): void => {
    resetForm();
    dispatch(setBCTStep(prevStep));
    window.scroll(0, 0);
  };

  // Show this while data is loading.
  if (isLoading || !currentCreditTypes) return <LoadingCards />;

  // Format the IBCTCreditType to work with the Multi component.
  const options: ILabelType[] =
    currentCreditTypes?.map(({ id, name, ...rest }: ITaxonomyTerm): ILabelType => ({ id, name, ...rest })) || [];

  // Show the selected Credit Types.
  const selectedCreditTypes: IBCTCreditType[] = orderBy(get(values, 'creditTypes') || [], 'sortOrder');

  return (
    <>
      <h2 className={pageStyles.subtitle}>Enter the credit type details for this organization</h2>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <div className={formStyles['form-card']}>
            <fieldset className={formStyles['no-divider']}>
              <legend>Input the allowable credit types.</legend>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <MultiSelectObject formikKey="creditTypes" options={options} placeholder="Enter Credit Types" />
                </Grid>
              </Grid>
            </fieldset>
          </div>
          {!!selectedCreditTypes?.length && (
            <FieldArray
              name="creditTypes"
              render={() => {
                return selectedCreditTypes.map(
                  (type: IBCTCreditType, idx: number): ReactElement => (
                    <CreditType
                      creditType={type}
                      formikKey={`creditTypes.${idx}`}
                      key={type.id}
                      selectedCreditTypes={selectedCreditTypes}
                      sortOrder={idx}
                    />
                  ),
                );
              }}
            />
          )}
          <ButtonRow
            backLabel="Back"
            ctaLabel="Save and Continue"
            isCtaDisabled={!isValid}
            isSubmitting={isSubmitting}
            isValid={isValid}
            onBackClick={handleBackClick}
            onClick={handleSubmit}
            showBack
          />
        </form>
      </FormikProvider>
    </>
  );
};
