import {
  Activity,
  ActivityType,
  CreateActivityRequest,
  IActivityReviewHistory,
  IActivitySearchRequest,
  IActivitySearchResponse,
  IActivitySearchStateProps,
  IArrayBufferDownload,
  IBatchActivityRequest,
  IJsonPatchDocumentOperation,
  ILearnerSearchActivity,
  IProcessActivityRequest,
  PARSAction,
  UpdateActivityRequest,
  IQueueMocActivityRequest,
} from 'core/models';

export interface ActivityState {
  activityDetail?: Activity;
  activityDownload?: IArrayBufferDownload;
  activityTypes: ActivityType[];
  currentActivities?: Activity[];
  editIndex: number;
  error?: string;
  file: { file: File };
  isCreating: boolean;
  isDeleting: boolean;
  isDownloading: boolean;
  isLoadingReviewHistory: boolean;
  isSelectingForReview: boolean;
  isEditingReviewOutcome: boolean;
  isSearchingActivities: boolean;
  isSearchingActivity: boolean;
  isUpdating: boolean;
  nextSequenceNumber?: number;
  progressPercentage: number;
  reviewHistory: IActivityReviewHistory[];
  searchRequest: IActivitySearchRequest;
  searchResponse?: IActivitySearchResponse;
  searchStateProps: IActivitySearchStateProps;
}

export const BATCH_ACTIVITY = 'BATCH_ACTIVITY';
export const BATCH_ACTIVITY_CLEAR = 'BATCH_ACTIVITY_CLEAR';
export const BATCH_ACTIVITY_FAILURE = 'BATCH_ACTIVITY_FAILURE';
export const BATCH_ACTIVITY_PROGRESS = 'BATCH_ACTIVITY_PROGRESS';
export const BATCH_ACTIVITY_SUCCESS = 'BATCH_ACTIVITY_SUCCESS';
export const BULK_DELETE_ACTIVITIES = 'BULK_DELETE_ACTIVITIES';
export const BULK_DELETE_ACTIVITIES_FAILURE = 'BULK_DELETE_ACTIVITIES_FAILURE';
export const BULK_DELETE_ACTIVITIES_SUCCESS = 'BULK_DELETE_ACTIVITIES_SUCCESS';
export const CLEAR_ACTIVITY_DETAIL = 'CLEAR_ACTIVITY_DETAIL';
export const CLEAR_CURRENT_ACTIVITIES = 'CLEAR_CURRENT_ACTIVITIES';
export const RESET_ACTIVITY_TYPES = 'RESET_ACTIVITY_TYPES';
export const CREATE_ACTIVITIES = 'CREATE_ACTIVITIES';
export const CREATE_ACTIVITIES_FAILURE = 'CREATE_ACTIVITIES_FAILURE';
export const CREATE_ACTIVITIES_SUCCESS = 'CREATE_ACTIVITIES_SUCCESS';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const DELETE_ACTIVITY_FAILURE = 'DELETE_ACTIVITY_FAILURE';
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS';

export const DELETE_LEARNER_ACTIVITY = 'DELETE_LEARNER_ACTIVITY';
export const DELETE_LEARNER_ACTIVITY_SUCCESS = 'DELETE_LEARNER_ACTIVITY_SUCCESS';
export const DELETE_LEARNER_ACTIVITY_FAILURE = 'DELETE_LEARNER_ACTIVITY_FAILURE';

export const QUEUE_MOC_ACTIVITY = 'QUEUE_MOC_ACTIVITY';
export const ADMIN_DELETE_ACTIVITY = 'ADMIN_DELETE_ACTIVITY';

export const DOWNLOAD_ACTIVITIES = 'DOWNLOAD_ACTIVITIES';
export const DOWNLOAD_ACTIVITIES_FAILURE = 'DOWNLOAD_ACTIVITIES_FAILURE';
export const DOWNLOAD_ACTIVITIES_SUCCESS = 'DOWNLOAD_ACTIVITIES_SUCCESS';
export const EXPORT_ACTIVITIES = 'EXPORT_ACTIVITIES';
export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const GET_ACTIVITIES_FAILURE = 'GET_ACTIVITIES_FAILURE';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITY = 'GET_ACTIVITY';
export const GET_ACTIVITY_FAILURE = 'GET_ACTIVITY_FAILURE';
export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS';
export const GET_ACTIVITY_TYPES = 'GET_ACTIVITY_TYPES';
export const GET_ACTIVITY_TYPES_FAILURE = 'GET_ACTIVITY_TYPES_FAILURE';
export const GET_ACTIVITY_TYPES_SUCCESS = 'GET_ACTIVITY_TYPES_SUCCESS';
export const PATCH_ACTIVITY = 'PATCH_ACTIVITY';
export const PATCH_ACTIVITY_FAILURE = 'PATCH_ACTIVITY_FAILURE';
export const PATCH_ACTIVITY_SUCCESS = 'PATCH_ACTIVITY_SUCCESS';
export const SET_ACTIVITY_EDIT_INDEX = 'SET_ACTIVITY_EDIT_INDEX';
export const UNDELETE_ACTIVITIES = 'UNDELETE_ACTIVITIES';
export const UNDELETE_ACTIVITIES_FAILURE = 'UNDELETE_ACTIVITIES_FAILURE';
export const UNDELETE_ACTIVITIES_SUCCESS = 'UNDELETE_ACTIVITIES_SUCCESS';
export const UPDATE_ACTIVITIES = 'UPDATE_ACTIVITIES';
export const UPDATE_ACTIVITIES_FAILURE = 'UPDATE_ACTIVITIES_FAILURE';
export const UPDATE_ACTIVITIES_SUCCESS = 'UPDATE_ACTIVITIES_SUCCESS';
export const UPDATE_ACTIVITY_SEARCH_REQUEST = 'UPDATE_ACTIVITY_SEARCH_REQUEST';
export const UPDATE_ACTIVITY_SEARCH_STATE_PROPS = 'UPDATE_ACTIVITY_SEARCH_STATE_PROPS';

export const UPDATE_ACTIVITIES_PAGINATION_STATE = 'UPDATE_ACTIVITIES_PAGINATION_STATE';

export const RESET_ACTIVITIES_SEARCH_RESPONSE = 'RESET_ACTIVITIES_SEARCH_RESPONSE';
export const RESET_ACTIVITIES_SEARCH_REQUEST = 'RESET_ACTIVITIES_SEARCH_REQUEST';
export const RESET_ACTIVITIES_SEARCH_STATE_PROPS = 'RESET_ACTIVITIES_SEARCH_STATE_PROPS';

export const TOGGLE_CLOSE_ACTIVITY = 'TOGGLE_CLOSE_ACTIVITY';
export const TOGGLE_CLOSE_ACTIVITY_SUCCESS = 'TOGGLE_CLOSE_ACTIVITY_SUCCESS';
export const TOGGLE_CLOSE_ACTIVITY_FAILURE = 'TOGGLE_CLOSE_ACTIVITY_FAILURE';

export const GET_NEXT_SEQUENCE_NUMBER = 'GET_NEXT_SEQUENCE_NUMBER';
export const GET_NEXT_SEQUENCE_NUMBER_SUCCESS = 'GET_NEXT_SEQUENCE_NUMBER_SUCCESS';
export const GET_NEXT_SEQUENCE_NUMBER_FAILURE = 'GET_NEXT_SEQUENCE_NUMBER_FAILURE';

export const SELECT_ACTIVITY_FOR_REVIEW = 'SELECT_ACTIVITY_FOR_REVIEW';
export const SELECT_ACTIVITY_FOR_REVIEW_SUCCESS = 'SELECT_ACTIVITY_FOR_REVIEW_SUCCESS';
export const SELECT_ACTIVITY_FOR_REVIEW_FAILURE = 'SELECT_ACTIVITY_FOR_REVIEW_FAILURE';

export const EDIT_ACTIVITY_REVIEW_OUTCOME = 'EDIT_ACTIVITY_REVIEW_OUTCOME';
export const EDIT_ACTIVITY_REVIEW_OUTCOME_SUCCESS = 'EDIT_ACTIVITY_REVIEW_OUTCOME_SUCCESS';
export const EDIT_ACTIVITY_REVIEW_OUTCOME_FAILURE = 'EDIT_ACTIVITY_REVIEW_OUTCOME_FAILURE';

export const GET_ACTIVITY_REVIEW_HISTORY = 'GET_ACTIVITY_REVIEW_HISTORY';
export const GET_ACTIVITY_REVIEW_HISTORY_SUCCESS = 'GET_ACTIVITY_REVIEW_HISTORY_SUCCESS';
export const GET_ACTIVITY_REVIEW_HISTORY_FAILURE = 'GET_ACTIVITY_REVIEW_HISTORY_FAILURE';

export const ACCEPT_ACTIVITY = 'ACCEPT_ACTIVITY';

interface CreateActivities extends PARSAction<CreateActivityRequest[], typeof CREATE_ACTIVITIES> {
  type: typeof CREATE_ACTIVITIES;
  payload: CreateActivityRequest[];
}

interface CreateActivitiesSuccess extends PARSAction<Activity[], typeof CREATE_ACTIVITIES_SUCCESS> {
  type: typeof CREATE_ACTIVITIES_SUCCESS;
  payload: Activity[];
}

interface CreateActivitiesFailure extends PARSAction<string, typeof CREATE_ACTIVITIES_FAILURE> {
  type: typeof CREATE_ACTIVITIES_FAILURE;
  payload: string;
}

interface BatchActivity extends PARSAction<IBatchActivityRequest, typeof BATCH_ACTIVITY> {
  type: typeof BATCH_ACTIVITY;
  payload: IBatchActivityRequest;
}

interface BatchActivityProgress extends PARSAction<number, typeof BATCH_ACTIVITY_PROGRESS> {
  type: typeof BATCH_ACTIVITY_PROGRESS;
  payload: number;
}

interface BatchActivitySuccess extends PARSAction<Activity[], typeof BATCH_ACTIVITY_SUCCESS> {
  type: typeof BATCH_ACTIVITY_SUCCESS;
  payload: Activity[];
}

interface BatchActivityFailure extends PARSAction<Error, typeof BATCH_ACTIVITY_FAILURE> {
  type: typeof BATCH_ACTIVITY_FAILURE;
  payload: Error;
}

interface BatchActivityClear extends PARSAction<undefined, typeof BATCH_ACTIVITY_CLEAR> {
  type: typeof BATCH_ACTIVITY_CLEAR;
}

interface ToggleActivity extends PARSAction<undefined, typeof TOGGLE_CLOSE_ACTIVITY> {
  type: typeof TOGGLE_CLOSE_ACTIVITY;
}

interface ToggleActivitySuccess extends PARSAction<undefined, typeof TOGGLE_CLOSE_ACTIVITY_SUCCESS> {
  type: typeof TOGGLE_CLOSE_ACTIVITY_SUCCESS;
}

interface ToggleActivityFailure extends PARSAction<string, typeof TOGGLE_CLOSE_ACTIVITY_FAILURE> {
  payload: string;
  type: typeof TOGGLE_CLOSE_ACTIVITY_FAILURE;
}

interface GetActivityTypes extends PARSAction<undefined, typeof GET_ACTIVITY_TYPES> {
  type: typeof GET_ACTIVITY_TYPES;
}

interface GetActivityTypesFailure extends PARSAction<string, typeof GET_ACTIVITY_TYPES_FAILURE> {
  type: typeof GET_ACTIVITY_TYPES_FAILURE;
  payload: string;
}

interface GetActivityTypesSuccess extends PARSAction<ActivityType[], typeof GET_ACTIVITY_TYPES_SUCCESS> {
  type: typeof GET_ACTIVITY_TYPES_SUCCESS;
  payload: ActivityType[];
}

interface ResetActivitySearchResponse extends PARSAction<undefined, typeof RESET_ACTIVITIES_SEARCH_RESPONSE> {
  type: typeof RESET_ACTIVITIES_SEARCH_RESPONSE;
}

interface ResetActivitySearchRequest extends PARSAction<undefined, typeof RESET_ACTIVITIES_SEARCH_REQUEST> {
  type: typeof RESET_ACTIVITIES_SEARCH_REQUEST;
}

interface ResetActivitySearchStateProps extends PARSAction<undefined, typeof RESET_ACTIVITIES_SEARCH_STATE_PROPS> {
  type: typeof RESET_ACTIVITIES_SEARCH_STATE_PROPS;
}

interface PatchActivity
  extends PARSAction<{ id: string; request: IJsonPatchDocumentOperation[] }, typeof PATCH_ACTIVITY> {
  type: typeof PATCH_ACTIVITY;
  payload: { id: string; request: IJsonPatchDocumentOperation[] };
}

interface PatchActivitySuccess extends PARSAction<void, typeof PATCH_ACTIVITY_SUCCESS> {
  type: typeof PATCH_ACTIVITY_SUCCESS;
  payload: void;
}

interface PatchActivityFailure extends PARSAction<Error, typeof PATCH_ACTIVITY_FAILURE> {
  type: typeof PATCH_ACTIVITY_FAILURE;
  payload: Error;
}

interface DeleteLearnerActivity extends PARSAction<ILearnerSearchActivity, typeof DELETE_LEARNER_ACTIVITY> {
  type: typeof DELETE_LEARNER_ACTIVITY;
  payload: ILearnerSearchActivity;
}

interface DeleteLearnerActivitySuccess extends PARSAction<void, typeof DELETE_LEARNER_ACTIVITY_SUCCESS> {
  type: typeof DELETE_LEARNER_ACTIVITY_SUCCESS;
}

interface DeleteLearnerActivityFailure extends PARSAction<string, typeof DELETE_LEARNER_ACTIVITY_FAILURE> {
  type: typeof DELETE_LEARNER_ACTIVITY_FAILURE;
  payload: string;
}

interface UpdateActivity extends PARSAction<UpdateActivityRequest, typeof UPDATE_ACTIVITIES> {
  type: typeof UPDATE_ACTIVITIES;
  payload: UpdateActivityRequest;
}

interface UpdateActivitySuccess extends PARSAction<Activity[], typeof UPDATE_ACTIVITIES_SUCCESS> {
  type: typeof UPDATE_ACTIVITIES_SUCCESS;
  payload: Activity[];
}

interface UpdateActivityFailure extends PARSAction<string, typeof UPDATE_ACTIVITIES_FAILURE> {
  type: typeof UPDATE_ACTIVITIES_FAILURE;
  payload: string;
}

interface QueueMocActivity extends PARSAction<IQueueMocActivityRequest, typeof QUEUE_MOC_ACTIVITY> {
  type: typeof QUEUE_MOC_ACTIVITY;
  payload: IQueueMocActivityRequest;
}

interface GetActivity extends PARSAction<string, typeof GET_ACTIVITY> {
  type: typeof GET_ACTIVITY;
  payload: string;
}

interface GetActivitySuccess extends PARSAction<Activity, typeof GET_ACTIVITY_SUCCESS> {
  type: typeof GET_ACTIVITY_SUCCESS;
  payload: Activity;
}

interface GetActivityFailure extends PARSAction<Error, typeof GET_ACTIVITY_FAILURE> {
  type: typeof GET_ACTIVITY_FAILURE;
  payload: Error;
}

interface GetActivities extends PARSAction<IActivitySearchRequest, typeof GET_ACTIVITIES> {
  type: typeof GET_ACTIVITIES;
  payload: IActivitySearchRequest;
}

interface GetActivitiesSuccess extends PARSAction<IActivitySearchResponse, typeof GET_ACTIVITIES_SUCCESS> {
  type: typeof GET_ACTIVITIES_SUCCESS;
  payload: IActivitySearchResponse;
}

interface GetActivitiesFailure extends PARSAction<Error, typeof GET_ACTIVITIES_FAILURE> {
  type: typeof GET_ACTIVITIES_FAILURE;
  payload: Error;
}

interface UpdateActivitySearchRequest
  extends PARSAction<IActivitySearchRequest, typeof UPDATE_ACTIVITY_SEARCH_REQUEST> {
  type: typeof UPDATE_ACTIVITY_SEARCH_REQUEST;
  payload: IActivitySearchRequest;
}

interface UpdateActivitySearchProps
  extends PARSAction<IActivitySearchStateProps, typeof UPDATE_ACTIVITY_SEARCH_STATE_PROPS> {
  type: typeof UPDATE_ACTIVITY_SEARCH_STATE_PROPS;
  payload: IActivitySearchStateProps;
}

interface ExportActivities extends PARSAction<IActivitySearchRequest, typeof EXPORT_ACTIVITIES> {
  type: typeof EXPORT_ACTIVITIES;
  payload: IActivitySearchRequest;
}

interface DownloadActivities extends PARSAction<string, typeof DOWNLOAD_ACTIVITIES> {
  type: typeof DOWNLOAD_ACTIVITIES;
  payload: string;
}

interface DownloadActivitiesSuccess extends PARSAction<IArrayBufferDownload, typeof DOWNLOAD_ACTIVITIES_SUCCESS> {
  type: typeof DOWNLOAD_ACTIVITIES_SUCCESS;
  payload: IArrayBufferDownload;
}

interface DownloadActivitiesFailure extends PARSAction<Error, typeof DOWNLOAD_ACTIVITIES_FAILURE> {
  type: typeof DOWNLOAD_ACTIVITIES_FAILURE;
  payload: Error;
}

interface BulkDeleteActivities extends PARSAction<string[], typeof BULK_DELETE_ACTIVITIES> {
  type: typeof BULK_DELETE_ACTIVITIES;
  payload: string[];
}

interface BulkDeleteActivitiesSuccess extends PARSAction<undefined, typeof BULK_DELETE_ACTIVITIES_SUCCESS> {
  type: typeof BULK_DELETE_ACTIVITIES_SUCCESS;
}

interface BulkDeleteActivitiesFailure extends PARSAction<Error, typeof BULK_DELETE_ACTIVITIES_FAILURE> {
  type: typeof BULK_DELETE_ACTIVITIES_FAILURE;
  payload: Error;
}

interface DeleteActivity extends PARSAction<string, typeof DELETE_ACTIVITY> {
  type: typeof DELETE_ACTIVITY;
  payload: string;
}

interface DeleteActivitySuccess extends PARSAction<undefined, typeof DELETE_ACTIVITY_SUCCESS> {
  type: typeof DELETE_ACTIVITY_SUCCESS;
}

interface DeleteActivityFailure extends PARSAction<Error, typeof DELETE_ACTIVITY_FAILURE> {
  type: typeof DELETE_ACTIVITY_FAILURE;
  payload: Error;
}

interface ProcessActivity extends PARSAction<IProcessActivityRequest, typeof ACCEPT_ACTIVITY> {
  type: typeof ACCEPT_ACTIVITY;
  payload: IProcessActivityRequest;
}

interface SelectActivityForReview extends PARSAction<void, typeof SELECT_ACTIVITY_FOR_REVIEW> {
  type: typeof SELECT_ACTIVITY_FOR_REVIEW;
}

interface SelectActivityForReviewSuccess extends PARSAction<void, typeof SELECT_ACTIVITY_FOR_REVIEW_SUCCESS> {
  type: typeof SELECT_ACTIVITY_FOR_REVIEW_SUCCESS;
}

interface SelectActivityForReviewFailure extends PARSAction<void, typeof SELECT_ACTIVITY_FOR_REVIEW_FAILURE> {
  type: typeof SELECT_ACTIVITY_FOR_REVIEW_FAILURE;
}

interface EditActivityReviewOutcome extends PARSAction<void, typeof EDIT_ACTIVITY_REVIEW_OUTCOME> {
  type: typeof EDIT_ACTIVITY_REVIEW_OUTCOME;
}

interface EditActivityReviewOutcomeSuccess extends PARSAction<void, typeof EDIT_ACTIVITY_REVIEW_OUTCOME_SUCCESS> {
  type: typeof EDIT_ACTIVITY_REVIEW_OUTCOME_SUCCESS;
}

interface EditActivityReviewOutcomeFailure extends PARSAction<void, typeof EDIT_ACTIVITY_REVIEW_OUTCOME_FAILURE> {
  type: typeof EDIT_ACTIVITY_REVIEW_OUTCOME_FAILURE;
}

interface GetActivityReviewHistory extends PARSAction<void, typeof GET_ACTIVITY_REVIEW_HISTORY> {
  type: typeof GET_ACTIVITY_REVIEW_HISTORY;
}

interface GetActivityReviewHistorySuccess
  extends PARSAction<IActivityReviewHistory[], typeof GET_ACTIVITY_REVIEW_HISTORY_SUCCESS> {
  type: typeof GET_ACTIVITY_REVIEW_HISTORY_SUCCESS;
  payload: IActivityReviewHistory[];
}

interface GetActivityReviewHistoryFailure extends PARSAction<void, typeof GET_ACTIVITY_REVIEW_HISTORY_FAILURE> {
  type: typeof GET_ACTIVITY_REVIEW_HISTORY_FAILURE;
}

interface UnDeleteActivities extends PARSAction<string[], typeof UNDELETE_ACTIVITIES> {
  type: typeof UNDELETE_ACTIVITIES;
  payload: string[];
}

interface UnDeleteActivitiesSuccess extends PARSAction<undefined, typeof UNDELETE_ACTIVITIES_SUCCESS> {
  type: typeof UNDELETE_ACTIVITIES_SUCCESS;
}

interface UnDeleteActivitiesFailure extends PARSAction<string, typeof UNDELETE_ACTIVITIES_FAILURE> {
  type: typeof UNDELETE_ACTIVITIES_FAILURE;
  payload: string;
}

interface UpdateActivitiesPaginationState
  extends PARSAction<IActivitySearchStateProps, typeof UPDATE_ACTIVITIES_PAGINATION_STATE> {
  payload: IActivitySearchStateProps;
  type: typeof UPDATE_ACTIVITIES_PAGINATION_STATE;
}

interface ClearActivityDetail extends PARSAction<undefined, typeof CLEAR_ACTIVITY_DETAIL> {
  type: typeof CLEAR_ACTIVITY_DETAIL;
}

interface ResetActivityTypes extends PARSAction<undefined, typeof RESET_ACTIVITY_TYPES> {
  type: typeof RESET_ACTIVITY_TYPES;
}

interface ClearCurrentActivities extends PARSAction<undefined, typeof CLEAR_CURRENT_ACTIVITIES> {
  type: typeof CLEAR_CURRENT_ACTIVITIES;
}

interface SetActivityEditIndex extends PARSAction<number, typeof SET_ACTIVITY_EDIT_INDEX> {
  type: typeof SET_ACTIVITY_EDIT_INDEX;
  payload: number;
}

interface GetNextSequenceNumber extends PARSAction<undefined, typeof GET_NEXT_SEQUENCE_NUMBER> {
  type: typeof GET_NEXT_SEQUENCE_NUMBER;
}

interface GetNextSequenceNumberSuccess extends PARSAction<number, typeof GET_NEXT_SEQUENCE_NUMBER_SUCCESS> {
  payload: number;
  type: typeof GET_NEXT_SEQUENCE_NUMBER_SUCCESS;
}

interface GetNextSequenceNumberFailure extends PARSAction<string, typeof GET_NEXT_SEQUENCE_NUMBER_FAILURE> {
  payload: string;
  type: typeof GET_NEXT_SEQUENCE_NUMBER_FAILURE;
}

export type ActivityActionTypes =
  | BatchActivity
  | BatchActivityClear
  | BatchActivityFailure
  | BatchActivityProgress
  | BatchActivitySuccess
  | BulkDeleteActivities
  | BulkDeleteActivitiesFailure
  | BulkDeleteActivitiesSuccess
  | ClearActivityDetail
  | ResetActivityTypes
  | ClearCurrentActivities
  | CreateActivities
  | CreateActivitiesFailure
  | CreateActivitiesSuccess
  | DeleteActivity
  | DeleteActivityFailure
  | DeleteActivitySuccess
  | DeleteLearnerActivity
  | DeleteLearnerActivityFailure
  | DeleteLearnerActivitySuccess
  | DownloadActivities
  | DownloadActivitiesFailure
  | DownloadActivitiesSuccess
  | EditActivityReviewOutcome
  | EditActivityReviewOutcomeSuccess
  | EditActivityReviewOutcomeFailure
  | ExportActivities
  | GetActivities
  | GetActivitiesFailure
  | GetActivitiesSuccess
  | GetActivity
  | GetActivityFailure
  | GetActivitySuccess
  | GetActivityReviewHistory
  | GetActivityReviewHistorySuccess
  | GetActivityReviewHistoryFailure
  | GetActivityTypes
  | GetActivityTypesFailure
  | GetActivityTypesSuccess
  | PatchActivity
  | PatchActivityFailure
  | PatchActivitySuccess
  | ResetActivitySearchRequest
  | ResetActivitySearchResponse
  | ResetActivitySearchStateProps
  | SelectActivityForReview
  | SelectActivityForReviewSuccess
  | SelectActivityForReviewFailure
  | SetActivityEditIndex
  | ToggleActivity
  | ToggleActivityFailure
  | ToggleActivitySuccess
  | UnDeleteActivities
  | UnDeleteActivitiesFailure
  | UnDeleteActivitiesSuccess
  | UpdateActivitiesPaginationState
  | UpdateActivity
  | UpdateActivityFailure
  | UpdateActivitySearchProps
  | UpdateActivitySearchRequest
  | UpdateActivitySuccess
  | ProcessActivity
  | GetNextSequenceNumber
  | GetNextSequenceNumberSuccess
  | GetNextSequenceNumberFailure
  | QueueMocActivity;
