/* eslint-disable sort-keys */
import { Box, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import { ReactElement, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { drop, range, take } from 'lodash';
import { Modal } from 'components/ContinuousImprovement';
import Button from 'components/Button/Button';
import { HighlightBox } from 'components/HighlightBox';
import { LoadingCards } from 'components/LoadingCard';
import { ButtonVariant } from 'core/enums';
import { useLoadData } from 'hooks/useLoadData';
import { DEFAULT_DATE_FORMAT } from 'core/constants';
import { useAccountManagerInfo } from './hooks';
import styles from './search.module.scss';
import moment from 'moment';
import { Accreditation } from 'core/models';
import { useDispatch } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import { popToast } from 'store/toast/actions';
import { errorToastOptions } from 'store/toast/constants';
import { handleServerError } from 'globals/utils/handleServerError';
import { AccreditationService } from 'services/Accreditation';

interface DeleteAccreditationFormProps {
  accreditation: Accreditation;
  onClose: VoidFunction;
  onSuccess: VoidFunction;
}

export const DeleteAccreditationForm = ({
  accreditation,
  onClose,
  onSuccess,
}: DeleteAccreditationFormProps): ReactElement => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {},
    onSubmit: async (): Promise<void> => {
      try {
        await AccreditationService.deleteAccreditationInformation(accreditation.id);
        onClose();
        onSuccess?.();
      } catch (error) {
        dispatch(popToast({ ...errorToastOptions, message: <>{handleServerError({ error }).errorMessage}</> }));
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div>Are you sure you want to delete?</div>
        <footer className={styles['modal-footer']}>
          <Button onClick={onClose} variant={ButtonVariant.Secondary}>
            Cancel
          </Button>
          <Button
            disabled={formik.isSubmitting}
            isSubmitting={formik.isSubmitting}
            type="submit"
            variant={ButtonVariant.Primary}
          >
            Delete
          </Button>
        </footer>
      </form>
    </FormikProvider>
  );
};

const pageSize = 5;

export const AccreditationInformationSearch = (): ReactElement => {
  const { canEditAccreditation, organization } = useAccountManagerInfo();

  const id = organization.id;

  const { data, error, isLoading, refresh } = useLoadData(
    'AccountManagerAccreditationSearch',
    useCallback(async () => {
      return await AccreditationService.searchAccreditationInfoAsync(id);
    }, [id]),
  );

  const pageCount = Math.ceil((data?.length ?? 0) / pageSize);
  const [page, setPage] = useState(0);
  const [toDelete, setToDelete] = useState<Accreditation | null>(null);

  return (
    <>
      <Modal isOpen={!!toDelete} title="Delete Accreditation" onClose={() => setToDelete(null)}>
        <DeleteAccreditationForm accreditation={toDelete} onClose={() => setToDelete(null)} onSuccess={refresh} />
      </Modal>
      <div>
        {isLoading ? (
          <LoadingCards count={3} />
        ) : error ? (
          <HighlightBox variant="danger">
            <Box display="flex" component="p">
              <WarningRounded />
              <Box ml={2}>{error}</Box>
            </Box>
          </HighlightBox>
        ) : (
          <TableContainer role="grid">
            <Table className={styles.table} size="small">
              <TableHead className={styles.tableHeader}>
                <TableRow>
                  <TableCell component="th">Accreditation Status</TableCell>
                  <TableCell component="th">Effective Date</TableCell>
                  <TableCell component="th">Expiration Date</TableCell>
                  <TableCell component="th">Progress Report Required</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {take(drop(data, page * pageSize), pageSize).map((accreditation) => (
                  <TableRow key={accreditation.id}>
                    <TableCell>{accreditation.accreditationType}</TableCell>
                    <TableCell>{moment(accreditation.effectiveDate).utc().format(DEFAULT_DATE_FORMAT)}</TableCell>
                    <TableCell>{moment(accreditation.expirationDate).utc().format(DEFAULT_DATE_FORMAT)}</TableCell>
                    <TableCell>{accreditation.progressReportDueDate === undefined ? 'No' : 'Yes'}</TableCell>
                    <TableCell>
                      <div className={styles['action-buttons']}>
                        {canEditAccreditation ? (
                          <Button
                            component={Link}
                            title="Edit Accreditation"
                            to={`/account-manager/accreditations/edit/${accreditation.id}`}
                            variant={ButtonVariant.Secondary}
                          >
                            Update
                          </Button>
                        ) : null}
                        {canEditAccreditation ? (
                          <Button
                            title="Delete Accreditation"
                            variant={ButtonVariant.Secondary}
                            onClick={() => setToDelete(accreditation)}
                          >
                            Delete
                          </Button>
                        ) : null}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={8}>
                    <div>
                      Showing {page * pageSize + 1} to {Math.min((page + 1) * pageSize, data.length)} of {data.length}{' '}
                      matching accreditation
                    </div>
                    <div className={styles['paging-buttons']}>
                      {range(0, pageCount).map((i) => (
                        <Button
                          key={i}
                          onClick={() => setPage(i)}
                          disabled={page === i}
                          variant={ButtonVariant.Secondary}
                        >
                          {i + 1}
                        </Button>
                      ))}
                    </div>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
};
