import { API } from 'core';
import {
  Activity,
  ActivityType,
  IActivitySearchRequest,
  IActivitySearchResponse,
  IActivitySearchStateProps,
  IArrayBufferDownload,
} from 'core/models';
import { Reducer } from 'redux';
import {
  ACCEPT_ACTIVITY,
  ActivityActionTypes,
  ActivityState,
  BATCH_ACTIVITY,
  BATCH_ACTIVITY_CLEAR,
  BATCH_ACTIVITY_FAILURE,
  BATCH_ACTIVITY_PROGRESS,
  BATCH_ACTIVITY_SUCCESS,
  BULK_DELETE_ACTIVITIES,
  BULK_DELETE_ACTIVITIES_FAILURE,
  BULK_DELETE_ACTIVITIES_SUCCESS,
  CLEAR_ACTIVITY_DETAIL,
  CLEAR_CURRENT_ACTIVITIES,
  CREATE_ACTIVITIES,
  CREATE_ACTIVITIES_FAILURE,
  CREATE_ACTIVITIES_SUCCESS,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_FAILURE,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_LEARNER_ACTIVITY,
  DELETE_LEARNER_ACTIVITY_FAILURE,
  DELETE_LEARNER_ACTIVITY_SUCCESS,
  DOWNLOAD_ACTIVITIES,
  DOWNLOAD_ACTIVITIES_FAILURE,
  DOWNLOAD_ACTIVITIES_SUCCESS,
  EDIT_ACTIVITY_REVIEW_OUTCOME,
  EDIT_ACTIVITY_REVIEW_OUTCOME_FAILURE,
  EDIT_ACTIVITY_REVIEW_OUTCOME_SUCCESS,
  EXPORT_ACTIVITIES,
  GET_ACTIVITIES,
  GET_ACTIVITIES_FAILURE,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITY,
  GET_ACTIVITY_FAILURE,
  GET_ACTIVITY_REVIEW_HISTORY,
  GET_ACTIVITY_REVIEW_HISTORY_FAILURE,
  GET_ACTIVITY_REVIEW_HISTORY_SUCCESS,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_TYPES_FAILURE,
  GET_ACTIVITY_TYPES_SUCCESS,
  GET_NEXT_SEQUENCE_NUMBER,
  GET_NEXT_SEQUENCE_NUMBER_FAILURE,
  GET_NEXT_SEQUENCE_NUMBER_SUCCESS,
  PATCH_ACTIVITY,
  PATCH_ACTIVITY_FAILURE,
  PATCH_ACTIVITY_SUCCESS,
  QUEUE_MOC_ACTIVITY,
  RESET_ACTIVITIES_SEARCH_REQUEST,
  RESET_ACTIVITIES_SEARCH_RESPONSE,
  RESET_ACTIVITIES_SEARCH_STATE_PROPS,
  RESET_ACTIVITY_TYPES,
  SELECT_ACTIVITY_FOR_REVIEW,
  SELECT_ACTIVITY_FOR_REVIEW_FAILURE,
  SELECT_ACTIVITY_FOR_REVIEW_SUCCESS,
  SET_ACTIVITY_EDIT_INDEX,
  TOGGLE_CLOSE_ACTIVITY,
  TOGGLE_CLOSE_ACTIVITY_FAILURE,
  TOGGLE_CLOSE_ACTIVITY_SUCCESS,
  UNDELETE_ACTIVITIES,
  UNDELETE_ACTIVITIES_FAILURE,
  UNDELETE_ACTIVITIES_SUCCESS,
  UPDATE_ACTIVITIES,
  UPDATE_ACTIVITIES_FAILURE,
  UPDATE_ACTIVITIES_PAGINATION_STATE,
  UPDATE_ACTIVITIES_SUCCESS,
  UPDATE_ACTIVITY_SEARCH_REQUEST,
  UPDATE_ACTIVITY_SEARCH_STATE_PROPS,
} from './types';
import {
  INITIAL_ACTIVITY_SEARCH_FACETS,
  INITIAL_SEARCH_STATE_PROPS,
} from 'components/ContinuousImprovement/Facets/ActivitySearchFacets/constants';

const initialState: ActivityState = {
  activityDetail: undefined,
  activityTypes: [],
  currentActivities: [],
  editIndex: 0,
  error: '',
  file: null,
  isCreating: false,
  isDeleting: false,
  isDownloading: false,
  isEditingReviewOutcome: false,
  isLoadingReviewHistory: false,
  isSearchingActivities: true,
  isSearchingActivity: true,
  isSelectingForReview: false,
  isUpdating: false,
  nextSequenceNumber: undefined,
  progressPercentage: 0,
  reviewHistory: [],
  searchRequest: INITIAL_ACTIVITY_SEARCH_FACETS,
  searchResponse: undefined,
  searchStateProps: INITIAL_SEARCH_STATE_PROPS,
};

const activitiesReducer: Reducer<ActivityState, ActivityActionTypes> = (
  state = initialState,
  action: ActivityActionTypes,
): ActivityState => {
  switch (action.type) {
    case CREATE_ACTIVITIES:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        currentActivities: action.payload as Activity[],
        error: '',
        isCreating: false,
      };
    case CREATE_ACTIVITIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isCreating: false,
      };
    case BATCH_ACTIVITY:
      return {
        ...state,
        file: action.payload.file,
        isCreating: true,
      };
    case BATCH_ACTIVITY_CLEAR:
      return {
        ...state,
        error: null,
        file: null,
        isCreating: false,
        progressPercentage: 0,
      };
    case BATCH_ACTIVITY_PROGRESS:
      return {
        ...state,
        progressPercentage: action.payload,
      };
    case BATCH_ACTIVITY_SUCCESS:
      return {
        ...state,
        error: null,
        isCreating: false,
        progressPercentage: 0,
      };
    case BATCH_ACTIVITY_FAILURE:
      return {
        ...state,
        error: API.getErrorMessage((action.payload || {}) as Error),
        isCreating: false,
      };
    case CLEAR_CURRENT_ACTIVITIES:
      return {
        ...state,
        currentActivities: [],
      };
    case GET_ACTIVITY_TYPES_SUCCESS:
      return {
        ...state,
        activityTypes: action.payload as ActivityType[],
      };
    case RESET_ACTIVITY_TYPES:
      return {
        ...state,
        activityTypes: initialState.activityTypes,
      };
    case GET_ACTIVITY_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_ACTIVITIES_PAGINATION_STATE:
      return {
        ...state,
        searchStateProps: action.payload as IActivitySearchStateProps,
      };
    case PATCH_ACTIVITY:
      return {
        ...state,
        isUpdating: true,
      };
    case PATCH_ACTIVITY_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      };
    case PATCH_ACTIVITY_FAILURE:
      return {
        ...state,
        isUpdating: false,
      };
    case TOGGLE_CLOSE_ACTIVITY:
      return {
        ...state,
        isUpdating: true,
      };
    case TOGGLE_CLOSE_ACTIVITY_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      };
    case TOGGLE_CLOSE_ACTIVITY_FAILURE:
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
      };
    case UPDATE_ACTIVITIES:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        currentActivities: action.payload as Activity[],
        error: '',
        isUpdating: false,
      };
    case UPDATE_ACTIVITIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
      };
    case QUEUE_MOC_ACTIVITY:
      return {
        ...state,
        isUpdating: true,
      };
    case DELETE_LEARNER_ACTIVITY:
      return {
        ...state,
      };

    case DELETE_LEARNER_ACTIVITY_SUCCESS:
      return {
        ...state,
      };

    case DELETE_LEARNER_ACTIVITY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case ACCEPT_ACTIVITY:
      return {
        ...state,
        isUpdating: true,
      };

    case GET_ACTIVITIES:
      return {
        ...state,
        isSearchingActivities: true,
        searchRequest: action.payload as IActivitySearchRequest,

        // Reset selected activities.
        searchStateProps: {
          ...state.searchStateProps,
          selectedActivities: initialState.searchStateProps.selectedActivities,
        },
      };
    case GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isSearchingActivities: false,
        searchResponse: action.payload as IActivitySearchResponse,
      };
    case GET_ACTIVITIES_FAILURE:
      return {
        ...state,
        isSearchingActivities: false,
      };
    case RESET_ACTIVITIES_SEARCH_RESPONSE:
      return {
        ...state,
        searchResponse: undefined,
      };
    case RESET_ACTIVITIES_SEARCH_REQUEST:
      return {
        ...state,
        searchRequest: INITIAL_ACTIVITY_SEARCH_FACETS,
      };
    case RESET_ACTIVITIES_SEARCH_STATE_PROPS:
      return {
        ...state,
        searchStateProps: INITIAL_SEARCH_STATE_PROPS,
      };
    case GET_ACTIVITY:
      return {
        ...state,
        activityDetail: undefined,
        isSearchingActivity: true,
      };
    case GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        activityDetail: action.payload as Activity,
        isSearchingActivity: false,
      };
    case CLEAR_ACTIVITY_DETAIL:
      return {
        ...state,
        activityDetail: undefined,
        error: '',
      };
    case GET_ACTIVITY_FAILURE:
      return {
        ...state,
        error: action.payload.message,
        isSearchingActivity: false,
      };
    case UPDATE_ACTIVITY_SEARCH_REQUEST:
      return {
        ...state,
        searchRequest: action.payload as IActivitySearchRequest,
      };
    case UPDATE_ACTIVITY_SEARCH_STATE_PROPS:
      return {
        ...state,
        searchStateProps: action.payload as IActivitySearchStateProps,
      };
    case DOWNLOAD_ACTIVITIES:
      return {
        ...state,
        isDownloading: true,
      };
    case DOWNLOAD_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activityDownload: action.payload as IArrayBufferDownload,
        isDownloading: false,
      };
    case DOWNLOAD_ACTIVITIES_FAILURE:
      return {
        ...state,
        isDownloading: false,
      };
    case BULK_DELETE_ACTIVITIES:
      return {
        ...state,
        isDeleting: true,
      };
    case BULK_DELETE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case BULK_DELETE_ACTIVITIES_FAILURE:
      return {
        ...state,
        isDeleting: false,
      };
    case DELETE_ACTIVITY: {
      return {
        ...state,
        isDeleting: true,
      };
    }
    case DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case DELETE_ACTIVITY_FAILURE:
      console.error(action.payload);
      return {
        ...state,
        isDeleting: false,
      };
    case UNDELETE_ACTIVITIES: {
      return {
        ...state,
      };
    }
    case UNDELETE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        searchStateProps: {
          ...state.searchStateProps,
          selectedActivities: INITIAL_SEARCH_STATE_PROPS.selectedActivities,
        },
      };
    case UNDELETE_ACTIVITIES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case SET_ACTIVITY_EDIT_INDEX:
      return {
        ...state,
        editIndex: action.payload,
      };
    case GET_NEXT_SEQUENCE_NUMBER:
      return {
        ...state,
      };
    case GET_NEXT_SEQUENCE_NUMBER_SUCCESS:
      return {
        ...state,
        nextSequenceNumber: action.payload,
      };
    case GET_NEXT_SEQUENCE_NUMBER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case SELECT_ACTIVITY_FOR_REVIEW:
      return { ...state, isSelectingForReview: true };
    case SELECT_ACTIVITY_FOR_REVIEW_SUCCESS:
    case SELECT_ACTIVITY_FOR_REVIEW_FAILURE:
      return { ...state, isSelectingForReview: false };
    case EDIT_ACTIVITY_REVIEW_OUTCOME:
      return { ...state, isEditingReviewOutcome: true };
    case EDIT_ACTIVITY_REVIEW_OUTCOME_SUCCESS:
    case EDIT_ACTIVITY_REVIEW_OUTCOME_FAILURE:
      return { ...state, isEditingReviewOutcome: true };
    case GET_ACTIVITY_REVIEW_HISTORY:
      return { ...state, isLoadingReviewHistory: true };
    case GET_ACTIVITY_REVIEW_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingReviewHistory: false,
        reviewHistory: action.payload,
      };
    case GET_ACTIVITY_REVIEW_HISTORY_FAILURE:
      return { ...state, isLoadingReviewHistory: false };
    case EXPORT_ACTIVITIES:
    default:
      return state;
  }
};

export default activitiesReducer;
