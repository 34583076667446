import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { MODAL_CLOSE, MODAL_OPEN } from './types';
import { AppState } from '../index';
import { PARSAction } from 'core/models';

// action creators
export const modalOpenAction = (): Action => {
  return { type: MODAL_OPEN };
};
export const modalCloseAction = (): Action => {
  return { type: MODAL_CLOSE };
};

// Actions
export const openModal = (): ThunkAction<void, AppState, null, PARSAction> => (dispatch) => {
  dispatch(modalOpenAction());
};
export const closeModal = (): ThunkAction<void, AppState, null, PARSAction> => (dispatch) => {
  dispatch(modalCloseAction());
};
