import { AppState } from '../index';
import { BCTSteps, CreditTypesErrors, IBoardSlim, IValidationException } from 'core/models';
import { IBCTBoard } from '../../layouts/pages/bct/types';

export const currentBCTStepSelector = ({ bctState }: AppState): BCTSteps => bctState?.currentStep;
export const isLoadingBCTSelector = ({ bctState }: AppState): boolean => bctState?.isLoading;
export const isErrorBCTSelector = ({ bctState }: AppState): boolean => !!bctState?.error;
export const errorBCTSelector = ({ bctState }: AppState): Error => bctState.error;

export const validationExceptionsSelector = ({ bctState }: AppState): IValidationException[] =>
  bctState.validationExceptions;

export const creditTypesErrorsSelector = ({ bctState }: AppState): CreditTypesErrors => bctState.creditTypesErrors;

export const isFileProcessingSelector = ({ bctState }: AppState): boolean => bctState.isFileProcessing;

export const boardListSelector = ({ bctState }: AppState): IBoardSlim[] => bctState?.boardList;

export const currentBoardSelector = ({ bctState }: AppState): IBCTBoard => bctState?.currentBoard;
