import { AssignmentTurnedIn, BarChart, Person } from '@material-ui/icons';
import classNames from 'classnames';
import { ReactElement } from 'react';

// Components.
import { ReportBlock } from './ReportBlock';

// Store.
import { IReport, ReportGroups } from 'store/report/types';

// Styles.
import styles from './ReportGroup.module.scss';
import { CircularProgress } from '@material-ui/core';

export enum ReportGroupVariants {
  Blue = 'blue',
  Green = 'green',
  Purple = 'purple',
}

const ReportGroupVariantMap: Record<ReportGroups, ReportGroupVariants> = {
  [ReportGroups.Activity]: ReportGroupVariants.Green,
  [ReportGroups.Learner]: ReportGroupVariants.Blue,
  [ReportGroups.None]: ReportGroupVariants.Green,
  [ReportGroups.Program]: ReportGroupVariants.Purple,
};

const ReportGroupIconMap: Record<ReportGroups, ReactElement> = {
  [ReportGroups.Activity]: <AssignmentTurnedIn />,
  [ReportGroups.Learner]: <Person />,
  [ReportGroups.None]: null,
  [ReportGroups.Program]: <BarChart />,
};

interface IReportGroupProps {
  group?: ReportGroups;
  isLoading?: boolean;
  reports?: IReport[];
}

export const ReportGroup = (props: IReportGroupProps): ReactElement => {
  const { group = 'Reports', isLoading = false, reports = [] } = props;
  const icon = ReportGroupIconMap[group];

  return (
    <section className={classNames(styles[ReportGroupVariantMap[group]])}>
      <header className={styles.header}>
        {icon && <span className={styles.icon}>{ReportGroupIconMap[group]}</span>}
        <h2 className={styles.title}>{group === ReportGroups.None ? 'Reports' : group}</h2>
      </header>
      <div className={classNames(styles.content, { [styles.empty]: isLoading || reports.length < 1 })}>
        {isLoading ? (
          <div>
            <CircularProgress />
          </div>
        ) : reports.length > 0 ? (
          reports.map((report, index) => <ReportBlock key={index} report={report} />)
        ) : (
          <div>There are no reports to show.</div>
        )}
      </div>
    </section>
  );
};
