import { ReactElement } from 'react';

// Types
interface IProps {
  uploadPercentage: number;
  uploadMessage: string;
}
export const UploadProgress = ({ uploadPercentage, uploadMessage }: IProps): ReactElement => {
  return (
    <>
      <div className="progress-label">
        <label htmlFor="file">
          {uploadMessage} <span className="percent">{uploadPercentage}%</span>
        </label>
        <progress className="progress-bar" id="file" max="100" value={uploadPercentage}>
          {uploadPercentage}
        </progress>
      </div>
      <p className="file-uploader-note">
        <span>
          You are free to complete other tasks in PARS while your file uploads. We'll let you know when the upload is
          complete.
        </span>
      </p>
    </>
  );
};
