import { Fragment, ReactElement, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import classNames from 'classnames';
import get from 'lodash/get';

// Types
import { IFormInputOption, ITaxonomyTerm } from 'core/models';

import { Box, Typography } from '@material-ui/core';
import _ from 'lodash';

// Context
interface IConditionalCheckboxesProps {
  options?: IFormInputOption[];
  isRequired?: boolean;
  formikKey?: string;
}

const FormikConditionalNestedCheckboxes = (props: IConditionalCheckboxesProps): ReactElement => {
  const { options, isRequired, formikKey } = props;
  const { setFieldValue, values } = useFormikContext();
  const currentList = (get(values, formikKey) as string[]) || [];
  const uniqueIds = new Set(currentList);
  // 4846 - start with default values selected in Formik
  const defaults = options
    .filter((i) => i.isDefault)
    .map((i) => i.Id)
    .sort()
    .join('///');

  useEffect(() => {
    if (!defaults) return;
    // directly calculate default ids
    const updateValues = () => {
      const defaultIds = options.filter((i) => i.isDefault).map((i) => i.Id);
      defaultIds.forEach((id) => {
        if (!uniqueIds.has(id)) {
          uniqueIds.add(id);
        }
      });
      setFieldValue(formikKey, Array.from(uniqueIds));
    };

    updateValues();
  }, [options, defaults]);

  const handleChange = (e, termId) => {
    const isChecked = e.target.checked;
    let updatedList;
    if (isChecked && !currentList.includes(termId)) {
      updatedList = [...currentList, termId];
    } else {
      updatedList = currentList.filter((id) => id !== termId);
    }
    setFieldValue(formikKey, updatedList);
  };

  return (
    <>
      {options?.map(
        (option: IFormInputOption): ReactElement => {
          const { Id, terms, Title } = option;

          return (
            <div id={Title} key={`${Title} Id`}>
              <Fragment key={Id}>
                <label aria-label={Title}>
                  <div className="label-text-container">
                    <div className="caption-text">{Title}</div>
                    {Title && <div className="caption-text" />}
                    &nbsp;
                  </div>
                </label>
                {terms?.map(
                  (subOption: ITaxonomyTerm): ReactElement => {
                    const { id, name } = subOption;
                    return (
                      <Box display="flex" key={id}>
                        <label className={classNames('form-input-checkbox', 'form-input-checkbox-indent')}>
                          <Field
                            name={formikKey}
                            type="checkbox"
                            value={id}
                            required={isRequired}
                            id={`checkbox-${id}`}
                            checked={currentList.includes(id)}
                            onChange={(e) => handleChange(e, id)}
                          />
                          <span className="checkmark" />
                          <Typography variant="srOnly">{name}</Typography>
                        </label>
                        <div className="label-text-container">
                          <div className="label-text">{name}</div>
                        </div>
                      </Box>
                    );
                  },
                )}
              </Fragment>
            </div>
          );
        },
      )}
    </>
  );
};

export default FormikConditionalNestedCheckboxes;
