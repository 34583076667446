import moment from 'moment';
import * as yup from 'yup';

interface IFormModel {
  provider: string;
  startDate: Date;
  endDate: Date;
}

export const buildProviderSubmissionLogsSchema = (): yup.AnyObjectSchema => {
  return yup.object({
    endDate: yup
      .date()
      .required('End Date is a required field')
      .typeError('Invalid date format')
      .test({
        message: 'End date cannot be before start date',
        name: 'endDateBeforeStartDateTest',
        test: (endDate: Date, { resolve }: yup.TestContext<IFormModel>) => {
          const startDate = resolve(yup.ref(`startDate`)) as Date;
          const momentStartDateDate = moment(endDate);
          const momentStartDate = moment(startDate);
          return !momentStartDateDate.isBefore(momentStartDate, 'days');
        },
      })
      .test({
        message: 'End Date cannot be in the future',
        name: 'futureEndDateTest',
        test: (endDate: Date) => {
          const momentEndDate = moment(endDate);
          const today = moment();
          return !today.isBefore(momentEndDate, 'days');
        },
      }),
    startDate: yup
      .date()
      .required('Start Date is a required field')
      .typeError('Invalid date format')
      .test({
        message: 'Start Date cannot be in the future',
        name: 'futureStartDateTest',
        test: (startDate: Date) => {
          const momentStartDate = moment(startDate);
          const today = moment();
          return !today.isBefore(momentStartDate, 'days');
        },
      }),
  });
};
