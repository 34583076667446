import { Action, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

// Core + Store
import { ICountry, IStateAndProvince, PARSAction } from 'core/models';
import { LocationsService } from 'services/LocationsService';

// Actions
import { popToast } from '../toast/actions';

// Utils
import { handleServerError } from 'globals/utils/handleServerError';

// Types
import { AppState } from '../index';
import { errorToastOptions } from '../toast/constants';
import {
  GET_COUNTRIES,
  GET_COUNTRIES_FAILURE,
  GET_COUNTRIES_SUCCESS,
  GET_STATES_AND_PROVINCES,
  GET_STATES_AND_PROVINCES_SUCCESS,
} from './types';

// Actions
export const getStatesAndProvincesAction = (): Action => ({
  type: GET_STATES_AND_PROVINCES,
});

export const getStatesAndProvincesSuccessAction = (statesAndProvinces: IStateAndProvince[]): AnyAction => ({
  payload: statesAndProvinces,
  type: GET_STATES_AND_PROVINCES_SUCCESS,
});

export const getStatesAndProvincesFailureAction = (error: Error): AnyAction => ({
  payload: error,
  type: GET_STATES_AND_PROVINCES_SUCCESS,
});

export const getCountriesAction = (): Action => ({
  type: GET_COUNTRIES,
});

export const getCountriesSuccess = (countries: ICountry[]): AnyAction => ({
  payload: countries,
  type: GET_COUNTRIES_SUCCESS,
});

export const getCountriesFailure = (error: Error): AnyAction => ({
  payload: error,
  type: GET_COUNTRIES_FAILURE,
});

export const getStatesAndProvinces = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(getStatesAndProvincesAction());
  try {
    const statesAndProvinces = await new LocationsService().getStatesAndProvinces();
    await dispatch(getStatesAndProvincesSuccessAction(statesAndProvinces));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getStatesAndProvinces' });
    await dispatch(getStatesAndProvincesFailureAction(error));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const getCountries = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(getCountriesAction());
  try {
    const countries = await new LocationsService().getCountries();
    await dispatch(getCountriesSuccess(countries));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getCountries' });
    await dispatch(getCountriesFailure(error));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};
