import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { Pagination } from '@material-ui/lab';
import { FilterListRounded, KeyboardBackspaceRounded } from '@material-ui/icons';
import noop from 'lodash/noop';

// Components
import BulkActions from 'components/Summary/BulkActions';
import { LearnerSearchTable } from 'components/LearnerSearchTable';
import { NoResultsCard } from 'components/NoResultsCard';
import { LearnerSearchFacets } from 'components/ContinuousImprovement/Facets/LearnerSearchFacets';
import Button from 'components/Button/Button';
import { LearnersByActivityHeader } from 'components/LearnersByActivityHeader';
import { LoadingCards } from 'components/LoadingCard';
import { ToastActions } from './ToastActions';

// Core + Store
import {
  bulkDeleteLearners,
  exportAllLearners,
  getLearnerSearch,
  onUpdateLearnerSearchStateProps,
  resetLearnerSearchResponse,
  updateLearnerSearch,
  updateLearnerSearchStateProps,
} from 'store/learner/actions';
import {
  learnerSearchCurrentPageSelector,
  learnerSearchRequestSelector,
  learnerSearchResponseSelector,
  learnerSearchResultsSelector,
  learnerSearchTotalCountSelector,
  searchLeanerResultsLoadingSelector,
  searchLearnerSelectedSelector,
} from 'store/learner/selectors';
import { Constants } from 'core';
import {
  ActivitySearchResultActivity,
  IActivityLearnerSearchResponse,
  ILearnerSearchActivity,
  ILearnerSearchRequest,
} from 'core/models';
import { activitiesSearchingSelector, searchResponseSelector } from 'store/activity/selectors';
import {
  getActivities,
  resetActivitiesSearchRequestAction,
  resetActivitiesSearchResponseAction,
  resetActivitiesSearchStatePropsAction,
  updateActivitySearchRequest,
} from 'store/activity/actions';
import { closeRail, openRail } from 'store/rail/actions';
import { isRailOpenSelector } from 'store/rail/selectors';

// Hooks
import { useClickEvent } from 'hooks';

// Data + Helpers
import { INITIAL_LEARNER_SEARCH_FACETS } from 'components/ContinuousImprovement/Facets/LearnerSearchFacets/constants';
import { perPageMenuItems } from './perPageMenuItems';
import { sortLearnerMenu } from './sortMenuItems';
import { DOWNLOAD_LEARNERS, DOWNLOAD_FILTERED_LEARNERS, exportMenuItems } from './exportMenuItems';

export const LearnersSummaryDetailPage = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { key } = useParams<{ key: string }>();
  const fireDownloadEvent = useClickEvent({ Event: 'Learner', EventCategory: 'Download' });

  // Redux states.
  const searchResponse: IActivityLearnerSearchResponse = useSelector(learnerSearchResponseSelector);
  const searchRequest: ILearnerSearchRequest = useSelector(learnerSearchRequestSelector);
  const learnerActivities: ILearnerSearchActivity[] = useSelector(learnerSearchResultsSelector);
  const searchLeanerTotalCount = useSelector(learnerSearchTotalCountSelector);
  const selectedLearners: ILearnerSearchActivity[] = useSelector(searchLearnerSelectedSelector);
  const currentPage = useSelector(learnerSearchCurrentPageSelector);
  const isLoading: boolean = useSelector(searchLeanerResultsLoadingSelector);
  const isSearching: boolean = useSelector(activitiesSearchingSelector);
  const activityDetail: ActivitySearchResultActivity = useSelector(searchResponseSelector)?.results?.[0]?.document;
  const isFacetsOpen: boolean = useSelector(isRailOpenSelector);

  // Sorting functions.
  const perPage = (top: number) => dispatch(getLearnerSearch({ top }));
  const sortByBoard = () => dispatch(getLearnerSearch({ sortBy: Constants.SORT_BY_BOARD }));
  const sortByLearnerName = () => dispatch(getLearnerSearch({ sortBy: Constants.SORT_BY_LEARNER_NAME }));
  const sortByLearnerId = () => dispatch(getLearnerSearch({ sortBy: Constants.SORT_BY_LEARNER_ID }));
  const sortByTotalCredits = () => dispatch(getLearnerSearch({ sortBy: Constants.SORT_BY_TOTAL_CREDITS }));
  const sortByStatus = () => dispatch(getLearnerSearch({ sortBy: Constants.SORT_BY_STATUS }));
  const sortByCompletionDate = () => dispatch(getLearnerSearch({ sortBy: Constants.SORT_BY_COMPLETION_DATE }));

  const toggleSortAscending = () => dispatch(getLearnerSearch({ sortAscending: !searchRequest.sortAscending }));

  // Export functions.
  const onExportActiveLearnerActivities = useCallback(() => {
    fireDownloadEvent({ EventAction: DOWNLOAD_LEARNERS });

    dispatch(exportAllLearners({ activityId: activityDetail.id }));
  }, [dispatch, fireDownloadEvent, searchRequest]);

  const onExportFilteredLearnerActivities = useCallback(() => {
    fireDownloadEvent({ EventAction: DOWNLOAD_FILTERED_LEARNERS });

    const { activityGuid, ...rest } = searchRequest;
    dispatch(exportAllLearners({ activityId: activityDetail.id, ...rest }));
  }, [dispatch, fireDownloadEvent, searchRequest]);

  // Pagination functions.
  const goToPage = (_, newPage: number) => dispatch(onUpdateLearnerSearchStateProps({ page: newPage }));

  const returnToSummaryPage = () => history.push('/learners/summary');

  // Fetch table data + activity data.
  useEffect(() => {
    const getActivityDetail = async (): Promise<void> => {
      await Promise.all([
        dispatch(getLearnerSearch({ activityGuid: key }, false)),
        dispatch(
          updateActivitySearchRequest({
            keys: [key],
            top: 1,
          }),
        ),
      ]);
    };

    // Make the API fetch.
    getActivityDetail().then(() => dispatch(getActivities()));

    // Reset the activity and summary on unmount.
    return async () => {
      await Promise.all([
        dispatch(updateLearnerSearch(INITIAL_LEARNER_SEARCH_FACETS)),
        dispatch(resetLearnerSearchResponse()),
        dispatch(resetActivitiesSearchRequestAction()),
        dispatch(resetActivitiesSearchResponseAction()),
        dispatch(resetActivitiesSearchStatePropsAction()),
        dispatch(updateLearnerSearchStateProps({ page: 1 })),
      ]).then(noop);
    };
  }, [dispatch, key]);

  // Show this when we're waiting for results.
  const isPending = (isLoading || isSearching) && <LoadingCards />;

  const paginationCount: number =
    searchResponse && searchRequest?.top ? Math.ceil(searchLeanerTotalCount / searchRequest.top) : 1;

  return (
    <>
      <aside className={classNames('rail-container rail-container--full', { open: isFacetsOpen })}>
        <LearnerSearchFacets
          handleClose={() => dispatch(closeRail())}
          initialValues={INITIAL_LEARNER_SEARCH_FACETS}
          forcedFilter={{ activityGuid: key }}
        />
      </aside>
      <section className="summary-container">
        <div className="page-navigation">
          <Button className="tertiary" onClick={returnToSummaryPage} component={Link}>
            <KeyboardBackspaceRounded className="tertiary-icon-back" />
            Back to Activity Learner Summary
          </Button>
        </div>

        {/* Render the main content. */}
        {activityDetail && <LearnersByActivityHeader activityDetail={activityDetail} />}

        {/* Render the facets for mobile. */}
        <div className="summary-refiners mobile">
          <Button className="btn-addfilter" onClick={() => dispatch(openRail())}>
            <FilterListRounded /> Add Filters
          </Button>
        </div>

        {/* Filters the main content. */}
        <BulkActions
          activityDetail={activityDetail}
          count={searchLeanerTotalCount}
          countLabel={`Learner${searchLeanerTotalCount !== 1 ? 's' : ''}`}
          isSingleOrNoResult={searchLeanerTotalCount < 2}
          isNoResults={false}
          downloadMenuProps={exportMenuItems({
            onExportActiveLearnerActivities,
            onExportFilteredLearnerActivities,
          })}
          hasDownloadMenu
          perPageMenuProps={perPageMenuItems({ perPage, searchRequest })}
          sortAscending={searchRequest.sortAscending}
          sortAscendingAction={toggleSortAscending}
          sortMenuProps={sortLearnerMenu({
            sortBy: searchRequest.sortBy,
            sortByBoard,
            sortByCompletionDate,
            sortByLearnerId,
            sortByLearnerName,
            sortByStatus,
            sortByTotalCredits,
          })}
        />
        {isPending}
        {!!learnerActivities.length && !isPending && (
          <LearnerSearchTable
            selectedAll={selectedLearners?.length === searchResponse?.results?.length}
            results={learnerActivities}
            omitActivityColumn
          />
        )}
        {!learnerActivities.length && !isPending && <NoResultsCard />}

        {/* Pagination */}
        {paginationCount > 1 && (
          <Pagination siblingCount={0} count={paginationCount} page={currentPage} onChange={goToPage} />
        )}
      </section>

      {/* Toasts */}
      <ToastActions deleteAction={() => dispatch(bulkDeleteLearners())} />
    </>
  );
};
