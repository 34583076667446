import { ReactElement } from 'react';
import { Grid } from '@material-ui/core';

// Styles
import styles from './NoBlueprintContent.module.scss';

export const NoBlueprintContent = (): ReactElement => {
  return (
    <Grid container>
      <Grid className={styles.container} item xs={12}>
        No blueprint entered yet. Go ahead and upload.
      </Grid>
    </Grid>
  );
};
