import { createSelector } from 'reselect';
import { AxiosError } from 'axios';

import { AppState } from '../index';
import {
  IArrayBufferDownload,
  IActivityLearnerSearchRequest,
  IActivityLearnerSearchResponse,
  IActivityLearnerSearchResult,
  IActivityLearnerSearchStateProps,
  ILearnerActivity,
  ILearnerMatchingModel,
  ILearnerSearchActivity,
  ILearnerMatchResponse,
  IMatchedLearner,
  Facet,
  ILearnerSearchRequest,
} from 'core/models';
import { LearnerState } from './types';
import { transformMatchedLearnerResponse } from './utils';

export const learnerStateSelector = ({ learnerState }: AppState): LearnerState => learnerState;

export const isLearnerCompletionAddedSelector = ({ learnerState }: AppState): boolean =>
  learnerState?.isLearnerCompletionAdded;

export const learnerValidationExceptionSelector = ({ learnerState }: AppState): AxiosError =>
  learnerState?.validationErrorResponse;

/**
 * @function searchLeanerResponseSelector
 * @param learnerState
 */
export const searchLeanerResponseSelector = ({ learnerState }: AppState): IActivityLearnerSearchResponse =>
  learnerState?.learnerSummarySearchResponse;

/**
 * @function searchLearnerRequestSelector
 * @param learnerState
 */
export const searchLeanerRequestSelector = ({ learnerState }: AppState): IActivityLearnerSearchRequest =>
  learnerState?.learnerSummarySearchRequest;

/**
 * @function searchLeanerTotalCountSelector
 * @param learnerState
 */
export const searchLeanerTotalCountSelector = createSelector(
  searchLeanerResponseSelector,
  (searchResponse: IActivityLearnerSearchResponse) => searchResponse?.totalCount,
);

export const isLearnerCreatingSelector = ({ learnerState }: AppState): boolean => learnerState?.isCreating;

/**
 * @function learnerSearchResponseSelector
 * @param learnerState
 */
export const learnerSearchResponseSelector = ({ learnerState }: AppState): IActivityLearnerSearchResponse =>
  learnerState?.learnerSearchResponse;

/**
 * @function learnerSearchRequestSelector
 * @param learnerState
 */
export const learnerSearchRequestSelector = ({ learnerState }: AppState): ILearnerSearchRequest =>
  learnerState?.learnerSearchRequest;

/**
 * @function learnerSearchTotalCountSelector
 * @param learnerState
 */
export const learnerSearchTotalCountSelector = createSelector(
  learnerSearchResponseSelector,
  (searchResponse: IActivityLearnerSearchResponse) => searchResponse?.totalCount,
);

/**
 * @function learnerSearchResultsSelector
 * @param learnerState
 */
export const learnerSearchResultsSelector = createSelector(
  learnerSearchResponseSelector,
  (searchResponse: IActivityLearnerSearchResponse) =>
    searchResponse?.results?.map(({ document }: IActivityLearnerSearchResult) => document) || [],
);

/**
 * @function learnerSearchCurrentPageSelector
 * @param learnerState
 * @returns Current page of learner search pagination
 */
export const learnerSearchCurrentPageSelector = ({ learnerState }: AppState): number =>
  learnerState.learnerSearchStateProps.page;

/**
 * @function learnerSearchFacetsSelector
 * @param learnerState
 */
export const learnerSearchFacetsSelector = createSelector(
  learnerSearchResponseSelector,
  (learnerSearchResponse: IActivityLearnerSearchResponse) => learnerSearchResponse?.facets,
);

export const learnerJointProviderSearchFacetsSelector = createSelector(
  learnerSearchFacetsSelector,
  (facet: Facet) => facet?.['JointProviders/Name'],
);

export const learnerStateSearchFacetsSelector = createSelector(
  learnerSearchFacetsSelector,
  (facet: Facet) => facet?.State,
);

export const learnerCountrySearchFacetsSelector = createSelector(
  learnerSearchFacetsSelector,
  (facet: Facet) => facet?.Country,
);

/**
 * @function searchLearnerStatePropsSelector
 * @param learnerState
 */
export const searchLearnerStatePropsSelector = ({ learnerState }: AppState): IActivityLearnerSearchStateProps =>
  learnerState?.searchStateProps;

/**
 * @function searchLearnerCurrentPageSelector
 * @param learnerState
 * @returns Current page of pagination
 */
export const searchLearnerCurrentPageSelector = createSelector(
  searchLearnerStatePropsSelector,
  (state: IActivityLearnerSearchStateProps) => state.page,
);

/**
 * @function searchLeanerFacetsSelector
 * @param learnerState
 */
export const searchLeanerFacetsSelector = createSelector(
  searchLeanerResponseSelector,
  (searchResponse: IActivityLearnerSearchResponse) => searchResponse?.facets,
);

/**
 * @function searchLeanerResultsSelector
 * @param learnerState
 */
export const searchLeanerResultsSelector = createSelector(
  searchLeanerResponseSelector,
  (searchResponse: IActivityLearnerSearchResponse) =>
    searchResponse?.results?.map(({ document }) => (document as unknown) as ILearnerActivity),
);

export const searchLearnerSelectedSelector = ({ learnerState }: AppState): ILearnerSearchActivity[] =>
  learnerState.learnerSearchStateProps.selectedLearners || [];

/**
 * @function searchLeanerResultsLoadingSelector
 * @param learnerState
 */
export const searchLeanerResultsLoadingSelector = ({ learnerState }: AppState): boolean => learnerState?.isLoading;

/**
 * @function learnerActivityDownloadFileSelector
 * @param learnerState
 */
export const learnerActivityDownloadFileSelector = ({ learnerState }: AppState): IArrayBufferDownload =>
  learnerState?.learnerActivityDownload;

/**
 * @function learnerSearchDownloadFileSelector
 * @param learnerState
 */
export const learnerSearchDownloadFileSelector = ({ learnerState }: AppState): IArrayBufferDownload =>
  learnerState?.learnerSearchDownload;

/**
 * @function isLearnerActivityDownloadingSelector
 * @param learnerState
 */
export const isLearnerActivityDownloadingSelector = ({ learnerState }: AppState): boolean =>
  learnerState?.isDownloading;

/**
 * @function learnerFileSelector
 * @param learnerState
 */
export const learnerFileSelector = ({ learnerState }: AppState): { file: File } => learnerState?.file;

/**
 * @function learnerValidationFileSelector
 * @param learnerState
 */
export const learnerValidationFileSelector = ({ learnerValidationState }: AppState): { file: File } =>
  learnerValidationState?.file;

/**
 * @function uploadPercentageSelector
 * @param activitiesState
 */
export const uploadLearnerValidationPercentageSelector = ({ learnerValidationState }: AppState): number =>
  learnerValidationState?.progressPercentage || 0;

/**
 * @function uploadPercentageSelector
 * @param activitiesState
 */
export const uploadLearnerPercentageSelector = ({ learnerState }: AppState): number =>
  learnerState?.progressPercentage || 0;
/**
 * @function isLearnerMatchingSelector
 * @param learnerState
 * @returns boolean
 */
export const isLearnerMatchingSelector = ({ learnerState }: AppState): boolean => learnerState?.isMatchingLearners;

/**
 * @function getPreviousMatchQuery
 * @param learnerState
 * @returns ILearnerMatchingModel
 */
export const previousMatchQuerySelector = ({ learnerState }: AppState): ILearnerMatchingModel =>
  learnerState?.matchLearnersQuery;

/**
 * @function matchedLearnersSelector
 * @param learnerState
 * @returns ILearnerMatchResponse
 */
export const matchLearnerResponseSelector = ({ learnerState }: AppState): ILearnerMatchResponse[] =>
  learnerState?.matchedLearners;
// Transform the match response
export const matchedLearnersSelector = ({ learnerState }: AppState): IMatchedLearner[] =>
  transformMatchedLearnerResponse(learnerState?.matchedLearners);
