import { ReactElement, useEffect, useMemo } from 'react';
import { AddActivityFormValues } from 'core/models';
import { CONTENT_TAG_CME_ROOT_ID } from 'core/constants';
import { FormService, NoOptionsWarning } from 'services/FormService';
import PillCheckbox from 'components/PillCheckbox/PillCheckbox';
import { useFormikContext } from 'formik';
import { sortBy } from 'lodash';
import FormikConditionalCheckboxes from 'components/forms/FormikConditionalCheckboxes/FormikConditionalCheckboxes';
import { useContentTags } from './contentTagHelpers';
import { useIsFirstRender } from 'hooks';

export interface EditDetailContentTagProps {
  enabled: (values: AddActivityFormValues) => boolean;
  rootId: string;
  title: string;
  topTitle: string;
  childTitle: string;
}

export const EditCMEStateContentTag = ({
  rootId,
  title,
  topTitle,
  childTitle,
  enabled,
}: EditDetailContentTagProps): ReactElement => {
  const { contentTagsById, contentTagsByParentId } = useContentTags();

  const topTags = useMemo(
    () =>
      (contentTagsByParentId[rootId] || []).map((i) => ({
        Id: i.id,
        Title: i.contentTagName,
        Tooltip: i.tooltip,
      })),
    [contentTagsByParentId, rootId],
  );

  const { values, setFieldValue } = useFormikContext<AddActivityFormValues>();
  const topLevelKey = `contentTagsTop-${rootId}`;
  const childKey = 'contentTags';
  const selectedTopTags: string[] = useMemo(() => values[topLevelKey] || [], [values, topLevelKey]);

  // on load, set topLevelKey based on childKey initially
  const isFirstRender = useIsFirstRender();
  useEffect(() => {
    // });
    if (isFirstRender) {
      // first render, set topLevelKey based on childKey
      const childTags: string[] = values[childKey] || [];
      const parentTags = childTags.reduce((acc, tagId) => {
        const tag = contentTagsById[tagId];
        if (!tag) {
          return acc;
        }
        if (!topTags.some((i) => i.Id === tag.parentContentTagId)) {
          return acc;
        }
        if (!acc.includes(tag.parentContentTagId)) {
          acc.push(tag.parentContentTagId);
        }
        return acc;
      }, [] as string[]);
      setFieldValue(topLevelKey, parentTags);
    } else {
      // not first render, remove childKeys not in topLevelKey (ie. if the user deselects a top level item, uncheck it's children)
      const childTags: string[] = (values[childKey] || []).sort();
      const parentTags = selectedTopTags;
      const newChildTags = childTags
        .filter((tag) => parentTags.includes(contentTagsById[tag]?.parentContentTagId))
        .sort();

      // only update the data in formik if they are different
      if (JSON.stringify(childTags) !== JSON.stringify(newChildTags)) {
        setFieldValue(childKey, newChildTags);
      }
    }
  }, [isFirstRender, topTags, childKey, selectedTopTags, setFieldValue, topLevelKey, values, contentTagsById]);

  if (!enabled(values)) {
    return <></>;
  }

  return (
    <div className="form-section">
      <div className="form-group form-group-title">
        {FormService.renderLabel({
          hasGroupTitle: true,
          name: topLevelKey,
          required: false,
          suppressLabel: true,
          title: title,
        })}
        <div className="form-group form-sub-group">
          {FormService.renderLabel({
            name: topLevelKey,
            required: true,
            suppressLabel: true,
            title: topTitle,
          })}
          <div className="form-input" role="group">
            <PillCheckbox name={topLevelKey} items={topTags} />
          </div>
        </div>
        {!!selectedTopTags.length && (
          <div className="form-group form-sub-group">
            {FormService.renderLabel({
              name: topLevelKey,
              required: true,
              suppressLabel: true,
              title: childTitle,
            })}
            {selectedTopTags.map((id) => {
              const childTags = sortBy(
                (contentTagsByParentId[id] || []).map((i) => ({
                  Id: i.id,
                  Title: i.contentTagName,
                  Tooltip: i.tooltip,
                })),
                'Title',
              );
              if (!childTags.length) {
                return <NoOptionsWarning />;
              }
              return (
                <div key={childKey} className="form-input" role="group">
                  <FormikConditionalCheckboxes
                    options={childTags}
                    isRequired={false}
                    formikKey={childKey}
                    showTooltipIcon
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export const EditContentTags = (): ReactElement => {
  return (
    <EditCMEStateContentTag
      rootId={CONTENT_TAG_CME_ROOT_ID}
      title="AMA PRA Category 1 Credit™"
      topTitle="Select the high-level content area(s) that your activity addresses."
      childTitle="Select the specific content area(s) that your activity addresses."
      enabled={(v) => v.hasStateContentTags === 'stateContentTagYes1'}
    />
  );
};
