import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store';
import * as PARS from '../../core';
import { RAIL_CLOSE, RAIL_OPEN } from './types';

// action creators
export const railOpenAction = () => {
  return { type: RAIL_OPEN };
};

export const railCloseAction = () => {
  return { type: RAIL_CLOSE };
};

// thunk actions
export const openRail = (): ThunkAction<void, AppState, null, PARS.Models.PARSAction> => (dispatch) => {
  dispatch(railOpenAction());
};

export const closeRail = (): ThunkAction<void, AppState, null, PARS.Models.PARSAction> => (dispatch) => {
  dispatch(railCloseAction());
};
