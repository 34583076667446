import { ReactElement, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import classNames from 'classnames';
import get from 'lodash/get';
import uniq from 'lodash/uniq';

import styles from './FormikConditionalCheckboxesGrid.module.scss';
// Types
import { IFormInputOption } from 'core/models';

import { Grid, GridSize, Typography } from '@material-ui/core';

// Context
interface IConditionalCheckboxesProps {
  options?: IFormInputOption[];
  isRequired?: boolean;
  formikKey?: string;
}

const FormikConditionalCheckboxesGrid = (props: IConditionalCheckboxesProps): ReactElement => {
  const { options, isRequired, formikKey } = props;
  const { setFieldValue, values } = useFormikContext();

  // 4846 - start with default values selected in Formik
  const defaults = options
    .filter((i) => i.isDefault)
    .map((i) => i.Id)
    .sort()
    .join('///');

  useEffect(() => {
    if (!defaults) return;
    const currentList = (get(values, formikKey) as string[]) || [];
    const defaultIds = uniq(defaults.split('///'));
    setFieldValue(formikKey, uniq([...currentList, ...defaultIds]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaults]);

  return (
    <Grid container spacing={2} className={styles['grid-container']}>
      {options?.map(
        (option: IFormInputOption): ReactElement => {
          const { Id, Title } = option;
          return (
            <Grid item xs={Math.ceil(12 / 5) as GridSize} className={styles['checkbox-container']} key={Id}>
              <label className={classNames('form-input-checkbox', 'form-input-checkbox-indent')}>
                <Field name={formikKey} type="checkbox" value={Id} required={isRequired} />
                <span className="checkmark" />
                <Typography variant="srOnly">{Title}</Typography>
              </label>
              <div className="label-text-container">
                <div className="label-text">{Title}</div>
              </div>
            </Grid>
          );
        },
      )}
    </Grid>
  );
};

export default FormikConditionalCheckboxesGrid;
