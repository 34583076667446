import { BaseApiService } from './baseApiService';
import { IProgramSummary } from './models';

export class ProgramSummaryApiService extends BaseApiService {
  public async get(): Promise<IProgramSummary> {
    return await super.getAsync('/api/ProgramSummaries');
  }

  public async post(payload: IProgramSummary): Promise<void> {
    return await super.postAsync('/api/ProgramSummaries/save', payload);
  }

  public async reEnableProgramSummary(): Promise<void> {
    return await super.putAsync('/api/ProgramSummaries/reEnable', null);
  }
}
