/* eslint-disable sort-keys */
import * as yup from 'yup';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { CircularProgress, Grid, TextField } from '@material-ui/core';
import { KeyboardBackspaceRounded } from '@material-ui/icons';
import classNames from 'classnames';

import Button from 'components/Button/Button';
import { FormikCheckboxField, FormikInputField } from 'components/ContinuousImprovement/PureFormElements';
import Dropdown from 'components/Dropdown/Dropdown';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { Constants } from 'core';
import { ButtonVariant } from 'core/enums';

import { BaseOrganizationUser, OrganizationUser, RollupOrganizationEnums, OrganizationKinds } from 'core/models';

import { handleServerError } from 'globals/utils/handleServerError';
import { CacheService } from 'services';
import { AccountManagerService } from 'services/AccountManagerService';
import { useCountries, useStates } from 'store/locations/hooks';
import { popToast } from 'store/toast/actions';
import { errorToastOptions, successToastOptions } from 'store/toast/constants';
import { getCurrentUser } from 'store/user/actions';

import { useAccountManagerInfo } from '../hooks';

// style
import styles from './AddEditUser.module.scss';
import fieldStyles from 'components/ContinuousImprovement/PureFormElements/FormikFields.module.scss';

interface IAddEditUserModel extends OrganizationUser {
  emailConfirmation: string;
}

// SchemaOf (not SchemaObject) is used for our version of yup - https://github.com/jquense/yup/blob/v0.32.8/docs/typescript.md
const editSchema: yup.SchemaOf<BaseOrganizationUser> = yup.object({
  firstName: yup.string().required('First Name is required'),
  middleName: yup.string(),
  lastName: yup.string().required('Last Name is required'),
  title: yup.string(),
  position: yup.string(),
  credentials: yup.string(),
  useOrganizationAddress: yup.boolean(),
  addressLine1: yup.string(),
  addressLine2: yup.string(),
  addressCity: yup.string(),
  addressState: yup.string(),
  addressZipCode: yup.string(),
  addressCountry: yup.string(),
  phoneCountryCode: yup.string(),
  phoneAreaCode: yup.string(),
  phoneLocal: yup.string(),
  faxCountryCode: yup.string(),
  faxAreaCode: yup.string(),
  faxLocal: yup.string(),
  isPrimaryContact: yup.boolean(),
  isStaff: yup.boolean(),
});

const createSchema = editSchema.concat(
  yup.object({
    email: yup.string().required('Email is required').email('Invalid email'),
    emailConfirmation: yup
      .string()
      .required('Emails must match')
      .oneOf([yup.ref('email')], 'Emails must match'),
  }),
);

export const AccountManagerAddEditUserPage = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { canEdit, organization, currentUserId, isAnccOrganization } = useAccountManagerInfo();

  const isOrganizationAccreditor: boolean = organization.organizationKind === OrganizationKinds.ACCREDITOR;

  const { id } = useParams<{ id: string }>();
  const [existingUser, setExistingUser] = useState<OrganizationUser | null>(null);
  useEffect(() => {
    (async () => {
      if (id) {
        try {
          const user = await AccountManagerService.getOrganizationUser(id);
          setExistingUser(user);
        } catch (error) {
          dispatch(popToast({ ...errorToastOptions, message: <>{handleServerError({ error }).errorMessage}</> }));
          history.push('/account-manager');
        }
      }
    })();
  }, [id, dispatch]);

  // set isStaff based on ANCC or Accreditor
  useEffect(() => {
    isAnccOrganization || isOrganizationAccreditor ? setFieldValue('isStaff', true) : setFieldValue('isStaff', false);
  }, [isAnccOrganization, isOrganizationAccreditor]);

  const formik = useFormik<IAddEditUserModel>({
    enableReinitialize: true,
    initialValues: { useOrganizationAddress: true, ...(existingUser || {}) } as IAddEditUserModel,
    onSubmit: async (values, { resetForm }) => {
      try {
        const {
          firstName,
          middleName,
          lastName,
          title,
          position,
          credentials,
          email,
          useOrganizationAddress,
          addressLine1,
          addressLine2,
          addressCity,
          addressState,
          addressZipCode,
          addressCountry,
          phoneCountryCode,
          phoneAreaCode,
          phoneLocal,
          faxCountryCode,
          faxAreaCode,
          faxLocal,
          isPrimaryContact,
          isStaff,
        } = values;
        const baseReq: BaseOrganizationUser = {
          firstName,
          middleName,
          lastName,
          title,
          position,
          credentials,
          useOrganizationAddress,
          addressLine1,
          addressLine2,
          addressCity,
          addressState,
          addressZipCode,
          addressCountry,
          phoneCountryCode,
          phoneAreaCode,
          phoneLocal,
          faxCountryCode,
          faxAreaCode,
          faxLocal,
          isPrimaryContact,
          isStaff,
        };
        if (id) {
          await AccountManagerService.updateOrganizationUser(id, baseReq);
          dispatch(popToast({ ...successToastOptions, message: <>Successfully updated user for {email}.</> }));
          history.push('/account-manager');
        } else {
          const req = {
            ...baseReq,
            organizationId: organization.id,
            email,
            isStaff,
          };
          await AccountManagerService.createOrganizationUser(req);
          dispatch(popToast({ ...successToastOptions, message: <>Successfully created user for {email}.</> }));

          resetForm();
        }

        if (isPrimaryContact && organization.isPrimaryContact) {
          // we may have lost our rights to edit the organization - reload the current user to make sure
          // if we lost access, we'll get redirected to /account-manager
          CacheService.remove(Constants.CACHE_USER_CURRENT);
          await dispatch(getCurrentUser());
        }
      } catch (error) {
        dispatch(popToast({ ...errorToastOptions, message: <>{handleServerError({ error }).errorMessage}</> }));
      }
    },
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: id ? editSchema : createSchema,
  });
  const { isValid, isSubmitting, submitForm, values, setFieldValue } = formik;

  const countries = useCountries();
  const states = useStates();

  if (!canEdit && !id) {
    return <Redirect to="/account-manager" />;
  }
  if (organization.rollupOrganizationEnum !== RollupOrganizationEnums.NARS) {
    return <Redirect to="/404" />;
  }
  if (id && !existingUser) {
    return <LoadingOverlay isOpen />;
  }
  if (!canEdit && existingUser && existingUser.userId !== currentUserId) {
    return <Redirect to="/account-manager" />;
  }

  const titleOptions = ['Dr.', 'Mr.', 'Mrs.', 'Ms.'].map((i) => ({ id: i, name: i }));

  return (
    <div className="form-container">
      <div className="page-navigation">
        <Link className="tertiary" to="/account-manager">
          <KeyboardBackspaceRounded className="tertiary-icon-back" />
          Account Management
        </Link>
      </div>
      <div className="form-title-container">
        <div className={classNames('title', styles.title)}>
          <h4>{id ? 'Edit' : 'Add New'} User</h4>
        </div>
      </div>
      <FormikProvider value={formik}>
        <div className="static-form">
          <Grid item xs={12} md={9} lg={6} className={styles.input}>
            <label className={fieldStyles.label} htmlFor="organization">
              Organization
            </label>
            <TextField
              id="organization"
              value={organization.organizationName}
              disabled
              variant="outlined"
              className={classNames(fieldStyles.input, styles.disabled)}
            />
            <FormikInputField label="First Name" formikKey="firstName" />
            <FormikInputField label="Middle Name" formikKey="middleName" />
            <FormikInputField label="Last Name" formikKey="lastName" />
            <label className={fieldStyles.label} htmlFor="title">
              Title
            </label>
            <Dropdown items={titleOptions} name="title" placeholder="Title" />
            <FormikInputField label="Position" formikKey="position" />
            <FormikInputField label="Credentials" formikKey="credentials" />
            {id ? (
              <>
                <label className={fieldStyles.label} htmlFor="email">
                  Email
                </label>
                <TextField
                  id="email"
                  value={values.email}
                  disabled
                  variant="outlined"
                  className={classNames(fieldStyles.input, styles.disabled)}
                />
              </>
            ) : (
              <>
                <FormikInputField label="Email Address" formikKey="email" />
                <FormikInputField label="Re-enter Email Address" formikKey="emailConfirmation" />
              </>
            )}
          </Grid>
          <Grid item xs={12} md={9} lg={6} className={styles['checkbox-group']}>
            <FormikCheckboxField
              name="Primary Contact"
              formikKey="isPrimaryContact"
              checked={values.isPrimaryContact}
              disabled={!canEdit}
              onChange={(e) => setFieldValue('isPrimaryContact', e.target.checked)}
            />
          </Grid>
          <h5 className={styles.address}>Address</h5>
          <Grid item xs={12} md={9} lg={6} className={styles['checkbox-group']}>
            <FormikCheckboxField
              name="Same as Organization"
              formikKey="useOrganizationAddress"
              checked={values.useOrganizationAddress}
              onChange={(e) => setFieldValue('useOrganizationAddress', e.target.checked)}
            />
          </Grid>
          {values.useOrganizationAddress ? null : (
            <Grid item xs={12} md={9} lg={6} className={styles.input}>
              <FormikInputField label="Address Line 1" formikKey="addressLine1" />
              <FormikInputField label="Address Line 2" formikKey="addressLine2" />
              <FormikInputField label="Address City" formikKey="addressCity" />
              <label className={fieldStyles.label} htmlFor="addressState">
                Address State
              </label>
              <Dropdown
                items={states}
                name="addressState"
                placeholder="Address State"
                idProp="isoStateCode"
                labelProp="stateName"
              />
              <FormikInputField label="Address ZipCode" formikKey="addressZipCode" />
              <label className={fieldStyles.label} htmlFor="addressCountry">
                Address Country
              </label>
              <Dropdown
                items={countries}
                name="addressCountry"
                placeholder="Address Country"
                idProp="isoCountryCode"
                labelProp="countryName"
              />
              <FormikInputField label="Phone Country Code" formikKey="phoneCountryCode" />
              <FormikInputField label="Phone Area Code" formikKey="phoneAreaCode" />
              <FormikInputField label="Phone Local" formikKey="phoneLocal" />
              <FormikInputField label="Fax Country Code" formikKey="faxCountryCode" />
              <FormikInputField label="Fax Area Code" formikKey="faxAreaCode" />
              <FormikInputField label="Fax Local" formikKey="faxLocal" />
            </Grid>
          )}
          <Grid item xs={12} md={9} lg={6}>
            <div className={styles['submit-button-container']}>
              <Button
                disabled={!isValid || isSubmitting}
                onClick={submitForm}
                startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
                variant={ButtonVariant.Primary}
              >
                Save Changes
              </Button>
            </div>
          </Grid>
        </div>
      </FormikProvider>
    </div>
  );
};
