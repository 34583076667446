import { DEFAULT_TIMEZONE, TAGS_ENUM, TAXONOMY_BATCH_MESSAGES_ID, TAXONOMY_BATCH_ROOT_ID } from 'core/constants';
import { IProviderFileBase } from 'core/models';
import moment from 'moment';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { taxonomyBatchMessagesSelector, taxonomyBatchSelector } from 'store/taxonomy/selectors';
import styles from './batchFileProgress.module.scss';
import { filterTaxonomyByRollupOrganizationEnum } from 'globals/utils/filterTaxonomyByOrg';
import { rollupOrganizationEnumSelector } from 'store/user/selectors';

interface IProps {
  file: IProviderFileBase;
}

// to avoid each copy of `BatchFileProgress` loading it's own copy of taxonomy, the control using it in a loop needs to include this control outside it
export const BatchTaxonomyLoader = (): ReactElement => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTaxonomyTermById(TAXONOMY_BATCH_ROOT_ID));
    dispatch(getTaxonomyTermById(TAXONOMY_BATCH_MESSAGES_ID));
  }, [dispatch]);
  return <></>;
};

interface IStatusResult {
  message: string;
  pctComplete: number;
  pctCompleteMessage: string;
}

const StatusJustStarted: IStatusResult = { message: 'File processing...', pctComplete: 0, pctCompleteMessage: '0%' };

export const BatchFileProgress = (props: IProps): ReactElement => {
  const { recordsInFile, recordsParsed, recordsProcessed, updatedDate } = props.file;
  const taxonomyBatch = useSelector(taxonomyBatchSelector);
  const taxonomyBatchMessages = useSelector(taxonomyBatchMessagesSelector);
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);

  const lastUpdated = moment(updatedDate);
  const lastUpdatedAgo = moment().diff(lastUpdated);
  const lastUpdatedAgoString = moment.duration(lastUpdatedAgo).humanize();
  const lastUpdatedDefaultTz = moment(lastUpdated).tz(DEFAULT_TIMEZONE);

  const parsed = recordsParsed ?? 0;
  const processed = recordsProcessed ?? 0;
  const records = Math.max(recordsInFile ?? 0, parsed, processed);

  function getStatus(): IStatusResult {
    // pctComplete will be a number from 0 to 99 indicating how complete we think we are
    //   this is a pretty wild guess, since later records are more expensive than early ones, and some files are parse-heavy and some are processing-heavy
    if (records === 0) {
      return StatusJustStarted;
    }
    if (processed >= records) {
      return { message: 'Finalizing processing...', pctComplete: 99, pctCompleteMessage: `99% of ${records} rows` };
    }
    const parsingFraction = 0.15; // parsing should be considered to be 15% of the time
    const processingFraction = 1 - parsingFraction;
    if (processed > 0) {
      // we intentionally use `records` in the numerator here, as we know we've parsed all the records if we've starting processing
      const pctComplete = ((records * parsingFraction + processed * processingFraction) / records) * 100;
      return {
        message: `Processed ${processed} records of ${records}...`,
        pctComplete,
        pctCompleteMessage: `${pctComplete.toFixed()}% of ${records} rows`,
      };
    }
    if (parsed > 0) {
      const pctComplete = ((parsed * parsingFraction) / records) * 100;
      return {
        message: `Parsed ${parsed} records of ${records}...`,
        pctComplete,
        pctCompleteMessage: `${pctComplete.toFixed()}% of ${records} rows`,
      };
    }
    return StatusJustStarted;
  }
  function getWarning(): ReactElement | null {
    if (!taxonomyBatch || !taxonomyBatchMessages) {
      return null;
    }

    const terms = filterTaxonomyByRollupOrganizationEnum(taxonomyBatch.terms, rollupOrganizationEnum);
    const warnUpdateSecondsString = terms.filter((i) => i.tag === TAGS_ENUM.BATCH__WARN_IF_NO_STATUS_UPDATE_SECONDS)[0]
      ?.description;
    const warnUpdateMessage = terms.filter(
      (i) => i.tag === TAGS_ENUM.BATCH__MESSAGES__WARN_IF_NO_STATUS_UPDATE_MESSAGE,
    )[0]?.description;
    if (!warnUpdateSecondsString || !warnUpdateMessage) {
      return null;
    }

    try {
      const warnUpdateSeconds = parseInt(warnUpdateSecondsString, 10);
      if (lastUpdatedAgo / 1000 > warnUpdateSeconds) {
        return <div className={styles['warn-no-status-update']}>{warnUpdateMessage}</div>;
      }
    } catch {
      return null;
    }

    return null;
  }

  const status = getStatus();
  const warning = getWarning();

  return (
    <div className={styles['progress-container']}>
      <div
        className={styles['progress-label']}
        title={`Last updated ${lastUpdatedAgoString} ago at ${lastUpdatedDefaultTz.toLocaleString()}`}
      >
        <label htmlFor="progress-bar">
          <span className={styles.percent}>{status.pctCompleteMessage}</span>
        </label>
        <progress
          className={styles['progress-bar']}
          id="progress-bar"
          max="100"
          value={status.pctComplete}
          aria-describedby="progress-label"
        >
          {status.pctComplete}%
        </progress>
      </div>
      {warning}
    </div>
  );
};
