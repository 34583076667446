import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store';
import * as PARS from '../../core';
import { FAQ_LOAD, FAQ_CLOSE, FAQ_OPEN } from './types';

// action creators
export const loadFaq = (faqs: any[]) => {
  // TODO replace with FAQ model
  return {
    type: FAQ_LOAD,
    payload: faqs,
  };
};

export const faqOpenAction = () => {
  return { type: FAQ_OPEN };
};

export const faqCloseAction = () => {
  return { type: FAQ_CLOSE };
};

// thunk actions
export const openFaq = (): ThunkAction<void, AppState, null, PARS.Models.PARSAction> => (dispatch) => {
  dispatch(faqOpenAction());
};

export const closeFaq = (): ThunkAction<void, AppState, null, PARS.Models.PARSAction> => (dispatch) => {
  dispatch(faqCloseAction());
};
