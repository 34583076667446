import { ChangeEvent, ReactElement, useState } from 'react';
import kebabCase from 'lodash/kebabCase';
import { Link, useHistory } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import sumBy from 'lodash/sumBy';
import { Description, PersonAddRounded } from '@material-ui/icons';

// Components
import { UtilityBtns } from '../ResultCard/UtilityBtns';
import { Modal } from 'components/ContinuousImprovement';
import { DeleteActivityForm, SelectForReviewForm, UpdateLearnersForm, CopyActivityForm } from '../../forms';
import { ActivityReviewOutcomes } from '../ActivityReviewOutcomes';
import { ActivityReviewHistory } from '../ActivityReviewHistory';
import CustomTooltip from 'components/Tooltip/Tooltip';
import Button from 'components/Button/Button';
import { DeselectForReviewForm } from 'components/forms/templates/DeselectForReviewForm';

// Styles
import './ActivityResultCard.module.scss';

// Store + Core
import { ActivitySearchResultActivity, IActivityCompletionBoard, RollupOrganizationEnums } from 'core/models';
import { getActivities, undeleteActivities } from 'store/activity/actions';
import {
  isAccreditorUserSelector,
  isBoardUserSelector,
  isStaffUserSelector,
  rollupOrganizationEnumSelector,
} from 'store/user/selectors';

// Types
import { ButtonVariant, StatusEnum } from 'core/enums';
import { ACTIVITY_DISPLAY_DATE_FORMAT } from 'core/constants';
import { ActivityService } from 'services/ActivityService';

export interface IProps {
  activity: ActivitySearchResultActivity;
  dates?: string;

  onDelete(): void;

  onReview(): void;

  onSelect({ e, activity }: { e: ChangeEvent<HTMLInputElement>; activity: ActivitySearchResultActivity }): void;

  isSelected: boolean;
}

export const ActivityResultCard = (props: IProps): ReactElement => {
  const { activity, onDelete, onReview, onSelect, isSelected } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const [isTotalLearnersModalOpen, setIsTotalLearnersModalOpen] = useState<boolean>(false);
  const [isCopyActivityModalOpen, setIsCopyActivityModalOpen] = useState<boolean>(false);
  const [isDeleteActivityModalOpen, setIsDeleteActivityModalOpen] = useState<boolean>(false);
  const [isSelectForReviewModalOpen, setIsSelectForReviewModalOpen] = useState<boolean>(false);
  const [isDeselectForReviewModalOpen, setIsDeselectForReviewModalOpen] = useState<boolean>(false);
  const [isEditOutcomeModalOpen, setIsEditOutcomeModalOpen] = useState<boolean>(false);
  const [isReviewHistoryModalOpen, setIsReviewHistoryModalOpen] = useState<boolean>(false);

  const {
    city,
    completionBoards,
    endDate,
    isCommercialSupport: hasCommercialSupport,
    hasLearners,
    id,
    internalId,
    isDeleted,
    isMips,
    isMoc,
    isRems,
    isSelectedForReviewStaffOrAccreditor,
    key,
    learnerCounts,
    providerName,
    providership,
    startDate,
    stateOrProvince,
    status,
    title,
    type,
    isIndividualActivity,
    iaOrganizationName,
  } = activity;

  // Selectors.
  const isBoardUser: boolean = useSelector(isBoardUserSelector);
  const isStaffUser: boolean = useSelector(isStaffUserSelector);
  const isAccreditorUser: boolean = useSelector(isAccreditorUserSelector);

  const isStaffOrAccreditor: boolean = isStaffUser || isAccreditorUser;
  const isClosed: boolean = status === StatusEnum.CLOSED;

  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  const isNars = rollupOrganizationEnum === RollupOrganizationEnums.NARS;

  const onRestore = () => dispatch(undeleteActivities([key]));

  const onCloseTotalLearnersModal = async (): Promise<void> => {
    await dispatch(getActivities(null, false));
    setIsTotalLearnersModalOpen(false);
  };

  const onCloseCopyActivityModal = (): void => {
    setIsCopyActivityModalOpen(false);
  };

  const onCloseDeleteActivityModal = (): void => {
    setIsDeleteActivityModalOpen(false);
  };

  const onCloseReviewHistoryModal = (): void => {
    setIsReviewHistoryModalOpen(false);
  };

  const onCloseSelectForReviewModal = (): void => {
    setIsSelectForReviewModalOpen(false);
  };

  const onCloseDeselectForReviewModal = (): void => {
    setIsDeselectForReviewModalOpen(false);
  };

  const onCloseEditOutcomeModal = (): void => {
    setIsEditOutcomeModalOpen(false);
  };

  const today = moment();
  const isUpdateLearnersDisabled: boolean = today.isBefore(startDate, 'day');

  const dates = () => {
    if (startDate && endDate) {
      const dateFormat = ACTIVITY_DISPLAY_DATE_FORMAT;
      const startDateMoment = moment(startDate);
      const endDateMoment = moment(endDate);
      const formattedStartDate = startDateMoment.format(dateFormat);
      const formattedEndDate = endDateMoment.format(dateFormat);
      return `${formattedStartDate} - ${formattedEndDate}`;
    } else {
      return null;
    }
  };
  const activityDates: string = dates();

  const programs: string = [isMoc && 'MOC', isRems && 'REMS', isMips && 'MIPS'].filter(Boolean).join(', ');

  const onCopyCallback = async (): Promise<void> => {
    const act = await ActivityService.getById(key);
    if (act != null) history.push('/activities/add-activity/new', { params: act });
  };

  /**
   * Renders the provider and individual activity organization name grid items.
   * @returns {ReactElement} The grid item component.
   */
  const renderProviderAndIaOrganizationNames = (): ReactElement => {
    const ProviderGridItem = (
      <Grid item>
        <div className="meta-activity">Provider: {providerName}</div>
      </Grid>
    );

    const IndividualActivityGridItem = (
      <Grid item>
        <div className="meta-activity">Individual Activity: {iaOrganizationName}</div>
      </Grid>
    );

    if (!isNars) {
      return <>{providerName && ProviderGridItem}</>;
    }

    if (isStaffOrAccreditor) {
      return (
        <>
          {providerName && ProviderGridItem}
          {isIndividualActivity && IndividualActivityGridItem}
        </>
      );
    }

    return <>{!isIndividualActivity && providerName ? ProviderGridItem : IndividualActivityGridItem}</>;
  };

  return (
    <div className="summary-results-card">
      <div className="summary-results-card-title">
        <div className="summary-results-card-status">
          <div className={classNames('status', `status--${kebabCase(status?.toLowerCase())}`)}>
            <div className="label-text">{status}</div>
          </div>
        </div>
        <div className="summary-results-card--checkbox">
          <div className="label-text-container">
            {isBoardUser ? (
              <Box ml={4}>
                <Link to={`/activities/detail/${key}`}>{title}</Link>
              </Box>
            ) : (
              <label className="label-text" htmlFor={id}>
                <input id={id} type="checkbox" checked={isSelected} onChange={(e) => onSelect({ activity, e })} />
                <span className="checkmark">
                  <Link aria-label={`View details of ${title}: ${id}`} to={`/activities/detail/${key}`}>
                    {title}
                  </Link>
                </span>
              </label>
            )}
          </div>
        </div>
      </div>
      <div className="summary-results-card-info">
        <div className="meta meta--pipe-separated">
          {activityDates && <div className="meta-date">{activityDates}</div>}
          {type && <div className="meta-type">{type}</div>}
          {city && stateOrProvince && <div className="meta-location">{`${city}, ${stateOrProvince}`}</div>}
        </div>
        <Grid className="meta meta--caption-text" container justify="space-between" direction="row">
          <Grid item xs={8}>
            <Grid container spacing={2}>
              {id && (
                <Grid item>
                  <div className="meta-activity">Activity ID: {id}</div>
                </Grid>
              )}
              {renderProviderAndIaOrganizationNames()}
              {internalId && (
                <Grid item>
                  <div>Internal ID: {internalId}</div>
                </Grid>
              )}
              {providership && (
                <Grid item>
                  <div>{providership}</div>
                </Grid>
              )}
              {hasCommercialSupport && (
                <Grid item>
                  <div>Commercial Support Received</div>
                </Grid>
              )}
              {programs && (
                <Grid item>
                  <div className="meta-programs">Collaborations: {programs}</div>
                </Grid>
              )}
              <Grid item>
                <div className="meta-programs">Learners: {sumBy(learnerCounts, 'count')}</div>
              </Grid>
              {!!completionBoards?.length && (
                <Grid item>
                  <div className="meta-programs">
                    MOC Learners:{' '}
                    {completionBoards
                      ?.reduce(
                        (acc, { count, name }: IActivityCompletionBoard): string[] => acc.concat(`${name}: ${count}`),
                        [],
                      )
                      .join(', ')}
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item style={{ marginRight: '30px' }}>
            {!isBoardUser ? (
              <div className="summary-results-card-cta">
                {isUpdateLearnersDisabled ? (
                  <CustomTooltip
                    aria-label="Cannot update learners before activity start date."
                    tooltipText="Cannot update learners before activity start date."
                  >
                    <span>
                      <Button
                        disabled={isUpdateLearnersDisabled}
                        onClick={(): void => setIsTotalLearnersModalOpen(true)}
                        variant={ButtonVariant.Label}
                      >
                        <PersonAddRounded />
                        Update Learners
                      </Button>
                    </span>
                  </CustomTooltip>
                ) : (
                  <Button
                    disabled={isUpdateLearnersDisabled}
                    onClick={(): void => setIsTotalLearnersModalOpen(true)}
                    variant={ButtonVariant.Label}
                  >
                    <PersonAddRounded />
                    Update Learners
                  </Button>
                )}
                {isStaffOrAccreditor && (
                  <Button onClick={(): void => setIsReviewHistoryModalOpen(true)} variant={ButtonVariant.Label}>
                    <Description />
                    Review History
                  </Button>
                )}
              </div>
            ) : (
              <div className="summary-results-card-cta" />
            )}
          </Grid>
        </Grid>
      </div>
      <div className="summary-results-card-utility">
        <UtilityBtns
          activityId={id}
          id={key}
          isClosed={isClosed}
          isDeleted={isDeleted}
          isDisabled={isBoardUser || hasLearners}
          isSelectedForReviewStaffOrAccreditor={isSelectedForReviewStaffOrAccreditor}
          isStaffOrAccreditor={isStaffOrAccreditor}
          onCopy={() => setIsCopyActivityModalOpen(true)}
          onDelete={() => setIsDeleteActivityModalOpen(true)}
          onDeselectForReview={() => setIsDeselectForReviewModalOpen(true)}
          onEnterOutcome={() => setIsEditOutcomeModalOpen(true)}
          onRestore={onRestore}
          onSelectForReview={() => setIsSelectForReviewModalOpen(true)}
          title={title}
        />
        {/* Modal */}
        <Modal isOpen={isTotalLearnersModalOpen} onClose={onCloseTotalLearnersModal} title="Total Learners">
          <UpdateLearnersForm
            activityId={id}
            id={key}
            onClose={onCloseTotalLearnersModal}
            startDate={startDate}
            endDate={endDate}
            title={title}
          />
        </Modal>
        <Modal isOpen={isDeleteActivityModalOpen} onClose={onCloseDeleteActivityModal} title="Delete Activity">
          <DeleteActivityForm
            activityId={id}
            callback={onDelete}
            id={key}
            onClose={onCloseDeleteActivityModal}
            startDate={startDate}
            endDate={endDate}
            title={title}
          />
        </Modal>
        <Modal isOpen={isCopyActivityModalOpen} onClose={onCloseCopyActivityModal} title="Copy Activity">
          <CopyActivityForm
            activityId={id}
            callback={onCopyCallback}
            id={key}
            onClose={onCloseCopyActivityModal}
            startDate={startDate}
            endDate={endDate}
            title={title}
          />
        </Modal>
        {isStaffOrAccreditor && (
          <>
            <Modal
              isOpen={isSelectForReviewModalOpen}
              onClose={onCloseSelectForReviewModal}
              title="Select Activity For Review"
            >
              <SelectForReviewForm activityIds={[key]} onClose={onCloseSelectForReviewModal} onSuccess={onReview} />
            </Modal>
            <Modal
              isOpen={isDeselectForReviewModalOpen}
              onClose={onCloseDeselectForReviewModal}
              title="Deselect Activity For Review"
            >
              <DeselectForReviewForm id={key} onClose={onCloseDeselectForReviewModal} onSuccess={onReview} />
            </Modal>
          </>
        )}
        <Modal isOpen={isEditOutcomeModalOpen} onClose={onCloseEditOutcomeModal} title="Outcome of Reviewed Activities">
          <ActivityReviewOutcomes id={key} onClose={onCloseEditOutcomeModal} onSave={onCloseEditOutcomeModal} />
        </Modal>
        <Modal isOpen={isReviewHistoryModalOpen} onClose={onCloseReviewHistoryModal} title="Activity Review History">
          <ActivityReviewHistory
            activityId={id}
            id={key}
            onClose={onCloseReviewHistoryModal}
            providerName={providerName}
            title={title}
          />
        </Modal>
      </div>
    </div>
  );
};
