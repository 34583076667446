import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { FormikProvider, FormikValues, useFormik } from 'formik';

// Components
import Button from 'components/Button/Button';

// Actions
import { bulkDeleteActivities } from 'store/activity/actions';

// Core + Store
import { ActivitySearchResultActivity } from 'core/models';
import { popToast } from 'store/toast/actions';
import { infoToastOptions } from 'store/toast/constants';

// Hooks.
import { useClickEvent } from 'hooks';

type IProps = {
  callback?(): void;
  onClose?(): void;
  selectedActivities: ActivitySearchResultActivity[];
};

const PROCEED_LABEL = 'Yes, Delete';
const CANCEL_LABEL = "No, Don't Delete";

export const BulkDeleteActivitiesForm = (props: IProps): ReactElement => {
  const dispatch = useDispatch();
  const { callback, onClose, selectedActivities } = props;

  const fireEvent = useClickEvent({ Event: 'Activity', EventCategory: 'Bulk Activity' });

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (_: FormikValues, { setSubmitting }) => {
      const selectedLength = selectedActivities.length;
      const success = await dispatch(
        bulkDeleteActivities(
          selectedActivities
            .map((activity: ActivitySearchResultActivity) => activity.key || '')
            .filter((id) => id !== ''),
        ),
      );
      // We are done submitting.
      await setSubmitting(false);

      if (!success) {
        return;
      }

      // Close the modal.
      await onClose();

      // Update the redux state.
      await callback?.();

      // Show a toast to the user.
      await dispatch(
        popToast({
          ...infoToastOptions,
          message: <>{`${selectedLength} ${selectedLength !== 1 ? 'Activities' : 'Activity'} Deleted`}</>,
        }),
      );
    },
  });

  const { handleSubmit, isSubmitting } = formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        {`Are you sure you want to delete ${selectedActivities.length} item(s)?`}
        <div className="modal-button-row">
          <Button
            className="primary"
            disabled={isSubmitting}
            onClick={() => fireEvent({ EventAction: 'Delete', EventLabel: PROCEED_LABEL })}
            type="submit"
          >
            {PROCEED_LABEL}
          </Button>
          <Button
            className="secondary"
            disabled={isSubmitting}
            onClick={() => {
              fireEvent({ EventAction: 'Delete', EventLabel: CANCEL_LABEL });
              onClose();
            }}
          >
            {CANCEL_LABEL}
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};
