import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormGroup, FormLabel, Grid, RadioGroup } from '@material-ui/core';
import { debounce, get, filter } from 'lodash';

// Components
import ButtonRow from 'layouts/pages/activity/forms/buttonRow';
import {
  FormikBooleanRadioField,
  FormikInputField,
  FormikNumberField,
  FormikRadioField,
} from 'components/ContinuousImprovement/PureFormElements';
import { Multi } from 'components/Typeahead/Multi';
import { LoadingCards } from 'components/LoadingCard';
import { ILabelType } from 'components/Typeahead/Typeahead';

// Styles
import pageStyles from '../../BCTPageStyles.module.scss';
import formStyles from '../FormStyles.module.scss';

// Store + Core
import { getBoardById, postBCTStep } from 'store/bct/actions';
import { currentBoardSelector, isLoadingBCTSelector, validationExceptionsSelector } from 'store/bct/selectors';
import { IStateAndProvince, IValidationException } from 'core/models';
import { getStatesAndProvinces } from 'store/locations/actions';
import { statesAndProvincesSelector } from 'store/locations/selectors';
import { LocationsService } from 'services/LocationsService';

// Utils
import { initialOrganizationDetailsForm, IOrganizationDetails } from './initialOrganizationDetailsForm';
import { handleValidationException } from 'globals/utils/handleValidationException';

// Types
import { BoardIntegrationTypes, BoardTypes, IBCTBoard, IBCTStepProps, StatusTypes } from '../../types';

export const OrganizationDetailsForm = (props: IBCTStepProps): ReactElement => {
  const { nextStep } = props;
  const [fsmbAliases, setFsmbAliases] = useState<ILabelType[]>([]);

  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  // Selectors.
  const currentBoard: IBCTBoard = useSelector(currentBoardSelector);
  const isLoading: boolean = useSelector(isLoadingBCTSelector);
  const validationExceptions: IValidationException[] = useSelector(validationExceptionsSelector);
  const statesAndProvinces: ILabelType[] = useSelector(statesAndProvincesSelector)?.map(
    ({ isoStateCode, stateName }: IStateAndProvince): ILabelType => ({
      id: isoStateCode,
      label: stateName,
    }),
  );

  // Fetch.
  useEffect(() => {
    dispatch(getBoardById(id));
  }, [dispatch, id]);

  // Form setup.
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialOrganizationDetailsForm(currentBoard),
    onSubmit: async (values: IOrganizationDetails): Promise<void> => {
      await dispatch(
        postBCTStep({
          nextStep,
          values: { ...currentBoard, ...values },
        }),
      );

      // Scroll to top when done.
      window.scroll(0, 0);
    },
  });

  const { handleSubmit, isValid, isSubmitting, values, setFieldValue, setFieldError } = formik;

  useEffect(() => {
    // Set field specific errors.
    validationExceptions?.forEach((validationException: IValidationException): void => {
      const [formikKey, message] = handleValidationException(validationException);

      setFieldError(formikKey, message);
    });
  }, [setFieldError, validationExceptions]);

  // Note: StateBoard and Licensing board are interchangeable terms.
  const isStateBoard: boolean = get(values, 'type') === BoardTypes.STATE_BOARD;

  const boardFsmbAliases: string[] = get(values, 'boardFsmbAliases', []);
  const states: string[] = get(values, 'states', []);

  // Get States and Provinces if `StateBoard` is selected.
  useEffect(() => {
    if (isStateBoard && !statesAndProvinces) {
      dispatch(getStatesAndProvinces());
    }
  }, [dispatch, isStateBoard, statesAndProvinces]);

  // Show this while data is loading.
  if (isLoading) return <LoadingCards />;

  const onChangeState = (option: string): void => {
    setFieldValue('state', option);
  };

  const onChangeFSMB = debounce(async (queryString: string): Promise<void> => {
    const fsmbAliases: string[] = await new LocationsService().getFSMBAliases(queryString || null);

    // Set the string array response to local-state in `ILabelType` shape.
    setFsmbAliases(fsmbAliases.map((alias: string): ILabelType => ({ id: alias, label: alias })));
  }, 700);

  // Set the initial states and values to use the full name.
  const initialStates = states
    .map((state: string): ILabelType[] => {
      const found: ILabelType[] = filter(statesAndProvinces, { id: state });

      return found || null;
    })
    .flat();

  return (
    <>
      <h2 className={pageStyles.subtitle}>Let's start with the general information about the organization</h2>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <div className={formStyles['form-card']}>
            {/*********************/}
            <fieldset>
              <legend>Enter the organization's acronym, name, and ID</legend>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                  <FormikInputField label="Acronym" formikKey="abbreviation" placeholder="Acronym" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikInputField
                    readOnly
                    label="Organization Name"
                    formikKey="name"
                    placeholder="Organization Name"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormikInputField
                    readOnly
                    label="Organization Id"
                    formikKey="businessId"
                    placeholder="Organization ID"
                  />
                </Grid>
              </Grid>
            </fieldset>
            {/*********************/}
            <fieldset className={formStyles['no-divider']}>
              <legend>Select the organization's status.</legend>
              <RadioGroup aria-label="activity status" name="activityStatus">
                <FormLabel component="div">Activity Status</FormLabel>
                <Grid container spacing={2}>
                  <Grid item>
                    <FormikRadioField name={StatusTypes.ACTIVE} formikKey="activityStatus" />
                  </Grid>
                  <Grid item>
                    <FormikRadioField name={StatusTypes.INACTIVE} formikKey="activityStatus" />
                  </Grid>
                  <Grid item>
                    <FormikRadioField name={StatusTypes.ARCHIVED} formikKey="activityStatus" />
                  </Grid>
                </Grid>
              </RadioGroup>
              <RadioGroup aria-label="learner status">
                <FormLabel component="div">Learner Status</FormLabel>
                <Grid container spacing={2}>
                  <Grid item>
                    <FormikRadioField name={StatusTypes.ACTIVE} formikKey="learnerStatus" />
                  </Grid>
                  <Grid item>
                    <FormikRadioField name={StatusTypes.INACTIVE} formikKey="learnerStatus" />
                  </Grid>
                  <Grid item>
                    <FormikRadioField name={StatusTypes.ARCHIVED} formikKey="learnerStatus" />
                  </Grid>
                </Grid>
              </RadioGroup>
            </fieldset>
            {/*********************/}
            <fieldset>
              <legend>Show in physician app?</legend>
              <RadioGroup aria-label="Show in physician app?" name="showInPhysicianApp">
                <Grid container spacing={2}>
                  <Grid item>
                    <FormikBooleanRadioField name="Yes" formikKey="showInPhysicianApp" value />
                  </Grid>
                  <Grid item>
                    <FormikBooleanRadioField name="No" formikKey="showInPhysicianApp" value={false} />
                  </Grid>
                </Grid>
              </RadioGroup>
            </fieldset>
            <fieldset>
              <legend>Select the collaboration type.</legend>
              <RadioGroup aria-label="collaboration type" name="type">
                <FormikRadioField name="Certifying" formikKey="type" payloadValue={BoardTypes.CERTIFYING_BOARD} />
                <FormikRadioField name="Licensing" formikKey="type" payloadValue={BoardTypes.STATE_BOARD} />
                <FormikRadioField name="REMS" formikKey="type" payloadValue={BoardTypes.REMS} />
              </RadioGroup>
              {/** ****** Only show when licensing is selected *************/}
              {isStateBoard && (
                <Grid container spacing={4}>
                  {statesAndProvinces && (
                    <Grid item xs={12} sm={6}>
                      <FormLabel component="div">Select Licensing State</FormLabel>
                      <Multi
                        id="states"
                        initialOptions={initialStates}
                        onInputChange={(_: ChangeEvent<HTMLInputElement>, option: string): void =>
                          onChangeState(option)
                        }
                        options={statesAndProvinces}
                        placeholder="States"
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <FormLabel component="div">Select All of the FSMB Aliases That Apply</FormLabel>
                    <Multi
                      id="boardFsmbAliases"
                      initialOptions={boardFsmbAliases.map(
                        (alias: string): ILabelType => ({ id: alias, label: alias }),
                      )}
                      onInputChange={(_: ChangeEvent<HTMLInputElement>, queryString: string): Promise<void> =>
                        onChangeFSMB(queryString)
                      }
                      options={fsmbAliases}
                      placeholder="Enter FSMB Aliases"
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </Grid>
              )}
            </fieldset>
            {/*********************/}
            <fieldset>
              <legend>Select the integration type.</legend>
              <RadioGroup aria-label="integration type" name="boardIntegrationType">
                <Grid container spacing={2}>
                  <Grid item>
                    <FormikRadioField name={BoardIntegrationTypes.A} formikKey="boardIntegrationType" />
                  </Grid>
                  <Grid item>
                    <FormikRadioField name={BoardIntegrationTypes.B} formikKey="boardIntegrationType" />
                  </Grid>
                  <Grid item>
                    <FormikRadioField name={BoardIntegrationTypes.C} formikKey="boardIntegrationType" />
                  </Grid>
                </Grid>
              </RadioGroup>
            </fieldset>
            {/*********************/}
            <fieldset>
              <legend>Enter the total credit required, if relevant.</legend>
              <FormGroup>
                <Grid container>
                  <Grid item xs={12}>
                    <FormikNumberField formikKey="totalCreditRequired" placeholder="00" step={1} />
                  </Grid>
                </Grid>
              </FormGroup>
            </fieldset>
            {/*********************/}
            <fieldset>
              <legend>Enter the cycle length (in years).</legend>
              <FormGroup>
                <Grid container>
                  <Grid item xs={12}>
                    <FormikNumberField formikKey="cycleLengthYears" placeholder="0" step={1} />
                  </Grid>
                </Grid>
              </FormGroup>
            </fieldset>
            {/*********************/}
            <fieldset className={formStyles['no-divider']}>
              <legend>Send error emails to providers?</legend>
              <RadioGroup aria-label="send email to providers" name="sendErrorEmailsToProviders">
                <Grid container spacing={2}>
                  <Grid item>
                    <FormikBooleanRadioField name="Yes" formikKey="sendErrorEmailsToProviders" value />
                  </Grid>
                  <Grid item>
                    <FormikBooleanRadioField name="No" formikKey="sendErrorEmailsToProviders" value={false} />
                  </Grid>
                </Grid>
              </RadioGroup>
            </fieldset>
          </div>
          {/*********************/}
          <ButtonRow
            backLabel="Back"
            ctaLabel="Save and Continue"
            isCtaDisabled={!isValid}
            isSubmitting={isSubmitting}
            isValid={isValid}
            onClick={handleSubmit}
            showBack={false}
          />
        </form>
      </FormikProvider>
    </>
  );
};
