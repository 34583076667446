import { ChangeEvent, ReactElement } from 'react';
import { useField, useFormikContext } from 'formik';
import get from 'lodash/get';
import { Box, Typography } from '@material-ui/core';

// Types
interface IProps {
  formikKey: string;
  name: string;
  value: boolean;
  label?: string;
}

export const FormikBooleanRadioField = ({ formikKey, value, label, ...rest }: IProps): ReactElement => {
  const [field] = useField(rest);
  const { setFieldValue, values } = useFormikContext();
  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(formikKey, e.target.value === 'Yes');
  };
  const formikValue: boolean = get(values, formikKey);

  return (
    <Box display="flex">
      <label key={formikKey} className="form-input-radio">
        <input
          {...field}
          checked={value === formikValue}
          id={`${formikKey}.${field.name}`}
          name={formikKey}
          onChange={onChange}
          type="radio"
          value={field.name}
          title={label || field.name}
        />
        <span className="checkmark" />
        <Typography variant="srOnly">{label || field.name}</Typography>
      </label>
      <div className="label-text-container">
        <div className="label-text">{label || field.name}</div>
      </div>
    </Box>
  );
};
