import {
  Activity,
  ActivityType,
  CreateActivityRequest,
  IActivityReviewHistory,
  IActivityReviewHistoryRequest,
  IActivityReviewOutcomeRequest,
  IActivitySearchRequest,
  IActivitySearchResponse,
  IArrayBufferDownload,
  IBulkActivityHeader,
  IJsonPatchDocumentOperation,
  ILearnerSearchActivity,
  IProcessActivityRequest,
  INextSequenceNumber,
  UpdateActivityRequest,
  IQueueMocActivityRequest,
  IAdminCommandResponse,
  IAdminDeleteActivityRequest,
} from 'core/models';

import { batchActivityProgressAction } from 'store/activity/actions';
import { API } from 'core';

import { ServerRoute } from 'core/api';
import { ActivitiesApiService } from 'core/activitiesApiService';

export class ActivityService {
  private static activitiesApiService: ActivitiesApiService = new ActivitiesApiService();
  public static async create(payload: CreateActivityRequest[]): Promise<Activity[]> {
    return await this.activitiesApiService.createActivitiesAsync(payload);
  }

  public static async patch(id: string, payload: IJsonPatchDocumentOperation[]): Promise<void> {
    await this.activitiesApiService.patchActivityAsync(id, payload);
  }

  public static async update(payload: UpdateActivityRequest): Promise<Activity> {
    return await this.activitiesApiService.updateActivityAsync(payload);
  }

  public static async batch(payload: { file: File }, shouldForce: boolean): Promise<IBulkActivityHeader> {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    // If `shouldForce` is true, we don't listen for duplicate files (the user should not see a confirm modal)
    // and we will immediately start the upload process.
    const action: string = shouldForce ? 'batches/uploadforce' : 'batches/upload';
    const route: ServerRoute = ServerRoute.forAction('activities', action);
    return await API.postFile<IBulkActivityHeader>(route, payload, batchActivityProgressAction, config);
  }

  public static async getTypes(): Promise<ActivityType[]> {
    const route: ServerRoute = ServerRoute.forAction('activities', 'types');
    return await API.get(route);
  }

  public static async getTemplate(id: string): Promise<string> {
    const route: ServerRoute = ServerRoute.forAction('activities', `types/${id}`);
    return await API.get(route);
  }

  public static async getNextSequenceNumber(year: number): Promise<INextSequenceNumber> {
    return await this.activitiesApiService.getNextSequenceNumber(year);
  }

  public static async getById(id: string): Promise<Activity> {
    const route: ServerRoute = ServerRoute.forAction('activities', id);
    return await API.get(route);
  }

  public static async get(payload: IActivitySearchRequest): Promise<IActivitySearchResponse> {
    const route: ServerRoute = ServerRoute.forAction('activities', 'search');
    return await API.post<IActivitySearchResponse>(route, payload);
  }

  public static async undelete(payload: string[]): Promise<void> {
    const route: ServerRoute = ServerRoute.forAction('activities', 'bulk/undelete');
    return await API.post(route, payload);
  }

  public static async delete(id: string): Promise<void> {
    const route: ServerRoute = ServerRoute.forAction('activities', id);
    return await API.sendDelete(route);
  }

  public static async close(payload: string[]): Promise<string[]> {
    const route: ServerRoute = ServerRoute.forAction('activities', 'bulk/close');
    return await API.post<string[]>(route, payload);
  }

  public static async unclose(payload: string[]): Promise<string[]> {
    const route: ServerRoute = ServerRoute.forAction('activities', 'bulk/unclose');
    return await API.post<string[]>(route, payload);
  }

  public static async processActivity(request: IProcessActivityRequest): Promise<IAdminCommandResponse> {
    const route: ServerRoute = ServerRoute.forAction('admincommands', 'sp_ProcessActivityOutboundQueue');
    return API.post(route, request);
  }

  public static async deleteLearnerCompletion({
    activityGuid,
    completionId,
    completionSource,
    completionIdentifier,
  }: Pick<
    ILearnerSearchActivity,
    'activityGuid' | 'completionId' | 'completionSource' | 'completionIdentifier'
  >): Promise<void> {
    const route: ServerRoute = ServerRoute.forAction(
      'activities',
      `${activityGuid}/learnercompletions/${completionId}?completionSource=${completionSource}&completionIdentifier=${completionIdentifier}`,
    );
    return await API.sendDelete(route);
  }

  public static async bulkDelete(payload: string[]): Promise<void> {
    const route: ServerRoute = ServerRoute.forAction('activities', 'bulk/delete');
    return await API.post(route, payload);
  }

  public static async export(payload: IActivitySearchRequest): Promise<void> {
    const route: ServerRoute = ServerRoute.forAction('activities', 'export');
    return await API.post<void>(route, payload);
  }

  public static async download(id: string): Promise<IArrayBufferDownload> {
    const route: ServerRoute = ServerRoute.forAction('activities', `download/${id}`);
    const result = await API.getFullResponse(route, undefined, { responseType: 'arraybuffer' });
    return {
      file: result.data,
      type: result.headers['Content-Type'],
    };
  }

  public static async selectActivityForReview(request: IActivityReviewHistoryRequest): Promise<void> {
    const route: ServerRoute = ServerRoute.forAction('activities', 'bulk/reviewhistory');
    return API.post(route, request);
  }

  public static async queueMocActivity(request: IQueueMocActivityRequest): Promise<IAdminCommandResponse> {
    const route: ServerRoute = ServerRoute.forAction('admincommands', 'sp_QueueMocActivity');
    return API.post(route, request);
  }

  public static async adminDeleteActivity(request: IAdminDeleteActivityRequest): Promise<IAdminCommandResponse> {
    const route: ServerRoute = ServerRoute.forAction('admincommands', 'sp_DeleteActivityAndChildren');
    return API.post(route, request);
  }

  public static async deselectActivityForReview(id: string, entryId: string): Promise<void> {
    const route: ServerRoute = ServerRoute.forAction('activities', `${id}/reviewhistory/${entryId}`);
    return API.sendDelete(route);
  }

  public static async editActivityReviewOutcome(
    id: string,
    entryId: string,
    request: IActivityReviewOutcomeRequest,
  ): Promise<void> {
    const route: ServerRoute = ServerRoute.forAction('activities', `${id}/reviewhistory/${entryId}`);
    return API.put(route, request);
  }

  public static async getActivityReviewHistory(id: string): Promise<IActivityReviewHistory[]> {
    const route: ServerRoute = ServerRoute.forAction('activities', `${id}/reviewhistory`);
    return API.get(route);
  }
}
