import { IBCTBoard } from 'layouts/pages/bct/types';

export type IOrganizationDetails = Pick<
  IBCTBoard,
  | 'abbreviation'
  | 'activityStatus'
  | 'boardFsmbAliases'
  | 'boardIntegrationType'
  | 'cycleLengthYears'
  | 'businessId'
  | 'learnerStatus'
  | 'name'
  | 'sendErrorEmailsToProviders'
  | 'showInPhysicianApp'
  | 'states'
  | 'totalCreditRequired'
  | 'type'
>;

export const initialOrganizationDetailsForm = (bctBoard: IBCTBoard): IOrganizationDetails | unknown => {
  if (!bctBoard) return {};
  const {
    abbreviation,
    activityStatus,
    boardFsmbAliases,
    boardIntegrationType,
    businessId,
    cycleLengthYears,
    learnerStatus,
    name,
    sendErrorEmailsToProviders,
    showInPhysicianApp,
    states,
    totalCreditRequired,
    type,
  } = bctBoard;
  return {
    abbreviation,
    activityStatus,
    boardFsmbAliases,
    boardIntegrationType,
    businessId,
    cycleLengthYears,
    learnerStatus,
    name,
    sendErrorEmailsToProviders,
    showInPhysicianApp,
    states,
    totalCreditRequired,
    type,
  };
};
