import { number, ref, object } from 'yup';
import Reference from 'yup/lib/Reference';

export const validationSchema = object().shape({
  maximumContentOutlines: number()
    .positive()
    .min(
      ref('minimumContentOutlines') as Reference<number>,
      'Maximum Content Outline tags needs to be larger than or equal to Minimum Content Outline tags',
    ),
  minimumContentOutlines: number().positive(),
});
