import { ReactElement, ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { HelpRounded } from '@material-ui/icons';

// Components
import Button from 'components/Button/Button';
import { Modal } from 'components/ContinuousImprovement';
import { EditCompletionForm } from 'components/forms/templates/EditCompletionForm';
import { QueueMocLearnerForm } from 'components/forms/templates/QueueMocLearnerForm';
import { DeleteLearnerRecord } from 'components/ContinuousImprovement/Modal/templates/delete-learner-record';
import CustomTooltip from 'components/Tooltip/Tooltip';

// Core + Store
import { StatusEnum } from 'core/enums';
import { IBoardSlim, IDictionary, ILearnerSearchActivity, IUserOrganization, OrganizationKinds } from 'core/models';
import { MESSAGE_CANNOT_EDIT_DELETE_OLD_COMPLETIONS } from 'core/constants';
import { deleteLearnerCompletion } from 'store/activity/actions';
import { isProviderUserSelector, organizationKindSelector, isStaffUserSelector } from 'store/user/selectors';
import { boardSelector } from 'store/board/selectors';

// Styles
import styles from './genericlearnerdetail.module.scss';

// Utils
import { calculateStatusTitle } from 'utils';
import { ProcessBoardCreditForm } from 'components/forms/templates/ProcessBoardCreditForm';
import { BoardTypes } from 'layouts/pages/bct/types';
import { AdminDeleteLearnerChildCompletionForm } from 'components/forms/templates/AdminDeleteLearnerChildCompletionForm';

interface IDefaultBuildStatus {
  completion: ILearnerSearchActivity;
  isProvider?: boolean;
  styles: IDictionary<string>;
}

interface IProps {
  learnerDetails: ILearnerSearchActivity[];
  heading: ReactNode;
  learnerId: ReactNode;
  buildCompletionLabel: (completion: ILearnerSearchActivity) => ReactNode;
  buildCompletionDetails: (completion: ILearnerSearchActivity) => ReactNode;
  buildStatus?: ({ completion, isProvider, styles }: IDefaultBuildStatus) => ReactNode;
  refreshLearnerData: () => Promise<void>;
}

// I think REMS may want this different, so splitting it like this to make it easier to refactor later
export const defaultBuildStatus = ({ completion, isProvider, styles }: IDefaultBuildStatus): ReactNode => {
  const { learnerStatus, rejectionReason = '' } = completion;
  // We show `Submitted` as a status if the user is a Provider instead of `Not Processed`.
  // A Provider user should never see `Not Processed`.
  const learnerStatusLabel: StatusEnum = calculateStatusTitle({ isProvider, statusTitle: learnerStatus });
  const statusClass = classNames(styles.status, {
    [styles.accepted]: learnerStatus === StatusEnum.ACCEPTED,
    [styles.rejected]: learnerStatus === StatusEnum.REJECTED,
    [styles.submitted]: learnerStatus === StatusEnum.SUBMITTED,
    [styles['not-processed']]: learnerStatus === StatusEnum.NOT_PROCESSED,
  });
  return learnerStatus === StatusEnum.REJECTED ? (
    <div className={statusClass}>
      <CustomTooltip tooltipText={rejectionReason}>
        {learnerStatusLabel}
        <HelpRounded />
      </CustomTooltip>
    </div>
  ) : (
    <div className={statusClass}>{learnerStatusLabel}</div>
  );
};

export const GenericLearnerDetails = (props: IProps): ReactElement => {
  const {
    learnerDetails,
    heading,
    learnerId,
    buildCompletionDetails,
    buildStatus,
    buildCompletionLabel,
    refreshLearnerData,
  } = props;
  const dispatch = useDispatch();
  const [selectedCompletion, setSelectedCompletion] = useState<ILearnerSearchActivity>(null);
  const [isEditCompletionModalOpen, setIsEditCompletionModalOpen] = useState<boolean>(false);
  const [isDeleteCompletionModalOpen, setIsDeleteCompletionModalOpen] = useState<boolean>(false);
  const [isQueueMocLearnerModalOpen, setQueueMocLearnerModalStatus] = useState<boolean>(false);
  const [isProcessBoardCreditOpen, setProcessBoardCreditStatus] = useState<boolean>(false);
  const [isAdminDeleteOpen, setAdminDeleteStatus] = useState<boolean>(false);

  // Selectors
  const org: IUserOrganization = useSelector(organizationKindSelector);
  const isProvider: boolean = useSelector(isProviderUserSelector);
  const isCurrentOrgAccmeStaff: boolean = useSelector(isStaffUserSelector);
  const boards: IBoardSlim[] = useSelector(boardSelector);

  if (!learnerDetails?.length || !org) {
    return null;
  }
  const detailGroupClass = styles['learner-detail-group'];
  const detailGroupHeaderClass = styles['learner-detail-group-header'];
  const detailGroupContentClass = styles['learner-detail-group-content'];
  const detailGroupContentMetaClass = styles.meta;
  const detailGroupContentActionsClass = styles.actions;
  const detailGroupContentActionsButtonsClass = styles['actions-buttons'];
  const dateTimeFormat = 'YYYY-MM-DD';
  const dateTimeCompletionFormat = 'MM/DD/YYYY';
  const now = moment();

  const { organizationKind } = org;
  const isVisibleCRUDOperations =
    organizationKind !== OrganizationKinds.BOARD && organizationKind !== OrganizationKinds.ACCREDITOR;

  const handleClickEdit = (completionRecord: ILearnerSearchActivity): void => {
    setSelectedCompletion(completionRecord);
    setIsEditCompletionModalOpen(true);
  };

  const handleClickDelete = (completionRecord: ILearnerSearchActivity): void => {
    setSelectedCompletion(completionRecord);
    setIsDeleteCompletionModalOpen(true);
  };

  const onDeleteLearner = async (learner: ILearnerSearchActivity): Promise<void> => {
    const { activityGuid, completionId, completionSource, completionIdentifier } = learner;
    await dispatch(deleteLearnerCompletion({ activityGuid, completionId, completionSource, completionIdentifier }));
    setIsDeleteCompletionModalOpen(false);
  };

  const handleClickQueueMocLearner = (completionRecord: ILearnerSearchActivity): void => {
    setSelectedCompletion(completionRecord);
    setQueueMocLearnerModalStatus(true);
  };

  const handleClickProcessBoardCredit = (completionRecord: ILearnerSearchActivity): void => {
    setSelectedCompletion(completionRecord);
    setProcessBoardCreditStatus(true);
  };

  const handleClickAdminDelete = (completionRecord: ILearnerSearchActivity): void => {
    setSelectedCompletion(completionRecord);
    setAdminDeleteStatus(true);
  };

  const selectedCompletionLabel = selectedCompletion && buildCompletionLabel(selectedCompletion);
  return (
    <>
      <div className={detailGroupClass}>
        <div className={detailGroupHeaderClass}>
          {heading} <div>{learnerId && `Learner ID: ${learnerId}`}</div>
        </div>
        <ul className={detailGroupContentClass}>
          {learnerDetails?.map(
            (completion: ILearnerSearchActivity): ReactElement => {
              const { completionDate, board, submissionDate } = completion;
              const completionDateMoment = moment(completionDate);
              const formattedCompletionDate = completionDateMoment.format(dateTimeCompletionFormat);
              const completionLabel = buildCompletionLabel(completion);
              const submissionDateMoment = moment(submissionDate);
              const formattedSubmissionDate = submissionDateMoment.format(dateTimeCompletionFormat);

              // get board CCT data by abbreviation since `item` doesn't have a boardId
              const cctBoard = boards?.find(({ abbreviation }) => abbreviation === board);
              const isCertBoard = cctBoard?.type === BoardTypes.CERTIFYING_BOARD;

              const cctLastEditDate = cctBoard?.boardLastLearnerCompletionRemovalDate?.find(
                (i) => i.year === completionDateMoment.year(),
              )?.cutoffDate;
              const canEditDelete = !cctLastEditDate || moment(cctLastEditDate).isAfter(now, 'day');

              return (
                <li key={completion.key}>
                  <div className={detailGroupContentMetaClass}>
                    <span>
                      Completion:{' '}
                      <time dateTime={moment(completionDate).format(dateTimeFormat)}>{formattedCompletionDate}</time>
                    </span>
                    <span>
                      Submission:{' '}
                      <time dateTime={moment(submissionDateMoment).format(dateTimeFormat)}>
                        {formattedSubmissionDate}
                      </time>
                    </span>
                    {buildCompletionDetails(completion)}
                  </div>
                  <div className={detailGroupContentActionsClass}>
                    <div className={detailGroupContentActionsButtonsClass}>
                      {isVisibleCRUDOperations && (
                        <>
                          {!true && (
                            <Button
                              className="link link--hyperlink"
                              title={`Edit ${completionLabel} completion for ${formattedCompletionDate}`}
                              onClick={() => handleClickEdit(completion)}
                              disabled={!canEditDelete}
                              disabledMessage={MESSAGE_CANNOT_EDIT_DELETE_OLD_COMPLETIONS}
                            >
                              Edit
                            </Button>
                          )}

                          <Button
                            className="link link--hyperlink"
                            title={`Delete ${completionLabel} completion for ${formattedCompletionDate}`}
                            onClick={() => handleClickDelete(completion)}
                            disabled={!canEditDelete}
                            disabledMessage={MESSAGE_CANNOT_EDIT_DELETE_OLD_COMPLETIONS}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                      {isCurrentOrgAccmeStaff && (
                        <>
                          {isCertBoard && (
                            <>
                              <Button
                                className="link link--hyperlink"
                                title="Queue Moc Learner"
                                onClick={() => handleClickQueueMocLearner(completion)}
                              >
                                Queue Moc Learner
                              </Button>
                              <Button
                                className="link link--hyperlink"
                                title="Process Board Credit"
                                onClick={() => handleClickProcessBoardCredit(completion)}
                              >
                                Process Board Credit
                              </Button>
                            </>
                          )}
                          <Button
                            className="link link--hyperlink"
                            title="Admin Delete"
                            onClick={() => handleClickAdminDelete(completion)}
                          >
                            Admin Delete
                          </Button>
                        </>
                      )}
                    </div>
                    {(buildStatus ?? defaultBuildStatus)({ completion, isProvider, styles })}
                  </div>
                </li>
              );
            },
          )}
        </ul>
        {/* Modal */}
        <Modal
          isOpen={isEditCompletionModalOpen}
          onClose={() => setIsEditCompletionModalOpen(false)}
          title={`Edit ${selectedCompletionLabel} Record`}
        >
          <EditCompletionForm
            completionSearchResult={selectedCompletion}
            onCloseCallback={() => setIsEditCompletionModalOpen(false)}
          />
        </Modal>
        <Modal
          isOpen={isDeleteCompletionModalOpen}
          title={`Delete ${selectedCompletionLabel} Learner Record`}
          onClose={() => setIsDeleteCompletionModalOpen(false)}
        >
          <DeleteLearnerRecord
            learnerRecord={selectedCompletion}
            onCancel={() => setIsDeleteCompletionModalOpen(false)}
            onProceed={() => onDeleteLearner(selectedCompletion)}
          />
        </Modal>
        {isCurrentOrgAccmeStaff && (
          <>
            <Modal
              isOpen={isQueueMocLearnerModalOpen}
              title="Queue Moc Learner"
              onClose={() => setQueueMocLearnerModalStatus(false)}
            >
              <QueueMocLearnerForm
                learnerkey={selectedCompletion?.key}
                onClose={() => setQueueMocLearnerModalStatus(false)}
              />
            </Modal>
            <Modal
              isOpen={isProcessBoardCreditOpen}
              title="Process Board Credit"
              onClose={() => setProcessBoardCreditStatus(false)}
            >
              <ProcessBoardCreditForm
                lccbId={selectedCompletion?.key}
                boardId={boards?.find(({ abbreviation }) => abbreviation === selectedCompletion?.board)?.id}
                onClose={() => setProcessBoardCreditStatus(false)}
                onSuccess={refreshLearnerData}
              />
            </Modal>
            <Modal isOpen={isAdminDeleteOpen} title="Admin Delete Learner" onClose={() => setAdminDeleteStatus(false)}>
              <AdminDeleteLearnerChildCompletionForm
                childCompletionId={selectedCompletion?.key}
                onClose={() => setAdminDeleteStatus(false)}
                onSuccess={refreshLearnerData}
              />
            </Modal>
          </>
        )}
      </div>
    </>
  );
};
