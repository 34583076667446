import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ReactElement, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';

// Components
import Button from 'components/Button/Button';

// Core.
import { CACHE_SELECTED_ORGANIZATION, CACHE_SELECTED_PROVIDER } from 'core/constants';

// Services.
import { CacheService } from 'services';

// Store.
import { currentOrganizationContextSelector, userProfileSelector } from 'store/user/selectors';
import { getRedirectUrl, getSite, getTargetSite } from 'utils/site';

interface IOrganizationSelect {
  onSelectClose(): void;
  onSelectOpen(): void;
}

export const OrganizationSelect = (props: IOrganizationSelect): ReactElement => {
  const site = getSite();
  const { onSelectClose, onSelectOpen } = props;
  const userProfile = useSelector(userProfileSelector);
  const currentOrganization = useSelector(currentOrganizationContextSelector);

  const organizationOptions = useMemo(
    () => sortBy(userProfile?.primaryUserOrganizations || [], 'organizationName', 'businessId'),
    [userProfile?.primaryUserOrganizations],
  );

  const handleChange = useCallback(
    (e) => {
      const selected = organizationOptions?.find(({ id }) => id === e.target.value);
      if (selected?.id) {
        const targetSite = getTargetSite(selected?.rollupOrganizationEnum);
        if (site !== targetSite) {
          window.location.href = getRedirectUrl(targetSite, selected.id);
          return;
        }
      }
      CacheService.set(CACHE_SELECTED_ORGANIZATION, e.target.value);
      CacheService.remove(CACHE_SELECTED_PROVIDER);
      window.location.href = '/';
    },
    [site, organizationOptions],
  );

  const handleClear = useCallback(() => {
    CacheService.remove(CACHE_SELECTED_ORGANIZATION);
    CacheService.remove(CACHE_SELECTED_PROVIDER);
    window.location.href = '/';
  }, []);

  return (
    organizationOptions?.length > 1 && (
      <>
        <FormControl>
          <InputLabel htmlFor="change-organization-select">Organization Context</InputLabel>
          <Select
            defaultValue={currentOrganization?.id}
            inputProps={{
              id: 'change-organization-select',
            }}
            onChange={handleChange}
            onClose={onSelectClose}
            onOpen={onSelectOpen}
            variant="outlined"
          >
            <MenuItem disabled value="">
              Select Organization
            </MenuItem>
            {organizationOptions.map(({ id, organizationName, businessId }) => (
              <MenuItem key={id} value={id}>
                {businessId} - {organizationName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button aria-label="Clear organization context" onClick={handleClear}>
          Clear
        </Button>
      </>
    )
  );
};
