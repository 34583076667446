import * as PARS from '../../core';

export interface FAQState {
  faqOpen: boolean;
  faqs: any[]; // TODO raplace with type
}

export const FAQ_LOAD = 'FAQ_LOAD';
export const FAQ_OPEN = 'FAQ_OPEN';
export const FAQ_CLOSE = 'FAQ_CLOSE';

interface FAQOpen extends PARS.Models.PARSAction<undefined, typeof FAQ_OPEN> {
  type: typeof FAQ_OPEN;
}

interface FAQClose extends PARS.Models.PARSAction<undefined, typeof FAQ_CLOSE> {
  type: typeof FAQ_CLOSE;
}

export type FAQActionTypes = FAQOpen | FAQClose;
