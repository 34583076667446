import { useCallback } from 'react';
import TagManager from 'react-gtm-module';

export enum GtmEvents {
  PageInteraction = 'pageInteraction',
  PageLoad = 'pageLoad',
}

export interface IGtmEvent {
  AddActivityStep?: number;
  Event?: string;
  EventAction?: number | string;
  EventCategory?: string;
  EventLabel?: string;
  LoginStatus?: boolean | string;
  Notifications?: boolean;
  OrganizationId?: string;
  Page?: string;
  PageName?: string;
  PageType?: string;
  SearchResultsNum?: number;
  UserPersona?: string;
  event: GtmEvents;
}

export type EventArgs = Omit<IGtmEvent, 'event'>;
export type EventFunction = (dataLayer: IGtmEvent) => void;

export const useEvent = (): EventFunction => {
  return useCallback((dataLayer: IGtmEvent) => {
    TagManager.dataLayer({ dataLayer });
  }, []);
};
