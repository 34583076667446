import { ReactElement, useEffect, useMemo, useRef } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { FormGroup, Grid, RadioGroup } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import classNames from 'classnames';

// Components
import ButtonRow from 'layouts/pages/activity/forms/buttonRow';
import { FormikBooleanRadioField, FormikNumberField } from 'components/ContinuousImprovement/PureFormElements';
import { ActivityFormats } from './ActivityFormats';
import { LoadingCards } from 'components/LoadingCard';
import { RichTextEditor } from './RichTextEditor';
import { TextboxRepeater } from 'components/TextboxRepeater';
import { PracticeAreasRepeater } from '../../components';
import { RemsRegularExpressionsRepeater } from './RemsRegularExpressionRepeater';

// Styles
import pageStyles from '../../BCTPageStyles.module.scss';
import formStyles from '../FormStyles.module.scss';

// Core + Store
import { getBoardById, postBCTStep, setBCTStep } from 'store/bct/actions';
import { currentBoardSelector, isLoadingBCTSelector, validationExceptionsSelector } from 'store/bct/selectors';
import { IBCTSpecialty, IValidationException } from 'core/models';

// Utils
import {
  IActivityRegistrationDetails,
  IBoardActivityTypeSubmission,
  initialActivityRegistrationDetailsForm,
} from './initialActivityRegistrationDetailsForm';
import { handleValidationException } from 'globals/utils/handleValidationException';

// Types
import { BoardTypes, IBCTBoard, IBCTStepProps, IBoardAdditionalFields, IBCTRemsRegularExpression } from '../../types';

export const ActivityRegistrationDetailsForm = (props: IBCTStepProps): ReactElement => {
  const { prevStep, nextStep } = props;

  const dispatch = useDispatch();
  const quillRef = useRef(null);

  const { id } = useParams<{ id: string }>();

  // Selectors.
  const currentBoard: IBCTBoard = useSelector(currentBoardSelector);
  const isLoading: boolean = useSelector(isLoadingBCTSelector);
  const validationExceptions: IValidationException[] = useSelector(validationExceptionsSelector);

  const isRems: boolean = currentBoard?.type === BoardTypes.REMS;
  const isLicensing: boolean = currentBoard?.type === BoardTypes.STATE_BOARD;

  // Fetch.
  useEffect(() => {
    dispatch(getBoardById(id));
  }, [dispatch, id]);

  // Form setup.
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialActivityRegistrationDetailsForm(currentBoard),
    onSubmit: async (rawValues: IActivityRegistrationDetails): Promise<void> => {
      const values: IBCTBoard = {
        ...currentBoard,
        ...rawValues,
        // Remove the ones with isChecked:false.
        boardActivityTypes: rawValues.boardActivityTypes.filter(
          (activity: IBoardActivityTypeSubmission): boolean => !!activity?.isChecked,
        ),
      };
      await dispatch(postBCTStep({ nextStep, values }));

      // Scroll to top when done.
      window.scroll(0, 0);
    },
  });
  const { handleSubmit, isValid, isSubmitting, resetForm, setFieldError, values } = formik;

  const specialties: IBCTSpecialty[] = useMemo(() => get(values, 'specialties', []), [values]);
  const boardRemsIdRegularExpressions: IBCTRemsRegularExpression[] = useMemo(
    () => get(values, 'boardRemsIdRegularExpressions', []),
    [values],
  );
  const boardActivityAdditionalFields: IBoardAdditionalFields[] = useMemo(
    () => get(values, 'boardActivityAdditionalFields', []),
    [values],
  );

  // We need a custom submit button to call `blur` on the quill text editor to update the formik model before submitting.
  const customSubmit = (e): void => {
    quillRef?.current?.blur();
    handleSubmit(e);
  };

  useEffect(() => {
    // Set field specific errors.
    validationExceptions?.forEach((validationException: IValidationException): void => {
      const [formikKey, message] = handleValidationException(validationException);

      setFieldError(formikKey, message);
    });
  }, [setFieldError, validationExceptions]);

  // Show this while data is loading.
  if (isLoading) return <LoadingCards />;

  const handleBackClick = (): void => {
    resetForm();
    dispatch(setBCTStep(prevStep));
    window.scroll(0, 0);
  };

  // Disable the repeater and the save button when
  // - The text inputs are empty. Must have values in order to add more.
  // - We have a duplicate `sortOrder`.
  // Here we are checking against `specialties` and `boardActivityAdditionalFields`.
  const hasEmptyValues: boolean =
    specialties.some(({ name, sortOrder }: IBCTSpecialty): boolean => !name || !sortOrder) ||
    boardActivityAdditionalFields.some(
      ({ question, sortOrder }: IBoardAdditionalFields): boolean => !question || !sortOrder,
    );
  const hasEmptyQuestions: boolean = boardActivityAdditionalFields.some(
    ({ question }: IBoardAdditionalFields): boolean => !question,
  );
  const hasEmptyRemsRegularExpression: boolean = boardRemsIdRegularExpressions.some(
    ({ regularExpression }: IBCTRemsRegularExpression): boolean => !regularExpression,
  );
  const uniqueSpecialtyValues: Set<number> = new Set(
    specialties.map(({ sortOrder }: IBCTSpecialty): number => sortOrder),
  );
  const uniqueQuestionValues: Set<number> = new Set(
    boardActivityAdditionalFields.map(({ sortOrder }: IBoardAdditionalFields): number => sortOrder),
  );
  const hasDupesSortOrder: boolean =
    specialties.length > uniqueSpecialtyValues.size || boardActivityAdditionalFields.length > uniqueQuestionValues.size;
  const isDisabled: boolean = hasEmptyValues || hasDupesSortOrder || hasEmptyQuestions;

  return (
    <>
      <h2 className={pageStyles.subtitle}>Enter the activity registration details for this organization</h2>
      <FormikProvider value={formik}>
        <form onSubmit={customSubmit}>
          <div className={formStyles['form-card']}>
            <fieldset>
              <legend>Turn on maximum credit validation for the organization?</legend>
              <RadioGroup aria-label="Turn on maximum credit validation for the organization?">
                <Grid container spacing={2}>
                  <Grid item>
                    <FormikBooleanRadioField name="Yes" formikKey="enableMaximumCreditValidation" value />
                  </Grid>
                  <Grid item>
                    <FormikBooleanRadioField name="No" formikKey="enableMaximumCreditValidation" value={false} />
                  </Grid>
                </Grid>
              </RadioGroup>
            </fieldset>
            {/*********************/}
            <fieldset>
              <legend>Enter the allowed activity formats and details.</legend>
              <FormGroup>
                <ActivityFormats />
              </FormGroup>
            </fieldset>
            {/*********************/}
            <fieldset>
              <legend>Enter the credit increment.</legend>
              <FormGroup>
                <Grid container>
                  <Grid item xs={12} sm={2}>
                    <FormikNumberField
                      formikKey="creditIncrement"
                      label="Credit Increment"
                      placeholder=".25"
                      step={1}
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </fieldset>
            {/*********************/}
            <fieldset
              className={classNames({
                [formStyles['no-divider']]: isLicensing,
              })}
            >
              <legend>Show the organization activities with no learners reported?</legend>
              <RadioGroup aria-label="show the organization activities">
                <Grid container spacing={3}>
                  <Grid item>
                    <FormikBooleanRadioField name="Yes" formikKey="showActivitiesWithNoLearnersReported" value />
                  </Grid>
                  <Grid item>
                    <FormikBooleanRadioField name="No" formikKey="showActivitiesWithNoLearnersReported" value={false} />
                  </Grid>
                </Grid>
              </RadioGroup>
            </fieldset>
            {/*********************/}
            {!isLicensing && (
              <fieldset>
                <legend>Is the internal ID required on the activity form?</legend>
                <RadioGroup aria-label="Is the internal ID required on the activity form">
                  <Grid container spacing={3}>
                    <Grid item>
                      <FormikBooleanRadioField name="Yes" formikKey="providerActivityIdRequired" value />
                    </Grid>
                    <Grid item>
                      <FormikBooleanRadioField name="No" formikKey="providerActivityIdRequired" value={false} />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </fieldset>
            )}
            {/*********************/}
            {isRems && (
              <>
                <fieldset>
                  <legend>Add your attestation.</legend>
                  <RichTextEditor ref={quillRef} formikKey="attestationText" />
                </fieldset>
                {/*********************/}
                <fieldset>
                  <legend>Add a Regex.</legend>

                  <RemsRegularExpressionsRepeater
                    disabled={hasEmptyRemsRegularExpression}
                    formikKey="boardRemsIdRegularExpressions"
                  />
                </fieldset>
                {/*********************/}
                <fieldset className={classNames({ [formStyles['no-divider']]: isRems })}>
                  <legend>Add additional questions.</legend>
                  <TextboxRepeater
                    disabled={hasEmptyValues}
                    labelPrompt="Question"
                    formikKey="boardActivityAdditionalFields"
                    payloadValue="question"
                  />
                </fieldset>
              </>
            )}
            {/*********************/}
            {!isRems && !isLicensing && (
              <fieldset className={formStyles['no-divider']}>
                <legend>Enter the practice areas for the organization</legend>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <PracticeAreasRepeater
                      disabled={hasEmptyValues}
                      labelPrompt="Practice Area"
                      formikKey="specialties"
                    />
                  </Grid>
                </Grid>
              </fieldset>
            )}
          </div>
          <ButtonRow
            backLabel="Back"
            ctaLabel="Save and Continue"
            isCtaDisabled={isDisabled || !isValid}
            isSubmitting={isSubmitting}
            isValid={isValid}
            onBackClick={handleBackClick}
            onClick={customSubmit}
            showBack
          />
        </form>
      </FormikProvider>
    </>
  );
};
