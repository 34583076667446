import { IBCTBoard, IBoardActivityType } from 'layouts/pages/bct/types';
import orderBy from 'lodash/orderBy';

export type IActivityRegistrationDetails = Pick<
  IBCTBoard,
  | 'attestationText'
  | 'boardActivityAdditionalFields'
  | 'boardActivityTypes'
  | 'boardRemsIdRegularExpressions'
  | 'creditIncrement'
  | 'enableMaximumCreditValidation'
  | 'providerActivityIdRequired'
  | 'remsTaxonomyTermId'
  | 'showActivitiesWithNoLearnersReported'
  | 'specialties'
>;

export const initialActivityRegistrationDetailsForm = (bctBoard: IBCTBoard): IActivityRegistrationDetails | unknown => {
  if (!bctBoard) return {};
  const {
    attestationText,
    boardActivityAdditionalFields,
    boardActivityTypes,
    boardRemsIdRegularExpressions,
    creditIncrement,
    enableMaximumCreditValidation,
    providerActivityIdRequired,
    remsTaxonomyTermId,
    showActivitiesWithNoLearnersReported,
    specialties,
  } = bctBoard;
  return {
    attestationText,
    // Start the AdditionalFields ordered by their `sortOrder`: lowest number at the top.
    boardActivityAdditionalFields: orderBy(boardActivityAdditionalFields, ['sortOrder'], ['asc']),
    // client will have all the activity types in this array, so we need to add `isChecked` to the ones selected from the server
    boardActivityTypes: boardActivityTypes.map((bat) => ({ ...bat, isChecked: true })),
    boardRemsIdRegularExpressions,
    creditIncrement,
    enableMaximumCreditValidation,
    providerActivityIdRequired,
    remsTaxonomyTermId,
    showActivitiesWithNoLearnersReported,
    // Start the Specialties ordered by their `sortOrder`: lowest number at the top.
    specialties: orderBy(specialties, ['sortOrder'], ['asc']),
  };
};

/**
 * Submission
 */
export type IBoardActivityTypeSubmission = IBoardActivityType & { isChecked: boolean };
