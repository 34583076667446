import { ReactElement, useState } from 'react';
import { IBoardCredits, ILearnerSearchActivity } from 'core/models';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';

// Components
import Button from 'components/Button/Button';

// Styles
import styles from './DeleteLearerRecord.module.scss';

// Types
import { IButtonProps } from '../types';

interface IProps extends IButtonProps {
  learnerRecord: ILearnerSearchActivity;
}

export const DeleteLearnerRecord = (props: IProps): ReactElement => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { learnerRecord, onCancel, onProceed } = props;
  const { credits, completionDate, learnerName, totalCredits } = learnerRecord;

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      await onProceed();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <h2 className={styles['learner-name']}>{learnerName}</h2>
      <div className={styles['row--pipe-separated']}>
        <span>Completion: {moment(completionDate).format('MM/DD/YYYY')}</span>
        <span>Total: {totalCredits}</span>
      </div>
      <div className={styles['row--pipe-separated']}>
        {credits.map(({ credits, name }: IBoardCredits) => (
          <span key={uniqueId()}>{`${name}: ${credits || 'N/A'}`}</span>
        ))}
      </div>

      <div className={styles.question}>Are you sure you want to delete this learner record?</div>
      <div className="modal-button-row">
        <Button className="primary" onClick={onSubmit} disabled={isSubmitting} isSubmitting={isSubmitting}>
          Yes, Delete
        </Button>
        <Button className="secondary" onClick={onCancel}>
          No, Don't Delete
        </Button>
      </div>
    </>
  );
};
