import { ReactChild, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { CacheService } from 'services';

// Configuration
import Configuration from 'core/configuration';
import { getSite } from 'utils/site';
import { RollupOrganizationEnums } from 'core/models';

// Types
interface IProps {
  children: ReactChild;
}

export const Auth0ProviderWithHistory = ({ children }: IProps): ReactElement => {
  const history = useHistory();

  const onRedirectCallback = (appState: AppState) => {
    const redirectToUri = CacheService.get(appState?.returnTo);
    // After getting the redirect uri, clear out the cached value
    CacheService.remove(appState?.returnTo);
    history.push(redirectToUri || window.location.pathname);
  };

  const site = getSite();
  const clientId =
    site === RollupOrganizationEnums.NARS ? Configuration.narsAuthClientId : Configuration.parsAuthClientId;
  if (!clientId) {
    console.log(`No client id found for site ${site}. Make sure environment variables are configured correctly.`);
  }

  return (
    <Auth0Provider
      audience={Configuration.authAudience}
      clientId={clientId}
      domain={Configuration.authDomain}
      onRedirectCallback={onRedirectCallback}
      redirectUri={Configuration.authRedirectUri}
      scope={Configuration.authScope}
    >
      {children}
    </Auth0Provider>
  );
};
