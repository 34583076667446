import { ChevronRightRounded } from '@material-ui/icons';
import classNames from 'classnames';
import { ReactElement, useEffect, useState } from 'react';

// Components.
import { DashboardNotificationsDrawer } from './DashboardNotificationsDrawer';

// Store.
import { IDashboardNotification } from 'store/dashboard/types';

// Styles.
import styles from './DashboardNotifications.module.scss';

interface IDashboardNotificationsProps {
  className?: string;
  notifications: IDashboardNotification[];
}

export const DashboardNotifications = (props: IDashboardNotificationsProps): ReactElement => {
  const { className, notifications = [] } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (notifications.length > 0) {
      setIsOpen(true);
    }
  }, []);

  return (
    <section className={classNames(styles.container, className)}>
      {notifications.length > 0 && (
        <button className={styles.notifications} onClick={() => setIsOpen(true)} type="button">
          <ChevronRightRounded />
          {notifications.length} notification{notifications.length > 1 && 's'} require
          {notifications.length === 1 && 's'} your attention
        </button>
      )}
      <DashboardNotificationsDrawer isOpen={isOpen} notifications={notifications} onClose={() => setIsOpen(false)} />
    </section>
  );
};
