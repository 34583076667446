import { FormLabel, InputLabel } from '@material-ui/core';
import { Field, FormikProvider, useFormik } from 'formik';
import { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Button from 'components/Button/Button';
import DatePicker from 'components/DatePicker/DatePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import { FormikTextField } from 'components/forms/FormikTextField';
import InputBlock from 'components/global/inputBlock/inputBlock';

// Styles.
import styles from './SelectForReviewForm.module.scss';

// Core + Store
import { TAXONOMY_ACTIVITY_REVIEW_TYPES_ID } from 'core/constants';
import { ButtonVariant } from 'core/enums';
import { selectActivityForReview } from 'store/activity/actions';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { activityReviewTypesSelector } from 'store/taxonomy/selectors';
import {
  isStaffUserSelector,
  rollupOrganizationEnumSelector,
  staffRollupOrganizationEnumsSelector,
} from 'store/user/selectors';

// Validations.
import { validationSchema } from './validationSchema';
import { filterTaxonomyByRollupOrganizationEnum } from 'globals/utils/filterTaxonomyByOrg';

interface ISelectForReviewFormProps {
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  activityIds: string[];
}

export const SelectForReviewForm = (props: ISelectForReviewFormProps): ReactElement => {
  const { activityIds, onClose, onSuccess } = props;
  const dispatch = useDispatch();
  const reviewTypes = useSelector(activityReviewTypesSelector);
  const isUserStaff = useSelector(isStaffUserSelector);
  const staffRollupOrgs = useSelector(staffRollupOrganizationEnumsSelector);
  const rollupOrg = useSelector(rollupOrganizationEnumSelector);
  const canUseStaffReviewTypes = useMemo(() => isUserStaff || staffRollupOrgs?.includes(rollupOrg), [
    isUserStaff,
    rollupOrg,
    staffRollupOrgs,
  ]);
  const permittedReviewTypes = useMemo(
    () =>
      filterTaxonomyByRollupOrganizationEnum(reviewTypes, rollupOrg)?.filter((reviewType) =>
        !canUseStaffReviewTypes ? reviewType.metadataText !== 'staff' : true,
      ),
    [canUseStaffReviewTypes, reviewTypes, rollupOrg],
  );

  const formik = useFormik({
    initialValues: {
      providerVisibleReviewPeriodEndDate: undefined,
      providerVisibleReviewPeriodStartDate: undefined,
      reviewDate: undefined,
      reviewNote: '',
      reviewTypeId: '',
    },
    onSubmit: async (values): Promise<void> => {
      const result = await dispatch(selectActivityForReview({ ...values, activityIds }));
      if (result) {
        onClose();
        onSuccess?.();
      }
    },
    validationSchema,
  });

  useEffect(() => {
    dispatch(getTaxonomyTermById(TAXONOMY_ACTIVITY_REVIEW_TYPES_ID));
  }, [dispatch]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <fieldset className={styles.fieldset}>
          <section className={styles.section}>
            <legend className={styles.legend}>Provide details for this review.</legend>
            <div>
              <InputLabel>
                Review Type <span className={styles.required}>*</span>
              </InputLabel>
              <InputBlock name="reviewTypeId">
                <Dropdown
                  className={styles.input}
                  fullWidth
                  items={permittedReviewTypes}
                  name="reviewTypeId"
                  placeholder="Select Review Type"
                  required
                />
              </InputBlock>
            </div>

            <div>
              <FormLabel component="div">
                Review Date <span className={styles.required}>*</span>
              </FormLabel>
              <InputBlock name="reviewDate">
                <Field name="reviewDate">
                  {({ field }) => (
                    <DatePicker
                      className={styles.input}
                      field={field}
                      formikKey="reviewDate"
                      fullWidth
                      required
                      timeOfDay="midnight"
                    />
                  )}
                </Field>
              </InputBlock>
            </div>

            <div>
              <FormLabel component="div">
                Provider View Date <span className={styles.required}>*</span>
              </FormLabel>
              <InputBlock name="providerVisibleReviewPeriodStartDate">
                <Field name="providerVisibleReviewPeriodStartDate">
                  {({ field }) => (
                    <DatePicker
                      className={styles.input}
                      field={field}
                      formikKey="providerVisibleReviewPeriodStartDate"
                      fullWidth
                      required
                      timeOfDay="midnight"
                    />
                  )}
                </Field>
              </InputBlock>
            </div>

            <div>
              <FormLabel component="div">
                Removed From Provider View Date <span className={styles.required}>*</span>
              </FormLabel>
              <InputBlock name="providerVisibleReviewPeriodEndDate">
                <Field name="providerVisibleReviewPeriodEndDate">
                  {({ field }) => (
                    <DatePicker
                      className={styles.input}
                      field={field}
                      formikKey="providerVisibleReviewPeriodEndDate"
                      fullWidth
                      required
                      timeOfDay="endOfDay"
                    />
                  )}
                </Field>
              </InputBlock>
            </div>

            <div>
              <FormLabel component="div">Review Note</FormLabel>
              <FormikTextField className={styles.input} formikKey="reviewNote" multiline rows={8} variant="outlined" />
            </div>

            <footer className={styles.footer}>
              <Button className={styles.button} onClick={onClose} variant={ButtonVariant.Secondary}>
                Cancel
              </Button>
              <Button
                className={styles.button}
                disabled={formik.isSubmitting}
                isSubmitting={formik.isSubmitting}
                type="submit"
                variant={ButtonVariant.Primary}
              >
                Save
              </Button>
            </footer>
          </section>
        </fieldset>
      </form>
    </FormikProvider>
  );
};
