import { ReactElement, useCallback, useEffect } from 'react';
import { useLoadData } from 'hooks/useLoadData';

// Styles
import { AdminCommandsService } from 'services/AdminCommandsService';
import { LoadingCards } from 'components/LoadingCard';
import { CommandInfo } from 'core/models';
import InputBlock from 'components/global/inputBlock/inputBlock';
import { Field, FormikProvider, useFormikContext } from 'formik';
import { Grid, RadioGroup } from '@material-ui/core';
import { FormikBooleanRadioField } from 'components/ContinuousImprovement/PureFormElements';
import styles from '../AdminCommands.module.scss';
import { CommandType } from 'core/enums';

const detailContentClass = styles['detail-content'];
const detailGroupClass = styles['detail-group'];
const detailGroupLabelClass = styles['detail-group-label'];

const textOptions = [CommandType.String, CommandType.Guid, CommandType.Date, CommandType.Time, CommandType.DateTime];
const numberOptions = [CommandType.Int, CommandType.Decimal];

export const AdminCommandDetail = (props: { commandName: string; formikKey: string }): ReactElement => {
  const getter = useCallback(async () => {
    if (!props.commandName) return;
    return await AdminCommandsService.getCommandInfoAsync(props.commandName);
  }, [props.commandName]);

  const { data, error, isLoading } = useLoadData<CommandInfo>('getCommandInfo', getter);
  const formik = useFormikContext();
  const { setFieldValue } = formik;

  useEffect(() => {
    // prepopulate initial form state with default values
    if (data?.parameters?.length > 0 ?? false) {
      data.parameters.forEach((p) => setFieldValue(`${props.formikKey}.${p.name}`, null));
    }
  }, [data, setFieldValue, props.formikKey]);

  return (
    <>
      <div>
        {isLoading ? (
          <LoadingCards count={3} />
        ) : data ? (
          <>
            <h5>Command Parameters</h5>
            <FormikProvider value={formik}>
              <Grid container spacing={2}>
                {data.parameters.map(({ name, type, example }, ix) => (
                  <Grid key={ix} item md={6}>
                    <InputBlock name={`${props.formikKey}.${name}`} title={`${name} (e.g. ${example})`}>
                      {textOptions.includes(type) && (
                        <Field
                          aria-label={name}
                          name={`${props.formikKey}.${name}`}
                          type="text"
                          placeholder={example}
                        />
                      )}
                      {type === CommandType.Bit && (
                        <RadioGroup aria-label={name}>
                          <Grid container spacing={2} className={styles.radioGroup}>
                            <Grid item>
                              <FormikBooleanRadioField
                                label="True"
                                name="Yes"
                                value
                                formikKey={`${props.formikKey}.${name}`}
                              />
                            </Grid>
                            <Grid item>
                              <FormikBooleanRadioField
                                label="False"
                                name="No"
                                value={false}
                                formikKey={`${props.formikKey}.${name}`}
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      )}
                      {numberOptions.includes(type) && (
                        <Field
                          aria-label={name}
                          name={`${props.formikKey}.${name}`}
                          type="number"
                          step={type === CommandType.Decimal ? '.01' : ''}
                          placeholder={example}
                        />
                      )}
                    </InputBlock>
                  </Grid>
                ))}
              </Grid>
            </FormikProvider>
          </>
        ) : (
          <div className={detailGroupClass}>
            <h5 className={detailGroupLabelClass}>Error</h5>
            <div className={detailContentClass}>{error || 'Unknown error'}</div>
          </div>
        )}
      </div>
    </>
  );
};
