import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import StaticModal from 'components/Modal';
import Button from 'components/Button/Button';

// Store
import { closeRail } from 'store/rail/actions';
import { getActivities } from 'store/activity/actions';

export const PrepareUploadActivitiesModal = (): ReactElement => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const onShowDeletedActivities = async (): Promise<void> => {
    await push('/activities/summary');
    await window.scroll(0, 0);
    await dispatch(closeRail());
    await dispatch(getActivities({ isDeleted: true }, false));
  };

  return (
    <>
      <h5>1&#41; Prepare your file for upload.</h5>
      <StaticModal
        buttonVariant="text"
        buttonText="Learn more about how to add, update or delete"
        titleText="Learn more about how to add, update or delete activities."
        actionVariant="done"
        actionTextPrimary="Done"
      >
        <ul>
          <li>Activities with “Add” specified in the file will get added to the system. </li>
          <li>
            Activities with “Update” specified in the file will get updated if matching Activity IDs are found in the
            system.
          </li>
          <li>
            Activities with “Delete” specified in the file will get updated if matching Activity IDs are found in the
            system. Deleted activities can be restored from the
            <Button className="link link--hyperlink" onClick={onShowDeletedActivities}>
              Deleted Activity Summary
            </Button>
            .
          </li>
          <li>**No action will be taken on any unmatched activities or activities with errors.</li>
        </ul>
      </StaticModal>
    </>
  );
};
