import { ThunkAction, ThunkDispatch } from 'redux-thunk';

// Core.
import {
  CACHE_DASHBOARD_BANNERS,
  CACHE_DASHBOARD_BANNERS_EXPIRY_MINS,
  CACHE_DASHBOARD_MESSAGES,
  CACHE_DASHBOARD_MESSAGES_EXPIRY_MINS,
  CACHE_DASHBOARD_NOTIFICATIONS,
  CACHE_DASHBOARD_NOTIFICATIONS_EXPIRY_MINS,
} from 'core/constants';
import { PARSAction } from 'core/models';

// Globals.
import { handleServerError } from 'globals/utils/handleServerError';

// Services.
import { CacheService } from 'services';
import { DashboardService } from 'services/DashboardService';

// Store.
import { AppState } from 'store';
import { popToast } from 'store/toast/actions';
import { errorToastOptions } from 'store/toast/constants';

// Types.
import {
  DISMISS_DASHBOARD_BANNER,
  DISMISS_DASHBOARD_MESSAGE,
  DISMISS_DASHBOARD_NOTIFICATION,
  GET_DASHBOARD,
  GET_DASHBOARD_BANNERS,
  GET_DASHBOARD_BANNERS_FAILURE,
  GET_DASHBOARD_BANNERS_SUCCESS,
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_MESSAGE,
  GET_DASHBOARD_MESSAGE_FAILURE,
  GET_DASHBOARD_MESSAGE_SUCCESS,
  GET_DASHBOARD_SUCCESS,
  IDashboard,
  IDashboardBanner,
  IDashboardMessage,
} from './types';

export const getDashboardAction = (): PARSAction<void, typeof GET_DASHBOARD> => ({
  type: GET_DASHBOARD,
});

export const getDashboardSuccessAction = (data: IDashboard): PARSAction<IDashboard, typeof GET_DASHBOARD_SUCCESS> => ({
  payload: data,
  type: GET_DASHBOARD_SUCCESS,
});

export const getDashboardFailureAction = (): PARSAction<void, typeof GET_DASHBOARD_FAILURE> => ({
  type: GET_DASHBOARD_FAILURE,
});

export const getDashboardBannersAction = (): PARSAction<void, typeof GET_DASHBOARD_BANNERS> => ({
  type: GET_DASHBOARD_BANNERS,
});

export const getDashboardBannersSuccessAction = (
  banners: IDashboardBanner[],
): PARSAction<IDashboardBanner[], typeof GET_DASHBOARD_BANNERS_SUCCESS> => ({
  payload: banners,
  type: GET_DASHBOARD_BANNERS_SUCCESS,
});

export const getDashboardBannersFailureAction = (): PARSAction<void, typeof GET_DASHBOARD_BANNERS_FAILURE> => ({
  type: GET_DASHBOARD_BANNERS_FAILURE,
});

export const getDashboardMessageAction = (): PARSAction<void, typeof GET_DASHBOARD_MESSAGE> => ({
  type: GET_DASHBOARD_MESSAGE,
});

export const getDashboardMessageSuccessAction = (
  message: IDashboardMessage,
): PARSAction<IDashboardMessage, typeof GET_DASHBOARD_MESSAGE_SUCCESS> => ({
  payload: message,
  type: GET_DASHBOARD_MESSAGE_SUCCESS,
});

export const getDashboardMessageFailureAction = (): PARSAction<void, typeof GET_DASHBOARD_MESSAGE_FAILURE> => ({
  type: GET_DASHBOARD_MESSAGE_FAILURE,
});

export const dismissDashboardBannerSuccess = (id: string): PARSAction<string, typeof DISMISS_DASHBOARD_BANNER> => ({
  payload: id,
  type: DISMISS_DASHBOARD_BANNER,
});

export const dismissDashboardMessageSuccess = (id: string): PARSAction<string, typeof DISMISS_DASHBOARD_MESSAGE> => ({
  payload: id,
  type: DISMISS_DASHBOARD_MESSAGE,
});

export const dismissDashboardNotificationSuccess = (
  id: string,
): PARSAction<string, typeof DISMISS_DASHBOARD_NOTIFICATION> => ({
  payload: id,
  type: DISMISS_DASHBOARD_NOTIFICATION,
});

export const getDashboard = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch: ThunkDispatch<AppState, null, PARSAction>,
): Promise<void> => {
  dispatch(getDashboardAction());

  try {
    const dashboard = await DashboardService.getDashboard();

    // Remove stale IDs from dismissed notifications.
    let dismissedNotifications: string[] = CacheService.get(CACHE_DASHBOARD_NOTIFICATIONS) ?? [];
    if (dashboard?.notifications) {
      dismissedNotifications = dismissedNotifications.filter((dismissedNotificationId) =>
        dashboard?.notifications?.some((notification) => notification.id === dismissedNotificationId),
      );
      CacheService.update(CACHE_DASHBOARD_NOTIFICATIONS, dismissedNotifications);
    }

    // Filter out dismissed notifications.
    const filteredNotifications = dashboard?.notifications?.filter(
      (notification) => !dismissedNotifications.includes(notification.id),
    );

    dispatch(getDashboardSuccessAction({ ...dashboard, notifications: filteredNotifications }));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getDashboard' });
    dispatch(getDashboardFailureAction());
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const getDashboardBanners = (
  organizationId: string,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch): Promise<void> => {
  dispatch(getDashboardBannersAction());

  try {
    const banners: IDashboardBanner[] = (await DashboardService.getDashboardBanners(organizationId)) || [];

    // Remove stale IDs from dismissed banners.
    let dismissedBanners: string[] = CacheService.get(CACHE_DASHBOARD_BANNERS) ?? [];
    dismissedBanners = dismissedBanners.filter((dismissedBannerId) =>
      banners?.some((banner) => banner.id === dismissedBannerId),
    );
    CacheService.update(CACHE_DASHBOARD_BANNERS, dismissedBanners);

    // Filter out dismissed banners.
    const filteredBanners = banners?.filter((banner) => !dismissedBanners.includes(banner.id));

    dispatch(getDashboardBannersSuccessAction(filteredBanners));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getDashboardBanners' });
    dispatch(getDashboardBannersFailureAction());
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const getDashboardMessage = (): ThunkAction<void, AppState, null, PARSAction> => async (dispatch) => {
  dispatch(getDashboardMessageAction());
  try {
    const message: IDashboardMessage =
      (await DashboardService.getDashboardMessage()) || (undefined as IDashboardMessage);

    // Remove stale IDs from dismissed message.
    let dismissedMessage: string[] = CacheService.get(CACHE_DASHBOARD_MESSAGES) ?? [];

    dismissedMessage = dismissedMessage.filter(
      (dismissedMessageId: string): boolean => message?.id === dismissedMessageId,
    );

    CacheService.update(CACHE_DASHBOARD_MESSAGES, dismissedMessage);

    // Filter out dismissed messages.
    const filteredMessages = dismissedMessage.includes(message?.id) ? undefined : message;

    dispatch(getDashboardMessageSuccessAction(filteredMessages));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getDashboardMessages' });
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
    dispatch(getDashboardMessageFailureAction());
  }
};

export const dismissDashboardBanner = (id: string): ThunkAction<void, AppState, null, PARSAction> => (
  dispatch,
): void => {
  const dismissedBanners: Set<string> = new Set(CacheService.get(CACHE_DASHBOARD_BANNERS) ?? []);
  dismissedBanners.add(id);
  CacheService.set(CACHE_DASHBOARD_BANNERS, Array.from(dismissedBanners), CACHE_DASHBOARD_BANNERS_EXPIRY_MINS);
  dispatch(dismissDashboardBannerSuccess(id));
};

export const dismissDashboardMessage = (id: string): ThunkAction<void, AppState, null, PARSAction> => (
  dispatch,
): void => {
  const dismissedMessages: Set<string> = new Set(CacheService.get(CACHE_DASHBOARD_MESSAGES) ?? []);
  dismissedMessages.add(id);
  CacheService.set(CACHE_DASHBOARD_MESSAGES, Array.from(dismissedMessages), CACHE_DASHBOARD_MESSAGES_EXPIRY_MINS);
  dispatch(dismissDashboardMessageSuccess(id));
};

export const dismissDashboardNotification = (id: string): ThunkAction<void, AppState, null, PARSAction> => (
  dispatch,
): void => {
  const dismissedNotifications: Set<string> = new Set(CacheService.get(CACHE_DASHBOARD_NOTIFICATIONS) ?? []);
  dismissedNotifications.add(id);
  CacheService.set(
    CACHE_DASHBOARD_NOTIFICATIONS,
    Array.from(dismissedNotifications),
    CACHE_DASHBOARD_NOTIFICATIONS_EXPIRY_MINS,
  );
  dispatch(dismissDashboardNotificationSuccess(id));
};
