import { ReactElement } from 'react';
import { Snackbar, SnackbarContent, Slide, makeStyles } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import classNames from 'classnames';

// Styles
import 'components/Toast/toast.module.scss';
import './staticToast.module.scss';

// Store + Core
import { IStaticToastOptions } from 'core/models';
import CustomTooltip from 'components/Tooltip/Tooltip';

function slideTransition(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles(() => ({
  toast: {
    width: '100%',
  },
  toastMessage: {
    backgroundColor: '#00838f',
    borderRadius: 0,
    width: '100%',
    '& > div': {
      display: 'inline-flex',
      margin: '0 auto',
      width: '55%',
    },
    '& > div > span': {
      display: 'inline-flex',
      margin: '0 auto',
      width: '55%',
    },
  },
}));

interface IStaticToastProps {
  isOpen: boolean;
  toastOptions?: IStaticToastOptions[];
}

const StaticToast = (props: IStaticToastProps): ReactElement => {
  const classes = useStyles();
  const { isOpen, toastOptions } = props;

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      className={classes.toast}
      open={isOpen}
      TransitionComponent={slideTransition}
    >
      <SnackbarContent
        className={classes.toastMessage}
        message={toastOptions.map((option: IStaticToastOptions) => {
          const element = (
            <div
              className={classNames('toast-content', 'static-toast-content', {
                'toast-content-centered': option?.centered,
                'toast-content-disabled': option?.disabled,
              })}
              onClick={!option?.disabled && option?.onClick}
              role="button"
            >
              {option?.icon || null}
              {option?.message}
            </div>
          );
          return option?.disabled && option?.disabledText ? (
            <CustomTooltip key={option?.toastType} tooltipText={option?.disabledText} aria-label={option?.disabledText}>
              {element}
            </CustomTooltip>
          ) : (
            <span>{element}</span>
          );
        })}
      />
    </Snackbar>
  );
};

export default StaticToast;
