import classNames from 'classnames';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { PropsWithChildren, ReactElement, useState } from 'react';

// Styles.
import styles from './DashboardSection.module.scss';

interface IDashboardSectionProps {
  className?: string;
  label?: string;
  toggleable?: boolean;
}

export const DashboardSection = (props: PropsWithChildren<IDashboardSectionProps>): ReactElement => {
  const { children, className, label, toggleable } = props;
  const [isVisible, setIsVisible] = useState<boolean>(true);

  return (
    <section className={classNames(styles.section, className)}>
      {(label || toggleable) && (
        <header className={styles.header}>
          {label && <h2>{label}</h2>}
          {toggleable && (
            <ToggleSwitch
              inputProps={{ title: label }}
              onChange={() => setIsVisible(!isVisible)}
              checked={isVisible}
              size="small"
            />
          )}
        </header>
      )}
      {isVisible && children}
    </section>
  );
};
