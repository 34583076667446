import { ReactElement, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardBackspaceRounded, PersonAddRounded } from '@material-ui/icons';
import { Box } from '@material-ui/core';

// Components
import { ContextualMenu, IContextualMenuProps } from 'components/ContextualMenu/ContextualMenu';
import Button from 'components/Button/Button';

// Styles
import styles from './bulkactions.module.scss';

// Store
import { isBoardUserSelector, organizationKindSelector } from 'store/user/selectors';
import { amaCreditTermIdSelector } from 'store/taxonomy/selectors';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { ActivitySearchResultActivity, ITaxonomyTerm, OrganizationKinds } from '../../core/models';
import { useClickEvent } from '../../hooks';
import { TAXONOMY_ACTIVITY_CREDIT_ROOT_ID } from 'core/constants';

export interface IBulkActionsProps {
  activityDetail?: ActivitySearchResultActivity;
  count?: number;
  countLabel?: string;
  downloadMenuProps?: IContextualMenuProps;
  exportMenuProps?: IContextualMenuProps;
  hasDownloadMenu?: boolean;
  hasSelectAll?: boolean;
  isSingleOrNoResult?: boolean;
  isNoResults: boolean;
  perPageMenuProps?: IContextualMenuProps;
  selectAllAction?: () => void;
  selectedAll?: boolean;
  sortAscending?: boolean;
  sortAscendingAction?: () => void;
  sortMenuProps?: IContextualMenuProps;
}

const ADD_LEARNERS_LABEL = 'Add Learners';

const BulkActions = (props: IBulkActionsProps): ReactElement => {
  const {
    activityDetail,
    count,
    countLabel,
    downloadMenuProps,
    hasDownloadMenu,
    hasSelectAll,
    isSingleOrNoResult,
    isNoResults,
    perPageMenuProps,
    selectAllAction,
    selectedAll,
    sortAscending,
    sortAscendingAction,
    sortMenuProps,
  } = props;
  const ascendingClass = classNames(
    {
      ascending: sortAscending,
      descending: !sortAscending,
    },
    'icon',
  );

  // Selectors.
  const organizationKind = useSelector(organizationKindSelector)?.organizationKind;
  const isBoardUser: boolean = useSelector(isBoardUserSelector);
  const amaCreditTerm: ITaxonomyTerm = useSelector(amaCreditTermIdSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    // If missing AMA Term Taxonomy, fetch credit terms
    if (!amaCreditTerm) {
      dispatch(getTaxonomyTermById(TAXONOMY_ACTIVITY_CREDIT_ROOT_ID));
    }
  }, [dispatch, amaCreditTerm]);

  const hasAmaCredits = !!activityDetail?.creditTypes?.find(({ creditTypeId }) => creditTypeId === amaCreditTerm?.id);
  const canAddLearners =
    hasAmaCredits || activityDetail?.boardMocDetails?.length > 0 || activityDetail?.remsDetails?.length > 0;

  const fireEvent = useClickEvent({ Event: 'learner', EventCategory: 'Learner' });

  return (
    <div className={styles['summary-actions']}>
      <div className={styles['summary-actions-options']}>
        <div className={styles['summary-actions-info']}>
          <div className={styles.count}>
            {count?.toLocaleString()} {countLabel}
          </div>
          {hasSelectAll && !isSingleOrNoResult && (
            <div className={styles['show-perpage']}>
              <ContextualMenu {...perPageMenuProps} />
            </div>
          )}
        </div>
        <Box display="flex">
          {hasDownloadMenu && (!isSingleOrNoResult || !isNoResults) && (
            <Box>
              <ContextualMenu {...downloadMenuProps} />
            </Box>
          )}
          {!!activityDetail && canAddLearners && organizationKind !== OrganizationKinds.BOARD && (
            <Box>
              <Button
                aria-label={`Add Learners to ${activityDetail.title}`}
                className={classNames(styles.button, 'btn label')}
                component={Link}
                onClick={() =>
                  fireEvent({
                    EventAction: ADD_LEARNERS_LABEL,
                    EventLabel: `/activities/${activityDetail.key}/add-learner`,
                  })
                }
                to={`/activities/${activityDetail.key}/add-learner`}
              >
                <PersonAddRounded />
                {ADD_LEARNERS_LABEL}
              </Button>
            </Box>
          )}
        </Box>
      </div>
      <div className={styles['summary-actions-controls']}>
        {selectAllAction && !isBoardUser && !isSingleOrNoResult ? (
          <div className={styles['select-control']}>
            <label className={styles['summary-actions--checkbox']}>
              <input type="checkbox" checked={selectedAll} onChange={selectAllAction} />
              <span className={classNames(styles.checkmark)}>
                <div className={styles['label-text-container']}>
                  <div className={styles['label-text']}>Select All</div>
                </div>
              </span>
            </label>
          </div>
        ) : (
          !isSingleOrNoResult && (
            <div className={styles['show-perpage']}>
              <ContextualMenu {...perPageMenuProps} />
            </div>
          )
        )}
        {!isSingleOrNoResult && (
          <div className={styles['sort-controls']}>
            <ContextualMenu {...sortMenuProps} />
            <Button className={ascendingClass} title="sort" onClick={sortAscendingAction}>
              <KeyboardBackspaceRounded />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BulkActions;
