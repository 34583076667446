import { AppState } from '../index';
import { ILeaernerValidationProviderFile, IProviderFile } from 'core/models';

export const activityProviderFilesSelector = ({ providerFiles }: AppState): IProviderFile[] =>
  providerFiles?.activityFiles || [];
export const providerFilesLoadingSelector = ({ providerFiles }: AppState): boolean => providerFiles?.isLoading;

export const learnerProviderFilesSelector = ({ providerFiles }: AppState): IProviderFile[] =>
  providerFiles?.learnerFiles || [];

export const learnerValidationProviderFilesSelector = ({
  providerFiles,
}: AppState): ILeaernerValidationProviderFile[] => providerFiles?.learnerValidationFiles || [];
