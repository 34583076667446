import { ReactElement } from 'react';
import { Field } from 'formik';
import { Box, Typography } from '@material-ui/core';

// Types
import { FormikCheckboxFieldProps } from 'components/forms/types';
interface IProps extends FormikCheckboxFieldProps {
  formikKey: string;
  name: string;
  /* @value: payloadValue
   * @description: `name` is used for the display value to the user on the FE.
   *                The BE may use a different key. If `payloadValue` is provided, its value will be used in the Formik model
   *                instead of the `name`, preserving the display value for the FE.
   */
  payloadValue?: string;
  suppressLabel?: boolean;
}

export const FormikRadioField = ({ formikKey, name, payloadValue, suppressLabel, ...rest }: IProps): ReactElement => {
  return (
    <Box display="flex">
      <label key={formikKey} className="form-input-radio">
        <Field
          id={`${formikKey}.${name}`}
          name={formikKey}
          title={payloadValue || name}
          type="radio"
          value={payloadValue || name}
          {...rest}
        />
        <span className="checkmark" />
        <Typography variant="srOnly">{name}</Typography>
      </label>
      {!suppressLabel && (
        <div className="label-text-container">
          <div className="label-text">{name}</div>
        </div>
      )}
    </Box>
  );
};
