// Libs
import { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { SearchRounded } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

// Styles
import styles from './FormikFields.module.scss';

export interface ITypeaheadOption {
  label?: string;
  value?: string;
}

export interface ITypeahead {
  asArray?: boolean;
  canOpen?: boolean;
  className?: string;
  inputValue?: string;
  label?: string;
  name?: string;
  onChange?(event: ChangeEvent, value: ITypeaheadOption): void;
  onInputChange?(inputValue: string): void;
  open?: boolean;
  openAfter?: number;
  options?: ITypeaheadOption[];
  placeholder?: string;
  required?: boolean;
  value?: ITypeaheadOption;
}

export const Typeahead = ({
  asArray,
  canOpen = true,
  className,
  inputValue: controlledInputValue,
  label,
  name,
  onChange,
  onInputChange,
  open,
  openAfter = 1,
  options = [],
  placeholder,
  required,
  value,
}: ITypeahead): ReactElement => {
  const [inputValue, setInputValue] = useState<string>(controlledInputValue || '');
  const [isOpen, setIsOpen] = useState<boolean>(open || false);

  const handleChange = useCallback(
    (event, value) => {
      if (event) onChange?.(event, value);
    },
    [onChange],
  );

  // Set input value.
  const handleInputChange = useCallback(
    (event, newInputValue): void => {
      if (event) {
        setInputValue(newInputValue);

        // Ensure suggestions open/close properly.
        if (newInputValue.length >= openAfter) {
          setIsOpen(canOpen);
        } else {
          setIsOpen(open || false);
        }

        // Pass inputValue back.
        onInputChange?.(newInputValue);
      }
    },
    [canOpen, onInputChange, open, openAfter],
  );

  // Ensure suggestions use default close trigger.
  const handleClose = useCallback((): void => setIsOpen(open || false), [open]);

  // Ensure suggestions use correct label.
  const getOptionLabel = useCallback((option: ITypeaheadOption) => option?.label || inputValue, [inputValue]);

  // Ensure suggestions are case-insensitive.
  const filterOptions = useCallback(
    (options: ITypeaheadOption[]): ITypeaheadOption[] =>
      options.filter((option: ITypeaheadOption): boolean => {
        return asArray
          ? option?.label?.toLowerCase().includes(inputValue?.[0]?.toLowerCase()) || false
          : option?.label?.toLowerCase().includes(inputValue?.toLowerCase()) || false;
      }),
    [inputValue, asArray],
  );

  // Render custom input.
  const renderSearchInput = useCallback(
    (params): ReactElement => {
      params.InputProps.startAdornment = (
        <InputAdornment position="start">
          <SearchRounded />
        </InputAdornment>
      );
      params.InputProps.endAdornment = null;
      return <TextField {...params} placeholder={placeholder} variant="outlined" />;
    },
    [placeholder],
  );

  useEffect(() => {
    if (typeof controlledInputValue !== 'undefined') setInputValue(controlledInputValue);
  }, [controlledInputValue]);

  return (
    <div className={styles.container}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </label>
      )}
      <Autocomplete
        className={className}
        filterOptions={filterOptions}
        freeSolo
        getOptionLabel={getOptionLabel}
        inputValue={inputValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onClose={handleClose}
        open={isOpen}
        options={options}
        renderInput={renderSearchInput}
        value={value}
      />
    </div>
  );
};
