import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { InputAdornment, TextField, makeStyles } from '@material-ui/core';
import { SearchRounded } from '@material-ui/icons';
import { FocusEvent, ReactElement } from 'react';

// Types
export interface ILabelType {
  description?: string;
  id: string;
  label: string;
  name?: string;
}

export interface ITypeaheadOption {
  id: string;
  label: string;
}
export interface ITypeaheadProps {
  id?: string;
  options?: ITypeaheadOption[];
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e, value: ITypeaheadOption | null) => void;
  value?: ITypeaheadOption;
  placeholder?: string;
  name?: string;
  className?: string;
}

const useStyles = makeStyles({
  input: {
    padding: '0.5rem 0.25rem !important',
  },
});

const Typeahead = (props: ITypeaheadProps): ReactElement => {
  const { options, placeholder, onChange, value, className, name } = props;

  const classes = useStyles();

  const renderField = (params: AutocompleteRenderInputParams): ReactElement => {
    params.InputProps.startAdornment = (
      <InputAdornment position="start">
        <SearchRounded />
      </InputAdornment>
    );
    params.InputProps.endAdornment = null;
    return (
      <TextField
        {...params}
        inputProps={{ ...params.inputProps, 'aria-label': placeholder, id: name }}
        placeholder={placeholder}
        variant="outlined"
      />
    );
  };
  const getOptionLabel = (option: ITypeaheadOption): string => option.label || '';

  return (
    <Autocomplete
      aria-label={props.name}
      classes={{
        input: classes.input,
      }}
      className={className}
      freeSolo
      getOptionLabel={getOptionLabel}
      inputValue={value?.label || ''}
      onChange={onChange}
      options={options || []}
      placeholder={placeholder}
      renderInput={renderField}
      style={{ minWidth: '50%' }}
      title={placeholder}
      value={value}
    />
  );
};

export default Typeahead;
