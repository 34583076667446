import { ReactElement, ReactNode, Fragment } from 'react';
import { Link } from 'react-router-dom';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { KeyboardBackspaceRounded } from '@material-ui/icons';
import classNames from 'classnames';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from 'core/constants';
import {
  BoardProcessingMetricsBase,
  BoardProcessingMetricsLearnerBase,
  BoardProcessingMetricsNoResult,
  BoardProcessingMetricsNotSent,
  BoardProcessingMetricsRejected,
  BoardProcessingMetricsStatusRate,
} from 'core/models';
import Button from 'components/Button/Button';
import { LoadingCards } from 'components/LoadingCard';
import { useLoadData } from 'hooks/useLoadData';
import { BoardProcessingMetricsService } from 'services/BoardProcessingMetricsService';

// Styles
import styles from './BoardProcessingMetrics.module.scss';

interface CardProps<T> {
  title: string;
  data: T[];
  extraFieldTitle?: string;
  extraField?: keyof T;
  showTransferStatus?: T extends BoardProcessingMetricsLearnerBase ? boolean : never;
}

interface DetailedCardProps<T> extends CardProps<T> {
  sortOrder: Array<keyof T>;
  buildSummary?: (data: T[]) => ReactNode;
  buildHeader: (data: T[]) => ReactNode;
  buildRow: (data: T) => ReactNode;
  buildFooter?: (data: T[]) => ReactNode;
}

const detailContentClass = styles['detail-content'];
const detailGroupClass = styles['detail-group'];
const detailGroupLabelClass = styles['detail-group-label'];

// shared rendering of a single card on this screen
const RenderCard = <T extends BoardProcessingMetricsBase>({
  title,
  data,
  buildHeader,
  sortOrder,
  buildFooter,
  buildRow,
  buildSummary,
}: DetailedCardProps<T>): ReactElement => (
  <section>
    <div className={detailGroupClass}>
      <h5 className={detailGroupLabelClass}>{title}</h5>
      <div className={detailContentClass}>
        {data.length ? (
          orderBy(Object.entries(groupBy(data, 'boardName')), '0').map(([boardName, boardData]) => (
            <Accordion key={boardData[0].boardAbbreviation}>
              <AccordionSummary>
                <h6>
                  {boardName ? (
                    <>
                      {boardName} ({boardData[0].boardAbbreviation})
                    </>
                  ) : (
                    boardData[0].boardAbbreviation
                  )}{' '}
                  ({buildSummary ? buildSummary(boardData) : sumBy(boardData, 'count')})
                </h6>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer role="grid">
                  <Table className={styles.table} size="small">
                    <TableHead className={styles.tableHeader}>{buildHeader(boardData)}</TableHead>
                    <TableBody>
                      {orderBy(boardData, sortOrder).map((i, ix) => (
                        <Fragment key={ix}>{buildRow(i)}</Fragment>
                      ))}
                    </TableBody>
                    {buildFooter ? <TableFooter>{buildFooter(boardData)}</TableFooter> : null}
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <div>No boards have any records that meet this criteria</div>
        )}
      </div>
    </div>
  </section>
);

// renders the data from a BoardProcessingMetricsStatusRate
const RenderStatusRate = ({ title, data }: CardProps<BoardProcessingMetricsStatusRate>): ReactElement => (
  <RenderCard
    title={title}
    data={data}
    sortOrder={['date']}
    buildSummary={(items) => {
      const total = sumBy(items, 'total');
      const rejected = sumBy(items, 'rejected');
      const rejectRate = total > 0 ? ((100 * rejected) / total).toFixed(2) : '0';
      const pending = sumBy(items, 'pending');
      const pendingRate = total > 0 ? ((100 * pending) / total).toFixed(2) : '0';
      return `${total} enqueued in last 30 days, ${rejected} (${rejectRate}%) rejected, ${pending} (${pendingRate}%) pending`;
    }}
    buildHeader={() => (
      <TableRow>
        <TableCell component="th">Date</TableCell>
        <TableCell component="th">Accepted</TableCell>
        <TableCell component="th">Rejected</TableCell>
        <TableCell component="th">Not Processed</TableCell>
        <TableCell component="th">Pending</TableCell>
        <TableCell component="th">Ignored</TableCell>
        <TableCell component="th">Total</TableCell>
        <TableCell component="th">Rejected Pct</TableCell>
        <TableCell component="th">Pending Pct</TableCell>
      </TableRow>
    )}
    buildRow={(i) => (
      <TableRow>
        {/** this is physically a UTC date over the wire, but it's logically a Central date, so displaying .utc() here is the right thing to do **/}
        <TableCell>{moment(i.date).utc().format(DEFAULT_DATE_FORMAT)}</TableCell>
        <TableCell align="right">{i.accepted}</TableCell>
        <TableCell align="right">{i.rejected}</TableCell>
        <TableCell align="right">{i.notProcessed}</TableCell>
        <TableCell align="right">{i.pending}</TableCell>
        <TableCell align="right">{i.ignored}</TableCell>
        <TableCell align="right">{i.total}</TableCell>
        <TableCell align="right">{i.rejectPct}</TableCell>
        <TableCell align="right">{i.pendingPct}</TableCell>
      </TableRow>
    )}
    buildFooter={(items) => {
      const accepted = sumBy(items, 'accepted');
      const rejected = sumBy(items, 'rejected');
      const notProcessed = sumBy(items, 'notProcessed');
      const pending = sumBy(items, 'pending');
      const ignored = sumBy(items, 'ignored');
      const total = sumBy(items, 'total');
      const rejectRate = total > 0 ? ((100 * rejected) / total).toFixed(2) : '0';
      const pendingRate = total > 0 ? ((100 * pending) / total).toFixed(2) : '0';
      return (
        <TableRow>
          <TableCell>Total</TableCell>
          <TableCell align="right" component="th">
            {accepted}
          </TableCell>
          <TableCell align="right" component="th">
            {rejected}
          </TableCell>
          <TableCell align="right" component="th">
            {notProcessed}
          </TableCell>
          <TableCell align="right" component="th">
            {pending}
          </TableCell>
          <TableCell align="right" component="th">
            {ignored}
          </TableCell>
          <TableCell align="right" component="th">
            {total}
          </TableCell>
          <TableCell align="right" component="th">
            {rejectRate}
          </TableCell>
          <TableCell align="right" component="th">
            {pendingRate}
          </TableCell>
        </TableRow>
      );
    }}
  />
);

// renders the data from a BoardProcessingMetricsNotSent
const RenderNotSent = <T extends BoardProcessingMetricsNotSent>({
  title,
  data,
  extraField,
  extraFieldTitle,
  showTransferStatus,
}: CardProps<T>): ReactElement => (
  <RenderCard
    title={title}
    data={data}
    sortOrder={['reason']}
    buildHeader={() => (
      <TableRow>
        <TableCell component="th">Reason</TableCell>
        {showTransferStatus && <TableCell component="th">LCCB Transfer Status</TableCell>}
        <TableCell align="center" component="th">
          Count
        </TableCell>
        <TableCell align="center" component="th">
          Oldest Updated Date
        </TableCell>
        {!!extraFieldTitle && (
          <TableCell align="center" component="th">
            {extraFieldTitle}
          </TableCell>
        )}
      </TableRow>
    )}
    buildRow={(i) => (
      <TableRow>
        <TableCell>{i.reason}</TableCell>
        {showTransferStatus && (
          <TableCell component="th">{((i as unknown) as BoardProcessingMetricsLearnerBase).transferStatus}</TableCell>
        )}
        <TableCell align="right">{i.count}</TableCell>
        <TableCell align="right">{moment(i.oldestUpdatedDate).format(DEFAULT_DATE_FORMAT)}</TableCell>
        <TableCell align="right">{i[extraField]}</TableCell>
      </TableRow>
    )}
  />
);

// renders the data from a BoardProcessingMetricsNoResult
const RenderNoResult = <T extends BoardProcessingMetricsNoResult>({
  title,
  data,
  extraField,
  extraFieldTitle,
  showTransferStatus,
}: CardProps<T>): ReactElement => (
  <RenderCard
    title={title}
    data={data}
    sortOrder={['hoursSinceCreated', 'action']}
    buildHeader={() => (
      <TableRow>
        <TableCell component="th">Hours Since Created</TableCell>
        <TableCell align="center" component="th">
          Action
        </TableCell>
        {showTransferStatus && <TableCell component="th">LCCB Transfer Status</TableCell>}
        <TableCell align="center" component="th">
          Count
        </TableCell>
        {!!extraFieldTitle && (
          <TableCell align="center" component="th">
            {extraFieldTitle}
          </TableCell>
        )}
      </TableRow>
    )}
    buildRow={(i) => (
      <TableRow>
        <TableCell align="right" title={moment().subtract(i.hoursSinceCreated, 'hours').format(DEFAULT_DATE_FORMAT)}>
          {i.hoursSinceCreated}
        </TableCell>
        <TableCell>{i.action}</TableCell>
        {showTransferStatus && (
          <TableCell component="th">{((i as unknown) as BoardProcessingMetricsLearnerBase).transferStatus}</TableCell>
        )}
        <TableCell align="right">{i.count}</TableCell>
        <TableCell align="right">{i[extraField]}</TableCell>
      </TableRow>
    )}
  />
);

// renders the data from a BoardProcessingMetricsRejected
const RenderRejected = <T extends BoardProcessingMetricsRejected>({
  title,
  data,
  extraField,
  extraFieldTitle,
  showTransferStatus,
}: CardProps<T>): ReactElement => (
  <RenderCard
    title={title}
    data={data}
    sortOrder={['daysSinceQueueUpdated', 'errorCode', 'action']}
    buildHeader={() => (
      <TableRow>
        <TableCell component="th">Days Since Queue Updated</TableCell>
        <TableCell align="center" component="th">
          Error Code
        </TableCell>
        <TableCell align="center" component="th">
          Error Description
        </TableCell>
        <TableCell align="center" component="th">
          Action
        </TableCell>
        {showTransferStatus && <TableCell component="th">LCCB Transfer Status</TableCell>}
        <TableCell align="center" component="th">
          Count
        </TableCell>
        {!!extraFieldTitle && (
          <TableCell align="center" component="th">
            {extraFieldTitle}
          </TableCell>
        )}
      </TableRow>
    )}
    buildRow={(i) => (
      <TableRow>
        <TableCell align="right">{i.daysSinceQueueUpdated}</TableCell>
        <TableCell>{i.errorCode}</TableCell>
        <TableCell>{i.errorDescription}</TableCell>
        <TableCell>{i.action}</TableCell>
        {showTransferStatus && (
          <TableCell component="th">{((i as unknown) as BoardProcessingMetricsLearnerBase).transferStatus}</TableCell>
        )}
        <TableCell align="right">{i.count}</TableCell>
        <TableCell align="right">{i[extraField]}</TableCell>
      </TableRow>
    )}
  />
);

// main page component
export const BoardProcessingMetricsPage = (): ReactElement => {
  const { data, error, isLoading, refresh } = useLoadData(
    'getBoardProcessingMetrics',
    BoardProcessingMetricsService.getData,
  );

  return (
    <>
      <div className="form-container">
        <div className="page-navigation">
          <Link className="tertiary" to="/admin">
            <KeyboardBackspaceRounded className="tertiary-icon-back" />
            Admin
          </Link>
        </div>
        <div className="form-title-container">
          <div className={classNames('title', styles.title)}>
            <h4>Board Processing Metrics</h4>
            <div className={styles.refresh}>
              <Button className="secondary" onClick={refresh}>
                Refresh
              </Button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <LoadingCards count={3} />
        ) : data ? (
          <div>
            <RenderStatusRate title="Activities Status by Day" data={data.activitiesStatusRate} />
            <RenderStatusRate title="Learners Status by Day" data={data.learnersStatusRate} />

            <RenderNotSent
              title="Number of activities not sent by reason by board"
              data={data.activitiesNotSent}
              extraField="oneActivityId"
              extraFieldTitle="One Activity Id"
            />
            <RenderNotSent
              title="Number of learners not sent by reason by board"
              data={data.learnersNotSent}
              extraField="oneLccbId"
              extraFieldTitle="One LCCB Id"
              showTransferStatus
            />

            <RenderNoResult
              title="Queued Activities not processed by board by action by hours since create"
              data={data.activitiesNotProcessed}
              extraField="oneActivityId"
              extraFieldTitle="One Activity Id"
            />
            <RenderNoResult
              title="Queued Learners not processed by board by action by hours since create"
              data={data.learnersNotProcessed}
              extraField="oneLccbId"
              extraFieldTitle="One LCCB Id"
              showTransferStatus
            />
            <RenderNoResult
              title="Queued Activities pending by board by action by hours since create"
              data={data.activitiesPending}
              extraField="oneActivityId"
              extraFieldTitle="One Activity Id"
            />
            <RenderNoResult
              title="Queued Learners pending by board by action by hours since create"
              data={data.learnersPending}
              extraField="oneLccbId"
              extraFieldTitle="One LCCB Id"
              showTransferStatus
            />

            <RenderRejected
              title="Queued Activities rejected by board by action by reason by days since Q update (max 14 days)"
              data={data.activitiesRejected}
              extraField="oneActivityId"
              extraFieldTitle="One Activity Id"
            />
            <RenderRejected
              title="Queued Learners rejected by board by action by reason by days since Q update (max 14 days)"
              data={data.learnersRejected}
              extraField="oneLccbId"
              extraFieldTitle="One LCCB Id"
              showTransferStatus
            />
          </div>
        ) : (
          <div className={detailGroupClass}>
            <h5 className={detailGroupLabelClass}>Error</h5>
            <div className={detailContentClass}>{error || 'Unknown error'}</div>
          </div>
        )}
      </div>
    </>
  );
};
