import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';

// Reducers
import activitiesReducer from './activity/reducer';
import attestationReducer from './attestation/reducer';
import bctReducer from './bct/reducer';
import boardReducer from './board/reducer';
import contentTagReducer from './contentTags/reducer';
import { dashboardReducer } from './dashboard/reducer';
import faqReducer from './faq/reducer';
import fileDownloadReducer from './fileDownload/reducer';
import formTemplateReducer from './formTemplate/reducer';
import { helpDeskReducer } from './helpDesk/reducer';
import learnerReducer, { learnerValidationReducer } from './learner/reducer';
import modalReducer from './modal/reducer';
import programSummaryReducer from './programSummary/reducer';
import providerFilesReducer from './providerFiles/reducer';
import railReducer from './rail/reducer';
import reportReducer from './report/reducer';
import taxonomyReducer from './taxonomy/reducer';
import toastReducer from './toast/reducer';
import userReducer from './user/reducer';
import locationsReducer from './locations/reducer';
import searchSynonymReducer from './search-synonym/reducer';

const rootReducer = combineReducers({
  // all reducers go in this object to be combined
  activitiesState: activitiesReducer,
  attestationState: attestationReducer,
  bctState: bctReducer,
  boardState: boardReducer,
  contentTagsState: contentTagReducer,
  dashboardState: dashboardReducer,
  faqState: faqReducer,
  fileDownloadState: fileDownloadReducer,
  formTemplateState: formTemplateReducer,
  helpDesk: helpDeskReducer,
  learnerState: learnerReducer,
  learnerValidationState: learnerValidationReducer,
  locationsState: locationsReducer,
  modalState: modalReducer,
  programSummaryState: programSummaryReducer,
  providerFiles: providerFilesReducer,
  railState: railReducer,
  reportState: reportReducer,
  searchSynonymState: searchSynonymReducer,
  taxonomyState: taxonomyReducer,
  toastState: toastReducer,
  userState: userReducer,
});

const { location, push } = createBrowserHistory();

export type AppState = ReturnType<typeof rootReducer>;

const middlewares = [thunk.withExtraArgument({ appRoute: location.pathname, push })];

if (process.env.NODE_ENV !== `production`) {
  // Add logging middleware
  const logger = createLogger({
    collapsed: true,
  });
  if (module.hot) {
    module.hot.accept('./', () => {
      store.replaceReducer(rootReducer);
    });
  }
  middlewares.push(logger);
}

// Hook up react developer tools if installed
const enhancedWindow: any = window;
const enhancer = enhancedWindow.devToolsExtension
  ? compose(applyMiddleware(...middlewares), enhancedWindow.devToolsExtension())
  : compose(applyMiddleware(...middlewares));

export const store = createStore(rootReducer, enhancer);
