import { ChangeEvent, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, useFormikContext } from 'formik';
import { CircularProgress, Grid } from '@material-ui/core';
import get from 'lodash/get';

// Components
import Button from 'components/Button/Button';
import { FormikCheckboxField } from 'components/ContinuousImprovement/PureFormElements';

// Core + Store
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { getTaxonomyStateById } from 'store/taxonomy/selectors';
import { ITaxonomyTerm } from 'core/models';

// Types
import { ILearnerCompletionOption } from 'layouts/pages/bct/types';

interface IProps {
  formikKey: string;
  formikTempKey: string;
  onCancel(): void;
  onSave(): void;
  option: ILearnerCompletionOption;
}
export const TaxonomyModalForm = (props: IProps): ReactElement => {
  const { formikKey, formikTempKey, onSave, onCancel, option } = props;
  const dispatch = useDispatch();
  const { setFieldValue, values } = useFormikContext();
  const taxonomyTermIds: string[] = get(values, formikKey)?.taxonomyTermIds || [];
  const tempValues: string[] = get(values, formikTempKey) || [];

  // Selectors.
  const taxonomy: ITaxonomyTerm = useSelector(getTaxonomyStateById(option?.taxonomyTerm));

  // Update the initial `taxonomyTermIds` and set them as the `temp` on initial render.
  // We only alter the `temp` values when checking/unchecking. On modal save, update the `taxonomyTermIds` array to what
  // is in `temp` for final form submission.
  useEffect(() => {
    if (taxonomyTermIds?.length && taxonomy) {
      setFieldValue(formikTempKey, taxonomyTermIds);
    }
  }, [formikTempKey, setFieldValue, taxonomy]);

  // Handlers.
  const onChangeCheckbox = ({ e, id }): void => {
    const isChecked: boolean = e.target.checked;
    // We are only updating the `temp` array and leaves the `taxonomyTermIds` pure, `taxonomyTermIds` gets updated only on save.
    //
    // If new value is checked, add it to the temp array.
    if (isChecked) {
      setFieldValue(formikTempKey, [...tempValues, id]);
    } else {
      // Otherwise, remove it from the array.
      setFieldValue(
        formikTempKey,
        tempValues.filter((existingId: string): boolean => existingId !== id),
      );
    }
  };

  // Fetch the related taxonomy term via the id.
  useEffect(() => {
    if (!taxonomy) {
      dispatch(getTaxonomyTermById(option?.taxonomyTerm));
    }
  }, [dispatch, option?.taxonomyTerm, taxonomy]);

  // Show this while data is loading.
  if (!taxonomy)
    return (
      <Grid container justify="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );

  return (
    <div id="transition-modal-description">
      {/* ^id is needed for a11y. */}
      <FieldArray
        name={formikKey}
        render={() => {
          const { terms } = taxonomy;

          return terms.map(
            ({ name, id }, idx: number): ReactElement => (
              <FormikCheckboxField
                checked={tempValues.includes(id)}
                formikKey={`${formikKey}.taxonomyTermIds`}
                key={idx}
                name={name}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => onChangeCheckbox({ e, id })}
                payloadValue="id"
              />
            ),
          );
        }}
      />
      <div className="modal-button-row">
        <Button className="primary" onClick={onSave}>
          Save
        </Button>
        <Button className="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};
