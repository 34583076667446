import classNames from 'classnames';
import { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components.
import { DashboardBanner } from './DashboardBanner';

// Store.
import { IDashboardBanner } from 'store/dashboard/types';
import { rollupOrganizationEnumSelector } from 'store/user/selectors';

// Styles.
import styles from './DashboardBanners.module.scss';
import { RollupOrganizationEnums } from 'core/models';

interface IDashboardBannersProps {
  banners: IDashboardBanner[];
  className?: string;
}

export const DashboardBanners = (props: IDashboardBannersProps): ReactElement => {
  const { banners = [], className } = props;
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  const isJa = rollupOrganizationEnum === RollupOrganizationEnums.JA;
  const filteredBanners = useMemo(() => banners.filter((banner) => (isJa ? banner.isJa : !banner.isJa)), [
    isJa,
    banners,
  ]);

  return (
    banners?.length > 0 && (
      <section className={classNames(styles.banners, className)}>
        <ul>
          {filteredBanners.map((banner) => (
            <li key={banner.id}>
              <DashboardBanner banner={banner} />
            </li>
          ))}
        </ul>
      </section>
    )
  );
};
