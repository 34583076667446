import { Fragment, ReactElement } from 'react';
import classNames from 'classnames';
import { Field, FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import * as Yup from 'yup';
import { get, groupBy, isEmpty, min, orderBy, sortBy, uniqueId } from 'lodash';
import * as PARS from 'core';
import { Box, Grid, Typography } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';

// Components
import Accordion from 'components/Accordion/Accordion';
import CustomTooltip from 'components/Tooltip/Tooltip';
import DatePicker from 'components/DatePicker/DatePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import InputBlock from 'components/global/inputBlock/inputBlock';
import Modal from 'components/Modal';
import PillCheckbox, { IInputItem } from 'components/PillCheckbox/PillCheckbox';
import RepeaterControl from 'components/global/repeater/RepeaterControl';
import Typeahead, { ITypeaheadOption } from 'components/Typeahead/Typeahead';
import { Multi } from 'components/Typeahead/Multi';
import { DatePickerRepeater } from 'components/DatePicker/DatePickerRepeater';
import { FormikNumberField } from 'components/forms';
import FormikConditionalCheckboxes from 'components/forms/FormikConditionalCheckboxes/FormikConditionalCheckboxes';
import { FormikCheckboxConditionalNumberInput } from 'components/forms/FormikCheckboxConditionalNumberInput';
import { FieldCaption } from 'components/FieldCaption';
import { ABAContentOutlines } from 'components/ABAContentOutlines';
import { DictionaryTextField } from 'components/ContinuousImprovement/DictionaryTextField';
import { FormikBooleanCheckboxField } from 'components/ContinuousImprovement/PureFormElements';
import { HighlightBox } from 'components/HighlightBox';

// Core
import boardTemplate from '../board-template.json';
import remsTemplate from '../rems-template.json';
import stateTaggingTemplate from '../statetagging-template.json';
import { CamlService } from './CamlService';
import {
  Activity,
  AddActivityFormValues,
  BaseActivity,
  ContentTag,
  IBCTSpecialty,
  IBoardSlim,
  IDictionary,
  IDisabledContext,
  IDisabledState,
  IFormGroup,
  IFormInput,
  IFormInputOption,
  IFormItem,
  IFormSection,
  IModalContentItem,
  ITaxonomyDictionary,
  ITaxonomyTerm,
  RollupOrganizationEnums,
} from 'core/models';
import { RepeaterEnum } from 'core/enums';
import { getTaxonomyChildByTag } from 'globals/utils/getTaxonomyChildByTag';
import { IBCTBoard, IBCTCreditType, IBoardAdditionalFields } from 'layouts/pages/bct/types';
import moment, { Moment } from 'moment';
import { filterTaxonomyByRollupOrganizationEnum } from 'globals/utils/filterTaxonomyByOrg';
import { getRemsRegularExpression } from 'layouts/pages/activity/utils';
import FormikConditionalCheckboxesGrid from 'components/forms/FormikConditionalCheckboxesGrid/FormikConditionalCheckboxesGrid';
import FormikConditionalNestedCheckboxes from 'components/forms/FormikConditionalNestedCheckboxes/FormikConditionalNestedCheckboxes';
import { EditContentTags } from 'components/EditContentTags/EditContentTags';

interface IFormServiceProps {
  activeStepLabel?: string;
  activities?: Activity[];
  allMocBoards?: IBoardSlim[];
  allRemsBoards?: IBoardSlim[];
  allContentTags: ContentTag[];
  allPharmacyRecertificationTaxonomyTerms?: Array<String>;
  amaCreditValue?: number;
  configurationDictionary?: IDictionary<IBCTBoard>;
  configuredMocBoards?: IBCTBoard[];
  configuredRemsBoards?: IBCTBoard[];
  disabledContext?: IDisabledContext;
  formikBag: {
    errors: FormikErrors<AddActivityFormValues>;
    handleChange;
    initialValues?: Partial<AddActivityFormValues>;
    setFieldValue: FormikHelpers<AddActivityFormValues>['setFieldValue'];
    touched: FormikTouched<AddActivityFormValues>;
    values: AddActivityFormValues;
  };
  formMeta: IFormSection[];
  rollupOrganizationEnum?: RollupOrganizationEnums;
  selectedRemsBoardIds?: string[];
  selectedSpecialtyBoardIds?: string[];
  terms: { [id: string]: ITaxonomyTerm };
}

interface IBaseBuildProps {
  stateContentTagId?: string;
  boardMocDetailId?: string;
  inputDetails: IFormInput;
  isSubgroup?: boolean;
  remsId?: string;
  sectionId: string;
}

interface IBuildRenderedInputProps extends Omit<IBaseBuildProps, 'isSubgroup' | 'sectionId'> {
  isRequired: boolean;
}

type IBuildInputProps = Omit<IBaseBuildProps, 'isSubgroup'>;

interface IBuildItemProps extends Omit<IBaseBuildProps, 'inputDetails'> {
  item: IFormItem;
}

interface IBuildGroupProps extends Omit<IBaseBuildProps, 'inputDetails'> {
  group: IFormGroup;
}

interface IRenderLabelProps {
  name: string;
  title: string;
  suppressLabel: boolean;
  required: boolean;
  hasGroupTitle?: boolean;
  tooltipContent?: string;
}

export const NoOptionsWarning = (): ReactElement => {
  return (
    <HighlightBox variant="warning">
      <Box display="flex" component="p">
        <WarningRounded />
        <Box ml={2}>No options available</Box>
      </Box>
    </HighlightBox>
  );
};

export abstract class FormService {
  private static activeStepLabel: string;
  private static activities: Activity[];
  private static allMocBoards: IBoardSlim[];
  private static allRemsBoards: IBoardSlim[];
  private static allContentTags: ContentTag[];
  private static amaCreditValue?: number;
  private static camlService: CamlService = new CamlService();
  private static configurationDictionary: IDictionary<IBCTBoard>;
  private static configuredMocBoards: IBCTBoard[];
  private static configuredRemsBoards: IBCTBoard[];
  private static disabledContext: IDisabledContext;
  private static errors;
  private static handleChange;
  private static initialValues = {};
  private static selectedRemsBoardIds: string[];
  private static selectedSpecialtyBoardIds: string[];
  private static setFieldValue;
  private static terms: ITaxonomyDictionary = {};
  private static touched;
  private static validationObject = {};
  private static values: AddActivityFormValues;
  private static rollupOrganizationEnum?: RollupOrganizationEnums;

  public static buildForm = ({
    activeStepLabel = '',
    activities,
    allMocBoards,
    allRemsBoards,
    allContentTags,
    amaCreditValue,
    configurationDictionary,
    configuredMocBoards,
    configuredRemsBoards,
    disabledContext,
    formikBag: { errors, handleChange, initialValues = {}, setFieldValue, touched, values },
    formMeta,
    rollupOrganizationEnum,
    selectedRemsBoardIds,
    selectedSpecialtyBoardIds,
    terms,
  }: IFormServiceProps): Array<JSX.Element> => {
    if (!formMeta) return [];
    FormService.activeStepLabel = activeStepLabel;
    FormService.activities = activities;
    FormService.allMocBoards = allMocBoards;
    FormService.allRemsBoards = allRemsBoards;
    FormService.allContentTags = allContentTags;
    FormService.amaCreditValue = amaCreditValue;
    FormService.configurationDictionary = configurationDictionary;
    FormService.configuredMocBoards = configuredMocBoards;
    FormService.configuredRemsBoards = configuredRemsBoards;
    FormService.disabledContext = disabledContext;
    FormService.errors = errors;
    FormService.handleChange = handleChange;
    FormService.initialValues = initialValues;
    FormService.rollupOrganizationEnum = rollupOrganizationEnum;
    FormService.selectedRemsBoardIds = selectedRemsBoardIds;
    FormService.selectedSpecialtyBoardIds = selectedSpecialtyBoardIds;
    FormService.setFieldValue = setFieldValue;
    FormService.terms = terms;
    FormService.touched = touched;
    FormService.values = values;

    // TODO confirm if we really need to do this object guard
    return formMeta
      .map((section) => FormService.buildSection(section))
      .filter((element) => element !== null && element !== undefined)
      .map((element) => element!);
  };

  private static buildSection = (section: IFormSection): JSX.Element | undefined => {
    const isStateContentTaggingSection = section.Id === 'State Content Tagging';

    if (!FormService.camlService.Evaluate(section.Visible, FormService.values)) return;

    return (
      <div data-template-id={section.Id} key={section.Id} className="form-section">
        {section.Children?.map((child) => FormService.buildItem({ item: child, sectionId: section.Id }))}
        {isStateContentTaggingSection && <EditContentTags />}
        {isStateContentTaggingSection && FormService.buildStateContentTaggingControls()}
      </div>
    );
  };

  private static buildItem = ({
    sectionId,
    item,
    isSubgroup = false,
    boardMocDetailId,
    remsId,
  }: IBuildItemProps): JSX.Element | undefined => {
    // @ts-ignore
    if (item.Type !== null && item.Type !== undefined) {
      return FormService.buildInput({ boardMocDetailId, inputDetails: item as IFormInput, remsId, sectionId });
    } else {
      return FormService.buildGroup({ boardMocDetailId, group: item as IFormGroup, isSubgroup, remsId, sectionId });
    }
  };

  /**
   * @function getMaximumContentOutlines
   * @param {string} boardMocDetailId
   */
  private static getMaximumContentOutlines = (boardMocDetailId: string): number =>
    FormService.configuredMocBoards?.find(({ id }: IBCTBoard) => id === boardMocDetailId)?.maximumContentOutlines ?? 2;

  private static buildGroup = ({
    group,
    isSubgroup = false,
    sectionId,
    boardMocDetailId,
    stateContentTagId,
    remsId,
  }: IBuildGroupProps): JSX.Element | undefined => {
    if (!FormService.camlService.Evaluate(group.Visible, FormService.values)) {
      // If a condition causes an input to be removed from the form, reset it's Children's values
      group.Children?.forEach(({ Id }) => {
        if (Id !== 'locationType') {
          FormService.setFieldValue(Id, FormService.initialValues[Id]);
        }
      });
      return;
    }
    const hasGroupTitle = !!group.Title && !group.HideGroupTitleStyle && !isSubgroup;
    const groupBoard = FormService.configuredMocBoards?.find(
      (board: IBCTBoard): boolean => board.id === boardMocDetailId,
    );
    const groupCollab = FormService.configuredRemsBoards?.find((collab: IBCTBoard): boolean => collab?.id === remsId);
    const derivedTitle = boardMocDetailId
      ? `${groupBoard?.name} (${groupBoard?.abbreviation})`
      : remsId
      ? `${groupCollab?.name}`
      : group.Title;
    return (
      // TODO change class
      <div
        key={group.Id}
        data-group-id={group.Id}
        className={classNames(!stateContentTagId ? 'form-group' : 'form-section', group.Variant, {
          'form-group-title': hasGroupTitle,
          'form-sub-group': isSubgroup,
        })}
      >
        {/* TODO revisit suppressLabel value */}
        {(!boardMocDetailId || (boardMocDetailId && !isSubgroup)) &&
          (!remsId || (remsId && !isSubgroup)) &&
          FormService.renderLabel({
            hasGroupTitle,
            name: group.Id,
            required: group.IsRequired === 'True',
            suppressLabel: true,
            title: derivedTitle,
            tooltipContent: group.TooltipContent,
          })}
        {group.LabelCaption && (
          <div className="caption-text" dangerouslySetInnerHTML={{ __html: group.LabelCaption }} />
        )}
        {group.ModalTitle && group.ModalContent && (
          <Modal buttonVariant="text" buttonText={group.ModalTitle} titleText={group.ModalTitle}>
            <Accordion
              items={group.ModalContent.map((item: IModalContentItem) => ({
                content: item.Content,
                title: item.Title,
              }))}
            />
          </Modal>
        )}
        {/* TODO confirm group will always have either its own input or children but not both */}
        {!stateContentTagId &&
          group.Children.map((subGroup: IFormItem) =>
            FormService.buildItem({ boardMocDetailId, isSubgroup: true, item: subGroup, remsId, sectionId }),
          )}

        {stateContentTagId &&
          group.Children.map((item: IFormInput) =>
            FormService.buildItem({
              stateContentTagId,
              isSubgroup: isSubgroup,
              item: item,
              sectionId,
            }),
          )}
        {/* group.InputCaption && (
          <div className="caption-text" dangerouslySetInnerHTML={{ __html: group.InputCaption }} />
        ) */}
      </div>
    );
  };

  private static buildInput = ({
    boardMocDetailId,
    inputDetails,
    remsId,
    sectionId,
  }: IBuildInputProps): JSX.Element | undefined => {
    if (!FormService.camlService.Evaluate(inputDetails.Visible, FormService.values)) {
      // If a condition causes an input to be removed from the form, reset it's value
      if (inputDetails?.Id !== 'locationType') {
        FormService.setFieldValue(inputDetails?.Id, FormService.initialValues[inputDetails?.Id]);
      }
      return;
    }
    // TODO change from mixed to more specific if possible
    const isRequired: boolean = FormService.camlService.Evaluate(inputDetails.Required, FormService.values);
    if (FormService.activeStepLabel === sectionId) {
      FormService.validationObject[inputDetails.Id] = isRequired ? Yup.mixed().required('Required field') : Yup.mixed();
    }
    const { HideErrorContent, Id, Type, Variant } = inputDetails;
    const isMultiInput =
      Type === 'Boolean' ||
      Type === 'MultiChoiceBoolean' ||
      Type === 'MultiChoiceWithNumber' ||
      Type === 'MultiChoice' ||
      Type === 'MultiChoiceGrid' ||
      Type === 'NestedMultiChoice' ||
      Type === 'MultiChoicePill' ||
      Type === 'MultiTextDictionary' ||
      Type === 'Taxonomy' ||
      (Type === 'TaxonomyMulti' && !boardMocDetailId);
    const isMOCBoardRepeater: boolean = Type === 'MocBoardRepeater';
    const isREMSRepeater: boolean = Type === 'RemsRepeater';

    const hasGroupTitle = false;
    if (Type === RepeaterEnum.REPEAT_TYPEAHEAD && !FormService.values[Id]) {
      FormService.setFieldValue(Id, [{ id: '', label: '' }]);
    } else if (Type === RepeaterEnum.REPEAT_MULTI_TYPEAHEAD && !FormService.values[Id]) {
      FormService.setFieldValue(Id, [{ amountGiven: null, source: '', id: uniqueId('repeatmultitypeahead_') }]);
    }

    const amaTerm = getTaxonomyChildByTag({
      rootId: PARS.Constants.TAXONOMY_ACTIVITY_CREDIT_ROOT_ID,
      tag: PARS.Constants.TAGS_ENUM.ACTIVITY_CREDIT_TYPE__AMA_PRA_CATEGORY1,
      terms: FormService.terms,
    });

    // Hard-coded check for sequence number to fix error messages
    const isSequenceNumber = Id === 'pharmacySequenceNumber';

    // Hard-coded REMS visiblity check
    if (Type === 'MultiTextDictionary' && !inputDetails.Options?.length) {
      return;
    }

    return (
      // TODO change class
      <div
        key={inputDetails.Id}
        data-sub-group-id={Id}
        className={classNames('form-group', inputDetails.Variant, {
          'form-group-title': hasGroupTitle,
          'form-sub-group': true,
        })}
      >
        {/* TODO revisit suppressLabel value */}
        {Type !== RepeaterEnum.REPEAT_MULTI_TYPEAHEAD
          ? FormService.renderLabel({
              hasGroupTitle: hasGroupTitle,
              name: inputDetails.Id,
              required: isRequired,
              suppressLabel: true,
              title: inputDetails.Title,
              tooltipContent: inputDetails.TooltipContent,
            })
          : null}
        {inputDetails.LabelCaption && (
          <div className="caption-text" dangerouslySetInnerHTML={{ __html: inputDetails.LabelCaption }} />
        )}
        {inputDetails.ModalTitle && inputDetails.ModalContent && (
          <Modal buttonVariant="text" buttonText={inputDetails.ModalTitle} titleText={inputDetails.ModalTitle}>
            {inputDetails.ModalSubtitle && (
              <div className="subtitle" dangerouslySetInnerHTML={{ __html: inputDetails.ModalSubtitle }} />
            )}
            <Accordion
              items={inputDetails.ModalContent.map(({ Content, Title }: IModalContentItem) => {
                return { content: Content, title: Title };
              })}
            />
          </Modal>
        )}
        {/* TODO confirm group will always have either its own input or children but not both */}
        {isMOCBoardRepeater ? (
          FormService.buildSingleInput({ boardMocDetailId, inputDetails, isRequired })
        ) : isREMSRepeater ? (
          FormService.buildSingleInput({
            inputDetails,
            isRequired: false,
            remsId,
          })
        ) : (
          <InputBlock
            ariaLabel={Id}
            errors={FormService.errors}
            isHiddenErrors={HideErrorContent || isSequenceNumber}
            name={
              boardMocDetailId
                ? `boardMocDetails.${boardMocDetailId}.${Id}`
                : remsId
                ? `boardRemsDetails.${remsId}.${Id}`
                : Id
            }
            required={isRequired}
            role="group"
            suppressLabel={isMultiInput}
            touched={FormService.touched}
            variant={Variant}
          >
            {isMultiInput
              ? FormService.buildMultiInput({ boardMocDetailId, inputDetails, isRequired, remsId })
              : FormService.buildSingleInput({ boardMocDetailId, inputDetails, isRequired, remsId })}
            {Id !== 'isMips' && inputDetails.InputCaption && (
              <FieldCaption htmlContent={inputDetails.InputCaption} formikKey={Id} />
            )}
            {/* Display the InputCaption field only if MIPS is selected as true and the AMA Cat 1 Credit Amount is 2.5 points or less. */}
            {Id === 'isMips' &&
            FormService.values.isMips === 'isMips-true' &&
            FormService.values.credits?.[amaTerm?.id] &&
            (FormService.values.credits?.[amaTerm?.id] as number) <= 2.5 &&
            inputDetails.InputCaption ? (
              <FieldCaption
                htmlContent={inputDetails.InputCaption.replace(
                  '{credits}',
                  FormService.values.credits[amaTerm.id]?.toString(),
                )}
                formikKey={Id}
              />
            ) : null}
          </InputBlock>
        )}
      </div>
    );
  };

  private static buildMultiInput = ({
    boardMocDetailId,
    inputDetails,
    isRequired,
    remsId,
  }: IBuildRenderedInputProps): JSX.Element | Array<JSX.Element> => {
    const { Id, Options, Type, Title, TermSetId, UseIntermediateTerms } = inputDetails;
    let derivedOptions: IFormInputOption[] = Options;
    const isCredits = inputDetails.Id === 'credits';
    const isCommendationCriteria = inputDetails.Id === 'commendationCriteriaIds';
    const isOutcomesMeasured = inputDetails.Id === 'outcomesMeasured';

    // The the termSetId is provided in the JSON get that to render the child options.
    if (TermSetId) {
      const term: ITaxonomyTerm | undefined = FormService.getTermFromTree(FormService.terms, TermSetId);
      const mapTermToFormInputOption = (term: ITaxonomyTerm) => ({
        Description: term.description,
        Id: term.id,
        LabelCaption: term.description,
        terms: term.terms,
        Title: term.name,
        tag: term.tag,
        group: term.metadataText,
        noValue: term.metadataNumber2 === 0,
        sortOrder: term.sortOrder,
      });
      if (isCredits || isCommendationCriteria || isOutcomesMeasured) {
        derivedOptions = filterTaxonomyByRollupOrganizationEnum(term?.terms, FormService.rollupOrganizationEnum).map(
          mapTermToFormInputOption,
        );
      } else {
        derivedOptions = term?.terms.map(mapTermToFormInputOption);
      }

      if (isCredits || isCommendationCriteria) {
        derivedOptions = derivedOptions?.map((option) => ({ ...option, Watermark: option.Watermark || '0.00' }));
      }
    }

    // If we have child terms, use those. Otherwise set to Yes/No.
    const radioOptions = derivedOptions || [
      { Id: `${Id}-true`, Title: 'Yes' },
      { Id: `${Id}-false`, Title: 'No' },
    ];

    // Builds Taxonomy-driven radio fields.
    switch (Type) {
      // "Boolean" is what we're calling radios
      case 'Boolean':
        return (
          <Grid container spacing={2}>
            {radioOptions.map(
              (option: IFormInputOption, idx: number): ReactElement => {
                const name = boardMocDetailId
                  ? `boardMocDetails.${boardMocDetailId}.${Id}`
                  : remsId
                  ? `boardRemsDetails.${remsId}.${Id}`
                  : Id;
                const disabledState: IDisabledState = get(FormService.disabledContext, name) as IDisabledState;
                return (
                  <Grid component={Box} display="flex" item key={idx}>
                    <label className="form-input-radio">
                      <Field
                        aria-required={isRequired}
                        disabled={disabledState?.isDisabled}
                        id={option.Id}
                        name={name}
                        type="radio"
                        value={option.Id}
                      />
                      <span className="checkmark" />
                      <Typography variant="srOnly">{option.Title}</Typography>
                    </label>
                    <div className="label-text-container">
                      <div className="label-text">{option.Title}</div>
                      {option.LabelCaption && (
                        <div className="caption-text" dangerouslySetInnerHTML={{ __html: option.LabelCaption }} />
                      )}
                    </div>
                  </Grid>
                );
              },
            )}
          </Grid>
        );
      case 'MultiChoiceWithNumber':
        if (!derivedOptions?.length) return null;
        const groups = Object.entries(groupBy(derivedOptions, 'group')).sort((i) =>
          min(i[1].map((ii) => ii.sortOrder ?? 0)),
        );
        return groups.map(([group, options]) => (
          <div key={group} className="form-group form-sub-group">
            {/** NARS TODO: styling **/}
            {group && group !== 'undefined' && <Typography variant="h6">{group}</Typography>}
            {options.map((option) =>
              option?.Id ? (
                <Fragment key={option?.Id}>
                  <FormikCheckboxConditionalNumberInput
                    alwaysZero={option?.noValue}
                    option={option}
                    isRequired={isRequired}
                    formikKey={Id as keyof BaseActivity}
                  />
                </Fragment>
              ) : null,
            )}
          </div>
        ));
      case 'MultiChoicePill':
        const isUsingIntermediateTerms = !!UseIntermediateTerms;
        const hasChildTerms: boolean =
          isUsingIntermediateTerms && derivedOptions?.some(({ terms }: IFormInputOption) => terms.length);

        // If there are child terms we render each of them with a title based on the parent..
        if (isUsingIntermediateTerms && hasChildTerms) {
          return derivedOptions?.map(
            (option: IFormInputOption): ReactElement => {
              const { Title, terms } = option;
              const items: IInputItem[] = terms?.map(
                ({ id, name }: ITaxonomyTerm): IInputItem => ({ Id: id, Title: name }),
              );
              return (
                <div key={Id} className="form-group form-sub-group">
                  {FormService.renderLabel({
                    name: Id,
                    required: isRequired,
                    suppressLabel: true,
                    title: Title,
                  })}
                  <PillCheckbox name={Id} items={items} />
                </div>
              );
            },
          );
        }

        // If there are no child terms group all of them together and render as pills.
        if (isUsingIntermediateTerms && !hasChildTerms) {
          const items: IInputItem[] = derivedOptions?.map(({ Id, Title }: IInputItem) => ({ Id, Title }));
          return (
            <div key={Id} className="form-group form-sub-group">
              {FormService.renderLabel({
                name: Id,
                required: isRequired,
                suppressLabel: true,
                title: isCommendationCriteria ? 'Commendation Criteria' : Title,
              })}
              <PillCheckbox name={Id} items={items} />
            </div>
          );
        }

        return (
          <PillCheckbox
            name={
              boardMocDetailId
                ? `boardMocDetails.${boardMocDetailId}.${Id}`
                : remsId
                ? `boardRemsDetails.${remsId}.${Id}`
                : Id
            }
            items={derivedOptions}
          />
        );

      case 'MultiChoiceGrid':
        return derivedOptions?.length ? (
          <FormikConditionalCheckboxesGrid options={derivedOptions} isRequired={isRequired} formikKey={Id} />
        ) : (
          <NoOptionsWarning />
        );
      case 'NestedMultiChoice':
        return derivedOptions?.length ? (
          <FormikConditionalNestedCheckboxes formikKey={Id} options={derivedOptions} isRequired={isRequired} />
        ) : (
          <NoOptionsWarning />
        );
      case 'TaxonomyMulti':
      case 'MultiChoice':
        return derivedOptions?.length ? (
          <FormikConditionalCheckboxes
            options={derivedOptions}
            isRequired={isRequired}
            formikKey={
              boardMocDetailId
                ? `boardMocDetails.${boardMocDetailId}.${Id}`
                : remsId
                ? `boardRemsDetails.${remsId}.${Id}`
                : Id
            }
          />
        ) : (
          <NoOptionsWarning />
        );
      // Specialized control for REMS
      case 'MultiTextDictionary':
        return (
          !!derivedOptions?.length && (
            <DictionaryTextField
              options={derivedOptions}
              formikKey={remsId ? `boardRemsDetails.${remsId}.${Id}` : Id}
              variant="outlined"
            />
          )
        );
      // Specialized control for REMS
      case 'MultiChoiceBoolean':
        return (
          (derivedOptions?.length === 1 && (
            <FormikBooleanCheckboxField
              caption={derivedOptions[0].LabelCaption}
              formikKey={
                boardMocDetailId
                  ? `boardMocDetails.${boardMocDetailId}.${Id}`
                  : remsId
                  ? `boardRemsDetails.${remsId}.${Id}`
                  : Id
              }
              name={derivedOptions[0].Title}
              shouldIgnoreCaptionStyling
            />
          )) || <></>
        );
      default:
        return <></>;
    }
  };

  private static buildMOCBoardControls = (): ReactElement => {
    // Null check.
    if (!FormService.selectedSpecialtyBoardIds?.length) return;

    return (
      <>
        {FormService.selectedSpecialtyBoardIds?.map(
          (id: string): ReactElement => {
            const config: IBCTBoard = FormService.configurationDictionary?.[id];

            // Take the config for a given board and build the controls.
            const abbreviation: string = config?.abbreviation;
            const contentOutlines = sortBy(config?.contentOutlines, ['sortOrder']);
            const specialties: IBCTSpecialty[] = sortBy(config?.specialties, ['sortOrder']);
            const isUseContentOutlines: boolean = config?.usesContentOutlines;

            // Filter credit types based on registration dates.
            const boardCreditTypes: IBCTCreditType[] = sortBy(config?.creditTypes, ['sortOrder']).filter(
              ({
                id,
                registrationEndDate,
                registrationStartDate,
                default: isDefault,
                activityEndDate,
                activityStartDate,
              }: IBCTCreditType): boolean => {
                // default is always allowed
                if (isDefault) {
                  return true;
                }

                for (const activity of FormService.activities ?? []) {
                  if (!activity) continue;
                  for (const board of Object.values(activity.boardMocDetails ?? {})) {
                    if (!board) continue;
                    for (const creditType of board.typesOfCreditIds ?? []) {
                      if (!creditType) continue;
                      if (creditType === id) {
                        // grandfather already-selected values so the user can unselect them
                        return true;
                      }
                    }
                  }
                }

                // block based on registration date vs. today
                const now: Moment = moment();
                if (registrationStartDate && now.isBefore(registrationStartDate, 'day')) {
                  return false;
                }
                if (registrationEndDate && now.isAfter(registrationEndDate, 'day')) {
                  return false;
                }

                // block based on activity date
                for (const activity of FormService.activities ?? []) {
                  if (!activity) continue;
                  const { startDate, endDate } = activity;
                  if (endDate && activityStartDate && moment(endDate).isBefore(activityStartDate, 'day')) {
                    return false;
                  }
                  if (startDate && activityEndDate && moment(startDate).isAfter(activityEndDate, 'day')) {
                    return false;
                  }
                }

                // nothing blocked it, so allow this to be selected
                return true;
              },
            );
            // these next lines to set values are pretty brittle but we have to do this somehow
            boardTemplate.Children[0].Children[0].Title = `Select the practice area(s) that most closely describe the content of your activity.`;
            // TODO this will eventually move to Taxonomy when the BCT is built, but hardcoding for now
            if (abbreviation === 'ABA') {
              boardTemplate.Children[0].Children[1].Title =
                'Please indicate the type(s) of MOCA® credit for which you are registering this activity';
            } else {
              boardTemplate.Children[0].Children[1].Title =
                'Please indicate the type(s) of MOC credit for which you are registering this activity:';
            }
            boardTemplate.Children[0].Children[2].Title = `How many points does this activity offer for ${abbreviation}?`;
            boardTemplate.Children[0].Children[2].Watermark = `${FormService.amaCreditValue || 0}`;
            boardTemplate.Children[0].Children[3].Watermark = 'Select Content Area';
            boardTemplate.Children[0].Children[0].Options = specialties?.map(({ id, name }) => ({
              Id: id,
              Title: name,
            })) as any;
            boardTemplate.Children[0].Children[1].Options = boardCreditTypes?.map(
              ({ default: isDefault, id, organizationName }: IBCTCreditType): IFormInputOption => ({
                Id: id,
                Title: organizationName,
                isDefault,
              }),
            ) as any;
            boardTemplate.Children[0].Children[3].Options = contentOutlines;
            boardTemplate.Children[0].Children[3].Visible.Operand.Value = isUseContentOutlines ? 'True' : 'False';
            return FormService.buildGroup({
              boardMocDetailId: id,
              group: boardTemplate as IFormGroup,
              isSubgroup: false,
              sectionId: '',
            });
          },
        )}
      </>
    );
  };

  private static buildRemsControls = (): ReactElement => {
    if (!FormService.selectedRemsBoardIds?.length) return;
    return (
      <>
        {FormService.selectedRemsBoardIds?.map(
          (guid: string): ReactElement => {
            const config: IBCTBoard = FormService.configurationDictionary?.[guid];
            const additionalQuestions: IBoardAdditionalFields[] = orderBy(
              config?.boardActivityAdditionalFields,
              ['sortOrder'],
              ['asc'],
            );
            const rems: IBCTBoard =
              FormService.configuredRemsBoards?.find((collab) => collab.id === guid) || ({} as IBCTBoard);
            if (!rems) {
              return null;
            }
            const { id } = rems;
            const transformedQuestions = additionalQuestions?.map(
              ({ id, question }: IBoardAdditionalFields): IFormInputOption => ({
                Id: id,
                Title: question,
              }),
            );
            // ID
            remsTemplate.Children[0].Children[0].Title =
              getRemsRegularExpression(
                rems?.boardRemsIdRegularExpressions,
                moment(FormService.activities?.[0]?.createdDate),
              )?.label || '';
            // Additional Questions
            (remsTemplate.Children[1].Children[0] as any).Options = transformedQuestions;
            // Attestation
            (remsTemplate.Children[2].Children[0] as any).Options[0].LabelCaption = rems.attestationText;
            return FormService.buildGroup({
              group: (remsTemplate as unknown) as IFormGroup,
              isSubgroup: false,
              remsId: id,
              sectionId: '',
            });
          },
        )}
      </>
    );
  };

  private static processPharmacyTag(): ReactElement {
    const stateContentId = stateTaggingTemplate.Children[0].Children[0].Id;
    const pharmacyTag = FormService.terms[PARS.Constants.TAXONOMY_ACTIVITY_PHARMACY_CONTENT_ID];

    stateTaggingTemplate.Children[0].Children[0].Title = `Select the content area(s) that your activity addresses.`;
    const terms: ITaxonomyTerm[] = sortBy(pharmacyTag?.terms, ['name'], ['asc']);
    stateTaggingTemplate.Children[0].Children[0].Options = terms?.map(({ id, name }) => ({
      Id: id,
      Title: name,
    })) as any;
    stateTaggingTemplate.Children[0].Children[1].Title = `Select the state(s) whose regulatory requirements your activity is designed to meet.`;
    const stateTag = PARS.Constants.TAXONOMY_ACTIVITY_PHARMACY_CONTENT_STATE_ID;

    const newOptions = (stateTaggingTemplate.Children[0].Children[1].Options = FormService.terms[stateTag].terms?.map(
      ({ id, name }) => ({
        Id: id,
        Title: name,
      }),
    ) as any);
    stateTaggingTemplate.Children[0].Children[1].Options = newOptions;

    return (
      <>
        {FormService.buildGroup({
          group: stateTaggingTemplate as IFormGroup,
          stateContentTagId: stateContentId,
          isSubgroup: false,
          sectionId: '',
        })}
      </>
    );
  }

  private static buildStateContentTaggingControls(): ReactElement {
    const { allContentTags, values, rollupOrganizationEnum } = FormService;

    if (
      isEmpty(allContentTags) ||
      (!values?.hasStateContentTags?.endsWith('1') && !values?.hasPharmacyContentTags?.endsWith('1'))
    ) {
      return null;
    }

    const contentTags: ReactElement[] = [];
    const hasPharmacyContentTags =
      values?.hasPharmacyContentTags?.endsWith('1') &&
      values?.targetAudience?.length > 0 &&
      rollupOrganizationEnum === RollupOrganizationEnums.JA;
    // clear and rebuilds content in case multiple changes
    setTimeout(() => {
      FormService.cleanupStateContentTaggingControls(hasPharmacyContentTags);
    }, 20);

    if (hasPharmacyContentTags) {
      contentTags.push(FormService.processPharmacyTag());
    }

    return <>{contentTags}</>;
  }

  private static cleanupStateContentTaggingControls(hasPharmacyTags: boolean) {
    const StateCTG = document.querySelector('[data-group-id="State Content Tagging Group"]');
    const pharReqCreditTaggingGroup = document.querySelector('[data-group-id="pharReqCreditTaggingGroup"]');

    if (StateCTG && !hasPharmacyTags) {
      const stateCTGFragment = document.createDocumentFragment();
      while (StateCTG?.firstChild) {
        stateCTGFragment.appendChild(StateCTG.firstChild);
      }
    }

    if (!hasPharmacyTags && pharReqCreditTaggingGroup) {
      const pharReqCreditTaggingGroupFragment = document.createDocumentFragment();
      while (pharReqCreditTaggingGroup?.firstChild) {
        pharReqCreditTaggingGroupFragment.appendChild(pharReqCreditTaggingGroup.firstChild);
      }
    }
  }

  private static buildSingleInput = ({
    boardMocDetailId,
    inputDetails,
    isRequired,
    remsId,
  }: IBuildRenderedInputProps): JSX.Element => {
    const {
      Options,
      Type,
      Watermark,
      WatermarkTwo,
      TermSetId,
      RepeatButtonText,
      Min,
      Max,
      Title,
      TitleTwo = '',
    } = inputDetails;
    let { Id } = inputDetails;

    if (Id.indexOf('{amaCreditId}') > -1) {
      // Get the credit taxonomy term and add it dynamically
      const term = getTaxonomyChildByTag({
        rootId: PARS.Constants.TAXONOMY_ACTIVITY_CREDIT_ROOT_ID,
        tag: PARS.Constants.TAGS_ENUM.ACTIVITY_CREDIT_TYPE__AMA_PRA_CATEGORY1,
        terms: FormService.terms,
      });
      Id = Id.replace('{amaCreditId}', term?.id);
    }

    const config: IBCTBoard = FormService.configurationDictionary?.[Id];
    // Take the config for a given board and build the control
    const abbreviation = config?.abbreviation;
    const maximumContentOutlines: number = FormService.getMaximumContentOutlines(boardMocDetailId);
    const isDatePicker: boolean = Type === 'DateTime';
    const isMOCBoardRepeater: boolean = Type === 'MocBoardRepeater';
    const isREMSRepeater: boolean = Type === 'RemsRepeater';

    // TODO handle plain Taxonomy
    const isTaxonomyMulti: boolean = Type === 'TaxonomyMulti';
    const isSelect: boolean = Type === 'Choice';
    const isTypeahead: boolean = Type === 'Typeahead';
    const isTextarea: boolean = Type === 'Note';
    const isDatePickerRepeater: boolean = Type === 'RepeatDate';
    const isNumber: boolean = Type === 'Number';
    const isRepeater: boolean =
      Type === RepeaterEnum.REPEAT_INPUT ||
      Type === RepeaterEnum.REPEAT_TYPEAHEAD ||
      Type === RepeaterEnum.REPEAT_MULTI_TYPEAHEAD;
    let taxonomyOptions: ITypeaheadOption[] = [];

    if (isDatePickerRepeater) {
      // Update the initial values for the form.
      FormService.initialValues[inputDetails.Id] = [''];
    }

    if (TermSetId || boardMocDetailId) {
      // TODO retrieve options from state
      const term: ITaxonomyTerm | undefined = FormService.getTermFromTree(
        FormService.terms,
        TermSetId || boardMocDetailId || '',
      );
      taxonomyOptions =
        term?.terms.sort(FormService.sortTerms).map((t: ITaxonomyTerm) => ({
          id: t.id,
          label: t.name,
        })) || [];
      if (Id === 'boardMocDetailsSelection')
        taxonomyOptions = FormService.allMocBoards
          .sort(FormService.sortTerms)
          .map(({ id, name }: IBoardSlim) => ({ id: id, label: name }));
      if (Id === 'supportedRemsIds') {
        taxonomyOptions = FormService.allRemsBoards
          .sort(FormService.sortTerms)
          .map(({ id, name }: IBoardSlim) => ({ id, label: name }));
      }
    }

    boardTemplate.Children[0].Children[3].Title = `Select up to ${maximumContentOutlines} content ${
      maximumContentOutlines === 1 ? 'area' : 'areas'
    } from the ${abbreviation || ''} MOCA Content Outline that apply to this activity.`;

    return (
      // TODO figure out label
      // TODO figure out placeholders
      // TODO figure out how to combine these three branches
      isNumber ? (
        <FormikNumberField
          formikKey={boardMocDetailId ? `boardMocDetails.${boardMocDetailId}.${Id}` : Id}
          min={Min}
          max={Max}
          placeholder={Watermark}
          variant="outlined"
        />
      ) : isDatePickerRepeater ? (
        <DatePickerRepeater formikKey={Id} />
      ) : isSelect ? (
        <Dropdown
          name={Id}
          onChange={FormService.handleChange}
          required={isRequired}
          items={Options}
          labelProp="Title"
          idProp="Id"
          placeholder={Watermark}
        />
      ) : isDatePicker ? (
        <Field
          component={DatePicker}
          id={Id}
          name={
            boardMocDetailId
              ? `boardMocDetails.${boardMocDetailId}.${Id}`
              : remsId
              ? `boardRemsDetails.${remsId}.${Id}`
              : Id
          }
          aria-required={isRequired}
          timeOfDay="endOfDay"
        />
      ) : isTextarea ? (
        <Field
          aria-label={Title}
          as="textarea"
          id={Id}
          maxLength={Max}
          name={
            boardMocDetailId
              ? `boardMocDetails.${boardMocDetailId}.${Id}`
              : remsId
              ? `boardRemsDetails.${remsId}.${Id}`
              : Id
          }
          placeholder={Watermark}
        />
      ) : isTaxonomyMulti ? (
        <ABAContentOutlines
          formikKey={boardMocDetailId ? `boardMocDetails.${boardMocDetailId}` : Id}
          dropdownId={boardMocDetailId ? 'optionSelectionId' : Id} // This will be stripped out on final submission.
          selectId={boardMocDetailId ? 'contentOutlines' : Id}
          maxSelectionCount={maximumContentOutlines}
          terms={(Options as any) as ITaxonomyTerm[]}
        />
      ) : isMOCBoardRepeater ? (
        FormService.buildMOCBoardControls()
      ) : isREMSRepeater ? (
        FormService.buildRemsControls()
      ) : isTypeahead ? (
        Id === 'boardMocDetailsSelection' || Id === 'supportedRemsIds' ? (
          <Multi setFieldValue={FormService.setFieldValue} id={Id} options={taxonomyOptions} placeholder={Watermark} />
        ) : (
          <Typeahead onChange={FormService.handleChange} id={Id} options={taxonomyOptions} placeholder={Watermark} />
        )
      ) : isRepeater ? (
        <RepeaterControl
          options={taxonomyOptions}
          name={Id}
          inputType={Type as any}
          repeatButtonText={RepeatButtonText}
          min={Min}
          placeholder={Watermark}
          placeholderTwo={WatermarkTwo}
          title={Title}
          titleTwo={TitleTwo}
        />
      ) : (
        <Field
          aria-label={Title}
          id={Id}
          name={
            boardMocDetailId
              ? `boardMocDetails.${boardMocDetailId}.${Id}`
              : remsId
              ? `boardRemsDetails.${remsId}.${Id}`
              : Id
          }
          aria-required={isRequired}
          type="text"
          placeholder={Watermark}
        />
      )
    );
  };

  public static renderLabel = ({
    name,
    title,
    suppressLabel,
    required,
    hasGroupTitle,
    tooltipContent,
  }: IRenderLabelProps): JSX.Element | null => {
    const shouldPrintAsterisk: boolean = required;
    // TODO can I conditionally determine the element in the next line?
    return title ? (
      suppressLabel ? (
        <h5 className={classNames({ 'form-group-label': hasGroupTitle, 'form-input-label': !hasGroupTitle })}>
          {title}
          {shouldPrintAsterisk && <span className="isRequired"> *</span>}
          {tooltipContent && <CustomTooltip iconTooltip tooltipText={tooltipContent} />}
        </h5>
      ) : (
        <label htmlFor={name} className="form-input-label">
          {title}
          {shouldPrintAsterisk && <span className="isRequired"> *</span>}
          {tooltipContent && <CustomTooltip iconTooltip tooltipText={tooltipContent} />}
        </label>
      )
    ) : null;
  };

  public static getInitialValues = () => {
    return FormService.initialValues;
  };

  public static getValidationObject = () => {
    return FormService.validationObject;
  };

  private static getTermFromTree = (terms: ITaxonomyDictionary, id: string) => {
    if (!id || !terms || !Object.keys(terms).length) {
      return;
    }
    // one of the IDs was coming through upcase so we can't count on case here
    if (
      (terms[id] !== null || terms[id.toUpperCase()] !== null || terms[id.toLowerCase()] !== null) &&
      (terms[id] !== undefined || terms[id.toUpperCase()] !== undefined || terms[id.toLowerCase()] !== undefined)
    ) {
      return terms[id] || terms[id.toUpperCase()] || terms[id.toLowerCase()];
    }
    // for (const termId of Object.keys(terms)) {
    //   const term: ITaxonomyTerm = terms[termId];
    //   const termDict: { [id: string]: ITaxonomyTerm } = {};
    //   term?.terms.forEach(t => (termDict[t.id] = t));
    //   const foundTerm = FormService.getTermFromTree(termDict, id);
    //   if (foundTerm) {
    //     return foundTerm;
    //   }
    // }
  };

  private static sortTerms = (a: ITaxonomyTerm | IBoardSlim, b: ITaxonomyTerm | IBoardSlim) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };
}
