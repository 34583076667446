import { Grid } from '@material-ui/core';
import { FormikProvider, useFormik } from 'formik';
import noop from 'lodash/noop';
import values from 'lodash/values';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// Components.
import { FileInput } from 'components/ContinuousImprovement/FileInput';
import { FormikInputField } from 'components/ContinuousImprovement/PureFormElements';
import Dropdown from 'components/Dropdown/Dropdown';
import InputBlock from 'components/global/inputBlock/inputBlock';
import ButtonRow from 'layouts/pages/activity/forms/buttonRow';

// Core.
import { TAXONOMY_HELP_DESK_ID } from 'core/constants';

// Store.
import { removeAttachment, resetAttachments, submitTicket, uploadAttachments } from 'store/helpDesk/actions';
import { helpDeskAttachmentsSelector } from 'store/helpDesk/selectors';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { helpDeskTermsSelector } from 'store/taxonomy/selectors';

// Styles.
import styles from './HelpDeskForm.module.scss';

// Validations.
import { validationSchema } from './validationSchema';
import { rollupOrganizationEnumSelector } from 'store/user/selectors';
import { getSite } from 'utils/site';
import { filterTaxonomyByRollupOrganizationEnum } from 'globals/utils/filterTaxonomyByOrg';

export const HelpDeskForm = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [files, setFiles] = useState<File[]>([]);
  const helpDeskTerms = useSelector(helpDeskTermsSelector);
  const helpDeskAttachments = useSelector(helpDeskAttachmentsSelector);
  // fall back to getSite() here since this screen needs to be workable even if your login is messed up
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector) || getSite();
  const topics = useMemo(
    () =>
      filterTaxonomyByRollupOrganizationEnum(helpDeskTerms, rollupOrganizationEnum)?.map((term) => ({
        id: term.id,
        name: term.name,
      })) ?? [],
    [helpDeskTerms, rollupOrganizationEnum],
  );

  const onBackClick = useCallback(() => {
    history.push('/');
  }, [history]);

  const onRemoveAttachment = useCallback(
    (file: File) => {
      dispatch(removeAttachment(file));
      setFiles(files.filter((instance) => instance.name !== file.name));
    },
    [dispatch, files],
  );

  const onUploadAttachment = useCallback(
    async (newFiles: File[]) => {
      // Remove duplicate files.
      const filesToUpload = newFiles.filter(
        (newFile) =>
          !files.some((existingFile) => {
            return existingFile.name === newFile.name;
          }),
      );

      // @ts-ignore
      const [uploadedFiles] = await dispatch(uploadAttachments(filesToUpload));
      setFiles([...files, ...uploadedFiles]);
    },
    [dispatch, files],
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      question: '',
      topicTermId: '',
    },
    onSubmit: async (ticket, { resetForm }) => {
      try {
        await dispatch(submitTicket({ ...ticket, attachmentBlobIds: values(helpDeskAttachments) }));
        resetForm();
        setFiles([]);
        dispatch(resetAttachments());
      } catch (error) {
        noop();
      }
    },
    validationSchema,
  });

  useEffect((): VoidFunction => {
    dispatch(getTaxonomyTermById(TAXONOMY_HELP_DESK_ID));
    return (): void => {
      dispatch(resetAttachments());
    };
  }, [dispatch]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles['form-card']}>
          {/*********************/}
          <fieldset className={styles['no-divider']}>
            <legend>
              What can we help you with? <span className={styles.required}>*</span>
            </legend>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputBlock name="topicTermId" variant={styles['input-block']}>
                  <Dropdown
                    displayEmpty
                    fullWidth
                    items={topics}
                    name="topicTermId"
                    placeholder="Select Topic"
                    required
                  />
                </InputBlock>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormikInputField formikKey="name" label="Name" required />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormikInputField formikKey="email" label="Email" required type="email" />
              </Grid>
              <Grid item xs={12}>
                <FormikInputField
                  FormHelperTextProps={{ style: { textAlign: 'right' } }}
                  formikKey="question"
                  helperText="Max 2500 characters"
                  inputProps={{ maxLength: 2500 }}
                  label="Questions or Comment"
                  multiline
                  placeholder="Enter question or comment"
                  required
                  rows={8}
                />
              </Grid>
            </Grid>
          </fieldset>
          {/*********************/}
          {/*********************/}
          <FileInput
            buttonLabel="Add Attachment"
            files={files}
            label="Please attach screenshots of error messages and/or the files with which you need help."
            multiple
            onRemove={onRemoveAttachment}
            onUpload={onUploadAttachment}
          />
          {/*********************/}
        </div>
        <ButtonRow
          backLabel="Cancel without submitting"
          ctaLabel="Submit"
          isSubmitting={formik.isSubmitting}
          isValid={formik.dirty && formik.isValid}
          onBackClick={onBackClick}
          showBack
        />
      </form>
    </FormikProvider>
  );
};
