import { IProgramSummary } from 'core/models';
import { Reducer } from 'redux';

import {
  GET_PROGRAM_SUMMARY,
  GET_PROGRAM_SUMMARY_FAILURE,
  GET_PROGRAM_SUMMARY_SUCCESS,
  IProgramSummaryState,
  ProgramSummaryActionTypes,
  REENABLE_PROGRAM_SUMMARY,
  REENABLE_PROGRAM_SUMMARY_FAILURE,
  REENABLE_PROGRAM_SUMMARY_SUCCESS,
  SAVE_PROGRAM_SUMMARY,
  SAVE_PROGRAM_SUMMARY_FAILURE,
  SAVE_PROGRAM_SUMMARY_SUCCESS,
} from './types';

const initialState: IProgramSummaryState = {
  error: '',
  isLoading: false,
  saved: false,
  storedSummary: null,
};

const programSummaryReducer: Reducer<IProgramSummaryState, ProgramSummaryActionTypes> = (
  state = initialState,
  action: ProgramSummaryActionTypes,
): IProgramSummaryState => {
  switch (action.type) {
    case GET_PROGRAM_SUMMARY:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROGRAM_SUMMARY_SUCCESS:
      return {
        ...state,
        error: '',
        isLoading: false,
        storedSummary: action.payload as IProgramSummary,
      };
    case GET_PROGRAM_SUMMARY_FAILURE:
      return {
        ...state,
        error: action.payload as string,
        isLoading: false,
      };
    case SAVE_PROGRAM_SUMMARY:
      return {
        ...state,
        error: '',
        isLoading: true,
        saved: false,
        storedSummary: action.payload as IProgramSummary,
      };
    case SAVE_PROGRAM_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saved: true,
      };
    case SAVE_PROGRAM_SUMMARY_FAILURE:
      return {
        ...state,
        error: action.payload as string,
        isLoading: false,
        saved: false,
      };
    case REENABLE_PROGRAM_SUMMARY:
      return {
        ...state,
        isLoading: true,
        saved: false,
      };
    case REENABLE_PROGRAM_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saved: true,
      };
    case REENABLE_PROGRAM_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        saved: false,
      };
    default:
      return state;
  }
};

export default programSummaryReducer;
