import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import sum from 'lodash/sum';
import {
  CloseRounded,
  DeleteRounded,
  PersonAddRounded,
  FileCopyRounded,
  Print,
  Restore,
  SupervisorAccountRounded,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { FormControlLabel, useMediaQuery } from '@material-ui/core';

// Components
import Button from 'components/Button/Button';
import { ToggleSwitch } from 'components/ToggleSwitch';
import CustomTooltip from 'components/Tooltip/Tooltip';
import { Modal } from 'components/ContinuousImprovement/Modal';
import { ProcessActivityForm } from 'components/forms/templates/ProcessActivityForm';
import { QueueMocActivityForm } from 'components/forms/templates/QueueMocActivityForm';
import { AdminDeleteActivityForm } from 'components/forms/templates/AdminDeleteActivityForm';

// Styles
import './activityactions.module.scss';

// Store + Core
import {
  activityDetailRollupOrganizationEnumSelector,
  activityDetailSelector,
  activityDetailStatusSelector,
  hasIndividualLearnersSelector,
  isDeletedSelector,
  learnerCountsSelector,
} from 'store/activity/selectors';
import { BreakpointsEnum, StatusEnum } from 'core/enums';
import { toggleCloseActivityDetail, undeleteActivities } from 'store/activity/actions';
import {
  organizationKindSelector,
  currentOrganizationContextSelector,
  isStaffUserSelector,
  isBoardUserSelector,
  isAccreditorUserSelector,
} from 'store/user/selectors';
import {
  staffProvider,
  accreditorOrStaff,
  withoutBoard,
  ACTIVITY_RECENTLY_CLOSED_THRESHOLD,
  MESSAGE_CANNOT_REOPEN_AFTER_30_DAYS,
} from 'core/constants';
import { IDictionary, OrganizationKinds, RollupOrganizationEnums } from 'core/models';
import { useCanTransitionToClosed } from './activityStateMachine';
import { useIsProviderSelected } from 'components/global/providerRestriction/ProviderRestriction';
import { popToast } from 'store/toast/actions';
import { errorStickyToastOptions } from 'store/toast/constants';

interface IActivityActionsProps {
  closeDate?: Date;
  setIsCopyActivityModalOpen(boolean): void;
  setIsDeleteActivityModalOpen(boolean): void;
  setIsUpdateLearnersModalOpen(boolean): void;
  handleClose: () => void;
  isAddLearnersDisabled: boolean;
  addLearnersDisabledMessage?: string;
  isUpdateLearnersDisabled: boolean;
  refreshActivity: () => void;
}

const useStyles = makeStyles({
  root: {
    margin: 0,
  },
  disabled: {
    '&$disabled': {
      color: '#667',
    },
  },
});

const cannotCloseMessage = 'Missing information needed to close is noted in red in activity details.';

export const ActivityActions = (props: IActivityActionsProps): ReactElement => {
  const {
    closeDate,
    setIsCopyActivityModalOpen,
    setIsDeleteActivityModalOpen,
    setIsUpdateLearnersModalOpen,
    handleClose,
    isAddLearnersDisabled,
    addLearnersDisabledMessage,
    isUpdateLearnersDisabled,
    refreshActivity,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const isProviderSelected = useIsProviderSelected();
  const { id } = useParams<{ id: string }>();
  const isMedBreakpoint = useMediaQuery(`(min-width:${BreakpointsEnum.md})`);
  const [isQueueProcessActivityModalOpen, setProcessingQueueActivityModalStatus] = useState<boolean>(false);
  const closeQueueProcessActivityModal = (): void => setProcessingQueueActivityModalStatus(false);
  const [isQueueMocActivityModalOpen, setQueueMocActivityModalStatus] = useState<boolean>(false);
  const closeQueueMocActivityModal = (): void => setQueueMocActivityModalStatus(false);
  const [isAdminDeleteActivityModalOpen, setAdminDeleteActivityModalStatus] = useState<boolean>(false);
  const closeAdminDeleteActivityModal = (): void => setAdminDeleteActivityModalStatus(false);

  // Selectors.
  const activityStatus: StatusEnum = useSelector(activityDetailStatusSelector);
  const isBoardUser: boolean = useSelector(isBoardUserSelector);
  const isStaffUser: boolean = useSelector(isStaffUserSelector);
  const isAccreditorUser: boolean = useSelector(isAccreditorUserSelector);
  const isDisabledUser: boolean = isStaffUser || isAccreditorUser || isBoardUser;
  const rollupOrganizationEnum = useSelector(activityDetailRollupOrganizationEnumSelector);
  const organizationKind = useSelector(organizationKindSelector)?.organizationKind;
  const isDeleted: boolean = useSelector(isDeletedSelector);
  const hasIndividualLearners: boolean = useSelector(hasIndividualLearnersSelector);
  const learnerCounts: IDictionary<number> = useSelector(learnerCountsSelector);
  const currentOrganizationKind = useSelector(currentOrganizationContextSelector)?.organizationKind;
  const isCurrentOrgAccmeStaff: boolean = useSelector(isStaffUserSelector);

  // A PARS activity must have both physician learners and total other learners in order to be closed, but JA and NARS only require one. AB#3869/4395
  const learnerCountsArray: number[] = Object.values(learnerCounts);
  const requiredLearnerTypes: number =
    rollupOrganizationEnum === RollupOrganizationEnums.JA || rollupOrganizationEnum === RollupOrganizationEnums.NARS
      ? 1
      : 2;
  const hasNeededLearnerTypes: boolean = learnerCountsArray?.length >= requiredLearnerTypes;
  const hasLearners: boolean = hasNeededLearnerTypes && sum(learnerCountsArray) >= 0;
  const isRollUpOrganizationNars: boolean = rollupOrganizationEnum === RollupOrganizationEnums.NARS;

  const isReadyToClose: boolean = activityStatus === StatusEnum.READY_TO_CLOSE;
  const isClosed: boolean = activityStatus === StatusEnum.CLOSED;

  const doUndelete = async () => {
    await dispatch(undeleteActivities([id]));
    refreshActivity();
  };
  const isDeletedItems: ReactElement = (
    <li className="restore-deleted">
      <Button onClick={doUndelete} type="button">
        <Restore /> Restore Activity
      </Button>
    </li>
  );
  const onChangeClosedStatus = async (): Promise<void> => {
    await dispatch(toggleCloseActivityDetail([id]));
  };

  // The activity has been closed within the last `n` days. AB-2762
  // If an activity does not have a close date it should be able to be closed.
  const hasBeenClosedRecently: boolean =
    !!closeDate &&
    moment(closeDate).isSameOrAfter(moment().subtract(ACTIVITY_RECENTLY_CLOSED_THRESHOLD, 'days'), 'day');

  const activityDetail = useSelector(activityDetailSelector);
  const canTransitionToClosed = useCanTransitionToClosed(activityDetail);
  const { hasReviewHistory } = activityDetail;

  const canEdit = withoutBoard.includes(organizationKind as OrganizationKinds);
  const showClose =
    isReadyToClose &&
    staffProvider.includes(organizationKind as OrganizationKinds) &&
    (hasLearners || !!activityDetail.providerAutoCloseActivities);
  const canClose = showClose && canTransitionToClosed === true;
  const showReopen = isClosed && withoutBoard.includes(currentOrganizationKind as OrganizationKinds);
  const canReopen =
    showReopen && (hasBeenClosedRecently || accreditorOrStaff.includes(currentOrganizationKind as OrganizationKinds));
  const closeControl = (
    <FormControlLabel
      classes={classes}
      control={<ToggleSwitch disabled={!canClose} onChange={onChangeClosedStatus} checked={isClosed} size="small" />}
      label="Mark Activity as Closed"
      data-reason={canTransitionToClosed}
    />
  );
  const reopenControl = (
    <FormControlLabel
      classes={classes}
      control={<ToggleSwitch disabled={!canReopen} onChange={onChangeClosedStatus} checked={isClosed} size="small" />}
      label="Reopen"
    />
  );
  const copyActivity = (
    <Button disabled={isDisabledUser} onClick={(): void => setIsCopyActivityModalOpen(true)}>
      <FileCopyRounded />
      Copy Activity
    </Button>
  );

  const showUserRestrictionToast = () => {
    dispatch(popToast({ ...errorStickyToastOptions, message: <>Please select a provider to continue</> }));
  };

  const getUpdateLearnersButton = () => {
    if (isUpdateLearnersDisabled) {
      return (
        <CustomTooltip
          aria-label="Cannot update learners before activity start date."
          tooltipText="Cannot update learners before activity start date."
        >
          <span>
            <Button disabled onClick={(): void => setIsUpdateLearnersModalOpen(true)}>
              <SupervisorAccountRounded />
              Update Total Learners
            </Button>
          </span>
        </CustomTooltip>
      );
    } else if (!isProviderSelected) {
      return (
        <Button onClick={(): void => showUserRestrictionToast()}>
          <SupervisorAccountRounded />
          Update Total Learners
        </Button>
      );
    }
  };

  return (
    <div className="activity-detail-actions">
      <Button className="close" aria-label="close" onClick={handleClose}>
        <CloseRounded />
      </Button>
      <ul>
        {isDeleted ? (
          isDeletedItems
        ) : (
          <>
            <li>
              {(isUpdateLearnersDisabled || !isProviderSelected) && canEdit ? (
                getUpdateLearnersButton()
              ) : (
                <Button disabled={!canEdit} onClick={(): void => setIsUpdateLearnersModalOpen(true)}>
                  <SupervisorAccountRounded />
                  Update Total Learners
                </Button>
              )}
            </li>
            {!isRollUpOrganizationNars && (
              <li>
                {isAddLearnersDisabled ? (
                  <CustomTooltip aria-label={addLearnersDisabledMessage} tooltipText={addLearnersDisabledMessage}>
                    <span>
                      <Button disabled className={classes.disabled}>
                        <PersonAddRounded />
                        Add Individual Learners
                      </Button>
                    </span>
                  </CustomTooltip>
                ) : (
                  <Button
                    component={Link}
                    disabled={isAddLearnersDisabled || !canEdit}
                    to={`/activities/${id}/add-learner`}
                  >
                    <PersonAddRounded />
                    Add Individual Learners
                  </Button>
                )}
              </li>
            )}
            {isMedBreakpoint && (
              <li>
                <Button onClick={() => window.print()}>
                  <Print />
                  Print Activity
                </Button>
              </li>
            )}
            {isClosed ? (
              <div>
                <li>{copyActivity}</li>
                <li>&nbsp;</li>
              </div>
            ) : (
              <li>{copyActivity}</li>
            )}

            {/* No one delete closed activities. */}
            {isClosed ? null : (
              <li>
                <Button
                  disabled={!canEdit || hasIndividualLearners || hasReviewHistory}
                  onClick={(): void => setIsDeleteActivityModalOpen(true)}
                >
                  <DeleteRounded />
                  Delete Activity
                </Button>
              </li>
            )}
          </>
        )}
      </ul>
      {showClose &&
        (canClose ? (
          closeControl
        ) : (
          <CustomTooltip tooltipText={cannotCloseMessage} aria-label={cannotCloseMessage}>
            {closeControl}
          </CustomTooltip>
        ))}
      {showReopen &&
        (canReopen ? (
          reopenControl
        ) : (
          <CustomTooltip
            tooltipText={MESSAGE_CANNOT_REOPEN_AFTER_30_DAYS}
            aria-label={MESSAGE_CANNOT_REOPEN_AFTER_30_DAYS}
          >
            {reopenControl}
          </CustomTooltip>
        ))}
      {isCurrentOrgAccmeStaff && (
        <div className="admin-popup-buttons">
          <div>
            <Button onClick={() => setProcessingQueueActivityModalStatus(true)}>Process Activity</Button>
          </div>
          <div>
            <Button onClick={() => setQueueMocActivityModalStatus(true)}>Queue MOC Activity</Button>
          </div>
          <div>
            <Button onClick={() => setAdminDeleteActivityModalStatus(true)}>Admin-Delete Activity</Button>
          </div>
        </div>
      )}
      <Modal isOpen={isQueueProcessActivityModalOpen} onClose={closeQueueProcessActivityModal} title="Process Activity">
        <ProcessActivityForm
          activityIds={parseInt(activityDetail.printableId)}
          onClose={closeQueueProcessActivityModal}
          onSuccess={refreshActivity}
        />
      </Modal>
      <Modal isOpen={isQueueMocActivityModalOpen} onClose={closeQueueMocActivityModal} title="Queue MOC Activity">
        <QueueMocActivityForm
          activityIds={parseInt(activityDetail.printableId)}
          onClose={closeQueueMocActivityModal}
          onSuccess={refreshActivity}
        />
      </Modal>
      <Modal isOpen={isAdminDeleteActivityModalOpen} onClose={closeAdminDeleteActivityModal} title="Delete Activity">
        <AdminDeleteActivityForm
          activityId={parseInt(activityDetail.printableId)}
          onClose={closeAdminDeleteActivityModal}
          onSuccess={refreshActivity}
        />
      </Modal>
    </div>
  );
};
