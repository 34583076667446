import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { PropsWithChildren, ReactElement } from 'react';

// Styles.
import styles from './DashboardWidget.module.scss';

export interface IDashboardWidgetProps {
  className?: string;
  isLoading?: boolean;
}

export const DashboardWidget = (props: PropsWithChildren<IDashboardWidgetProps>): ReactElement => {
  const { className, children, isLoading } = props;

  return (
    <div className={classNames(styles.widget, { [styles.loading]: isLoading }, className)}>
      {isLoading ? <CircularProgress color="inherit" /> : children}
    </div>
  );
};
