import { API } from 'core';
import { ServerRoute } from 'core/api';
import { BoardProcessingMetrics } from 'core/models';

export class BoardProcessingMetricsService {
  public static async getData(): Promise<BoardProcessingMetrics> {
    const route: ServerRoute = ServerRoute.forAction('BoardProcessingMetrics', 'data');
    return await API.get(route);
  }
}
