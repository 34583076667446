import { isBoolean, keys, min, omit, uniq } from 'lodash';

// Core
import {
  Activity,
  ActivityFormStateValues,
  BoardMocDetails,
  HasStateContentTags,
  HasPharmacyContentTags,
  HasPharmacyRecertifications,
  IBoardRemsDetails,
  IDatesAndLocation,
  IDictionary,
} from 'core/models';
import { IBCTBoard, IBCTCreditType } from 'layouts/pages/bct/types';

// Utils
import { transformCommercialSupportSources } from '../utils';

interface ITransformMocPayload {
  allowedMocKeys?: string[];
  amaCreditValue: number;
  boards: IDictionary<BoardMocDetails>;
  configs: IDictionary<IBCTBoard>;
}

interface ITransformActivityFormPayload {
  amaCreditId?: string;
  configDictionary?: IDictionary<IBCTBoard>;
  values: ActivityFormStateValues;
}

const filterCreditsForSubmit = (credits: IDictionary<boolean | number>): IDictionary<number> => {
  // If the checkbox was checked but no numbers were entered, the key's value will be 0
  // This prevents uncontrolled to controlled errors in console from Material UI
  // The isBoolean check filters the keys on credits used for the checkboxes true/false value
  const filteredCreditsList: string[] = keys(credits)?.filter(
    // NARS TODO: check if the above warning is still an issue, since we need to submit 0 credits for OB-CE credit types - I think adding the credits[`credits${key}`] check (which is what checking the box sets) will work
    (key: string): boolean => {
      // booleans are markers - exclude them
      if (isBoolean(credits[key])) return true;
      // valid number - don't exclude
      if (credits[key]) return false;
      // 0 and is checked - don't exclude
      if (credits[`credits${key}`] === true && credits[key] === 0) return false;
      // anything else - exclude
      return true;
    },
  );
  return omit(credits, filteredCreditsList) as IDictionary<number>;
};

const transformMocBoards = ({
  allowedMocKeys,
  amaCreditValue,
  boards,
  configs,
}: ITransformMocPayload): IDictionary<BoardMocDetails> => {
  const updatedBoards: IDictionary<BoardMocDetails> = {};
  allowedMocKeys.forEach((boardKey: string) => {
    const board: BoardMocDetails = boards?.[boardKey];
    const config: IBCTBoard = configs?.[boardKey];
    const creditTypes: IBCTCreditType[] = config?.creditTypes;
    const isMatchAmaCreditValue: boolean = creditTypes?.some(({ mustMatchCmeAmount }) => mustMatchCmeAmount);
    const mocCreditValue: number = board?.mocPointsGiven || amaCreditValue;
    const updatedMocCreditValue: number = isMatchAmaCreditValue
      ? amaCreditValue
      : min([amaCreditValue, mocCreditValue]);
    updatedBoards[boardKey] = omit(
      {
        ...boards?.[boardKey],
        mocPointsGiven: updatedMocCreditValue,
      },
      'optionSelectionId',
    );
    // Get the default credit types into the boardMocDetails object for a given board
    const initialCreditTypes: string[] =
      creditTypes?.filter(({ default: isDefault }) => isDefault).map(({ id }) => id) || [];
    const currentCreditTypes: string[] = boards?.[boardKey]?.typesOfCreditIds || [];
    const updatedCreditTypes = uniq([...initialCreditTypes, ...currentCreditTypes]);
    if (updatedCreditTypes?.length) {
      updatedBoards[boardKey].typesOfCreditIds = updatedCreditTypes;
    }
  });
  return updatedBoards;
};

const transformRems = (
  rems: IDictionary<IBoardRemsDetails>,
  allowedRemsKeys: string[] = [],
): IDictionary<IBoardRemsDetails> => {
  const updatedRems: IDictionary<IBoardRemsDetails> = {};
  allowedRemsKeys.forEach((remsKey: string) => (updatedRems[remsKey] = { ...rems?.[remsKey] }));
  return updatedRems;
};

export const transformActivityFormValues = ({
  amaCreditId,
  configDictionary,
  values,
}: ITransformActivityFormPayload): Partial<Activity> => {
  const datesAndLocation: IDatesAndLocation = values.tempDatesAndLocation?.[0];
  const city = datesAndLocation?.city;
  const country = datesAndLocation?.country;
  const endDate = datesAndLocation?.endDate;
  const postalCode = datesAndLocation?.postalCode;
  const startDate = datesAndLocation?.startDate;
  const stateOrProvince = datesAndLocation?.stateOrProvince;
  const allowedMocKeys = values.boardMocDetailsSelection;
  const allowedRemsKeys = values.supportedRemsIds;
  const amaCreditValue: number = values.credits?.[amaCreditId] as number;
  const mocClaimDate = values?.mocCreditDeadline;
  const trueText = 'true';
  const isMoc = values.isMoc?.includes(trueText);
  const isRems = values.isRems?.includes(trueText);
  const isResettingMocData = !allowedMocKeys?.length && !isMoc;
  const hasPharmacyRecertifications = values.hasPharmacyRecertifications?.endsWith('1')
    ? HasPharmacyRecertifications.Yes
    : values.hasPharmacyRecertifications?.endsWith('0')
    ? HasPharmacyRecertifications.No
    : HasPharmacyRecertifications.NotSure;
  const hasPharmacyContentTags = values.hasPharmacyContentTags?.endsWith('1')
    ? HasPharmacyContentTags.Yes
    : values?.hasPharmacyContentTags?.endsWith('0')
    ? HasPharmacyContentTags.No
    : HasPharmacyContentTags.NotSure;
  const hasStateContentTags = values?.hasStateContentTags?.endsWith('1')
    ? HasStateContentTags.Yes
    : HasStateContentTags.No;
  const transformedFormValues: Partial<Activity> = {
    ...values,
    boardMocDetails: isMoc
      ? transformMocBoards({
          allowedMocKeys,
          amaCreditValue,
          boards: values?.boardMocDetails,
          configs: configDictionary,
        })
      : {},
    boardRemsDetails: isRems ? transformRems(values.boardRemsDetails, allowedRemsKeys) : {},
    city,
    commercialSupportSources: transformCommercialSupportSources(
      values.commercialMonetarySupportSources,
      values.commercialInKindSupportSources,
    ),
    contentTags: hasStateContentTags === HasStateContentTags.Yes ? values.contentTags ?? [] : [],
    country,
    credits: filterCreditsForSubmit(values?.credits),
    endDate,
    hasCommercialSupport: values.hasCommercialSupport?.includes(trueText),
    hasPharmacyContentTags,
    hasPharmacyRecertifications,
    hasStateContentTags,
    includeInCmeFinder: values.includeInCmeFinder?.includes(trueText),
    isIpce: values.isIpce?.includes(trueText),
    isJointlyProvided: values.isJointlyProvided?.includes('join')
      ? true
      : values.isJointlyProvided?.includes('direct')
      ? false
      : null,
    isMips: values.isMips?.includes(trueText),
    isMoc: values.isMoc?.includes(trueText),
    isPharmacyCertificateProgram: values.isPharmacyCertificateProgram?.includes(trueText),
    isRems: values.isRems?.includes(trueText),
    isRestrictedAudience: values.isRestrictedAudience === '' ? null : values.isRestrictedAudience?.includes('limited'),
    jointProviders: values?.jointProviders || [],
    markedForCommendationCriteria: values.markedForCommendationCriteria?.includes(trueText),
    mocCreditDeadline: !isResettingMocData ? mocClaimDate : null,
    mocProgramAttestation: !isResettingMocData
      ? values.mocProgramAttestation?.includes('mocProgramAttestation')
      : false,
    pharmacyContentTagStateTaxonomyTerms:
      hasPharmacyContentTags === HasPharmacyContentTags.Yes ? values.pharmacyContentTagStateTaxonomyTerms ?? [] : [],
    pharmacyContentTagTaxonomyTerms:
      hasPharmacyContentTags === HasPharmacyContentTags.Yes ? values.pharmacyContentTagTaxonomyTerms ?? [] : [],
    pharmacyRecertificationTaxonomyTerms: values?.pharmacyRecertificationTaxonomyTerms ?? [],
    postalCode,
    startDate,
    stateOrProvince,
  };
  return transformedFormValues as Partial<Activity>;
};
