import { AttestationApiService } from 'core/attestationApiService';
import { IAttestation } from 'core/models';

export class AttestationService {
  private static attestationApiService: AttestationApiService = new AttestationApiService();

  public static async get(): Promise<IAttestation> {
    const attestation = await this.attestationApiService.get();
    return {
      heading: attestation.heading,
      subHeading: attestation.subHeading,
      content: attestation.content,
      isAttested: !!attestation.isAttested,
      startDate: new Date(attestation.startDate),
      endDate: new Date(attestation.endDate),
      updatedById: attestation.updatedById,
      updatedDate: new Date(attestation.updatedDate),
      email: attestation.email,
    };
  }

  public static async attest(): Promise<void> {
    return await this.attestationApiService.attest();
  }
}
