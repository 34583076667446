import { IActivityReviewHistory } from 'core/models';
import moment from 'moment';
import { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';

// Store.
import { activityReviewOutcomesSelector, activityReviewTypesSelector } from 'store/taxonomy/selectors';

interface IActivityReviewEntryProps {
  className?: string;
  entry: IActivityReviewHistory;
  providerName: string;
}

export const ActivityReviewEntry = (props: IActivityReviewEntryProps): ReactElement => {
  const { className, entry, providerName } = props;
  const reviewTypes = useSelector(activityReviewTypesSelector);
  const reviewOutcomes = useSelector(activityReviewOutcomesSelector);
  const type = useMemo(() => reviewTypes?.find((item) => item.id === entry.reviewTypeId), [entry, reviewTypes]);
  const outcome = useMemo(() => reviewOutcomes?.find((item) => item.id === entry.outcomeId), [entry, reviewOutcomes]);

  return (
    <section className={className} key={entry.id}>
      <h3>
        {type?.name} Review {moment(entry?.reviewDate).format('MM/DD/YYYY')}
      </h3>
      <p>
        Provider: <strong>{providerName}</strong>
      </p>
      <p>
        Provider View Date: <strong>{moment(entry?.providerVisibleReviewPeriodStartDate).format('MM/DD/YYYY')}</strong>
      </p>
      <p>
        Removed From Provider View:{' '}
        <strong>{moment(entry?.providerVisibleReviewPeriodEndDate).format('MM/DD/YYYY')}</strong>
      </p>
      {!!entry?.reviewNote && (
        <p>
          Review Note: <strong>{entry?.reviewNote}</strong>
        </p>
      )}
      {outcome?.name && (
        <p>
          Outcome: <strong>{outcome.name}</strong>
        </p>
      )}
      {entry?.outcomeDate && (
        <p>
          Outcome Date: <strong>{moment(entry.outcomeDate).format('MM/DD/YYYY')}</strong>
        </p>
      )}
      {entry?.outcomeDetails && (
        <p>
          Outcome Details: <strong>{entry.outcomeDetails}</strong>
        </p>
      )}
    </section>
  );
};
