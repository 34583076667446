import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';
import merge from 'lodash/merge';
import { ReactElement, useMemo } from 'react';

// Styles.
import styles from './Chart.module.scss';

interface IChartProps {
  options: Partial<Highcharts.Options>;
}

// Initialize highcharts exporting.
HighchartsExporting(Highcharts);

export const Chart = (props: IChartProps): ReactElement => {
  const { options } = props;
  const chartOptions = useMemo(
    () =>
      merge(options, {
        credits: { enabled: false },
        title: { style: { color: 'black' }, text: undefined },
        xAxis: {
          labels: {
            style: { color: 'black' },
          },
        },
        yAxis: {
          labels: {
            style: { color: 'black' },
          },
          stackLabels: {
            style: { color: 'black' },
          },
          title: {
            style: { color: 'black' },
          },
        },
      }),
    [options],
  );

  return (
    <div className={styles.container}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};
