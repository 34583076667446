import {
  CLEAR_CONTENT_TAGS,
  GET_CONTENT_TAGS,
  GET_CONTENT_TAGS_FAILURE,
  GET_CONTENT_TAGS_SUCCESS,
  GET_CONTENT_TAGS_BY_ID,
  GET_CONTENT_TAGS_BY_ID_FAILURE,
  GET_CONTENT_TAGS_BY_ID_SUCCESS,
} from './types';

import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store';
import { handleServerError } from '../../globals/utils/handleServerError';
import { PARSAction, ContentTag } from 'core/models';
import { ContentTagsService } from 'services/ContentTagsService';

export const getContentTagsAction = (): PARSAction<undefined, typeof GET_CONTENT_TAGS> => ({
  type: GET_CONTENT_TAGS,
});

export const getContentTagsSuccessAction = (
  payload: ContentTag[],
): PARSAction<ContentTag[], typeof GET_CONTENT_TAGS_SUCCESS> => {
  return {
    payload,
    type: GET_CONTENT_TAGS_SUCCESS,
  };
};

export const getContentTagsFailureAction = (payload: Error): PARSAction<Error, typeof GET_CONTENT_TAGS_FAILURE> => {
  return {
    payload,
    type: GET_CONTENT_TAGS_FAILURE,
  };
};

export const getContentTagsByIdAction = (): PARSAction<undefined, typeof GET_CONTENT_TAGS_BY_ID> => ({
  type: GET_CONTENT_TAGS_BY_ID,
});

export const getContentTagsByIdSuccessAction = (
  payload: ContentTag,
): PARSAction<ContentTag, typeof GET_CONTENT_TAGS_BY_ID_SUCCESS> => {
  return {
    payload,
    type: GET_CONTENT_TAGS_BY_ID_SUCCESS,
  };
};

export const getContentTagsByIdFailureAction = (
  payload: Error,
): PARSAction<Error, typeof GET_CONTENT_TAGS_BY_ID_FAILURE> => {
  return {
    payload,
    type: GET_CONTENT_TAGS_BY_ID_FAILURE,
  };
};

export const clearContentTagss = (): PARSAction<undefined, typeof CLEAR_CONTENT_TAGS> => ({
  type: CLEAR_CONTENT_TAGS,
});

export const getAllContentTags = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(getContentTagsAction());
  try {
    const contentTagsService = new ContentTagsService();
    const result: Array<ContentTag> = await contentTagsService.getAllContentTagsAsync();
    await dispatch(getContentTagsSuccessAction(result));
  } catch (error) {
    handleServerError({ error, thunkName: 'getAllContentTags' });
    await dispatch(getContentTagsFailureAction(error));
  }
};

export const getContentTagsById = (id: string): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
  getState,
) => {
  const { contentTagsState } = getState();
  // Check if we already have this ContentTags's details loaded
  const isExistingContentTags = !!contentTagsState?.childContentTags?.find(
    ({ parentContentTagId: ContentTagsId }: ContentTag): boolean => ContentTagsId === id,
  );
  if (isExistingContentTags) {
    return;
  }
  const contentTagsService = new ContentTagsService();
  await dispatch(getContentTagsByIdAction());
  try {
    const result = await contentTagsService.getContentTagsById(id);
    await dispatch(getContentTagsByIdSuccessAction(result));
  } catch (error) {
    handleServerError({ error, thunkName: 'getContentTagsById' });
    await dispatch(getContentTagsByIdFailureAction(error));
  }
};
