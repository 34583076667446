import { ReactElement } from 'react';
import { Box, FormLabel, Grid, RadioGroup, useMediaQuery } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';

// Components
import DatePicker from 'components/DatePicker/DatePicker';
import { FormikBooleanRadioField } from 'components/ContinuousImprovement/PureFormElements';

// Store + Core
import { BreakpointsEnum } from 'core/enums';
import { IActivityRegistrationDetails } from '../initialActivityRegistrationDetailsForm';

// Types
interface IProps {
  formikKey: string;
}

export const FormatDetails = (props: IProps): ReactElement => {
  const { formikKey } = props;
  const isSmBreakpoint = useMediaQuery(`(min-width:${BreakpointsEnum.sm})`);
  const {
    values: { enableMaximumCreditValidation },
  } = useFormikContext<IActivityRegistrationDetails>();

  return (
    <Box ml={5}>
      <Grid container spacing={isSmBreakpoint ? 6 : 2}>
        <Grid item>
          <FormLabel component="div">Format Start Date</FormLabel>
          <Field name={`${formikKey}.startDate`}>
            {({ field }) => <DatePicker field={field} formikKey={`${formikKey}.startDate`} timeOfDay="midnight" />}
          </Field>
        </Grid>
        <Grid item>
          <FormLabel component="div">Format End Date</FormLabel>
          <Field name={`${formikKey}.endDate`}>
            {({ field }) => <DatePicker field={field} formikKey={`${formikKey}.endDate`} timeOfDay="endOfDay" />}
          </Field>
        </Grid>
      </Grid>
      <Grid container spacing={isSmBreakpoint ? 10 : 2}>
        <Grid item>
          <FormLabel component="div">Allow Repeat Participation?</FormLabel>
          <RadioGroup aria-label="Allow Repeat Participation">
            <Grid container spacing={6}>
              <Grid item>
                <FormikBooleanRadioField name="Yes" formikKey={`${formikKey}.allowRepeatParticipation`} value />
              </Grid>
              <Grid item>
                <FormikBooleanRadioField name="No" formikKey={`${formikKey}.allowRepeatParticipation`} value={false} />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
        {/* Only show this field when `enableMaximumCreditValidation` is true. */}
        {!!enableMaximumCreditValidation && (
          <Grid item>
            <FormLabel component="div">Max Credit Validation Format?</FormLabel>
            <RadioGroup aria-label="Max Credit Validation Format">
              <Grid container spacing={6}>
                <Grid item>
                  <FormikBooleanRadioField name="Yes" formikKey={`${formikKey}.maxCreditValidation`} value />
                </Grid>
                <Grid item>
                  <FormikBooleanRadioField name="No" formikKey={`${formikKey}.maxCreditValidation`} value={false} />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
