// Libs
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { isNumber, keys } from 'lodash';
import { Link } from 'react-router-dom';
import { KeyboardBackspaceRounded } from '@material-ui/icons';

// Components
import Button from 'components/Button/Button';
import { CompletedRems } from './CompletedRems';
import { CompletedPharmacyCredits } from './CompletedPharmacyCredits';

// Core
import { AddActivityFormValues, IDictionary, Activity, ITaxonomyTerm, RollupOrganizationEnums } from 'core/models';
import { IBCTBoard } from 'layouts/pages/bct/types';
import { CompletedMoc } from './CompletedMoc';
import { TAGS_ENUM } from 'core/constants';
import { creditChildTermsSelector } from 'store/taxonomy/selectors';

// Styles
import styles from './CompletedActivityCard.module.scss';

interface IProps {
  currentActivities: Activity[];
  configurationDictionary: IDictionary<IBCTBoard>;
  handleAddAnotherActivity: () => void;
  values: AddActivityFormValues;
}
export const CompletedActivityCard = ({
  currentActivities,
  configurationDictionary,
  handleAddAnotherActivity,
  values,
}: IProps): ReactElement => {
  const isMoc = values.isMoc?.includes('true');
  const isRems = values.isRems?.includes('true');

  const creditChildTerms: ITaxonomyTerm[] = useSelector(creditChildTermsSelector);
  const pharmacyCreditTerm = creditChildTerms?.find(({ tag }) => tag === TAGS_ENUM.ACTIVITY_CREDIT_TYPE__PHARMACY);

  const hasPharmacyCredits: boolean = isNumber(values.credits?.[pharmacyCreditTerm?.id]);

  // UAT Bug 3107 - Add a block of text along with a link if isPharmacyCertificateProgram is true
  const isPharmacyCertificateProgram = values.isPharmacyCertificateProgram?.includes('true');
  // ACCME provided us two links depending on environment
  const certProgramLink =
    process.env.NODE_ENV === 'production'
      ? 'https://apps.acpe-accredit.org/PWTool/Login.aspx?t=JA'
      : 'https://test.acpe-accredit.org/PWTool/Login.aspx?t=JA';

  return (
    <>
      {currentActivities.map((currentActivity, ix) => {
        const { rollupOrganizationEnum, uanPharmacy, uanPharmacyTech } = currentActivity;
        const isJa = rollupOrganizationEnum === RollupOrganizationEnums.JA;
        const uanPharmacyString = `${uanPharmacy ? `UAN ${uanPharmacy}` : ''}`;
        const uanPharmacyTechString = `${uanPharmacyTech ? `UAN ${uanPharmacyTech}` : ''}`;

        return (
          <div className="caption-text" key={ix}>
            {currentActivity && currentActivity.printableId && <span>Activity ID: {currentActivity.printableId}</span>}
            {values.internalId && <span> | Internal ID: {values.internalId}</span>}
            {isJa && uanPharmacyString && <span> | {uanPharmacyString}</span>}
            {isJa && uanPharmacyTechString && <span> | {uanPharmacyTechString}</span>}
          </div>
        );
      })}
      <div className="completed-activity">
        {isMoc && (
          <ul>
            {keys(values.boardMocDetails).map(
              (boardKey: string): ReactElement => {
                return (
                  <li key={boardKey}>
                    <CompletedMoc
                      boardKey={boardKey}
                      configurationDictionary={configurationDictionary}
                      formValues={values}
                    />
                  </li>
                );
              },
            )}
          </ul>
        )}
        {isRems && isMoc && <hr className={styles.separator} />}
        {isRems && (
          <ul>
            {keys(values.boardRemsDetails).map(
              (remsKey: string): ReactElement => (
                <li key={remsKey}>
                  <CompletedRems
                    configurationDictionary={configurationDictionary}
                    formValues={values}
                    remsKey={remsKey}
                  />
                </li>
              ),
            )}
          </ul>
        )}
        {!!isPharmacyCertificateProgram && (
          <p className={styles['pharmacy-program']}>
            To create a new Certificate Program or register this activity with an existing certificate program, please
            click{' '}
            <a href={certProgramLink} rel="noreferrer noopener" target="_blank" title="ACPE Certificate Program">
              here
            </a>
            .
          </p>
        )}
        {(isMoc || isRems) && hasPharmacyCredits && <hr className={styles.separator} />}
        <ul>
          {currentActivities.map((currentActivity) => {
            const { rollupOrganizationEnum, status } = currentActivity;
            if (hasPharmacyCredits) {
              return (
                <li>
                  <CompletedPharmacyCredits
                    formValues={values}
                    rollupOrganizationEnum={rollupOrganizationEnum}
                    activityStatus={status}
                  />
                </li>
              );
            }
          })}
        </ul>
      </div>
      <div className="button-row">
        <div className="cta-container">
          <Button className="primary" type="button" onClick={handleAddAnotherActivity}>
            Add Another Activity
          </Button>
          <Link className="tertiary" to="/activities/summary">
            View All Activities
            <KeyboardBackspaceRounded className="tertiary-icon" />
          </Link>
        </div>
      </div>
    </>
  );
};
