import classNames from 'classnames';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

// Components.
import { DashboardSection } from '../DashboardSection';

// Styles.
import styles from './DashboardBreakdownSection.module.scss';

// Types.
import { IDashboardBreakdownSection } from 'store/dashboard/types';

export const DashboardBreakdownSection = (props: IDashboardBreakdownSection): ReactElement => {
  const { data = [], label, toggleable } = props;

  return (
    <DashboardSection className={styles.section} label={label} toggleable={toggleable}>
      <table>
        <tbody>
          {data.map((breakdown, index) => (
            <tr key={index}>
              <td>{breakdown.y}</td>
              <td>
                <Link className={classNames(styles.tag, styles[breakdown.color])} to={breakdown.link}>
                  {breakdown.name}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </DashboardSection>
  );
};
