import { ExpandMoreRounded, ExpandLessRounded } from '@material-ui/icons';
import classNames from 'classnames';
import { ReactElement, useEffect, useState, useRef, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Components.
import LogoutContainer from 'components/login/LogoutContainer';
import { OrganizationSelect } from './OrganizationSelect';
import { ProviderSelect } from './ProviderSelect';

// Core.
import { Configuration } from 'core';
import {
  IAuth0User,
  IUser,
  IUserOrganization,
  OrganizationKind,
  OrganizationKinds,
  RollupOrganizationEnums,
} from 'core/models';

// Store.
import { AppState } from 'store';
import { organizationKindSelector, rollupOrganizationEnumSelector } from 'store/user/selectors';

// Services.
import { History } from 'services';

// Styles.
import './usercontrol.module.scss';

interface IUserProps {
  auth0User: IAuth0User | null;
  userProfile: IUser | null;
  currentOrganization: IUserOrganization | null;
}

const getUserName = (userProfile: IUser | null, auth0User: IAuth0User | null) => {
  const innerUserProfile: IUser = userProfile || ({} as IUser);
  const innerAuth0User: IAuth0User = auth0User || ({} as IAuth0User);
  const firstName: string =
    (innerUserProfile && innerUserProfile.firstName) ||
    (innerAuth0User && innerAuth0User['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname']) ||
    '';
  const lastName: string =
    (innerUserProfile && innerUserProfile.lastName) ||
    (innerAuth0User && innerAuth0User['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname']) ||
    '';
  let name = (firstName + ' ' + lastName).trim();

  if (!name) name = innerUserProfile?.email || '';
  if (!name) name = innerAuth0User?.nickname || '';
  if (!name) name = innerAuth0User?.name || '';
  if (!name) name = innerAuth0User?.email || '';

  return name;
};

export const User = (props: IUserProps): ReactElement => {
  const organizationKind: OrganizationKind = useSelector(organizationKindSelector)?.organizationKind;
  const isBoardUser: boolean = organizationKind === OrganizationKinds.BOARD;
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  const isNARS = rollupOrganizationEnum === RollupOrganizationEnums.NARS;

  const [isClickOutsideDisabled, setIsClickOutsideDisabled] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  History.listen(() => setMenuOpen(false));
  const menuToggle = (event) => {
    event.preventDefault();
    setMenuOpen(!menuOpen);
  };

  const node = useRef(null);

  const handleClickOutside = useCallback(
    (e) => {
      // @ts-ignore: ref type
      if (!node?.current?.contains(e.target) && !isClickOutsideDisabled) {
        // This is required to allow a click event to propagate to any popout items that are not in the same subtree
        setTimeout(() => setMenuOpen(false), 300);
      }
    },
    [node, isClickOutsideDisabled],
  );

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [menuOpen, handleClickOutside]);

  const { auth0User, userProfile, currentOrganization } = props;

  return (
    <div ref={node} className="usercontrol">
      <div className="usercontrol-action">
        {!isBoardUser && !isNARS && <a href={Configuration.default.armatureUrl}>Back to Accreditation Management</a>}
        {isNARS && <Link to="/account-manager">Account Management</Link>}
      </div>
      <div className="usercontrol-user">
        {(auth0User || userProfile) && (
          <a href="" onClick={menuToggle}>
            {getUserName(userProfile, auth0User)}
            {menuOpen ? <ExpandLessRounded /> : <ExpandMoreRounded />}
          </a>
        )}
      </div>
      <div className={classNames('menu', { active: menuOpen })}>
        <div className="user-info">
          <div>Logged in as {userProfile?.email || auth0User?.email}</div>
          {currentOrganization && <div>{currentOrganization?.organizationName}</div>}
          {currentOrganization && <div>Organization ID: {currentOrganization?.businessId}</div>}
        </div>
        <div className="user-info dropdown-controls">
          <OrganizationSelect
            onSelectClose={() => setIsClickOutsideDisabled(false)}
            onSelectOpen={() => setIsClickOutsideDisabled(true)}
          />
        </div>
        <div className="user-info dropdown-controls">
          <ProviderSelect />
        </div>
        <div className="menu-utility">
          <Link to="/contact-support" className="support-link">
            Contact Support
          </Link>
          <LogoutContainer />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    auth0User: state.userState.auth0User,
    currentOrganization: state.userState.currentOrganizationContext,
    userProfile: state.userState.userProfile,
  };
};

export default connect(mapStateToProps)(User);
