import classNames from 'classnames';
import { Fragment, ReactElement } from 'react';

// Components.
import { DashboardWidget, IDashboardWidgetProps } from 'components/Dashboard';
import { DashboardBreakdownSection } from './DashboardBreakdownSection';
import { DashboardChartSection } from './DashboardChartSection';
import { DashboardFooterSection } from './DashboardFooterSection';
import { DashboardMetricSection } from './DashboardMetricSection';

// Store.
import { DashboardSectionTypes, IDashboardBlock } from 'store/dashboard/types';

// Styles.
import styles from './DashboardDataWidget.module.scss';

interface IDashboardDataWidgetProps extends IDashboardWidgetProps {
  data: IDashboardBlock;
}

export const DashboardDataWidget = (props: IDashboardDataWidgetProps): ReactElement => {
  const { data, className, ...widgetProps } = props;
  const { sections, links } = data || {};

  return (
    <DashboardWidget className={classNames(styles.widget, className)} {...widgetProps}>
      <div className={styles.sections}>
        {sections?.map((section, index) => (
          <Fragment key={index}>
            {section.type === DashboardSectionTypes.Chart && <DashboardChartSection {...section} />}
            {section.type === DashboardSectionTypes.Breakdown && <DashboardBreakdownSection {...section} />}
            {section.type === DashboardSectionTypes.Metric && <DashboardMetricSection {...section} />}
          </Fragment>
        ))}
      </div>
      <DashboardFooterSection links={links} />
    </DashboardWidget>
  );
};
