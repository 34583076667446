import { ReactElement } from 'react';
import { Field, useField } from 'formik';
import { Box, Grid, GridItemsAlignment, Typography } from '@material-ui/core';
import classNames from 'classnames';

// Types
interface IProps {
  checkboxAlignment?: GridItemsAlignment;
  callback?(): void;
  caption?: string;
  formikKey: string;
  name?: string;
  payloadValue?: boolean;
  shouldIgnoreCaptionStyling?: boolean;
}

export const FormikBooleanCheckboxField = (props: IProps): ReactElement => {
  const {
    callback,
    caption,
    checkboxAlignment = 'flex-start',
    formikKey,
    name,
    payloadValue,
    shouldIgnoreCaptionStyling = false,
  } = props;
  const [{ onChange, value }] = useField(formikKey);

  return (
    <Grid container wrap="nowrap">
      <Box component={Grid} display="flex" alignItems={checkboxAlignment}>
        <label className="form-input-checkbox">
          <Field
            onChange={callback || onChange}
            name={formikKey}
            type="checkbox"
            value={!!value}
            checked={payloadValue || !!value}
          />
          <span className="checkmark" />
          <Typography variant="srOnly">{name}</Typography>
        </label>
      </Box>
      <Grid item xs={11}>
        <div className="label-text-container">
          <div className="label-text">{name}</div>
          <div
            className={classNames({ 'caption-text': !shouldIgnoreCaptionStyling })}
            dangerouslySetInnerHTML={{ __html: caption }}
          />
        </div>
      </Grid>
    </Grid>
  );
};
