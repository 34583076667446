// this logic is a copy of part of these classes from the backend implementation, and needs to be kept in sync with them:
//  - https://github.com/accmeorg/PARS3/blob/develop/PARS.Application/Services/ActivityStateMachine.cs
//  - https://github.com/accmeorg/PARS3/blob/develop/PARS.Application/Services/ActivityRemsCompliantValidator.cs
//  - https://github.com/accmeorg/PARS3/blob/develop/PARS.Application/Services/ActivityMocRegistrationValidator.cs

import { CENTRAL_TIMEZONE, TAGS_ENUM, DELIVERY_METHOD_REQUIRED_ACTIVITY_TYPES } from 'core/constants';
import { StatusEnum } from 'core/enums';
import {
  Activity,
  ActivityType,
  ContentTag,
  IBoardSlim,
  ITaxonomyTerm,
  LocationTypeStringEnum,
  RollupOrganizationEnums,
  HasPharmacyContentTags,
  HasPharmacyRecertifications,
} from 'core/models';
import moment, { MomentInput } from 'moment-timezone';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityTypes } from 'store/activity/actions';
import { activityTypesSelector } from 'store/activity/selectors';
import { getAllBoards } from 'store/board/actions';
import { boardSelector } from 'store/board/selectors';
import { getAllContentTags } from 'store/contentTags/actions';
import { contentTagsSelector } from 'store/contentTags/selectors';
import {
  allLearnerTypeTermsSelector,
  amaCreditTermIdSelector,
  anccContactHoursCreditTermSelector,
  ipceCreditTermIdSelector,
  learnerKnowledgeMeasuredOutcomeSelector,
  obceCreditLevelCreditTermsSelector,
  pharmacyCreditTermIdSelector,
} from 'store/taxonomy/selectors';

export interface IActivityStateMachineContext {
  activityTypes: ActivityType[];
  allBoards: IBoardSlim[];
  amaCreditTerm: ITaxonomyTerm;
  anccContactHoursCreditTerm: ITaxonomyTerm;
  ipceCreditTerm: ITaxonomyTerm;
  obceCreditLevelCreditTerms: ITaxonomyTerm[];
  otherLearnersTermId: string;
  pharmacyCreditTerm: ITaxonomyTerm;
  physiciansLearnersTermId: string;
  learnerKnowledgeMeasuredOutcome: ITaxonomyTerm;
}

export type CanTransitionResult = true | string;

const pharmacyLiveDatesRequiredActivityTypes: string[] = ['C', 'RSS', 'LFT', 'CML'];

const compareUtcDateTimesAsCentralDatesImpl = (first: MomentInput, second: MomentInput) => {
  if (!first && !second) return 0;
  if (!first) return -1;
  if (!second) return 1;

  // convert each TZ to central
  // moment treats Date objects as browser-local time, and strings like "2021-06-02T05:00:00Z" as UTC, which turns out to be exactly what we want
  const firstM = moment(first).tz(CENTRAL_TIMEZONE);
  const secondM = moment(second).tz(CENTRAL_TIMEZONE);

  if (firstM.isSame(secondM, 'day')) {
    return 0;
  }

  if (firstM.isBefore(secondM, 'day')) {
    return -1;
  }

  return 1;
};

export const compareUtcDateTimesAsCentralDates = (first: MomentInput, second: MomentInput): -1 | 0 | 1 => {
  const result = compareUtcDateTimesAsCentralDatesImpl(first, second);
  return result;
};

const checkMeasuredOutcomeForClose = (
  context: IActivityStateMachineContext,
  activity: Activity,
): CanTransitionResult => {
  if (!activity.outcomesMeasured?.length) return 'Must include at least one measured outcome';
  if (
    activity.outcomesMeasured.every(
      (_) =>
        _ === context.learnerKnowledgeMeasuredOutcome?.id ||
        (context.learnerKnowledgeMeasuredOutcome?.terms || []).some((childTerm) => childTerm.id === _),
    )
  )
    return `At least 1 ActivityMeasuredOutcome besides '${
      context.learnerKnowledgeMeasuredOutcome?.name ?? 'Learner Knowledge'
    } will also be measured for this activity' is required`;

  return true;
};

const checkCommercialSupportForClose = (activity: Activity): CanTransitionResult => {
  if (activity.hasCommercialSupport !== true && activity.hasCommercialSupport !== false)
    return 'Has Commercial Support is required';
  if (activity.hasCommercialSupport === true && (activity.commercialSupportSources?.length ?? 0) === 0)
    return 'At least 1 commercial support source is required';

  for (const support of activity.commercialSupportSources ?? []) {
    if ((support.source ?? '').trim() === '') return 'Commercial Support Sources must have a name';
    if (!support.hasInKindSupport && isNaN(parseFloat((support.amountGiven ?? '').toString())))
      return 'Commercial Support Sources must be listed as in-kind or with a support amount';
  }

  return true;
};

const MocOrRemsRegistration = (activity: Activity): boolean => {
  return activity?.isMoc || activity?.isRems;
};

const canTransitionJaToClosed = (context: IActivityStateMachineContext, activity: Activity): CanTransitionResult => {
  if (!activity) return 'Activity not loaded';
  if (!context) return 'Context not loaded';
  if (activity.status !== StatusEnum.READY_TO_CLOSE) return 'Activity is not ready to close';
  if (!activity.title?.trim()) return 'Title is required';

  const activityType = context.activityTypes?.find((at) => at.id === activity.typeId);
  if (!activityType) return 'Cannot find activity type';
  if (DELIVERY_METHOD_REQUIRED_ACTIVITY_TYPES.includes(activityType.abbreviation) && !activity.locationType?.length)
    return 'Location Type is required';
  if (!activity.startDate) return 'Start Date is required';
  if (!activity.endDate) return 'End Date is required';

  if (compareUtcDateTimesAsCentralDates(activity.startDate, activity.endDate) > 0)
    return 'Start Date must be before End Date';
  if (compareUtcDateTimesAsCentralDates(activity.endDate, new Date()) > 0) return 'End Date must be in the past';

  const isInPerson = activity.locationType?.includes(LocationTypeStringEnum.InPerson);
  const country = activity.country?.trim();
  if (isInPerson && !country) return 'Location country is required';
  if (isInPerson && !activity.city?.trim()) return 'Location city is required';
  if (isInPerson && !activity.stateOrProvince?.trim() && country?.toUpperCase() === 'USA')
    return 'Location state/province is required';

  const creditTypes = Object.keys(activity.credits || {});
  if (creditTypes.length === 0) return 'Must include credits';

  if (creditTypes.includes(context.amaCreditTerm?.id) || creditTypes.includes(context.ipceCreditTerm?.id)) {
    if (activity.includeInCmeFinder !== true && activity.includeInCmeFinder !== false)
      return 'Include in CME finder is required';

    if (activity.hasStateContentTags) {
      if (
        (activity.rollupOrganizationEnum === RollupOrganizationEnums.JA &&
          (activity.hasPharmacyRecertifications || activity.hasPharmacyContentTags)) ||
        activity.hasStateContentTags
      ) {
        if (
          !activity.pharmacyRecertificationTaxonomyTerms?.length &&
          activity.hasPharmacyRecertifications === HasPharmacyRecertifications.Yes
        ) {
          return 'Pharmacy Recertification Terms are required';
        }
        if (
          !activity.pharmacyContentTagStateTaxonomyTerms?.length &&
          activity.hasPharmacyContentTags === HasPharmacyContentTags.Yes
        ) {
          return 'Pharmacy Content State Terms are required';
        }
      }
    }

    if ((activity.includeInCmeFinder || MocOrRemsRegistration(activity)) && !activity.participationFeeTypeId)
      return 'Participation Fee is required';
    if (
      (activity.includeInCmeFinder || MocOrRemsRegistration(activity)) &&
      activity.isRestrictedAudience !== true &&
      activity.isRestrictedAudience !== false
    )
      return 'Restricted Audience is required';
    if ((activity.includeInCmeFinder || MocOrRemsRegistration(activity)) && !activity.detailsUrl?.trim())
      return 'Activity URL is required';
  }

  if (creditTypes.includes(context.pharmacyCreditTerm?.id)) {
    if (!activity.targetAudience?.length) return 'Target Audience is required';
    if (!activity.pharmacyTypeId) return 'Pharmacy Type Category is required';
    if (!activity.pharmacyTopicId) return 'Pharmacy Topic Category is required';
    if (!activity.pharmacySequenceNumber) return 'Sequence number is required';

    if (activity.pharmacySequenceNumber < 1 || activity.pharmacySequenceNumber > 9999)
      return 'Sequence number must be between 1 and 9999 (inclusive)';

    if (pharmacyLiveDatesRequiredActivityTypes.includes(activityType.abbreviation)) {
      if (!activity.pharmacyLiveDates?.length) {
        return 'Pharmacy Live Dates are required';
      }
    }
  }

  if (!activity.description?.trim()) return 'Description is required';
  if (activity.isJointlyProvided !== true && activity.isJointlyProvided !== false)
    return 'Is Jointly Provided is required';
  if (activity.isJointlyProvided === true && (activity.jointProviders?.length ?? 0) === 0)
    return 'At least 1 activity joint sponsor is required';

  const measuredOutcomeError = checkMeasuredOutcomeForClose(context, activity);
  if (measuredOutcomeError !== true) return measuredOutcomeError;
  const commercialSupportError = checkCommercialSupportForClose(activity);
  if (commercialSupportError !== true) return commercialSupportError;

  if (!Object.keys(activity.learnerCounts).length) return 'Learner counts are required';

  if (Object.keys(activity.boardMocDetails ?? {}).length || Object.keys(activity.boardRemsDetails ?? {}).length) {
    if (!context.allBoards?.length) {
      return 'Boards must be loaded';
    }
  }

  // 5067 - no longer need to check for MOC/REMS valid to sync to close the activity

  return true;
};

const canTransitionParsToClosed = (context: IActivityStateMachineContext, activity: Activity): CanTransitionResult => {
  if (!activity) return 'Activity not loaded';
  if (activity.status !== StatusEnum.READY_TO_CLOSE) return 'Activity is not ready to close';
  if (!activity.title?.trim()) return 'Title is required';

  const activityType = context.activityTypes?.find((at) => at.id === activity.typeId);
  if (!activityType) return 'Cannot find activity type';
  if (DELIVERY_METHOD_REQUIRED_ACTIVITY_TYPES.includes(activityType.abbreviation) && !activity.locationType?.length)
    return 'Location Type is required';
  if (!activity.startDate) return 'Start Date is required';
  if (!activity.endDate) return 'End Date is required';

  if (compareUtcDateTimesAsCentralDates(activity.startDate, activity.endDate) > 0)
    return 'Start Date must not be after End Date';
  if (compareUtcDateTimesAsCentralDates(activity.endDate, new Date()) > 0) return 'End Date must be in the past';

  const isInPerson = activity.locationType?.includes(LocationTypeStringEnum.InPerson);
  const country = activity.country?.trim();
  if (isInPerson && !country) return 'Location country is required';
  if (isInPerson && !activity.city?.trim()) return 'Location city is required';
  if (isInPerson && !activity.stateOrProvince?.trim() && country?.toUpperCase() === 'USA')
    return 'Location state/province is required';

  if (activity.includeInCmeFinder !== true && activity.includeInCmeFinder !== false)
    return 'Include in CME finder is required';

  if ((activity.includeInCmeFinder || MocOrRemsRegistration(activity)) && !activity.participationFeeTypeId)
    return 'Participation Fee is required';
  if (
    (activity.includeInCmeFinder || MocOrRemsRegistration(activity)) &&
    activity.isRestrictedAudience !== true &&
    activity.isRestrictedAudience !== false
  )
    return 'Restricted Audience is required';
  if ((activity.includeInCmeFinder || MocOrRemsRegistration(activity)) && !activity.detailsUrl?.trim())
    return 'Activity URL is required';
  if (!activity.description?.trim()) return 'Description is required';

  if (!Object.keys(activity.credits || {}).includes(context.amaCreditTerm?.id))
    return 'Must include AMA PRA Category 1 credit';
  if (activity.isJointlyProvided !== true && activity.isJointlyProvided !== false)
    return 'Is Jointly Provided is required';
  if (activity.isJointlyProvided === true && (activity.jointProviders?.length ?? 0) === 0)
    return 'At least 1 activity joint sponsor is required';

  const measuredOutcomeError = checkMeasuredOutcomeForClose(context, activity);
  if (measuredOutcomeError !== true) return measuredOutcomeError;
  const commercialSupportError = checkCommercialSupportForClose(activity);
  if (commercialSupportError !== true) return commercialSupportError;

  if (activity.learnerCounts[context.physiciansLearnersTermId] === undefined)
    return 'Physician learner count is required';
  if (activity.learnerCounts[context.otherLearnersTermId] === undefined) return 'Other learner count is required';

  if (Object.keys(activity.boardMocDetails ?? {}).length || Object.keys(activity.boardRemsDetails ?? {}).length) {
    if (!context.allBoards?.length) {
      return 'Boards must be loaded';
    }
  }

  // 5067 - no longer need to check for MOC/REMS valid to sync to close the activity

  return true;
};

const canTransitionNarsToClosed = (context: IActivityStateMachineContext, activity: Activity): CanTransitionResult => {
  if (!activity) return 'Activity not loaded';
  if (activity.status !== StatusEnum.READY_TO_CLOSE) return 'Activity is not ready to close';
  if (!activity.title?.trim()) return 'Title is required';

  const activityType = context.activityTypes?.find((at) => at.id === activity.typeId);
  if (!activityType) return 'Cannot find activity type';
  if (DELIVERY_METHOD_REQUIRED_ACTIVITY_TYPES.includes(activityType.abbreviation) && !activity.locationType?.length)
    return 'Location Type is required';
  if (!activity.startDate) return 'Start Date is required';
  if (!activity.endDate) return 'End Date is required';

  if (compareUtcDateTimesAsCentralDates(activity.startDate, activity.endDate) > 0)
    return 'Start Date must not be after End Date';
  if (compareUtcDateTimesAsCentralDates(activity.endDate, new Date()) > 0) return 'End Date must be in the past';

  const isInPerson = activity.locationType?.includes(LocationTypeStringEnum.InPerson);
  const country = activity.country?.trim();
  if (isInPerson && !country) return 'Location country is required';
  if (isInPerson && !activity.city?.trim()) return 'Location city is required';
  if (isInPerson && !activity.stateOrProvince?.trim() && country?.toUpperCase() === 'USA')
    return 'Location state/province is required';

  const requiredNarsTermIds = [context.anccContactHoursCreditTerm?.id]
    .concat((context.obceCreditLevelCreditTerms ?? []).map((_) => _.id))
    .filter((_) => _);
  if (!Object.keys(activity.credits || {}).some((i) => requiredNarsTermIds.includes(i)))
    return 'Must include required credit';
  if (activity.isJointlyProvided === true && (activity.jointProviders?.length ?? 0) === 0)
    return 'At least 1 activity joint sponsor is required';
  if (activity.isJointlyProvided === null || activity.isJointlyProvided === undefined)
    return 'Providership (Directly or Jointly) is required';

  const commercialSupportError = checkCommercialSupportForClose(activity);
  if (commercialSupportError !== true) return commercialSupportError;

  if (!activity.providerAutoCloseActivities && !Object.keys(activity.learnerCounts).length)
    return 'Learner counts are required';

  return true;
};

export const canTransitionToClosed = (
  context: IActivityStateMachineContext,
  activity: Activity,
): CanTransitionResult => {
  if (!activity) return 'Activity is null';

  switch (activity.rollupOrganizationEnum) {
    case RollupOrganizationEnums.JA:
      return canTransitionJaToClosed(context, activity);
    case RollupOrganizationEnums.PARS:
      return canTransitionParsToClosed(context, activity);
    case RollupOrganizationEnums.NARS:
      return canTransitionNarsToClosed(context, activity);
    default:
      return `Unknown rollup organization: ${activity.rollupOrganizationEnum}`;
  }
};

// wrap up the hooks into a single context object that can be used for multiple canTransitionToClosed calls (ie. if this is later repurposed for client-side checking of multiple activities to be bulk-closed)
export const useActivityStateMachineContext = (): IActivityStateMachineContext => {
  const dispatch = useDispatch();
  const allBoards: IBoardSlim[] = useSelector(boardSelector);
  const allContentTags: ContentTag[] = useSelector(contentTagsSelector);
  const learnerTypes: ITaxonomyTerm[] = useSelector(allLearnerTypeTermsSelector);
  const amaCreditTerm: ITaxonomyTerm = useSelector(amaCreditTermIdSelector);
  const pharmacyCreditTerm: ITaxonomyTerm = useSelector(pharmacyCreditTermIdSelector);
  const ipceCreditTerm: ITaxonomyTerm = useSelector(ipceCreditTermIdSelector);
  const anccContactHoursCreditTerm: ITaxonomyTerm = useSelector(anccContactHoursCreditTermSelector);
  const obceCreditLevelCreditTerms: ITaxonomyTerm[] = useSelector(obceCreditLevelCreditTermsSelector);
  const activityTypes: ActivityType[] = useSelector(activityTypesSelector);
  const learnerKnowledgeMeasuredOutcome: ITaxonomyTerm = useSelector(learnerKnowledgeMeasuredOutcomeSelector);
  const physiciansLearnersTermId = learnerTypes?.find((lt) => lt.tag === TAGS_ENUM.LEARNER_TYPES__PHYSICIANS)?.id;
  const otherLearnersTermId = learnerTypes?.find((lt) => lt.tag === TAGS_ENUM.LEARNER_TYPES__OTHER_LEARNERS)?.id;

  useEffect(() => {
    if (!allBoards?.length) {
      dispatch(getAllBoards());
    }
  }, [allBoards, dispatch]);

  useEffect(() => {
    if (!allContentTags?.length) {
      dispatch(getAllContentTags());
    }
  }, [allContentTags, dispatch]);

  useEffect(() => {
    if (!activityTypes?.length) {
      dispatch(getActivityTypes());
    }
  }, [activityTypes, dispatch]);

  return useMemo(
    () => ({
      activityTypes,
      allBoards,
      allContentTags,
      amaCreditTerm,
      anccContactHoursCreditTerm,
      ipceCreditTerm,
      learnerKnowledgeMeasuredOutcome,
      obceCreditLevelCreditTerms,
      otherLearnersTermId,
      pharmacyCreditTerm,
      physiciansLearnersTermId,
    }),
    [
      activityTypes,
      allBoards,
      allContentTags,
      amaCreditTerm,
      anccContactHoursCreditTerm,
      ipceCreditTerm,
      learnerKnowledgeMeasuredOutcome,
      obceCreditLevelCreditTerms,
      otherLearnersTermId,
      pharmacyCreditTerm,
      physiciansLearnersTermId,
    ],
  );
};

// simpliest way to use this - single hook call with an activity, and get the result
export const useCanTransitionToClosed = (activity: Activity): CanTransitionResult => {
  const context = useActivityStateMachineContext();

  const result = canTransitionToClosed(context, activity);

  return result;
};
