import { ReactElement, useState } from 'react';

// Components
import { UploadHistory } from 'components/Batch/UploadHistory';
import { PageNavigation, PageTitle } from 'components/PageComponents';
import { BatchActivityForm } from 'layouts/pages/activity/forms/batchActivityForm';

const BatchActivityHistoryPage = (): ReactElement => {
  const [isShowingDropzone, setIsShowingDropzone] = useState<boolean>(false);

  return (
    <section className="form-container">
      <PageNavigation path="/activities/batch" label="Batch Upload Activity" />
      <PageTitle eyebrow="upload history" title="View and download previous batch files and error reports." />
      <div className="form-instructions">
        If you have <span>rejected records</span>, follow these instructions:
        <ol>
          <li>Download rejected activities (this download contains only records that weren’t processed).</li>
          <li>Make any necessary corrections referencing the error descriptions in the returned Excel file. </li>
          <li>Save the spreadsheet as an Excel file once you’ve made any changes.</li>
          <li>
            {/* Leave as native button tag for styling purposes. */}
            <button className="link" onClick={(): void => setIsShowingDropzone(true)}>
              Upload
            </button>{' '}
            the newly corrected Excel file.
          </li>
        </ol>
        {isShowingDropzone && <BatchActivityForm />}
      </div>
      <UploadHistory fileType="activity" />
    </section>
  );
};

export default BatchActivityHistoryPage;
