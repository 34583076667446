import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import { CircularProgress } from '@material-ui/core';

// Components
import Button from 'components/Button/Button';

// Actions
import { toggleCloseActivity } from 'store/activity/actions';

// Core + Store
import { ActivitySearchResultActivity } from 'core/models';
import { StatusEnum } from 'core/enums';

type IProps = {
  callback?(): void;
  onClose?(): void;
  selectedActivities: ActivitySearchResultActivity[];
};
export const BulkUncloseActivitiesForm = (props: IProps): ReactElement => {
  const dispatch = useDispatch();
  const { callback, onClose, selectedActivities } = props;

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (_: FormikValues, { setSubmitting }) => {
      await dispatch(
        toggleCloseActivity({
          activityIds: selectedActivities
            .map((activity: ActivitySearchResultActivity): string => activity.key || '')
            .filter((id: string): boolean => id !== ''),
          status: StatusEnum.CLOSED,
        }),
      );
      // We are done submitting.
      await setSubmitting(false);

      // Close the modal.
      await onClose();

      // Update the redux state.
      await callback?.();
    },
  });

  const { handleSubmit, isSubmitting } = formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        {`Are you sure you want to reopen ${selectedActivities.length} item(s)?`}
        <div className="modal-button-row">
          <Button
            className="primary"
            disabled={isSubmitting}
            startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
            type="submit"
          >
            Yes, Reopen
          </Button>
          <Button className="secondary" disabled={isSubmitting} onClick={onClose}>
            No, Don't Reopen
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};
