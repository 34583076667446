import { ReactElement, ReactNode } from 'react';
import { Provider, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { StylesProvider } from '@material-ui/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment-timezone';

// Components
import AuthenticatedLayoutRoute from 'components/global/routing/AuthenticatedLayoutRoute';
import DownloadFile from 'components/DownloadFile/DownloadFile';
import { DownloadLearnerFile } from './components/DownloadFile/DownloadLearnerFile';
import { DownloadLearnerSearchFile } from './components/DownloadFile/DownloadLearnerSearchFile';

// Styles
import './index.scss';
import { commonTheme } from 'globals/themes/common-theme';

// Layouts/Pages
import { AuthenticatedLayout } from 'layouts/AuthenticatedLayout';
import { DashboardPage } from 'layouts/pages/dashboard';
import DashboardAgreementPage from 'layouts/pages/dashboard/agreement';
import { ActivitySummaryPage } from 'layouts/pages/activity/summary';
import ActivityDetailPage from 'layouts/pages/activity/detail';
import ActivityEditPage from 'layouts/pages/activity/edit';
import { AddActivityPage } from 'layouts/pages/activity/add-activity';
import BatchActivityPage from 'layouts/pages/activity/batch';
import BatchActivityHistoryPage from 'layouts/pages/activity/batch/history';
import { LearnerSummaryPage } from 'layouts/pages/learner/summary';
import { AddLearnerPage } from 'layouts/pages/learner/add-learner';
import { LearnerDetailPage } from 'layouts/pages/learner/detail';
import { LearnersSummaryDetailPage } from 'layouts/pages/learner/detail/ByActivity';
import { LearnerSearchPage } from 'layouts/pages/learner/search';
import { BatchLearnerPage } from 'layouts/pages/learner/batch';
import { BatchLearnerHistoryPage } from 'layouts/pages/learner/batch/history';
import { LearnerValidationPage } from 'layouts/pages/learner/validation';
import { LearnerValidationToolBatchPage } from 'layouts/pages/learner/validation/batch';
import { BatchLearnerValidationHistoryPage } from 'layouts/pages/learner/validation/batch/history';
import { ReportPage } from 'layouts/pages/report';
import { ReportViewPage } from 'layouts/pages/report/view';
import { BCTPage } from 'layouts/pages/bct';
import { BoardListPage } from 'layouts/pages/boardlist';
import { ProgramSummary } from 'layouts/pages/dashboard/program-summary';
import { AdminPage } from 'layouts/pages/admin';
import { AccountManagerPage } from 'layouts/pages/account-manager';
import { BoardProcessingMetricsPage } from 'layouts/pages/admin/board-processing-metrics';
import { ProviderSubmissionLogsPage } from 'layouts/pages/admin/provider-submission-logs';
import { SearchSynonymsPage } from 'layouts/pages/admin/search-synonyms';
import { AdminCommandsPage } from 'layouts/pages/admin/admin-commands';
import { CmePassportImpersonationPage } from 'layouts/pages/admin/cme-passport-impersonation';
import { CreateWebServiceAccount } from './layouts/pages/admin/create-web-service-account';
import { SetContextPage } from 'layouts/pages/system/SetContextPage';

// Store
import { store } from 'store';
import { NotFoundPage } from 'layouts/pages/notfound';
import { HelpDeskPage } from 'layouts/pages/helpdesk';
import { DEFAULT_TIMEZONE } from './core/constants';
import { SmsSummaryReportPage } from './layouts/pages/report/SummaryReport';
import classNames from 'classnames';
import { getSite } from 'utils/site';
import { rollupOrganizationEnumSelector } from 'store/user/selectors';
import { RollupOrganizationEnums } from 'core/models';
import { SiteHeadManager } from 'components/SiteHeadManager/SiteHeadManager';
import { AccountManagerAddEditUserPage } from 'layouts/pages/account-manager/add-edit-user';
import { AccountManagerEditOrganizationPage } from 'layouts/pages/account-manager/edit-organization';
import { AccountManagerAddEditAccreditationPage } from 'layouts/pages/account-manager/add-edit-accreditation';
import { AccountManagerAddEditProviderPage } from 'layouts/pages/account-manager/add-edit-provider';

// Force all moment timezones to match the default. You should still be able to use moment like normal in any ts/tsx files.
moment.tz.setDefault(DEFAULT_TIMEZONE);

// placeholder for /activities/add-activity that just redirects to the real page
const AddActivityPlaceholder = () => <Redirect to="/activities/add-activity/new" />;

const Container = ({ children }: { children: ReactNode }): ReactElement => {
  const site = getSite();
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  const className = classNames('App', {
    'site-ja': rollupOrganizationEnum === RollupOrganizationEnums.JA,
    'site-nars': site === 'NARS',
    'site-pars': site === 'PARS',
  });
  return <div className={className}>{children}</div>;
};

const App = (): ReactNode => {
  return (
    <>
      <SiteHeadManager />
      <Provider store={store}>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={commonTheme}>
            <Container>
              <Switch>
                <AuthenticatedLayoutRoute
                  exact
                  path="/"
                  layout={AuthenticatedLayout}
                  render={() => <Redirect to="/dashboard" />}
                />
                {/** this page will set some values then immediately redirect to /, so it doesn't do auth or have a layout **/}
                <Route component={SetContextPage} exact path="/set-context/:userId/:orgId?/:providerId?" />
                <AuthenticatedLayoutRoute
                  component={BCTPage}
                  exact
                  hideSubnav
                  isStaffOnly
                  layout={AuthenticatedLayout}
                  path="/bct/:id"
                />
                <AuthenticatedLayoutRoute
                  component={DashboardPage}
                  exact
                  hideSubnav
                  layout={AuthenticatedLayout}
                  path="/dashboard"
                />
                <AuthenticatedLayoutRoute
                  component={DashboardAgreementPage}
                  exact
                  hideSubnav
                  isBoardUserAllowed={false}
                  layout={AuthenticatedLayout}
                  path="/dashboard/agreement"
                />
                <AuthenticatedLayoutRoute
                  component={ProgramSummary}
                  exact
                  hideSubnav
                  isBoardUserAllowed={false}
                  layout={AuthenticatedLayout}
                  path="/dashboard/summary"
                />
                <AuthenticatedLayoutRoute
                  exact
                  layout={AuthenticatedLayout}
                  path="/Activities"
                  redirectTo="/activities/summary"
                />
                <AuthenticatedLayoutRoute
                  component={BoardListPage}
                  exact
                  hideSubnav
                  isStaffOnly
                  layout={AuthenticatedLayout}
                  path="/board-list"
                />
                <AuthenticatedLayoutRoute
                  component={AdminPage}
                  exact
                  hideSubnav
                  isStaffOnly
                  layout={AuthenticatedLayout}
                  path="/admin"
                />
                <AuthenticatedLayoutRoute
                  component={AccountManagerPage}
                  hideSubnav
                  exact
                  layout={AuthenticatedLayout}
                  path="/account-manager"
                />
                <AuthenticatedLayoutRoute
                  component={AccountManagerAddEditAccreditationPage}
                  hideSubnav
                  exact
                  layout={AuthenticatedLayout}
                  path="/account-manager/accreditations/add"
                />
                <AuthenticatedLayoutRoute
                  component={AccountManagerAddEditAccreditationPage}
                  hideSubnav
                  exact
                  layout={AuthenticatedLayout}
                  path="/account-manager/accreditations/edit/:id"
                />
                <AuthenticatedLayoutRoute
                  component={AccountManagerAddEditProviderPage}
                  hideSubnav
                  exact
                  layout={AuthenticatedLayout}
                  path="/account-manager/provider/add"
                />
                <AuthenticatedLayoutRoute
                  component={AccountManagerAddEditProviderPage}
                  hideSubnav
                  exact
                  layout={AuthenticatedLayout}
                  path="/account-manager/provider/edit/:id"
                />
                <AuthenticatedLayoutRoute
                  component={AccountManagerAddEditUserPage}
                  hideSubnav
                  exact
                  layout={AuthenticatedLayout}
                  path="/account-manager/users/add"
                />
                <AuthenticatedLayoutRoute
                  component={AccountManagerEditOrganizationPage}
                  hideSubnav
                  exact
                  layout={AuthenticatedLayout}
                  path="/account-manager/edit-organization/:id"
                />
                <AuthenticatedLayoutRoute
                  component={AccountManagerAddEditUserPage}
                  hideSubnav
                  exact
                  layout={AuthenticatedLayout}
                  path="/account-manager/users/edit/:id"
                />
                <AuthenticatedLayoutRoute
                  component={BoardProcessingMetricsPage}
                  exact
                  hideSubnav
                  isStaffOnly
                  layout={AuthenticatedLayout}
                  path="/admin/board-processing-metrics"
                />
                <AuthenticatedLayoutRoute
                  component={ProviderSubmissionLogsPage}
                  exact
                  hideSubnav
                  isStaffOnly
                  layout={AuthenticatedLayout}
                  path="/admin/provider-submission-logs"
                />
                <AuthenticatedLayoutRoute
                  component={AdminCommandsPage}
                  exact
                  hideSubnav
                  isStaffOnly
                  layout={AuthenticatedLayout}
                  path="/admin/admin-commands"
                />
                <AuthenticatedLayoutRoute
                  layout={AuthenticatedLayout}
                  component={CreateWebServiceAccount}
                  exact
                  hideSubnav
                  isStaffOnly
                  path="/admin/create-web-service-account"
                />
                <AuthenticatedLayoutRoute
                  component={SearchSynonymsPage}
                  exact
                  hideSubnav
                  isStaffOnly
                  layout={AuthenticatedLayout}
                  path="/admin/search-synonyms"
                />
                <AuthenticatedLayoutRoute
                  component={CmePassportImpersonationPage}
                  exact
                  hideSubnav
                  isStaffOnly
                  layout={AuthenticatedLayout}
                  path="/admin/cme-passport-impersonation"
                />
                <AuthenticatedLayoutRoute
                  component={ActivitySummaryPage}
                  exact
                  layout={AuthenticatedLayout}
                  path="/activities/summary"
                />
                <AuthenticatedLayoutRoute
                  component={ActivityDetailPage}
                  exact
                  layout={AuthenticatedLayout}
                  path="/activities/detail/:id"
                />
                <AuthenticatedLayoutRoute
                  component={ActivityEditPage}
                  exact
                  isBoardUserAllowed={false}
                  layout={AuthenticatedLayout}
                  path="/activities/edit/:id"
                />
                <AuthenticatedLayoutRoute
                  component={AddLearnerPage}
                  exact
                  isBoardUserAllowed={false}
                  layout={AuthenticatedLayout}
                  path="/activities/:activityId/add-learner"
                />
                <AuthenticatedLayoutRoute
                  component={AddActivityPlaceholder}
                  exact
                  isBoardUserAllowed={false}
                  layout={AuthenticatedLayout}
                  path="/activities/add-activity"
                />
                <AuthenticatedLayoutRoute
                  component={AddActivityPage}
                  exact
                  isBoardUserAllowed={false}
                  layout={AuthenticatedLayout}
                  path="/activities/add-activity/new"
                />

                <AuthenticatedLayoutRoute
                  component={BatchActivityHistoryPage}
                  exact
                  isBoardUserAllowed={false}
                  layout={AuthenticatedLayout}
                  path="/activities/batch/history"
                />
                <AuthenticatedLayoutRoute
                  component={BatchActivityPage}
                  exact
                  isBoardUserAllowed={false}
                  layout={AuthenticatedLayout}
                  path="/activities/batches/downloadtemplate/:type"
                />
                <AuthenticatedLayoutRoute
                  component={BatchActivityPage}
                  exact
                  isBoardUserAllowed={false}
                  layout={AuthenticatedLayout}
                  path="/activities/batch"
                />
                <AuthenticatedLayoutRoute
                  exact
                  layout={AuthenticatedLayout}
                  path="/learners"
                  redirectTo="/learners/summary"
                />
                <AuthenticatedLayoutRoute
                  component={LearnerSummaryPage}
                  exact
                  layout={AuthenticatedLayout}
                  path="/learners/summary"
                />
                <AuthenticatedLayoutRoute
                  component={LearnersSummaryDetailPage}
                  exact
                  layout={AuthenticatedLayout}
                  path="/learners/summary/detail/:key"
                />
                <AuthenticatedLayoutRoute
                  component={LearnerDetailPage}
                  exact
                  layout={AuthenticatedLayout}
                  path="/learners/:activityId/learnercompletions/bylearner/:ulid"
                />
                <AuthenticatedLayoutRoute
                  component={LearnerDetailPage}
                  exact
                  layout={AuthenticatedLayout}
                  path="/learners/:activityId/learnercompletions/bycompletion/:completionId"
                />
                <AuthenticatedLayoutRoute
                  component={LearnerSearchPage}
                  exact
                  layout={AuthenticatedLayout}
                  path="/learners/search"
                />
                <AuthenticatedLayoutRoute
                  component={BatchLearnerHistoryPage}
                  exact
                  isBoardUserAllowed={false}
                  layout={AuthenticatedLayout}
                  path="/learners/batch/history"
                />
                <AuthenticatedLayoutRoute
                  component={BatchLearnerPage}
                  exact
                  isBoardUserAllowed={false}
                  layout={AuthenticatedLayout}
                  path="/learners/batches/downloadtemplate/:type"
                />
                <AuthenticatedLayoutRoute
                  component={BatchLearnerPage}
                  exact
                  isBoardUserAllowed={false}
                  layout={AuthenticatedLayout}
                  path="/learners/batch"
                />
                <AuthenticatedLayoutRoute
                  component={LearnerValidationPage}
                  exact
                  layout={AuthenticatedLayout}
                  path="/learners/validation"
                />
                <AuthenticatedLayoutRoute
                  component={LearnerValidationToolBatchPage}
                  exact
                  layout={AuthenticatedLayout}
                  path="/learners/validation/batch"
                />
                <AuthenticatedLayoutRoute
                  component={BatchLearnerValidationHistoryPage}
                  exact
                  layout={AuthenticatedLayout}
                  path="/learners/validation/batch/history"
                />
                <AuthenticatedLayoutRoute
                  component={ReportPage}
                  exact
                  hideSubnav
                  layout={AuthenticatedLayout}
                  path="/reports"
                />
                <AuthenticatedLayoutRoute
                  component={SmsSummaryReportPage}
                  exact
                  hideSubnav
                  layout={AuthenticatedLayout}
                  path="/reports/summaryreports"
                />
                <AuthenticatedLayoutRoute
                  component={ReportViewPage}
                  exact
                  layout={AuthenticatedLayout}
                  path="/reports/view/:userId/:reportId"
                />
                <AuthenticatedLayoutRoute
                  component={HelpDeskPage}
                  exact
                  hideSubnav
                  allowInvalidUser
                  layout={AuthenticatedLayout}
                  path="/contact-support"
                />
                <AuthenticatedLayoutRoute hideSubnav path="*" layout={AuthenticatedLayout} component={NotFoundPage} />
              </Switch>
              <DownloadFile />
              <DownloadLearnerFile />
              <DownloadLearnerSearchFile />
            </Container>
          </MuiThemeProvider>
        </StylesProvider>
      </Provider>
    </>
  );
};

export default App;
