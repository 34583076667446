import { IInitialSearchFacet } from '../types';
import { IActivitySearchStateProps } from 'core/models';

export const INITIAL_ACTIVITY_SEARCH_FACETS: IInitialSearchFacet = {
  excludeFilters: false,
  isDeleted: false,
  searchText: '',
  skip: 0,
  sortAscending: false,
  sortBy: 'Timestamp',
  top: 10,
};

export const INITIAL_SEARCH_STATE_PROPS: IActivitySearchStateProps = {
  page: 1,
  selectedActivities: [],
};
