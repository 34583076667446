import { Info } from '@material-ui/icons';
import classNames from 'classnames';
import { ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// Components.
import { DashboardWidget, IDashboardWidgetProps } from 'components/Dashboard';
import Button from 'components/Button/Button';

// Store.
import { dismissDashboardMessage } from 'store/dashboard/actions';
import { IDashboardMessage } from 'store/dashboard/types';

// Styles.
import styles from './DashboardMessageWidget.module.scss';

// Utils
import { IValidateUrl, validateUrl } from 'utils';

// Types
import { ButtonVariant } from 'core/enums';

interface IDashboardMessageWidgetProps extends IDashboardWidgetProps {
  data: IDashboardMessage;
}

export const DashboardMessageWidget = (props: IDashboardMessageWidgetProps): ReactElement => {
  const { className, data, ...widgetProps } = props;
  const { content, id, linkText, linkUrl } = data || {};
  const dispatch = useDispatch();

  const dynamicUrl: IValidateUrl = validateUrl(linkUrl);

  const handleDismiss = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(dismissDashboardMessage(id));
    },
    [dispatch, id],
  );

  return (
    <DashboardWidget className={classNames(styles.widget, className)} {...widgetProps}>
      <section className={styles.section}>
        <h2>
          <Info className={styles.icon} /> Important Message
        </h2>
        <p>{content}</p>
      </section>
      <footer className={styles.footer}>
        <Button onClick={handleDismiss} variant={ButtonVariant.Tertiary} size="large">
          Dismiss
        </Button>
        {/* If the url passed is an external link, wrap in an anchor tag.
            Otherwise use the SPA router link. Keep the tabIndex on the Button because it is wrapped in a anchor tag.
        */}
        {typeof dynamicUrl === 'string' ? (
          <a href={dynamicUrl} rel="noreferrer noopener" target="_blank">
            <Button tabIndex={-1} title={linkText} variant={ButtonVariant.Primary}>
              {linkText}
            </Button>
          </a>
        ) : (
          <Link className="primary" to={dynamicUrl?.pathname}>
            {linkText}
          </Link>
        )}
      </footer>
    </DashboardWidget>
  );
};
