import { FormControl, InputLabel } from '@material-ui/core';
import { ReactElement, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import { noop, sortBy } from 'lodash';

// Components
import Button from 'components/Button/Button';
import { ITypeaheadOption, Typeahead } from 'components/ContinuousImprovement/Typeahead';
// Core.
import { CACHE_SELECTED_ORGANIZATION, CACHE_SELECTED_PROVIDER } from 'core/constants';
import { IUser, IUserOrganization, OrganizationKinds } from 'core/models';

// Services.
import { CacheService } from 'services';
import { getRedirectUrl, getSite, getTargetSite } from 'utils/site';

// Store.
import {
  currentOrganizationContextSelector,
  isAccmeUserSelector,
  isDefaultOrgAccmeStaffSelector,
  userProfileSelector,
} from 'store/user/selectors';

interface IFormModel {
  provider: ITypeaheadOption;
}

interface IProps {
  beginWithPristineForm?: boolean;
  onChange?: (_, value: ITypeaheadOption) => void;
  onClear?: () => void;
}

export const ProviderSelect = ({ onChange, onClear, beginWithPristineForm }: IProps): ReactElement => {
  const site = getSite();
  const userProfile: IUser = useSelector(userProfileSelector);
  const currentOrganization: IUserOrganization = useSelector(currentOrganizationContextSelector);
  const isUserAccme: boolean = useSelector(isAccmeUserSelector);
  const isCurrentOrgAccmeStaff: boolean = useSelector(isDefaultOrgAccmeStaffSelector);

  const isOrganizationAccreditor = currentOrganization?.organizationKind === OrganizationKinds.ACCREDITOR;
  const childOrganizationIds: string[] = currentOrganization?.childOrganizationIds;

  const providerOptions: IUserOrganization[] = useMemo(
    () =>
      sortBy(
        userProfile?.secondaryUserOrganizations?.filter(({ id }) => childOrganizationIds?.includes(id)),
        'organizationName',
        'businessId',
      ),
    [userProfile?.secondaryUserOrganizations, childOrganizationIds],
  );

  const handleChange = (_, value: ITypeaheadOption) => {
    if (onChange) {
      onChange(_, value);
    } else {
      const selected = providerOptions?.find(({ id }: IUserOrganization): boolean => id === value?.id);
      if (selected?.id) {
        const targetSite = getTargetSite(selected.rollupOrganizationEnum);
        if (site !== targetSite) {
          window.location.href = getRedirectUrl(targetSite, CacheService.get(CACHE_SELECTED_ORGANIZATION), selected.id);
          return;
        }
      }
      CacheService.set(CACHE_SELECTED_PROVIDER, value?.id);
      window.location.href = '/';
    }
  };

  const handleClear = useCallback(() => {
    if (onClear) {
      onClear();
    } else {
      CacheService.remove(CACHE_SELECTED_PROVIDER);
      window.location.href = '/';
    }
  }, [onClear]);

  // Get the current provider out of cache and attempt to find it in the current provider options
  let initialProvider: ITypeaheadOption;

  if (!beginWithPristineForm) {
    const storedProviderId: string = CacheService.get(CACHE_SELECTED_PROVIDER);
    const storedProvider: IUserOrganization = providerOptions?.find(
      ({ id }: IUserOrganization): boolean => id === storedProviderId,
    );
    initialProvider = storedProvider && {
      id: storedProvider?.id,
      label: `${storedProvider?.businessId} - ${storedProvider?.organizationName}`,
    };
  }

  const formik = useFormik<IFormModel>({
    enableReinitialize: true,
    initialValues: {
      provider: initialProvider,
    },
    onSubmit: noop,
  });

  if (!isOrganizationAccreditor && !isCurrentOrgAccmeStaff && !isUserAccme) {
    return null;
  }
  const typeaheadOptions: ITypeaheadOption[] = providerOptions?.map(
    ({ businessId, id, organizationName }: IUserOrganization): ITypeaheadOption => ({
      id,
      label: `${businessId} - ${organizationName}`,
    }),
  );
  return (
    providerOptions.length > 1 && (
      <FormikProvider value={formik}>
        <FormControl>
          <InputLabel htmlFor="change-provider-select">Provider Context</InputLabel>
          <Typeahead id="change-provider-select" options={typeaheadOptions} name="provider" onChange={handleChange} />
        </FormControl>
        <Button aria-label="Clear organization context" onClick={handleClear}>
          Clear
        </Button>
      </FormikProvider>
    )
  );
};
