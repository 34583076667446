import { PARSAction } from 'core/models';

export interface IHelpDeskState {
  attachments: Record<string, string>;
}

export interface IHelpDeskTicketRequest {
  attachmentBlobIds: string[];
  name: string;
  email: string;
  question: string;
}

export const HELP_DESK_ADD_ATTACHMENT = 'HELP_DESK_ADD_ATTACHMENT';
export const HELP_DESK_REMOVE_ATTACHMENT = 'HELP_DESK_REMOVE_ATTACHMENT';
export const HELP_DESK_RESET_ATTACHMENT = 'HELP_DESK_RESET_ATTACHMENT';

export type HelpDeskAddAttachment = PARSAction<Record<string, string>, typeof HELP_DESK_ADD_ATTACHMENT>;
export type HelpDeskRemoveAttachment = PARSAction<string, typeof HELP_DESK_REMOVE_ATTACHMENT>;
export type HelpDeskResetAttachment = PARSAction<string, typeof HELP_DESK_RESET_ATTACHMENT>;

export type HelpDeskActionTypes = HelpDeskAddAttachment | HelpDeskRemoveAttachment | HelpDeskResetAttachment;
