import { Activity, UpdateActivityRequest } from 'core/models';
import omit from 'lodash/omit';

const omittedKeys = ['sequenceNumber', 'formTemplate', 'printableId', 'status'];
const stepOneKeys = [
  'city',
  'country',
  'endDate',
  'locationType',
  'postalCode',
  'recurring',
  'startDate',
  'stateOrProvince',
  'status',
  'title',
  'typeId',
];

interface IArgs {
  submitValues?: UpdateActivityRequest;
  currentActivities: Activity[];
}

export const transformUpdatePayloadValues = ({ submitValues, currentActivities }: IArgs): UpdateActivityRequest[] => {
  const isMultiActivity = currentActivities.length > 1;
  // Return a merged copy of the formik model + the server-generated currentActivities
  return currentActivities.map((activity: UpdateActivityRequest, idx: number) => {
    // Create a pure updated activity without the server-generated keys.
    // This is to reduce the amount of data that we are sending over the wire
    const sanitizedSubmitValues = isMultiActivity ? omit(submitValues[idx], stepOneKeys) : submitValues[idx];
    const updatedActivity = omit(activity, omittedKeys);
    return {
      ...updatedActivity,
      ...sanitizedSubmitValues,
      // if we have a pharmacySequenceNumber on updatedActivity, use it.
      // if we have to pull it from sanitizedSubmitValues, we need to increment it for each activity we send
      ...(updatedActivity.pharmacySequenceNumber
        ? { pharmacySequenceNumber: updatedActivity.pharmacySequenceNumber }
        : sanitizedSubmitValues.pharmacySequenceNumber
        ? { pharmacySequenceNumber: sanitizedSubmitValues.pharmacySequenceNumber + idx }
        : {}),
      id: activity.id,
    };
  });
};
