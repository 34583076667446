import { PropsWithChildren, ReactElement } from 'react';
import { Field, FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Box, Fab, Grid, IconButton, FormLabel } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';

// Components
import { FormikInputField } from 'components/ContinuousImprovement/PureFormElements';
import DatePicker from 'components/DatePicker/DatePicker';

// Styles
import styles from 'components/TextboxRepeater/TextboxRepeater.module.scss';
import get from 'lodash/get';
import { IBCTRemsRegularExpression } from 'layouts/pages/bct/types';

// Types
type IProps = PropsWithChildren<{
  disabled?: boolean;
  formikKey: string;
}>;

export const RemsRegularExpressionsRepeater = (props: IProps): ReactElement => {
  const { formikKey, disabled } = props;
  const { setFieldTouched, values } = useFormikContext();
  const formikAccess: IBCTRemsRegularExpression[] = get(values, formikKey);

  return (
    <FieldArray
      name={formikKey}
      render={(arrayHelpers: FieldArrayRenderProps): ReactElement => {
        return (
          <Grid container>
            {formikAccess?.map(
              (_: IBCTRemsRegularExpression, idx: number): ReactElement => {
                return (
                  <Grid container spacing={3} key={idx}>
                    <Grid item xs={12} sm={2}>
                      <FormikInputField
                        label="Regex Label"
                        formikKey={`${formikKey}.${idx}.label`}
                        placeholder="Regex Label"
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormikInputField
                        formikKey={`${formikKey}.${idx}.regularExpression`}
                        label="Regular Expression"
                        placeholder="Regex"
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormLabel component="div">Start Date</FormLabel>
                      <Field name={`${formikKey}.${idx}.startDate`}>
                        {({ field }) => (
                          <DatePicker field={field} formikKey={`${formikKey}.${idx}.startDate`} timeOfDay="midnight" />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormLabel component="div">End Date</FormLabel>
                      <Field name={`${formikKey}.${idx}.endDate`}>
                        {({ field }) => (
                          <DatePicker field={field} formikKey={`${formikKey}.${idx}.endDate`} timeOfDay="endOfDay" />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormikInputField
                        formikKey={`${formikKey}.${idx}.errorMessage`}
                        label="Error Message"
                        placeholder="Error message"
                      />
                    </Grid>
                    <Box component={Grid} alignSelf="center" mt={3}>
                      <IconButton aria-label="remove" onClick={(): void => arrayHelpers.remove(idx)} size="small">
                        <ClearIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  </Grid>
                );
              },
            )}
            <Box display="flex" pt={2} pb={2}>
              <Box mr={2}>
                <Fab
                  aria-label="add another instance"
                  className={classNames(
                    {
                      [styles.disabled]: disabled,
                    },
                    styles['add-btn'],
                  )}
                  disabled={disabled}
                  onClick={(): void => {
                    arrayHelpers.push('');
                    setFieldTouched(formikKey, true);
                  }}
                  size="small"
                >
                  <AddIcon color="inherit" />
                </Fab>
              </Box>
              <Box pt={0.5}>Add Another Instance</Box>
            </Box>
          </Grid>
        );
      }}
    />
  );
};
