import { Action, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

// Types
import {
  USER_AUTH0_LOADING,
  USER_AUTH0_SUCCESS,
  GET_USER_CURRENT,
  GET_USER_CURRENT_FAILURE,
  GET_USER_CURRENT_SUCCESS,
  SET_CURRENT_ORGANIZATION,
  SET_DEFAULT_ORGANIZATION,
  SET_CURRENT_PROVIDER,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
} from './types';

// Core + Store
import { AppState } from 'store';
import { popToast } from 'store/toast/actions';
import { errorStickyToastOptions, errorToastOptions, successToastOptions } from 'store/toast/constants';
import { handleServerError } from 'globals/utils/handleServerError';
import { IAuth0User, IUser, IUserOrganization, OrganizationKinds, PARSAction, CreateUserRequest } from 'core/models';
import { CACHE_SELECTED_ORGANIZATION, CACHE_SELECTED_PROVIDER } from 'core/constants';

// Services.
import { CacheService } from 'services';
import { AnalyticsService } from 'services/AnalyticsService';
import { ProviderService } from 'services/ProviderService';
import { UserService } from 'services/UserService';
import { getRedirectUrl, getSite, getTargetSite } from 'utils/site';
import { LogService } from 'services/LogService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { TraceLevel } from 'core/enums';

// action creators
export const loadUserAction = (): Action => ({
  type: USER_AUTH0_LOADING,
});

export const userSuccessAction = (user: IAuth0User): AnyAction => ({
  payload: user,
  type: USER_AUTH0_SUCCESS,
});

export const getCurrentUserSuccessAction = (payload: IUser): AnyAction => ({
  payload,
  type: GET_USER_CURRENT_SUCCESS,
});

export const getCurrentUserFailureAction = (payload: Error): AnyAction => ({
  payload,
  type: GET_USER_CURRENT_FAILURE,
});

export const getCurrentUserAction = (): Action => ({
  type: GET_USER_CURRENT,
});

export const createUserAction = (request: CreateUserRequest): AnyAction => ({
  payload: request,
  type: CREATE_USER,
});

export const createUserSuccessAction = (user: IUser): AnyAction => ({
  payload: user,
  type: CREATE_USER_SUCCESS,
});

export const createUserFailureAction = (error: Error): AnyAction => ({
  payload: error,
  type: CREATE_USER_FAILURE,
});

export const setCurrentOrganizationAction = (payload: IUserOrganization): AnyAction => ({
  payload,
  type: SET_CURRENT_ORGANIZATION,
});

export const setDefaultOrganizationAction = (payload: IUserOrganization): AnyAction => ({
  payload,
  type: SET_DEFAULT_ORGANIZATION,
});

export const setCurrentProviderAction = (payload: IUserOrganization): AnyAction => ({
  payload,
  type: SET_CURRENT_PROVIDER,
});

// return value indicates whether there is a redirect in progress
export const getCurrentUser = (
  customerId?: string,
): ThunkAction<Promise<boolean>, AppState, null, PARSAction> => async (dispatch): Promise<boolean> => {
  await dispatch(getCurrentUserAction());
  let customErrorMessage: string;

  try {
    const site = getSite();
    const userService = new UserService();
    const user: IUser = await userService.getCurrentAsync();

    if (user?.id) {
      AnalyticsService.setUser(user.id, true);
    }

    if (user?.primaryUserOrganizations === undefined && user?.secondaryUserOrganizations === undefined) {
      customErrorMessage = 'Unexpected account error occurred (your organization is no longer active).';
      throw Error();
    }

    // if _all_ the organizations a user has are on the other site, send them there - this will send the current user to avoid getting stuck in a loop
    const primaryOrgsOnThisSite = user.primaryUserOrganizations.filter(
      (o) => getTargetSite(o.rollupOrganizationEnum) === site,
    );
    if (user.primaryUserOrganizations.length > 0 && primaryOrgsOnThisSite.length === 0) {
      const otherSite = getRedirectUrl(getTargetSite(user.primaryUserOrganizations[0].rollupOrganizationEnum));
      window.location.href = otherSite;
      return true;
    }

    /* 
    If the user has a default org then set defaultOrganization to that.
    If the primary org is ACCME or ANCC default to it.
    If the primary org is an accreditor or special org then default to it.
    Otherwise pick the first primary org available.
     */
    const defaultOrganization: IUserOrganization =
      primaryOrgsOnThisSite.find((o) => o.id === user.defaultOrganizationId) ??
      primaryOrgsOnThisSite.find((o) => o.businessId === 'ACCME') ??
      primaryOrgsOnThisSite.find((o) => o.businessId === 'ANCC') ??
      primaryOrgsOnThisSite.find((o) => o.organizationKind === OrganizationKinds.SPECIAL_ORGANIZATION) ??
      primaryOrgsOnThisSite.find((o) => o.organizationKind === OrganizationKinds.ACCREDITOR) ??
      primaryOrgsOnThisSite[0];

    if (defaultOrganization) {
      // Set defaultOrganization.
      dispatch(setDefaultOrganizationAction(defaultOrganization));

      // Initialize currentOrganization with defaultOrganization.
      let currentOrganization: IUserOrganization = defaultOrganization;
      let currentProvider: IUserOrganization = null;

      // Handle context passed from armature.
      if (customerId) {
        const armatureOrganization: IUserOrganization = user.primaryUserOrganizations.find(
          (o) => o.customerId === customerId,
        );
        if (armatureOrganization) {
          if (getTargetSite(armatureOrganization.rollupOrganizationEnum) !== site) {
            // we're on the wrong site - send the user to the correct one
            const otherSite = getRedirectUrl(
              getTargetSite(armatureOrganization.rollupOrganizationEnum),
              armatureOrganization.id,
            );
            window.location.href = otherSite;
            return true;
          }
          CacheService.set(CACHE_SELECTED_ORGANIZATION, armatureOrganization.id);
          CacheService.remove(CACHE_SELECTED_PROVIDER);
        }
      }

      // If user has a selected organization id, use that.
      const selectedOrganizationId = CacheService.get(CACHE_SELECTED_ORGANIZATION);
      if (selectedOrganizationId) {
        for (const organization of primaryOrgsOnThisSite) {
          if (organization.id === selectedOrganizationId) {
            currentOrganization = organization;
            break;
          }
        }
      }

      // If user has a selected provider id, use that.
      const selectedProviderId = CacheService.get(CACHE_SELECTED_PROVIDER);
      if (selectedProviderId) {
        for (const provider of user.secondaryUserOrganizations.filter(
          (i) => getTargetSite(i.rollupOrganizationEnum) === site,
        )) {
          if (provider.id === selectedProviderId) {
            currentProvider = provider;
            break;
          }
        }
      }

      // Depending on the available Organization/Provider Ids, set the Organization-Id/Provider-Id headers
      const providerService = new ProviderService();
      providerService.currentOrganizationId = currentOrganization?.id;

      // if you don't have a provider selected, we shouldn't send one, unless your selected organization *is* a provider
      providerService.currentProviderId =
        currentProvider?.id ??
        (currentOrganization?.organizationKind === OrganizationKinds.PROVIDER ? currentOrganization?.id : undefined);

      // If there's no current organization, the user likely has an invalid account
      if (!currentOrganization) {
        await dispatch(
          popToast({
            ...errorStickyToastOptions,
            message: <>Unexpected account error occurred (no org). Please logout to continue.</>,
          }),
        );
      }
      // Set currentOrganization.
      await dispatch(setCurrentOrganizationAction(currentOrganization));
      await dispatch(setCurrentProviderAction(currentProvider));

      // set current user's individual activity properties
      user.currentProvider = currentProvider;
      user.hasChildApproverOfActivities = (user?.primaryUserOrganizations || [])
        .concat(user?.secondaryUserOrganizations || [])
        .filter(({ id }) => currentOrganization?.childOrganizationIds?.includes(id))
        .some((i) => i.isApproverOfActivities);
    } else {
      await dispatch(
        popToast({
          ...errorStickyToastOptions,
          message: <>Unexpected account error occurred (no default org). Please logout to continue.</>,
        }),
      );
    }
    await dispatch(getCurrentUserSuccessAction(user));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getCurrentUser' });
    await dispatch(getCurrentUserFailureAction(error));
    await dispatch(
      popToast({
        ...errorStickyToastOptions,
        message: customErrorMessage ? (
          <>{customErrorMessage}</>
        ) : (
          <>Unexpected account error occurred. Please logout to continue. ({errorMessage})</>
        ),
      }),
    );

    if (customErrorMessage) {
      LogService.sendToAppInsights(
        {
          exception: { name: error.name, message: customErrorMessage, stack: error.stack },
          name: '',
          properties: {
            environmentErrorLocation: `Error occurs in ${process.env.NODE_ENV} environment.`,
            occurredOn: Date.now(),
            thunkName: '',
          },
          severityLevel: SeverityLevel.Error,
        },
        TraceLevel.Error,
      );
    }
  }
  return false;
};

export const createUser = (
  request: CreateUserRequest,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  dispatch(createUserAction(request));
  try {
    const userService = new UserService();
    const user: IUser = await userService.createUserAsync(request);
    dispatch(createUserSuccessAction(user));
    dispatch(popToast({ ...successToastOptions, message: <>Webservice account successfully created</> }));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'createUser' });
    dispatch(createUserFailureAction(error));
    dispatch(
      popToast({
        ...errorToastOptions,
        message: <>Unable to create Webservice Account, contact IT. ({errorMessage})</>,
      }),
    );
  }
};
