import { IUserOrganization, RollupOrganizationEnums } from 'core/models';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { OrgEnum } from 'core/enums';
import {
  currentOrganizationContextSelector,
  currentProviderContextSelector,
  rollupOrganizationEnumSelector,
  staffRollupOrganizationEnumsSelector,
  userProfileSelector,
} from 'store/user/selectors';

export interface IAccountManagerInfo {
  canEdit: boolean;
  currentUserId: string;
  organization: IUserOrganization | null;
  canEditAccreditation: boolean;
  isAccreditor: boolean;
  isPrimaryContact: boolean;
  isAnccOrganization: boolean;
  anccOrganization: IUserOrganization | null;
}
export const useAccountManagerInfo = (): IAccountManagerInfo => {
  const currentOrganization = useSelector(currentOrganizationContextSelector);
  const currentProvider = useSelector(currentProviderContextSelector);
  const staffRollupOrgs = useSelector(staffRollupOrganizationEnumsSelector);
  const rollupOrg = useSelector(rollupOrganizationEnumSelector);
  const isRollupOrgStaff = useMemo(() => staffRollupOrgs?.includes(rollupOrg), [rollupOrg, staffRollupOrgs]);
  const userProfile = useSelector(userProfileSelector);

  // lowest-level thing picked in org-switcher - this is what we are operating on
  const organization = currentProvider ?? currentOrganization;
  const organizationId = organization.id;
  const hasPrimaryOrg = userProfile.primaryUserOrganizations?.some((o) => o.id === organizationId);

  // we only have primary-contact data for _primary_ orgs, so get the value from there even if we also have rights at the accreditor and are currently looking at the provider as a secondary
  const isPrimaryContact = userProfile.primaryUserOrganizations?.some(
    (o) => o.id === organizationId && o.isPrimaryContact === true,
  );

  const anccOrganization = userProfile.primaryUserOrganizations?.find(
    (o) => o.rollupOrganizationEnum === RollupOrganizationEnums.NARS && o.businessId === OrgEnum.ANCC,
  );

  // we will only see accreditors if we are staff there or their rollup org, so if we see the target org in their list of child orgs, we can edit it
  const isAccreditorOrRollupOrgStaff = useMemo(
    () => userProfile.primaryUserOrganizations?.some((p) => p.childOrganizationIds.includes(organizationId)),
    [userProfile, organizationId],
  );

  return {
    // still need isRollupOrgStaff so ANCC can edit accreditors (since accreditor staff cannot)
    canEdit:
      hasPrimaryOrg ||
      isRollupOrgStaff ||
      isAccreditorOrRollupOrgStaff ||
      isPrimaryContact ||
      organization.organizationKind === 'Accreditor',
    currentUserId: userProfile.id,
    organization,
    canEditAccreditation: isRollupOrgStaff || isAccreditorOrRollupOrgStaff,
    isAccreditor: organization.organizationKind === 'Accreditor',
    isPrimaryContact: isPrimaryContact,
    isAnccOrganization: currentOrganization.businessId === OrgEnum.ANCC,
    anccOrganization: anccOrganization,
  };
};
