import { ReactElement } from 'react';
import { LearnerValidationToolForm } from '../forms/LearnerValidationToolForm';

export const LearnerValidationPage = (): ReactElement => {
  return (
    <section className="form-container">
      <LearnerValidationToolForm />
    </section>
  );
};
