import { useEffect, useMemo } from 'react';
import { BoardTypes, IBCTBoard } from 'layouts/pages/bct/types';
import { useDispatch, useSelector } from 'react-redux';
import { activityTemplateSelector } from 'store/formTemplate/selectors';
import { getActivityFormTemplate } from 'store/formTemplate/actions';
import { auth0UserSelector, organizationKindSelector } from 'store/user/selectors';

// Hook to filter loaded IBCTConfigs by a specific BoardType
export const useFilterHook = (boardType: BoardTypes, configuredBoards: IBCTBoard[]): IBCTBoard[] => {
  const configFilter = useMemo(() => ({ type }: IBCTBoard): boolean => type === boardType, [boardType]);
  const filterBoardConfigs = useMemo(() => configuredBoards?.filter(configFilter), [configFilter, configuredBoards]);
  return filterBoardConfigs;
};

export const useActivityTemplate = (): ReturnType<typeof activityTemplateSelector> => {
  const dispatch = useDispatch();
  const activityTemplate = useSelector(activityTemplateSelector);
  const auth0User = useSelector(auth0UserSelector);
  const currentOrganization = useSelector(organizationKindSelector);

  useEffect(() => {
    if (auth0User && currentOrganization) {
      dispatch(getActivityFormTemplate());
    }
  }, [auth0User, currentOrganization, dispatch]);

  return activityTemplate;
};
