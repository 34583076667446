import { AxiosResponse } from 'axios';
import { API } from 'core';
import { ServerRoute } from 'core/api';
import { ProviderFilesApiService } from 'core/providerFilesApiService';
import { IFileDownload, ManualDownloadLink } from 'core/models';

export class FileService {
  public static async download(controller: string, id: string): Promise<IFileDownload> {
    if (controller.startsWith('/')) {
      controller = controller.substring(1);
    }
    const route: ServerRoute = ServerRoute.forAction(controller, id);
    const result: AxiosResponse = await API.getFullResponse(route, undefined, { responseType: 'blob' });
    const disposition = result.headers['content-disposition'];
    const filename = disposition && /filename=(.*);/.exec(disposition)[1];

    return {
      disposition,
      file: result.data,
      filename,
      type: result.headers['content-type'],
    };
  }

  public static async downloadProviderFiles({ fileName, id }: ManualDownloadLink): Promise<IFileDownload> {
    const route: ProviderFilesApiService = new ProviderFilesApiService();
    const data = await route.getActivityContentsAsync(id);

    return {
      disposition: fileName,
      file: data,
      type: data.type,
    };
  }

  public static async downloadLearnerValidationProviderFiles({
    fileName,
    id,
  }: ManualDownloadLink): Promise<IFileDownload> {
    const route: ProviderFilesApiService = new ProviderFilesApiService();
    const data = await route.getLearnerValidationContentsAsync(id);

    return {
      disposition: fileName,
      file: data,
      type: data.type,
    };
  }

  /**
   * Retrieves a list of files in the specified folder.
   *
   * @param {string} controller - The controller route.
   * @param {string} folderName - The folder name.
   * @returns {Promise<Array<string>>} - A promise that resolves to an array of file names.
   */
  public static async getFileList(controller: string, folderName: string): Promise<Array<string>> {
    if (controller.startsWith('/')) {
      controller = controller.substring(1);
    }
    const route: ServerRoute = ServerRoute.forAction(controller, folderName);
    const result: AxiosResponse = await API.getFullResponse(route, undefined, { responseType: 'json' });
    return result.data;
  }

  /**
   * Retrieves a file located in a specified directory path.
   *
   * @param {string} controller - The controller route.
   * @param {string} directoryPath - The directory path.
   * @param {string} fileName - The name of the file.
   * @returns {Promise<Blob>} - A Promise that resolves with the retrieved file as a Blob object.
   */
  public static async getFileInDirectoryPath(
    controller: string,
    directoryPath: string,
    fileName: string,
  ): Promise<Blob> {
    if (controller.startsWith('/')) {
      controller = controller.substring(1);
    }
    const action = `${directoryPath}/${fileName}`;
    const route: ServerRoute = ServerRoute.forAction(controller, action);
    const result: AxiosResponse = await API.getFullResponse(route, undefined, { responseType: 'blob' });
    return result.data;
  }

  /**
   * Retrieves all files in a specified directory path as a zip.
   *
   * @param {string} controller - The controller route.
   * @param {string} directoryPath - The directory path.
   * @returns {Promise<Blob>} - A Promise that resolves with the retrieved zip as a Blob object.
   */
  public static async getFilesInDirectoryPathAsZip(controller: string, directoryPath: string): Promise<Blob> {
    if (controller.startsWith('/')) {
      controller = controller.substring(1);
    }
    const route: ServerRoute = ServerRoute.forAction(controller, directoryPath);
    const result: AxiosResponse = await API.getFullResponse(route, undefined, { responseType: 'arraybuffer' });
    return result.data;
  }
}
