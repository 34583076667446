import { ReactElement } from 'react';

// Components
import StaticModal from 'components/Modal';

export const PrepareUploadLearnersModal = (): ReactElement => {
  return (
    <>
      <h5>1&#41; Prepare your file for upload.</h5>
      <StaticModal
        buttonVariant="text"
        buttonText="Learn more about how to add or delete"
        titleText="Learn more about how to add and delete learners."
        actionVariant="done"
        actionTextPrimary="Done"
      >
        <ul>
          <li>
            Learners with "Add" specified in the file will get added if matching Learners are found in the system.
          </li>
          <li>
            Learners with "Delete" specified in the file will get updated if matching Learners are found in the system.
          </li>
          <li>**No action will be taken on any unmatched learners or learners with errors.</li>
        </ul>
      </StaticModal>
    </>
  );
};
