import { BaseApiService, IJsonPatchDocumentOperation } from './baseApiService';
import { IBoardSlim } from './models';
import { IBCTBoard } from 'layouts/pages/bct/types';

export class BoardsApiService extends BaseApiService {
  public async getAllBoardsAsync(): Promise<Array<IBoardSlim>> {
    return await super.getAsync('/api/Boards');
  }

  public async patchBoardAsync(id: string, patchRequest: IJsonPatchDocumentOperation[]): Promise<IBCTBoard> {
    return await super.patchAsync(`/api/Boards/${id}`, patchRequest);
  }

  public async putBoardAsync(id: string, value: Partial<IBCTBoard>): Promise<IBCTBoard> {
    return super.putAsync(`api/Boards/${id}`, value);
  }
}
