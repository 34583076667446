import { API } from 'core';
import { ServerRoute } from 'core/api';
import { ProviderSubmissionLog } from 'core/models';

export interface ProviderSubmissionLogsQuery {
  providerId: string;
  startDate: Date;
  endDate: Date;
  providerSubmissionType?: number;
}

export class ProviderSubmissionLogsService {
  public static async getData(params: ProviderSubmissionLogsQuery): Promise<ProviderSubmissionLog[]> {
    const route: ServerRoute = ServerRoute.forAction('ProviderSubmissionLogs', 'logs');
    return await API.post(route, params);
  }
}
