import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { noop } from 'lodash';

// Store + Core
import { openRail, closeRail } from 'store/rail/actions';
import { isRailOpenSelector } from 'store/rail/selectors';
import { boardSelector } from 'store/board/selectors';
import { getAllBoards } from 'store/board/actions';
import { learnerSearchResponseSelector } from 'store/learner/selectors';
import { searchResponseSelector } from 'store/activity/selectors';
import {
  ActivitySearchResultActivity,
  IActivityLearnerSearchResponse,
  IActivityLearnerSearchResult,
  IActivitySearchRequest,
  IActivitySearchResponse,
  IActivitySearchResult,
  IBoardSlim,
  ILearnerCreditDetails,
  ILearnerSearchActivity,
  ILearnerSearchRequest,
} from 'core/models';
import { getActivities, updateActivitySearchRequest } from 'store/activity/actions';
import {
  getLearnerActivities,
  getLearnerSearch,
  resetLearnerSearchResponse,
  updateLearnerSearch,
  updateLearnerSearchStateProps,
} from 'store/learner/actions';
import { INITIAL_LEARNER_SEARCH_FACETS } from 'components/ContinuousImprovement/Facets/LearnerSearchFacets/constants';

// Components
import Button from 'components/Button/Button';
import { CreditDetails } from 'components/Summary/CreditDetails';
import { LearnerDetail } from 'components/Summary/LearnerDetails';
import { LoadingCards } from 'components/LoadingCard';
import { RemsDetails } from 'components/Summary/RemsDetails';

// Misc + Util
import {
  splitLearnerDetailsByCertifyingBoards,
  splitLearnerDetailsByRems,
  splitLearnerDetailsByStateBoards,
  transformCreditsPerBoard,
  transformLearnerSearchResults,
} from './util';

// Styles
import styles from './detail.module.scss';

interface IParams {
  activityId?: string;
  completionId?: string;
  ulid?: string;
}

export const LearnerDetailPage = (): ReactElement => {
  const detailSectionClass = styles['learner-detail-section'];
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const isOpen: boolean = useSelector(isRailOpenSelector);
  const activitySearchResponse: IActivitySearchResponse = useSelector(searchResponseSelector);
  const activitySearchResults: IActivitySearchResult[] = activitySearchResponse?.results;
  const learnerSearchResponse: IActivityLearnerSearchResponse = useSelector(learnerSearchResponseSelector);
  const learnerSearchResults: IActivityLearnerSearchResult[] = learnerSearchResponse?.results;
  const specialtyBoards: IBoardSlim[] = useSelector(boardSelector);
  const { activityId, completionId, ulid } = useParams<IParams>();
  const history = useHistory();

  const loadLearnerData = useCallback(async () => {
    // Get the Learner Search Results
    const defaultDetailPageLearnerQuery: ILearnerSearchRequest = {
      ...INITIAL_LEARNER_SEARCH_FACETS,
      activityGuid: activityId,
      excludeFilters: true,
      top: undefined,
    };

    // If there is a ulid, construct the payload with the ulid, otherwise use the completionId
    const detailPageLearnerQuery: ILearnerSearchRequest = ulid
      ? { ...defaultDetailPageLearnerQuery, ulid }
      : { ...defaultDetailPageLearnerQuery, completionId };

    await dispatch(getLearnerSearch(detailPageLearnerQuery));
  }, [activityId, ulid, completionId, dispatch]);

  // Get the Activity Search Results
  const detailPageActivityQuery: IActivitySearchRequest = {
    excludeFilters: true,
    keys: [activityId],
    top: 1,
  };

  // Activity Metadata
  const activity: ActivitySearchResultActivity = activitySearchResults?.[0]?.document;
  const title = activity?.title;
  const id = activity?.id;
  const internalId = activity?.internalId;
  const creditClaimDate = activity?.creditClaimDate;

  const dateFormat = 'MM/DD/YYYY';

  const formattedClaimDate = moment(creditClaimDate).format(dateFormat);

  // Execute once on page load
  useEffect(() => {
    const getDetailPageData = async (): Promise<void> => {
      // Since this is triggered once on mount, no risk of infinite loop
      if (!specialtyBoards?.length) {
        dispatch(getAllBoards());
      }
      Promise.all([
        dispatch(updateActivitySearchRequest(detailPageActivityQuery)),
        dispatch(getActivities()),
        loadLearnerData(),
        dispatch(getLearnerActivities()),
      ]).then((): void => setIsLoading(false));
    };

    getDetailPageData().then(noop);

    return () => {
      dispatch(updateLearnerSearch(INITIAL_LEARNER_SEARCH_FACETS));
      dispatch(resetLearnerSearchResponse());
      dispatch(updateLearnerSearchStateProps({ page: 1 }));
    };
  }, []);

  const learnerDetails: ILearnerSearchActivity[] = transformLearnerSearchResults(learnerSearchResults);

  // not all records in learnerDetails may contain learnerName, learnerDOBMonth, or learnerDOBDay
  // therefore attempt to find these values by looping thru all records in learnerDetails
  const learnerName: string = learnerDetails?.find(({ learnerName }: ILearnerSearchActivity): string => learnerName)
    ?.learnerName;
  const { learnerDOBMonth, learnerDOBDay } =
    learnerDetails?.find(
      ({ learnerDOBMonth, learnerDOBDay }: ILearnerSearchActivity): number => learnerDOBMonth && learnerDOBDay,
    ) || {};

  const formattedDob: string = learnerDOBMonth && learnerDOBDay ? `${learnerDOBMonth}/${learnerDOBDay}` : 'N/A';
  const learnerDetailsByBoard: ILearnerSearchActivity[][] = splitLearnerDetailsByCertifyingBoards(
    learnerDetails,
    specialtyBoards,
  );
  const boardCreditDetails: ILearnerCreditDetails[] = transformCreditsPerBoard(learnerDetailsByBoard);
  const learnerDetailsByState: ILearnerSearchActivity[][] = splitLearnerDetailsByStateBoards(learnerDetails);
  const stateCreditDetails: ILearnerCreditDetails[] = transformCreditsPerBoard(learnerDetailsByState);
  const learnerDetailsByRems: ILearnerSearchActivity[] = splitLearnerDetailsByRems(learnerDetails);

  if (!isLoading && learnerDetails.length === 0) {
    return <Redirect to={activity?.key ? `/learners/summary/detail/${activity.key}` : '/learners/summary'} />;
  }

  return (
    <>
      {/* Begin Detail Page Heading */}
      <div className="form-container">
        {isLoading ? (
          <>
            <div className={classNames(['form-title-container', styles['learner-detail-title-container']])}>
              <div className={styles.title}>
                <div className="eyebrow">Learner Details</div>
              </div>
            </div>
            <LoadingCards count={3} />
          </>
        ) : (
          <>
            <div className={classNames(['form-title-container', styles['learner-detail-title-container']])}>
              <div className={styles.title}>
                <div className="eyebrow">Learner Details</div>
                <h4>
                  <div className={styles['learner-name']}>{learnerName}</div>
                  <div className={styles['learner-dob']}>
                    <span>
                      Date of Birth: <time dateTime={formattedDob}>{formattedDob}</time>
                    </span>
                    <Button className="btn-actions" onClick={() => dispatch(openRail())}>
                      Credits
                    </Button>
                  </div>
                </h4>
                <div className={styles['activity-title']}>{title}</div>
                <div className={styles['activity-meta']}>
                  <span>Activity ID {id}</span>
                  <span>Internal ID {internalId}</span>
                  {!!creditClaimDate && (
                    <span>
                      Credit Claim Date: <time dateTime={formattedClaimDate}>{formattedClaimDate}</time>
                    </span>
                  )}
                </div>
              </div>
            </div>
            {/* End Detail Page Heading */}
            {/* Begin Detail Section */}
            <section className={detailSectionClass}>
              {!!learnerDetailsByState?.length &&
                learnerDetailsByState?.map((learnerRecords: ILearnerSearchActivity[], idx: number) => (
                  <LearnerDetail learnerDetails={learnerRecords} key={idx} refreshLearnerData={loadLearnerData} />
                ))}
              {!!learnerDetailsByBoard?.length &&
                learnerDetailsByBoard?.map((learnerRecords: ILearnerSearchActivity[], idx: number) => (
                  <LearnerDetail learnerDetails={learnerRecords} key={idx} refreshLearnerData={loadLearnerData} />
                ))}
              {!!learnerDetailsByRems?.length &&
                learnerDetailsByRems?.map((activity: ILearnerSearchActivity, idx: number) => {
                  return <RemsDetails remsDetails={activity} key={idx} refreshLearnerData={loadLearnerData} />;
                })}
            </section>

            {/* End Detail Section */}
            <div className="button-row">
              <div className="cta-container">
                <Button className="primary" title="Return to previous page" onClick={() => history.goBack()}>
                  Done
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      {/* Side content */}
      <aside className={classNames('rail-container rail-container--75', { open: isOpen })}>
        <CreditDetails
          creditDetails={[...stateCreditDetails, ...boardCreditDetails]}
          handleClose={() => dispatch(closeRail())}
        />
      </aside>
      {/* End Side content */}
    </>
  );
};
