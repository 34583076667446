import { ReactElement, useEffect, useMemo } from 'react';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormLabel, Grid, RadioGroup } from '@material-ui/core';
import get from 'lodash/get';
import classNames from 'classnames';

// Components
import ButtonRow from 'layouts/pages/activity/forms/buttonRow';
import { LoadingCards } from 'components/LoadingCard';
import { RadioTable } from './RadioTable';
import { FormikBooleanRadioField } from 'components/ContinuousImprovement/PureFormElements';
import { CutoffDateRepeater } from '../../components';
import { RegularExpressionsRepeater } from './RegularExpressionsRepeater';

// Styles
import pageStyles from '../../BCTPageStyles.module.scss';
import formStyles from '../FormStyles.module.scss';

// Core + Store
import { getBoardById, postBCTStep, setBCTStep } from 'store/bct/actions';
import { currentBoardSelector, isLoadingBCTSelector, validationExceptionsSelector } from 'store/bct/selectors';
import { IBCTLearnerDate, IValidationException } from 'core/models';

// Utils
import { initialLearnerCompletionDetails } from './initialLearnerCompletionDetails';
import { handleValidationException } from 'globals/utils/handleValidationException';

// Types
import {
  BoardLearnerCollectionFields,
  BoardTypes,
  CollectionTypes,
  IBCTBoard,
  IBCTBoardLearnerCollectionField,
  IBCTRegularExpression,
  IBCTStepProps,
} from '../../types';

export const LearnerCompletionDetailsForm = (props: IBCTStepProps): ReactElement => {
  const { prevStep } = props;
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();

  // Selectors.
  const currentBoard: IBCTBoard = useSelector(currentBoardSelector);
  const isLoading: boolean = useSelector(isLoadingBCTSelector);
  const validationExceptions: IValidationException[] = useSelector(validationExceptionsSelector);

  // Fetch.
  useEffect(() => {
    dispatch(getBoardById(id));
  }, [dispatch, id]);

  const isCertifying: boolean = currentBoard?.type === BoardTypes.CERTIFYING_BOARD;

  // Form setup.
  const formik = useFormik({
    initialValues: initialLearnerCompletionDetails(currentBoard),
    onSubmit: async (values: FormikValues): Promise<void> => {
      await dispatch(postBCTStep({ nextStep: null, values: { ...currentBoard, ...values } }));

      // Scroll to top when done.
      window.scroll(0, 0);
    },
  });
  const { handleSubmit, isValid, isSubmitting, resetForm, setFieldError, values } = formik;

  const fields: IBCTBoardLearnerCollectionField[] = useMemo(() => get(values, 'boardLearnerCollectionFields', []), [
    values,
  ]);
  const boardLearnerIdRegularExpressions: IBCTRegularExpression[] = useMemo(
    () => get(values, 'boardLearnerIdRegularExpressions', []),
    [values],
  );
  const boardLastLearnerCompletionDates: IBCTLearnerDate[] = useMemo(
    () => get(values, 'boardLastLearnerCompletionDates', []),
    [values],
  );
  const boardLastLearnerCompletionRemovalDates: IBCTLearnerDate[] = useMemo(
    () => get(values, 'boardLastLearnerCompletionRemovalDates', []),
    [values],
  );

  useEffect(() => {
    // Set field specific errors.
    validationExceptions?.forEach((validationException: IValidationException): void => {
      const [formikKey, message] = handleValidationException(validationException);

      setFieldError(formikKey, message);
    });
  }, [setFieldError, validationExceptions]);

  // Show this while data is loading.
  if (isLoading) return <LoadingCards />;

  const handleBackClick = (): void => {
    resetForm();
    dispatch(setBCTStep(prevStep));
    window.scroll(0, 0);
  };

  // Is learner ID `Required` or `Optional`?
  const isLearnerIDNeeded = !!fields.find(
    ({ fieldType, collectionType }): boolean =>
      (fieldType === BoardLearnerCollectionFields.LICENSE_ID || fieldType === BoardLearnerCollectionFields.BOARD_ID) &&
      (collectionType === CollectionTypes.REQUIRED || collectionType === CollectionTypes.OPTIONAL),
  );

  // We should not be able to submit if any years are empty.
  const findYear = ({ year }: IBCTLearnerDate): boolean => !year;
  const hasEmptyBoardLastLearnerCompletionDates = !!boardLastLearnerCompletionDates.some(findYear);
  const hasEmptyBoardLastLearnerCompletionRemovalDates = !!boardLastLearnerCompletionRemovalDates.some(findYear);
  const hasEmptyYears: boolean =
    hasEmptyBoardLastLearnerCompletionDates || hasEmptyBoardLastLearnerCompletionRemovalDates;

  // Check for empty regex fields.
  const hasEmptyRegularExpressions: boolean = boardLearnerIdRegularExpressions.some(
    ({ errorMessage, regularExpression }: IBCTRegularExpression): boolean => !errorMessage || !regularExpression,
  );

  // Disabled the submit button when any of these are true.
  const isDisabled: boolean = hasEmptyRegularExpressions || hasEmptyYears;

  return (
    <>
      <h2 className={pageStyles.subtitle}>Enter the details for reporting learner completions</h2>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <div className={formStyles['form-card']}>
            <fieldset className={classNames({ [formStyles['no-divider']]: !isLearnerIDNeeded })}>
              <legend>Which learner completion data will be required or optional?</legend>
              <RadioTable formikKey="boardLearnerCollectionFields" />
            </fieldset>
            {/*********************/}
            {isLearnerIDNeeded && (
              <fieldset className={classNames({ [formStyles['no-divider']]: isLearnerIDNeeded })}>
                <legend>Enter the Learner ID validation rules.</legend>
                <RegularExpressionsRepeater formikKey="boardLearnerIdRegularExpressions" />
              </fieldset>
            )}
          </div>
          <div className={formStyles['form-card']}>
            {isCertifying && (
              <>
                <fieldset>
                  <legend>Do you want to allow learner completion dates after credit claim date?</legend>
                  <RadioGroup
                    aria-label="Do you want to allow learner completion dates after credit claim date?"
                    name="allowLearnerCompletionDatesAfterCreditClaimDate"
                  >
                    <Grid container spacing={2}>
                      <Grid item>
                        <FormikBooleanRadioField
                          formikKey="allowLearnerCompletionDatesAfterCreditClaimDate"
                          name="Yes"
                          value
                        />
                      </Grid>
                      <Grid item>
                        <FormikBooleanRadioField
                          formikKey="allowLearnerCompletionDatesAfterCreditClaimDate"
                          name="No"
                          value={false}
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </fieldset>
              </>
            )}
            {/*********************/}
            <fieldset>
              <legend>What is the last date to allow learner completions annually?</legend>
              <CutoffDateRepeater formikKey="boardLastLearnerCompletionDates" />
            </fieldset>
            {/*********************/}
            <fieldset className={formStyles['no-divider']}>
              <FormLabel component="div">Last Edit Date</FormLabel>
              <CutoffDateRepeater formikKey="boardLastLearnerCompletionRemovalDates" />
            </fieldset>
          </div>
          <ButtonRow
            backLabel="Back"
            ctaLabel="Save and Continue"
            isCtaDisabled={isDisabled}
            isSubmitting={isSubmitting}
            isValid={isValid}
            onBackClick={handleBackClick}
            onClick={handleSubmit}
            showBack
          />
        </form>
      </FormikProvider>
    </>
  );
};
