// Libraries
import { get, isEqual, keys, last, omit } from 'lodash';

// Core
import {
  ActivitySearchResultActivity,
  ICreditTypes,
  IDictionary,
  IFormInputOption,
  ILearnerCompletion,
  ILearnerCredits,
  LearnerCreditsBoardTypeEnum,
  IEditCompletionFormModel,
} from 'core/models';
import { CreditRouteKeys } from 'core/constants';
import { ITypeaheadOption } from 'components/ContinuousImprovement/Typeahead';
import { IBCTBoard, IBCTCreditType } from 'layouts/pages/bct/types';

export interface ICreditsTransformPayload {
  activity: ActivitySearchResultActivity;
  boardConfig: IBCTBoard;
  boardType: LearnerCreditsBoardTypeEnum;
  completion: ILearnerCompletion;
  route: string;
}

/**
 * @function getCreditsOptions
 * @param ICreditsTransformPayload
 *  activity: The activity record from search results
 *  boardConfig: BCT Config for the board the completion belongs to
 *  boardType: One of creditsByCertifyingBoard, creditsByRems, creditsByStateOrProvince
 *  completion: The completion record
 *  route: The route for a completion record from search results
 *
 * @returns IFormInputOption[] - Options for the form control to use for rendering credits
 */
export const getCreditsOptions = ({
  activity,
  boardConfig,
  boardType,
  completion,
  route,
}: ICreditsTransformPayload): IFormInputOption[] => {
  // Abort if data is missing
  if (!activity || !boardConfig || !boardType || !completion || !route) {
    return;
  }

  const lowerRoute: string = route.toLowerCase();
  const completionBodyId: string = last(route.split('/'));

  const isMocBoard: boolean = lowerRoute.includes(CreditRouteKeys.CreditsByCertifyingBoard);
  const isStateBoard: boolean = lowerRoute.includes(CreditRouteKeys.CreditsByStateOrProvince);
  const isRemsCollab: boolean = lowerRoute.includes(CreditRouteKeys.CreditsByRems);

  const learnerCredits: IDictionary<ILearnerCredits> =
    completion?.[boardType]?.[completionBodyId]?.learnerCredits || {};

  // Abort if data is missing
  if (!completionBodyId || !learnerCredits || (!isMocBoard && !isStateBoard && !isRemsCollab)) {
    return;
  }

  // Configured credits for the completion body
  const creditTypes: IBCTCreditType[] = boardConfig?.creditTypes;

  // Get the registered credits for this activity
  const registeredCreditTypes: ICreditTypes[] = activity.creditTypes;
  const registeredMocCreditTypes: ICreditTypes[] = activity.moCCreditTypes;

  // Determine the taxonomy to use for credits on the form
  const editableCreditTerms: ICreditTypes[] = isMocBoard
    ? registeredMocCreditTypes
    : isStateBoard
    ? registeredCreditTypes
    : [];

  // An empty list means REMS, which doesn't have credits, so abort
  if (!editableCreditTerms?.length) {
    return;
  }

  // Convert the taxonomy for credits into IFormInputOption
  const outputCredits: IFormInputOption[] = editableCreditTerms
    .filter(({ boardId }) => boardConfig?.id === boardId)
    .map(
      (creditTerm: ICreditTypes): IFormInputOption => ({
        Id: creditTerm.creditTypeId,
        Title: creditTerm.name,
        Watermark: '0.00',
        isDisabled: creditTypes?.find(({ id }) => id === creditTerm.creditTypeId)?.default,
      }),
    );
  return outputCredits;
};

/**
 * @function getIsFormModified
 *
 * @param formValues - IEditCompletionFormModel
 * @param initialValues - IEditCompletionFormModel
 *
 * @description Convenience function to check if anything on the completion has been changed
 *
 * @returns boolean
 */
export const getIsFormModified = (
  formValues: IEditCompletionFormModel,
  initialValues: IEditCompletionFormModel,
): boolean => !isEqual(formValues, initialValues);

/**
 * @function convertBoardCreditsForPatch
 *
 * @param formValues - IEditCompletionFormModel
 * @param creditsKey - string - deep path to learnerCredits
 *
 * @description Convert the credits model used by the edit form to a shape comparable to ILearnerCredits
 *
 * @returns IDictionary<ILearnerCredits>
 */
export const convertBoardCreditsForPatch = (
  formValues: IEditCompletionFormModel,
  creditsKey: string,
): IDictionary<ILearnerCredits> => {
  const formCredits: IDictionary<ILearnerCredits> = get(formValues, creditsKey);
  if (!formCredits) {
    return;
  }
  const creditKeys: string[] = keys(formCredits);
  const patchCredits: IDictionary<ILearnerCredits> = {};
  creditKeys.forEach(
    (creditKey) =>
      (patchCredits[creditKey] = {
        credits: formCredits[creditKey]?.credits,
        providerCreditId: creditKey,
        selected: formCredits[creditKey]?.selected || false,
      }),
  );
  return patchCredits;
};

/**
 * @function convertTypeaheadForPatch
 *
 * @param formValues - IEditCompletionFormModel
 * @param typeaheadKey - string - deep path to typeahead selection
 *
 * @description Convenience function for getting id of a specific typeahead (i.e. practiceStateOrProvince)
 * @returns - string
 */
export const convertTypeaheadForPatch = (formValues: IEditCompletionFormModel, typeaheadKey: string): string => {
  const typeaheadOption: ITypeaheadOption = get(formValues, typeaheadKey);
  return typeaheadOption?.id;
};

/**
 * @function omitCompletionFields
 *
 * @param completion - ILearnerCompletion
 * @param boardKey - string - base path for the completion's board/collab
 *
 * @returns Partial<ILearnerCompletion>
 */
export const omitCompletionFields = (completion: ILearnerCompletion, boardKey: string): Partial<ILearnerCompletion> => {
  const baseOmitKeys = [
    'dateOfBirthDay',
    'dateOfBirthMonth',
    'email',
    'firstName',
    'id',
    'lastName',
    'medicalSchoolId',
    'medicalSchoolGraduationYear',
    'mobileNumber',
    'npi',
    'printableLearnerId',
    'universalLearnerId',
    'updatedDate',
  ];
  const deepOmitKeys = [
    `${boardKey}.createdDate`,
    `${boardKey}.id`,
    `${boardKey}.licenseId`,
    `${boardKey}.rejectionReason`,
    `${boardKey}.transferStatus`,
    `${boardKey}.updatedDate`,
  ];
  return omit(completion, [...baseOmitKeys, ...deepOmitKeys]);
};
