import { ThunkAction } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import moment from 'moment';
import { ActivityService } from 'services/ActivityService';
import {
  BATCH_ACTIVITY,
  BATCH_ACTIVITY_SUCCESS,
  BATCH_ACTIVITY_FAILURE,
  BATCH_ACTIVITY_PROGRESS,
  CREATE_ACTIVITIES,
  CREATE_ACTIVITIES_FAILURE,
  CREATE_ACTIVITIES_SUCCESS,
  GET_ACTIVITY_TYPES,
  GET_ACTIVITY_TYPES_SUCCESS,
  GET_ACTIVITY_TYPES_FAILURE,
  PATCH_ACTIVITY,
  PATCH_ACTIVITY_FAILURE,
  PATCH_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITIES,
  UPDATE_ACTIVITIES_FAILURE,
  UPDATE_ACTIVITIES_SUCCESS,
  QUEUE_MOC_ACTIVITY,
  GET_ACTIVITIES,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_FAILURE,
  GET_ACTIVITY,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_FAILURE,
  UPDATE_ACTIVITY_SEARCH_REQUEST,
  UPDATE_ACTIVITY_SEARCH_STATE_PROPS,
  EXPORT_ACTIVITIES,
  DOWNLOAD_ACTIVITIES,
  DOWNLOAD_ACTIVITIES_SUCCESS,
  DOWNLOAD_ACTIVITIES_FAILURE,
  BULK_DELETE_ACTIVITIES,
  BULK_DELETE_ACTIVITIES_SUCCESS,
  BULK_DELETE_ACTIVITIES_FAILURE,
  CLEAR_ACTIVITY_DETAIL,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAILURE,
  BATCH_ACTIVITY_CLEAR,
  SET_ACTIVITY_EDIT_INDEX,
  UNDELETE_ACTIVITIES_FAILURE,
  UNDELETE_ACTIVITIES,
  UNDELETE_ACTIVITIES_SUCCESS,
  CLEAR_CURRENT_ACTIVITIES,
  RESET_ACTIVITIES_SEARCH_RESPONSE,
  DELETE_LEARNER_ACTIVITY,
  DELETE_LEARNER_ACTIVITY_SUCCESS,
  DELETE_LEARNER_ACTIVITY_FAILURE,
  TOGGLE_CLOSE_ACTIVITY,
  TOGGLE_CLOSE_ACTIVITY_SUCCESS,
  TOGGLE_CLOSE_ACTIVITY_FAILURE,
  GET_NEXT_SEQUENCE_NUMBER,
  GET_NEXT_SEQUENCE_NUMBER_SUCCESS,
  GET_NEXT_SEQUENCE_NUMBER_FAILURE,
  GET_ACTIVITY_REVIEW_HISTORY,
  GET_ACTIVITY_REVIEW_HISTORY_SUCCESS,
  GET_ACTIVITY_REVIEW_HISTORY_FAILURE,
  SELECT_ACTIVITY_FOR_REVIEW,
  SELECT_ACTIVITY_FOR_REVIEW_FAILURE,
  SELECT_ACTIVITY_FOR_REVIEW_SUCCESS,
  UPDATE_ACTIVITIES_PAGINATION_STATE,
  RESET_ACTIVITIES_SEARCH_REQUEST,
  RESET_ACTIVITIES_SEARCH_STATE_PROPS,
  RESET_ACTIVITY_TYPES,
  ACCEPT_ACTIVITY,
  ADMIN_DELETE_ACTIVITY,
} from './types';
import { AppState, store } from 'store';
import {
  Activity,
  ActivityType,
  CreateActivityRequest,
  IArrayBufferDownload,
  IActivitySearchRequest,
  IActivitySearchResponse,
  IActivitySearchStateProps,
  IBatchActivityRequest,
  IJsonPatchDocumentOperation,
  ILearnerSearchActivity,
  PARSAction,
  UpdateActivityRequest,
  IProcessActivityRequest,
  INextSequenceNumber,
  IActivityReviewHistory,
  IActivityReviewHistoryRequest,
  IActivityReviewOutcomeRequest,
  IQueueMocActivityRequest,
  IAdminDeleteActivityRequest,
} from 'core/models';
import { ServerErrorCodes, StatusEnum } from 'core/enums';
import { popToast } from 'store/toast/actions';
import {
  errorToastOptions,
  errorStickyToastOptions,
  infoToastOptions,
  successToastOptions,
  warningToastOptions,
} from 'store/toast/constants';
import { closeModal, openModal } from 'store/modal/actions';
import { getLearnerSearch } from '../learner/actions';
import { handleServerError } from 'globals/utils/handleServerError';
import {
  DEFAULT_RESULTS_PER_PAGE,
  MESSAGE_DEFAULT_FILE_HAS_TOO_MANY_ROWS_TO_PROCESS,
  MESSAGE_DEFAULT_UPLOAD_COMPLETE,
  TAGS_ENUM,
  TAXONOMY_BATCH_MESSAGES_ID,
} from 'core/constants';
import { TaxonomyService } from 'services/TaxonomyService';
import { filterTaxonomyByRollupOrganizationEnum } from 'globals/utils/filterTaxonomyByOrg';

// action creators
export const createActivitiesAction = (requests: CreateActivityRequest[]): AnyAction => ({
  payload: requests,
  type: CREATE_ACTIVITIES,
});

export const createActivitiesSuccessAction = (activities: Activity[]): AnyAction => ({
  payload: activities,
  type: CREATE_ACTIVITIES_SUCCESS,
});

export const clearActivityDetail = (): Action => ({
  type: CLEAR_ACTIVITY_DETAIL,
});

export const createActivitiesFailureAction = (errorMessage: string): AnyAction => ({
  payload: errorMessage,
  type: CREATE_ACTIVITIES_FAILURE,
});

export const batchActivityAction = (file: { file: File }): AnyAction => ({
  payload: file,
  type: BATCH_ACTIVITY,
});

export const batchActivityProgressAction = (percentage: number): AnyAction => ({
  payload: percentage,
  type: BATCH_ACTIVITY_PROGRESS,
});

export const batchActivitySuccessAction = (): Action => ({
  type: BATCH_ACTIVITY_SUCCESS,
});

export const batchActivityClearAction = (): Action => ({
  type: BATCH_ACTIVITY_CLEAR,
});

export const batchActivityFailureAction = (errorMessage: string): AnyAction => ({
  error: true,
  payload: errorMessage,
  type: BATCH_ACTIVITY_FAILURE,
});

export const getActivityTypesAction = (): Action => ({
  type: GET_ACTIVITY_TYPES,
});

export const getActivityTypesSuccessAction = (activityTypes: ActivityType[]): AnyAction => ({
  payload: activityTypes,
  type: GET_ACTIVITY_TYPES_SUCCESS,
});

export const getActivityTypesFailureAction = (errorMessage: string): AnyAction => ({
  payload: errorMessage,
  type: GET_ACTIVITY_TYPES_FAILURE,
});

export const patchActivityAction = (id: string, request: IJsonPatchDocumentOperation[]): AnyAction => ({
  payload: { id, request },
  type: PATCH_ACTIVITY,
});

export const patchActivitySuccessAction = (): AnyAction => ({
  payload: undefined,
  type: PATCH_ACTIVITY_SUCCESS,
});

export const patchActivityFailureAction = (error: Error): AnyAction => ({
  payload: error,
  type: PATCH_ACTIVITY_FAILURE,
});

export const updateActivitiesAction = (request: UpdateActivityRequest[]): AnyAction => ({
  payload: request,
  type: UPDATE_ACTIVITIES,
});

export const updateActivitiesSuccessAction = (activities: Activity[]): AnyAction => ({
  payload: activities,
  type: UPDATE_ACTIVITIES_SUCCESS,
});

export const updateActivitiesFailureAction = (errorMessage: string): AnyAction => ({
  payload: errorMessage,
  type: UPDATE_ACTIVITIES_FAILURE,
});

export const queueMocActivityAction = (request: IQueueMocActivityRequest): AnyAction => ({
  payload: request,
  type: QUEUE_MOC_ACTIVITY,
});

export const adminDeleteActivityAction = (request: IAdminDeleteActivityRequest): AnyAction => ({
  payload: request,
  type: ADMIN_DELETE_ACTIVITY,
});

export const getActivitiesAction = (request: IActivitySearchRequest): AnyAction => ({
  payload: request,
  type: GET_ACTIVITIES,
});

export const getActivitiesSuccessAction = (activities: IActivitySearchResponse): AnyAction => ({
  payload: activities,
  type: GET_ACTIVITIES_SUCCESS,
});

export const getActivitiesFailureAction = (error: Error): AnyAction => ({
  payload: error,
  type: GET_ACTIVITIES_FAILURE,
});

export const resetActivitiesSearchResponseAction = (): Action => ({
  type: RESET_ACTIVITIES_SEARCH_RESPONSE,
});

export const resetActivitiesSearchRequestAction = (): Action => ({
  type: RESET_ACTIVITIES_SEARCH_REQUEST,
});

export const resetActivitiesSearchStatePropsAction = (): Action => ({
  type: RESET_ACTIVITIES_SEARCH_STATE_PROPS,
});

export const getActivityAction = (id: string): AnyAction => ({
  payload: id,
  type: GET_ACTIVITY,
});

export const getActivitySuccessAction = (activity: Activity): AnyAction => ({
  payload: activity,
  type: GET_ACTIVITY_SUCCESS,
});

export const getActivityFailureAction = (error: Error): AnyAction => ({
  payload: error,
  type: GET_ACTIVITY_FAILURE,
});

export const processActivityAction = (request: IProcessActivityRequest): AnyAction => ({
  payload: request,
  type: ACCEPT_ACTIVITY,
});

export const updateActivitySearchRequestAction = (searchRequest: IActivitySearchRequest): AnyAction => ({
  payload: searchRequest,
  type: UPDATE_ACTIVITY_SEARCH_REQUEST,
});

export const updateSearchStatePropsAction = (props: IActivitySearchStateProps): AnyAction => ({
  payload: props,
  type: UPDATE_ACTIVITY_SEARCH_STATE_PROPS,
});

export const updateActivitiesPaginationState = (payload: IActivitySearchStateProps): AnyAction => ({
  payload,
  type: UPDATE_ACTIVITIES_PAGINATION_STATE,
});

export const exportActivitiesAction = (): Action => ({
  type: EXPORT_ACTIVITIES,
});

export const downloadActivitiesAction = (id: string): AnyAction => ({
  payload: id,
  type: DOWNLOAD_ACTIVITIES,
});

export const downloadActivitiesSuccessAction = (payload: IArrayBufferDownload): AnyAction => ({
  payload: payload,
  type: DOWNLOAD_ACTIVITIES_SUCCESS,
});

export const downloadActivitiesFailureAction = (error: Error): AnyAction => ({
  payload: error,
  type: DOWNLOAD_ACTIVITIES_FAILURE,
});

export const bulkDeleteActivitiesAction = (payload: string[]): AnyAction => ({
  payload: payload,
  type: BULK_DELETE_ACTIVITIES,
});

export const bulkDeleteActivitiesSuccessAction = (): Action => ({
  type: BULK_DELETE_ACTIVITIES_SUCCESS,
});
export const bulkDeleteActivitiesFailureAction = (error: Error): AnyAction => ({
  payload: error,
  type: BULK_DELETE_ACTIVITIES_FAILURE,
});

export const deleteActivityAction = (payload: string): AnyAction => ({
  payload,
  type: DELETE_ACTIVITY,
});

export const deleteActivitySuccessAction = (): Action => ({ type: DELETE_ACTIVITY_SUCCESS });

export const deleteActivityFailureAction = (error: Error): AnyAction => ({
  payload: error,
  type: DELETE_ACTIVITY_FAILURE,
});

export const deleteLearnerCompletionAction = (payload: ILearnerSearchActivity): AnyAction => ({
  payload,
  type: DELETE_LEARNER_ACTIVITY,
});

export const deleteLearnerCompletionSuccess = (): Action => ({
  type: DELETE_LEARNER_ACTIVITY_SUCCESS,
});

export const deleteLearnerCompletionFailure = (errorMessage: string): AnyAction => ({
  payload: errorMessage,
  type: DELETE_LEARNER_ACTIVITY_FAILURE,
});

export const clearCurrentActivities = (): Action => ({
  type: CLEAR_CURRENT_ACTIVITIES,
});

export const toggleCloseActivityAction = (): Action => ({
  type: TOGGLE_CLOSE_ACTIVITY,
});

export const toggleCloseActivitySuccessAction = (): Action => ({
  type: TOGGLE_CLOSE_ACTIVITY_SUCCESS,
});

export const toggleCloseActivityFailureAction = (errorMessage: string): AnyAction => ({
  payload: errorMessage,
  type: TOGGLE_CLOSE_ACTIVITY_FAILURE,
});

export const setActivityEditIndexAction = (index: number): AnyAction => {
  return {
    payload: index,
    type: SET_ACTIVITY_EDIT_INDEX,
  };
};

export const undeleteActivityAction = (payload: string[]): AnyAction => ({
  payload,
  type: UNDELETE_ACTIVITIES,
});

export const undeleteActivitySuccessAction = (): Action => ({ type: UNDELETE_ACTIVITIES_SUCCESS });

export const undeleteActivityFailureAction = (errorMessage: string): AnyAction => ({
  payload: errorMessage,
  type: UNDELETE_ACTIVITIES_FAILURE,
});

export const getNextSequenceNumberAction = (): PARSAction<void> => ({
  type: GET_NEXT_SEQUENCE_NUMBER,
});

export const getNextSequenceNumberSuccessAction = (payload: number): PARSAction<number> => ({
  payload,
  type: GET_NEXT_SEQUENCE_NUMBER_SUCCESS,
});

export const getNextSequenceNumberFailureAction = (payload: string): PARSAction<string> => ({
  payload,
  type: GET_NEXT_SEQUENCE_NUMBER_FAILURE,
});

export const selectActivityForReviewAction = (): PARSAction<void, typeof SELECT_ACTIVITY_FOR_REVIEW> => ({
  type: SELECT_ACTIVITY_FOR_REVIEW,
});

export const selectActivityForReviewSuccessAction = (): PARSAction<
  void,
  typeof SELECT_ACTIVITY_FOR_REVIEW_SUCCESS
> => ({
  type: SELECT_ACTIVITY_FOR_REVIEW_SUCCESS,
});

export const selectActivityForReviewFailureAction = (): PARSAction<
  void,
  typeof SELECT_ACTIVITY_FOR_REVIEW_FAILURE
> => ({
  type: SELECT_ACTIVITY_FOR_REVIEW_FAILURE,
});

export const editActivityReviewOutcomeAction = (): PARSAction<void, typeof SELECT_ACTIVITY_FOR_REVIEW> => ({
  type: SELECT_ACTIVITY_FOR_REVIEW,
});

export const editActivityReviewOutcomeSuccessAction = (): PARSAction<
  void,
  typeof SELECT_ACTIVITY_FOR_REVIEW_SUCCESS
> => ({
  type: SELECT_ACTIVITY_FOR_REVIEW_SUCCESS,
});

export const editActivityReviewOutcomeFailureAction = (): PARSAction<
  void,
  typeof SELECT_ACTIVITY_FOR_REVIEW_FAILURE
> => ({
  type: SELECT_ACTIVITY_FOR_REVIEW_FAILURE,
});

export const getActivityReviewHistoryAction = (): PARSAction<void> => ({
  type: GET_ACTIVITY_REVIEW_HISTORY,
});

export const getActivityReviewHistorySuccessAction = (
  payload: IActivityReviewHistory[],
): PARSAction<IActivityReviewHistory[]> => ({
  payload,
  type: GET_ACTIVITY_REVIEW_HISTORY_SUCCESS,
});

export const getActivityReviewHistoryFailureAction = (): PARSAction<void> => ({
  type: GET_ACTIVITY_REVIEW_HISTORY_FAILURE,
});

export const resetActivityTypes = (): PARSAction<void> => ({
  type: RESET_ACTIVITY_TYPES,
});

// thunk actions
export const createActivities = (
  requests: CreateActivityRequest[],
): ThunkAction<Promise<Activity[] | null>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(createActivitiesAction(requests));
  const saveMessage = requests.length === 1 ? 'Activity' : 'Activities';
  try {
    const activities: Activity[] = await ActivityService.create(requests);
    await dispatch(createActivitiesSuccessAction(activities));
    dispatch(popToast({ ...successToastOptions, message: <>{`${saveMessage} Draft Started`}</> }));
    return activities;
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'createActivities' });
    await dispatch(createActivitiesFailureAction(error));
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
    return null;
  }
};

export const updateActivities = (
  request: UpdateActivityRequest[],
): ThunkAction<Promise<boolean>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(updateActivitiesAction(request));
  const saveMessage = request.length === 1 ? 'Activity' : 'Activities';
  const updatedActivities: Activity[] = [];
  return await Promise.all(
    request.map(async (activity: UpdateActivityRequest) => {
      const updatedActivity = await ActivityService.update(activity);
      updatedActivities.push(updatedActivity);
    }),
  )
    .then(() => {
      dispatch(updateActivitiesSuccessAction(updatedActivities));
      dispatch(popToast({ ...successToastOptions, message: <>{`${saveMessage} Saved`}</> }));
      return true;
    })
    .catch((error) => {
      const { errorMessage } = handleServerError({ error, thunkName: 'updateActivities' });
      dispatch(updateActivitiesFailureAction(errorMessage));
      dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
      return false;
    });
};

export const batchActivity = ({
  file,
  shouldForce,
  rollupOrganizationEnum,
}: IBatchActivityRequest): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  // Null check.
  if (!file) {
    return dispatch(popToast({ ...errorToastOptions, message: <>No file found, please try again.</> }));
  }
  // Tell the user we started the upload.
  dispatch(popToast({ ...infoToastOptions, message: <>Uploading...</> }));

  const batchMessages = await new TaxonomyService().getByIdAsync(TAXONOMY_BATCH_MESSAGES_ID);
  const terms = filterTaxonomyByRollupOrganizationEnum(batchMessages?.terms, rollupOrganizationEnum);

  // Start the upload.
  await dispatch(batchActivityAction(file));
  try {
    await ActivityService.batch(file, shouldForce);
    // Tell the user that the file successfully uploaded and clear the state.
    dispatch(batchActivitySuccessAction());
    const successMessage =
      terms?.filter((i) => i.tag === TAGS_ENUM.BATCH__MESSAGES__AFTER_UPLOAD)[0]?.description ||
      MESSAGE_DEFAULT_UPLOAD_COMPLETE;
    dispatch(popToast({ ...infoToastOptions, autoHideDuration: undefined, message: <>{successMessage}</> }));

    // Close the modal when done.
    dispatch(closeModal());
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'batchActivity' });
    console.warn('batch learner upload error: ', errorMessage);
    // Warn the user that a duplicate file was detected - 409 server error.
    if (error?.response?.data?.errorCode === ServerErrorCodes.CONFLICT__PROVIDER_FILE) {
      dispatch(batchActivityFailureAction(ServerErrorCodes.CONFLICT__PROVIDER_FILE));
      dispatch(popToast({ ...warningToastOptions, message: <>We have detected a duplicate file.</> }));
      // Open the modal for them.
      dispatch(openModal());
    } else if (error?.response?.data?.errorCode === ServerErrorCodes.BAD_REQUEST__FILE__RECORD_COUNT__INVALID) {
      dispatch(batchActivityFailureAction(ServerErrorCodes.BAD_REQUEST__FILE__RECORD_COUNT__INVALID));
      const fileTooBigMessage =
        terms?.filter((i) => i.tag === TAGS_ENUM.BATCH__MESSAGES__FILE_TOO_BIG)[0]?.description ||
        MESSAGE_DEFAULT_FILE_HAS_TOO_MANY_ROWS_TO_PROCESS;
      dispatch(popToast({ ...errorStickyToastOptions, message: <>{fileTooBigMessage}</> }));
    } else {
      dispatch(batchActivityFailureAction(errorMessage));
      dispatch(
        popToast({
          ...errorToastOptions,
          message: <>{errorMessage ?? 'Something went wrong. Please try again later.'}</>,
        }),
      );
    }
  }
};

export const deleteLearnerCompletion = ({
  activityGuid,
  completionId,
  completionSource,
  completionIdentifier,
}: ILearnerSearchActivity): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(deleteLearnerCompletionAction({ activityGuid, completionId, completionSource }));
  try {
    await ActivityService.deleteLearnerCompletion({
      activityGuid,
      completionId,
      completionSource,
      completionIdentifier,
    });
    await dispatch(deleteLearnerCompletionSuccess());
    await dispatch(popToast({ ...successToastOptions, message: <>Successfully deleted</> }));
    await dispatch(getLearnerSearch());
  } catch (error) {
    const { errorMessage } = handleServerError({
      error,
      fallbackMessage: 'Could not delete learner completions.',
      thunkName: 'deleteLearnerCompletion',
    });
    await dispatch(deleteLearnerCompletionFailure(errorMessage));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const getActivityTypes = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(getActivityTypesAction());
  try {
    const activityTypes = await ActivityService.getTypes();
    await dispatch(getActivityTypesSuccessAction(activityTypes));
  } catch (error) {
    const { errorMessage } = handleServerError({
      error,
      fallbackMessage: 'Could not get activity types.',
      thunkName: 'getActivityTypes',
    });
    await dispatch(getActivityTypesFailureAction(errorMessage));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const getActivities = (
  payload?: IActivitySearchRequest,
  shouldResetPageReset = true,
  shouldSkipToastMessaging = false,
  replacePayload = false,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  const { searchRequest } = store.getState().activitiesState;

  let newSearchRequest: IActivitySearchRequest;

  if (replacePayload) {
    newSearchRequest = { top: 10, ...payload };
  } else {
    newSearchRequest = { ...searchRequest, ...payload };
  }

  // Always reset to page 1 except from an explicit pagination call.
  if (shouldResetPageReset) {
    await dispatch(onUpdateActivitiesPaginationState({ ...newSearchRequest, page: 1 }, replacePayload));
    return;
  }

  dispatch(getActivitiesAction(newSearchRequest));
  if (!shouldSkipToastMessaging) {
    dispatch(popToast({ ...infoToastOptions, message: <>Searching...</> }));
  }

  try {
    const activities = await ActivityService.get(newSearchRequest);
    dispatch(getActivitiesSuccessAction(activities));
    if (!shouldSkipToastMessaging)
      dispatch(popToast({ ...successToastOptions, message: <>Activity search updated</> }));
  } catch (error) {
    const { errorMessage } = handleServerError({
      error,
      fallbackMessage: 'Could not get activities.',
      thunkName: 'getActivities',
    });
    dispatch(getActivitiesFailureAction(error));
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const onUpdateActivitiesPaginationState = (
  props: IActivitySearchStateProps,
  replacePayload = false,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch, getState) => {
  const { searchRequest } = getState().activitiesState;

  let activitySearchStateProps: IActivitySearchStateProps;
  let skip: number;

  if (replacePayload) {
    activitySearchStateProps = { ...props };
    skip = DEFAULT_RESULTS_PER_PAGE;
  } else {
    activitySearchStateProps = { ...searchRequest, ...props };
    skip = searchRequest.top || DEFAULT_RESULTS_PER_PAGE;
  }

  dispatch(updateActivitiesPaginationState(activitySearchStateProps));

  await dispatch(
    getActivities(
      {
        ...props,
        skip: skip * (props.page - 1),
      },
      false,
      false,
      replacePayload,
    ),
  );
};

export const getActivity = (
  id: string,
): ThunkAction<Promise<void>, AppState, { appRoute: string }, PARSAction> => async (dispatch) => {
  await dispatch(getActivityAction(id));
  try {
    const activity = await ActivityService.getById(id);
    dispatch(getActivitySuccessAction(activity));
  } catch (error) {
    const { errorMessage } = handleServerError({
      error,
      fallbackMessage: 'Could not get activity.',
      thunkName: 'getActivity',
    });
    await dispatch(getActivityFailureAction(error));
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const updateActivitySearchRequest = (
  searchRequest: IActivitySearchRequest,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(updateActivitySearchRequestAction(searchRequest));
};

export const updateSearchStateProps = (
  props: IActivitySearchStateProps,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(updateSearchStatePropsAction(props));
};

export const exportActivities = (
  payload: IActivitySearchRequest,
): ThunkAction<void, AppState, null, PARSAction> => async (dispatch) => {
  dispatch(exportActivitiesAction());
  dispatch(
    popToast({
      ...infoToastOptions,
      message: <>Preparing an export.</>,
    }),
  );
  try {
    await ActivityService.export(payload);
    dispatch(
      popToast({
        ...successToastOptions,
        message: <>An email will be sent to you with a link to download your exported activities.</>,
      }),
    );
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'exportActivities' });
    dispatch(
      popToast({
        ...errorToastOptions,
        message: <>{errorMessage}</>,
      }),
    );
  }
};

export const downloadActivities = (id: string): ThunkAction<void, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(downloadActivitiesAction(id));
  await dispatch(popToast({ ...infoToastOptions, message: <>Downloading export...</> }));
  try {
    const downloadedFile = await ActivityService.download(id);
    await dispatch(downloadActivitiesSuccessAction(downloadedFile));
    popToast({ ...successToastOptions, message: <>Successfully downloaded</> });
  } catch (error) {
    handleServerError({ error, thunkName: 'downloadActivities' });
    await dispatch(downloadActivitiesFailureAction(error));
    await dispatch(
      popToast({
        ...errorToastOptions,
        message: <>This report was generated by a different user and is not accessible.</>,
      }),
    );
  }
};

export const bulkDeleteActivities = (
  payload: string[],
): ThunkAction<Promise<boolean>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(bulkDeleteActivitiesAction(payload));
  try {
    await ActivityService.bulkDelete(payload);
    await dispatch(bulkDeleteActivitiesSuccessAction());
    return true;
  } catch (error) {
    const { errorMessage } = handleServerError({
      error,
      fallbackMessage: 'Could not delete these activities.',
      thunkName: 'bulkDeleteActivities',
    });
    await dispatch(bulkDeleteActivitiesFailureAction(error));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
    return false;
  }
};

export const deleteActivity = (payload: string): ThunkAction<Promise<boolean>, AppState, null, PARSAction> => async (
  dispatch,
) => {
  await dispatch(deleteActivityAction(payload));
  try {
    await ActivityService.delete(payload);
    await dispatch(deleteActivitySuccessAction());
    return true;
  } catch (error) {
    const { errorMessage } = handleServerError({
      error,
      fallbackMessage: 'Could not delete this activity.',
      thunkName: 'deleteActivity',
    });
    await dispatch(deleteActivityFailureAction(error));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
    return false;
  }
};

export const toggleCloseActivityDetail = (payload: string[]): ThunkAction<void, AppState, null, PARSAction> => async (
  dispatch,
  getState,
) => {
  const { id, status } = getState().activitiesState.activityDetail;

  await dispatch(toggleCloseActivityAction());
  try {
    const errorString = (action: string): string =>
      `Cannot ${action} this activity. Please make adjustments and try again.`;
    if (status === StatusEnum.READY_TO_CLOSE) {
      dispatch(popToast({ ...infoToastOptions, message: <>Closing this activity.</> }));
      const response: string[] = await ActivityService.close(payload);

      // TLDR; [] = success ["some-id"] = failure.
      // The BE doesn't throw errors when it fails to close an activity.
      // It returns a 200 with a response of string[] (failing activity guids).
      // We don't receive any reason as to why something failed but if the BE cannot close the activities,
      // the server returns the list of failed ids.

      if (response.length) {
        dispatch(toggleCloseActivityFailureAction(errorString('close')));
        dispatch(popToast({ ...errorToastOptions, message: <>{errorString('close')}</> }));
        return new Error(errorString('close'));
      }

      await dispatch(toggleCloseActivitySuccessAction());

      await dispatch(popToast({ ...successToastOptions, message: <>Activity marked as Closed.</> }));

      // Refetch to update the activity detail.
      await dispatch(getActivity(id));
    } else if (status === StatusEnum.CLOSED) {
      dispatch(popToast({ ...infoToastOptions, message: <>Re-opening this activity.</> }));
      const response: string[] = await ActivityService.unclose(payload);

      // TLDR; [] = success ["some-id"] = failure.
      // The BE doesn't throw errors when it fails to close an activity.
      // It returns a 200 with a response of string[] (failing activity guids).
      // We don't receive any reason as to why something failed but if the BE cannot close the activities,
      // the server returns the list of failed ids.

      if (response.length) {
        await dispatch(toggleCloseActivityFailureAction(errorString('unclose')));
        dispatch(popToast({ ...errorToastOptions, message: <>{errorString('unclose')}</> }));
        return;
      }

      // Otherwise, we have successfully closed the activities.
      await dispatch(toggleCloseActivitySuccessAction());
      await dispatch(popToast({ ...successToastOptions, message: <>Activity marked as Ready for Close.</> }));

      // Refetch to update the activity detail.
      await dispatch(getActivity(id));
    }
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'toggleCloseActivityDetail' });
    await dispatch(toggleCloseActivityFailureAction(errorMessage));
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

// This is for bulk closing.
export const toggleCloseActivity = (payload: {
  activityIds: string[];
  status: StatusEnum.READY_TO_CLOSE | StatusEnum.CLOSED;
}): ThunkAction<Promise<string[]>, AppState, null, PARSAction> => async (dispatch) => {
  const { activityIds, status } = payload;
  const errorString = (action: string): string =>
    `Cannot ${action} these activities. Please make adjustments to the activities and try again.`;
  const activityLabel = `${activityIds.length} ${activityIds.length !== 1 ? 'Activities' : 'Activity'}`;

  await dispatch(toggleCloseActivityAction());

  status === StatusEnum.READY_TO_CLOSE
    ? dispatch(popToast({ ...infoToastOptions, message: <>{`Closing ${activityLabel}.`}</> }))
    : dispatch(popToast({ ...infoToastOptions, message: <>{`Unclosing ${activityLabel}.`}</> }));

  try {
    if (status === StatusEnum.READY_TO_CLOSE) {
      const response: string[] = await ActivityService.close(activityIds);

      // TLDR; [] = success ["some-id"] = failure.
      // The BE doesn't throw errors when it fails to close an activity.
      // It returns a 200 with a response of string[] (failing activity guids).
      // We don't receive any reason as to why something failed but if the BE cannot close the activities,
      // the server returns the list of failed ids.
      if (response.length) {
        dispatch(toggleCloseActivityFailureAction(errorString('close')));
        dispatch(popToast({ ...errorToastOptions, message: <>{errorString('close')}</> }));
        return response;
      }

      await dispatch(toggleCloseActivitySuccessAction());

      await dispatch(
        popToast({
          ...successToastOptions,
          message: <>{`${activityLabel} marked as Closed.`}</>,
        }),
      );

      // Refetch all of the activities.
      await dispatch(getActivities());

      return [];
    } else if (status === StatusEnum.CLOSED) {
      const response: string[] = await ActivityService.unclose(activityIds);

      // TLDR; [] = success ["some-id"] = failure.
      // The BE doesn't throw errors when it fails to close an activity.
      // It returns a 200 with a response of string[] (failing activity guids).
      // We don't receive any reason as to why something failed but if the BE cannot close the activities,
      // the server returns the list of failed ids.

      if (response.length) {
        await dispatch(toggleCloseActivityFailureAction(errorString('unclose')));
        dispatch(popToast({ ...errorToastOptions, message: <>{errorString('unclose')}</> }));
        return response;
      }

      // Otherwise, we have successfully closed the activities.
      await dispatch(toggleCloseActivitySuccessAction());
      await dispatch(
        popToast({ ...successToastOptions, message: <>{`${activityLabel} marked as Ready for Close.`}</> }),
      );

      // Refetch all of the activities.
      await dispatch(getActivities());

      return [];
    }
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'toggleCloseActivity' });
    await dispatch(toggleCloseActivityFailureAction(errorMessage));
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
    return activityIds; // if we failed completely, we failed to close/reopen anything
  }
};

export const undeleteActivities = (payload: string[]): ThunkAction<void, AppState, null, PARSAction> => async (
  dispatch,
) => {
  // Tell the user we are starting to restore their activity.
  await dispatch(popToast({ ...infoToastOptions, message: <>Restoring...</> }));
  await dispatch(undeleteActivityAction(payload));
  try {
    await ActivityService.undelete(payload);
    await dispatch(undeleteActivitySuccessAction());
    await dispatch(popToast({ ...successToastOptions, message: <>Selected activities were restored.</> }));

    // Refetch the deleted activities.
    // TODO: Refactor. Should be an object instead.
    await dispatch(getActivities(null, null, true));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'undeleteActivities' });
    // Failure.
    await dispatch(undeleteActivityFailureAction(errorMessage));

    // Tell the user there was an error.
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const patchActivity = (
  id: string,
  request: IJsonPatchDocumentOperation[],
  successCallback?: () => void,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(patchActivityAction(id, request));
  await dispatch(
    popToast({
      ...infoToastOptions,
      message: <>Saving activity...</>,
    }),
  );
  try {
    await ActivityService.patch(id, request);
    await dispatch(patchActivitySuccessAction());
    await dispatch(clearActivityDetail());
    await dispatch(getActivity(id)); // Refresh the activity after a patch
    successCallback?.(); // On success, trigger callback
    await dispatch(
      popToast({
        ...successToastOptions,
        message: <>Activity successfully updated</>,
      }),
    );
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'patchActivity' });
    await dispatch(patchActivityFailureAction(error));
    await dispatch(
      popToast({
        ...errorToastOptions,
        message: <>{errorMessage}</>,
      }),
    );
  }
};

export const getNextSequenceNumber = (
  activities: Activity[],
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(getNextSequenceNumberAction());
  try {
    const year = activities?.[0]?.statusYear ?? moment().year();
    const response: INextSequenceNumber = await ActivityService.getNextSequenceNumber(year);
    await dispatch(getNextSequenceNumberSuccessAction(response?.nextSequenceNumber));
  } catch (error) {
    handleServerError({ error, thunkName: 'getNextSequenceNumber' });
    await dispatch(patchActivityFailureAction(error));
    // No toast to prevent excess toasting. This can be a silent request/response
  }
};

export const selectActivityForReview = (
  review: IActivityReviewHistoryRequest,
): ThunkAction<Promise<boolean>, AppState, null, PARSAction> => async (dispatch): Promise<boolean> => {
  dispatch(selectActivityForReviewAction());
  try {
    await ActivityService.selectActivityForReview(review);
    dispatch(selectActivityForReviewSuccessAction());
    dispatch(popToast({ ...successToastOptions, message: <>Activity selected for review.</> }));
    return true;
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'selectActivityForReview' });
    dispatch(selectActivityForReviewFailureAction());
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
    return false;
  }
};

export const queueMocActivity = (
  request: IQueueMocActivityRequest,
): ThunkAction<Promise<unknown | boolean>, AppState, null, PARSAction> => async (
  dispatch,
): Promise<unknown | boolean> => {
  await dispatch(queueMocActivityAction(request));
  try {
    const response = await ActivityService.queueMocActivity(request);
    if (response?.error?.errorCode) {
      await dispatch(popToast({ ...errorToastOptions, message: <>{response?.error?.message}</> }));
      return false;
    } else {
      await dispatch(
        popToast({
          ...successToastOptions,
          message: <>MOC Activity queued - {response?.recordsAffected ?? 0} records affected</>,
        }),
      );
      return true;
    }
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'queueMocActivity' });
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
    return false;
  }
};

export const adminDeleteActivity = (
  request: IAdminDeleteActivityRequest,
): ThunkAction<Promise<unknown | boolean>, AppState, null, PARSAction> => async (
  dispatch,
): Promise<unknown | boolean> => {
  await dispatch(adminDeleteActivityAction(request));
  try {
    const response = await ActivityService.adminDeleteActivity(request);
    if (response?.error?.errorCode) {
      await dispatch(popToast({ ...errorToastOptions, message: <>{response?.error?.message}</> }));
      return false;
    } else {
      await dispatch(
        popToast({
          ...successToastOptions,
          message: <>Activity Deleted - {response?.recordsAffected ?? 0} records affected</>,
        }),
      );
      return true;
    }
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'adminDeleteActivity' });
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
    return false;
  }
};

export const deselectActivityForReview = (
  activityId: string,
  reviewId: string,
): ThunkAction<Promise<boolean>, AppState, null, PARSAction> => async (dispatch): Promise<boolean> => {
  try {
    await ActivityService.deselectActivityForReview(activityId, reviewId);
    dispatch(popToast({ ...successToastOptions, message: <>Activity deselected for review.</> }));
    return true;
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'deselectActivityForReview' });
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
    return false;
  }
};

export const editActivityReviewOutcome = (
  id: string,
  entryId: string,
  outcome: IActivityReviewOutcomeRequest,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch): Promise<void> => {
  dispatch(editActivityReviewOutcomeAction());
  try {
    await ActivityService.editActivityReviewOutcome(id, entryId, outcome);
    dispatch(editActivityReviewOutcomeSuccessAction());
    dispatch(popToast({ ...successToastOptions, message: <>Review Outcomes Updated</> }));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'editActivityReviewOutcome' });
    dispatch(editActivityReviewOutcomeFailureAction());
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const getActivityReviewHistory = (id: string): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
): Promise<void> => {
  dispatch(getActivityReviewHistoryAction());

  try {
    const reviewHistory: IActivityReviewHistory[] = await ActivityService.getActivityReviewHistory(id);
    dispatch(getActivityReviewHistorySuccessAction(reviewHistory));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getActivityReviewHistory' });
    dispatch(getActivityReviewHistoryFailureAction());
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const processActivity = (
  request: IProcessActivityRequest,
): ThunkAction<Promise<unknown | boolean>, AppState, null, PARSAction> => async (
  dispatch,
): Promise<unknown | boolean> => {
  await dispatch(processActivityAction(request));
  try {
    const response = await ActivityService.processActivity(request);
    if (console.table) {
      for (const set of response?.recordSets || []) {
        console.table(set);
      }
    }
    if (response?.error?.errorCode) {
      await dispatch(popToast({ ...errorToastOptions, message: <>{response?.error?.message}</> }));
      return false;
    } else {
      await dispatch(
        popToast({
          ...successToastOptions,
          message: <>Activity processed - {response?.recordsAffected ?? 0} records affected</>,
        }),
      );
      return true;
    }
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'processActivity' });
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
    return false;
  }
};
