import * as PARS from '../../core';
import {
  GET_ACTIVITY_FORM_TEMPLATE,
  GET_ACTIVITY_FORM_TEMPLATE_SUCCESS,
  GET_ACTIVITY_FORM_TEMPLATE_FAILURE,
  SET_ACTIVITY_FORM_VALIDATION_OBJECT,
} from './types';
import { AppState } from 'store';
import { ThunkAction } from 'redux-thunk';
import { FormTemplateService } from '../../services/FormTemplateService';
import { IFormSection } from 'core/models';
import { handleServerError } from '../../globals/utils/handleServerError';

export const getActivityFormTemplateAction = () => {
  return {
    type: GET_ACTIVITY_FORM_TEMPLATE,
  };
};

export const getActivityFormTemplateSuccessAction = (payload: Array<IFormSection>) => {
  return {
    type: GET_ACTIVITY_FORM_TEMPLATE_SUCCESS,
    payload,
  };
};

export const getActivityFormTemplateFailureAction = (error: Error) => {
  return {
    type: GET_ACTIVITY_FORM_TEMPLATE_FAILURE,
    payload: error,
  };
};

export const setActivityFormValidationObjectAction = (payload: Array<IFormSection>) => {
  return {
    type: SET_ACTIVITY_FORM_VALIDATION_OBJECT,
    payload,
  };
};

export const getActivityFormTemplate = (): ThunkAction<Promise<void>, AppState, null, PARS.Models.PARSAction> => async (
  dispatch,
) => {
  await dispatch(getActivityFormTemplateAction());
  try {
    const formTemplateService = new FormTemplateService();
    const template = await formTemplateService.getActivityTemplate();
    await dispatch(getActivityFormTemplateSuccessAction(template));
  } catch (error) {
    handleServerError({ error, thunkName: 'getActivityFormTemplate' });
    await dispatch(getActivityFormTemplateFailureAction(error));
  }
};
