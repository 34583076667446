import { ReactElement } from 'react';

// Components.
import { DashboardSection } from '../DashboardSection';

// Styles.
import styles from './DashboardMetricSection.module.scss';

// Types.
import { IDashboardMetricSection } from 'store/dashboard/types';

export const DashboardMetricSection = (props: IDashboardMetricSection): ReactElement => {
  const { description, label, toggleable, value } = props;

  return (
    <DashboardSection className={styles.section} label={label} toggleable={toggleable}>
      {value && <strong className={styles.value}>{value}</strong>}
      {description && <p className={styles.description}>{description}</p>}
    </DashboardSection>
  );
};
