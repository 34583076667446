import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import { useMediaQuery } from '@material-ui/core';
// Components
import { FacetsAccordion, FiltersSelected } from 'components/ContinuousImprovement/Facets/components';
import Button from 'components/Button/Button';
import { FormikInputFieldWithSearch } from '../../FormikInputFieldWithSearch';

// Styles
import styles from '../Facets.module.scss';

// Core + Store
import { Facet, IActivitySearchRequest } from 'core/models';
import { IInitialSearchFacet } from '../types';
import { searchLeanerFacetsSelector, searchLeanerResultsLoadingSelector } from 'store/learner/selectors';
import { LEARNER_ACCORDION_TITLES } from 'core/constants';
import { getLearnerActivities, updateLearnerActivitiesSearch } from 'store/learner/actions';
import { isRailOpenSelector } from 'store/rail/selectors';

// Types
import { BreakpointsEnum, ButtonVariant } from 'core/enums';
import { INITIAL_LEARNER_ACTIVITY_SEARCH_FACETS } from './constants';
interface IProps {
  initialValues: IInitialSearchFacet;
  searchRequest?: IActivitySearchRequest;
  handleClose(): void;
}

export const FacetSearch = (props: IProps): ReactElement => {
  const { handleClose, initialValues, searchRequest } = props;
  const dispatch = useDispatch();
  const isLgBreakpoint = useMediaQuery(`(max-width:${BreakpointsEnum.lg})`);

  // Selectors.
  const facets: Facet = useSelector(searchLeanerFacetsSelector);
  const isLoading: boolean = useSelector(searchLeanerResultsLoadingSelector);
  const isRailOpen: boolean = useSelector(isRailOpenSelector);

  const formik = useFormik({
    initialValues,
    // API call.
    onSubmit: async (values: FormikValues): Promise<void> => {
      window.scroll(0, 0);
      await dispatch(updateLearnerActivitiesSearch(values));
      await dispatch(getLearnerActivities());
      handleClose();
    },
  });

  const { handleReset, handleSubmit, setValues, values } = formik;

  const onClearAll = async (e): Promise<void> => {
    window.scroll(0, 0);
    await handleReset(e);
    await setValues(INITIAL_LEARNER_ACTIVITY_SEARCH_FACETS);
    await dispatch(updateLearnerActivitiesSearch(INITIAL_LEARNER_ACTIVITY_SEARCH_FACETS));
    await dispatch(getLearnerActivities({}));
  };

  const isDisabled = !facets && isLoading;

  return isLgBreakpoint && !isRailOpen ? null : (
    <FormikProvider value={formik}>
      <form className={styles['summary-refiners']} onSubmit={handleSubmit}>
        {isLgBreakpoint && (
          <Button aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </Button>
        )}
        <FormikInputFieldWithSearch formikKey="searchText" placeholder="Search Activity" title="Search activity" />
        <Button
          disabled={isDisabled}
          fullWidth
          variant={ButtonVariant.Primary}
          type="submit"
          className={styles['margin-top']}
        >
          Apply selected filter(s)
        </Button>
        {/* Filter pills */}
        <div className={styles.filters}>
          <div className={styles['filters-header']}>
            Filter By:
            <Button className={styles['link link--hyperlink']} disabled={isDisabled} onClick={onClearAll}>
              Clear All
            </Button>
          </div>

          {/* Facets */}
          <div className={styles['filters-selected']}>
            {values && (
              <div className={styles['filters-selected']}>
                <FiltersSelected defaultFacets={LEARNER_ACCORDION_TITLES} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.refiners}>
          <FacetsAccordion defaultFacets={LEARNER_ACCORDION_TITLES} facets={facets} searchRequest={searchRequest} />
        </div>
        <Button disabled={isDisabled} fullWidth variant={ButtonVariant.Primary} type="submit">
          Apply selected filter(s)
        </Button>
      </form>
    </FormikProvider>
  );
};
