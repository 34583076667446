import { Reducer } from 'redux';
import {
  DOWNLOAD_SYNONYM_FILE,
  DOWNLOAD_SYNONYM_FILE_FAILURE,
  DOWNLOAD_SYNONYM_FILE_SUCCESS,
  UPLOAD_SYNONYM_FILE,
  CLEAR_UPLOADED_SYNONYM_FILE,
  UPLOAD_SYNONYM_FILE_PROGRESS,
  UPLOAD_SYNONYM_FILE_FAILURE,
  UPLOAD_SYNONYM_FILE_SUCCESS,
  ISearchSynonymState,
  SynonymFileActionTypes,
} from './types';

const initialState: ISearchSynonymState = {
  error: null,
  isDownloading: false,
  isSuccess: false,
};

const searchSynonymnReducer: Reducer<ISearchSynonymState, SynonymFileActionTypes> = (
  state = initialState,
  action: SynonymFileActionTypes,
): ISearchSynonymState => {
  switch (action.type) {
    case DOWNLOAD_SYNONYM_FILE:
      return {
        ...state,
        isDownloading: true,
      };
    case DOWNLOAD_SYNONYM_FILE_SUCCESS:
      return {
        ...state,
        isDownloading: false,
        isSuccess: true,
      };
    case DOWNLOAD_SYNONYM_FILE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isDownloading: false,
        isSuccess: false,
      };
    case UPLOAD_SYNONYM_FILE:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_UPLOADED_SYNONYM_FILE:
      return {
        ...state,
        file: undefined,
        progressPercentage: undefined,
      };
    case UPLOAD_SYNONYM_FILE_PROGRESS:
      return {
        ...state,
        progressPercentage: action.payload,
      };
    case UPLOAD_SYNONYM_FILE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPLOAD_SYNONYM_FILE_SUCCESS:
      return {
        ...state,
        progressPercentage: 100,
      };
    default:
      return state;
  }
};

export default searchSynonymnReducer;
