import { ReactElement, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
// @ts-ignore: This package isn't typescript-friendly
import { saveAs } from 'filesaver.js-npm';

// Store
import { learnerSearchDownloadFileSelector } from 'store/learner/selectors';
import { downloadLearnerSearch } from 'store/learner/actions';

export const DownloadLearnerSearchFile = (): ReactElement => {
  const { isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search);
  const learnerSearchDownloadFile = useSelector(learnerSearchDownloadFileSelector);

  useEffect(() => {
    // Check query string for download activities flag
    const downloadGuid = query.get('downloadLearnerSearch');
    if (isAuthenticated && downloadGuid) {
      dispatch(downloadLearnerSearch(downloadGuid));
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (!learnerSearchDownloadFile) return;
    const { file, type } = learnerSearchDownloadFile;
    if (file) {
      // Convert csv string to blob
      const blob = new Blob([new Uint8Array(file, 0, file.byteLength)], { type });
      saveAs(blob, 'Learner Search Export.csv');
    }
  }, [learnerSearchDownloadFile]);

  return null;
};
