import { KeyboardBackspace } from '@material-ui/icons';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Components.
import { ReportGroup } from './ReportGroup';

// Hooks.
import { useLoadEvent } from 'hooks';
import { getReportsByWorkspaceId, getSmsSummaryReportAuthorization } from 'store/report/actions';
import {
  isLoadingReportsSelector,
  isSmsSummaryReportAuthorizedSelector,
  reportsSelector,
} from 'store/report/selectors';
import { ReportGroups } from 'store/report/types';
import { currentOrganizationContextSelector } from 'store/user/selectors';

// Styles.
import styles from './ReportPage.module.scss';
import { Grid } from '@material-ui/core';
import { RollupOrganizationEnums } from '../../../core/models';

export const ReportPage = (): ReactElement => {
  const dispatch = useDispatch();
  const currentOrganization = useSelector(currentOrganizationContextSelector);
  const isLoadingReports = useSelector(isLoadingReportsSelector);
  const reports = useSelector(reportsSelector);
  const isSmsSummaryReportAuthorized = useSelector(isSmsSummaryReportAuthorizedSelector);
  const reportGroups = useMemo(() => groupBy(reports, (item) => get(item, 'group', 'Reports')), [reports]);
  const isNars = currentOrganization.rollupOrganizationEnum === RollupOrganizationEnums.NARS;

  const fireLoadEvent = useLoadEvent({ PageName: 'Report Dashboard', PageType: 'Report' });

  useEffect(() => {
    fireLoadEvent();
  }, [fireLoadEvent]);

  useEffect(() => {
    if (!reports && currentOrganization) {
      dispatch(
        getReportsByWorkspaceId(
          currentOrganization.businessId,
          currentOrganization.organizationKind,
          currentOrganization.rollupOrganizationEnum,
        ),
      );
    }
  }, [dispatch, reports, currentOrganization]);

  useEffect(() => {
    if (!isNars) dispatch(getSmsSummaryReportAuthorization());
  }, []);

  return (
    <div className={styles.container}>
      <Grid container spacing={2}>
        <Grid item md={2}>
          <Link className={styles.link} to="/Dashboard">
            <KeyboardBackspace /> Dashboard
          </Link>
        </Grid>
        {!isNars && (
          <Grid item md={10}>
            {isSmsSummaryReportAuthorized && (
              <Link className={styles.link} to="/reports/summaryreports">
                SMS Summary Provider Review
              </Link>
            )}
          </Grid>
        )}
      </Grid>
      {reports?.length > 0 ? (
        keys(reportGroups).map((group, index) => (
          <ReportGroup group={group as ReportGroups} key={index} reports={reportGroups[group]} />
        ))
      ) : (
        <ReportGroup isLoading={isLoadingReports} />
      )}
    </div>
  );
};
