// Libs
import * as yup from 'yup';
import moment from 'moment';

// Core
import {
  IDictionary,
  IEditLearnerCompletionValidationBlueprint,
  ILearnerCredits,
  LearnerCreditsBoardTypeEnum,
} from 'core/models';
import {
  IBCTBoardLearnerCollectionField,
  BoardLearnerCollectionFields,
  IBCTBoard,
  CollectionTypes,
  BoardCreditTypeSubmissionTypes,
  IBCTCreditType,
} from 'layouts/pages/bct/types';
import { isAlmostInteger } from 'utils';

interface IRequiredFieldSearch {
  fieldConfigs: IBCTBoardLearnerCollectionField[];
  fieldType: BoardLearnerCollectionFields;
}

// Completion Date Criteria
// - On or after start date
// - On or before max claim date, if any
// - Required
const buildCompletionDateSchema = ({
  creditMaxClaimDate,
  startDate,
}: IEditLearnerCompletionValidationBlueprint): yup.DateSchema =>
  yup
    .date()
    .test({
      message: 'Completion date must be before max credit claim date',
      name: 'creditMaxClaimDate',
      test: (val) => (creditMaxClaimDate ? moment(val) <= moment(creditMaxClaimDate) : true),
    })
    .test({
      message: 'Completion date must be on or after activity start date',
      name: 'minDateTest',
      test: (val) => {
        if (!startDate || !val) {
          return true;
        }
        const completionDateMoment = moment(val);
        const startDateMoment = moment(startDate);
        return !completionDateMoment.isBefore(startDateMoment, 'days');
      },
    })
    .typeError('')
    .required('Completion date required to save');

// Check if the requested fieldType is required across configs
const isFieldRequired = ({ fieldConfigs = [], fieldType }: IRequiredFieldSearch): boolean => {
  if (!fieldConfigs?.length) {
    return false;
  }
  if (!fieldType) {
    return false;
  }
  const matchField: IBCTBoardLearnerCollectionField = fieldConfigs?.find(
    ({ fieldType: matchFieldType }): boolean => fieldType === matchFieldType,
  );
  if (!matchField) {
    return false;
  }
  return matchField?.collectionType === CollectionTypes.REQUIRED;
};

// Credits Criteria
// - Default must be selected
// - If a credit has dependencies, they must be selected
// - Required if the corresponding checkbox is checked
export const buildMocCreditsSchema = (
  boardConfig: IBCTBoard,
  maxBoardCredits: number,
  allowedCreditTypes: string[],
): IDictionary<yup.AnyObjectSchema> => {
  const creditsSchema: IDictionary<yup.AnyObjectSchema> = {};
  const creditIncrement: number = boardConfig?.creditIncrement;

  allowedCreditTypes?.forEach(
    (creditId) =>
      (creditsSchema[creditId] = yup.object().shape({
        credits: yup.number().when('selected', {
          is: (val: boolean) => val,
          otherwise: yup.number().notRequired(),
          then: yup
            .number()
            .min(0, 'Credits cannot be negative')
            .max(maxBoardCredits, `Credits cannot exceed ${maxBoardCredits}`)
            .test({
              message: `Credits must be in increments of ${creditIncrement}`,
              name: 'mocCreditIncrementTest',
              test: (val: number) => {
                if (typeof val === 'number' && typeof creditIncrement === 'number') {
                  return isAlmostInteger(val / creditIncrement);
                }
                return true;
              },
            }),
        }),
        selected: yup.boolean().notRequired(),
      })),
  );
  return creditsSchema;
};

const buildTotalCreditsSchema = (
  creditIncrement: number,
  maxCredits: number,
  isMaxCreditsLimited?: boolean,
): yup.NumberSchema =>
  yup
    .number()
    .typeError('')
    .test({
      message: `Credits must be in increments of ${creditIncrement}`,
      name: 'creditIncrementTest',
      test: (val: number) => {
        if (typeof val === 'number' && typeof creditIncrement === 'number') {
          return isAlmostInteger(val / creditIncrement);
        }
        return true;
      },
    })
    .max(isMaxCreditsLimited ? maxCredits : Math.max() * -1, `Credits cannot exceed ${maxCredits}`)
    .min(0, 'Credits cannot be negative');

export const buildCreditsByCertifyingBoardSchema = ({
  boardActivityDetails,
  boardConfigs,
  boardId,
}: IEditLearnerCompletionValidationBlueprint & { boardId: string; boardType: LearnerCreditsBoardTypeEnum }): any => {
  const boardConfig = boardConfigs?.[boardId];
  const boardDetails = boardActivityDetails?.[boardId];
  const creditIncrement = boardConfig?.creditIncrement;
  const maxBoardCredits = boardDetails?.mocPointsGiven;
  const allowedCreditTypes = boardDetails?.typesOfCreditIds;
  let instanceTotalCredits = 0;

  const boardSchema = yup.object().shape({
    [boardId]: yup.object().shape({
      learnerCredits: yup.object().when('totalCredits', {
        is: (val: number) => {
          instanceTotalCredits = val;
          return true;
        },
        otherwise: yup.object().notRequired(),
        then: yup
          .object()
          .shape(buildMocCreditsSchema(boardConfig, instanceTotalCredits, allowedCreditTypes))
          .test({
            message: '',
            name: 'crossCreditErrors',
            test: (val: IDictionary<ILearnerCredits>, context: yup.TestContext) => {
              if (!boardConfig) {
                return true;
              }
              const selectedCreditKeys: string[] = allowedCreditTypes?.filter(
                (creditKey: string): boolean => val?.[creditKey]?.selected,
              );
              const { creditTypes }: IBCTBoard = boardConfig;
              const creditKeyWithDependency: string = selectedCreditKeys?.find(
                (creditKey: string): boolean =>
                  creditTypes?.find(({ id }: IBCTCreditType): boolean => id === creditKey)
                    ?.boardCreditTypeSubmissionType === BoardCreditTypeSubmissionTypes.WITH_SPECIFIC_CREDIT_TYPES,
              );
              const configuredDependencies: string[] = boardConfig.creditTypes?.find(
                ({ id }: IBCTCreditType): boolean => id === creditKeyWithDependency,
              )?.boardCreditTypeRequiredCreditTypeIds;
              const isCreditDependenciesSelected: boolean = configuredDependencies?.length
                ? configuredDependencies.some((dependentKey: string): boolean =>
                    selectedCreditKeys?.includes(dependentKey),
                  )
                : true;
              if (!isCreditDependenciesSelected) {
                const dependentCreditName = creditTypes?.find(
                  ({ id }: IBCTCreditType): boolean => id === creditKeyWithDependency,
                )?.organizationName;
                const dependencyNames: string[] = configuredDependencies.map(
                  (dependentKey: string): string =>
                    creditTypes?.find(({ id }: IBCTCreditType): boolean => id === dependentKey)?.organizationName,
                );
                const dependencyErrorMessage = `${dependentCreditName} must be submitted with ${dependencyNames?.join(
                  ', ',
                )}`;
                const dependencyValidationError: yup.ValidationError = new yup.ValidationError(
                  dependencyErrorMessage,
                  val,
                  context.path,
                );
                return dependencyValidationError;
              }
              const creditKeysSubmitWithOthers: string[] = selectedCreditKeys?.filter(
                (creditKey: string): boolean =>
                  creditTypes?.find(({ id }: IBCTCreditType): boolean => id === creditKey)
                    ?.boardCreditTypeSubmissionType === BoardCreditTypeSubmissionTypes.WITH_ANY_OTHER_CREDIT_TYPE,
              );
              const isSubmitWithOthersValid: boolean = creditKeysSubmitWithOthers?.length !== 1;
              if (!isSubmitWithOthersValid) {
                const submitWithOthersErrorMessage = 'Selected credit type cannot be submitted alone';
                const submitWithOthersValidationError: yup.ValidationError = new yup.ValidationError(
                  submitWithOthersErrorMessage,
                  val,
                  context.path,
                );
                return submitWithOthersValidationError;
              }
              return true;
            },
          }),
      }),
      totalCredits: buildTotalCreditsSchema(creditIncrement, maxBoardCredits),
    }),
  });
  return boardSchema;
};

const buildCreditsByRemsSchema = (boardConfig: IBCTBoard): yup.AnyObjectSchema =>
  yup.object().shape({
    deaRegistrationTypeId: isFieldRequired({
      fieldConfigs: boardConfig?.boardLearnerCollectionFields,
      fieldType: BoardLearnerCollectionFields.DEA_REGISTRATION,
    })
      ? yup.string().typeError('').required('DEA registration is required')
      : yup.string().typeError('').notRequired(),
    learnerId: isFieldRequired({
      fieldConfigs: boardConfig?.boardLearnerCollectionFields,
      fieldType: BoardLearnerCollectionFields.PROVIDER_LEARNER_ID,
    })
      ? yup.string().typeError('').required('Learner ID is required')
      : yup.string().typeError('').notRequired(),
    practiceAreaId: isFieldRequired({
      fieldConfigs: boardConfig?.boardLearnerCollectionFields,
      fieldType: BoardLearnerCollectionFields.PRACTICE_AREA,
    })
      ? yup.string().typeError('').required('Practice area is required')
      : yup.string().typeError('').notRequired(),
    practiceStateOrProvince: isFieldRequired({
      fieldConfigs: boardConfig?.boardLearnerCollectionFields,
      fieldType: BoardLearnerCollectionFields.PRACTICE_STATE,
    })
      ? yup.object().test({
          message: 'Practice state is required',
          name: 'practiceStateRequiredTest',
          test: (value) => {
            return !!value?.id;
          },
        })
      : yup.object().typeError('').notRequired(),
    professionId: isFieldRequired({
      fieldConfigs: boardConfig?.boardLearnerCollectionFields,
      fieldType: BoardLearnerCollectionFields.PROFESSION,
    })
      ? yup.string().typeError('').required('Profession area is required')
      : yup.string().typeError('').notRequired(),
    timeInPracticeId: isFieldRequired({
      fieldConfigs: boardConfig?.boardLearnerCollectionFields,
      fieldType: BoardLearnerCollectionFields.TIME_IN_PRACTICE,
    })
      ? yup.string().typeError('').required('Time in practice is required')
      : yup.string().typeError('').notRequired(),
  });

const buildCreditsByStateOrProvinceSchema = (
  boardConfig: IBCTBoard,
  maxTotalCredits: number,
  isMaxCreditsLimited: boolean,
): yup.AnyObjectSchema =>
  yup.object().shape({
    // build the validation object dynamically based on the keys in the data (ie. the credit types)
    // Todo 0.25 will be dynamic whebever we enable this
    learnerCredits: yup.lazy((obj) =>
      yup.object().shape(
        Object.fromEntries(
          Object.keys(obj || {}).map((_) => [
            _,
            yup.object().shape({
              credits: buildTotalCreditsSchema(
                boardConfig?.creditIncrement || 0.25,
                maxTotalCredits,
                isMaxCreditsLimited,
              ),
            }),
          ]),
        ),
      ),
    ),
  });
export const buildEditCompletionSchema = (blueprint: IEditLearnerCompletionValidationBlueprint): yup.AnySchema => {
  const boardType: LearnerCreditsBoardTypeEnum = blueprint?.boardType;
  const boardId: string = blueprint?.boardId;
  const boardConfig: IBCTBoard = blueprint?.boardConfigs?.[boardId];
  return yup.object().shape({
    completionDate: buildCompletionDateSchema(blueprint),
    creditsByCertifyingBoard:
      boardType === LearnerCreditsBoardTypeEnum.MOC && boardId
        ? buildCreditsByCertifyingBoardSchema(blueprint).nullable(true)
        : yup.object().notRequired(),
    creditsByRems:
      boardType === LearnerCreditsBoardTypeEnum.REMS && boardId
        ? yup.object().shape({ [boardId]: buildCreditsByRemsSchema(boardConfig).nullable(true) })
        : yup.object().notRequired(),
    creditsByStateOrProvince:
      boardType === LearnerCreditsBoardTypeEnum.STATE && boardId
        ? yup.lazy((obj) =>
            // build the validation object dynamically based on the keys in the data (ie. the states)
            yup
              .object()
              .shape(
                Object.fromEntries(
                  Object.keys(obj || {}).map((_) => [
                    _,
                    buildCreditsByStateOrProvinceSchema(
                      boardConfig,
                      blueprint?.maxTotalCredits,
                      blueprint.isMaxCreditsLimited,
                    ).nullable(true),
                  ]),
                ),
              ),
          )
        : yup.object().notRequired(),
  });
};
