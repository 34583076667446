import { Reducer } from 'redux';
import { FAQActionTypes, FAQ_OPEN, FAQ_CLOSE, FAQState } from './types';

const initialState: FAQState = {
  faqOpen: false,
  faqs: [],
};

const faqReducer: Reducer<FAQState, FAQActionTypes> = (state = initialState, action: FAQActionTypes): FAQState => {
  switch (action.type) {
    case FAQ_OPEN:
      return {
        ...state,
        faqOpen: true,
      };
    case FAQ_CLOSE:
      return {
        ...state,
        faqOpen: false,
      };
    default:
      return state;
  }
};

export default faqReducer;
