import { ReactElement } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { KeyboardBackspaceRounded } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

// Components
import { LoadingCards } from 'components/LoadingCard';
import { NoResultsCard } from 'components/NoResultsCard';

// Styles
import styles from './styles.module.scss';

export const DetailLoading = ({ hasError, isOpen }: { hasError: boolean; isOpen: boolean }): ReactElement => {
  return (
    <div className={styles['form-container']}>
      <div className={classNames(styles['loading-wrapper'], 'form-container')}>
        <div className="page-navigation">
          <Link className="tertiary" to="/activities/summary">
            <KeyboardBackspaceRounded className="tertiary-icon-back" />
            Activity Summary
          </Link>
        </div>
        <section>
          {hasError ? (
            <NoResultsCard headingText="Invalid Activity ID">
              <p>We are unable to find this ID. Please try another.</p>
            </NoResultsCard>
          ) : (
            <LoadingCards />
          )}
        </section>
      </div>
      <aside className={classNames('rail-container rail-container--75', { open: isOpen }, styles['loading-wrapper'])}>
        <section className={styles.content}>{hasError ? null : <CircularProgress />}</section>
      </aside>
    </div>
  );
};
