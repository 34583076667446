import { ChangeEvent, ReactElement, useMemo } from 'react';
import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { SearchRounded } from '@material-ui/icons';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Field, FieldProps, useField, useFormikContext } from 'formik';

const useStyles = makeStyles({
  input: {
    padding: '0 0rem !important',
  },
});

export interface ITypeaheadOption {
  id: string;
  label: string;
}

export interface ITypeaheadProps {
  className?: string;
  freeSolo?: boolean;
  id?: string;
  name: string;
  onChange?: (name: string, value: ITypeaheadOption | string) => void;
  options: ITypeaheadOption[];
  placeholder?: string;
}

const inputAdornment = (
  <InputAdornment position="start">
    <SearchRounded />
  </InputAdornment>
);

const style = { minWidth: '50%' };

export const Typeahead = ({
  className,
  freeSolo = false,
  id,
  name,
  onChange,
  options,
  placeholder = 'Select an option',
}: ITypeaheadProps): ReactElement => {
  const { setTouched, setFieldValue, touched } = useFormikContext();
  const [field] = useField(name);
  const { value } = field;

  // to make this work properly as a typeahead, we need to continue to feed the original value as `value` to Autocomplete - this captures it (and works on the first render, as opposed to useEffect)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValue = useMemo(() => value, []);

  const classes = useStyles();

  const handleBlur = (): void => {
    setTouched({ ...touched, [name]: true });
  };

  const handleChange = (_: ChangeEvent<unknown>, value: ITypeaheadOption): void => {
    if (freeSolo) {
      // in text (aka freeSolo) mode, we need the text from the selected item
      setFieldValue(name, value?.label, true);
      onChange?.(name, value?.label);
    } else {
      setFieldValue(name, value, true);
      onChange?.(name, value);
    }
  };

  const handleInputChange = (_: ChangeEvent<unknown>, value: string): void => {
    setFieldValue(name, value || '', false);
    onChange?.(name, value);
  };

  // Returns true if an option is selected
  const getOptionSelected = (current: ITypeaheadOption, next: ITypeaheadOption): boolean => {
    return current?.id === next?.id;
  };

  // Renders the label for the field to display
  const getOptionLabel = (option: ITypeaheadOption | string): string => {
    if (!option) return '';
    if (typeof option === 'string') return option;
    return option?.label || '';
  };

  const renderInput = (params: AutocompleteRenderInputParams): ReactElement => (
    <TextField
      {...params}
      InputProps={{ ...params.InputProps, endAdornment: null, startAdornment: inputAdornment }}
      inputProps={{ ...params.inputProps, 'aria-label': placeholder, id: id || name }}
      placeholder={placeholder}
      variant="outlined"
    />
  );
  const renderAutocomplete = (fieldProps: FieldProps): ReactElement => {
    return (
      <Autocomplete
        {...fieldProps}
        {...field}
        aria-label={name}
        classes={{
          input: classes.input,
        }}
        className={className}
        freeSolo={freeSolo}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        onBlur={handleBlur}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        renderInput={renderInput}
        style={style}
        {...(freeSolo
          ? {
              inputValue: value || '',
              onInputChange: handleInputChange,
              value: initialValue, // if we use `value` here, the options don't filter as you type
            }
          : {
              value: value || null,
            })}
      />
    );
  };
  return <Field name={name}>{renderAutocomplete}</Field>;
};
