import { Reducer } from 'redux';
import {
  UserState,
  USER_AUTH0_LOADING,
  USER_AUTH0_SUCCESS,
  GET_USER_CURRENT,
  GET_USER_CURRENT_FAILURE,
  GET_USER_CURRENT_SUCCESS,
  SET_CURRENT_ORGANIZATION,
  SET_DEFAULT_ORGANIZATION,
  SET_CURRENT_PROVIDER,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
} from './types';

export const initialState: UserState = {
  auth0User: null,
  currentOrganizationContext: null,
  currentProviderContext: null,
  defaultOrganizationContext: null,
  isAuthenticating: false,
  isLoading: false,
  userProfile: null,
};

const userReducer: Reducer<UserState> = (state = initialState, action): UserState => {
  switch (action.type) {
    case USER_AUTH0_LOADING:
      return {
        ...state,
        isAuthenticating: true,
      };

    case USER_AUTH0_SUCCESS:
      return {
        ...state,
        auth0User: action.payload,
        isAuthenticating: false,
      };

    case CREATE_USER:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case CREATE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case GET_USER_CURRENT:
      return {
        ...state,
        isLoading: true,
      };

    case GET_USER_CURRENT_FAILURE:
      console.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case GET_USER_CURRENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userProfile: action.payload,
      };

    case SET_CURRENT_ORGANIZATION:
      return {
        ...state,
        currentOrganizationContext: action.payload,
      };

    case SET_DEFAULT_ORGANIZATION:
      return {
        ...state,
        defaultOrganizationContext: action.payload,
      };

    case SET_CURRENT_PROVIDER:
      return {
        ...state,
        currentProviderContext: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
