import { ReactElement, useEffect, useState } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { KeyboardBackspaceRounded } from '@material-ui/icons';

import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress, Grid } from '@material-ui/core';

// Components
import DatePicker from 'components/DatePicker/DatePicker';
import InputBlock from 'components/global/inputBlock/inputBlock';
import Button from 'components/Button/Button';
import { Modal } from 'components/ContinuousImprovement/Modal';

import { FormikTextField } from 'components/ContinuousImprovement/FormikTextField';
import { ITypeaheadOption, Typeahead } from 'components/ContinuousImprovement/Typeahead';

// Core
import { statesAndProvincesSelector } from 'store/locations/selectors';
import { medicalSchoolTermsSelector } from 'store/taxonomy/selectors';
import { boardListSelector } from 'store/bct/selectors';
import { matchedLearnersSelector } from 'store/learner/selectors';

import { matchLearners } from 'store/learner/actions';

import { closeModal, openModal } from 'store/modal/actions';

import { getStatesAndProvinces } from 'store/locations/actions';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { getBoardList } from 'store/bct/actions';

// constants
import { TAXONOMY_MEDICAL_SCHOOLS_ROOT_ID } from 'core/constants';

// Types
import {
  IStateAndProvince,
  ITaxonomyTerm,
  IBoardSlim,
  ILearnerMatchingModel,
  IValidateLearnersToolFormModel,
  IDictionary,
  IMatchedLearner,
} from 'core/models';
import { ButtonVariant, StatusEnum } from 'core/enums';

// Styles
import styles from './LearnerValidationForm.module.scss';

// Validations.
import { LearnerValidationToolSchema } from '../validationSchemas';

import { LearnerValidationToolResult } from 'components/ContinuousImprovement/Modal/templates/learner-validation-tool-result';

const initialValues: IValidateLearnersToolFormModel = {
  boardId: '',
  boardName: undefined,
  dateOfBirth: undefined,
  firstName: '',
  lastName: '',
  licenseId: '',
  medicalSchoolName: undefined,
  npi: '',
  stateName: undefined,
};

export const LearnerValidationToolForm = (): ReactElement => {
  // local state

  const [titleSummary, setTitleSummary] = useState<string>('');
  const [modalButtonText, setModalButtonText] = useState<string>('');

  const dispatch = useDispatch();
  const testDateIsValid = (date: Date): boolean => !!new Date(date).getDate();
  const splitDateAsDob = (dateMoment: moment.Moment) => [dateMoment.month() + 1, dateMoment.date()];
  const getMatchPayloadFromFormValues = ({
    boardId,
    boardName,
    dateOfBirth,
    firstName,
    lastName,
    licenseId,
    medicalSchoolName,
    npi,
    stateName,
  }: IValidateLearnersToolFormModel): ILearnerMatchingModel => {
    const boardIds: IDictionary<string> = {};

    // if user provides board ids(combination of boardId and LearnerId), use those; otherwise use boardId as licenseId which is mapped with learnerId as per learner match api.

    if (boardName && boardName.id) {
      boardIds[boardName.id] = boardId || '';
    } else if (!licenseId && boardId) {
      licenseId = boardId;
    }

    // If the Date constructor fails to make a valid date, this will be false
    const isDobValidDateString = testDateIsValid(dateOfBirth);
    const [birthMonth, birthDay] = dateOfBirth && isDobValidDateString ? splitDateAsDob(moment(dateOfBirth)) : [];
    return {
      birthDay,
      birthMonth,
      boardIds,
      firstName,
      lastName,
      licenseId,
      medicalSchoolName: medicalSchoolName?.label,
      npi,
      stateName: stateName?.label,
    };
  };

  const matchedLearners: IMatchedLearner[] = useSelector(matchedLearnersSelector);
  const [isLearnerValidationModalOpen, setIsLearnerValidationModalOpen] = useState<boolean>(false);
  // Hooks.
  const medicalSchools: ITaxonomyTerm[] = useSelector(medicalSchoolTermsSelector);

  const statesAndProvinces: IStateAndProvince[] = useSelector(statesAndProvincesSelector);

  const boardList: IBoardSlim[] = useSelector(boardListSelector);

  const boardListOptions: ITypeaheadOption[] =
    boardList
      ?.filter((board) => board.learnerStatus === StatusEnum.ACTIVE)
      .map(
        ({ id, name }: IBoardSlim): ITypeaheadOption => ({
          id: id,
          label: name,
        }),
      ) || [];

  const statesAndProvincesOptions: ITypeaheadOption[] =
    statesAndProvinces?.map(
      ({ isoStateCode, stateName }: IStateAndProvince): ITypeaheadOption => ({
        id: isoStateCode,
        label: stateName,
      }),
    ) || [];

  useEffect(() => {
    if (!statesAndProvinces) {
      dispatch(getStatesAndProvinces());
    }
  }, [statesAndProvinces, dispatch]);

  useEffect(() => {
    if (!medicalSchools) {
      dispatch(getTaxonomyTermById(TAXONOMY_MEDICAL_SCHOOLS_ROOT_ID));
    }
  }, [medicalSchools, dispatch]);

  useEffect(() => {
    if (!boardList) {
      dispatch(getBoardList());
    }
  }, [boardList, dispatch]);

  const handleBaseLearnerMatching = async (learnerFormValues: IValidateLearnersToolFormModel): Promise<void> => {
    const matchingPayload: ILearnerMatchingModel = getMatchPayloadFromFormValues(learnerFormValues);

    await dispatch(matchLearners(matchingPayload));
  };

  const formik = useFormik({
    initialValues,

    onSubmit: async (values): Promise<void> => {
      await handleBaseLearnerMatching(values);

      setIsLearnerValidationModalOpen(true);
      dispatch(openModal());
    },
    validateOnChange: true,
    validationSchema: LearnerValidationToolSchema,
  });

  const { dirty, errors, handleSubmit, isSubmitting, isValid, resetForm, touched, values } = formik;

  const sortedMedicalSchools: ITypeaheadOption[] =
    medicalSchools?.map(({ id, name }: ITaxonomyTerm): ITypeaheadOption => ({ id, label: name })) || [];

  const isSubmitDisabled: boolean = !dirty || isSubmitting || !isValid;

  useEffect(() => {
    if (matchedLearners) {
      if (matchedLearners?.length === 0) {
        setTitleSummary(
          'There is no learner matching the information you provided. Please try the validation again by entering more data about the learner to see if a match can be made.',
        );
        setModalButtonText('Try again with more information');
      } else if (matchedLearners?.length === 1) {
        setModalButtonText('Done');
        setTitleSummary('The learner is matched, and you can submit completion data for this learner.');
      } else {
        setModalButtonText('Try again with more information');
        setTitleSummary(
          'Multiple learners were matched to the data you provided. Please try the validation again by entering more data about the learner to see if a single match can be made.',
        );
      }
    }
  }, [matchedLearners]);

  const closeValidateLearnersModal = (): void => {
    if (matchedLearners?.length === 1) {
      resetForm({ errors, touched, values: initialValues });
    }
    setIsLearnerValidationModalOpen(false);
    dispatch(closeModal());
  };

  const { firstName, lastName, dateOfBirth, stateName, licenseId, boardName, boardId, npi, medicalSchoolName } = values;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <div className="static-form">
          <div className="form-group">
            <h4>Enter the data that you have for your learner</h4>
            <h6>
              First name and last name are required. Beyond that there are no specific required fields. The more
              information you can provide, the more likely you are to get a single matched learner.
            </h6>
            <Grid container spacing={2} className={styles['form-grid']}>
              <Grid item xs={12} sm={4} className={styles.input}>
                <InputBlock
                  errors={errors}
                  name="firstName"
                  required
                  suppressLabel
                  touched={touched}
                  title="First name"
                >
                  <FormikTextField formikKey="firstName" helperText="" placeholder="First name" variant="outlined" />
                </InputBlock>
              </Grid>
              <Grid item xs={12} sm={4} className={styles.input}>
                <InputBlock errors={errors} name="lastName" required touched={touched} suppressLabel title="Last name">
                  <FormikTextField formikKey="lastName" placeholder="Last name" helperText="" variant="outlined" />
                </InputBlock>
              </Grid>
              <Grid item xs={12} sm={2} className={styles.input}>
                <InputBlock errors={errors} name="dateOfBirth" touched={touched} title="Dob" suppressLabel>
                  <Field
                    component={DatePicker}
                    id="dateOfBirth"
                    name="dateOfBirth"
                    className="form-input"
                    format="MM/DD"
                    helperText=""
                    disablePast={false}
                  />
                </InputBlock>
              </Grid>
              <Grid item xs={12} sm={5} className={styles.input}>
                <InputBlock errors={errors} name="stateName" touched={touched} title="Licensing state" suppressLabel>
                  <Typeahead
                    options={statesAndProvincesOptions}
                    id="stateName"
                    name="stateName"
                    placeholder="Select a state or province"
                  />
                </InputBlock>
              </Grid>
              <Grid item xs={12} sm={5} className={styles.input}>
                <InputBlock errors={errors} touched={touched} name="licenseId" title="Licensing id" suppressLabel>
                  <FormikTextField formikKey="licenseId" placeholder="Licensing id" variant="outlined" />
                </InputBlock>
              </Grid>
              <Grid item xs={12} sm={6} className={styles.input}>
                <InputBlock errors={errors} name="boardName" touched={touched} title="Board Name" suppressLabel>
                  <Typeahead
                    options={boardListOptions}
                    id="boardName"
                    name="boardName"
                    placeholder="Enter Board Name"
                  />
                </InputBlock>
              </Grid>
              <Grid item xs={12} sm={4} className={styles.input}>
                <InputBlock errors={errors} touched={touched} name="boardId" title="Board Id" suppressLabel>
                  <FormikTextField formikKey="boardId" placeholder="Board Id" variant="outlined" />
                </InputBlock>
              </Grid>
              <Grid item xs={12} sm={5} className={styles.input}>
                <InputBlock errors={errors} touched={touched} name="npi" title="NPI" suppressLabel>
                  <FormikTextField formikKey="npi" placeholder="NPI" helperText="" variant="outlined" />
                </InputBlock>
              </Grid>
              <Grid item xs={12} sm={5} className={styles.input}>
                <InputBlock
                  errors={errors}
                  name="medicalSchoolName"
                  touched={touched}
                  title="Medical school"
                  suppressLabel
                >
                  <Typeahead
                    options={sortedMedicalSchools}
                    id="medicalSchoolName"
                    name="medicalSchoolName"
                    placeholder="Select a medical school"
                  />
                </InputBlock>
              </Grid>
            </Grid>
          </div>
          <div className={styles['button-row']}>
            <Button
              variant={ButtonVariant.Primary}
              title="Validate Learner"
              disabled={isSubmitDisabled}
              onClick={formik.submitForm}
              startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
            >
              Validate Learner
            </Button>
            <Link className="tertiary" to="/learners/validation/batch">
              Upload a learner validation batch file
              <KeyboardBackspaceRounded className="tertiary-icon" />
            </Link>
          </div>
        </div>
      </form>
      <Modal
        isOpen={isLearnerValidationModalOpen}
        onClose={closeValidateLearnersModal}
        title="Learner Validation Result"
      >
        <LearnerValidationToolResult
          dateOfBirth={dateOfBirth}
          firstName={firstName}
          titleSummary={titleSummary}
          lastName={lastName}
          licenseId={licenseId}
          stateName={stateName}
          boardName={boardName}
          boardId={boardId}
          npi={npi}
          medicalSchoolName={medicalSchoolName}
        />

        {modalButtonText !== '' && (
          <div className={styles['button-row']}>
            <Button
              variant={ButtonVariant.Primary}
              onClick={closeValidateLearnersModal}
              startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
            >
              {modalButtonText}
            </Button>
          </div>
        )}
      </Modal>
    </FormikProvider>
  );
};
