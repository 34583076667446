import { get, isArray, keys } from 'lodash';
import { IDictionary } from 'core/models';
import { ReactElement } from 'react';

interface IProps {
  error: any;
  name: string;
}

export const ErrorLabel = ({ error, name }: IProps): ReactElement => {
  const renderErrorArrayEntry = (reactKey: string, key: string, list: IDictionary<string>): ReactElement => (
    <p className="error" key={reactKey}>
      {list[key]}
    </p>
  );

  const renderErrorMessage = (error: any): ReactElement => {
    if (typeof error === 'string') {
      return <p className="error">{error}</p>;
    }
    if (isArray(error)) {
      const errorOutput = error?.map((entry, index): ReactElement[] | ReactElement => {
        if (typeof entry === 'object') {
          const entryKeys = keys(entry);
          return entryKeys?.map((key, idx) => renderErrorArrayEntry(`${index}-${idx}`, key, entry));
        } else if (typeof entry === 'string') {
          return <p className="error">{entry}</p>;
        }
        return null;
      });
      return <>{errorOutput}</>;
    } else if (typeof error === 'object') {
      return <p className="error">{get(error, name)}</p>;
    }
  };
  return <>{renderErrorMessage(error)}</>;
};
