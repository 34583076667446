import { Close } from '@material-ui/icons';
import { ReactElement, useCallback, useRef, useState } from 'react';

// Components.
import Button from 'components/Button/Button';

// Core.
import { ButtonVariant } from 'core/enums';

// Styles.
import styles from './FileInput.module.scss';

interface IFileInputProps {
  buttonLabel?: string;
  files?: File[];
  label?: string;
  multiple?: boolean;
  onRemove?(file: File): void;
  onUpload?(files: File[]): void;
}

export const FileInput = (props: IFileInputProps): ReactElement => {
  const { buttonLabel = 'Select File', files, label, multiple, onRemove, onUpload } = props;
  const [isUploading, setIsUploading] = useState<boolean>();
  const ref = useRef(null);

  const handleButtonClick = useCallback(() => {
    ref.current?.click();
  }, [ref]);

  const handleChange = useCallback(
    async (event) => {
      setIsUploading(true);
      if (onUpload) await onUpload?.([...event.target.files]);
      setIsUploading(false);
    },
    [onUpload],
  );

  const handleRemove = useCallback(
    (file: File) => {
      onRemove?.(file);
    },
    [onRemove],
  );

  return (
    <div>
      {label && (
        <span className={styles.label} id="file-input-label">
          {label}
        </span>
      )}
      <div className={styles.container}>
        <Button
          aria-labelledby="file-input-label"
          disabled={isUploading}
          isSubmitting={isUploading}
          onClick={handleButtonClick}
          variant={ButtonVariant.Secondary}
        >
          {buttonLabel}
        </Button>
        {files?.map((file) => (
          <div className={styles.file} key={file.name}>
            <span>{file.name}</span>
            <button
              aria-label={`Remove file ${file.name}`}
              className={styles.button}
              onClick={() => handleRemove(file)}
              type="button"
            >
              <Close />
            </button>
          </div>
        ))}
      </div>
      <input className={styles.input} onChange={handleChange} multiple={multiple} ref={ref} type="file" value="" />
    </div>
  );
};
