// Core + Store
import { ContentTag, IJsonPatchDocumentOperation } from 'core/models';
import { ServerRoute } from 'core/api';
import { API } from 'core';
import { ContentTagsApiService } from 'core/contentTagsApiService';

export class ContentTagsService {
  private contentTagsApiService: ContentTagsApiService;
  private static contentApiService: ContentTagsApiService = new ContentTagsApiService();
  ContentTagsApiService;
  constructor() {
    this.contentTagsApiService = new ContentTagsApiService();
  }

  public async getAllContentTagsAsync(): Promise<Array<ContentTag>> {
    return await this.contentTagsApiService.getAllContentTagAsync();
  }

  public static async patch(id: string, payload: IJsonPatchDocumentOperation[]): Promise<void> {
    await this.contentApiService.patchContentTagAsync(id, payload);
  }

  public static async put(id: string, payload: Partial<ContentTag>): Promise<void> {
    await this.contentApiService.putContentTagAsync(id, payload);
  }

  public async getContentTagsById(id: string): Promise<ContentTag> {
    const route: ServerRoute = ServerRoute.forAction('contentTags', `${id}`);
    return await API.get(route);
  }
}
