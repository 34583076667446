import { ReactElement } from 'react';

// Types
interface IProps {
  htmlContent: string;
  formikKey: string;
}
export const FieldCaption = (props: IProps): ReactElement => {
  const { htmlContent, formikKey } = props;
  return <div className="caption-text" data-input-id={formikKey} dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
