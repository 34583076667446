import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

// Hooks.
import { EventArgs, GtmEvents, useEvent } from 'hooks';

// Store.
import { currentOrganizationContextSelector } from 'store/user/selectors';

type LoadEventFunction = (overrideArgs?: EventArgs, force?: boolean) => void;

export const useLoadEvent = (args: EventArgs): LoadEventFunction => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const currentOrganization = useSelector(currentOrganizationContextSelector);
  const fireEvent = useEvent();

  const { PageName, ...rest } = args;

  return useCallback(
    (overrideArgs?: EventArgs, force?: boolean) => {
      if (!isLoaded || force) {
        setIsLoaded(true);

        // Wait 150ms to ensure react-helmet has time
        // to update page metadata before firing.
        setTimeout(() => {
          fireEvent({
            Event: 'pageLoad',
            LoginStatus: true,
            OrganizationId: currentOrganization?.id,
            Page: window.location.href,
            PageName: PageName || document.title,
            UserPersona: currentOrganization?.organizationKind,
            event: GtmEvents.PageLoad,
            ...rest,
            ...overrideArgs,
          });
        }, 150);
      }
    },
    [PageName, isLoaded, rest, currentOrganization, fireEvent],
  );
};
