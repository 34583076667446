import {
  IComparisonOperation,
  IConstantOperation,
  IFieldRefOperation,
  ILogicalOperation,
  IOperand,
  IWhere,
} from 'core/models';
import { JSONPath } from 'jsonpath-plus';

export class CamlService {
  public Evaluate(where: IWhere, json: object): boolean {
    if (where === null || where === undefined) throw new Error('where cannot be null or undefined');

    let value: string | number | boolean | Date | Array<string> | null = this.EvaluateOperand(where.Operand, json);

    if (typeof value === 'string') value = (value as any).toLowerCase() === 'true';
    else if (typeof value === 'number') value = (value as any) > 0;
    else if (typeof value !== 'boolean') throw new Error(`Operand evaluation returned an invalid response: ${value}`);

    return value!;
  }

  private EvaluateOperand(operand: IOperand, json: object): string | number | boolean | Date | Array<string> | null {
    if (operand === null || operand === undefined) throw new Error('operand cannot be null or undefined');

    switch (operand.OperandType) {
      case 'Comparison':
        return this.EvaluateComparisonOperation(operand as IComparisonOperation, json);

      case 'Constant':
        return this.EvaluateConstantOperation(operand as IConstantOperation);

      case 'FieldRef':
        return this.EvaluateFieldRefOperation(operand as IFieldRefOperation, json);

      case 'Logical':
        return this.EvaluateLogicalOperation(operand as ILogicalOperation, json);

      default:
        throw new Error(`Unknown operand type ${operand.OperandType}`);
    }
  }

  private EvaluateComparisonOperation(comparisonOperation: IComparisonOperation, json: object): boolean {
    if (comparisonOperation === null || comparisonOperation === undefined)
      throw new Error('comparisonOperation cannot be null or undefined');

    const left: string | number | boolean | Date | Array<string> | null = this.EvaluateOperand(
      comparisonOperation.Left,
      json,
    );
    const right: string | number | boolean | Date | Array<string> | null =
      comparisonOperation.ComparisonOperationType === 'IsNotNull' ||
      comparisonOperation.ComparisonOperationType === 'IsNull'
        ? null
        : this.EvaluateOperand(comparisonOperation.Right!, json);

    switch (comparisonOperation.ComparisonOperationType) {
      case 'Eq':
        return left === right;

      case 'Geq':
        return left! >= right!;

      case 'Gt':
        return left! > right!;

      case 'IsNotNull':
        return left !== null && left !== undefined;

      case 'IsNull':
        return left === null || left === undefined;

      case 'Leq':
        return left! <= right!;

      case 'Lt':
        return left! < right!;

      case 'Neq':
        return left !== right!;

      default:
        throw new Error(`Unknown comparison operation type ${comparisonOperation.ComparisonOperationType}`);
    }
  }

  private EvaluateConstantOperation(
    constantOperation: IConstantOperation,
  ): string | number | boolean | Date | Array<string> | null {
    if (constantOperation === null || constantOperation === undefined)
      throw new Error('constantOperation cannot be null or undefined');

    switch (constantOperation.FormInputType) {
      case 'Boolean':
        const stringValue: string =
          constantOperation.Value === null || constantOperation.Value === undefined
            ? false.toString()
            : constantOperation.Value.toString().toLowerCase();
        return stringValue === 'true';

      case 'Date':
        if (constantOperation.Value === '[Today]') {
          const now = new Date();
          return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDay()));
        } else {
          const stringValue: string =
            constantOperation.Value === null || constantOperation.Value === undefined
              ? new Date().toISOString()
              : constantOperation.Value.toString();
          return new Date(stringValue);
        }

      case 'DateTime':
        if (constantOperation.Value === '[Now]') {
          return new Date();
        } else {
          const stringValue: string =
            constantOperation.Value === null || constantOperation.Value === undefined
              ? '1900-01-01T00:00:00.0Z'
              : constantOperation.Value.toString();
          return new Date(stringValue);
        }

        break;

      case 'Guid':
        return constantOperation.Value === null || constantOperation.Value === undefined
          ? '00000000-0000-0000-0000-000000000000'
          : constantOperation.Value.toString();

      case 'Integer':
        return constantOperation.Value === null || constantOperation.Value === undefined
          ? 0
          : parseInt(constantOperation.Value.toString());

      case 'MultiChoice':
        return constantOperation.Value === null || constantOperation.Value === undefined
          ? []
          : constantOperation.Value.toString().split(';#');
      case 'MultiChoiceGrid':
        return constantOperation.Value === null || constantOperation.Value === undefined
          ? []
          : constantOperation.Value.toString().split(';#');
      case 'NestedMultiChoice':
        return constantOperation.Value === null || constantOperation.Value === undefined
          ? []
          : constantOperation.Value.toString().split(';#');
      case 'Number':
        return constantOperation.Value === null || constantOperation.Value === undefined
          ? 0
          : parseFloat(constantOperation.Value.toString());

      case 'Text':
      case 'Choice':
      case 'Note':
        return constantOperation.Value === null || constantOperation.Value === undefined
          ? null
          : constantOperation.Value.toString();

      case 'Time':
        return constantOperation.Value === null || constantOperation.Value === undefined
          ? '00:00:00.0'
          : constantOperation.Value.toString();

      default:
        throw new Error(`Unknown form input type ${constantOperation.FormInputType}`);
    }
  }

  private EvaluateFieldRefOperation(
    fieldRefOperation: IFieldRefOperation,
    json: object,
  ): string | number | boolean | Date | Array<string> | null {
    if (fieldRefOperation === null || fieldRefOperation === undefined)
      throw new Error('fieldRefOperation cannot be null or undefined');

    const value: string | number | boolean = JSONPath<string | number | boolean>({
      path: fieldRefOperation.JsonPath,
      json,
    })[0];

    switch (fieldRefOperation.FormInputType) {
      // radios in the form won't be true/false but instead have string values that include those words
      case 'Boolean':
        return value === null || value === undefined
          ? false.toString()
          : value.toString().toLowerCase().includes('true');

      case 'Date':
      case 'DateTime':
        const stringValue: string = value === null || value === undefined ? '1900-01-01T00:00:00.0Z' : value.toString();
        return new Date(stringValue);

      case 'Integer':
        return value === null || value === undefined ? false.toString() : parseInt(value.toString());

      case 'MultiChoice':
        return value === null || value === undefined ? [] : value;
      case 'MultiChoiceGrid':
        return value === null || value === undefined ? [] : value;
      case 'NestedMultiChoice':
        return value === null || value === undefined ? [] : value;

      case 'Number':
        return value === null || value === undefined ? false.toString() : parseFloat(value.toString());

      default:
        return value === null || value === undefined ? null : value.toString();
    }
  }

  private EvaluateLogicalOperation(logicalOperation: ILogicalOperation, json: object): boolean {
    if (logicalOperation === null || logicalOperation === undefined)
      throw new Error('logicalOperation cannot be null or undefined');

    let leftValue: string | number | boolean | Date | Array<string> | null = this.EvaluateOperand(
      logicalOperation.Left,
      json,
    );
    let rightValue: string | number | boolean | Date | Array<string> | null = this.EvaluateOperand(
      logicalOperation.Right,
      json,
    );

    if (typeof leftValue === 'string') leftValue = (leftValue as any).toLowerCase() === 'true';
    else if (typeof leftValue === 'number') leftValue = (leftValue as any) > 0;
    else if (typeof leftValue !== 'boolean')
      throw new Error(`Left Operand evaluation returned an invalid response: ${leftValue}`);
    if (typeof rightValue === 'string') rightValue = (rightValue as any).toLowerCase()! === 'true';
    else if (typeof rightValue === 'number') rightValue = (rightValue as any) > 0;
    else if (typeof rightValue !== 'boolean')
      throw new Error(`Right Operand evaluation returned an invalid response: ${rightValue}`);

    switch (logicalOperation.LogicalOperationType) {
      case 'And':
        return leftValue && rightValue;

      case 'Or':
        return leftValue || rightValue;

      default:
        throw new Error(`Unknown logical operation type ${logicalOperation.LogicalOperationType}`);
    }
  }
}
