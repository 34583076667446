import * as PARS from '../../core';

export interface ReportState {
  isLoadingReport: boolean;
  isLoadingReports: boolean;
  isLoadingSmsSummaryReports: boolean;
  report?: any;
  reports: IReport[];
  smsSummaryReports: Array<string>;
  isSmsSummaryReportAuthorized: boolean;
}

export interface IReport {
  description?: string;
  group?: ReportGroups;
  link?: string;
  name: string;
  parameterAssignment: string;
  reportId: string;
  showDateParameters?: boolean;
  workspaceId: string;
}

export enum ReportGroups {
  Activity = 'Activity',
  Learner = 'Learner',
  None = 'None',
  Program = 'Program',
}

export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAILURE = 'GET_REPORT_FAILURE';
export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_SMS_SUMMARY_REPORT_FILE_LIST = 'GET_SMS_SUMMARY_REPORT_FILE_LIST';
export const GET_SMS_SUMMARY_REPORT_FILE_LIST_SUCCESS = 'GET_SMS_SUMMARY_REPORT_FILE_LIST_SUCCESS';
export const GET_SMS_SUMMARY_REPORT_FILE_LIST_FAILURE = 'GET_SMS_SUMMARY_REPORT_FILE_LIST_FAILURE';
export const GET_SMS_SUMMARY_REPORT_FILE = 'GET_SMS_SUMMARY_REPORT_FILE';
export const GET_SMS_SUMMARY_REPORT_FILE_SUCCESS = 'GET_SMS_SUMMARY_REPORT_FILE_SUCCESS';
export const GET_SMS_SUMMARY_REPORT_FILE_FAILURE = 'GET_SMS_SUMMARY_REPORT_FILE_FAILURE';
export const GET_SMS_SUMMARY_REPORT_DIRECTORY = 'GET_SMS_SUMMARY_REPORT_DIRECTORY';
export const GET_SMS_SUMMARY_REPORT_DIRECTORY_SUCCESS = 'GET_SMS_SUMMARY_REPORT_DIRECTORY_SUCCESS';
export const GET_SMS_SUMMARY_REPORT_DIRECTORY_FAILURE = 'GET_SMS_SUMMARY_REPORT_DIRECTORY_FAILURE';
export const GET_SMS_SUMMARY_REPORT_AUTHORIZATION = 'GET_SMS_SUMMARY_REPORT_AUTHORIZATION';
export const GET_SMS_SUMMARY_REPORT_AUTHORIZATION_SUCCESS = 'GET_SMS_SUMMARY_REPORT_AUTHORIZATION_SUCCESS';
export const GET_SMS_SUMMARY_REPORT_AUTHORIZATION_FAILURE = 'GET_SMS_SUMMARY_REPORT_AUTHORIZATION_FAILURE';

export interface GetReport extends PARS.Models.PARSAction<undefined, typeof GET_REPORT> {
  type: typeof GET_REPORT;
}

export interface GetReportSuccess extends PARS.Models.PARSAction<any, typeof GET_REPORT_SUCCESS> {
  type: typeof GET_REPORT_SUCCESS;
  payload: any; // TODO add type
}

export interface GetReportFailure extends PARS.Models.PARSAction<undefined, typeof GET_REPORT_FAILURE> {
  type: typeof GET_REPORT_FAILURE;
}

export interface GetReports extends PARS.Models.PARSAction<undefined, typeof GET_REPORTS> {
  type: typeof GET_REPORTS;
}

export interface GetReportsFailure extends PARS.Models.PARSAction<undefined, typeof GET_REPORTS_FAILURE> {
  type: typeof GET_REPORTS_FAILURE;
}

export interface GetReportsSuccess extends PARS.Models.PARSAction<any, typeof GET_REPORTS_SUCCESS> {
  type: typeof GET_REPORTS_SUCCESS;
  payload: any; // TODO add type
}

export interface GetSmsSummaryReportFileList
  extends PARS.Models.PARSAction<undefined, typeof GET_SMS_SUMMARY_REPORT_FILE_LIST> {
  type: typeof GET_SMS_SUMMARY_REPORT_FILE_LIST;
}

export interface GetSmsSummaryReportFileListFailure
  extends PARS.Models.PARSAction<undefined, typeof GET_SMS_SUMMARY_REPORT_FILE_LIST_FAILURE> {
  type: typeof GET_SMS_SUMMARY_REPORT_FILE_LIST_FAILURE;
}

export interface GetSmsSummaryReportFileListSuccess
  extends PARS.Models.PARSAction<Array<string>, typeof GET_SMS_SUMMARY_REPORT_FILE_LIST_SUCCESS> {
  type: typeof GET_SMS_SUMMARY_REPORT_FILE_LIST_SUCCESS;
  payload: Array<string>;
}

export interface GetSmsSummaryReportFile extends PARS.Models.PARSAction<undefined, typeof GET_SMS_SUMMARY_REPORT_FILE> {
  type: typeof GET_SMS_SUMMARY_REPORT_FILE;
}

export interface GetSmsSummaryReportFileFailure
  extends PARS.Models.PARSAction<undefined, typeof GET_SMS_SUMMARY_REPORT_FILE_FAILURE> {
  type: typeof GET_SMS_SUMMARY_REPORT_FILE_FAILURE;
}

export interface GetSmsSummaryReportFileSuccess
  extends PARS.Models.PARSAction<string, typeof GET_SMS_SUMMARY_REPORT_FILE_SUCCESS> {
  type: typeof GET_SMS_SUMMARY_REPORT_FILE_SUCCESS;
  payload: string;
}

export interface GetSmsSummaryReportDirectory
  extends PARS.Models.PARSAction<undefined, typeof GET_SMS_SUMMARY_REPORT_DIRECTORY> {
  type: typeof GET_SMS_SUMMARY_REPORT_DIRECTORY;
}

export interface GetSmsSummaryReportDirectoryFailure
  extends PARS.Models.PARSAction<undefined, typeof GET_SMS_SUMMARY_REPORT_DIRECTORY_FAILURE> {
  type: typeof GET_SMS_SUMMARY_REPORT_DIRECTORY_FAILURE;
}

export interface GetSmsSummaryReportDirectorySuccess
  extends PARS.Models.PARSAction<string, typeof GET_SMS_SUMMARY_REPORT_DIRECTORY_SUCCESS> {
  type: typeof GET_SMS_SUMMARY_REPORT_DIRECTORY_SUCCESS;
  payload: string;
}

export interface GetSmsSummaryReportAuthorization
  extends PARS.Models.PARSAction<undefined, typeof GET_SMS_SUMMARY_REPORT_AUTHORIZATION> {
  type: typeof GET_SMS_SUMMARY_REPORT_AUTHORIZATION;
}

export interface GetSmsSummaryReportAuthorizationSuccess
  extends PARS.Models.PARSAction<undefined, typeof GET_SMS_SUMMARY_REPORT_AUTHORIZATION_SUCCESS> {
  type: typeof GET_SMS_SUMMARY_REPORT_AUTHORIZATION_SUCCESS;
}

export interface GetSmsSummaryReportAuthorizationFailure
  extends PARS.Models.PARSAction<undefined, typeof GET_SMS_SUMMARY_REPORT_AUTHORIZATION_FAILURE> {
  type: typeof GET_SMS_SUMMARY_REPORT_AUTHORIZATION_FAILURE;
}

export type ReportActionTypes =
  | GetReport
  | GetReportFailure
  | GetReportSuccess
  | GetReports
  | GetReportsFailure
  | GetReportsSuccess
  | GetSmsSummaryReportFileList
  | GetSmsSummaryReportFileListFailure
  | GetSmsSummaryReportFileListSuccess
  | GetSmsSummaryReportFile
  | GetSmsSummaryReportFileFailure
  | GetSmsSummaryReportFileSuccess
  | GetSmsSummaryReportDirectory
  | GetSmsSummaryReportDirectorySuccess
  | GetSmsSummaryReportDirectoryFailure
  | GetSmsSummaryReportAuthorization
  | GetSmsSummaryReportAuthorizationSuccess
  | GetSmsSummaryReportAuthorizationFailure;
