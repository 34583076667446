import { CACHE_USER_CURRENT, GUID_EMPTY } from 'core/constants';
import { RollupOrganizationEnums } from 'core/models';
import { CacheService } from 'services';

export type Site = RollupOrganizationEnums.PARS | RollupOrganizationEnums.NARS;

const parsUrls = (process.env.REACT_APP_PARS_URL ?? '').split(';');
const narsUrls = (process.env.REACT_APP_NARS_URL ?? '').split(';');

// identifies which site we're currently on based on URL
export function getSite(): Site {
  // NARS TODO: confirm this works with all possible ways of hitting the site - ie. will this have issues with https://pars.accme.org/ vs. https://pars.accme.org:443/?
  if (parsUrls.some((u) => window.location.href.startsWith(u))) {
    return RollupOrganizationEnums.PARS;
  }
  if (narsUrls.some((u) => window.location.href.startsWith(u))) {
    return RollupOrganizationEnums.NARS;
  }
  console.log(
    `Unknown site. Defaulting to PARS.  ${window.location.href}, ${parsUrls.join(';')}, ${narsUrls.join(';')}`,
  );
  return RollupOrganizationEnums.PARS;
}

// identifies which site a given rollup organization wants to be on
export function getTargetSite(rollupOrganization?: RollupOrganizationEnums): Site {
  return rollupOrganization === RollupOrganizationEnums.NARS
    ? RollupOrganizationEnums.NARS
    : RollupOrganizationEnums.PARS;
}

function getSiteBaseUrl(site: Site): string {
  return site === RollupOrganizationEnums.NARS
    ? narsUrls[0]
    : site === RollupOrganizationEnums.PARS
    ? parsUrls[0]
    : null;
}

export function getRedirectUrl(site: Site, orgId?: string, providerId?: string): string {
  const baseUrl = getSiteBaseUrl(site);
  if (!baseUrl) return baseUrl;
  const userId = (CacheService.get(CACHE_USER_CURRENT) as { id?: string })?.id ?? GUID_EMPTY;
  const parts = [userId, orgId, providerId].filter((part) => !!part);
  return new URL(`/set-context/${parts.join('/')}`, baseUrl).toString();
}
