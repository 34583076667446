import { Reducer } from 'redux';
import * as PARS from 'core';
import {
  FormTemplateState,
  FormTemplateActionTypes,
  GET_ACTIVITY_FORM_TEMPLATE,
  GET_ACTIVITY_FORM_TEMPLATE_SUCCESS,
  GET_ACTIVITY_FORM_TEMPLATE_FAILURE,
  SET_ACTIVITY_FORM_VALIDATION_OBJECT,
} from './types';

const initialState: FormTemplateState = {
  activityTemplate: [],
  activityValidationObject: {},
  error: '',
};

const formTemplateReducer: Reducer<FormTemplateState, FormTemplateActionTypes> = (
  state = initialState,
  action: FormTemplateActionTypes,
): FormTemplateState => {
  switch (action.type) {
    case GET_ACTIVITY_FORM_TEMPLATE:
      return {
        ...state,
        activityTemplate: action.payload || [],
      };
    case GET_ACTIVITY_FORM_TEMPLATE_SUCCESS:
      return {
        ...state,
        activityTemplate: action.payload || [],
      };
    case GET_ACTIVITY_FORM_TEMPLATE_FAILURE:
      return {
        ...state,
        error: PARS.API.getErrorMessage((action.payload || {}) as Error),
      };
    case SET_ACTIVITY_FORM_VALIDATION_OBJECT:
      return {
        ...state,
        activityValidationObject: action.payload || {},
      };
    default:
      return state;
  }
};

export default formTemplateReducer;
