// Libs
import { ReactElement, useEffect, useMemo } from 'react';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkAction } from 'redux-thunk';

// Core + Store
import {
  BoardLearnerCollectionFields,
  CollectionTypes,
  IBCTBoard,
  IBCTBoardLearnerCollectionField,
} from 'layouts/pages/bct/types';
import { IDictionary, IFormInputOption, IStateAndProvince, ITaxonomyTerm, PARSAction } from 'core/models';
import { taxonomyRootTermsSelector } from 'store/taxonomy/selectors';
import {
  TAXONOMY_DEA_REGISTRATION_ROOT_ID,
  TAXONOMY_PRACTICE_AREA_ROOT_ID,
  TAXONOMY_PROFESSION_ROOT_ID,
  TAXONOMY_TIME_IN_PRACTICE_ROOT_ID,
} from 'core/constants';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { statesAndProvincesSelector } from 'store/locations/selectors';
import { ITypeaheadOption, Typeahead } from 'components/ContinuousImprovement/Typeahead';

// Components
import Dropdown from 'components/Dropdown/Dropdown';
import InputBlock from 'components/global/inputBlock/inputBlock';
import { FastFormikTextField } from 'components/ContinuousImprovement/FastFormikTextField';
import { FastFormikCheckbox as FormikCheckboxField } from 'components/ContinuousImprovement/FastFormikCheckbox';

// Styles
import styles from './RemsCompletion.module.scss';

// Utils
import { mapTermToFormInputOption } from 'layouts/pages/learner/add-learner/utils';

interface IProps {
  config: IBCTBoard;
}

export const RemsCompletion = ({ config }: IProps): ReactElement => {
  const dispatch = useDispatch();
  const taxonomyRoot: IDictionary<ITaxonomyTerm> = useSelector(taxonomyRootTermsSelector);
  const professionTerms: ITaxonomyTerm[] = taxonomyRoot?.[TAXONOMY_PROFESSION_ROOT_ID]?.terms;
  const timeInPracticeTerms: ITaxonomyTerm[] = taxonomyRoot?.[TAXONOMY_TIME_IN_PRACTICE_ROOT_ID]?.terms;
  const practiceAreaTerms: ITaxonomyTerm[] = taxonomyRoot?.[TAXONOMY_PRACTICE_AREA_ROOT_ID]?.terms;
  const deaTerms: ITaxonomyTerm[] = taxonomyRoot?.[TAXONOMY_DEA_REGISTRATION_ROOT_ID]?.terms;
  const statesAndProvinces: IStateAndProvince[] = useSelector(statesAndProvincesSelector);
  const statesAndProvincesOptions: ITypeaheadOption[] =
    statesAndProvinces?.map(
      ({ isoStateCode, stateName }: IStateAndProvince): ITypeaheadOption => ({
        id: isoStateCode,
        label: stateName,
      }),
    ) || [];

  const getRemsCompletionMetadata = async () => {
    const metadataPromises: ThunkAction<Promise<void>, unknown, null, PARSAction<unknown>>[] = [];

    // If missing Profession taxonomy, fetch it
    if (!professionTerms || !professionTerms?.length) {
      metadataPromises.push(dispatch(getTaxonomyTermById(TAXONOMY_PROFESSION_ROOT_ID)));
    }

    // If missing Time in Practice taxonomy, fetch it
    if (!timeInPracticeTerms || !timeInPracticeTerms?.length) {
      metadataPromises.push(dispatch(getTaxonomyTermById(TAXONOMY_TIME_IN_PRACTICE_ROOT_ID)));
    }

    // If missing Practice Area taxonomy, fetch it
    if (!practiceAreaTerms || !practiceAreaTerms?.length) {
      metadataPromises.push(dispatch(getTaxonomyTermById(TAXONOMY_PRACTICE_AREA_ROOT_ID)));
    }

    // If missing DEA Registration taxonomy, fetch it
    if (!deaTerms || !deaTerms?.length) {
      metadataPromises.push(dispatch(getTaxonomyTermById(TAXONOMY_DEA_REGISTRATION_ROOT_ID)));
    }

    Promise.all(metadataPromises);
  };

  // Config metadata
  const learnerFieldConfig: IBCTBoardLearnerCollectionField[] = config?.boardLearnerCollectionFields;
  const getFieldConfig = useMemo(
    () => (fieldType: BoardLearnerCollectionFields): IBCTBoardLearnerCollectionField =>
      learnerFieldConfig?.find(
        ({ fieldType: fieldName }: IBCTBoardLearnerCollectionField): boolean => fieldName === fieldType,
      ),
    [learnerFieldConfig],
  );
  const boardId = config?.id;
  const baseFieldName = `creditsByRems.${boardId}`;

  const deaRegistrationFieldName = `${baseFieldName}.deaRegistrationTypeId`;
  const practiceAreaFieldName = `${baseFieldName}.practiceAreaId`;
  const practiceStateFieldName = `${baseFieldName}.practiceStateOrProvince`;
  const professionFieldName = `${baseFieldName}.professionId`;
  const learnerIdFieldName = `${baseFieldName}.learnerId`;
  const surgicalProceduresFieldName = `${baseFieldName}.performsSurgicalProcedures`;
  const recentlyPrescribedFieldName = `${baseFieldName}.prescribedInPast12Months`;
  const timeInPracticeFieldName = `${baseFieldName}.timeInPracticeId`;

  // Requiredness and visibility per field
  const learnerIdConfig = getFieldConfig(BoardLearnerCollectionFields.PROVIDER_LEARNER_ID);
  const practiceStateConfig = getFieldConfig(BoardLearnerCollectionFields.PRACTICE_STATE);
  const professionConfig = getFieldConfig(BoardLearnerCollectionFields.PROFESSION);
  const timeInPracticeConfig = getFieldConfig(BoardLearnerCollectionFields.TIME_IN_PRACTICE);
  const practiceAreaConfig = getFieldConfig(BoardLearnerCollectionFields.PRACTICE_AREA);
  const deaRegistrationConfig = getFieldConfig(BoardLearnerCollectionFields.DEA_REGISTRATION);
  const surgicalProceduresConfig = getFieldConfig(BoardLearnerCollectionFields.SURGICAL_PROCEDURES);
  const prescribedConfig = getFieldConfig(BoardLearnerCollectionFields.PRESCRIBED_IN_PAST_12_MONTHS);

  const mapTermsToFormInputOptions = useMemo(() => (term: ITaxonomyTerm) => mapTermToFormInputOption(term), []);

  // Construct a Dictionary of Form Input Options for board taxonomy fields
  const deaRegistrationOptions: IFormInputOption[] = deaTerms?.map(mapTermsToFormInputOptions);
  const practiceAreaOptions: IFormInputOption[] = practiceAreaTerms?.map(mapTermsToFormInputOptions);
  const professionOptions: IFormInputOption[] = professionTerms?.map(mapTermsToFormInputOptions);
  const timeInPracticeOptions: IFormInputOption[] = timeInPracticeTerms?.map(mapTermsToFormInputOptions);

  const selectedDeaRegistrations = deaRegistrationOptions?.filter(({ Id }: IFormInputOption): boolean =>
    deaRegistrationConfig?.taxonomyTermIds?.includes(Id),
  );
  const selectedPracticeAreas = practiceAreaOptions?.filter(({ Id }: IFormInputOption): boolean =>
    practiceAreaConfig?.taxonomyTermIds?.includes(Id),
  );
  const selectedProfessions = professionOptions?.filter(({ Id }: IFormInputOption): boolean =>
    professionConfig?.taxonomyTermIds?.includes(Id),
  );
  const selectedTimesInPractice = timeInPracticeOptions?.filter(({ Id }: IFormInputOption): boolean =>
    timeInPracticeConfig?.taxonomyTermIds?.includes(Id),
  );

  // Initial load, get recent learner completions
  useEffect(() => {
    getRemsCompletionMetadata();
  }, []);

  // Show this while loading.
  if (
    !config ||
    !professionTerms ||
    !deaTerms ||
    !practiceAreaTerms ||
    !timeInPracticeTerms ||
    !statesAndProvincesOptions
  ) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  return (
    <Grid container spacing={2} className={styles['rems-completion']}>
      <Grid item xs={12} sm={6}>
        <InputBlock
          name={learnerIdFieldName}
          title="Learner ID"
          required={learnerIdConfig.collectionType === CollectionTypes.REQUIRED}
        >
          <FastFormikTextField
            variant="outlined"
            formikKey={learnerIdFieldName}
            placeholder="Learner ID"
            helperText=""
            required={learnerIdConfig.collectionType === CollectionTypes.REQUIRED}
          />
        </InputBlock>
      </Grid>
      {practiceStateConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
        <Grid item xs={12} sm={6}>
          <InputBlock
            name={practiceStateFieldName}
            title="Practice State"
            required={practiceStateConfig.collectionType === CollectionTypes.REQUIRED}
          >
            <Typeahead
              options={statesAndProvincesOptions}
              id="stateName"
              name={practiceStateFieldName}
              placeholder="Select a state or province"
            />
          </InputBlock>
        </Grid>
      )}
      {professionConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
        <Grid item xs={12} sm={6}>
          <InputBlock
            name={professionFieldName}
            title="Profession"
            required={professionConfig.collectionType === CollectionTypes.REQUIRED}
          >
            <Dropdown
              idProp="Id"
              items={selectedProfessions}
              labelProp="Title"
              name={professionFieldName}
              placeholder="Select a profession"
              required={professionConfig.collectionType === CollectionTypes.REQUIRED}
            />
          </InputBlock>
        </Grid>
      )}
      {timeInPracticeConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
        <Grid item xs={12} sm={6}>
          <InputBlock
            name={timeInPracticeFieldName}
            title="Time in practice"
            required={timeInPracticeConfig.collectionType === CollectionTypes.REQUIRED}
          >
            <Dropdown
              idProp="Id"
              items={selectedTimesInPractice}
              labelProp="Title"
              name={timeInPracticeFieldName}
              placeholder="Select a range"
              required={timeInPracticeConfig.collectionType === CollectionTypes.REQUIRED}
            />
          </InputBlock>
        </Grid>
      )}
      {practiceAreaConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
        <Grid item xs={12} sm={6}>
          <InputBlock
            name={practiceAreaFieldName}
            title="Practice area"
            required={practiceAreaConfig.collectionType === CollectionTypes.REQUIRED}
          >
            <Dropdown
              idProp="Id"
              items={selectedPracticeAreas}
              labelProp="Title"
              name={practiceAreaFieldName}
              placeholder="Select a practice area"
              required={practiceAreaConfig.collectionType === CollectionTypes.REQUIRED}
            />
          </InputBlock>
        </Grid>
      )}
      {deaRegistrationConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
        <Grid item xs={12} sm={6}>
          <InputBlock
            name={deaRegistrationFieldName}
            title="DEA registration"
            required={deaRegistrationConfig.collectionType === CollectionTypes.REQUIRED}
          >
            <Dropdown
              idProp="Id"
              items={selectedDeaRegistrations}
              labelProp="Title"
              name={deaRegistrationFieldName}
              placeholder="Select a DEA Registration Type"
              required={deaRegistrationConfig.collectionType === CollectionTypes.REQUIRED}
            />
          </InputBlock>
        </Grid>
      )}
      {surgicalProceduresConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
        <Grid item xs={12}>
          <FormikCheckboxField formikKey={surgicalProceduresFieldName} title="Surgical procedures" />
        </Grid>
      )}
      {prescribedConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
        <Grid item xs={12}>
          <FormikCheckboxField formikKey={recentlyPrescribedFieldName} title="Prescribed in past 12 months?" />
        </Grid>
      )}
    </Grid>
  );
};
