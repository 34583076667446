import { ChangeEvent, Fragment, ReactElement, useEffect } from 'react';
import { Box, FormGroup, FormLabel, Grid, RadioGroup } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

// Components
import {
  FormikBooleanCheckboxField,
  FormikBooleanRadioField,
  FormikCheckboxField,
  FormikInputField,
  FormikRadioField,
} from 'components/ContinuousImprovement/PureFormElements';

import DatePicker from 'components/DatePicker/DatePicker';

// Styles
import formStyles from '../../FormStyles.module.scss';

// Core + Store
import { IValidationException } from 'core/models';
import { creditTypesErrorsSelector, currentBoardSelector } from 'store/bct/selectors';

// Utils
import { handleValidationException } from 'globals/utils/handleValidationException';

// Types
import { BoardCreditTypeSubmissionTypes, BoardTypes, IBCTBoard, IBCTCreditType } from 'layouts/pages/bct/types';
import { ICreditTypeDetails } from '../initialCreditTypeDetails';

interface IProps {
  creditType: IBCTCreditType;
  formikKey: string;
  selectedCreditTypes: IBCTCreditType[];
  sortOrder: number;
}

export const CreditType = (props: IProps): ReactElement => {
  const { creditType, formikKey, selectedCreditTypes, sortOrder } = props;
  const { id, name } = creditType;
  const { values, setFieldValue, setFieldError } = useFormikContext<ICreditTypeDetails>();

  // Selectors
  const validationExceptions: IValidationException[] = useSelector(creditTypesErrorsSelector)?.[id]
    ?.validationExceptions;
  const currentBoard: IBCTBoard = useSelector(currentBoardSelector);
  const isLicensing: boolean = currentBoard?.type === BoardTypes.STATE_BOARD;

  const creditTypes: IBCTCreditType[] = get(values, 'creditTypes', []);
  const boardCreditTypeRequiredCreditTypeIds: string[] = get(creditType, `boardCreditTypeRequiredCreditTypeIds`, []);
  const isWithSpecificCreditTypes: boolean =
    get(creditType, 'boardCreditTypeSubmissionType') === BoardCreditTypeSubmissionTypes.WITH_SPECIFIC_CREDIT_TYPES;
  const filteredSelectedCreditTypes: IBCTCreditType[] = selectedCreditTypes.filter(
    (creditType: IBCTCreditType): boolean => creditType?.name !== name,
  );

  // Handle server errors.
  useEffect(() => {
    if (validationExceptions) {
      validationExceptions.forEach((validationException: IValidationException): void => {
        const [formFieldKey, message] = handleValidationException(validationException);

        setFieldError(`${formikKey}.${formFieldKey}`, message);
      });
    }
  }, [formikKey, setFieldError, validationExceptions]);

  // `boardCreditTypeRequiredCreditTypeIds` cannot be null. Make sure to initialize as an empty array.
  useEffect(() => {
    if (!boardCreditTypeRequiredCreditTypeIds.length) {
      setFieldValue(`${formikKey}.boardCreditTypeRequiredCreditTypeIds`, []);
    }
  }, []);

  useEffect(() => {
    setFieldValue(`${formikKey}.sortOrder`, sortOrder);
  }, [sortOrder]);

  const onChangeCheckbox = ({ e, id }): void => {
    const isChecked: boolean = e.target.checked;
    // If new value is checked, add it to the temp array.
    if (isChecked) {
      setFieldValue(`${formikKey}.boardCreditTypeRequiredCreditTypeIds`, [...boardCreditTypeRequiredCreditTypeIds, id]);
    } else {
      // Otherwise, remove it from the array.
      setFieldValue(
        `${formikKey}.boardCreditTypeRequiredCreditTypeIds`,
        boardCreditTypeRequiredCreditTypeIds.filter((existingId: string): boolean => existingId !== id),
      );
    }
  };

  const setAsDefault = (id: string): void => {
    creditTypes.map((val: IBCTCreditType, idx: number): void => {
      const defaultKey = `creditTypes.${idx}.default`;

      // Update the `default` key of the selected id to be true. Make all of the others be false.
      return val.id === id ? setFieldValue(defaultKey, !val.default) : setFieldValue(defaultKey, false);
    });
  };

  return (
    <div className={formStyles['form-card-banner']}>
      <Box className={formStyles.banner} display="flex" flexDirection="space-between" alignItems="center">
        <Box flexGrow={1}>{name}</Box>
        <Box>
          <FormikBooleanCheckboxField
            callback={(): void => setAsDefault(creditType.id)}
            checkboxAlignment="center"
            formikKey={`${formikKey}.default`}
            name="Set As Default"
            payloadValue={creditType.default}
          />
        </Box>
      </Box>
      <Box component="section" className={formStyles.container}>
        {!isLicensing && (
          <>
            <fieldset>
              <legend>Enter other terms used for this credit type.</legend>
              <RadioGroup aria-label="Enter other terms used for this credit type.">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} md={3}>
                    <FormikInputField
                      formikKey={`${formikKey}.organizationName`}
                      label="Organization Credit Language"
                      placeholder="Enter Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <FormikInputField
                      formikKey={`${formikKey}.abbreviation`}
                      label="PARS Abbreviation"
                      placeholder="Enter Acronym"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormikInputField
                      formikKey={`${formikKey}.xmlLanguageActivity`}
                      label="XML Language Activity"
                      placeholder="Enter ID"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormikInputField
                      formikKey={`${formikKey}.xmlLanguageLearnerCompletion`}
                      label="XML Language Learner Completions"
                      placeholder="Enter ID"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </fieldset>
            {/*********************/}
            <fieldset>
              <legend>What is the XML unit language for this credit type?</legend>
              <RadioGroup aria-label="activity status">
                <Grid container spacing={2}>
                  <Grid item>
                    <FormikRadioField name="Point" formikKey={`${formikKey}.xmlLanguageUnits`} />
                  </Grid>
                  <Grid item>
                    <FormikRadioField name="Credits" formikKey={`${formikKey}.xmlLanguageUnits`} />
                  </Grid>
                </Grid>
              </RadioGroup>
            </fieldset>
            {/*********************/}
            <fieldset className={formStyles['no-divider']}>
              <legend>Enter the start and end dates for this credit type registration.</legend>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormLabel component="div">Start Date</FormLabel>
                  <Field name={`${formikKey}.registrationStartDate`}>
                    {({ field }): ReactElement => (
                      <DatePicker
                        field={field}
                        formikKey={`${formikKey}.registrationStartDate`}
                        fullWidth
                        timeOfDay="midnight"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="div">End Date</FormLabel>
                  <Field name={`${formikKey}.registrationEndDate`}>
                    {({ field }): ReactElement => (
                      <DatePicker
                        field={field}
                        formikKey={`${formikKey}.registrationEndDate`}
                        fullWidth
                        timeOfDay="endOfDay"
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </fieldset>
            {/*********************/}
            <fieldset className={formStyles['no-divider']}>
              <legend>Enter the start and end dates for activities for this credit type.</legend>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormLabel component="div">Start Date</FormLabel>
                  <Field name={`${formikKey}.activityStartDate`}>
                    {({ field }): ReactElement => (
                      <DatePicker
                        fullWidth
                        field={field}
                        formikKey={`${formikKey}.activityStartDate`}
                        timeOfDay="midnight"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="div">End Date</FormLabel>
                  <Field name={`${formikKey}.activityEndDate`}>
                    {({ field }): ReactElement => (
                      <DatePicker
                        fullWidth
                        field={field}
                        formikKey={`${formikKey}.activityEndDate`}
                        timeOfDay="endOfDay"
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </fieldset>
            {/*********************/}
            <fieldset className={formStyles['no-divider']}>
              <legend>Enter the start and end dates for learners for this credit type.</legend>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormLabel component="div">Start Date</FormLabel>
                  <Field name={`${formikKey}.learnerCompletionStartDate`}>
                    {({ field }): ReactElement => (
                      <DatePicker
                        field={field}
                        formikKey={`${formikKey}.learnerCompletionStartDate`}
                        fullWidth
                        timeOfDay="midnight"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="div">End Date</FormLabel>
                  <Field name={`${formikKey}.learnerCompletionEndDate`}>
                    {({ field }): ReactElement => (
                      <DatePicker
                        field={field}
                        formikKey={`${formikKey}.learnerCompletionEndDate`}
                        fullWidth
                        timeOfDay="endOfDay"
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </fieldset>
            {/*********************/}
            <fieldset>
              <legend>Allow credit types to be submitted alone?</legend>
              <RadioGroup aria-label="Allow credit types to be submitted alone?">
                <Grid container spacing={2}>
                  <Grid item>
                    <FormikRadioField
                      formikKey={`${formikKey}.boardCreditTypeSubmissionType`}
                      name="Alone"
                      payloadValue={BoardCreditTypeSubmissionTypes.ALONE}
                    />
                  </Grid>
                  <Grid item>
                    <FormikRadioField
                      formikKey={`${formikKey}.boardCreditTypeSubmissionType`}
                      name="No, with any other credit type"
                      payloadValue={BoardCreditTypeSubmissionTypes.WITH_ANY_OTHER_CREDIT_TYPE}
                    />
                  </Grid>
                  <Grid item>
                    <FormikRadioField
                      formikKey={`${formikKey}.boardCreditTypeSubmissionType`}
                      name="No, with specific credit type(s)"
                      payloadValue={BoardCreditTypeSubmissionTypes.WITH_SPECIFIC_CREDIT_TYPES}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </fieldset>
            {/*********************/}
            {isWithSpecificCreditTypes && (
              <fieldset className={formStyles['no-divider']}>
                <legend>Which credit type(s) must this be submitted with?</legend>
                <FormGroup>
                  {filteredSelectedCreditTypes.map(
                    (creditType: IBCTCreditType): ReactElement => {
                      const { id, name } = creditType;

                      return (
                        <Fragment key={id}>
                          <FormikCheckboxField
                            checked={boardCreditTypeRequiredCreditTypeIds.includes(id)}
                            formikKey={`${formikKey}.boardCreditTypeRequiredCreditTypeIds`}
                            name={name}
                            onChange={(e: ChangeEvent<HTMLInputElement>): void => onChangeCheckbox({ e, id })}
                            payloadValue={id}
                          />
                        </Fragment>
                      );
                    },
                  )}
                </FormGroup>
              </fieldset>
            )}
            {/*********************/}
            <fieldset>
              <legend>Should this credit always match the AMA credit value?</legend>
              <RadioGroup aria-label="Should this credit always match the AMA credit value?">
                <Grid container spacing={2}>
                  <Grid item>
                    <FormikBooleanRadioField name="Yes" formikKey={`${formikKey}.mustMatchCmeAmount`} value />
                  </Grid>
                  <Grid item>
                    <FormikBooleanRadioField name="No" formikKey={`${formikKey}.mustMatchCmeAmount`} value={false} />
                  </Grid>
                </Grid>
              </RadioGroup>
            </fieldset>
            {/*********************/}
            <fieldset>
              <legend>Does this credit type earn credit?</legend>
              <RadioGroup aria-label="Does this credit type earn credit?">
                <Grid container spacing={2}>
                  <Grid item>
                    <FormikBooleanRadioField name="Yes" formikKey={`${formikKey}.earnsCredit`} value />
                  </Grid>
                  <Grid item>
                    <FormikBooleanRadioField name="No" formikKey={`${formikKey}.earnsCredit`} value={false} />
                  </Grid>
                </Grid>
              </RadioGroup>
            </fieldset>
            {/*********************/}
            <fieldset className={formStyles['no-divider']}>
              <legend>Is this credit type additive?</legend>
              <RadioGroup aria-label="Is this credit type additive?">
                <Grid container spacing={2}>
                  <Grid item>
                    <FormikBooleanRadioField name="Yes" formikKey={`${formikKey}.isAdditive`} value />
                  </Grid>
                  <Grid item>
                    <FormikBooleanRadioField name="No" formikKey={`${formikKey}.isAdditive`} value={false} />
                  </Grid>
                </Grid>
              </RadioGroup>
            </fieldset>
          </>
        )}
      </Box>
    </div>
  );
};
