import { useAuth0 } from '@auth0/auth0-react';
import { CACHE_SELECTED_ORGANIZATION, CACHE_SELECTED_PROVIDER, CACHE_USER_CURRENT, GUID_EMPTY } from 'core/constants';
import { ReactElement } from 'react';
import { Redirect, useParams } from 'react-router';
import { CacheService } from 'services';

/**
 * Page that takes the orgId and providerId from the URL and sets them in the cache.
 *
 * Since:
 * 1. This page will only be loaded as a full-page navigation (when switching RollupOrgs via from OrganizationSelect/ProviderSelect)
 * 2. This page is registered with Route instead of AuthenticatedRoute and doesn't use AuthenticatedLayout
 *
 * Therefore:
 * 1. The getCurrentUser action is not fired until _after_ this logic runs
 * 2. We can set the values here and redirect to /, which will cause the normal load logic to run, setting the current org/provider.
 */
export const SetContextPage = (): ReactElement => {
  const { userId, orgId, providerId } = useParams<{ userId: string; orgId?: string; providerId?: string }>();
  const { logout } = useAuth0();

  const currentUserId = (CacheService.get(CACHE_USER_CURRENT) as { id?: string })?.id ?? GUID_EMPTY;
  if (currentUserId !== GUID_EMPTY && userId !== GUID_EMPTY && userId !== currentUserId) {
    console.log(
      `User ID in URL does not match current user ID.  UserId: ${userId}, CurrentUserId: ${currentUserId}.  Clearing cache and auth context.`,
    );

    // Clear cache to prevent session leaks
    CacheService.clear();

    // clear the Auth0 login state for *this site* so we'll have to reauth (though the Auth0 session is probably still valid, so it won't prompt for credentials again)
    logout({ localOnly: true });
  }

  // even if we are going to have to re-login, still save the ID of the org/provider being selected
  if (orgId) {
    CacheService.set(CACHE_SELECTED_ORGANIZATION, orgId);
    if (providerId) {
      CacheService.set(CACHE_SELECTED_PROVIDER, providerId);
    } else {
      CacheService.remove(CACHE_SELECTED_PROVIDER);
    }
  } else {
    CacheService.remove(CACHE_SELECTED_ORGANIZATION);
    CacheService.remove(CACHE_SELECTED_PROVIDER);
  }

  return <Redirect to="/" />;
};
