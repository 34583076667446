import { IInitialSearchFacet } from '../types';

export const INITIAL_LEARNER_SEARCH_FACETS: IInitialSearchFacet = {
  excludeFilters: false,
  isDeleted: false,
  searchText: '',
  skip: 0,
  sortAscending: false,
  sortBy: 'Board',
  top: 10,
};
