// Libs
import { keys } from 'lodash';
import { AxiosError } from 'axios';

// Core
import { IDictionary, IValidationException, ICertifyingBoardFormModel, IAddLearnersFormModel } from 'core/models';

// Utils
import { handleValidationException } from 'globals/utils/handleValidationException';

interface IBoardServerError {
  learnerCredits?: IDictionary<{ validationExceptions: IValidationException[] }>;
  validationExceptions: IValidationException[];
}

interface IAddLearnerServerError {
  creditsByCertifyingBoard: IDictionary<IBoardServerError>;
  creditsByRems: IDictionary<IBoardServerError>;
  creditsByStateOrProvince: IDictionary<IBoardServerError>;
  validationExceptions: IValidationException[];
}

export interface IFieldError {
  formikKey: string;
  message: string;
}

const getFieldError = (validationException: IValidationException): IFieldError => {
  const [formikKey, message] = handleValidationException(validationException);

  return {
    formikKey,
    message,
  };
};

/**
 * @function transformCmeValidationExceptions
 * @description Get the top-level add learner form validation exceptions into a shape usable by setFieldError
 * @param errorResponse IAddLearnerServerError
 * @returns IFieldError[]
 */
const transformCmeValidationExceptions = (errorResponse: IAddLearnerServerError): IFieldError[] => {
  const errorsByStateOrProvince = errorResponse?.creditsByStateOrProvince;
  const stateIds: string[] = keys(errorsByStateOrProvince);
  const fieldErrors: IFieldError[] = errorResponse?.validationExceptions?.map(getFieldError) || [];

  stateIds?.forEach((stateId: string): void => {
    const stateErrors: IBoardServerError = errorsByStateOrProvince?.[stateId];

    fieldErrors.push(...(stateErrors?.validationExceptions?.map(getFieldError) || []));

    const creditErrorKeys: string[] = keys(stateErrors?.learnerCredits);

    creditErrorKeys?.forEach((creditKey: string): void => {
      fieldErrors.push(
        ...(stateErrors?.learnerCredits?.[creditKey]?.validationExceptions?.map(
          (validationException: IValidationException) => {
            const [, message] = handleValidationException(validationException);

            return {
              formikKey: 'credits', // This is hard-coded to work with the form model IAddLearnersFormModel
              message,
            };
          },
        ) || []),
      );
    });
  });
  return fieldErrors;
};

/**
 * @function transformBoardCollabValidationExceptions
 * @description Get the validation exception for all boards in a shape usable by setFieldError
 * @param errorsByBoard IDictionary<IBoardServerError>
 * @param certifyingBoardFormModel ICertifyingBoardFormModel[]
 * @returns IFieldError[]
 */
const transformBoardCollabValidationExceptions = (
  errorsByBoard: IDictionary<IBoardServerError>,
  certifyingBoardFormModel: ICertifyingBoardFormModel[],
): IFieldError[] => {
  const boardIds: string[] = keys(errorsByBoard);
  const fieldErrors: IFieldError[] = [];

  boardIds?.forEach((boardId: string): void => {
    const boardErrors: IBoardServerError = errorsByBoard?.[boardId];
    const index = certifyingBoardFormModel?.findIndex(
      ({ certifyingBoard }: ICertifyingBoardFormModel): boolean => certifyingBoard === boardId,
    );

    if (index >= 0) {
      const baseFormikKey = `certifyingBoards.${index}`;

      fieldErrors.push(
        ...(boardErrors?.validationExceptions?.map((validationException) => {
          const [formikKey, message] = handleValidationException(validationException);

          // Board ID is the field in UI + BCT. BE expects it to be learnerId
          const fieldKey = formikKey === 'learnerId' ? 'boardId' : formikKey;

          return {
            formikKey: `${baseFormikKey}.${fieldKey}`,
            message,
          };
        }) || []),
      );

      const learnerCreditErrorKeys: string[] = keys(boardErrors?.learnerCredits);

      learnerCreditErrorKeys?.forEach((creditKey: string): void => {
        const learnerCreditErrors: IValidationException[] =
          boardErrors?.learnerCredits?.[creditKey]?.validationExceptions ||
          (boardErrors?.learnerCredits?.[creditKey] as any);

        fieldErrors.push(
          ...(learnerCreditErrors?.map((validationException: IValidationException) => {
            const [formikKey, message] = handleValidationException(validationException);

            return {
              formikKey: `${baseFormikKey}.learnerCredits.${formikKey}`,
              message,
            };
          }) || []),
        );
      });
    }
  });
  return fieldErrors;
};

/**
 * @function handleAddLearnerServerError
 * @description Takes the validation exceptions returned for add learner form and returns a usable list of errors with dot-paths
 * @param error AxiosError
 * @param formModel IAddLearnersFormModel
 * @returns IFieldError[]
 */
export const handleAddLearnerServerError = (error: AxiosError, formModel: IAddLearnersFormModel): IFieldError[] => {
  const errorResponse = error?.response?.data;
  const baseValidationExceptions: IFieldError[] = transformCmeValidationExceptions(errorResponse);
  const certifyingBoardValidationExceptions: IFieldError[] = transformBoardCollabValidationExceptions(
    errorResponse?.creditsByCertifyingBoard,
    formModel?.certifyingBoards,
  );
  const remsValidationExceptions: IFieldError[] = transformBoardCollabValidationExceptions(
    errorResponse?.creditsByRems,
    formModel?.certifyingBoards,
  );

  return [...baseValidationExceptions, ...certifyingBoardValidationExceptions, ...remsValidationExceptions];
};
