import { Reducer } from 'redux';
import {
  GET_COUNTRIES,
  GET_COUNTRIES_FAILURE,
  GET_COUNTRIES_SUCCESS,
  GET_STATES_AND_PROVINCES,
  GET_STATES_AND_PROVINCES_FAILURE,
  GET_STATES_AND_PROVINCES_SUCCESS,
  LocationsActionTypes,
  LocationsState,
} from './types';

const initialState: LocationsState = {
  countries: undefined,
  error: undefined,
  isLoading: true,
  statesAndProvinces: undefined,
};

const locationsReducer: Reducer<LocationsState, LocationsActionTypes> = (
  state: LocationsState = initialState,
  action: LocationsActionTypes,
): LocationsState => {
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        isLoading: true,
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        isLoading: false,
      };
    case GET_COUNTRIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case GET_STATES_AND_PROVINCES:
      return {
        ...state,
        isLoading: true,
      };
    case GET_STATES_AND_PROVINCES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        statesAndProvinces: action.payload,
      };
    case GET_STATES_AND_PROVINCES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default locationsReducer;
