import styles from '../../../layouts/pages/account-manager/search.module.scss';
import Button from '../../Button/Button';
import { ButtonVariant } from '../../../core/enums';
import { ArrowBackIos, ArrowForwardIos, MoreHoriz } from '@material-ui/icons';
import React from 'react';
import { range } from 'lodash';

interface CustomPaginationParams {
  dataSet: any[];
  pageSize?: number;
  page: number;
  endSize?: number;
  centerSize?: number;
  onPageChange: (page: number) => void;
}

/**
 * Custom pagination for tables.
 * @param dataSet - the data set array that represents the information in the table.
 * @param pageSize - the number of rows to display per page in the table.
 * @param page - current page number.
 * @param endSize - how many page buttons are present at the beginning and end of the pagination.
 * @param centerSize - how many page buttons are present in the center of the pagination.
 * @param onPageChange - callback function that passes the page index for changing the table information.
 * @constructor
 */
const CustomPagination = ({
  dataSet,
  pageSize = 10,
  page,
  endSize = 1,
  centerSize = 1,
  onPageChange,
}: CustomPaginationParams) => {
  const pageCount = Math.ceil((dataSet?.length ?? 0) / pageSize);
  const filteredPageRange = range(0, pageCount).filter(
    (i) => i < endSize || i >= pageCount - endSize || (page - centerSize <= i && i <= page + centerSize),
  );

  return (
    <div className={styles['paging-buttons']}>
      <Button disabled={page === 0} onClick={() => onPageChange(page - 1)} variant={ButtonVariant.Secondary}>
        <ArrowBackIos fontSize="small" className={styles.previousArrow} />
      </Button>
      {filteredPageRange.map((i) => (
        <>
          <Button key={i} onClick={() => onPageChange(i)} disabled={page === i} variant={ButtonVariant.Secondary}>
            {i + 1}
          </Button>
          {i < pageCount - 1 && filteredPageRange.indexOf(i + 1) === -1 ? (
            <MoreHoriz className={styles.ellipsis} />
          ) : null}
        </>
      ))}
      <Button
        disabled={page === pageCount - 1}
        onClick={() => onPageChange(page + 1)}
        variant={ButtonVariant.Secondary}
      >
        <ArrowForwardIos fontSize="small" className={styles.nextArrow} />
      </Button>
    </div>
  );
};

export default CustomPagination;
