import { useSelector } from 'react-redux';

// Core + Store
import { ITaxonomyTerm, OrganizationKind, OrganizationKinds } from 'core/models';
import {
  currentOrganizationKindSelector,
  isStaffUserSelector,
  rollupOrganizationEnumSelector,
} from 'store/user/selectors';
import { navigationTermsSelector } from 'store/taxonomy/selectors';
import { filterTaxonomyByRollupOrganizationEnum } from 'globals/utils/filterTaxonomyByOrg';

export const useTaxonomySubnavTerms = (pathname: string): ITaxonomyTerm[] => {
  const organizationKind: OrganizationKind = useSelector(currentOrganizationKindSelector);
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  const isStaffUser: boolean = useSelector(isStaffUserSelector);

  // Get all of the subnav terms that:
  // 1. Match the parent route
  // 2. Its tag matches the organization kind
  // - Null tag means it should always be returned regardless of tag
  const parentTerm: ITaxonomyTerm = useSelector(
    navigationTermsSelector,
  )?.find(({ description }: ITaxonomyTerm): boolean => pathname.toLowerCase().includes(description.toLowerCase()));

  return (
    filterTaxonomyByRollupOrganizationEnum(parentTerm?.terms, rollupOrganizationEnum)?.filter(
      ({ tag }: ITaxonomyTerm): boolean => {
        // Example tag: "Staff,Provider,Accreditor"
        const normalizedTag: string = tag?.toLowerCase();
        const normalizedOrganization: string = organizationKind?.toLowerCase();
        const isStaffOrSpecialOrganization: boolean =
          (normalizedTag?.includes(OrganizationKinds.STAFF.toLowerCase()) ||
            normalizedTag?.includes(OrganizationKinds.SPECIAL_ORGANIZATION.toLowerCase())) &&
          isStaffUser;

        // Return true if tag is `null`.
        if (!normalizedTag) return true;

        // Always return true if we are staff or special.
        if (isStaffOrSpecialOrganization) return true;

        // Otherwise, return true if the organizationKind is in the tag string.
        return !!normalizedTag?.includes(normalizedOrganization);
      },
    ) || []
  );
};
