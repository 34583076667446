import { ReactElement } from 'react';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';
import classNames from 'classnames';

// Styles
import styles from '../FormikFields.module.scss';

interface IProps {
  disabled?: boolean;
  formikKey: string;
  label?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  noOffset?: boolean;
  onKeyPress?(e: never): void;
  step?: number;
}
export const FormikNumberField = ({
  formikKey,
  label,
  placeholder,
  min = 0,
  max,
  noOffset,
  onKeyPress,
  step = 0.25,
  ...props
}: IProps): ReactElement => {
  const [field, meta] = useField<string>(formikKey);
  const { name, onChange } = field;
  const { error, touched } = meta;
  const inputClass = classNames(
    {
      [styles.offset]: !label && !noOffset,
    },
    styles.input,
  );

  return (
    <>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <TextField
        className={inputClass}
        error={touched && !!error}
        helperText={meta ? error : ''}
        id={name}
        InputProps={{
          disableUnderline: true,
          inputProps: {
            max,
            min,
            onKeyPress,
            step,
            title: name,
            value: field.value || '',
          },
          placeholder,
        }}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        type="number"
        {...field}
        {...props}
      />
    </>
  );
};
