import React, { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';

// Components
import Button from 'components/Button/Button';

// Styles
import styles from './ContentBlueprintDropzone.module.scss';

// Store + Core
import { uploadBoardContentOutlines } from 'store/bct/actions';
import { isFileProcessingSelector } from 'store/bct/selectors';
import { FileTypesEnum } from 'core/enums';

// Types
interface IProps {
  onProceed(): void;
}

export const ContentBlueprintDropzone = (props: IProps): ReactElement => {
  const { onProceed } = props;
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const onDrop = useCallback(
    async (files: File[]): Promise<void> => {
      await dispatch(uploadBoardContentOutlines(id, { file: files }));

      // Close the modal when done.
      await onProceed();
    },
    [dispatch, id, onProceed],
  );

  const isFileProcessing: boolean = useSelector(isFileProcessingSelector);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ accept: FileTypesEnum.XLSX, onDrop });

  return (
    <Box component="div">
      <Grid container>
        <Grid item xs={12} className={styles.error} alignItems="center">
          <Box display="flex">
            <InfoRounded />
            <span className={styles.message}>Batch upload will overwrite any existing content blueprint.</span>
          </Box>
        </Grid>
      </Grid>
      <Box component="div" marginTop={4}>
        <Grid container justify="center">
          <Grid className={styles.dropzone} {...getRootProps()} id="transition-modal-description" item xs={12}>
            {isFileProcessing ? (
              <CircularProgress color="inherit" />
            ) : (
              <>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>
                    Drop your completed files here, or <span>choose file</span>
                  </p>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" flexDirection="row-reverse" marginTop={4}>
        <Button className="primary" onClick={onProceed}>
          Done
        </Button>
      </Box>
    </Box>
  );
};
