import { ITaxonomyTerm } from 'core/models';

/**
 * @function findTaxonomyNameById
 * @param taxonomyTree - Array of Taxonomy Terms
 * @param searchId - ID to search for
 * @returns matching term's name or undefined
 */
export const findTaxonomyNameById = (taxonomyTree: ITaxonomyTerm[] = [], searchId: string): string => {
  let result: string;
  taxonomyTree.some(({ id, name, terms = [] }: ITaxonomyTerm): string => {
    result = (id === searchId && name) || findTaxonomyNameById(terms, searchId);
    return result;
  });
  return result;
};
