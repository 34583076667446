import orderBy from 'lodash/orderBy';

// Core + Store
import { BoardsApiService } from 'core/boardsApiService';
import { IBoardSlim, IJsonPatchDocumentOperation } from 'core/models';
import { ServerRoute } from 'core/api';
import { API } from 'core';
import { batchActivityProgressAction } from 'store/activity/actions';

// Types
import { IBCTBoard } from '../layouts/pages/bct/types';

export class BoardService {
  private boardsApiService: BoardsApiService;
  private static boardApiService: BoardsApiService = new BoardsApiService();

  constructor() {
    this.boardsApiService = new BoardsApiService();
  }

  public async getAllBoardsAsync(): Promise<Array<IBoardSlim>> {
    return await this.boardsApiService.getAllBoardsAsync();
  }

  public static async patch(id: string, payload: IJsonPatchDocumentOperation[]): Promise<void> {
    await this.boardApiService.patchBoardAsync(id, payload);
  }

  public static async put(id: string, payload: Partial<IBCTBoard>): Promise<void> {
    await this.boardApiService.putBoardAsync(id, payload);
  }

  public async getBoards(): Promise<IBoardSlim[]> {
    const route: ServerRoute = ServerRoute.forAction('boards');
    return orderBy(await API.get(route), ['abbreviation']);
  }

  public async getBoardById(id: string): Promise<IBCTBoard> {
    const route: ServerRoute = ServerRoute.forAction('boards', `${id}`);
    return await API.get(route);
  }

  public async postBoardContentOutlines(id: string, payload: { file: File[] }): Promise<any> {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const route: ServerRoute = ServerRoute.forAction('Boards', `${id}/boardcontentoutlines/upload`);
    return await API.postFile(route, payload, batchActivityProgressAction, config);
  }
}
