import { IProgramSummary, PARSAction } from 'core/models';

export interface IProgramSummaryState {
  error?: string;
  isLoading: boolean;
  saved?: boolean;
  storedSummary: IProgramSummary;
}

export const GET_PROGRAM_SUMMARY = 'GET_PROGRAM_SUMMARY';
export const GET_PROGRAM_SUMMARY_SUCCESS = 'GET_PROGRAM_SUMMARY_SUCCESS';
export const GET_PROGRAM_SUMMARY_FAILURE = 'GET_PROGRAM_SUMMARY_FAILURE';
export const SAVE_PROGRAM_SUMMARY = 'SAVE_PROGRAM_SUMMARY';
export const SAVE_PROGRAM_SUMMARY_SUCCESS = 'SAVE_PROGRAM_SUMMARY_SUCCESS';
export const SAVE_PROGRAM_SUMMARY_FAILURE = 'SAVE_PROGRAM_SUMMARY_FAILURE';

export const REENABLE_PROGRAM_SUMMARY = 'REENABLE_PROGRAM_SUMMARY';
export const REENABLE_PROGRAM_SUMMARY_SUCCESS = 'REENABLE_PROGRAM_SUMMARY_SUCCESS';
export const REENABLE_PROGRAM_SUMMARY_FAILURE = 'REENABLE_PROGRAM_SUMMARY_FAILURE';

interface IGetProgramSummary extends PARSAction<undefined, typeof GET_PROGRAM_SUMMARY> {
  type: typeof GET_PROGRAM_SUMMARY;
}

interface IGetProgramSummaryFailure extends PARSAction<string, typeof GET_PROGRAM_SUMMARY_FAILURE> {
  payload: string;
  type: typeof GET_PROGRAM_SUMMARY_FAILURE;
}

interface IGetProgramSummarySuccess extends PARSAction<IProgramSummary, typeof GET_PROGRAM_SUMMARY_SUCCESS> {
  payload: IProgramSummary;
  type: typeof GET_PROGRAM_SUMMARY_SUCCESS;
}

interface ISaveProgramSummary extends PARSAction<IProgramSummary, typeof SAVE_PROGRAM_SUMMARY> {
  payload: IProgramSummary;
  type: typeof SAVE_PROGRAM_SUMMARY;
}

interface ISaveProgramSummaryFailure extends PARSAction<string, typeof SAVE_PROGRAM_SUMMARY_FAILURE> {
  payload: string;
  type: typeof SAVE_PROGRAM_SUMMARY_FAILURE;
}

interface ISaveProgramSummarySuccess extends PARSAction<undefined, typeof SAVE_PROGRAM_SUMMARY_SUCCESS> {
  type: typeof SAVE_PROGRAM_SUMMARY_SUCCESS;
}

interface IReEnableProgramSummary extends PARSAction<undefined, typeof REENABLE_PROGRAM_SUMMARY> {
  type: typeof REENABLE_PROGRAM_SUMMARY;
}

interface IReEnableProgramSummarySuccess extends PARSAction<undefined, typeof REENABLE_PROGRAM_SUMMARY_SUCCESS> {
  type: typeof REENABLE_PROGRAM_SUMMARY_SUCCESS;
}

interface IReEnableProgramSummaryFailure extends PARSAction<undefined, typeof REENABLE_PROGRAM_SUMMARY_FAILURE> {
  type: typeof REENABLE_PROGRAM_SUMMARY_FAILURE;
}

export type ProgramSummaryActionTypes =
  | IGetProgramSummary
  | IGetProgramSummaryFailure
  | IGetProgramSummarySuccess
  | ISaveProgramSummary
  | ISaveProgramSummaryFailure
  | ISaveProgramSummarySuccess
  | IReEnableProgramSummary
  | IReEnableProgramSummarySuccess
  | IReEnableProgramSummaryFailure;
