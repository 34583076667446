import { AxiosResponse } from 'axios';
import { API } from 'core';
import { ServerRoute } from 'core/api';
import { IFileDownload } from 'core/models';
import { uploadProgressAction } from 'store/search-synonym/actions';

const controller = 'SearchAdmin';

export class SynonymFileService {
  public static async download(mapName: string): Promise<IFileDownload> {
    const route: ServerRoute = ServerRoute.forAction(controller, `synonyms/${mapName}/download`);
    const result: AxiosResponse = await API.getFullResponse(route, undefined, { responseType: 'blob' });
    const disposition = result.headers['content-disposition'];
    const filename = disposition && /filename=(.*);/.exec(disposition)[1];

    return {
      disposition,
      file: result.data,
      filename,
      type: result.headers['content-type'],
    };
  }

  public static async upload(mapName: string, payload: { file: File }): Promise<void> {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const route: ServerRoute = ServerRoute.forAction(controller, `synonyms/${mapName}`);
    return await API.postFile<void>(route, payload, uploadProgressAction, config);
  }
}
