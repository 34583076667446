import orderBy from 'lodash/orderBy';
import { IBCTBoard } from 'layouts/pages/bct/types';

export type ICreditTypeDetails = Pick<IBCTBoard, 'creditTypes'>;

export const initialCreditTypeDetailsForm = (bctBoard: IBCTBoard): ICreditTypeDetails | unknown => {
  if (!bctBoard) return {};
  const { creditTypes } = bctBoard;
  return {
    creditTypes: orderBy(creditTypes, ['sortOrder'], ['asc']),
  };
};
