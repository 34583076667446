import { ReactElement } from 'react';

// Components
import Button from 'components/Button/Button';

// Styles
import styles from '../../detail.module.scss';

// Types
import { ButtonVariant } from 'core/enums';

interface IProps {
  onClick(x: number): void;
}
export const EditButton = (props: IProps): ReactElement => {
  const { onClick } = props;

  return (
    <div className={styles.secondary}>
      <Button variant={ButtonVariant.Secondary} onClick={() => onClick(3)} role="navigation">
        Edit
      </Button>
    </div>
  );
};
