import * as yup from 'yup';

export const buildProviderSchema = (): yup.AnyObjectSchema => {
  return yup.object({
    provider: yup.mixed().required('Provider is a required field').typeError('Invalid Provider format'),
  });
};

export const buildWebServiceAccountSchema = (): yup.AnyObjectSchema => {
  return yup.object({
    firstName: yup.string().required('First Name is a required field').typeError('Invalid First Name format'),
    lastName: yup.string().required('Last Name is a required field').typeError('Invalid Last Name format'),
  });
};

export const buildFinalFormSchema = (): yup.AnyObjectSchema => {
  const requirementString =
    'Password must have: \n at least 6 characters \n one lowercase \n one uppercase \n one number \n and one symbol';
  return yup.object({
    email: yup.string().email('Invalid Email format').required('Email is a required field'),
    password: yup
      .string()
      .required('Password is a required field')
      .typeError('Invalid Password format')
      .min(6, requirementString)
      .test('isValidPassword', requirementString, (value) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasSymbol = /[!@#$%^&*]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 4;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbol];
        conditions.forEach((condition) => (condition ? validConditions++ : null));
        return validConditions >= numberOfMustBeValidConditions;
      }),
  });
};
