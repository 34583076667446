// Libs
import { isBoolean, keys, omit } from 'lodash';

// Core
import {
  ActivityFormStateValues,
  HasStateContentTags,
  HasPharmacyContentTags,
  HasPharmacyRecertifications,
  IDictionary,
} from 'core/models';

// Utils
import { buildNewActivity, transformSupportedRemsIdsForSubmit } from 'layouts/pages/activity/add-activity/utils';
import { transformBoardMocDetails } from '../transformBoardMocDetails';
import { transformCommercialSupportSources } from 'layouts/pages/activity/utils';

export const transformPayloadValues = (values: ActivityFormStateValues) => {
  const {
    boardMocDetails,
    credits,
    commercialMonetarySupportSources,
    commercialInKindSupportSources,
    contentTags,
    hasCommercialSupport,
    includeInCmeFinder,
    isIpce,
    isJointlyProvided,
    isMips,
    isMoc,
    isPharmacyCertificateProgram,
    isRems,
    isRemsCompliant,
    isRestrictedAudience,
    isTargetAudiencePharmacy,
    isTargetAudiencePharmacyTech,
    jointProviders,
    locationType,
    markedForCommendationCriteria,
    mocProgramAttestation,
    pharmacyRecertificationTaxonomyTerms,
    pharmacyContentTagTaxonomyTerms,
    supportedRemsIds,
    hasPharmacyContentTags,
    hasPharmacyRecertifications,
    hasStateContentTags,
  } = values;
  const creditsFilterList: string[] = keys(credits).filter((entry: string): boolean => isBoolean(credits[entry]));
  const transformedValues = {
    ...values,
    boardMocDetails: transformBoardMocDetails(boardMocDetails),
    commercialSupportSources: transformCommercialSupportSources(
      commercialMonetarySupportSources,
      commercialInKindSupportSources,
    ),
    credits: omit(credits, creditsFilterList) as IDictionary<number>,
    hasCommercialSupport: hasCommercialSupport?.includes('true'),
    contentTags: contentTags && contentTags.length > 0 ? contentTags : [],
    hasStateContentTags: hasStateContentTags?.endsWith('1') ? HasStateContentTags.Yes : HasStateContentTags.No,
    hasPharmacyContentTags: hasPharmacyContentTags?.endsWith('1')
      ? HasPharmacyContentTags.Yes
      : hasPharmacyContentTags?.endsWith('0')
      ? HasPharmacyContentTags.No
      : HasPharmacyContentTags.NotSure,
    hasPharmacyRecertifications: hasPharmacyRecertifications?.endsWith('1')
      ? HasPharmacyRecertifications.Yes
      : hasPharmacyRecertifications?.endsWith('0')
      ? HasPharmacyRecertifications.No
      : HasPharmacyRecertifications.NotSure,
    includeInCmeFinder: includeInCmeFinder === 'includeInCmeFinder-true',
    isIpce: isIpce?.includes('true'),
    isJointlyProvided: isJointlyProvided?.includes('join'),
    isMips: isMips?.includes('true'),
    isMoc: isMoc?.includes('true'),
    isPharmacyCertificateProgram: isPharmacyCertificateProgram?.includes('true'),
    isRems: isRems?.includes('true'),
    isRemsCompliant: isRemsCompliant?.includes('isRemsCompliant'),
    isRestrictedAudience: isRestrictedAudience === '' ? null : isRestrictedAudience?.includes('limited'),
    isTargetAudiencePharmacy: isTargetAudiencePharmacy?.includes('isTargetAudiencePharmacy'),
    isTargetAudiencePharmacyTech: isTargetAudiencePharmacyTech?.includes('isTargetAudiencePharmacyTech'),
    jointProviders: jointProviders?.filter((entry: string): boolean => !!entry?.length) || [],
    locationType: locationType || [],
    markedForCommendationCriteria: markedForCommendationCriteria?.includes('true'),
    mocProgramAttestation: mocProgramAttestation?.includes('mocProgramAttestation'),
    pharmacyContentTagTaxonomyTerms:
      pharmacyContentTagTaxonomyTerms && pharmacyContentTagTaxonomyTerms?.length > 0
        ? pharmacyContentTagTaxonomyTerms
        : [],
    pharmacyRecertificationTaxonomyTerms:
      pharmacyRecertificationTaxonomyTerms && pharmacyRecertificationTaxonomyTerms?.length > 0
        ? pharmacyRecertificationTaxonomyTerms
        : [],
    supportedRemsIds: transformSupportedRemsIdsForSubmit(supportedRemsIds),
  };
  return buildNewActivity(transformedValues);
};
