import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Box, Grid, Typography } from '@material-ui/core';
import { InfoRounded, KeyboardBackspace, SaveAltRounded } from '@material-ui/icons';
import classNames from 'classnames';
import { Field, FormikProvider, useFormik } from 'formik';

import Button from 'components/Button/Button';
import { Modal } from 'components/ContinuousImprovement/Modal';
import { FileUploader } from 'components/global/fileUploader';
import { SYNONYM_UPLOAD_FILE_TYPES } from 'core/constants';
import { isModalOpenSelector } from 'store/modal/selectors';
import { closeModal, openModal } from 'store/modal/actions';
import { clearUploadedAction, downloadSynonymFile, uploadSynonymFile } from 'store/search-synonym/actions';
import { SynonymMapName, SYNONYM_MAP_FIRST_NAME } from 'store/search-synonym/types';
import { uploadFileSelector, uploadPercentageSelector } from 'store/search-synonym/selectors';

// Styles
import styles from './SearchSynonymsStyles.module.scss';

export const SearchSynonymsPage = (): ReactElement => {
  // TODO: when we have multiple maps, use a select to have the user pick the one to use, or maybe show a list or something?
  const currentMap = {
    name: SYNONYM_MAP_FIRST_NAME as SynonymMapName,
    title: 'First Names',
  };

  const dispatch = useDispatch();
  const isModalOpen: boolean = useSelector(isModalOpenSelector);
  const handleDownload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    event.preventDefault();
    dispatch(downloadSynonymFile(currentMap.name));
  };

  // Redux State.
  const uploadPercentage: number = useSelector(uploadPercentageSelector);
  const uploadedFile = useSelector(uploadFileSelector);

  const onClearFile = (): void => {
    dispatch(clearUploadedAction());
  };
  const onClose = () => {
    dispatch(closeModal());
    onClearFile();
  };

  const formik = useFormik<{ file?: File }>({
    initialValues: {},
    onSubmit: async (values): Promise<void> => {
      // API call - upload file.
      if (await dispatch(uploadSynonymFile(currentMap.name, values.file))) {
        onClose();
      }
    },
  });
  const { handleSubmit } = formik;

  return (
    <>
      <div className={classNames(styles.container)}>
        <div>
          <Link className={styles.link} to="/admin">
            <KeyboardBackspace /> Admin
          </Link>
        </div>

        <h3>Synonyms: {currentMap.title}</h3>
        <a
          download
          onClick={handleDownload}
          href={`${currentMap.name}.xlsx`}
          role="button"
          style={{ display: 'inline-block' }}
        >
          <SaveAltRounded />
          &nbsp; Download current synonyms
        </a>

        <Box display="flex">
          <Button className="primary" onClick={() => dispatch(openModal())}>
            Upload replacement list of synonyms
          </Button>
        </Box>

        <Modal isOpen={isModalOpen} onClose={onClose} title={`Upload "${currentMap.title}" Synonyms`}>
          <Box component="div">
            <Grid container>
              <Grid item xs={12} className={styles.error} alignItems="center">
                <Box display="flex">
                  <InfoRounded />
                  <span className={styles.message}>
                    Upload will overwrite all existing "{currentMap.title}" synonyms.
                  </span>
                </Box>
              </Grid>
            </Grid>
            <Box component="div" marginTop={4}>
              <FormikProvider value={formik}>
                <form encType="multipart/form-data" onSubmit={handleSubmit}>
                  <Field
                    component={FileUploader}
                    fileTypes={SYNONYM_UPLOAD_FILE_TYPES}
                    id="batchLearnerValidationFile"
                    name="file"
                    showDropZoneSubTitle={false}
                    onClearFile={onClearFile}
                    uploadedFile={uploadedFile}
                    uploadPercentage={uploadPercentage}
                  />
                  <Typography variant="srOnly">
                    <button tabIndex={-1}>Submit</button>
                  </Typography>
                </form>
              </FormikProvider>
            </Box>
            <Box display="flex" flexDirection="row-reverse" marginTop={4}>
              <Button className="primary" onClick={onClose}>
                Done
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};
