import { makeStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
      maxWidth: '650px',
    },
  }),
);
