import { StatusEnum } from 'core/enums';

// Types
interface IStatus {
  isProvider: boolean;
  statusTitle: StatusEnum;
}

/**
 * @function calculateStatusTitle
 * @param isProvider
 * @param statusTitle
 * @description We show `Submitted` as a status if the user is a Provider instead of `Not Processed`.
 *              A Provider user should never see `Not Processed`. `Submitted` can also be a value as a facet
 *              for providers, but this is fake (not queryable) so we need to return `null` in that case.
 */
export const calculateStatusTitle = ({ isProvider, statusTitle }: IStatus): StatusEnum => {
  if (isProvider && statusTitle === StatusEnum.NOT_PROCESSED) {
    return StatusEnum.SUBMITTED;
  }
  if (isProvider && statusTitle === StatusEnum.DELETE_NOT_PROCESSED) {
    return StatusEnum.DELETE_SUBMITTED;
  }

  if (isProvider && statusTitle === StatusEnum.SUBMITTED) {
    return null;
  }

  return statusTitle;
};
