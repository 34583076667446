import * as PARS from '../../core';

export interface TaxonomyState {
  terms: { [id: string]: PARS.Models.ITaxonomyTerm };
}

export const GET_TAXONOMY_TERM_BY_ID = 'GET_TAXONOMY_TERM_BY_ID';
export const GET_TAXONOMY_TERM_BY_ID_SUCCESS = 'GET_TAXONOMY_TERM_BY_ID_SUCCESS';
export const GET_TAXONOMY_TERM_BY_ID_FAILURE = 'GET_TAXONOMY_TERM_BY_ID_FAILURE';

interface GetTaxonomyTermById extends PARS.Models.PARSAction<undefined, typeof GET_TAXONOMY_TERM_BY_ID> {
  type: typeof GET_TAXONOMY_TERM_BY_ID;
}

interface GetTaxonomyTermByIdSuccess
  extends PARS.Models.PARSAction<PARS.Models.ITaxonomyTerm, typeof GET_TAXONOMY_TERM_BY_ID_SUCCESS> {
  type: typeof GET_TAXONOMY_TERM_BY_ID_SUCCESS;
}

interface GetTaxonomyTermByIdFailure extends PARS.Models.PARSAction<Error, typeof GET_TAXONOMY_TERM_BY_ID_FAILURE> {
  type: typeof GET_TAXONOMY_TERM_BY_ID_FAILURE;
}

export type TaxonomyActionTypes = GetTaxonomyTermById | GetTaxonomyTermByIdSuccess | GetTaxonomyTermByIdFailure;
