import { InputLabel } from '@material-ui/core';
import classNames from 'classnames';
import { Field, FormikProvider, useFormik } from 'formik';
import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components.
import Button from 'components/Button/Button';
import DatePicker from 'components/DatePicker/DatePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import { FormikTextField } from 'components/forms/FormikTextField';
import InputBlock from 'components/global/inputBlock/inputBlock';

// Core.
import { ButtonVariant } from 'core/enums';
import { IActivityReviewHistory } from 'core/models';

// Store.
import { editActivityReviewOutcome } from 'store/activity/actions';
import { activityReviewOutcomesSelector } from 'store/taxonomy/selectors';

// Styles.
import styles from './EditOutcomeForm.module.scss';

// Validations.
import { validationSchema } from './validationSchema';

interface IEditOutcomeFormProps {
  entry: IActivityReviewHistory;
  id: string;
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
}

export const EditOutcomeForm = (props: IEditOutcomeFormProps): ReactElement => {
  const { entry, id, onClose, onSuccess } = props;
  const dispatch = useDispatch();
  const reviewOutcomes = useSelector(activityReviewOutcomesSelector);
  const formik = useFormik({
    initialValues: {
      outcomeDate: entry.outcomeDate,
      outcomeDetails: entry.outcomeDetails,
      outcomeId: entry.outcomeId,
    },
    onSubmit: async (values) => {
      await dispatch(editActivityReviewOutcome(id, entry.id, values));
      onSuccess?.();
    },
    validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <section className={styles.section}>
          {/* Outcome */}
          <div>
            <InputLabel>
              Review Outcome <span className={styles.required}>*</span>
            </InputLabel>
            <InputBlock name="outcomeId">
              <Dropdown
                className={styles.input}
                fullWidth
                items={reviewOutcomes}
                name="outcomeId"
                placeholder="Select Outcome"
              />
            </InputBlock>
          </div>

          {/* Outcome Date */}
          <div>
            <InputLabel>
              Outcome Date <span className={styles.required}>*</span>
            </InputLabel>
            <InputBlock name="outcomeDate">
              <Field name="outcomeDate">
                {({ field }) => (
                  <DatePicker
                    className={styles.input}
                    field={field}
                    formikKey="outcomeDate"
                    fullWidth
                    timeOfDay="midnight"
                  />
                )}
              </Field>
            </InputBlock>
          </div>

          {/* Outcome Details */}
          <div>
            <InputLabel>Outcome Details</InputLabel>
            <FormikTextField
              className={classNames(styles.input, styles.full)}
              formikKey="outcomeDetails"
              fullWidth
              multiline
              placeholder="Please fill in the outcome details"
              rows={8}
              variant="outlined"
            />
          </div>

          <footer className={styles.footer}>
            <Button onClick={onClose} variant={ButtonVariant.Secondary}>
              Cancel
            </Button>
            <Button
              disabled={formik.isSubmitting}
              isSubmitting={formik.isSubmitting}
              type="submit"
              variant={ButtonVariant.Primary}
            >
              Submit
            </Button>
          </footer>
        </section>
      </form>
    </FormikProvider>
  );
};
