import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

// Core + Store
import { AppState } from 'store';
import { TaxonomyService } from 'services/TaxonomyService';
import { handleServerError } from 'globals/utils/handleServerError';
import { ITaxonomyDictionary, ITaxonomyTerm, PARSAction } from 'core/models';

// Types
import { GET_TAXONOMY_TERM_BY_ID, GET_TAXONOMY_TERM_BY_ID_SUCCESS, GET_TAXONOMY_TERM_BY_ID_FAILURE } from './types';

export const getTaxonomyTermByIdAction = (id: string): AnyAction => {
  return {
    payload: { id },
    type: GET_TAXONOMY_TERM_BY_ID,
  };
};

export const getTaxonomyTermByIdSuccessAction = (payload: ITaxonomyDictionary): AnyAction => {
  return {
    payload: payload,
    type: GET_TAXONOMY_TERM_BY_ID_SUCCESS,
  };
};

export const getTaxonomyTermByIdFailureAction = (payload: Error): AnyAction => {
  return {
    payload: payload,
    type: GET_TAXONOMY_TERM_BY_ID_FAILURE,
  };
};

export const getTaxonomyTermById = (id: string): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
) => {
  await dispatch(getTaxonomyTermByIdAction(id));
  try {
    const taxonomyService = new TaxonomyService();
    const term: ITaxonomyTerm = await taxonomyService.getByIdAsync(id);
    const result: ITaxonomyDictionary = {};
    result[id] = term;
    await dispatch(getTaxonomyTermByIdSuccessAction(result));
  } catch (error) {
    handleServerError({ error, thunkName: 'getTaxonomyTermById' });
    await dispatch(getTaxonomyTermByIdFailureAction(error));
  }
};
