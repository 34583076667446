import { Skeleton } from '@material-ui/lab';
import { Fragment, ReactElement } from 'react';

// Styles
import styles from '../Summary/LearnerResultCard/learnerresultcard.module.scss';

export const LoadingCards = ({ count = 6 }: { count?: number }): ReactElement => {
  return (
    <>
      {[...Array(count).keys()].map(
        (_: number): ReactElement => (
          <Fragment key={_}>
            <div className={styles['learner-results-card']}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </div>
          </Fragment>
        ),
      )}
    </>
  );
};
