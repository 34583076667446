import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const ToggleSwitch = withStyles({
  switchBase: {
    color: '#373737',
    '&$checked': {
      color: '#00aea9',
    },
    '&$checked + $track': {
      backgroundColor: '#00aea9',
    },
    '&.Mui-disabled': {
      color: '#bdbdbd',
    },
    '&.Mui-disabled + $track': {
      backgroundColor: '#000',
    },
  },
  checked: {},
  track: {},
})(Switch);
