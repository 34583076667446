import { ReactElement } from 'react';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Box, Fab, Grid, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import get from 'lodash/get';

// Components
import { FormikInputField, FormikNumberField } from 'components/ContinuousImprovement/PureFormElements';

// Styles
import styles from 'components/TextboxRepeater/TextboxRepeater.module.scss';

// Types
import { IBCTSpecialty } from 'core/models';
interface IProps {
  disabled?: boolean;
  formikKey: string;
  labelPrompt?: string;
}
export const PracticeAreasRepeater = (props: IProps): ReactElement => {
  const { disabled, formikKey, labelPrompt } = props;
  const { setFieldTouched, values } = useFormikContext();
  const formikAccess: IBCTSpecialty[] = get(values, formikKey);

  return (
    <FieldArray
      name={formikKey}
      render={(arrayHelpers: FieldArrayRenderProps): ReactElement => {
        return (
          <Grid container spacing={3}>
            {formikAccess?.map(
              (_: IBCTSpecialty, idx: number): ReactElement => {
                return (
                  <Grid item component={Box} display="flex" key={idx}>
                    <Box>
                      <FormikInputField
                        formikKey={`${formikKey}.${idx}.name`}
                        label={labelPrompt}
                        name={`${formikKey}.${idx}.name`}
                      />
                    </Box>
                    <Grid component={Box} ml={2} sm={3}>
                      <FormikNumberField
                        formikKey={`${formikKey}.${idx}.sortOrder`}
                        label="Sort Order"
                        min={1}
                        step={1}
                      />
                    </Grid>
                    <Box component={Box} alignSelf="center" mt={3} ml={2}>
                      <IconButton aria-label="remove" onClick={(): void => arrayHelpers.remove(idx)} size="small">
                        <ClearIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  </Grid>
                );
              },
            )}
            <Box display="flex" pt={2} pb={2}>
              <Box mr={2}>
                <Fab
                  aria-label="add another instance"
                  className={classNames(
                    {
                      [styles.disabled]: disabled,
                    },
                    styles['add-btn'],
                  )}
                  disabled={disabled}
                  onClick={(): void => {
                    arrayHelpers.push({ sortOrder: formikAccess.length + 1 });
                    setFieldTouched(formikKey, true);
                  }}
                  size="small"
                >
                  <AddIcon color="inherit" />
                </Fab>
              </Box>
              <Box pt={0.5}>Add Another Instance</Box>
            </Box>
          </Grid>
        );
      }}
    />
  );
};
