import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment/moment';
import { FormikProvider, useFormik } from 'formik';

// Components
import Button from 'components/Button/Button';

// Styles
import styles from '../UpdateLearnersForm/index.module.scss';

// Store + Core
import { Activity } from 'core/models';
import { activityDetailSelector } from 'store/activity/selectors';
import { clearActivityDetail, getActivity } from 'store/activity/actions';

// Types
type IProps = {
  activityId: string;
  id: string;
  title: string;
  callback?(): void;
  endDate: Date;
  onClose?(): void;
  startDate: Date;
};
export const CopyActivityForm = (props: IProps): ReactElement => {
  const dispatch = useDispatch();
  const { activityId, callback, endDate, startDate, title, id, onClose } = props;

  // Create a user friendly date string.
  const dates = `${moment(startDate).format('LL')} - ${moment(endDate).format('LL')}`;

  // Get the active activityDetail
  const activityDetail: Activity = useSelector(activityDetailSelector);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (_, { setSubmitting }) => {
      // We are done submitting.
      await setSubmitting(false);

      // Close the modal.
      await onClose?.();

      // Callback.
      await callback?.();
    },
  });

  const { handleSubmit, isSubmitting } = formik;

  // Setup.
  useEffect(() => {
    if (!activityDetail) {
      // Get the current activity.
      dispatch(getActivity(id));
    }
  }, [activityDetail, dispatch, id]);

  // Unmount.
  useEffect(() => {
    // Clear the activity details on unmount.
    return () => dispatch(clearActivityDetail());
  }, [dispatch]);

  // Wait to have data before rendering anything.
  if (!activityDetail)
    return (
      <div className={styles['loading-wrapper']}>
        <CircularProgress />
      </div>
    );

  return (
    <>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{`Activity ID ${activityId} | ${dates}`}</p>
      {activityDetail.uanPharmacy && <p className={styles.description}>UAN: {activityDetail.uanPharmacy}</p>}
      {activityDetail.uanPharmacyTech && <p className={styles.description}>UAN: {activityDetail.uanPharmacyTech}</p>}
      <>
        <div className={styles['sub-heading']}>Are you sure you want to copy this activity?</div>
        <FormikProvider value={formik}>
          <form onSubmit={handleSubmit}>
            <div className="modal-button-row">
              <Button className="primary" disabled={isSubmitting} type="submit">
                Yes, Copy
              </Button>
              <Button className="secondary" disabled={isSubmitting} onClick={onClose}>
                No, Don't Copy
              </Button>
            </div>
          </form>
        </FormikProvider>
      </>
    </>
  );
};
