import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { FilterListRounded } from '@material-ui/icons';

// Components
import { FacetSearch } from 'components/ContinuousImprovement/Facets';
import { LearnerResultCard } from 'components/Summary/LearnerResultCard';
import BulkActions from 'components/Summary/BulkActions';
import { NoResultsCard } from 'components/NoResultsCard';
import Button from 'components/Button/Button';
import { LoadingCards } from 'components/LoadingCard';
import { SearchPagination } from 'components/SearchPagination';

// Hooks.
import { useClickEvent, useLoadEvent, useQueryString } from 'hooks';

// Core + Store
import {
  exportAllLearnerActivities,
  getLearnerActivities,
  onUpdateLearnerActivitiesPaginationState,
} from 'store/learner/actions';
import {
  searchLeanerRequestSelector,
  searchLeanerResponseSelector,
  searchLeanerResultsLoadingSelector,
  searchLeanerResultsSelector,
  searchLeanerTotalCountSelector,
  searchLearnerCurrentPageSelector,
} from 'store/learner/selectors';
import {
  SORT_BY_A_Z_TITLE,
  SORT_BY_ACCEPTED,
  SORT_BY_ACTIVITY_ID,
  SORT_BY_ACTIVITY_START_DATE,
  SORT_BY_CLAIM_DATE,
  SORT_BY_END_DATE,
  SORT_BY_INTERNAL_ID,
  SORT_BY_LEARNER,
  SORT_BY_RECENTLY_ADDED,
  SORT_BY_REJECTED,
  SORT_BY_SUBMITTED,
  SORT_BY_UAN,
  SORT_BY_UAN_TECHNICIAN,
  TAXONOMY_ACTIVITY_CREDIT_ROOT_ID,
} from 'core/constants';
import { rollupOrganizationEnumSelector, organizationKindSelector } from 'store/user/selectors';
import {
  IActivityLearnerSearchRequest,
  IActivityLearnerSearchResponse,
  ILearnerActivity,
  ITaxonomyTerm,
  OrganizationKinds,
} from 'core/models';

// Data + Helpers
import { INITIAL_LEARNER_ACTIVITY_SEARCH_FACETS } from 'components/ContinuousImprovement/Facets/FacetSearch/constants';
import { perPageMenuItems } from './perPageMenuItems';
import { DOWNLOAD_ALL_ACTIVITIES, DOWNLOAD_FILTERED_ACTIVITIES, exportMenuItems } from './exportMenuItems';
import { sortLearnerMenu } from './sortMenuItems';
import { closeRail, openRail } from 'store/rail/actions';
import { isRailOpenSelector } from 'store/rail/selectors';

// Utils
import { getPaginationCount } from 'globals/utils/getPaginationCount';

// Types
import { SearchRequest } from 'components/ContinuousImprovement/Facets/types';
import { amaCreditTermIdSelector } from 'store/taxonomy/selectors';
import { getTaxonomyTermById } from 'store/taxonomy/actions';

export const LearnerSummaryPage = (): ReactElement => {
  const dispatch = useDispatch();
  const urlSearchPayload = useQueryString<SearchRequest>();

  const fireLoadEvent = useLoadEvent({ PageName: 'Learner Summary', PageType: 'Learner' });
  const fireDownloadEvent = useClickEvent({ Event: 'Learner', EventCategory: 'Download' });

  // Redux states.
  const currentPage = useSelector(searchLearnerCurrentPageSelector);
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  const isLoading: boolean = useSelector(searchLeanerResultsLoadingSelector);
  const learnerActivities: ILearnerActivity[] = useSelector(searchLeanerResultsSelector);
  const searchLeanerTotalCount: number = useSelector(searchLeanerTotalCountSelector);
  const searchRequest: IActivityLearnerSearchRequest = useSelector(searchLeanerRequestSelector);
  const searchResponse: IActivityLearnerSearchResponse = useSelector(searchLeanerResponseSelector);
  const isFacetsOpen: boolean = useSelector(isRailOpenSelector);
  const amaCreditTerm: ITaxonomyTerm = useSelector(amaCreditTermIdSelector);
  const organization = useSelector(organizationKindSelector);
  const organizationKind = organization?.organizationKind;

  // Sorting functions.
  const perPage = (top: number) => dispatch(getLearnerActivities({ top }));
  const sortByActivityId = () => dispatch(getLearnerActivities({ sortBy: SORT_BY_ACTIVITY_ID }));
  const sortByClaimDate = () => dispatch(getLearnerActivities({ sortBy: SORT_BY_CLAIM_DATE }));
  const sortByEndDate = () => dispatch(getLearnerActivities({ sortBy: SORT_BY_END_DATE }));
  const sortByInternalId = () => dispatch(getLearnerActivities({ sortBy: SORT_BY_INTERNAL_ID }));
  const sortByStartDate = () => dispatch(getLearnerActivities({ sortBy: SORT_BY_ACTIVITY_START_DATE }));
  const sortByRejectedCount = () => dispatch(getLearnerActivities({ sortBy: SORT_BY_REJECTED }));
  const sortByAcceptedCount = () => dispatch(getLearnerActivities({ sortBy: SORT_BY_ACCEPTED }));
  const sortBySubmittedCount = () => dispatch(getLearnerActivities({ sortBy: SORT_BY_SUBMITTED }));
  const sortByLearnerCount = () => dispatch(getLearnerActivities({ sortBy: SORT_BY_LEARNER }));
  const sortByTimestamp = () => dispatch(getLearnerActivities({ sortBy: SORT_BY_RECENTLY_ADDED }));
  const sortByTitle = () => dispatch(getLearnerActivities({ sortBy: SORT_BY_A_Z_TITLE }));
  const sortByUAN = () => dispatch(getLearnerActivities({ sortBy: SORT_BY_UAN }));
  const sortByUANTechnician = () => dispatch(getLearnerActivities({ sortBy: SORT_BY_UAN_TECHNICIAN }));
  const toggleSortAscending = () => dispatch(getLearnerActivities({ sortAscending: !searchRequest.sortAscending }));

  // Pagination functions.
  const goToPage = (_, newPage: number) => {
    window.scroll(0, 0);
    dispatch(onUpdateLearnerActivitiesPaginationState({ page: newPage }));
  };

  // Export functions.
  const onDownloadAllActivities = useCallback(() => {
    fireDownloadEvent({ EventAction: DOWNLOAD_ALL_ACTIVITIES });
    dispatch(exportAllLearnerActivities({}));
  }, [dispatch, fireDownloadEvent]);

  const onDownloadFilteredActivities = useCallback(() => {
    fireDownloadEvent({ EventAction: DOWNLOAD_FILTERED_ACTIVITIES });
    dispatch(exportAllLearnerActivities(searchRequest));
  }, [dispatch, fireDownloadEvent, searchRequest]);

  useEffect(() => {
    if (!searchResponse || !learnerActivities) {
      dispatch(getLearnerActivities(urlSearchPayload, false));
    }
  }, [dispatch, searchResponse, learnerActivities, urlSearchPayload]);

  useEffect(() => {
    // If missing AMA Term Taxonomy, fetch credit terms
    if (!amaCreditTerm) {
      dispatch(getTaxonomyTermById(TAXONOMY_ACTIVITY_CREDIT_ROOT_ID));
    }
  }, [dispatch, amaCreditTerm]);

  // Show this when we're waiting for results.
  const isPending = useMemo(() => isLoading && <LoadingCards />, [isLoading]);

  // Show this when we have results.
  const hasResults =
    !!learnerActivities?.length &&
    learnerActivities.map(
      (learnerActivity: ILearnerActivity): ReactElement => (
        <LearnerResultCard key={learnerActivity.id} learnerActivity={learnerActivity} amaCreditTerm={amaCreditTerm} />
      ),
    );

  // Show this when we don't have any results.
  const noResults: ReactElement = !learnerActivities?.length && <NoResultsCard />;

  // Pagination count selected.
  const paginationCount: number = getPaginationCount({
    perPageCount: searchRequest.top,
    totalCount: searchLeanerTotalCount,
  });

  useEffect(() => {
    if (!isPending) {
      fireLoadEvent();
    }
  }, [fireLoadEvent, isPending]);

  return (
    <>
      <aside className={classNames('rail-container rail-container--full', { open: isFacetsOpen })}>
        <FacetSearch
          handleClose={() => dispatch(closeRail())}
          initialValues={{ ...INITIAL_LEARNER_ACTIVITY_SEARCH_FACETS, ...searchRequest, ...urlSearchPayload }}
        />
      </aside>
      <section className="summary-container">
        <div className="form-title-container">
          <h4 className="title">Learner Summary by Activity</h4>
        </div>

        {/* Render the facets for mobile. */}
        <div className="summary-refiners mobile">
          <Button className="btn-addfilter" onClick={() => dispatch(openRail())}>
            <FilterListRounded /> Add Filters
          </Button>
        </div>

        {/* Filters the main content. */}
        <BulkActions
          count={searchLeanerTotalCount}
          countLabel={`${searchLeanerTotalCount === 1 ? 'Activity' : 'Activities'}`}
          downloadMenuProps={exportMenuItems({
            onDownloadAllActivities,
            onDownloadFilteredActivities,
          })}
          hasDownloadMenu
          isSingleOrNoResult={searchLeanerTotalCount < 2}
          isNoResults={searchLeanerTotalCount < 1}
          perPageMenuProps={perPageMenuItems({ perPage, searchRequest })}
          sortAscending={searchRequest.sortAscending}
          sortAscendingAction={toggleSortAscending}
          sortMenuProps={sortLearnerMenu({
            isBoard: organizationKind === OrganizationKinds.BOARD,
            rollupOrganizationEnum,
            sortBy: searchRequest.sortBy,
            sortByAcceptedCount,
            sortByActivityId,
            sortByClaimDate,
            sortByEndDate,
            sortByInternalId,
            sortByLearnerCount,
            sortByRejectedCount,
            sortByStartDate,
            sortBySubmittedCount,
            sortByTimestamp,
            sortByTitle,
            sortByUAN,
            sortByUANTechnician,
          })}
        />

        {/* Render the main content. */}
        {isPending || hasResults || noResults}

        {/* Pagination */}
        <SearchPagination
          paginationCount={paginationCount}
          currentPage={currentPage}
          goToPage={goToPage}
          totalCount={searchLeanerTotalCount}
        />
      </section>
    </>
  );
};
