import { InputLabel } from '@material-ui/core';
import { Field, FormikProvider, useFormik } from 'formik';
import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';

// Components
import Button from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';
import InputBlock from 'components/global/inputBlock/inputBlock';

// Styles.
import styles from '../AdminPopupStyle/AdminPopupStyle.module.scss';

// Core + Store
import { ButtonVariant } from 'core/enums';
import { processActivity } from 'store/activity/actions';
import { useAdminCommandMocCTypeBoardList } from 'hooks';

// Validations.
import { validationSchema } from './ValidationSchema';

interface IQueueProcessActivityFormProps {
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  activityIds: number;
}

export const ProcessActivityForm = (props: IQueueProcessActivityFormProps): ReactElement => {
  const { activityIds, onClose, onSuccess } = props;
  const BoardList = useAdminCommandMocCTypeBoardList();
  const ActionTypes = [
    { id: 'Add', name: 'Add' },
    { id: 'Update', name: 'Update' },
    { id: 'Delete', name: 'Delete' },
  ];
  const StatusCode = [
    { id: 'A', name: 'Accept' },
    { id: 'R', name: 'Reject' },
  ];
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      activityLegacyId: activityIds,
      boardAbbreviation: '',
      action: '',
      transferStatusCode: 'A',
      errorCode: '',
      rejectedReason: '',
      debug: true,
    },
    onSubmit: async (values): Promise<void> => {
      const result = await dispatch(processActivity({ ...values }));
      if (result) {
        onClose();
        onSuccess?.();
      }
    },
    validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <fieldset className={styles.fieldset}>
          <section className={styles.section}>
            <div>
              <InputLabel>
                Board <span className={styles.required}>*</span>
              </InputLabel>
              <InputBlock name="boardAbbreviation">
                <Dropdown
                  className={styles.input}
                  fullWidth
                  items={BoardList}
                  name="boardAbbreviation"
                  placeholder="Select Board"
                  required
                />
              </InputBlock>
            </div>
            <div>
              <InputLabel>
                Action That You want to Process<span className={styles.required}>*</span>
              </InputLabel>
              <InputBlock name="action">
                <Dropdown
                  className={styles.input}
                  fullWidth
                  items={ActionTypes}
                  name="action"
                  placeholder="Select Action Type"
                  required
                />
                <legend>(must be the latest one linked from the ActivityMocRegistration)</legend>
              </InputBlock>
            </div>
            <div>
              <InputLabel>Transfer Status code</InputLabel>
              <InputBlock name="transferStatusCode">
                <Dropdown className={styles.input} fullWidth items={StatusCode} name="transferStatusCode" required />
              </InputBlock>
            </div>
            {formik.values?.transferStatusCode === 'R' ? (
              <>
                <div>
                  <InputLabel>Error Code</InputLabel>
                  <InputBlock name="errorCode">
                    <Field id="errorCode" name="errorCode" type="number" />
                  </InputBlock>
                </div>
                <div>
                  <InputLabel>Rejected Reason</InputLabel>
                  <InputBlock name="rejectedReason">
                    <Field id="rejectedReason" name="rejectedReason" type="text" />
                  </InputBlock>
                </div>
              </>
            ) : null}
            <footer className={styles.footer}>
              <Button className={styles.button} onClick={onClose} variant={ButtonVariant.Secondary}>
                Cancel
              </Button>
              <Button
                className={styles.button}
                disabled={formik.isSubmitting}
                isSubmitting={formik.isSubmitting}
                type="submit"
                variant={ButtonVariant.Primary}
              >
                Save
              </Button>
            </footer>
          </section>
        </fieldset>
      </form>
    </FormikProvider>
  );
};
