import { ChangeEvent, ReactElement, MouseEvent, useMemo, useState, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { Box, FormControl, IconButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TreeView from '@material-ui/lab/TreeView';
import { InfoRounded } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import TreeItem from '@material-ui/lab/TreeItem';
import get from 'lodash/get';

// Components
import Dropdown from 'components/Dropdown/Dropdown';
import Button from 'components/Button/Button';
import { FormikTextField } from '../forms';

// Styles
import styles from './styles.module.scss';

// Utils
import { findParents, findRootNodeId } from './utils/findParents';

// Types
import { ButtonVariant } from 'core/enums';
import { IActivitySearchRequest, ITaxonomyTerm } from 'core/models';
import { IContentOutline } from '../../layouts/pages/bct/types';

const useStyles = makeStyles({
  label: {
    '&:hover': {
      cursor: 'pointer',
    },
    alignItems: 'center',
    display: 'flex',
    height: '40px',
  },
  root: {
    flexGrow: 1,
    marginBottom: '10px',
  },
});

interface IProps {
  dropdownId?: string;
  formikKey: string;
  isReadOnly?: boolean;
  maxSelectionCount: number;
  placeholder?: string;
  selectId?: string;
  terms: IContentOutline[];
}

interface RadioValues {
  id: string;
  otherText: string;
}

export const ABAContentOutlines = (props: IProps): ReactElement => {
  const {
    dropdownId = 'optionSelectionId',
    formikKey,
    isReadOnly,
    maxSelectionCount,
    placeholder,
    selectId = 'contentOutlines',
    terms,
  } = props;
  const [allPaths, setPath] = useState<string[][]>([]);
  const selectRef = useRef(null);
  const [activeSelectionCount, setActiveSelectionCount] = useState<number>(0); // Zero-based index.
  const { values, setFieldValue } = useFormikContext<IActivitySearchRequest>();
  const classes = useStyles();

  const formikOptionKey = `${formikKey}.${dropdownId}`;
  const formikOptionValues: string[] = get(values, formikOptionKey);

  const formikSelectKey = `${formikKey}.${selectId}`;
  const formikSelectValues: RadioValues[] = get(values, formikSelectKey);

  const isMaximumReached: boolean = maxSelectionCount === allPaths?.length;

  const getTerm = useMemo(
    () => (term: ITaxonomyTerm): boolean =>
      // Once a selection is made look for the next one in the formik model and select it.
      // If the index is not there, keep the first value selected but accessing the `activeSelectionCount - 1` index.
      term.id === (formikOptionValues?.[activeSelectionCount] || formikOptionValues?.[activeSelectionCount - 1]),
    [activeSelectionCount, formikOptionValues],
  );

  const selectedItems: ITaxonomyTerm[] = terms?.find(getTerm)?.terms || [];

  useEffect(() => {
    // Set initial values of state for rehydrating the form
    const initialFieldValues = get(values, formikSelectKey) as RadioValues[];
    if (initialFieldValues?.length) {
      const initialPaths = initialFieldValues?.map((value: RadioValues) => findParents({ terms }, value.id));
      const initialOptions = initialFieldValues?.map((value: RadioValues) => value.id);
      setPath(initialPaths);
      setActiveSelectionCount(initialFieldValues.length);
      const initialSelections = initialOptions.map((option: string): string => findRootNodeId({ terms }, option));
      setFieldValue(formikOptionKey, initialSelections);
    }
  }, []);

  const onSelectDropdown = (event: ChangeEvent<HTMLSelectElement>): void => {
    const value: string = event.target.value;
    const tempArray: string[] = formikOptionValues || [];

    // Update the proper value at the correct array index.
    if (tempArray[activeSelectionCount]) {
      tempArray[activeSelectionCount] = value;
    } else if (!isMaximumReached) {
      tempArray.push(value);
    }

    // Update the Formik model.
    setFieldValue(formikOptionKey, tempArray);
  };

  const onClearSelection = (idx: number): void => {
    const tempRadioArray: RadioValues[] = formikSelectValues || [];
    // At index `idx` remove 1 element.
    allPaths.splice(idx, 1);

    // Update all of the paths.
    setPath(allPaths);

    // Update the `radio` array to send to Formik.
    if (tempRadioArray[idx]) {
      tempRadioArray.splice(idx, 1);
    }

    // Update model in Formik.
    setFieldValue(formikSelectKey, tempRadioArray);

    // Decrease the count.
    setActiveSelectionCount(activeSelectionCount - 1);
  };

  const onClickSelect = (event: MouseEvent, node: ITaxonomyTerm): void => {
    event.stopPropagation();
    const { id } = node;
    const tempArray: RadioValues[] = formikSelectValues || [];

    // Set the path.
    allPaths[activeSelectionCount] = findParents({ terms }, id);

    // Update the formik model.
    if (tempArray[activeSelectionCount]) {
      if (node.name === 'Other') {
        tempArray[activeSelectionCount] = { id, otherText: formikSelectValues[activeSelectionCount].otherText };
      } else {
        tempArray[activeSelectionCount] = { id, otherText: null };
      }
    } else {
      tempArray.push({ id, otherText: null });
    }
    setFieldValue(formikSelectKey, tempArray);

    // Increase the count.
    setActiveSelectionCount(activeSelectionCount + 1);

    // Scroll into view.
    selectRef.current.scrollIntoView();
  };

  const onChangeTextFieldOther = (e: ChangeEvent<HTMLInputElement>, node: ITaxonomyTerm): void => {
    const value: string = e.target.value;
    const { id } = node;
    const tempArray: RadioValues[] = formikSelectValues || [];

    // Update the proper value at the correct array index.
    if (tempArray[activeSelectionCount]) {
      tempArray[activeSelectionCount] = { ...tempArray[activeSelectionCount], otherText: value };
    } else {
      tempArray.push({ id, otherText: value });
    }
    // Update the Formik model.
    setFieldValue(formikSelectKey, tempArray);
  };

  const renderTree = (node: ITaxonomyTerm, level: number): ReactElement => {
    const { id, name } = node;
    const isChecked = !!formikSelectValues?.find((radio: RadioValues): boolean => radio?.id === id);
    const isIncluded: boolean = formikSelectValues?.[activeSelectionCount]?.id === id;
    // We disabled when two of the same selections are made. You cannot select the same `path` twice.
    const isDisabled: boolean = (isChecked && !isIncluded) || isMaximumReached;
    // Check to see if there are duplicates inside of the formik model. If so, we disabled the select btn `Other` field.
    const set: Set<string> = new Set(
      formikSelectValues?.map(({ id, otherText }: RadioValues): string => id?.concat(otherText?.toLowerCase())),
    );
    const hasDuplicates: boolean = set?.size < formikSelectValues?.length;

    const button = (
      <Button
        onClick={(e: MouseEvent<HTMLButtonElement>): void => onClickSelect(e, node)}
        disabled={isDisabled}
        variant={ButtonVariant.Primary}
      >
        Select
      </Button>
    );

    const label = (
      <div className={styles.flex}>
        <span className={styles['max-width']}>{name}</span>

        {/* Only show btns in the component if it is not `isReadOnly` and level 3 or 4 (#4810). */}
        {!isReadOnly && (level === 3 || level === 4) && (
          <>
            {/* Child tree item with "other" input if we are not `isReadOnly`. */}
            {name === 'Other' ? (
              <>
                <FormikTextField
                  disabled={isMaximumReached}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => onChangeTextFieldOther(e, node)}
                  onClick={(e: MouseEvent<HTMLInputElement>) => e.stopPropagation()}
                  formikKey={`${formikSelectKey}.${id}.otherText`}
                  variant="outlined"
                />
                <Button
                  onClick={(e: MouseEvent<HTMLButtonElement>): void => onClickSelect(e, node)}
                  disabled={isDisabled || hasDuplicates}
                  variant={ButtonVariant.Primary}
                >
                  Select
                </Button>
              </>
            ) : (
              button
            )}
          </>
        )}
      </div>
    );

    return (
      <TreeItem classes={classes} key={id} label={label} nodeId={id}>
        {Array.isArray(node.terms) &&
          node.terms.map((node: ITaxonomyTerm): ReactElement => renderTree(node, level + 1))}
      </TreeItem>
    );
  };

  return (
    <Box mb="2rem">
      <FormControl fullWidth>
        <span aria-hidden="true" ref={selectRef} />
        <Dropdown
          isDisabled={isMaximumReached}
          items={terms}
          name={formikOptionKey}
          onChange={onSelectDropdown}
          placeholder={placeholder}
        />
      </FormControl>

      {/* Render a soft validation message if the maximum is reached. */}
      {isMaximumReached && (
        <Box component="div" m={1} my={4} className={styles['soft-validation']}>
          <p>
            <InfoRounded /> You have reached the maximum number of selections.
          </p>
        </Box>
      )}

      {/* Render the tree structure based on the dropdown selection. */}
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={['root']}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {/* dropdown is level 1, first part of tree is level 2 */}
        {selectedItems.map((term: ITaxonomyTerm): ReactElement => renderTree(term, 2))}
      </TreeView>

      {/* Render the selected paths. */}
      {allPaths.map(
        (paths: string[], idx: number): ReactElement => {
          // Add the `otherText` to the path if applicable.
          const currentRadioValues: RadioValues = formikSelectValues?.[idx];
          const otherText: string = currentRadioValues?.otherText;
          const isLastOther: boolean = paths[paths.length - 1] === 'Other';
          if (otherText && isLastOther) paths.push(otherText);

          return (
            <div className={styles.path} key={paths.toString()}>
              <div>
                <CheckCircleIcon color="inherit" fontSize="inherit" />
              </div>
              <div className={styles['path-text']}>{paths.join(' > ')}</div>
              <div className={styles.filler} />
              <div>
                <IconButton aria-label="remove" onClick={() => onClearSelection(idx)} size="small">
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              </div>
            </div>
          );
        },
      )}
    </Box>
  );
};
