import { IContentOutline } from 'layouts/pages/bct/types';

export const findParents = (node: { terms: IContentOutline[] }, searchForId: string): string[] => {
  // If current node name matches the search name, return
  // empty array which is the beginning of our parent result
  // @ts-ignore
  if (node.id === searchForId) {
    return [];
  }

  // Otherwise, if this node has a tree field/value, recursively process the nodes in this tree array.
  if (Array.isArray(node.terms)) {
    for (const treeNode of node.terms) {
      // Recursively process treeNode. If an array result is returned, then add the treeNode.name to that result
      // and return recursively.
      const childResult: string[] = findParents(treeNode, searchForId);

      if (Array.isArray(childResult)) {
        return [treeNode.name].concat(childResult);
      }
    }
  }
};

// Duplicate logic of findParents, returning only the root ID
export const findRootNodeId = (node: { terms: IContentOutline[] }, searchForId: string): string => {
  // If current node name matches the search name, we've found the correct parent
  // @ts-ignore
  if (node.id === searchForId) {
    return searchForId;
  }

  // Otherwise, if this node has a tree field/value, recursively process the nodes in this tree array.
  if (Array.isArray(node.terms)) {
    for (const treeNode of node.terms) {
      // Recursively process treeNode. Then return the root node id.
      const childResult: string[] = findParents(treeNode, searchForId);

      if (Array.isArray(childResult)) {
        return treeNode.id;
      }
    }
  }
};
