import { ReactElement } from 'react';
import { InfoRounded } from '@material-ui/icons';

// Components
import Button from 'components/Button/Button';

// Types
interface IProps {
  btnText: string;
  onClick(): void;
  text: string;
}

export const Banner = (props: IProps): ReactElement => {
  const { btnText, onClick, text } = props;

  return (
    <div className="info">
      <div className="message">
        <InfoRounded /> {text}
      </div>
      <Button className="link link--hyperlink" onClick={onClick}>
        {btnText}
      </Button>
    </div>
  );
};
