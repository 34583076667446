import * as PARS from 'core';

export const DOWNLOAD_SYNONYM_FILE = 'DOWNLOAD_SYNONYM_FILE';
export const DOWNLOAD_SYNONYM_FILE_FAILURE = 'DOWNLOAD_SYNONYM_FILE_FAILURE';
export const DOWNLOAD_SYNONYM_FILE_SUCCESS = 'DOWNLOAD_SYNONYM_FILE_SUCCESS';
export const CLEAR_UPLOADED_SYNONYM_FILE = 'CLEAR_UPLOADED_SYNONYM_FILE';
export const UPLOAD_SYNONYM_FILE = 'UPLOAD_SYNONYM_FILE';
export const UPLOAD_SYNONYM_FILE_PROGRESS = 'UPLOAD_SYNONYM_FILE_PROGRESS';
export const UPLOAD_SYNONYM_FILE_FAILURE = 'UPLOAD_SYNONYM_FILE_FAILURE';
export const UPLOAD_SYNONYM_FILE_SUCCESS = 'UPLOAD_SYNONYM_FILE_SUCCESS';

export const SYNONYM_MAP_FIRST_NAME = 'first-name-map';

export type SynonymMapName = typeof SYNONYM_MAP_FIRST_NAME;

export interface ISynonymFileActionProperties {
  mapName: SynonymMapName;
}
export interface IUploadSynonymFileActionProperties extends ISynonymFileActionProperties {
  file: File;
}

export interface DownloadSynonymFile
  extends PARS.Models.PARSAction<ISynonymFileActionProperties, typeof DOWNLOAD_SYNONYM_FILE> {
  type: typeof DOWNLOAD_SYNONYM_FILE;
}

export interface DownloadSynonymFileFailure
  extends PARS.Models.PARSAction<string, typeof DOWNLOAD_SYNONYM_FILE_FAILURE> {
  type: typeof DOWNLOAD_SYNONYM_FILE_FAILURE;
}

export interface DownloadSynonymFileSuccess
  extends PARS.Models.PARSAction<ISynonymFileActionProperties, typeof DOWNLOAD_SYNONYM_FILE_SUCCESS> {
  type: typeof DOWNLOAD_SYNONYM_FILE_SUCCESS;
}

export interface ClearUploadedSynonymFile
  extends PARS.Models.PARSAction<ISynonymFileActionProperties, typeof CLEAR_UPLOADED_SYNONYM_FILE> {
  type: typeof CLEAR_UPLOADED_SYNONYM_FILE;
}

export interface UploadSynonymFile
  extends PARS.Models.PARSAction<IUploadSynonymFileActionProperties, typeof UPLOAD_SYNONYM_FILE> {
  type: typeof UPLOAD_SYNONYM_FILE;
}

export interface UploadSynonymFileProgress extends PARS.Models.PARSAction<number, typeof UPLOAD_SYNONYM_FILE_PROGRESS> {
  type: typeof UPLOAD_SYNONYM_FILE_PROGRESS;
}

export interface UploadSynonymFileFailure extends PARS.Models.PARSAction<string, typeof UPLOAD_SYNONYM_FILE_FAILURE> {
  type: typeof UPLOAD_SYNONYM_FILE_FAILURE;
}

export interface UploadSynonymFileSuccess
  extends PARS.Models.PARSAction<ISynonymFileActionProperties, typeof UPLOAD_SYNONYM_FILE_SUCCESS> {
  type: typeof UPLOAD_SYNONYM_FILE_SUCCESS;
}

export type SynonymFileActionTypes =
  | DownloadSynonymFile
  | DownloadSynonymFileFailure
  | DownloadSynonymFileSuccess
  | ClearUploadedSynonymFile
  | UploadSynonymFile
  | UploadSynonymFileProgress
  | UploadSynonymFileFailure
  | UploadSynonymFileSuccess;

export interface ISearchSynonymState {
  error: string;
  isDownloading: boolean;
  isSuccess: boolean;
  progressPercentage?: number;
  file?: File;
}
