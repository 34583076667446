import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import { Tab } from 'components/Tab';

// Styles
import './subnav.module.scss';

// Hooks
import { useTaxonomySubnavTerms } from 'hooks/useTaxonomySubnavTerms';

// Util
import { navigationMap } from 'globals/constants/server-image-map';

// Types
import { ITaxonomyTerm } from 'core/models';

export const Subnav = (): ReactElement => {
  const { pathname } = useLocation();
  const terms: ITaxonomyTerm[] = useTaxonomySubnavTerms(pathname);

  return (
    <div className="subnav">
      <nav>
        {terms.map(
          (tab: ITaxonomyTerm): ReactElement => {
            const { id, description, metadataText, name } = tab;
            const hasIcon = !!navigationMap[metadataText];

            return (
              <Tab key={id} title={name} link={description} exact={description !== '/activities/add-activity'}>
                {hasIcon && navigationMap[metadataText]}
              </Tab>
            );
          },
        )}
      </nav>
    </div>
  );
};
