import { PropsWithChildren, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// Components
import { DialogTitle } from './DialogTitle';
import { DialogContent } from './DialogContent';
import { DialogActions } from './DialogActions';

// Store + Core
import { rollupOrganizationEnumSelector } from 'store/user/selectors';

// Styles
import './modal.module.scss';
import { RollupOrganizationEnums } from 'core/models';

type IModalProps = PropsWithChildren<{
  buttonText?: string;
  buttonVariant?: 'text' | 'outlined' | 'contained';
  titleText?: string;
  actionVariant?: 'done' | 'confirm';
  actionTextSecondary?: string;
  actionTextPrimary?: string;
}>;

const useStyles = makeStyles({
  paperWidthSm: {
    width: '600px',
  },
});

const Modal = (props: IModalProps): ReactElement => {
  const {
    actionTextPrimary,
    actionTextSecondary,
    actionVariant,
    buttonText,
    buttonVariant,
    children,
    titleText,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();

  const handleClickOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);

  return (
    <>
      {buttonVariant === 'text' ? (
        <button className="link" type="button" onClick={handleClickOpen}>
          {buttonText || 'Open'}
        </button>
      ) : (
        <Button variant={buttonVariant || 'outlined'} color="primary" onClick={handleClickOpen}>
          {buttonText || 'Open'}
        </Button>
      )}
      <Dialog
        aria-labelledby="customized-dialog-title"
        classes={classes}
        className={classNames('dialog', { ja: rollupOrganizationEnum === RollupOrganizationEnums.JA })}
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {titleText && titleText}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        {actionVariant && (
          <DialogActions>
            {actionVariant === 'done' ? (
              <button className="primary" onClick={handleClose}>
                {actionTextPrimary}
              </button>
            ) : (
              <div className="modal-button-row">
                <button className="secondary" onClick={handleClose}>
                  {actionTextSecondary}
                </button>
                <button className="primary" onClick={handleClose}>
                  {actionTextPrimary}
                </button>
              </div>
            )}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default Modal;
