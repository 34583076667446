import { IBCTBoard } from 'layouts/pages/bct/types';

export type IContentBlueprintDetails = Pick<
  IBCTBoard,
  'contentOutlines' | 'maximumContentOutlines' | 'minimumContentOutlines' | 'usesContentOutlines'
>;

export const initialContentBlueprintDetails = (bctBoard: IBCTBoard): IContentBlueprintDetails | unknown => {
  if (!bctBoard) return {};
  const { contentOutlines, maximumContentOutlines, minimumContentOutlines, usesContentOutlines } = bctBoard;
  return {
    contentOutlines,
    maximumContentOutlines,
    minimumContentOutlines,
    usesContentOutlines,
  };
};
