import { CircularProgress, InputLabel, MenuItem, Select } from '@material-ui/core';
import moment from 'moment';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import { orderBy } from 'lodash';
import { ExpandMoreRounded } from '@material-ui/icons';

// Components.
import Button from 'components/Button/Button';

// Core.
import { TAXONOMY_ACTIVITY_REVIEW_OUTCOMES_ID, TAXONOMY_ACTIVITY_REVIEW_TYPES_ID } from 'core/constants';
import { ButtonVariant } from 'core/enums';
import { IActivityReviewHistory } from 'core/models';

// Store.
import { deselectActivityForReview, getActivityReviewHistory } from 'store/activity/actions';
import { activityReviewHistorySelector } from 'store/activity/selectors';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { activityReviewTypesSelector } from 'store/taxonomy/selectors';

// Styles.
import styles from './DeselectForReviewForm.module.scss';

interface IActivityReviewOutcomesProps {
  id: string;
  onClose?: VoidFunction;
  onSuccess?: VoidFunction;
}

export const DeselectForReviewForm = (props: IActivityReviewOutcomesProps): ReactElement => {
  const { id, onClose, onSuccess } = props;
  const dispatch = useDispatch();
  const { isLoadingReviewHistory, reviewHistory } = useSelector(activityReviewHistorySelector);
  const reviewTypes = useSelector(activityReviewTypesSelector);
  const eligibleEntries = useMemo(() => orderBy(reviewHistory ?? [], ['reviewDate'], ['asc']), [reviewHistory]);
  const [selectedReview, setSelectedReview] = useState<IActivityReviewHistory | null>(null);
  useEffect(() => {
    setSelectedReview(eligibleEntries[0]);
  }, [eligibleEntries]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      if (await dispatch(deselectActivityForReview(id, selectedReview?.id))) {
        onClose?.();
        onSuccess?.();
      }
    },
  });

  useEffect(() => {
    dispatch(getTaxonomyTermById(TAXONOMY_ACTIVITY_REVIEW_TYPES_ID));
    dispatch(getTaxonomyTermById(TAXONOMY_ACTIVITY_REVIEW_OUTCOMES_ID));
    dispatch(getActivityReviewHistory(id));
  }, [dispatch, id]);

  if (isLoadingReviewHistory)
    return (
      <div className={styles['loading-wrapper']}>
        <CircularProgress color="inherit" />
      </div>
    );
  if (eligibleEntries?.length === 0) {
    return (
      <section className={styles.section}>
        <p>This activity has no ongoing reviews</p>
      </section>
    );
  }

  return (
    <section className={styles.section}>
      <h2 className={styles.title}>
        <InputLabel>
          Review <span className={styles.required}>*</span>
        </InputLabel>
        <Select
          className={styles.select}
          defaultValue={eligibleEntries[0]?.id}
          onChange={(e) => {
            setSelectedReview(eligibleEntries.find(({ id }) => id === e.target.value));
          }}
          variant="outlined"
          IconComponent={ExpandMoreRounded}
        >
          {eligibleEntries.map(({ id, reviewTypeId, reviewDate }) => {
            const reviewType = reviewTypes?.find((item) => item.id === reviewTypeId);
            const formatedReviewDate = moment(reviewDate).format('MM-DD-YYYY');
            return (
              <MenuItem key={id} value={id}>
                {reviewType?.name} Review {formatedReviewDate}
              </MenuItem>
            );
          })}
        </Select>
      </h2>

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <footer className={styles.footer}>
            <Button onClick={onClose} variant={ButtonVariant.Secondary}>
              Cancel
            </Button>
            <Button
              disabled={formik.isSubmitting}
              isSubmitting={formik.isSubmitting}
              type="submit"
              variant={ButtonVariant.Primary}
            >
              Submit
            </Button>
          </footer>
        </form>
      </FormikProvider>
    </section>
  );
};
