import { ReactElement } from 'react';
import { AddRounded, CloseRounded } from '@material-ui/icons';
import { Field, FieldArray, FieldProps } from 'formik';
import { uniqueId } from 'lodash';
import classNames from 'classnames';

// Component
import { Typeahead, ITypeaheadOption } from 'components/ContinuousImprovement/Typeahead';
import Button from 'components/Button/Button';

// Core
import { RepeaterEnum } from 'core/enums';
import { IInKindSupportSourceOption, IMonetarySupportSourceOption } from 'core/models';

// Styles
import styles from './index.module.scss';

interface IRepeaterProps {
  inputType: RepeaterEnum.REPEAT_INPUT | RepeaterEnum.REPEAT_TYPEAHEAD | RepeaterEnum.REPEAT_MULTI_TYPEAHEAD;
  label?: string;
  min?: number;
  name?: string;
  options?: ITypeaheadOption[];
  placeholder?: string;
  placeholderTwo?: string;
  title?: string;
  titleTwo?: string;
  repeatButtonText?: string;
}

interface IRepeaterRenderProps extends IRepeaterProps, FieldProps {}

const RepeaterControl = (props: IRepeaterRenderProps): ReactElement => {
  const {
    inputType,
    repeatButtonText,
    min,
    options,
    placeholder,
    placeholderTwo,
    title,
    titleTwo,
    field: { name, value },
    form: { handleChange },
  } = props;

  const renderAddEntry = (newEntry: any, push: (entry: any) => void): ReactElement => (
    <Button className="icon add" title="Add" onClick={() => push(newEntry)}>
      <AddRounded />
      {repeatButtonText || 'Add'}
    </Button>
  );

  const renderDeleteEntry = (
    remove: (index: number) => IMonetarySupportSourceOption | IInKindSupportSourceOption | undefined,
    index: number,
  ): ReactElement => (
    <Button className="remove" title="Remove" onClick={() => remove(index)}>
      <CloseRounded />
    </Button>
  );

  const renderOneFieldRepeater = (
    index: number,
    fieldName: string,
    remove: (index: number) => IInKindSupportSourceOption,
  ): ReactElement => (
    <div
      key={`${name}-${index}`}
      className={classNames('form-input form-input--with-delete', styles['repeater-option'])}
    >
      <label htmlFor={fieldName} aria-label={placeholder} className={styles['repeater-input']}>
        <Typeahead id={fieldName} name={fieldName} options={options} placeholder={placeholder} freeSolo />
        {index > 0 && renderDeleteEntry(remove, index)}
      </label>
    </div>
  );

  const renderTwoFieldRepeater = (
    entry: IMonetarySupportSourceOption,
    index: number,
    fieldName: string,
    remove: (index: number) => IMonetarySupportSourceOption | undefined,
  ): ReactElement => {
    return (
      <div
        key={`${name}-${index}-${entry.id}`}
        className={classNames('form-input form-input--with-delete', styles['repeater-option'])}
      >
        <div className={styles['inline-input']}>
          <h5 className="form-input-label">{title}</h5>
          <label className={styles['repeater-input']} htmlFor={`${fieldName}.source`} aria-label={placeholder}>
            <Typeahead
              id={`${fieldName}.source`}
              name={`${fieldName}.source`}
              options={props.options}
              placeholder={placeholder}
              freeSolo
            />
          </label>
        </div>
        <div className={styles['inline-input']}>
          <h5 className="form-input-label">{titleTwo}</h5>
          <label
            className={styles['repeater-input-with-delete']}
            htmlFor={`${fieldName}.amountGiven`}
            aria-label={placeholder}
          >
            <Field
              name={`${fieldName}.amountGiven`}
              placeholder={placeholderTwo}
              min={min}
              type="number"
              value={entry.amountGiven}
              onChange={handleChange}
            />
            {index > 0 && renderDeleteEntry(remove, index)}
          </label>
        </div>
      </div>
    );
  };

  return (
    <FieldArray
      name={name}
      render={({ push, remove }) => (
        <>
          {inputType && inputType === RepeaterEnum.REPEAT_TYPEAHEAD ? (
            <>
              {value?.map((_: ITypeaheadOption, index: number) =>
                renderOneFieldRepeater(index, `${name}.${index}`, remove),
              )}
              {renderAddEntry('', push)}
            </>
          ) : null}

          {inputType && inputType === RepeaterEnum.REPEAT_MULTI_TYPEAHEAD ? (
            <>
              {value?.map((entry: IMonetarySupportSourceOption, index: number) =>
                renderTwoFieldRepeater(entry, index, `${name}.${index}`, remove),
              )}
              {renderAddEntry({ source: '', amountGiven: null, id: uniqueId('repeatmultitypeahead_') }, push)}
            </>
          ) : null}

          {inputType && inputType === RepeaterEnum.REPEAT_INPUT && (
            <>
              {value?.map((entry, index) => (
                <div
                  key={`${name}-${index}`}
                  className={classNames('form-input form-input--with-delete', styles['multi-input-text'])}
                >
                  <Field
                    aria-label={name}
                    InputProps={{ id: entry.id }}
                    key={index}
                    name={`${name}.${index}`}
                    onChange={handleChange}
                    placeholder={placeholder}
                    type="text"
                    value={entry}
                  />
                  {index > 0 && renderDeleteEntry(remove, index)}
                </div>
              ))}
              {renderAddEntry('', push)}
            </>
          )}
        </>
      )}
    />
  );
};

const FieldRepeaterControl = (props: IRepeaterProps): ReactElement => (
  <Field aria-label={props.name} name={props.name}>
    {(renderProps: FieldProps) => <RepeaterControl {...props} {...renderProps} />}
  </Field>
);

export default FieldRepeaterControl;
