import { PARSAction } from 'core/models';

export interface IModalState {
  isModalOpen: boolean;
}

export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';

interface ModalOpen extends PARSAction<undefined, typeof MODAL_OPEN> {
  type: typeof MODAL_OPEN;
}

interface ModalClose extends PARSAction<undefined, typeof MODAL_CLOSE> {
  type: typeof MODAL_CLOSE;
}

export type ModalActionTypes = ModalOpen | ModalClose;
