import { FormikProvider, useFormik } from 'formik';
import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';

// Components
import Button from 'components/Button/Button';

// Styles.
import styles from '../AdminPopupStyle/AdminPopupStyle.module.scss';

// Core + Store
import { ButtonVariant } from 'core/enums';
import { adminDeleteActivity } from 'store/activity/actions';

interface IAdminDeleteActivityForm {
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  activityId: number;
}

export const AdminDeleteActivityForm = (props: IAdminDeleteActivityForm): ReactElement => {
  const { activityId, onClose, onSuccess } = props;
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      activityLegacyId: activityId,
    },
    onSubmit: async (values): Promise<void> => {
      const result = await dispatch(adminDeleteActivity(values));
      if (result) {
        onClose();
        onSuccess?.();
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <fieldset className={styles.fieldset}>
          <section className={styles.section}>
            <div>Are you sure you want to delete this activity?</div>
            <footer className={styles.footer}>
              <Button className={styles.button} onClick={onClose} variant={ButtonVariant.Secondary}>
                Cancel
              </Button>
              <Button
                className={styles.button}
                disabled={formik.isSubmitting}
                isSubmitting={formik.isSubmitting}
                type="submit"
                variant={ButtonVariant.Primary}
              >
                Delete
              </Button>
            </footer>
          </section>
        </fieldset>
      </form>
    </FormikProvider>
  );
};
