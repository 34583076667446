import { IContextualMenuOptionProps } from 'components/ContextualMenu/ContextualMenu';

// Types
interface IOption {
  options: IContextualMenuOptionProps[];
}

interface IArgs {
  onDownloadAllActivities(): void;
  onDownloadFilteredActivities(): void;
}

export const DOWNLOAD_ALL_ACTIVITIES = 'Download All Activities';
export const DOWNLOAD_FILTERED_ACTIVITIES = 'Download Filtered Activities';

export const exportMenuItems = ({ onDownloadAllActivities, onDownloadFilteredActivities }: IArgs): IOption => ({
  options: [
    {
      icon: 'get_app',
      name: DOWNLOAD_ALL_ACTIVITIES,
      onSelectAction: onDownloadAllActivities,
      selected: true,
    },
    {
      icon: 'get_app',
      name: DOWNLOAD_FILTERED_ACTIVITIES,
      onSelectAction: onDownloadFilteredActivities,
    },
  ],
});
