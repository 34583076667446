import { render } from 'react-dom';
import TagManager from 'react-gtm-module';
import { Router } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

// Components
import App from './App';
import { Auth0ProviderWithHistory } from 'auth/auth0-provider-with-history';

// Core.
import Configuration from 'core/configuration';

// Styles
import './index.css';

// Store
import { History } from 'services';
import { AnalyticsService } from 'services/AnalyticsService';

const tagManagerArgs = { gtmId: Configuration.gtmId };
TagManager.initialize(tagManagerArgs);
AnalyticsService.initialize();

const renderApp = (Component) => {
  return render(
    <Router history={History}>
      <Auth0ProviderWithHistory>
        <Component />
      </Auth0ProviderWithHistory>
    </Router>,
    document.getElementById('root'),
  );
};

renderApp(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    renderApp(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
