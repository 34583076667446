import { IAttestation, PARSAction } from 'core/models';

export const GET_ATTESTATION = 'GET_ATTESTATION';
export const GET_ATTESTATION_SUCCESS = 'GET_ATTESTATION_SUCCESS';
export const GET_ATTESTATION_FAILURE = 'GET_ATTESTATION_FAILURE';
export const ATTEST = 'ATTEST';
export const ATTEST_SUCCESS = 'ATTEST_SUCCESS';
export const ATTEST_FAILURE = 'ATTEST_FAILURE';

export interface AttestationState {
  attestation?: IAttestation;
  error?: string;
  isAttestationLoading: boolean;
  isAttestLoading: boolean;
}

interface GetAttestation extends PARSAction<void, typeof GET_ATTESTATION> {
  type: typeof GET_ATTESTATION;
}

interface GetAttestationSuccess extends PARSAction<IAttestation, typeof GET_ATTESTATION_SUCCESS> {
  type: typeof GET_ATTESTATION_SUCCESS;
  payload: IAttestation;
}

interface GetAttestationFailure extends PARSAction<Error, typeof GET_ATTESTATION_FAILURE> {
  type: typeof GET_ATTESTATION_FAILURE;
  payload: Error;
}

interface Attest extends PARSAction<void, typeof ATTEST> {
  type: typeof ATTEST;
}

interface AttestSuccess extends PARSAction<void, typeof ATTEST_SUCCESS> {
  type: typeof ATTEST_SUCCESS;
}
interface AttestFailure extends PARSAction<Error, typeof ATTEST_FAILURE> {
  type: typeof ATTEST_FAILURE;
  payload: Error;
}

export type AttestationTypes =
  | GetAttestation
  | GetAttestationSuccess
  | GetAttestationFailure
  | Attest
  | AttestSuccess
  | AttestFailure;
