import { ThunkAction } from 'redux-thunk';

// Core.
import { API } from 'core';
import { OrganizationKind, PARSAction, RollupOrganizationEnums } from 'core/models';

// Globals.
import { handleServerError } from 'globals/utils/handleServerError';

// Services.
import { ReportService } from 'services/ReportService';
// @ts-ignore: This package isn't typescript-friendly
import { saveAs } from 'filesaver.js-npm';

// Store.
import { AppState } from 'store';
import { popToast } from 'store/toast/actions';
import { errorToastOptions, infoToastOptions, successToastOptions } from 'store/toast/constants';

// Types.
import {
  GET_REPORT,
  GET_REPORT_FAILURE,
  GET_REPORT_SUCCESS,
  GET_REPORTS,
  GET_REPORTS_FAILURE,
  GET_REPORTS_SUCCESS,
  GET_SMS_SUMMARY_REPORT_AUTHORIZATION,
  GET_SMS_SUMMARY_REPORT_AUTHORIZATION_FAILURE,
  GET_SMS_SUMMARY_REPORT_AUTHORIZATION_SUCCESS,
  GET_SMS_SUMMARY_REPORT_DIRECTORY,
  GET_SMS_SUMMARY_REPORT_DIRECTORY_FAILURE,
  GET_SMS_SUMMARY_REPORT_DIRECTORY_SUCCESS,
  GET_SMS_SUMMARY_REPORT_FILE,
  GET_SMS_SUMMARY_REPORT_FILE_FAILURE,
  GET_SMS_SUMMARY_REPORT_FILE_LIST,
  GET_SMS_SUMMARY_REPORT_FILE_LIST_FAILURE,
  GET_SMS_SUMMARY_REPORT_FILE_LIST_SUCCESS,
  GET_SMS_SUMMARY_REPORT_FILE_SUCCESS,
  GetReport,
  GetReportFailure,
  GetReports,
  GetReportsFailure,
  GetReportsSuccess,
  GetReportSuccess,
} from './types';
import {
  OrganizationKindPowerBIJaWorkspaceId,
  OrganizationKindPowerBINarsWorkspaceId,
  OrganizationKindPowerBIWorkspaceId,
} from 'core/constants';
import { AnyAction } from 'redux';
import { SmsSummaryReportFileService } from '../../services/SmsSummaryReportFileService';
import Configuration from '../../core/configuration';

// action creators
export const getReportByIDAction = (): GetReport => ({
  type: GET_REPORT,
});

export const getReportByIDSuccessAction = (report: any): GetReportSuccess => ({
  type: GET_REPORT_SUCCESS,
  payload: report,
});

export const getReportByIDFailureAction = (): GetReportFailure => ({
  type: GET_REPORT_FAILURE,
});

export const getReportsByWorkspaceIdAction = (): GetReports => ({
  type: GET_REPORTS,
});

export const getReportsByWorkspaceIdFailureAction = (): GetReportsFailure => ({
  type: GET_REPORTS_FAILURE,
});

export const getReportsByWorkspaceIdSuccessAction = (reports: any): GetReportsSuccess => ({
  payload: reports,
  type: GET_REPORTS_SUCCESS,
});

export const getSmsSummaryReportFileListAction = (): AnyAction => {
  return {
    type: GET_SMS_SUMMARY_REPORT_FILE_LIST,
  };
};

export const getSmsSummaryReportFileListSuccessAction = (
  payload: Array<string>,
): PARSAction<Array<string>, typeof GET_SMS_SUMMARY_REPORT_FILE_LIST_SUCCESS> => {
  return {
    payload,
    type: GET_SMS_SUMMARY_REPORT_FILE_LIST_SUCCESS,
  };
};

export const getSmsSummaryReportFileListFailureAction = (errorMessage: string): AnyAction => {
  return {
    payload: errorMessage,
    type: GET_SMS_SUMMARY_REPORT_FILE_LIST_FAILURE,
  };
};

export const getSmsSummaryReportFileAction = (): AnyAction => {
  return {
    type: GET_SMS_SUMMARY_REPORT_FILE,
  };
};

export const getSmsSummaryReportFileSuccessAction = (
  payload: Blob,
): PARSAction<Blob, typeof GET_SMS_SUMMARY_REPORT_FILE_SUCCESS> => {
  return {
    payload: payload,
    type: GET_SMS_SUMMARY_REPORT_FILE_SUCCESS,
  };
};

export const getSmsSummaryReportFileFailureAction = (errorMessage: string): AnyAction => {
  return {
    payload: errorMessage,
    type: GET_SMS_SUMMARY_REPORT_FILE_FAILURE,
  };
};

export const getSmsSummaryReportDirectoryAction = (): AnyAction => {
  return {
    type: GET_SMS_SUMMARY_REPORT_DIRECTORY,
  };
};

export const getSmsSummaryReportDirectorySuccessAction = (
  payload: Blob,
): PARSAction<Blob, typeof GET_SMS_SUMMARY_REPORT_DIRECTORY_SUCCESS> => {
  return {
    payload: payload,
    type: GET_SMS_SUMMARY_REPORT_DIRECTORY_SUCCESS,
  };
};

export const getSmsSummaryReportDirectoryFailureAction = (errorMessage: string): AnyAction => {
  return {
    payload: errorMessage,
    type: GET_SMS_SUMMARY_REPORT_DIRECTORY_FAILURE,
  };
};

export const getSmsSummaryReportAuthorizationAction = (): AnyAction => {
  return {
    type: GET_SMS_SUMMARY_REPORT_AUTHORIZATION,
  };
};

export const getSmsSummaryReportAuthorizationSuccessAction = (
  payload: boolean,
): PARSAction<boolean, typeof GET_SMS_SUMMARY_REPORT_AUTHORIZATION_SUCCESS> => {
  return {
    payload: payload,
    type: GET_SMS_SUMMARY_REPORT_AUTHORIZATION_SUCCESS,
  };
};

export const getSmsSummaryReportAuthorizationFailureAction = (errorMessage: string): AnyAction => {
  return {
    payload: errorMessage,
    type: GET_SMS_SUMMARY_REPORT_AUTHORIZATION_FAILURE,
  };
};

export const getReportsByWorkspaceId = (
  organizationBusinessId: string,
  organizationKind: OrganizationKind,
  rollupOrganizationEnum?: RollupOrganizationEnums,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch): Promise<void> => {
  let workspaceId: string;
  if (rollupOrganizationEnum === RollupOrganizationEnums.JA) {
    workspaceId = OrganizationKindPowerBIJaWorkspaceId[organizationKind];
  } else if (organizationBusinessId === 'ANCC') {
    workspaceId = Configuration.powerBiNarsAnccWorkspaceId;
  } else if (rollupOrganizationEnum === RollupOrganizationEnums.NARS) {
    workspaceId = OrganizationKindPowerBINarsWorkspaceId[organizationKind];
  } else {
    workspaceId = OrganizationKindPowerBIWorkspaceId[organizationKind];
  }

  if (workspaceId) {
    dispatch(getReportsByWorkspaceIdAction());

    try {
      const reports = await ReportService.getReportsByWorkspaceId(workspaceId);
      dispatch(getReportsByWorkspaceIdSuccessAction(reports));
    } catch (error) {
      const { errorMessage } = handleServerError({ error, thunkName: 'getReportsByWorkspaceId' });
      dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage || 'Error retrieving reports.'}</> }));
      dispatch(getReportsByWorkspaceIdFailureAction());
    }
  }
};

// thunk actions
export const getPowerReportById = (
  userId: string,
  reportId: string,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(getReportByIDAction());
  try {
    // TODO move this to service if we keep POC
    const route = API.ServerRoute.forAction('Reports', `${userId}/${reportId}`);
    const report = await API.get(route);
    await dispatch(getReportByIDSuccessAction(report));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getPowerReportById' });
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage || 'Error retrieving report.'}</> }));
    dispatch(getReportByIDFailureAction());
  }
};

/**
 * Download all SMS Summary Reports within the current organizations sms-reports directory.
 *
 * @returns {ThunkAction<void, AppState, null, PARSAction>} - A thunk action that dispatches async actions.
 */
export const getSmsSummaryReportsDirectory = (): ThunkAction<void, AppState, null, PARSAction> => async (
  dispatch,
  getState,
) => {
  dispatch(getSmsSummaryReportDirectoryAction());

  dispatch(popToast({ ...infoToastOptions, message: <>Downloading...</> }));

  try {
    const reports = await SmsSummaryReportFileService.getFilesInDirectoryPathAsZip();
    const blob = new Blob([reports], { type: 'application/zip' });
    saveAs(blob, 'SMS_Summary_Reports');

    dispatch(getSmsSummaryReportDirectorySuccessAction(reports));
    dispatch(popToast({ ...successToastOptions, message: <>Downloaded successfully.</> }));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getSmsSummaryReportsFolder' });
    dispatch(getSmsSummaryReportDirectoryFailureAction(errorMessage));
    dispatch(popToast({ ...errorToastOptions, message: <>{getState().fileDownloadState.error}</> }));
  }
};

/**
 * Retrieves a list of SMS Summary Reports from the current organizations sms-reports directory.
 *
 * @returns {ThunkAction<void, AppState, null, PARSAction>} - The thunk action that performs the retrieval process.
 * @throws {Error} - If an error occurs during the list retrieval process.
 */
export const getSmsSummaryReportFileList = (): ThunkAction<void, AppState, null, PARSAction> => async (dispatch) => {
  dispatch(getSmsSummaryReportFileListAction());

  try {
    const list = await SmsSummaryReportFileService.getSmsSummaryReportFileList();

    if (list) {
      dispatch(getSmsSummaryReportFileListSuccessAction(list));
    }
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getSmsSummaryReportFileList' });
    dispatch(getSmsSummaryReportFileListFailureAction(errorMessage));
  }
};

/**
 * Retrieves the SMS Summary Report file with the specified file name.
 *
 * @param {string} fileName - The name of the file to retrieve.
 * @returns {ThunkAction<void, AppState, null, PARSAction>} - The thunk action that performs the retrieval process.
 * @throws {Error} - If an error occurs during the file retrieval process.
 */
export const getSmsSummaryReportFile = (fileName: string): ThunkAction<void, AppState, null, PARSAction> => async (
  dispatch,
  getState,
) => {
  if (!fileName) {
    dispatch(getSmsSummaryReportFileFailureAction('Please provide a file name.'));
  }
  dispatch(getSmsSummaryReportFileAction());

  try {
    const summaryReport = await SmsSummaryReportFileService.getSmsSummaryReportFileInDirectory(fileName);
    saveAs(summaryReport, fileName);

    dispatch(getSmsSummaryReportFileSuccessAction(summaryReport));
    dispatch(popToast({ ...successToastOptions, message: <>Downloaded successfully.</> }));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getSmsSummaryReportFile' });
    dispatch(getSmsSummaryReportFileFailureAction(errorMessage));
    dispatch(popToast({ ...errorToastOptions, message: <>{getState().fileDownloadState.error}</> }));
  }
};

/**
 * Gets whether the current user is authorized to view SMS Summary Reports.
 *
 * @returns {ThunkAction<void, AppState, null, PARSAction>} - The thunk action that performs the retrieval process.
 * @throws {Error} - If an error occurs during the retrieval process.
 */
export const getSmsSummaryReportAuthorization = (): ThunkAction<void, AppState, null, PARSAction> => async (
  dispatch,
) => {
  dispatch(getSmsSummaryReportAuthorizationAction());

  const isAuthorized = await SmsSummaryReportFileService.getSmsSummaryReportAuthorization();

  if (isAuthorized) {
    dispatch(getSmsSummaryReportAuthorizationSuccessAction(isAuthorized));
  } else {
    dispatch(
      getSmsSummaryReportFileFailureAction('Current User does not have permission to view SMS Summary Report Files.'),
    );
  }
};
