import * as PARS from '../core';
import ActivitiesJA from '../activityformtemplates/ActivitiesJa.json';
import ActivitiesNotJA from '../activityformtemplates/ActivitiesNotJa.json';
import ActivitiesNars from '../activityformtemplates/ActivitiesNars.json';
import { FormTemplatesApiService } from '../core/formTemplatesApiService';
import { ProviderService } from './ProviderService';
import { CacheService } from './index';

// Change to true to read from local JSON.
const RENDER_LOCAL_JA = false;
const RENDER_LOCAL_NOT_JA = false;
const RENDER_LOCAL_NARS = false;

export class FormTemplateService {
  private formTemplatesApiService: FormTemplatesApiService;
  private providerService: ProviderService;

  constructor() {
    this.formTemplatesApiService = new FormTemplatesApiService();
    this.providerService = new ProviderService();
  }

  public async getActivityTemplate(): Promise<Array<PARS.Models.IFormSection>> {
    // For faster local development, flip to true to read from local JSON files.
    if (RENDER_LOCAL_JA) return ActivitiesJA as any;
    if (RENDER_LOCAL_NOT_JA) return ActivitiesNotJA as any;
    if (RENDER_LOCAL_NARS) return ActivitiesNars as any;

    // return await this.formTemplatesApiService.getActivityTemplateAsync();
    const cacheKey = `${PARS.Constants.CACHE_FORM_TEMPLATE_PREFIX}.activity.${this.providerService.currentOrganizationId}.${this.providerService.currentProviderId}`;
    const cachedTemplate = CacheService.get<Array<PARS.Models.IFormSection>>(cacheKey);
    if (cachedTemplate) {
      return cachedTemplate;
    }

    const template = await this.formTemplatesApiService.getActivityTemplateAsync();
    CacheService.set(cacheKey, template, PARS.Constants.CACHE_FORM_TEMPLATE_EXPIRY_MINS);
    return template;
  }
}
