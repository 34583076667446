import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { IBoardSlim } from 'core/models';
import { getAllBoards } from 'store/board/actions';
import { boardSelector } from 'store/board/selectors';

export const useBoardList = (): IBoardSlim[] => {
  const boards: IBoardSlim[] = useSelector(boardSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!boards) {
      dispatch(getAllBoards());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return boards;
};
