// Libs
import * as yup from 'yup';
import { first, isArray, isDate, isNumber, keys, orderBy } from 'lodash';
import moment from 'moment';

// Core + Store
import { StatusEnum } from 'core/enums';
import {
  ActivityType,
  IActivityValidatorBlueprint,
  IDatesAndLocation,
  IDictionary,
  RollupOrganizationEnums,
} from 'core/models';
import { ACTIVITY_TYPE_ABBREVIATION_ENUM } from 'core/constants';

// Misc
import {
  BoardCreditTypeSubmissionTypes,
  IBCTBoard,
  IBCTCreditType,
  IBoardAdditionalFields,
} from 'layouts/pages/bct/types';
import { VALIDATION_MESSAGES } from './validationMessages';
import { isAlmostInteger } from 'utils';
import { getRemsRegularExpression } from '../../utils';

// https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
const urlPrefixRegex = /^https?:\/\//;
// https://mathiasbynens.be/demo/url-regex - The most effective one I could find was by @diegoperini
const urlContentRegex = /(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)(?:\.(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)*(?:\.(?:[a-z\x{00a1}\-\x{ffff}]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/;
// String value ends with -true or -false
const booleanFormFieldRegex = /[-](true|false){1}$/;
// Exclusively match ends with -true
// DO NOT CHANGE THIS OR YOU'RE FIRED
const booleanTrueValueRegex = /[-](true){1}$/;
// Matches exactly joint or direct
const jointTypeRegex = /^(joint|direct)$/;

const requiredStringField = yup.string().required(VALIDATION_MESSAGES.REQUIRED_TO_CONTINUE);
const notRequiredStringField = yup.string().notRequired();
const booleanFieldSchema = yup.string().notRequired();

/**
 * @description BUSINESS RULE 24
 * No specific requirements.
 *
 * Individualized Learners Added: Activity Name can be edited"
 */
const titleSchema = requiredStringField.max(256, VALIDATION_MESSAGES.TITLE_MAX_CHARS);

/**
 * @description BUSINESS RULE 25
 * One option must be selected.
 * Course: Options include 'In Person', 'Online', or a provider can select both. If In Person is selected, the city/state/country/zip
 * fields should populate. The question 'Is this course offered more than once with the same content' should appear. and the option
 * to add more dates/locations should also appear.
 *
 * RSS: Options include 'In Person', 'Online', or a provider can select both. The form should display a start and end date for the
 * series, and allow for individualized location if In Person  is selected
 * Enduring Material: The form should show 'Internet' or 'Other' along with a start and end date. The form should not display fields
 * to allow for an individualized location.
 *
 * All additional types do not need a location or additional fields. All activity types should display a start and end date
 *
 * Individualized Learners Added: Providers should be able to change activity type with some considerations as different activity types
 * have different learner reporting rules:
 * Group 1: Course, RSS, Enduring Material, Performance Improvement and Other can be interchanged.
 * Group 2: Learning from Teaching, Test Item Writing, Manuscript Review, Committee Learning, Journal-based CE and Internet Searching
 * and Learning can be interchanged.
 *
 * If a provider wants to change an activity from group 1 to group 2 after learners have been reported they need to contact support.
 * Otherwise the activity type can be changed freely."
 */
const buildTypeSchema = ({
  activityTypeGroups,
  hasLearners,
  selectedActivityType,
}: IActivityValidatorBlueprint): yup.StringSchema => {
  if (!hasLearners) {
    return requiredStringField;
  }
  const [groupOne, groupTwo] = activityTypeGroups;
  return yup
    .string()
    .test({
      message: VALIDATION_MESSAGES.TYPE_CHANGE_RESTRICTED,
      name: 'activityTypeGroupingTest',
      test: (val: string) => {
        const wasInGroupOne = groupOne?.find((type: ActivityType): boolean => type.id === selectedActivityType);
        const wasInGroupTwo = groupTwo?.find((type: ActivityType): boolean => type.id === selectedActivityType);
        if (wasInGroupOne && !groupOne?.find((type: ActivityType): boolean => type.id === val)) {
          return false;
        }
        return !(wasInGroupTwo && !groupTwo?.find((type: ActivityType): boolean => type.id === val));
      },
    })
    .required();
};

/**
 * @description BUSINESS RULE 26
 * The activity start date cannot be past the listed activity end date. The start date can be equal to the activity end date.
 *
 * Individualized Learners Added: Start date can be edited. The start date of the activity cannot fall after the completion date
 * of any of the learners reported. If the earliest learner was reported with a completion date of 9/14/2020, the start date can
 * fall anywhere from 9/14/2020 or earlier."
 */
const buildStartDateSchema = ({
  status,
  learnerEarliestCompletion,
  hasLearners,
}: IActivityValidatorBlueprint): yup.DateSchema => {
  const defaultSchema = yup
    .date()
    .test({
      message: VALIDATION_MESSAGES.START_DATE_BEFORE_END_DATE,
      name: 'startBeforeEndDateTest',
      test: (startDate: Date, context: yup.TestContext): boolean => {
        const { resolve } = context;
        const endDate = resolve(yup.ref('endDate'));
        if (!endDate || !isDate(endDate) || !startDate || !isDate(startDate)) {
          return true;
        }
        return !moment(endDate).isBefore(moment(startDate), 'days');
      },
    })
    .typeError('')
    .required(VALIDATION_MESSAGES.REQUIRED_TO_CONTINUE);
  if (status === StatusEnum.DRAFT) {
    return defaultSchema;
  } else {
    if (!hasLearners) {
      return defaultSchema;
    }
    return defaultSchema.max(learnerEarliestCompletion, VALIDATION_MESSAGES.START_DATE_BEFORE_COMPLETION_DATE);
  }
};

/**
 * @description BUSINESS RULE 27
 * The activity end date cannot be listed as earlier than the activity start date. The activity end date can be equal to the activity start date.
 *
 * Automatically flag non-Enduring Material or Journal-based CE Activities with too long of a timespan
 * Up to 3 years (1,096 days to account for a leap year) - for Enduring Material
 * Up to 1 year (366 days to account for a year) - for all other formats
 * Individualized Learners Added: Activity end date can be edited."
 *
 */

const endDateSchema = yup
  .date()
  .min(yup.ref('startDate'), VALIDATION_MESSAGES.END_DATE_AFTER_START_DATE)
  .test({
    message: VALIDATION_MESSAGES.ACTIVITY_LENGTH_REQUIREMENT,
    name: 'activityDurationsTest',
    test: (endDate: Date, context: yup.TestContext): boolean => {
      const { resolve } = context;
      const startDate = resolve(yup.ref('startDate')) as Date;
      const activityTypeGroups = resolve(yup.ref('activityTypeGroups')) as [ActivityType[], ActivityType[]];
      const [groupOne, groupTwo] = activityTypeGroups;
      const fullGroup = [...groupOne, ...groupTwo];
      const duration = differenceInDays(startDate, new Date(endDate));
      const typeId = getCheckedElementIdByName('typeId');

      if (!isValidDate(startDate) || !isValidDate(endDate)) {
        return true;
      }

      const selectedType = fullGroup.find((type: ActivityType) => type.id === typeId);
      const maxDuration = selectedType ? getMaxActivityDuration(selectedType) : 0;
      return duration <= maxDuration;
    },
  })
  .typeError('')
  .required(VALIDATION_MESSAGES.REQUIRED_TO_CONTINUE);

/** Check for valid date
 * @param {date}
 */
const isValidDate = (date) => date && isDate(date);

/** Filters for the currently selected id.
 * @param {name} id selected
 */
function getCheckedElementIdByName(name) {
  for (const element of document.getElementsByName(name)) {
    if (element instanceof HTMLInputElement && element.checked) {
      return element.id;
    }
  }
  return null;
}

const maxActivityDurationByActivityTypeAbbreviation = {
  [ACTIVITY_TYPE_ABBREVIATION_ENUM.EM]: 1096,
  [ACTIVITY_TYPE_ABBREVIATION_ENUM.JN]: 1096,
};

/** Gets the max activity duration set in the dictionary
 * @param activityType
 * @returns  The max duration of an activity of the given type
 */
const getMaxActivityDuration = (activityType: ActivityType) =>
  maxActivityDurationByActivityTypeAbbreviation[activityType.abbreviation] ?? 366;

/** Calculates the difference in days between two dates.
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {number} - The difference in days between the two dates.
 * End date returns the next day(EOD)
 */
const differenceInDays = (startDate, endDate) => {
  const msPerDay = 24 * 60 * 60 * 1000;
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();
  return Math.round((end - start) / msPerDay) - 1;
};

/**
 * @function buildActivityBasicsSchema
 * @description Build the Basics schema for the Activity Edit page
 * @returns BasicsSchema
 * @param blueprint
 */
const buildActivityBasicsSchema = (blueprint: IActivityValidatorBlueprint): yup.AnyObjectSchema => {
  const startDateSchema = buildStartDateSchema(blueprint);
  return yup.object().shape({
    tempDatesAndLocation: yup.array().of(
      yup.object().shape({
        endDate: endDateSchema,
        startDate: startDateSchema,
        activityTypeGroups: yup.array().default(blueprint.activityTypeGroups),
      }),
    ),
    title: titleSchema,
    typeId: buildTypeSchema(blueprint),
    iaOrganizationName: yup.object().when(['isIndividualActivity'], (isIndividualActivity: boolean) => {
      if (isIndividualActivity) {
        return requiredStringField.max(256, VALIDATION_MESSAGES.IA_ORGANIZATION_NAME_MAX_CHARS);
      }
      return notRequiredStringField;
    }),
  });
};

/**
 * @description BUSINESS RULE 35
 * The option to select boards should only popuate if the MOC option has been selected.
 *
 * Providers can unselect boards that have not had learners reported.
 *
 * Individualized Learners Added: If learners have been reported for a specific certifying board, the board cannot be
 * unselected. Other boards with no learners can be removed.
 *
 * If learners have been reported and then deleted from the system and delete was successfully processed with the
 * board, then the board can be deselected.
 */
const buildBoardSelectionsSchema = ({
  boardLearnersReportedDictionary,
}: IActivityValidatorBlueprint): yup.AnySchema => {
  const requiredBoardIds = keys(boardLearnersReportedDictionary);
  return yup
    .array()
    .test({
      message: 'Cannot remove boards with reported learners',
      name: 'requiredBoardsTest',
      test: (boardSelections: string[]): boolean => {
        let isValid = true;
        requiredBoardIds.forEach((boardId: string): void => {
          if (!boardSelections?.includes(boardId) && boardLearnersReportedDictionary[boardId] === true) isValid = false;
        });
        return isValid;
      },
    })
    .test({
      message: VALIDATION_MESSAGES.CERTIFYING_BOARD_MIN,
      name: 'boardDetailsLengthTest',
      test: (value: string[], context: yup.TestContext): boolean => {
        const isMoc = context.resolve(yup.ref('isMoc')) as string;
        return booleanTrueValueRegex.test(isMoc) ? value?.length > 0 : true;
      },
    });
};

/**
 * @description BUSINESS RULE 28
 * This field is required for JA and non-JA. For NARS this field is not required.
 * The description can be edited regardless if learners have been added. There is a 2,500 character limit.
 * It cannot be removed completely if there are learners reported.
 * NOTE: The 2500 character limit is not a hard requirement
 *
 * Individualized Learners Added: This can be edited
 *
 * @param hasLearners
 * @param rollupOrganizationEnum
 */
const descriptionSchema = (hasLearners: boolean, rollupOrganizationEnum: RollupOrganizationEnums) => {
  if (rollupOrganizationEnum === RollupOrganizationEnums.NARS) {
    return notRequiredStringField.max(2500, VALIDATION_MESSAGES.DESCRIPTION_MAX_CHARS);
  } else {
    return hasLearners
      ? requiredStringField.max(2500, VALIDATION_MESSAGES.DESCRIPTION_MAX_CHARS)
      : notRequiredStringField.max(2500, VALIDATION_MESSAGES.DESCRIPTION_MAX_CHARS);
  }
};

/**
 * @description BUSINESS RULE 29
 * Credit values should be in .25 increments. AMA amount should be a minimum of 0.25 credits and a max of 999 credits.
 *
 * Individualized Learners Added: The number of credits can be adjusted, but cannot be lower than the amount of credit reported for learners.
 * If the credit needs to be adjusted to a greater amount, there are no restrictions. If the credit needs to be lower, PARS should check the
 * records for the activity. EX: An activity has been registered for 20 credits but needs to be lowered to 15. If a learner was reported for
 * a sum of 19 points across all submissions in that activity, PARS should not allow this until the learner has been adjusted/deleted. If the
 * highest amount of points awarded was 14, the system would allow the change. These rules should be in effect for the following activity
 * types: Course, Regularly Scheduled Series, Enduring Material, Performance Improvement
 *
 * For certain activity types which allow credit in excess of the total credit available this can be ignored: Internet Searching and Learning,
 * Learning from Teaching, Test Item Writing, Manuscript Review, Committee Learning, Journal-based CE, Other"
 */
const buildCreditsSectionSchema = ({
  amaCreditTerm,
  creditTypesDictionary,
  learnerMaximumCreditAmounts,
  hasLearners,
  activityTypesWithLearnerCreditValidation,
  selectedActivityType,
}: IActivityValidatorBlueprint): yup.AnyObjectSchema => {
  const baseCreditsSchema: IDictionary<yup.NumberSchema> = {};

  const creditTypeKeys = keys(creditTypesDictionary);
  creditTypeKeys?.forEach((creditKey: string): void => {
    const creditIncrement = creditTypesDictionary[creditKey]?.metadataNumber2 ?? 0.25;
    if (creditIncrement === 0) {
      baseCreditsSchema[creditKey] = yup.number().test({
        message: `Credit value must be zero.`,
        name: 'creditAmountZero',
        test: (val: number, context: yup.TestContext): boolean => {
          const { resolve } = context;
          const creditIsChecked = resolve(yup.ref(`credits${creditKey}`)) as boolean;
          return creditIsChecked ? isNumber(val) && val === 0 : true;
        },
      });
      return;
    }
    const creditIncrementsSchema = yup.number().test({
      message: `Credit value must be in ${creditIncrement} increments. Standard rounding rules apply.`,
      name: 'creditAmountIncrementTest',
      test: (val: number, context: yup.TestContext): boolean => {
        const { resolve } = context;
        const creditIsChecked = resolve(yup.ref(`credits${creditKey}`)) as boolean;
        return creditIsChecked ? isNumber(val) && isAlmostInteger(val / creditIncrement) : true;
      },
    });
    baseCreditsSchema[creditKey] = creditIncrementsSchema
      .test({
        message: VALIDATION_MESSAGES.CREDIT_MIN_WITH_LEARNERS,
        name: 'creditToLearnersTest',
        test: (val: number, context: yup.TestContext): boolean => {
          if (!learnerMaximumCreditAmounts) {
            return true;
          }
          const { resolve } = context;
          const creditIsChecked = resolve(yup.ref(`credits${creditKey}`)) as boolean;
          const hasCreditsReported = isNumber(learnerMaximumCreditAmounts?.[creditKey]);
          return hasLearners &&
            creditIsChecked &&
            hasCreditsReported &&
            activityTypesWithLearnerCreditValidation?.includes(selectedActivityType)
            ? learnerMaximumCreditAmounts[creditKey] <= val
            : true;
        },
      })
      .test({
        message: VALIDATION_MESSAGES.CREDIT_MIN_WITH_LEARNERS,
        name: 'amaValueModTest',
        test: (val: number, { resolve }: yup.TestContext): boolean => {
          if (!learnerMaximumCreditAmounts) {
            return true;
          }
          const isCreditChecked: boolean = resolve(yup.ref(`credits${creditKey}`)) as boolean;
          if (typeof learnerMaximumCreditAmounts?.[creditKey] === 'number') {
            if (!isCreditChecked) {
              return false;
            }
            if (typeof val !== 'number') {
              return true;
            }
            // If the min credit value does not need to be validated based on the credit type, return true
            if (!activityTypesWithLearnerCreditValidation?.includes(selectedActivityType)) {
              return true;
            }
            if (creditKey === amaCreditTerm?.id) {
              const maxReportedCreditValue: number = learnerMaximumCreditAmounts[creditKey];
              if (typeof maxReportedCreditValue !== 'number') {
                return true;
              }
              return val >= maxReportedCreditValue;
            }
          }
          return true;
        },
      })
      .test({
        message: VALIDATION_MESSAGES.CREDIT_MIN,
        name: 'creditsPositiveTest',
        test: (val: number, context: yup.TestContext) => {
          const { resolve } = context;
          const creditIsChecked = resolve(yup.ref(`credits${creditKey}`)) as boolean;
          return creditIsChecked ? val > 0 : true;
        },
      })
      .test({
        message: VALIDATION_MESSAGES.CREDIT_MAX,
        name: 'creditValueMaxTest',
        test: (val: number, context: yup.TestContext): boolean => {
          const { resolve } = context;
          const creditIsChecked = resolve(yup.ref(`credits${creditKey}`)) as boolean;
          return creditIsChecked ? val <= 999 : true;
        },
      });
  });
  return yup.object().shape(baseCreditsSchema);
};

/**
 * @description BUSINESS RULE 31
 * This can be edited at any time for all. Must be a valid URL and cannot be empty if learners are reported.
 *
 * Individualized Learners Added: This can be edited
 */
const urlSchema = (hasLearners: boolean) =>
  hasLearners
    ? yup
        .string()
        .required(VALIDATION_MESSAGES.REQUIRED_TO_CONTINUE)
        .matches(urlPrefixRegex, VALIDATION_MESSAGES.URL_FORMAT)
        .matches(urlContentRegex, VALIDATION_MESSAGES.URL_FORMAT)
    : yup
        .string()
        .notRequired()
        .matches(urlPrefixRegex, VALIDATION_MESSAGES.URL_FORMAT)
        .matches(urlContentRegex, VALIDATION_MESSAGES.URL_FORMAT);

/**
 * @description BUSINESS RULE 32
 * The option to deselect MOC can only be done when learners have not been reported for any certifying board.
 *
 * Individualized Learners Added: If learners have been reported and then deleted from the system and processed with the board, then the
 * option can be deselected as well. Learners reported for REMS do not count.
 *
 * MOC selections (initial or additional boards) can be done at any time.
 */
// @ts-ignore
const buildMocSchema = ({ amaCreditTerm, boardLearnersReportedDictionary }: IActivityValidatorBlueprint) => {
  const reportedBoards = keys(boardLearnersReportedDictionary);
  return reportedBoards?.length > 0
    ? yup.string().matches(booleanTrueValueRegex)
    : notRequiredStringField.test({
        message: VALIDATION_MESSAGES.MOC_REQUIRES_AMA,
        name: 'mocRequiresAmaCreditTest',
        test: (isMoc, context) => {
          const { resolve } = context;
          const isAmaCreditChecked = resolve(yup.ref(`credits.credits${amaCreditTerm?.id}`));
          const amaCreditValue = resolve(yup.ref(`credits.${amaCreditTerm?.id}`));
          const isMocTrue = booleanTrueValueRegex.test(isMoc);

          if (isMocTrue) {
            return !!(amaCreditValue > 0 && isAmaCreditChecked);
          }
          return true;
        },
      });
};

/**
 * @description BUSINESS RULE 34
 *
 * Can be de-selected if no REMS learners have been added.
 *
 * Individualized Learners Added: If learners have been reported for the REMS program, providers cannot unselect REMS. If all learners are
 * deleted from the activity, the option to unselect REMS will become available. Learners reported for MOC/State Boards do not count."
 */
const buildRemsSchema = ({ remsLearnersReported }: IActivityValidatorBlueprint): yup.StringSchema => {
  return remsLearnersReported ? yup.string().matches(booleanTrueValueRegex) : notRequiredStringField;
};

const buildRemsIdsSchema = () => {
  return yup
    .mixed()
    .transform((val, original) => {
      if (original?.length > 0) {
        if (isArray(original)) {
          return val[0];
        }
        return val;
      }
      return '';
    })
    .test({
      message: VALIDATION_MESSAGES.REMS_PROGRAM_REQUIRED,
      name: 'remsProgramRequiredTest',
      test: (val, context) => {
        const { resolve } = context;
        const isRems = resolve(yup.ref('isRems')) as string;
        const isRemsTrue = booleanTrueValueRegex.test(isRems);
        return isRemsTrue ? !!val?.length : true;
      },
    });
};

/**
 * @function buildSequenceNumberSchema
 * @description Build the schema for pharmacySequenceNumber
 * @returns yup.NumberSchema
 */
const buildSequenceNumberSchema = (): yup.NumberSchema =>
  yup
    .number()
    .min(0, VALIDATION_MESSAGES.SEQUENCE_NUMBER_INVALID)
    .max(9999, VALIDATION_MESSAGES.SEQUENCE_NUMBER_INVALID)
    .typeError('')
    .notRequired();

/**
 * @function buildInformationForLearnersSchema
 * @description Build the Information for Learners schema for the Activity Edit page
 * @returns InformationForLearnersSchema
 * @param blueprint
 * @param rollupOrganizationEnum
 */
const buildInformationForLearnersSchema = (
  blueprint: IActivityValidatorBlueprint,
  rollupOrganizationEnum: RollupOrganizationEnums,
): yup.AnyObjectSchema =>
  yup.object().shape({
    boardMocDetailsSelection: buildBoardSelectionsSchema(blueprint),
    credits: buildCreditsSectionSchema(blueprint),
    description: descriptionSchema(blueprint.hasLearners, rollupOrganizationEnum),
    detailsUrl: urlSchema(blueprint.hasLearners),
    isMoc: buildMocSchema(blueprint),
    isRems: buildRemsSchema(blueprint),
    pharmacySequenceNumber: buildSequenceNumberSchema(),
    supportedRemsIds: buildRemsIdsSchema(),
  });

/**
 * @description BUSINESS RULE 36
 *
 * Can be alpha-numeric. If a provider attempts to enter their organization ID, messaging should appear with additional information
 *
 * Individualized Learners Added: This can be edited.
 */
const buildInternalIdSchema = ({ orgId }: IActivityValidatorBlueprint) => {
  return notRequiredStringField
    .test({
      message: 'Internal Id must not match Organization Id',
      name: 'internalIdNotOrgId',
      test: (internalId: string): boolean => internalId !== orgId,
    })
    .test({
      message: 'Internal Id must be 256 characters or fewer',
      name: 'internalId256Chars',
      test: (internalId: string): boolean => (internalId ? internalId.length <= 256 : true),
    });
};

/**
 * @description BUSINESS RULE 37
 *
 * One option should be selected: Direct or Joint.
 *
 * If the activity is directly provided then no additional information will appear in this section.
 *
 * If the activity is jointly provided, a field will appear below with open text to allow the provider to enter the name of an
 * organization. The option to add an additional Joint Provider is there if selected, and providers can remove additional Joint
 * Providers if necessary, but one entry field must be present. If the provider goes back to 'direct' then the text field will disappear.
 *
 * Individualized Learners Added: This can be edited.
 */
const jointProvidersSchema = yup.array().when('isJointlyProvided', {
  is: (val) => /^(joint)$/.test(val),
  otherwise: yup.array().notRequired(),
  then: yup
    .array()
    .of(
      yup.mixed().test({
        message: 'Please enter a joint provider',
        name: 'noEmptyJointProvidersTest',
        test: (val: string[]): boolean => val?.length > 0,
      }),
    )
    .min(1, 'Joint provided requires 1 provider'),
});

/**
 * @description BUSINESS RULE 39
 *
 * Should have the options of Yes or No.
 *
 * If Yes: Dropdown of support sources will appear along with a monetary/inkind option. Provider should have a list to choose from an
 * have a smart text box where they can begin typing and a list will autopopulate below.
 *
 * If No: Nothing else happens with the form.
 *
 * Activity can be saved as active without selecting CS sources, but if the provider selects 'yes' the information must be completed for
 * activity to be complete in terms of data. Commercial Support amounts entered must be >=0 and reference USD currency.
 *
 * Individualized Learners Added: This can be edited.
 */
const hasCommercialSupportSchema = booleanFieldSchema.matches(booleanFormFieldRegex, 'Required field');

const buildMonetarySupportSourcesSchema = (): yup.AnySchema =>
  yup.array().of(
    yup.object().shape({
      amountGiven: yup.lazy(() =>
        yup
          .mixed()
          .test({
            message: 'Please enter an amount given of $0 or more',
            name: 'isValidAmountGiven',
            test: (val: number): boolean => (isNumber(val) ? val >= 0 : true),
          })
          .notRequired(),
      ),
      hasInKindSupport: yup.boolean().isFalse().notRequired(),
      id: yup.string().notRequired(),
      source: yup.string().notRequired().nullable(true),
    }),
  );
const buildInKindSupportSourcesSchema = (): yup.AnySchema => yup.array().of(yup.string().notRequired().nullable(true));

/**
 * @function buildAccreditationDetailsSchema
 * @description Build the Accreditation Details schema for the Activity Edit page
 * @returns AccreditationDetailsSchema
 * @param blueprint
 */
const buildAccreditationDetailsSchema = (blueprint: IActivityValidatorBlueprint): yup.AnyObjectSchema =>
  yup.object().shape({
    commercialInKindSupportSources: buildInKindSupportSourcesSchema(),
    commercialMonetarySupportSources: buildMonetarySupportSourcesSchema(),
    hasCommercialSupport: hasCommercialSupportSchema,
    internalId: buildInternalIdSchema(blueprint),
    isJointlyProvided: booleanFieldSchema.matches(jointTypeRegex),
    jointProviders: jointProvidersSchema,
  });

/**
 * @function buildInformationForContentTaggingSchema
 * @description Build the schema for content tagging
 * @returns ContentTaggingDetailsSchema
 * @param blueprint
 */
const buildInformationForContentTaggingSchema = (blueprint: IActivityValidatorBlueprint): yup.AnyObjectSchema =>
  yup.object().shape({
    pharmacyContentTagStateTaxonomyTerms: yup
      .array()
      .when(
        [
          `credits.credits${blueprint?.pharmacyCreditTerm?.id}`,
          `credits.${blueprint?.pharmacyCreditTerm?.id}`,
          'hasPharmacyContentTags',
        ],
        (...args) => {
          // typescript thinks this should take 2 arguments, but it actually takes n+1 arguments, so we'll do the casting ourselves
          const [isPharmacyChecked, pharmacyCreditValue, hasPharmacyContentTags] = args as [
            boolean | undefined,
            number | undefined,
            string | undefined,
          ];
          const hasPharmacyCredits = isPharmacyChecked && pharmacyCreditValue > 0;
          const isPharmacyContentTagsChecked = hasPharmacyContentTags?.endsWith('1');
          return hasPharmacyCredits && isPharmacyContentTagsChecked
            ? yup.array().min(1, 'Please select at least one state')
            : yup.array().notRequired();
        },
      ),
  });

/**
 * @function buildBoardsSchema
 * @description Build a schema for each board in taxonomy
 * @param IActivityValidatorBlueprint
 * @returns BoardsSchema
 */
const buildBoardsSchema = ({
  amaCreditValue,
  activityTypesWithLearnerCreditValidation,
  boardKeys,
  boardLearnersReportedDictionary,
  configuredBoards,
  maximumCertifyingBoardCredits,
  status,
  selectedActivityType,
}: IActivityValidatorBlueprint & {
  amaCreditValue: number;
  boardKeys: string[];
}): IDictionary<yup.AnyObjectSchema> => {
  const baseBoardSchema: IDictionary<yup.AnyObjectSchema> = {};
  const mocConfigs = configuredBoards?.filter(({ id }: IBCTBoard): boolean => boardKeys?.includes(id));
  mocConfigs?.forEach(({ contentOutlines, creditIncrement, creditTypes, id, specialties }: IBCTBoard): void => {
    if (!boardKeys?.includes(id)) {
      baseBoardSchema[id] = yup.object().notRequired();
      return;
    }
    baseBoardSchema[id] = yup.object().shape({
      contentOutlinesIds: yup
        .array()
        .of(
          yup.string().oneOf(
            contentOutlines?.map(({ id }) => id),
            'Please select a valid content outline',
          ),
        )
        .notRequired(),
      mocPointsGiven: yup
        .number()
        .test({
          message: VALIDATION_MESSAGES.MOC_CREDIT_BELOW_CME,
          name: 'mocMinCreditTest',
          test: (val) => (amaCreditValue ? (val ? val <= amaCreditValue : true) : true),
        })
        .test({
          message: VALIDATION_MESSAGES.MOC_CREDIT_MIN_WITH_LEARNERS,
          name: 'mocMinWithLearnersTest',
          test: (val) => {
            if (status === StatusEnum.DRAFT || !val) return true;
            return activityTypesWithLearnerCreditValidation?.includes(selectedActivityType) &&
              boardLearnersReportedDictionary[id]
              ? val >= maximumCertifyingBoardCredits[id]
              : true;
          },
        })
        .test({
          message: VALIDATION_MESSAGES.MOC_CREDIT_EQUAL_CME,
          name: 'mocMatchCmeCreditTest',
          test: (val: number, { resolve }: yup.TestContext): boolean => {
            if (!val) return true;
            const typesOfCreditIds: string[] = resolve(yup.ref('typesOfCreditIds')) as string[];
            const isAnyCreditCMECredit: boolean = typesOfCreditIds?.some((creditId: string): boolean =>
              creditTypes.some(
                ({ id, mustMatchCmeAmount }: IBCTCreditType): boolean => id === creditId && mustMatchCmeAmount,
              ),
            );
            return isAnyCreditCMECredit ? val === amaCreditValue : true;
          },
        })
        .test({
          message: `Credit value must be in ${creditIncrement} increments. Standard rounding rules apply (ex: 3.89 rounds up to 4.00).`,
          name: 'mocCreditIncrementTest',
          test: (val: number) =>
            isNumber(creditIncrement) && creditIncrement > 0 && !!val
              ? isNumber(val) && isAlmostInteger(val / creditIncrement)
              : true,
        }),
      practiceIds: yup
        .array()
        .of(
          yup.string().oneOf(
            specialties?.map(({ id }) => id),
            'Please select a valid practice area',
          ),
        )
        .notRequired(),
      typesOfCreditIds: yup
        .array()
        .of(
          yup.string().oneOf(
            creditTypes?.map(({ id }) => id),
            'Please select a valid credit type',
          ),
        )
        .test({
          message: VALIDATION_MESSAGES.MOC_CREDIT_WITH_OTHER,
          name: 'mocCreditSubmitWithOthersTest',
          test: (val: string[]) => {
            // If no credits are selected, skip and return true
            if (!val?.length) {
              return true;
            }
            const creditTypesSubmitWithOthers: IBCTCreditType[] = creditTypes.filter(
              ({ boardCreditTypeSubmissionType }: IBCTCreditType): boolean =>
                boardCreditTypeSubmissionType === BoardCreditTypeSubmissionTypes.WITH_ANY_OTHER_CREDIT_TYPE,
            );
            // If no configured credits require additional credits to submit, return true
            if (!creditTypesSubmitWithOthers?.length) {
              return true;
            }
            const isSelectedCreditsSubmitWithOther = creditTypesSubmitWithOthers.some(
              ({ id }: IBCTCreditType): boolean => val?.includes(id),
            );
            return isSelectedCreditsSubmitWithOther ? val.length > 1 : true;
          },
        })
        .test({
          name: 'mocCreditSubmitWithSpecificTest',
          test: (val: string[], context: yup.TestContext): boolean | yup.ValidationError => {
            // If no credits are selected, skip and return true
            if (!val?.length) {
              return true;
            }
            const dependentCreditTypes: IBCTCreditType[] = creditTypes.filter(
              ({ boardCreditTypeSubmissionType }: IBCTCreditType): boolean =>
                boardCreditTypeSubmissionType === BoardCreditTypeSubmissionTypes.WITH_SPECIFIC_CREDIT_TYPES,
            );
            // If no configured credits have dependencies, return true
            if (!dependentCreditTypes.length) {
              return true;
            }
            const selectedDependentCreditIds: string[] = val?.filter((creditId: string): boolean =>
              dependentCreditTypes.some(({ id }: IBCTCreditType): boolean => creditId === id),
            );
            // If no selected credits have dependencies, return true
            if (!selectedDependentCreditIds?.length) {
              return true;
            }
            const creditsMissingDependencies: string[] = selectedDependentCreditIds.filter(
              (creditId: string): boolean =>
                dependentCreditTypes
                  .find(({ id }: IBCTCreditType): boolean => creditId === id)
                  .boardCreditTypeRequiredCreditTypeIds.some(
                    (dependentId: string): boolean => !val?.includes(dependentId),
                  ),
            );

            const firstFailingCreditId: string = first(creditsMissingDependencies);
            // If no selected credits are missing dependencies, return true
            if (!firstFailingCreditId) {
              return true;
            }
            const selectedCreditType: IBCTCreditType = creditTypes.find(
              ({ id }: IBCTCreditType): boolean => id === firstFailingCreditId,
            );
            const selectedCreditName: string = selectedCreditType?.organizationName;
            const dependentCreditNames: string[] = selectedCreditType.boardCreditTypeRequiredCreditTypeIds.map(
              (dependentId: string): string =>
                creditTypes.find(({ id }: IBCTCreditType): boolean => dependentId === id)?.organizationName,
            );
            // If any names failed to be retrieved due to unknown error, return true
            if (!selectedCreditName || !dependentCreditNames?.length) {
              return true;
            }
            const { path } = context;
            const validationString = `${selectedCreditName} must be submitted with ${dependentCreditNames.join(', ')}`;
            return new yup.ValidationError(validationString, val, path);
          },
        })
        .notRequired(),
    });
  });
  return baseBoardSchema;
};

/**
 * @function buildMocDetailsSchema
 * @description Build the MOC Details schema for the Activity Edit page
 * @returns MocDetailsSchema
 * @param blueprint
 */
const buildMocDetailsSchema = (blueprint: IActivityValidatorBlueprint): yup.AnyObjectSchema =>
  yup.object().shape({
    boardMocDetails: yup
      .object()
      .when(
        [`credits.credits${blueprint?.amaCreditTerm?.id}`, `credits.${blueprint?.amaCreditTerm?.id}`],
        (isAmaChecked, amaCreditValue) => {
          const isAmaCredits = isAmaChecked && amaCreditValue > 0;
          return isAmaCredits
            ? yup
                .object()
                .when(
                  ['boardMocDetailsSelection'],
                  (boardKeys: string[]): yup.AnyObjectSchema =>
                    yup.object().shape(buildBoardsSchema({ ...blueprint, amaCreditValue, boardKeys })),
                )
            : yup.object().notRequired();
        },
      ),
    mocCreditDeadline: yup.lazy((value) => {
      if (!value) {
        return yup.mixed().notRequired();
      }
      return yup
        .date()
        .typeError('')
        .test({
          message: VALIDATION_MESSAGES.MOC_CLAIM_DATE_AFTER_END_DATE,
          name: 'creditDeadlineAfterEndDateTest',
          test: (val: Date, context: yup.TestContext): boolean => {
            const { resolve } = context;
            const activityDates: IDatesAndLocation[] = resolve(yup.ref('tempDatesAndLocation')) as IDatesAndLocation[];
            // Sort the date entries by end date, desc
            const sortedDates = orderBy(activityDates, 'endDate', ['desc']);
            // Since the date entries are sorted descending, the first entry is the latest endDate.
            // Confirmed with ACCME, validate against the LATEST entered endDate
            return !moment(val).isBefore(moment(sortedDates[0]?.endDate), 'days');
          },
        })
        .test({
          message: VALIDATION_MESSAGES.MOC_CLAIM_DATE_WITH_LEARNERS,
          name: 'creditDeadlineMinDateTest',
          test: (val: Date): boolean =>
            !blueprint.learnerLatestCompletion ||
            !moment(val).isBefore(moment(blueprint.learnerLatestCompletion), 'days'),
        });
    }),
    mocProgramAttestation: yup
      .array()
      .of(yup.string())
      .test({
        message: 'Cannot deselect with boards reported',
        name: 'mocAttestationTest',
        test: (val: string[]): boolean =>
          keys(blueprint.boardLearnersReportedDictionary)?.length > 0 ? val?.length > 0 : true,
      }),
  });

/**
 * @function buildAdditionalQuestionsSchema
 * @description Build the REMS additional questions schema
 * @returns IDictionary<StringSchema>
 * @param boardActivityAdditionalFields
 */
const buildAdditionalQuestionsSchema = (
  boardActivityAdditionalFields: IBoardAdditionalFields[],
): IDictionary<yup.StringSchema> => {
  const questionIds: string[] = boardActivityAdditionalFields?.map(({ id }: IBoardAdditionalFields): string => id);
  const questionsSchema: IDictionary<yup.StringSchema> = {};
  if (!questionIds?.length) {
    return { blankSchema: yup.string().notRequired() };
  }
  questionIds?.forEach(
    (questionId: string) =>
      (questionsSchema[questionId] = yup.string().required(VALIDATION_MESSAGES.REMS_QUESTIONS_REQUIRED)),
  );
  return questionsSchema;
};

/**
 * @function buildRemsCollabSchema
 * @description Build the REMS schema for each collab for the Activity
 * @param IActivityValidatorBlueprint
 * @returns IDictionary<AnyObjectSchema>
 */
const buildRemsCollabSchema = ({
  configuredBoards,
  remsIds,
  remsLearnersReported,
  activityCreatedDate,
}: IActivityValidatorBlueprint & {
  remsIds: string[];
  activityCreatedDate: string;
}): IDictionary<yup.AnyObjectSchema> => {
  const remsBoardSchema: IDictionary<yup.AnyObjectSchema> = {};
  const remsConfigs = configuredBoards?.filter(({ id }: IBCTBoard): boolean => remsIds?.includes(id));
  remsConfigs?.forEach(({ boardActivityAdditionalFields, boardRemsIdRegularExpressions, id }: IBCTBoard): void => {
    const remsRegExData = getRemsRegularExpression(boardRemsIdRegularExpressions, moment(activityCreatedDate));
    if (!remsIds?.includes(id)) {
      remsBoardSchema[id] = yup.object().notRequired();
      return;
    }
    remsBoardSchema[id] = yup.object().shape({
      additionalQuestionAnswers: yup.object().shape(buildAdditionalQuestionsSchema(boardActivityAdditionalFields)),
      isAttested: yup
        .boolean()
        .test({
          message: 'Cannot deselect with REMS reported',
          name: 'remsAttestationTest',
          test: (isChecked: boolean): boolean => (remsLearnersReported ? isChecked : true),
        })
        .notRequired(),
      remsActivityId: yup
        .string()
        .test({
          message: remsRegExData?.errorMessage,
          name: 'remsActivityIdRegexTest',
          test: (val?: string) =>
            !new RegExp(remsRegExData?.regularExpression) ||
            !(val?.length > 0) ||
            new RegExp(remsRegExData?.regularExpression)?.test(val),
        })
        .notRequired(),
    });
  });
  return remsBoardSchema;
};

/**
 * @function buildRemsDetailsSchema
 * @description Build the REMS Details schema for the Activity
 * @returns AnyObjectSchema
 * @param blueprint
 */
const buildRemsDetailsSchema = (blueprint: IActivityValidatorBlueprint): yup.AnyObjectSchema =>
  yup.object().shape({
    boardRemsDetails: yup.object().when(['supportedRemsIds', 'createdDate'], (remsIds, activityCreatedDate) => {
      if (remsIds?.length) {
        return yup.object().shape(buildRemsCollabSchema({ ...blueprint, activityCreatedDate, remsIds }));
      }
      return yup.object().notRequired();
    }),
  });

export const buildActivityValidationSchemas = (
  blueprint: IActivityValidatorBlueprint,
  rollupOrganizationEnum: RollupOrganizationEnums,
): yup.AnyObjectSchema[] => {
  const editActivityBasicsSchema = buildActivityBasicsSchema(blueprint);
  const informationForLearnersSchema = buildInformationForLearnersSchema(blueprint, rollupOrganizationEnum);
  const accreditationSchema = buildAccreditationDetailsSchema(blueprint);
  const stateContentTagging = buildInformationForContentTaggingSchema(blueprint);
  const mocDetailsSchema = buildMocDetailsSchema(blueprint);
  const remsSchema = buildRemsDetailsSchema(blueprint);

  return [
    editActivityBasicsSchema,
    informationForLearnersSchema,
    accreditationSchema,
    stateContentTagging,
    mocDetailsSchema,
    remsSchema,
  ];
};

export const blankSchema = yup.mixed().notRequired();
