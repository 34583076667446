import { Fragment, ReactElement, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import CreateIcon from '@material-ui/icons/Create';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import get from 'lodash/get';

// Components
import { FormikRadioField } from 'components/ContinuousImprovement/PureFormElements';
import { Modal } from 'components/ContinuousImprovement';
import { TaxonomyModalForm } from '../TaxonomyModalForm';

// Styles
import styles from './RadioTable.module.scss';

// Constants
import { learnerCompletionOptions } from 'layouts/pages/bct/constants';

// Store + Core
import { popToast } from 'store/toast/actions';
import { infoToastOptions } from 'store/toast/constants';
import { currentBoardSelector } from 'store/bct/selectors';

// Types
import {
  BoardLearnerCollectionFields,
  BoardTypes,
  CollectionTypes,
  IBCTBoard,
  IBCTBoardLearnerCollectionField,
} from 'layouts/pages/bct/types';

interface IProps {
  formikKey: string;
}

export const RadioTable = (props: IProps): ReactElement => {
  const { formikKey } = props;
  const { setFieldValue, values } = useFormikContext();
  const dispatch = useDispatch();
  const [openTaxonomyModalTerm, setOpenTaxonomyModalTerm] = useState<string>(null);

  // Selectors
  const currentBoard: IBCTBoard = useSelector(currentBoardSelector);
  const isRems: boolean = currentBoard?.type === BoardTypes.REMS;

  const handleOpenTaxonomyModal = (taxonomyTerm): void => {
    setOpenTaxonomyModalTerm(taxonomyTerm);
  };

  // Filter out the `prescribed` question from the radio fields if not isRems.
  const filteredLearnerCompletionOptions = isRems
    ? learnerCompletionOptions
    : learnerCompletionOptions.filter(
        ({ fieldType }) => fieldType !== BoardLearnerCollectionFields.PRESCRIBED_IN_PAST_12_MONTHS,
      );

  return (
    <TableContainer role="grid">
      <Table className={styles.table} size="small">
        <TableHead className={styles.tableHeader}>
          <TableRow>
            <TableCell component="th">Identifier</TableCell>
            <TableCell align="center" component="th">
              {CollectionTypes.REQUIRED}
            </TableCell>
            <TableCell align="center" component="th">
              {CollectionTypes.OPTIONAL}
            </TableCell>
            <TableCell align="center" component="th">
              {CollectionTypes.NOT_COLLECTED}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray
            name={formikKey}
            render={(): ReactElement => {
              return (
                <>
                  {filteredLearnerCompletionOptions.map(
                    (field: IBCTBoardLearnerCollectionField, idx: number): ReactElement => {
                      const { fieldType, taxonomyTerm } = field;
                      const formikIndex = `${formikKey}.${idx}`;
                      const tempValues: string[] = get(values, `${formikIndex}.temp`) || [];
                      const taxonomyTermIds: string[] = get(values, `${formikIndex}.taxonomyTermIds`) || [];

                      const handleCancelTaxonomyModal = (): void => {
                        // Reset the `temp` to the initial `taxonomyTermIds`.
                        setFieldValue(`${formikIndex}.temp`, taxonomyTermIds);

                        // Close the modal.
                        setOpenTaxonomyModalTerm(null);
                      };

                      const handleSaveTaxonomyModal = (): void => {
                        // Set the `taxonomyTermIds` to what is in `temp`.
                        setFieldValue(`${formikIndex}.taxonomyTermIds`, tempValues);

                        // Close the modal.
                        setOpenTaxonomyModalTerm(null);

                        // Show a toast.
                        dispatch(
                          popToast({ ...infoToastOptions, centered: false, message: <>{fieldType} values saved.</> }),
                        );
                      };

                      return (
                        <Fragment key={fieldType}>
                          <TableRow role="row">
                            <TableCell component="td" scope="row">
                              {fieldType}
                              {taxonomyTerm && (
                                <IconButton
                                  aria-label="edit"
                                  onClick={(): void => handleOpenTaxonomyModal(taxonomyTerm)}
                                >
                                  <CreateIcon />
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell align="center" component="td">
                              <Box display="flex" justifyContent="center">
                                <FormikRadioField
                                  formikKey={`${formikIndex}.collectionType`}
                                  name={CollectionTypes.REQUIRED}
                                  suppressLabel
                                />
                              </Box>
                            </TableCell>
                            <TableCell component="td">
                              <Box display="flex" justifyContent="center">
                                <FormikRadioField
                                  formikKey={`${formikIndex}.collectionType`}
                                  name={CollectionTypes.OPTIONAL}
                                  suppressLabel
                                />
                              </Box>
                            </TableCell>
                            <TableCell component="td">
                              <Box display="flex" justifyContent="center">
                                <FormikRadioField
                                  formikKey={`${formikIndex}.collectionType`}
                                  name={CollectionTypes.NOT_COLLECTED}
                                  suppressLabel
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                          {taxonomyTerm && (
                            <Modal
                              isOpen={openTaxonomyModalTerm === taxonomyTerm}
                              onClose={handleCancelTaxonomyModal}
                              title={`Edit ${fieldType}`}
                            >
                              <Box mb={4}>
                                <strong>Edit values to display to this board for learner completion.</strong>
                              </Box>
                              <TaxonomyModalForm
                                formikKey={formikIndex}
                                formikTempKey={`${formikIndex}.temp`}
                                onCancel={handleCancelTaxonomyModal}
                                onSave={handleSaveTaxonomyModal}
                                option={field}
                              />
                            </Modal>
                          )}
                        </Fragment>
                      );
                    },
                  )}
                </>
              );
            }}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};
