import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import InputBlock from '../../../../components/global/inputBlock/inputBlock';
import { FormControl, Grid, InputLabel } from '@material-ui/core';
import { useSelector } from 'react-redux';
import styles from './CreateWebServiceAccount.module.scss';
import { Link } from 'react-router-dom';
import { KeyboardBackspaceRounded } from '@material-ui/icons';
import classNames from 'classnames';
import Button from '../../../../components/Button/Button';
import { buildFinalFormSchema, buildProviderSchema, buildWebServiceAccountSchema } from './validationSchema';
import { ITypeaheadOption, Typeahead } from '../../../../components/ContinuousImprovement/Typeahead';
import { EnvironmentEnum, IUser, IUserOrganization } from '../../../../core/models';
import { currentOrganizationContextSelector, userProfileSelector } from '../../../../store/user/selectors';
import { sortBy } from 'lodash';
import { Modal } from '../../../../components/ContinuousImprovement';
import { CreateWebServiceAccountForm } from '../../../../components/forms/templates/CreateWebServiceAccountForm';
import { capitalizeWord, getCurrentEnvironment } from '../../../../utils';

const detailContentClass = styles['detail-content'];
const detailGroupClass = styles['detail-group'];
const detailGroupLabelClass = styles['detail-group-label'];

/**
 * Admin component to create a new Webservice Account.
 * @constructor
 */
export const CreateWebServiceAccount = (): ReactElement => {
  const [form, setForm] = useState(null);
  const [submissionError] = useState(false);
  const [provider, setProvider] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [emailFormat, setEmailFormat] = useState('');
  const [environment, setEnvironment] = useState('');
  const [showEmail, setShowEmail] = useState(false);

  const [isCreateWebServiceAccountModalOpen, setCreateWebServiceAccountModalStatus] = useState<boolean>(false);
  const closeCreateWebServiceAccountModal = (): void => setCreateWebServiceAccountModalStatus(false);

  const providerFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      provider: null,
    },
    onSubmit: () => null,
    validationSchema: buildProviderSchema(),
    validateOnBlur: false,
    validateOnChange: false,
  });

  const mainFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      organization: null,
      organizationId: '',
      firstName: '',
      lastName: '',
    },
    onSubmit: () => null,
    validationSchema: buildWebServiceAccountSchema(),
    validateOnBlur: false,
    validateOnChange: false,
  });

  const finalFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: emailFormat,
      password: '',
    },
    onSubmit: () => null,
    validationSchema: buildFinalFormSchema(),
    validateOnBlur: false,
    validateOnChange: false,
  });

  const { errors: mainErrors, touched: mainTouched, dirty: mainDirty, isValid: mainIsValid } = mainFormik;
  const {
    errors: providerErrors,
    touched: providerTouched,
    dirty: providerDirty,
    isValid: providerIsValid,
  } = providerFormik;
  const { errors: finalErrors, touched: finalTouched } = finalFormik;

  const userProfile: IUser = useSelector(userProfileSelector);
  const currentOrganization: IUserOrganization = useSelector(currentOrganizationContextSelector);
  const childOrganizationIds: string[] = currentOrganization?.childOrganizationIds;

  const providerOptions: IUserOrganization[] = useMemo(
    () =>
      sortBy(
        userProfile?.secondaryUserOrganizations?.filter(({ id }) => childOrganizationIds?.includes(id)),
        'organizationName',
        'businessId',
      ),
    [userProfile?.secondaryUserOrganizations, childOrganizationIds],
  );

  const typeaheadOptions: ITypeaheadOption[] = useMemo(
    () =>
      providerOptions?.map(
        ({ businessId, id, organizationName }: IUserOrganization): ITypeaheadOption => ({
          id,
          label: `${businessId} - ${organizationName}`,
        }),
      ),
    [providerOptions],
  );

  /**
   * Set Environment variable from the PARSConfiguration.
   * Production needs to be an empty string because it is not added to the Email while in Production.
   */
  const setEnvironmentVariable = () => {
    const environment = getCurrentEnvironment();
    if (environment === EnvironmentEnum.Production) {
      setEnvironment(' ');
    } else {
      setEnvironment(environment);
    }
  };

  /**
   * Set the Provider and Organization and then build the Email formatting.
   * @param _
   * @param value
   */
  const setOrgProviderAndEmail = (_: string = null, value: ITypeaheadOption = null) => {
    let email: string;

    if (value) {
      setProvider(providerOptions.find((po) => po.id === value?.id));
      setOrganization(value);
    }

    const environmentCheck = getCurrentEnvironment();
    if (environmentCheck === EnvironmentEnum.Production) {
      email = `${mainFormik.values.firstName}${environmentCheck}${provider?.businessId}@`;
    } else {
      email = `${mainFormik.values.firstName}${capitalizeWord(environment)}${provider?.businessId}@`;
    }

    setEmailFormat(email.replaceAll(/\s+/g, ''));
  };

  /**
   * Set values for submission modal.
   */
  const setValues = () => {
    finalFormik.validateForm().then((result) => {
      if (result.email === undefined && result.password === undefined) {
        setForm({
          provider: provider,
          organization: organization,
          organizationId: organization.id,
          email: finalFormik.values.email,
          firstName: mainFormik.values.firstName + capitalizeWord(environment),
          lastName: mainFormik.values.lastName + provider?.businessId,
          password: finalFormik.values.password,
        });
        setCreateWebServiceAccountModalStatus(true);
      }
    });
  };

  useEffect(() => {
    setEnvironmentVariable();
    setOrgProviderAndEmail();
  });

  const renderProviderForm = () => {
    return (
      <FormikProvider value={providerFormik}>
        <Grid item md={6}>
          <InputBlock title="Provider" name="provider" errors={providerErrors} touched={providerTouched}>
            {providerOptions.length > 1 && (
              <FormControl style={{ width: '100%', marginTop: '1rem' }}>
                <InputLabel htmlFor="provider">Provider</InputLabel>
                <Typeahead id="provider" options={typeaheadOptions} name="provider" onChange={setOrgProviderAndEmail} />
              </FormControl>
            )}
          </InputBlock>
        </Grid>
        <Grid item md={6} />
      </FormikProvider>
    );
  };

  const renderMainForm = () => {
    return (
      <FormikProvider value={mainFormik}>
        <Grid item md={6}>
          <InputBlock
            title={`First Name: ${mainFormik.values.firstName}${capitalizeWord(environment)}`}
            name="firstName"
            errors={mainErrors}
            touched={mainTouched}
          >
            <div className="caption-text">Format: First Name[Environment]</div>
            <Field aria-label="firstName" name="firstName" type="text" placeholder="ACCME" id="firstNameContainer" />
          </InputBlock>
        </Grid>
        <Grid item md={6}>
          <InputBlock
            title={`Last Name: ${mainFormik.values.lastName}${provider?.businessId}`}
            name="lastName"
            errors={mainErrors}
            touched={mainTouched}
          >
            <div className="caption-text">Format: Last Name[OrgId]</div>
            <Field aria-label="lastName" name="lastName" type="text" placeholder="Webservices" />
          </InputBlock>
        </Grid>
        <Grid item md={12}>
          <Button
            disabled={!mainDirty || !mainIsValid || !providerDirty || !providerIsValid}
            className="primary"
            onClick={() => {
              setShowEmail(true);
              finalFormik.values.email = emailFormat;
            }}
          >
            Next
          </Button>
        </Grid>
      </FormikProvider>
    );
  };

  const renderFinalForm = () => {
    return (
      <Grid container spacing={2} className={styles.formContainer}>
        <FormikProvider value={finalFormik}>
          <Grid item md={6}>
            <InputBlock title="Email Address" name="email" errors={finalErrors} touched={finalTouched}>
              <div className="caption-text">Format: [FirstName][Environment][OrgId]@[Domain]</div>
              <Field aria-label="email" name="email" type="email" placeholder="ACCMEWebservices1234567@accme.org" />
            </InputBlock>
          </Grid>
          <Grid item md={6}>
            <InputBlock title="Password" name="password" errors={finalErrors} touched={finalTouched}>
              <div className={`${styles.required} ${styles.caption}`}>
                Store this email and password in Keeper before continuing
              </div>
              <Field aria-lable="password" name="password" type="password" placeholder="Password" />
            </InputBlock>
          </Grid>
          <Grid item md={2}>
            <Button
              className="primary"
              onClick={() => {
                setValues();
              }}
            >
              Submit
            </Button>
          </Grid>
          <Grid item md={6} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <Button onClick={() => setShowEmail(false)}>Back</Button>
          </Grid>
        </FormikProvider>
      </Grid>
    );
  };

  return (
    <>
      <div className="form-container">
        <div className="page-navigation">
          <Link className="tertiary" to="/admin">
            <KeyboardBackspaceRounded className="tertiary-icon-back" />
            Admin
          </Link>
        </div>

        <div className="form-title-container">
          <div className={classNames('title', styles.title)}>
            <h4>Create a new Webservice Account</h4>
          </div>
        </div>

        <Grid container spacing={2} className={styles.formContainer}>
          {!showEmail && renderProviderForm()}

          {!showEmail && renderMainForm()}
        </Grid>

        {showEmail && renderFinalForm()}

        {submissionError && (
          <div className={detailGroupClass}>
            <h5 className={detailGroupLabelClass}>Error</h5>
            <div className={detailContentClass}>
              An error occurred while trying to create a new Webservice Account. Please try again or check Application
              Insights for further details.
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={isCreateWebServiceAccountModalOpen}
        onClose={closeCreateWebServiceAccountModal}
        title="Create Webservice Account"
      >
        <CreateWebServiceAccountForm
          formDetails={form}
          onClose={closeCreateWebServiceAccountModal}
          onSuccess={mainFormik.handleSubmit}
        />
      </Modal>
    </>
  );
};
