import { AxiosError } from 'axios';
import {
  CreateLearnerRequest,
  IActivityLearnerSearchResponse,
  IActivityLearnerSearchStateProps,
  IActivityLearnerSummarySearch,
  IActivitySearchStateProps,
  IBatchLearnerRequest,
  IJsonPatchDocumentOperation,
  ILearnerSearchRequest,
  ILearnerMatchingModel,
  Learner,
  LearnerType,
  PARSAction,
  UpdateLearnerRequest,
  ILearnerCompletion,
  IArrayBufferDownload,
  ILearnerMatchResponse,
  IQueueMocLearnerRequest,
  IActivityLearnerSummarySearchResponse,
} from 'core/models';

export interface LearnerState {
  currentLearner?: Learner[];
  currentLearnerCompletion?: ILearnerCompletion;
  error?: string;
  file: { file: File };
  isCreating: boolean;
  isDownloading: boolean;
  isLearnerCompletionAdded: boolean;
  isLoading: boolean;
  isMatchingLearners: boolean;
  isUpdating: boolean;
  learnerActivityDownload?: IArrayBufferDownload;
  learnerSearchDownload?: IArrayBufferDownload;
  learnerSearchRequest: ILearnerSearchRequest;
  learnerSearchResponse: IActivityLearnerSearchResponse;
  learnerSearchStateProps: IActivityLearnerSearchStateProps;
  learnerSummarySearchRequest: IActivityLearnerSummarySearch;
  learnerSummarySearchResponse: IActivityLearnerSummarySearchResponse;
  learnerTypes: LearnerType[];
  matchLearnersQuery: ILearnerMatchingModel;
  matchedLearners: ILearnerMatchResponse[];
  progressPercentage: number;
  recentLearners: ILearnerCompletion[];
  searchStateProps: IActivityLearnerSearchStateProps;
  validationErrorResponse?: AxiosError;
}

export interface LearnerValidationState {
  error?: string;
  file: { file: File };
  isCreating: boolean;
  progressPercentage: number;
}

export const BATCH_LEARNER_VALIDATION = 'BATCH_LEARNER_VALIDATION';
export const BATCH_LEARNER_VALIDATION_CLEAR = 'BATCH_LEARNER_VALIDATION_CLEAR';
export const BATCH_LEARNER_VALIDATION_PROGRESS = 'BATCH_LEARNER_VALIDATION_PROGRESS';
export const BATCH_LEARNER_VALIDATION_SUCCESS = 'BATCH_LEARNER_VALIDATION_SUCCESS';
export const BATCH_LEARNER_VALIDATION_FAILURE = 'BATCH_LEARNER_VALIDATION_FAILURE';

export const BATCH_LEARNER_CLEAR = 'BATCH_LEARNER_CLEAR';
export const CREATE_LEARNER = 'CREATE_LEARNER';
export const CREATE_LEARNER_FAILURE = 'CREATE_LEARNER_FAILURE';
export const CREATE_LEARNER_SUCCESS = 'CREATE_LEARNER_SUCCESS';
export const BATCH_LEARNER = 'BATCH_LEARNER';
export const BATCH_LEARNER_PROGRESS = 'BATCH_LEARNER_PROGRESS';
export const BATCH_LEARNER_SUCCESS = 'BATCH_LEARNER_SUCCESS';
export const BATCH_LEARNER_FAILURE = 'BATCH_LEARNER_FAILURE';
export const GET_LEARNER_TYPES = 'GET_LEARNER_TYPES';
export const EXPORT_LEARNER_ACTIVITIES = 'EXPORT_LEARNER_ACTIVITIES';
export const EXPORT_LEARNERS = 'EXPORT_LEARNERS';
export const GET_LEARNER_TYPES_SUCCESS = 'GET_LEARNER_TYPES_SUCCESS';
export const PATCH_LEARNER = 'PATCH_LEARNER';
export const PATCH_LEARNER_SUCCESS = 'PATCH_LEARNER_SUCCESS';
export const PATCH_LEARNER_FAILURE = 'PATCH_LEARNER_FAILURE';
export const UPDATE_LEARNER = 'UPDATE_LEARNER';
export const UPDATE_LEARNER_SUCCESS = 'UPDATE_LEARNER_SUCCESS';
export const UPDATE_LEARNER_FAILURE = 'UPDATE_LEARNER_FAILURE';

export const UPDATE_LEARNER_SEARCH_ACTIVITIES = 'UPDATE_LEARNER_SEARCH_ACTIVITIES';
export const UPDATE_LEARNER_ACTIVITIES_PAGINATION_STATE = 'UPDATE_LEARNER_ACTIVITIES_PAGINATION_STATE';
export const UPDATE_LEARNER_SEARCH_STATE_PROPS = 'UPDATE_LEARNER_SEARCH_STATE_PROPS';

export const GET_LEARNER_ACTIVITIES = 'GET_LEARNER_ACTIVITIES';
export const GET_LEARNER_ACTIVITIES_SUCCESS = 'GET_LEARNER_ACTIVITIES_SUCCESS';
export const GET_LEARNER_ACTIVITIES_FAILURE = 'GET_LEARNER_ACTIVITIES_FAILURE';

export const DOWNLOAD_LEARNER_ACTIVITIES = 'DOWNLOAD_LEARNER_ACTIVITIES';
export const DOWNLOAD_LEARNER_ACTIVITIES_SUCCESS = 'DOWNLOAD_LEARNER_ACTIVITIES_SUCCESS';
export const DOWNLOAD_LEARNER_ACTIVITIES_FAILURE = 'DOWNLOAD_LEARNER_ACTIVITIES_FAILURE';

export const GET_LEARNER_SEARCH = 'GET_LEARNER_SEARCH';
export const GET_LEARNER_SEARCH_SUCCESS = 'GET_LEARNER_SEARCH_SUCCESS';
export const GET_LEARNER_SEARCH_FAILURE = 'LEARNER_SEARCH_FAILURE';
export const UPDATE_LEARNER_SEARCH = 'UPDATE_LEARNER_SEARCH';
export const RESET_LEARNER_SEARCH_RESPONSE = 'RESET_LEARNER_SEARCH_RESPONSE';

export const MATCH_LEARNERS = 'MATCH_LEARNERS';
export const MATCH_LEARNERS_SUCCESS = 'MATCH_LEARNERS_SUCCESS';
export const MATCH_LEARNERS_FAILURE = 'MATCH_LEARNERS_FAILURE';

export const MATCH_LEARNER_BOARDS = 'MATCH_LEARNER_BOARDS';
export const MATCH_LEARNER_BOARDS_SUCCESS = 'MATCH_LEARNER_BOARDS_SUCCESS';
export const MATCH_LEARNER_BOARDS_FAILURE = 'MATCH_LEARNER_BOARDS_FAILURE';

export const RESET_LEARNER_MATCHES = 'RESET_LEARNER_MATCHES';

export const ADD_LEARNER_COMPLETION = 'ADD_LEARNER_COMPLETION';
export const ADD_LEARNER_COMPLETION_SUCCESS = 'ADD_LEARNER_COMPLETION_SUCCESS';
export const ADD_LEARNER_COMPLETION_FAILURE = 'ADD_LEARNER_COMPLETION_FAILURE';

export const GET_RECENT_LEARNER_COMPLETIONS = 'GET_RECENT_LEARNER_COMPLETIONS';
export const GET_RECENT_LEARNER_COMPLETIONS_SUCCESS = 'GET_RECENT_LEARNER_COMPLETIONS_SUCCESS';
export const GET_RECENT_LEARNER_COMPLETIONS_FAILURE = 'GET_RECENT_LEARNER_COMPLETIONS_FAILURE';

export const GET_LEARNER_COMPLETION = 'GET_LEARNER_COMPLETION';
export const GET_LEARNER_COMPLETION_SUCCESS = 'GET_LEARNER_COMPLETION_SUCCESS';
export const GET_LEARNER_COMPLETION_FAILURE = 'GET_LEARNER_COMPLETION_FAILURE';

export const RESET_LEARNER_COMPLETION = 'RESET_LEARNER_COMPLETION';

export const UPDATE_LEARNER_COMPLETION = 'UPDATE_LEARNER_COMPLETION';
export const UPDATE_LEARNER_COMPLETION_SUCCESS = 'UPDATE_LEARNER_COMPLETION_SUCCESS';
export const UPDATE_LEARNER_COMPLETION_FAILURE = 'UPDATE_LEARNER_COMPLETION_FAILURE';

export const EXPORT_LEARNER_SEARCH = 'EXPORT_LEARNER_SEARCH';
export const EXPORT_LEARNER_SEARCH_SUCCESS = 'EXPORT_LEARNER_SEARCH_SUCCESS';
export const EXPORT_LEARNER_SEARCH_FAILURE = 'EXPORT_LEARNER_SEARCH_FAILURE';

export const DOWNLOAD_LEARNER_SEARCH = 'DOWNLOAD_LEARNER_SEARCH';
export const DOWNLOAD_LEARNER_SEARCH_SUCCESS = 'DOWNLOAD_LEARNER_SEARCH_SUCCESS';
export const DOWNLOAD_LEARNER_SEARCH_FAILURE = 'DOWNLOAD_LEARNER_SEARCH_FAILURE';

export const BULK_DELETE_LEARNERS = 'BULK_DELETE_LEARNERS';
export const BULK_DELETE_LEARNERS_SUCCESS = 'BULK_DELETE_LEARNERS_SUCCESS';
export const BULK_DELETE_LEARNERS_FAILURE = 'BULK_DELETE_LEARNERS_FAILURE';

export const QUEUE_MOC_LEARNER = 'QUEUE_MOC_LEARNER';
export const PROCESS_BOARD_CREDIT = 'PROCESS_BOARD_CREDIT';
export const ADMIN_DELETE_LEARNER = 'ADMIN_DELETE_LEARNER';

interface BulkDeleteLearners extends PARSAction<CreateLearnerRequest[], typeof BULK_DELETE_LEARNERS> {
  type: typeof BULK_DELETE_LEARNERS;
  payload: CreateLearnerRequest[];
}

interface BulkDeleteLearnersSuccess extends PARSAction<void, typeof BULK_DELETE_LEARNERS_SUCCESS> {
  type: typeof BULK_DELETE_LEARNERS_SUCCESS;
}

interface BulkDeleteLearnersFailure extends PARSAction<Error, typeof BULK_DELETE_LEARNERS_FAILURE> {
  type: typeof BULK_DELETE_LEARNERS_FAILURE;
  payload: Error;
}

interface CreateLearner extends PARSAction<CreateLearnerRequest[], typeof CREATE_LEARNER> {
  type: typeof CREATE_LEARNER;
  payload: CreateLearnerRequest[];
}

interface CreateLearnerSuccess extends PARSAction<Learner[], typeof CREATE_LEARNER_SUCCESS> {
  type: typeof CREATE_LEARNER_SUCCESS;
  payload: Learner[];
}

interface CreateLearnerFailure extends PARSAction<Error, typeof CREATE_LEARNER_FAILURE> {
  type: typeof CREATE_LEARNER_FAILURE;
  payload: Error;
}

interface BatchLearner extends PARSAction<IBatchLearnerRequest, typeof BATCH_LEARNER> {
  type: typeof BATCH_LEARNER;
  payload: IBatchLearnerRequest;
}

interface BatchLearnerClear extends PARSAction<undefined, typeof BATCH_LEARNER_CLEAR> {
  type: typeof BATCH_LEARNER_CLEAR;
}

interface BatchLearnerProgress extends PARSAction<number, typeof BATCH_LEARNER_PROGRESS> {
  type: typeof BATCH_LEARNER_PROGRESS;
  payload: number;
}

interface BatchLearnerSuccess extends PARSAction<Learner[], typeof BATCH_LEARNER_SUCCESS> {
  type: typeof BATCH_LEARNER_SUCCESS;
  // TODO confirm payload type
  payload: Learner[];
}

interface BatchLearnerFailure extends PARSAction<Error, typeof BATCH_LEARNER_FAILURE> {
  type: typeof BATCH_LEARNER_FAILURE;
  payload: Error;
}

interface ExportLearnerActivities extends PARSAction<Error, typeof EXPORT_LEARNER_ACTIVITIES> {
  type: typeof EXPORT_LEARNER_ACTIVITIES;
}

interface ExportLearners extends PARSAction<Error, typeof EXPORT_LEARNERS> {
  type: typeof EXPORT_LEARNERS;
}

interface GetActivityTypes extends PARSAction<undefined, typeof GET_LEARNER_TYPES> {
  type: typeof GET_LEARNER_TYPES;
}

interface GetActivityTypesSuccess extends PARSAction<LearnerType[], typeof GET_LEARNER_TYPES_SUCCESS> {
  type: typeof GET_LEARNER_TYPES_SUCCESS;
  payload: LearnerType[];
}

interface PatchLearner
  extends PARSAction<{ id: string; request: IJsonPatchDocumentOperation[] }, typeof PATCH_LEARNER> {
  type: typeof PATCH_LEARNER;
  payload: { id: string; request: IJsonPatchDocumentOperation[] };
}

interface PatchLearnerSuccess extends PARSAction<void, typeof PATCH_LEARNER_SUCCESS> {
  type: typeof PATCH_LEARNER_SUCCESS;
  payload: void;
}

interface PatchLearnerFailure extends PARSAction<Error, typeof PATCH_LEARNER_FAILURE> {
  type: typeof PATCH_LEARNER_FAILURE;
  payload: Error;
}

interface UpdateLearner extends PARSAction<UpdateLearnerRequest, typeof UPDATE_LEARNER> {
  type: typeof UPDATE_LEARNER;
  payload: UpdateLearnerRequest;
}

interface UpdateLearnerSuccess extends PARSAction<void, typeof UPDATE_LEARNER_SUCCESS> {
  type: typeof UPDATE_LEARNER_SUCCESS;
  payload: void;
}

interface UpdateLearnerFailure extends PARSAction<Error, typeof UPDATE_LEARNER_FAILURE> {
  type: typeof UPDATE_LEARNER_FAILURE;
  payload: Error;
}

interface UpdateLearnerSearchActivities
  extends PARSAction<IActivityLearnerSummarySearch, typeof UPDATE_LEARNER_SEARCH_ACTIVITIES> {
  payload: IActivityLearnerSummarySearch;
  type: typeof UPDATE_LEARNER_SEARCH_ACTIVITIES;
}

interface UpdateLearnerActivitiesPaginationState
  extends PARSAction<IActivitySearchStateProps, typeof UPDATE_LEARNER_ACTIVITIES_PAGINATION_STATE> {
  payload: IActivitySearchStateProps;
  type: typeof UPDATE_LEARNER_ACTIVITIES_PAGINATION_STATE;
}

interface GetLearnerActivities extends PARSAction<IActivityLearnerSummarySearch, typeof GET_LEARNER_ACTIVITIES> {
  payload: IActivityLearnerSummarySearch;
  type: typeof GET_LEARNER_ACTIVITIES;
}

interface GetLearnerActivitiesSuccess
  extends PARSAction<IActivityLearnerSummarySearchResponse, typeof GET_LEARNER_ACTIVITIES_SUCCESS> {
  payload: IActivityLearnerSummarySearchResponse;
  type: typeof GET_LEARNER_ACTIVITIES_SUCCESS;
}

interface GetLearnerActivitiesFailure extends PARSAction<string, typeof GET_LEARNER_ACTIVITIES_FAILURE> {
  payload: string;
  type: typeof GET_LEARNER_ACTIVITIES_FAILURE;
}

interface ResetLearnerSearchResponse extends PARSAction<undefined, typeof RESET_LEARNER_SEARCH_RESPONSE> {
  type: typeof RESET_LEARNER_SEARCH_RESPONSE;
}

interface DownloadLearnerActivities extends PARSAction<string, typeof DOWNLOAD_LEARNER_ACTIVITIES> {
  payload: string;
  type: typeof DOWNLOAD_LEARNER_ACTIVITIES;
}

interface DownloadLearnerActivitiesSuccess
  extends PARSAction<IArrayBufferDownload, typeof DOWNLOAD_LEARNER_ACTIVITIES_SUCCESS> {
  payload: IArrayBufferDownload;
  type: typeof DOWNLOAD_LEARNER_ACTIVITIES_SUCCESS;
}

interface DownloadLearnerActivitiesFailure extends PARSAction<string, typeof DOWNLOAD_LEARNER_ACTIVITIES_FAILURE> {
  payload: string;
  type: typeof DOWNLOAD_LEARNER_ACTIVITIES_FAILURE;
}

interface GetLearnerSearch extends PARSAction<ILearnerSearchRequest, typeof GET_LEARNER_SEARCH> {
  type: typeof GET_LEARNER_SEARCH;
  payload: ILearnerSearchRequest;
}

interface GetLearnerSearchSuccess
  extends PARSAction<IActivityLearnerSearchResponse, typeof GET_LEARNER_SEARCH_SUCCESS> {
  type: typeof GET_LEARNER_SEARCH_SUCCESS;
  payload: IActivityLearnerSearchResponse;
}

interface GetLearnerSearchFailure extends PARSAction<string, typeof GET_LEARNER_SEARCH_FAILURE> {
  type: typeof GET_LEARNER_SEARCH_FAILURE;
  payload: string;
}

interface updateLearnerSearchStateProps
  extends PARSAction<IActivitySearchStateProps, typeof UPDATE_LEARNER_SEARCH_STATE_PROPS> {
  payload: IActivitySearchStateProps;
  type: typeof UPDATE_LEARNER_SEARCH_STATE_PROPS;
}

interface UpdateLearnerSearch extends PARSAction<UpdateLearnerRequest, typeof UPDATE_LEARNER_SEARCH> {
  type: typeof UPDATE_LEARNER_SEARCH;
  payload: UpdateLearnerRequest;
}

interface MatchLearners extends PARSAction<ILearnerMatchingModel, typeof MATCH_LEARNERS> {
  payload: ILearnerMatchingModel;
  type: typeof MATCH_LEARNERS;
}

interface MatchLearnersSuccess extends PARSAction<ILearnerMatchResponse[], typeof MATCH_LEARNERS_SUCCESS> {
  payload: ILearnerMatchResponse[];
  type: typeof MATCH_LEARNERS_SUCCESS;
}

interface MatchLearnersFailure extends PARSAction<string, typeof MATCH_LEARNERS_FAILURE> {
  payload: string;
  type: typeof MATCH_LEARNERS_FAILURE;
}

interface MatchLearnerBoards extends PARSAction<ILearnerMatchingModel, typeof MATCH_LEARNER_BOARDS> {
  payload: ILearnerMatchingModel;
  type: typeof MATCH_LEARNER_BOARDS;
}

interface MatchLearnerBoardsSuccess extends PARSAction<ILearnerMatchResponse[], typeof MATCH_LEARNER_BOARDS_SUCCESS> {
  payload: ILearnerMatchResponse[];
  type: typeof MATCH_LEARNER_BOARDS_SUCCESS;
}

interface MatchLearnerBoardsFailure extends PARSAction<string, typeof MATCH_LEARNER_BOARDS_FAILURE> {
  payload: string;
  type: typeof MATCH_LEARNER_BOARDS_FAILURE;
}

interface ResetLearnerMatches extends PARSAction<undefined, typeof RESET_LEARNER_MATCHES> {
  type: typeof RESET_LEARNER_MATCHES;
}

interface AddLearnerCompletion extends PARSAction<undefined, typeof ADD_LEARNER_COMPLETION> {
  type: typeof ADD_LEARNER_COMPLETION;
}

interface AddLearnerCompletionSuccess extends PARSAction<undefined, typeof ADD_LEARNER_COMPLETION_SUCCESS> {
  type: typeof ADD_LEARNER_COMPLETION_SUCCESS;
}

interface AddLearnerCompletionFailure extends PARSAction<AxiosError, typeof ADD_LEARNER_COMPLETION_FAILURE> {
  payload?: AxiosError;
  type: typeof ADD_LEARNER_COMPLETION_FAILURE;
}

interface GetRecentLearnerCompletions extends PARSAction<string, typeof GET_RECENT_LEARNER_COMPLETIONS> {
  payload: string;
  type: typeof GET_RECENT_LEARNER_COMPLETIONS;
}

interface GetRecentLearnerCompletionsSuccess
  extends PARSAction<ILearnerCompletion[], typeof GET_RECENT_LEARNER_COMPLETIONS_SUCCESS> {
  payload: ILearnerCompletion[];
  type: typeof GET_RECENT_LEARNER_COMPLETIONS_SUCCESS;
}

interface GetRecentLearnerCompletionsFailure extends PARSAction<string, typeof GET_RECENT_LEARNER_COMPLETIONS_FAILURE> {
  payload: string;
  type: typeof GET_RECENT_LEARNER_COMPLETIONS_FAILURE;
}

interface GetLearnerCompletion extends PARSAction<undefined, typeof GET_LEARNER_COMPLETION> {
  type: typeof GET_LEARNER_COMPLETION;
}

interface GetLearnerCompletionSuccess extends PARSAction<ILearnerCompletion, typeof GET_LEARNER_COMPLETION_SUCCESS> {
  payload: ILearnerCompletion;
  type: typeof GET_LEARNER_COMPLETION_SUCCESS;
}

interface GetLearnerCompletionFailure extends PARSAction<string, typeof GET_LEARNER_COMPLETION_FAILURE> {
  payload: string;
  type: typeof GET_LEARNER_COMPLETION_FAILURE;
}

interface ResetLearnerCompletion extends PARSAction<undefined, typeof RESET_LEARNER_COMPLETION> {
  type: typeof RESET_LEARNER_COMPLETION;
}

interface UpdateLearnerCompletion extends PARSAction<IJsonPatchDocumentOperation[], typeof UPDATE_LEARNER_COMPLETION> {
  payload: IJsonPatchDocumentOperation[];
  type: typeof UPDATE_LEARNER_COMPLETION;
}

interface UpdateLearnerCompletionSuccess extends PARSAction<undefined, typeof UPDATE_LEARNER_COMPLETION_SUCCESS> {
  type: typeof UPDATE_LEARNER_COMPLETION_SUCCESS;
}

interface UpdateLearnerCompletionFailure extends PARSAction<string, typeof UPDATE_LEARNER_COMPLETION_FAILURE> {
  payload: string;
  type: typeof UPDATE_LEARNER_COMPLETION_FAILURE;
}

interface ExportLearnerSearch extends PARSAction<ILearnerSearchRequest, typeof EXPORT_LEARNER_SEARCH> {
  payload: ILearnerSearchRequest;
  type: typeof EXPORT_LEARNER_SEARCH;
}

interface ExportLearnerSearchSuccess extends PARSAction<undefined, typeof EXPORT_LEARNER_SEARCH_SUCCESS> {
  type: typeof EXPORT_LEARNER_SEARCH_SUCCESS;
}

interface ExportLearnerSearchFailure extends PARSAction<string, typeof EXPORT_LEARNER_SEARCH_FAILURE> {
  payload: string;
  type: typeof EXPORT_LEARNER_SEARCH_FAILURE;
}

interface DownloadLearnerSearch extends PARSAction<string, typeof DOWNLOAD_LEARNER_SEARCH> {
  payload: string;
  type: typeof DOWNLOAD_LEARNER_SEARCH;
}

interface DownloadLearnerSearchSuccess
  extends PARSAction<IArrayBufferDownload, typeof DOWNLOAD_LEARNER_SEARCH_SUCCESS> {
  payload: IArrayBufferDownload;
  type: typeof DOWNLOAD_LEARNER_SEARCH_SUCCESS;
}

interface DownloadLearnerSearchFailure extends PARSAction<string, typeof DOWNLOAD_LEARNER_SEARCH_FAILURE> {
  payload: string;
  type: typeof DOWNLOAD_LEARNER_SEARCH_FAILURE;
}

interface BatchLearnerValidation extends PARSAction<IBatchLearnerRequest, typeof BATCH_LEARNER_VALIDATION> {
  type: typeof BATCH_LEARNER_VALIDATION;
  payload: IBatchLearnerRequest;
}

interface BatchLearnerValidationClear extends PARSAction<undefined, typeof BATCH_LEARNER_VALIDATION_CLEAR> {
  type: typeof BATCH_LEARNER_VALIDATION_CLEAR;
}

interface BatchLearnerValidationProgress extends PARSAction<number, typeof BATCH_LEARNER_VALIDATION_PROGRESS> {
  type: typeof BATCH_LEARNER_VALIDATION_PROGRESS;
  payload: number;
}

interface BatchLearnerValidationSuccess extends PARSAction<Learner[], typeof BATCH_LEARNER_VALIDATION_SUCCESS> {
  type: typeof BATCH_LEARNER_VALIDATION_SUCCESS;
  payload: Learner[];
}

interface BatchLearnerValidationFailure extends PARSAction<Error, typeof BATCH_LEARNER_VALIDATION_FAILURE> {
  type: typeof BATCH_LEARNER_VALIDATION_FAILURE;
  payload: Error;
}

interface QueueMocLearner extends PARSAction<IQueueMocLearnerRequest, typeof QUEUE_MOC_LEARNER> {
  type: typeof QUEUE_MOC_LEARNER;
  payload: IQueueMocLearnerRequest;
}

export type LearnerValidationActionTypes =
  | BatchLearnerValidation
  | BatchLearnerValidationClear
  | BatchLearnerValidationFailure
  | BatchLearnerValidationProgress
  | BatchLearnerValidationSuccess;

export type LearnerActionTypes =
  | AddLearnerCompletion
  | AddLearnerCompletionFailure
  | AddLearnerCompletionSuccess
  | BatchLearner
  | BatchLearnerClear
  | BatchLearnerFailure
  | BatchLearnerProgress
  | BatchLearnerSuccess
  | BulkDeleteLearners
  | BulkDeleteLearnersFailure
  | BulkDeleteLearnersSuccess
  | CreateLearner
  | CreateLearnerFailure
  | CreateLearnerSuccess
  | DownloadLearnerActivities
  | DownloadLearnerActivitiesFailure
  | DownloadLearnerActivitiesSuccess
  | DownloadLearnerSearch
  | DownloadLearnerSearchSuccess
  | ExportLearnerActivities
  | ExportLearners
  | ExportLearnerSearch
  | ExportLearnerSearchFailure
  | ExportLearnerSearchSuccess
  | GetActivityTypes
  | GetActivityTypesSuccess
  | GetLearnerActivities
  | GetLearnerActivitiesFailure
  | GetLearnerActivitiesSuccess
  | GetLearnerCompletion
  | GetLearnerCompletionFailure
  | GetLearnerCompletionSuccess
  | GetLearnerSearch
  | GetLearnerSearchFailure
  | GetLearnerSearchSuccess
  | GetRecentLearnerCompletions
  | GetRecentLearnerCompletionsFailure
  | GetRecentLearnerCompletionsSuccess
  | MatchLearnerBoards
  | MatchLearnerBoardsFailure
  | MatchLearnerBoardsSuccess
  | MatchLearners
  | MatchLearnersFailure
  | MatchLearnersSuccess
  | PatchLearner
  | PatchLearnerFailure
  | PatchLearnerSuccess
  | ResetLearnerCompletion
  | ResetLearnerMatches
  | ResetLearnerSearchResponse
  | UpdateLearner
  | UpdateLearnerActivitiesPaginationState
  | UpdateLearnerCompletion
  | UpdateLearnerCompletionFailure
  | UpdateLearnerCompletionSuccess
  | UpdateLearnerFailure
  | UpdateLearnerSearch
  | UpdateLearnerSearchActivities
  | updateLearnerSearchStateProps
  | UpdateLearnerSuccess
  | DownloadLearnerSearchFailure
  | QueueMocLearner;
