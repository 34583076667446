import { CircularProgress, Backdrop, Fade, IconButton, Modal as ModalComponent } from '@material-ui/core';
import { Close, KeyboardBackspaceRounded, ReplayRounded } from '@material-ui/icons';
import classNames from 'classnames';
import { Field, FormikProvider, useFormik } from 'formik';
import { isEqual } from 'lodash';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import Button from 'components/Button/Button';
import InputBlock from 'components/global/inputBlock/inputBlock';
import Faq from 'components/Faq/Faq';
import { LoadingCards } from 'components/LoadingCard';
import { ButtonVariant } from 'core/enums';
import { IProgramSummary, RollupOrganizationEnums } from 'core/models';
import { getProgramSummary, reEnableProgramSummary, saveProgramSummary } from 'store/programSummary/actions';
import { programSummarySelector } from 'store/programSummary/selectors';
import { DEFAULT_DATE_FORMAT, TAXONOMY_FAQ_ROOT_ID } from 'core/constants';
import { closeRail, openRail } from 'store/rail/actions';
import { isRailOpenSelector } from 'store/rail/selectors';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { faqTermsSelector } from 'store/taxonomy/selectors';
import { rollupOrganizationEnumSelector } from 'store/user/selectors';
import { useClickEvent, useLoadEvent } from 'hooks';
import { useStyles } from 'hooks/useStyles';

// Styles
import styles from './index.module.scss';
import moment from 'moment';
import { AuthenticatedAdminElement } from 'components/global/authenitcation/AuthenticatedAdminElement';

const DRAFT_BUTTON_LABEL = 'Save as Draft';
const SUBMIT_BUTTON_LABEL = 'Submit Program Summary';
const SUBMISSION_MESSAGE =
  'Are you ready to submit your Program Summary, or would you like to save it as a draft for now?';

export const ProgramSummary = (): ReactElement => {
  const dispatch = useDispatch();
  const faq = useSelector(faqTermsSelector);
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const isOpen: boolean = useSelector(isRailOpenSelector);
  const { modal } = useStyles();

  useEffect(() => {
    dispatch(getTaxonomyTermById(TAXONOMY_FAQ_ROOT_ID));
  }, [dispatch]);

  const initialValues: IProgramSummary = {
    advertisingAndExhibitOverall: '' as any, // 'any' typing to avoid uncontrolled input error
    governmentGrants: '' as any, // 'any' typing to avoid uncontrolled input error
    isDraft: null,
    privateDonations: '' as any, // 'any' typing to avoid uncontrolled input error
    registrationFees: '' as any, // 'any' typing to avoid uncontrolled input error
    updatedDate: null,
    email: '',
  };
  const programSummary = useSelector(programSummarySelector);

  const { error, isLoading, saved, storedSummary } = programSummary;

  const requiredNumber = yup
    .number()
    .required('This is a required field')
    .min(0, 'Please enter a value greater than or equal to 0');

  const programSummaryValidationSchema = yup.object().shape({
    advertisingAndExhibitOverall: requiredNumber,
    registrationFees: requiredNumber,
    governmentGrants: requiredNumber,
    privateDonations: requiredNumber,
  });

  const summaryForm = useFormik<IProgramSummary>({
    initialValues: {
      ...initialValues,
      ...storedSummary, // Overwrite the form values with what's been stored
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await dispatch(saveProgramSummary(values));
      setSubmitting(false);
      dispatch(getProgramSummary());
    },
    validationSchema: programSummaryValidationSchema,
  });
  const { handleSubmit, isSubmitting, resetForm, setFieldValue, submitForm, values, isValid } = summaryForm;

  const isSubmitted = !!storedSummary && !storedSummary?.isDraft;
  const isDisabledInteraction = isSubmitted || isLoading || isSubmitting;
  const isSubmitButtonEnabled =
    !isNaN(parseInt(values.governmentGrants.toString())) &&
    !isNaN(parseInt(values.advertisingAndExhibitOverall.toString())) &&
    !isNaN(parseInt(values.privateDonations.toString())) &&
    !isNaN(parseInt(values.registrationFees.toString()));
  const inputDisabledClass = classNames({ [styles['disabled-input']]: isDisabledInteraction });
  const summaryInputClass = styles['summary-input'];
  const fieldSeparatorClass = styles['field-separator'];
  const topBorderClass = styles['top-border'];
  const reOpenButton = styles['re-open-button'];
  const reOpenSvg = styles['re-open-svg'];

  const fireLoadEvent = useLoadEvent({ PageName: 'Program Summary', PageType: 'Program' });
  const fireClickEvent = useClickEvent({ Event: 'Program Summary', EventCategory: 'Program Summary' });

  useEffect(() => {
    fireLoadEvent();
  }, [fireLoadEvent]);

  // Check if the saved summary has been fetched. If not, try to fetch it
  useEffect(() => {
    if (!storedSummary) {
      dispatch(getProgramSummary());
    }
    if (storedSummary) {
      resetForm({ values: { ...initialValues, ...storedSummary } });
    }
  }, [storedSummary]);

  useEffect(() => {
    // Post submission, get a copy of the new program summary
    if (!isSubmitting && saved && !error && !isLoading && !isEqual(storedSummary, values)) {
      dispatch(getProgramSummary());
    }
    if (saved && storedSummary && isEqual(storedSummary, values)) {
      resetForm({ values: { ...initialValues, ...storedSummary } });
    }
  }, [isSubmitting, saved, error, isLoading]);

  const handleClickSaveAsDraft = useCallback(async () => {
    fireClickEvent({ EventAction: DRAFT_BUTTON_LABEL });
    try {
      setIsModalOpen(false);
      await setFieldValue('isDraft', true);
      await summaryForm.setSubmitting(true);
      summaryForm.values.isDraft = true;
      setIsModalOpen(false);
      await dispatch(saveProgramSummary(summaryForm.values));
    } finally {
      await summaryForm.setSubmitting(false);
    }
  }, [fireClickEvent, setFieldValue, submitForm]);

  const handleClickSubmitSummary = useCallback(async () => {
    fireClickEvent({ EventAction: SUBMIT_BUTTON_LABEL });
    await setFieldValue('isDraft', false);
    setIsModalOpen(false);
    await submitForm();
  }, [fireClickEvent, setFieldValue, submitForm]);

  const handleClose = (): void => {
    setIsModalOpen(false);
  };

  const saveAsDraftButton = (insideOfPopUp) => {
    return (
      <Button
        variant={ButtonVariant.Secondary}
        disabled={
          insideOfPopUp
            ? isLoading || isSubmitting || !isSubmitButtonEnabled
            : isDisabledInteraction || !isValid || !isSubmitButtonEnabled
        }
        title="Save as Draft"
        startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
        onClick={handleClickSaveAsDraft}
      >
        Save as Draft
      </Button>
    );
  };

  /**
   * Handle re-enabling the Program Summary.
   */
  const handleReEnableSummary = async () => {
    const result = dispatch(reEnableProgramSummary());
    if (result) {
      await setFieldValue('isDraft', true);
      summaryForm.values.isDraft = true;
    }
  };

  return (
    <>
      <FormikProvider value={summaryForm}>
        <section className="form-container">
          <div className="page-navigation">
            <Link className="tertiary" to="/dashboard">
              <KeyboardBackspaceRounded className="tertiary-icon-back" />
              Dashboard
            </Link>
          </div>
          <div className="form-title-container">
            <div className="title">
              <div className="eyebrow">Program Summary</div>
              <h4>
                Year End Summary
                <Button className="btn-faq" onClick={() => dispatch(openRail())}>
                  FAQ
                </Button>
              </h4>
              <div className="caption-text">
                <span>
                  The{' '}
                  <a href="https://www.accme.org/faq/what-program-summary" rel="noopener noreferrer" target="_blank">
                    Program Summary
                  </a>{' '}
                  summarizes income for the Reporting Year. Required fields may be filled with a zero ("0") if they do
                  not apply to your organization.
                </span>
              </div>
            </div>
          </div>
          {isLoading && !storedSummary ? (
            <LoadingCards count={3} />
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="form-group active">
                <InputBlock
                  name="advertisingAndExhibitOverall"
                  title="Total exhibit and advertising income received this year."
                  required
                  suppressLabel
                >
                  <label className="caption-text" htmlFor="advertisingAndExhibitOverall">
                    Advertising and exhibit income received in support of your program. If none received, type “0”
                  </label>
                  <div className={summaryInputClass}>
                    <Field
                      className={inputDisabledClass}
                      disabled={isSubmitted || isLoading || isSubmitting}
                      type="number"
                      id="advertisingAndExhibitOverall"
                      name="advertisingAndExhibitOverall"
                      placeholder="Amount in USD"
                    />
                  </div>
                </InputBlock>
                <hr className={fieldSeparatorClass} />
                <InputBlock
                  name="registrationFees"
                  title="Total registration fees received this year."
                  required
                  suppressLabel
                >
                  <label className="caption-text" htmlFor="registrationFees">
                    Registration fees received from activity participants. If none received, type “0”
                  </label>
                  <div className={summaryInputClass}>
                    <Field
                      className={inputDisabledClass}
                      disabled={isSubmitted || isLoading || isSubmitting}
                      type="number"
                      id="registrationFees"
                      name="registrationFees"
                      placeholder="Amount in USD"
                    />
                  </div>
                </InputBlock>
                <hr className={fieldSeparatorClass} />
                <InputBlock
                  name="governmentGrants"
                  title="Total government monetary grants received this year."
                  required
                  suppressLabel
                >
                  <label className="caption-text" htmlFor="governmentGrants">
                    Monetary grants received from federal, state or local governmental agencies in support of your
                    Program. If none received, type “0”
                  </label>
                  <div className={summaryInputClass}>
                    <Field
                      className={inputDisabledClass}
                      disabled={isSubmitted || isLoading || isSubmitting}
                      type="number"
                      id="governmentGrants"
                      name="governmentGrants"
                      placeholder="Amount in USD"
                    />
                  </div>
                </InputBlock>
                <hr className={fieldSeparatorClass} />
                <InputBlock
                  name="privateDonations"
                  title="Total private monetary donations received this year."
                  required
                  suppressLabel
                >
                  <label className="caption-text" htmlFor="privateDonations">
                    Monetary donations received from private sector, including foundations, in support of your Program.
                    Commercial Support is not considered to be a Private Monetary Donation. If none received, type “0”
                  </label>
                  <div className={summaryInputClass}>
                    <Field
                      className={inputDisabledClass}
                      disabled={isSubmitted || isLoading || isSubmitting}
                      type="number"
                      id="privateDonations"
                      name="privateDonations"
                      placeholder="Amount in USD"
                    />
                  </div>
                </InputBlock>
                <hr className={fieldSeparatorClass} />
                <div className="form-input">
                  {rollupOrganizationEnum === RollupOrganizationEnums.JA ? (
                    <>
                      <h6 className="form-input-label">
                        Please acknowledge your acceptance of the following terms for using JA-PARS to report IPCE/CE
                        activity data. You understand that submission of accurate information about the activities your
                        organization provides and the sources of revenue available to your program of IPCE/CE is
                        required to maintain accreditation. To that end, by clicking the "Submit Program Summary" button
                        below, you attest that your organization has submitted accurate information about:
                        <ul>
                          <li>The activities provided by your organization during this past year, and</li>
                          <li>The sources of revenue available to your program for this past year.</li>
                        </ul>
                        You understand that the data submitted will be reviewed and that your organization’s data may be
                        included in aggregated totals that are made available to the public.
                      </h6>
                    </>
                  ) : (
                    <>
                      <h6 className="form-input-label">
                        Please acknowledge your acceptance of the following terms for using PARS to report CME activity
                        and program data. You understand that submission of accurate information about the activities
                        your organization provides and the sources of revenue available to your program of CME is
                        required to maintain accreditation. By clicking the "Submit Program Summary" button below, you
                        attest that your organization has submitted accurate information about:
                        <ul>
                          <li>The activities provided by your organization during this past year, and</li>
                          <li>The sources of revenue available to your program for this past year.</li>
                        </ul>
                        You understand that the data submitted will be reviewed and that your organization’s data may be
                        included in aggregated totals that are made available to the public.
                      </h6>
                    </>
                  )}
                </div>
              </div>
              <ModalComponent
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={modal}
                open={isModalOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={isModalOpen} style={{ backgroundColor: 'white', padding: '20px' }}>
                  <div className="modal-body">
                    {/* Header content */}
                    <div className={styles.header}>
                      <div className="modal-button-row">
                        <IconButton className={styles.close} aria-label="close" title="Close" onClick={handleClose}>
                          <Close onClick={handleClose} />
                        </IconButton>
                        <h4 id="transition-modal-title">Confirm Submission</h4>
                      </div>
                    </div>
                    {/* Body content */}
                    <div>
                      <p>{SUBMISSION_MESSAGE}</p>
                    </div>
                    {/* Button Row content */}
                    <div className="modal-button-row">
                      <div style={{ padding: '20px' }}>
                        <Button
                          className="primary"
                          onClick={handleClickSubmitSummary}
                          disabled={isSubmitting}
                          isSubmitting={isSubmitting}
                        >
                          {SUBMIT_BUTTON_LABEL}
                        </Button>
                      </div>
                      {saveAsDraftButton(true)}
                    </div>
                  </div>
                </Fade>
              </ModalComponent>
              <div className={styles['button-row']}>
                {summaryForm.values.isDraft === false && !isLoading && !isSubmitting ? (
                  <div style={{ marginRight: 'auto' }}>
                    {`Program summary submitted on ${moment(summaryForm.values.updatedDate).format(
                      DEFAULT_DATE_FORMAT,
                    )} by ${summaryForm.values.email}`}
                  </div>
                ) : null}

                {saveAsDraftButton(false)}
                <Button
                  variant={ButtonVariant.Primary}
                  disabled={isDisabledInteraction}
                  title={SUBMIT_BUTTON_LABEL}
                  startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
                  onClick={() => setIsModalOpen(true)}
                >
                  {SUBMIT_BUTTON_LABEL}
                </Button>
              </div>
            </form>
          )}
        </section>
      </FormikProvider>
      <aside className={classNames('rail-container', 'rail-container--75', { open: isOpen })}>
        <Faq
          faqs={faq}
          faqArea="ProgramSummary"
          faqTitle="Program Summary FAQs"
          rollupOrganizationEnum={rollupOrganizationEnum}
          handleClose={() => dispatch(closeRail())}
        />

        {!storedSummary?.isDraft && (
          <AuthenticatedAdminElement>
            <div className={topBorderClass}>
              <Button className={reOpenButton} onClick={() => handleReEnableSummary()}>
                <ReplayRounded className={reOpenSvg} />
                Re-Open Program Summary
              </Button>
            </div>
          </AuthenticatedAdminElement>
        )}
      </aside>
    </>
  );
};
