import Configuration from 'core/configuration';

export abstract class CacheService {
  public static storage: Storage = Configuration?.storage === 'localStorage' ? localStorage : sessionStorage;

  public static get<T>(cacheKey: string): T | null {
    let result: T | null = null;

    // attempt to get the localStorage object
    const cacheJson: string | null = CacheService.storage.getItem(cacheKey);
    if (cacheJson) {
      // if found parse it
      const cacheValue = JSON.parse(cacheJson);

      // check expiry
      if (cacheValue.expiry !== null) {
        const expiry: Date = new Date(cacheValue.expiry);
        if (expiry > new Date()) {
          result = cacheValue.value as T;
        } else {
          CacheService.storage.removeItem(cacheKey);
        }
      } else {
        result = cacheValue.value as T;
      }
    }

    return result;
  }

  public static set(cacheKey: string, val: any, expiryMinutes?: number | null): void {
    const stringified = CacheService.createPersistable(val, expiryMinutes);
    try {
      CacheService.storage.setItem(cacheKey, stringified);
    } catch (e) {
      console.warn('Storage is full: %o', e);
    }
  }

  public static update(cacheKey: string, val: any): void {
    const cacheJson: string | null = CacheService.storage.getItem(cacheKey);
    if (cacheJson) {
      const cacheValue = JSON.parse(cacheJson);
      const stringified = JSON.stringify({
        expiry: cacheValue.expiry,
        value: val || '',
      });
      CacheService.storage.setItem(cacheKey, stringified);
    }
  }

  public static remove(cacheKey: string): void {
    CacheService.storage.removeItem(cacheKey);
  }

  private static createPersistable(val: any, expiryMinutes?: number | null) {
    let expiry: Date | null = null;
    if (expiryMinutes) {
      expiry = new Date();
      expiry.setMinutes(expiry.getMinutes() + expiryMinutes);
    }

    return JSON.stringify({
      expiry,
      value: val || '',
    });
  }

  public static clear(): void {
    CacheService.storage.clear();
  }
}
