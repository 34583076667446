import { InputLabel } from '@material-ui/core';
import { KeyboardBackspaceRounded } from '@material-ui/icons';
import classNames from 'classnames';
import { Field, FormikProvider, useFormik } from 'formik';
import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// components
import Button from 'components/Button/Button';
import InputBlock from 'components/global/inputBlock/inputBlock';

// core/services/store
import { ButtonVariant } from 'core/enums';
import { handleServerError } from 'globals/utils/handleServerError';
import { CmePassportImpersonationService } from 'services/CmePassportImpersonationService';
import { popToast } from 'store/toast/actions';
import { successToastOptions, errorStickyToastOptions } from 'store/toast/constants';

// Styles
import styles from './CmePassportImpersonation.module.scss';

// main page component
export const CmePassportImpersonationPage = (): ReactElement => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values, { resetForm }): Promise<void> => {
      try {
        const token = await CmePassportImpersonationService.getImpersonationToken(values.email);
        const url = `${process.env.REACT_APP_CME_PASSPORT_URL}#impersonationToken=${encodeURIComponent(token)}`;
        window.open(url, '_blank', 'noopener,noreferrer');
        await dispatch(
          popToast({
            ...successToastOptions,
            autoHideDuration: undefined, // keep the message available until the user clicks something
            message: (
              // the browser's popup blocker may stop the window.open call above, so we'll include a link in the banner so the user can just click that to launch CME Passport
              <>
                CME Passport has been opened in a new window - &nbsp;
                <a href={url} target="_blank" rel="noopener noreferrer">
                  click here to open manually if your browser blocked it
                </a>
                .
              </>
            ),
          }),
        );
        resetForm();
      } catch (error) {
        const { errorMessage } = handleServerError({ error, thunkName: 'getImpersonationToken' });
        await dispatch(popToast({ ...errorStickyToastOptions, message: <>{errorMessage}</> }));
      }
    },
  });

  return (
    <>
      <div className="form-container">
        <div className="page-navigation">
          <Link className="tertiary" to="/admin">
            <KeyboardBackspaceRounded className="tertiary-icon-back" />
            Admin
          </Link>
        </div>
        <div className="form-title-container">
          <div className={classNames('title', styles.title)}>
            <h4>Impersonate CME Passport User</h4>
          </div>
        </div>

        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <section className={styles.section}>
              <div>
                <InputLabel>CME Passport user email</InputLabel>
                <InputBlock name="email">
                  <Field id="email" name="email" type="text" />
                </InputBlock>
              </div>

              <footer className={styles.footer}>
                <Button
                  className={styles.button}
                  disabled={formik.isSubmitting}
                  isSubmitting={formik.isSubmitting}
                  type="submit"
                  variant={ButtonVariant.Primary}
                >
                  Impersonate
                </Button>
              </footer>
            </section>
          </form>
        </FormikProvider>
      </div>
    </>
  );
};
