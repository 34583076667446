// Libraries
import { useAuth0 } from '@auth0/auth0-react';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
// @ts-ignore: This package isn't typescript-friendly
import { saveAs } from 'filesaver.js-npm';

// Store
import { activitySelector } from 'store/activity/selectors';
import { auth0UserSelector } from 'store/user/selectors';
import { downloadActivities } from 'store/activity/actions';

const DownloadFile: React.FC = (): ReactElement | null => {
  const dispatch = useDispatch();
  const activity = useSelector(activitySelector);
  const activityDownload = activity?.activityDownload;
  const user = useSelector(auth0UserSelector);
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();
  const search = new URLSearchParams(location?.search);
  const downloadGuid = search.get('downloadActivities');

  useEffect(() => {
    // Check query string for download activities flag
    if (isAuthenticated && !isLoading && user && downloadGuid) {
      dispatch(downloadActivities(downloadGuid));
    }
  }, [isAuthenticated, downloadGuid, isLoading, user]);

  useEffect(() => {
    if (!activityDownload) return;
    const { file, type } = activityDownload;
    if (file) {
      // Convert csv string to blob
      const blob = new Blob([new Uint8Array(file, 0, file.byteLength)], { type });
      saveAs(blob, 'Activity Export.csv');
    }
  }, [activityDownload]);

  return <></>;
};

export default DownloadFile;
