import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components.
import { Typeahead } from './TypeaheadComponent';

// Core + Store
import { IFacetAccordion } from 'core/constants';
import { Facet } from 'core/models';

export interface IFacetProps {
  callback?(): void;
  classes: any;
  expandIcon: ReactElement;
  facet: IFacetAccordion;
  facets: Facet;
  isDisabled: boolean;
  isExpanded: boolean;
  onToggleExpand(): void;
}

export const TypeaheadFacet = (props: IFacetProps): ReactElement => {
  const { classes, facet, isExpanded, expandIcon, onToggleExpand } = props;
  const { asArray, displayValue, payloadValue, reduxAction, reduxSelector, transformTypeaheadData } = facet;
  const dispatch = useDispatch();
  const data = useSelector(reduxSelector);
  const options = useMemo(() => transformTypeaheadData(data), [data, transformTypeaheadData]);

  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    if (reduxAction) {
      dispatch(reduxAction());
    }
  }, [dispatch, reduxAction]);

  const handleInputChange = useCallback(
    (inputValue: string) => {
      // If `asArray` is specified, we will set the formik model to be an array. The BE sometimes wants things from
      // the typeahead to be a string, other times it expects an array.
      const value: string | string[] = asArray ? [inputValue] : inputValue;

      setFieldValue(payloadValue, value);
    },
    [asArray, payloadValue, setFieldValue],
  );

  return (
    <Accordion key={displayValue} className={classes.accordion} expanded={isExpanded} onChange={onToggleExpand}>
      <AccordionSummary
        aria-controls={`${displayValue}-content`}
        className={classes.summary}
        expandIcon={expandIcon}
        id={`${displayValue}-header`}
      >
        {displayValue}
      </AccordionSummary>
      <AccordionDetails>
        <Typeahead
          asArray={asArray}
          inputValue={values[payloadValue] || ''}
          options={options}
          onInputChange={handleInputChange}
        />
      </AccordionDetails>
    </Accordion>
  );
};
