import PARSConfiguration from '../core/configuration';
import { EnvironmentEnum } from '../core/models';

/**
 * Get Environment variable from the PARSConfiguration.
 */
export const getCurrentEnvironment = (): string => {
  const environment = new URL(PARSConfiguration.api).host.split('.')[0].replace('pars3services', '').toLowerCase();
  if (environment === 'prd') {
    return EnvironmentEnum.Production;
  } else if (environment === 'sb') {
    return EnvironmentEnum.Sandbox;
  } else if (environment === 'dev' || environment.includes('localhost')) {
    return EnvironmentEnum.Development;
  } else if (environment === 'ua') {
    return EnvironmentEnum.UA;
  } else {
    return null;
  }
};
