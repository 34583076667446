import { ReactChild, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { downloadFile, downloadLearnerValidationFile } from 'store/fileDownload/actions';
import { MatchTemplate } from 'store/fileDownload/types';

interface IManualDownloadLinkProps {
  children: ReactChild;
  fileName?: string;
  id: string;
  map: MatchTemplate;
  to: string;
}

export const ManualDownloadLink = (props: IManualDownloadLinkProps): ReactElement => {
  const { fileName, to, id, map, children } = props;
  const dispatch = useDispatch();

  const handleDownload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (id === 'lvtbatch') {
      dispatch(downloadLearnerValidationFile({ controller: to, matchTemplate: map }));
    } else {
      dispatch(downloadFile({ controller: to, id, matchTemplate: map }));
    }
  };

  return (
    <a href={to} download={fileName || map[id]} onClick={handleDownload} role="link">
      {children}
    </a>
  );
};
