import classnames from 'classnames';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { FormService } from '../../../services/FormService';
import { ErrorLabel } from 'components/ContinuousImprovement/ErrorLabel';
import { PropsWithChildren, ReactElement } from 'react';

type IInputBlockProps = PropsWithChildren<{
  ariaLabel?: string;
  errors?: unknown;
  isHiddenErrors?: boolean;
  name: string;
  required?: boolean;
  suppressLabel?: boolean;
  role?: string;
  title?: string;
  touched?: unknown;
  variant?: string;
}>;

const InputBlock = (props: IInputBlockProps): ReactElement => {
  const { errors } = useFormikContext();
  const { ariaLabel, children, isHiddenErrors, name, title, required, role, suppressLabel, variant } = props;
  const validationError = get(errors, name);
  const hasError = !!validationError;

  return (
    <div
      aria-label={ariaLabel}
      data-question={name}
      className={classnames('form-input', variant, { has_error: !!hasError })}
      role={role}
    >
      {FormService.renderLabel({ name, required, suppressLabel, title })}
      {children}
      {hasError && !isHiddenErrors && <ErrorLabel error={validationError} name={name} />}
    </div>
  );
};

export default InputBlock;
