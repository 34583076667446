import { BaseApiService } from './baseApiService';
import { ITaxonomyTerm } from './models';

export declare type FormInputType =
  | 'Boolean'
  | 'Choice'
  | 'Date'
  | 'DateTime'
  | 'Integer'
  | 'Guid'
  | 'MultiChoice'
  | 'MultiChoiceGrid'
  | 'NestedMultiChoice'
  | 'Number'
  | 'Text'
  | 'Time'
  | 'Note'
  | 'Taxonomy'
  | 'TaxonomyMulti'
  | 'MultiChoicePill';

export declare type ChoiceFormatType = 'Dropdown' | 'Radiobuttons';

export interface IOperand {
  ['$type']: string;
  OperandType: 'Comparison' | 'Constant' | 'FieldRef' | 'Logical';
}

export interface IBinaryOperation extends IOperand {
  Left: IOperand;
  Right: IOperand;
}

export interface IComparisonOperation extends IBinaryOperation {
  ComparisonOperationType: 'Eq' | 'Geq' | 'Gt' | 'IsNotNull' | 'IsNull' | 'Leq' | 'Lt' | 'Neq';
}

export interface IConstantOperation extends IOperand {
  FormInputType: FormInputType;
  Value: string | number | boolean | Date | Array<string> | null;
}

export interface IFieldRefOperation extends IOperand {
  FormInputType: FormInputType;
  JsonPath: string;
}

export interface ILogicalOperation extends IBinaryOperation {
  LogicalOperationType: 'And' | 'Or';
}

export interface IWhere {
  Operand: IOperand;
}

export interface IFormItem {
  ['$type']: string;
  Id: string;
  Visible: IWhere;
}

export interface IFormGroup extends IFormItem {
  HideGroupTitleStyle?: boolean;
  Children: Array<IFormItem>;
}

export interface IFormSection extends IFormGroup {
  Breadcrumb: string;
  Title: string;
  Subtitle: string;
  Variant: string;
  TooltipContent: string;
  IsRequired: string;
}

export interface IFormInputOption {
  Id: string;
  Title: string;
  Description?: string;
  LabelCaption?: string;
  terms?: ITaxonomyTerm[];
}

export interface IFormInput extends IFormItem {
  Title: string;
  Description: string;
  Watermark: string;
  Type: FormInputType;
  ChoiceFormatType?: ChoiceFormatType;
  Options?: Array<IFormInputOption>;
  ValueJsonPath: string;
  TermSetId?: string;
  UseIntermediateTerms?: boolean;
  Variant: string;
  InputCaption: string;
  LabelCaption: string;
  RepeatButtonText: string;
  Required: IWhere;
}

export class FormTemplatesApiService extends BaseApiService {
  public async getActivityTemplateAsync(): Promise<Array<IFormSection>> {
    return await super.getAsync('/api/formTemplates/activities');
  }
}
