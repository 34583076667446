import { ReactElement } from 'react';
import { FastField } from 'formik';
import classNames from 'classnames';
import { IFormCheckbox } from 'core/models';

/**
 * FastFormikCheckbox Component
 * - This component makes use of Formik's FastField field wrapper.
 * - This control introduces shouldComponentUpdate logic to prevent excess rerendering.
 * - IMPORTANT: This component can only be used when it's use is not dependent on other fields!!!
 *
 */
export const FastFormikCheckbox = (props: IFormCheckbox): ReactElement => {
  const { isRequired = false, formikKey, labelCaption, title } = props;

  return (
    <label className={classNames('form-input-checkbox')}>
      <FastField name={formikKey} type="checkbox" required={isRequired} />
      <span className="checkmark">
        <div className="label-text-container">
          <div className="label-text">{title}</div>
          <div className="caption-text">{labelCaption}</div>
        </div>
      </span>
    </label>
  );
};
