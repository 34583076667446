import * as axios from 'axios';

// Core.
import * as PARS from 'core';
import Configuration from 'core/configuration';

// Services.
import { ProviderService, TokenService } from 'services';

// Store.
import { store } from 'store';

export class ServerRoute {
  private constructor(controller: string, action?: string) {
    this.controller = controller;
    this.action = action;

    if (!this.controller) {
      throw new Error(`Expected controller argument to be defined.`);
    }
  }

  public static forAction(controller: string, action?: string): ServerRoute {
    const result: ServerRoute = new ServerRoute(controller, action);
    const actionUrl = PARS.Utils.isDefined(action) ? `/${action}` : '';
    result.fullUrl = `/${controller}${actionUrl}`;
    return result;
  }

  private readonly controller: string;
  // @ts-ignore
  // TODO Can we remove this?
  private readonly action: string;
  private fullUrl?: string;

  public get url(): string {
    return this.fullUrl || '';
  }
}

export const axiosInstance = axios.default;
// TODO config axios response interceptor
axiosInstance.interceptors.request.use((config) => {
  const accessToken: string = TokenService.getAccessToken() || '';
  const organizationId: string = new ProviderService().currentOrganizationId;
  const providerId: string = new ProviderService().currentProviderId;
  const defaultHeaders = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
    ...(organizationId && { 'Organization-Id': organizationId }),
    ...(providerId && { 'Provider-Id': providerId }),
  };

  return Object.assign({}, config, {
    // TODO check if config.data.headers is ok or if we need config.headers too
    headers: Object.assign({}, defaultHeaders, config.headers || {}),
  });
});

interface RequestConfig extends axios.AxiosRequestConfig {
  headers?: any;
  sendAuthentication?: boolean;
  authTokenOverride?: string;
  globalErrorHandling?: boolean;
}

async function get<TResult>(routeData: ServerRoute, queryString?: string, config?: RequestConfig): Promise<TResult> {
  return (await axiosInstance.get(makeUrl(routeData, queryString), config)).data;
}

async function getFullResponse(
  routeData: ServerRoute,
  queryString?: string,
  config?: RequestConfig,
): Promise<axios.AxiosResponse<any>> {
  return await axiosInstance.get(makeUrl(routeData, queryString), config);
}

async function post<TResult>(routeData: ServerRoute, payload: any, config?: RequestConfig): Promise<TResult> {
  return (await axiosInstance.post(makeUrl(routeData), payload, config)).data;
}

async function put<TResult>(routeData: ServerRoute, payload: any, config?: RequestConfig): Promise<TResult> {
  return (await axiosInstance.put(makeUrl(routeData), payload, config)).data;
}

async function postFile<TResult>(
  // TODO return arguments as object  with interface
  routeData: ServerRoute,
  payload: any,
  batchProgressAction?: (percentage: number) => any,
  config?: RequestConfig,
): Promise<TResult> {
  const formData = new FormData();
  const handleProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    const percentage = Math.round((loaded / total) * 100) || 0;
    if (batchProgressAction) store.dispatch(batchProgressAction(percentage));
  };
  const configWithProgressHandler = {
    ...config,
    onUploadProgress: (progressEvent) => handleProgress(progressEvent),
  };
  payload.file.forEach((file: File) => {
    formData.append('file', file);
  });
  return (await axiosInstance.post(makeUrl(routeData), formData, configWithProgressHandler)).data;
}

async function sendDelete<TResult>(
  routeData: ServerRoute,
  queryString?: string,
  config?: RequestConfig,
): Promise<TResult> {
  return (await axiosInstance.delete(makeUrl(routeData, queryString), config)).data;
}

function getErrorMessage(error) {
  // TODO add error type?
  if (
    error &&
    error.response &&
    (error.response.status === 400 || error.response.status === 404 || error.response.status === 409) &&
    error.response.message
  ) {
    if (typeof error.response.message === 'string') {
      return error.response.message;
    } else if (typeof error.response.title === 'string') {
      return error.response.title;
    }
  }
  return PARS.Utils.isDefined(error) ? error.message : '';
}

function makeUrl(routeData: ServerRoute, queryString?: string | null) {
  const baseUrl: string = Configuration.api;
  let url = `${baseUrl}/api${routeData.url}`;

  if (queryString && queryString.length > 0) {
    url += `?${queryString}`;
  }

  return url;
}

export default {
  ServerRoute,
  get,
  getErrorMessage,
  getFullResponse,
  post,
  postFile,
  put,
  sendDelete,
};
