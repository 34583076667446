// Libs
import { ReactElement } from 'react';
import { isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Cancel } from '@material-ui/icons';

// Core
import { AddActivityFormValues, ITaxonomyTerm, RollupOrganizationEnums } from 'core/models';
import { TAGS_ENUM } from 'core/constants';
import { creditChildTermsSelector } from 'store/taxonomy/selectors';

// Styles
import styles from '../CompletedActivityCard.module.scss';
import { StatusEnum } from 'core/enums';

interface IProps {
  formValues: AddActivityFormValues;
  rollupOrganizationEnum?: RollupOrganizationEnums;
  activityStatus: StatusEnum;
}

export const CompletedPharmacyCredits = ({
  formValues,
  rollupOrganizationEnum,
  activityStatus,
}: IProps): ReactElement => {
  const isJA = rollupOrganizationEnum === RollupOrganizationEnums.JA;
  const creditChildTerms: ITaxonomyTerm[] = useSelector(creditChildTermsSelector);
  const pharmacyCreditTerm = creditChildTerms?.find(({ tag }) => tag === TAGS_ENUM.ACTIVITY_CREDIT_TYPE__PHARMACY);

  const hasPharmacyCredits: boolean = isNumber(formValues.credits?.[pharmacyCreditTerm?.id]);
  const isActivityDraft: boolean = activityStatus === StatusEnum.DRAFT;

  if (!hasPharmacyCredits || !isActivityDraft) return null;

  return (
    <div
      className={classNames('board', {
        [styles['board-incomplete']]: isJA && hasPharmacyCredits && isActivityDraft,
      })}
    >
      <div className="board-name">
        <>
          <Cancel />
          <span>{`${
            isJA && hasPharmacyCredits && isActivityDraft
              ? 'Your pharmacy activity is in Draft status and cannot be sent to CPE Monitor. Please go to the activity summary, click the activity title and edit to add data fields noted as missing in red text.'
              : ''
          }`}</span>
        </>
      </div>
    </div>
  );
};
