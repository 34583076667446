import { ReactElement, ReactNode } from 'react';
import { Backdrop, Fade, Modal as ModalComponent, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

// Styles
import styles from './index.module.scss';

// Hooks
import { useStyles } from 'hooks/useStyles';

// Store + Core
import { closeModal } from 'store/modal/actions';
import { isModalOpenSelector } from 'store/modal/selectors';

// Types
interface IProps {
  children?: ReactNode;
  errorMessage?: string;
  isOpen: boolean;
  onClose?(): void;
  title?: string;
}

export const Modal = (props: IProps): ReactElement => {
  const { children, isOpen, errorMessage, onClose, title } = props;
  const { modal } = useStyles();
  const dispatch = useDispatch();
  const isReduxModalOpen: boolean = useSelector(isModalOpenSelector);

  const handleClose = (): void => {
    // Redux: close modal.
    dispatch(closeModal());

    // Optional callback.
    onClose?.();
  };

  return (
    <ModalComponent
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={modal}
      open={isReduxModalOpen || isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={styles.wrapper}>
          {/* Error message */}
          {errorMessage && <div className={styles.error}>{errorMessage}</div>}

          {/* Header content */}
          <div className={styles.header}>
            {title && <h2 id="transition-modal-title">{title}</h2>}
            <IconButton className={styles.close} title="Close" onClick={handleClose}>
              <Close />
            </IconButton>
          </div>

          {/* Main content */}
          <div className={styles['content-wrapper']}>{children}</div>
        </div>
      </Fade>
    </ModalComponent>
  );
};
