import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Core + Store
import { ActivitySearchResultActivity, IBoard } from 'core/models';

// Styles
import styles from './learnersbyactivityheader.module.scss';

interface IProps {
  activityDetail: ActivitySearchResultActivity;
}

export const LearnersByActivityHeader = (props: IProps): ReactElement => {
  const { activityDetail } = props;
  const {
    boards,
    creditTypes,
    endDate,
    id,
    isMoc,
    isRems,
    isMips,
    internalId,
    startDate,
    title,
    type,
    key,
  } = activityDetail;
  const dateFormat = 'M/D/YYYY';
  const certifyingBoards: string = boards?.map(({ abbreviation }: IBoard): string => abbreviation).join(', ');
  const credits = creditTypes?.map(({ credits, name }) => `${credits} ${name} Credits`).join(', ');
  const formattedClaimDate: string =
    activityDetail?.creditClaimDate && moment(activityDetail?.creditClaimDate).format(dateFormat);
  const collaborations: string[] = [isMoc && 'MOC', isRems && 'REMS', isMips && 'MIPS'].filter(Boolean);

  return (
    <div className="form-title-container">
      <h4 className="title">
        Learners for{' '}
        <Link to={`/activities/detail/${key}`} className={styles['activity-title']}>
          {title}
        </Link>
        <div className={styles['activity-info']}>
          <div className={styles.meta}>
            <div className={styles['meta-activity']}>
              <span>{type}</span>
              <span>Activity ID: {id}</span>
            </div>
            <div className={styles['meta-internal']}>Internal ID: {internalId}</div>
          </div>
          <div className={styles.meta}>
            <div className={styles['meta-date']}>
              {moment(startDate).format(dateFormat)}-{moment(endDate).format(dateFormat)}
            </div>
            {formattedClaimDate && (
              <div className={styles['meta-claim-date']}>Credit Claim Date: {formattedClaimDate}</div>
            )}
          </div>
          <div className={styles.meta}>
            {!!collaborations.length && (
              <div className={styles['meta-collab']}>Collaborations: {collaborations.join(', ')} </div>
            )}
            <div className={styles['meta-boards']}>Certifying Boards: {certifyingBoards}</div>
            <div className={styles['meta-credits']}>{credits}</div>
          </div>
        </div>
      </h4>
    </div>
  );
};
