import { ReactElement, useEffect, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

// Components
import DatePicker from '../../DatePicker/DatePicker';
import Dropdown, { IDropdownType } from '../../Dropdown/Dropdown';
import InputBlock from 'components/global/inputBlock/inputBlock';

// Store + Core
import { getCountries, getStatesAndProvinces } from 'store/locations/actions';
import { countriesSelector, statesAndProvincesSelector } from 'store/locations/selectors';
import { ICountry, IStateAndProvince } from 'core/models';
import { formatCountries } from './utils/formatCountry';
import { sortCountries } from './utils/sortCountries';
import { formatStates } from './utils/formatStates';
import { sortStates } from './utils/sortedStates';

// Types
interface IProps {
  isDisabled?: boolean;
  formikKey: string;
  shouldShowLocationInformation: boolean;
}

export const SessionInputs = (props: IProps): ReactElement => {
  const { formikKey, isDisabled, shouldShowLocationInformation } = props;
  const dispatch = useDispatch();
  const { handleChange } = useFormikContext();

  // Selectors
  const countries: ICountry[] = useSelector(countriesSelector);
  const states: IStateAndProvince[] = useSelector(statesAndProvincesSelector);

  const startDateKey = `${formikKey}.startDate`;
  const endDateKey = `${formikKey}.endDate`;
  const countryKey = `${formikKey}.country`;
  const cityKey = `${formikKey}.city`;
  const stateOrProvinceKey = `${formikKey}.stateOrProvince`;
  const postalCodeKey = `${formikKey}.postalCode`;

  // Fetch countries.
  useEffect(() => {
    if (!countries) {
      dispatch(getCountries());
    }
  }, [countries, dispatch]);

  // Fetch states.
  useEffect(() => {
    if (!states) {
      dispatch(getStatesAndProvinces());
    }
  }, [states, dispatch]);

  // Format the countries into a shape that works with the dropdown.
  const formattedCountries: IDropdownType[] = useMemo(() => formatCountries(countries), [countries]);

  // Format the states into a shape that works with the dropdown.
  const formattedStates: IDropdownType[] = useMemo(() => formatStates(states), [states]);

  // Sort the countries alphabetically, then put USA at the top.
  const sortedCountries: IDropdownType[] = sortCountries(formattedCountries);

  // Sort the states alphabetically.
  const sortedStates: IDropdownType[] = sortStates(formattedStates);

  return (
    <div className="form-group form-group-inline form-sub-group">
      <div className="form-group form-sub-group">
        <h5 className="form-input-label">
          Activity Start Date <span className="isRequired">*</span>
        </h5>
        <InputBlock name={startDateKey}>
          <Field
            aria-required="true"
            required
            component={DatePicker}
            disabled={isDisabled}
            id={startDateKey}
            name={startDateKey}
            timeOfDay="midnight"
          />
        </InputBlock>
      </div>
      <div className="form-group form-sub-group">
        <h5 className="form-input-label">
          Activity End Date <span className="isRequired">*</span>
        </h5>
        <InputBlock name={endDateKey}>
          <Field
            aria-required="true"
            required
            component={DatePicker}
            disabled={isDisabled}
            id={endDateKey}
            name={endDateKey}
            timeOfDay="endOfDay"
          />
        </InputBlock>
      </div>
      {shouldShowLocationInformation && (
        <div className="form-group-inline form-sub-group">
          <div className="form-group form-sub-group">
            <h5 className="form-input-label">Country</h5>
            <InputBlock name={countryKey}>
              <Dropdown
                isDisabled={isDisabled}
                idProp="Id"
                items={sortedCountries}
                labelProp="Title"
                name={countryKey}
                onChange={handleChange}
              />
            </InputBlock>
          </div>
          <div className="form-group form-sub-group">
            <h5 className="form-input-label">City</h5>
            <InputBlock name={cityKey}>
              <Field aria-label="City" disabled={isDisabled} id={cityKey} name={cityKey} type="text" />
            </InputBlock>
          </div>
          <div className="form-group form-sub-group">
            <h5 className="form-input-label">State/Province</h5>
            <InputBlock name={stateOrProvinceKey}>
              <Dropdown
                isDisabled={isDisabled}
                idProp="Id"
                items={sortedStates}
                labelProp="Title"
                name={stateOrProvinceKey}
                onChange={handleChange}
              />
            </InputBlock>
          </div>
          <div className="form-group form-sub-group">
            <h5 className="form-input-label">Zip Code / Postal Code</h5>
            <InputBlock name={postalCodeKey}>
              <Field
                aria-label="Zip Code / Postal Code"
                disabled={isDisabled}
                id={postalCodeKey}
                name={postalCodeKey}
                type="text"
              />
            </InputBlock>
          </div>
        </div>
      )}
    </div>
  );
};
