import { ReactElement } from 'react';
import { FastField, FieldProps } from 'formik';
import { TextField } from '@material-ui/core';

// Types
import { FormikTextFieldProps } from 'components/forms/types';

/**
 * FastFormikTextField Component
 * - This component makes use of Formik's FastField field wrapper.
 * - This control introduces shouldComponentUpdate logic to prevent excess rerendering.
 * - IMPORTANT: This component can only be used when it's use is not dependent on other fields!!!
 *
 */
export const FastFormikTextField = ({ formikKey, inputProps, ...props }: FormikTextFieldProps): ReactElement => {
  return (
    <FastField name={formikKey}>
      {({ field, meta }: FieldProps): ReactElement => {
        // formik supplies a field.onBlur that needs to be called - if props supplies one too, we need to call both
        const onBlur =
          field.onBlur && props.onBlur
            ? (...rest) => {
                field.onBlur.apply(this, rest);
                props.onBlur.apply(this, rest);
              }
            : field.onBlur ?? props.onBlur;
        return (
          <TextField
            className="form-input"
            error={meta.touched && !!meta.error}
            helperText={meta ? meta.error : ''}
            id={field.name}
            name={field.name}
            onChange={field.onChange}
            value={field.value}
            inputProps={{ ...inputProps, 'aria-label': field.name }}
            {...field}
            {...props}
            onBlur={onBlur}
          />
        );
      }}
    </FastField>
  );
};
