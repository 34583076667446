import { AxiosError, AxiosResponse } from 'axios';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

// Core + Store
import { LogService } from 'services/LogService';
import { TraceLevel } from 'core/enums';
import { CreditTypesErrors, IValidationException } from 'core/models';
import { AnalyticsService } from 'services/AnalyticsService';

// Utils
import { clientErrorMessageMap } from 'globals/utils/handleValidationException/clientErrorMessageMap';

// Types
interface IArgs {
  error: AxiosError;
  fallbackMessage?: string;
  name?: string;
  thunkName?: string;
}

// some errors objects from the server can be deeply nested - look for something with an errorCode and message
const collectErrors = (response: any): IValidationException[] => {
  try {
    if (!response) {
      return [];
    }
    const { errorCode, message } = response;
    if (errorCode && message) {
      return [{ errorCode, message }];
    }
    const errors: IValidationException[] = [];
    if (typeof response === 'object') {
      for (const i in response) {
        for (const e of collectErrors(response[i])) {
          errors.push(e);
        }
      }
    }
    return errors;
  } catch (e) {
    AnalyticsService.trackException(e);
    return [];
  }
};

/**
 * @function handleServerError
 * @param error
 * @param fallbackMessage
 * @param thunkName
 * @description Handles server errors and does the following:
 *              - Sends data to App Insights.
 *              - Logs a console.error for extra context when not in production.
 *              - Returns a human readable string for the user.
 * @return string
 */
export interface IServerError {
  creditTypesErrors?: CreditTypesErrors;
  clientErrorMessage: string;
  errorMessage: string;
  validationExceptions: IValidationException[];
}
export const handleServerError = ({
  error,
  fallbackMessage = 'An error occurred. Please try again later.',
  name = '',
  thunkName = '',
}: IArgs): IServerError => {
  const message: string = error?.message;
  const response: AxiosResponse<any> = error?.response;

  const collectedErrors: IValidationException[] = collectErrors(response?.data);

  LogService.sendToAppInsights(
    {
      exception: error,
      name,
      properties: {
        collectedErrors,
        environmentErrorLocation: `Error occurs in ${process.env.NODE_ENV} environment.`,
        occurredOn: Date.now(),
        thunkName,
      },
      severityLevel: SeverityLevel.Error,
    },
    TraceLevel.Error,
  );

  if (process.env.NODE_ENV !== `production`) {
    if (!response) console.log(message);
    // Example: Error Code: 500 - Internal Server Error {@type Error}
    console.log(`Error Code: ${response?.status} - ${response?.statusText} `, { ...error });
  }

  const errorMessage: string =
    response?.data?.message || collectedErrors[0]?.message || response?.statusText || message || fallbackMessage;

  return {
    clientErrorMessage: clientErrorMessageMap[collectedErrors[0]?.errorCode] || errorMessage,
    creditTypesErrors: response?.data?.creditTypes,
    errorMessage,
    validationExceptions: response?.data?.validationExceptions,
  };
};
