// Core + Store
import { ICountry, IStateAndProvince } from 'core/models';
import { ServerRoute } from 'core/api';
import { API } from 'core';

export class LocationsService {
  public async getCountries(): Promise<ICountry[]> {
    const route: ServerRoute = ServerRoute.forAction('countries');

    return await API.get(route);
  }

  public async getStatesAndProvinces(): Promise<IStateAndProvince[]> {
    const route: ServerRoute = ServerRoute.forAction('StatesAndProvinces');
    return await API.get(route);
  }

  public async getFSMBAliases(queryString: string): Promise<string[]> {
    const route: ServerRoute = ServerRoute.forAction(
      `FsmbLearnerLicensures`,
      `licenseentitydescriptions?search=${queryString}`,
    );
    return await API.get(route);
  }
}
