import { ReactElement, useEffect } from 'react';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, RadioGroup } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import classNames from 'classnames';

// Components
import ButtonRow from 'layouts/pages/activity/forms/buttonRow';
import { Modal } from 'components/ContinuousImprovement/Modal';
import { FormikNumberField, FormikBooleanRadioField } from 'components/ContinuousImprovement/PureFormElements';
import { ContentBlueprintDropzone } from 'components/ContinuousImprovement/Modal/templates/content-blueprint-dropzone';
import { LoadingCards } from 'components/LoadingCard';
import { NoBlueprintContent } from './NoBlueprintContent';
import { ABAContentOutlines } from 'components/ABAContentOutlines';

// Styles
import pageStyles from '../../BCTPageStyles.module.scss';
import formStyles from '../FormStyles.module.scss';

// Core + Store
import { getBoardById, postBCTStep, setBCTStep } from 'store/bct/actions';
import { closeModal, openModal } from 'store/modal/actions';
import { isModalOpenSelector } from 'store/modal/selectors';
import { currentBoardSelector, isLoadingBCTSelector, validationExceptionsSelector } from 'store/bct/selectors';
import { IValidationException } from 'core/models';

// Utils
import { initialContentBlueprintDetails } from './initialContentBlueprintDetails';
import { handleValidationException } from 'globals/utils/handleValidationException';

// Validations
import { validationSchema } from './validationSchema';

// Types
import { IBCTBoard, IBCTStepProps, IContentOutline } from '../../types';

export const ContentBlueprintDetailsForm = (props: IBCTStepProps): ReactElement => {
  const { prevStep, nextStep } = props;
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();

  // Selectors.
  const currentBoard: IBCTBoard = useSelector(currentBoardSelector);
  const isLoading: boolean = useSelector(isLoadingBCTSelector);
  const isBlueprintModalOpen: boolean = useSelector(isModalOpenSelector);
  const validationExceptions: IValidationException[] = useSelector(validationExceptionsSelector);

  // Fetch.
  useEffect(() => {
    dispatch(getBoardById(id));
  }, [dispatch, id]);

  // Form setup.
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialContentBlueprintDetails(currentBoard),
    onSubmit: async (values: FormikValues): Promise<void> => {
      await dispatch(postBCTStep({ nextStep, values: { ...currentBoard, ...values } }));

      // Scroll to top when done.
      window.scroll(0, 0);
    },
    validationSchema,
  });
  const { handleSubmit, isValid, isSubmitting, resetForm, setFieldError, values } = formik;

  useEffect(() => {
    // Set field specific errors.
    validationExceptions?.forEach((validationException: IValidationException): void => {
      const [formikKey, message] = handleValidationException(validationException);

      setFieldError(formikKey, message);
    });
  }, [setFieldError, validationExceptions]);

  // Show this while data is loading.
  if (isLoading) return <LoadingCards />;

  const handleBackClick = (): void => {
    resetForm();
    dispatch(setBCTStep(prevStep));
    window.scroll(0, 0);
  };

  const contentOutlines = get(values, 'contentOutlines') || [];
  const usesContentOutlines = get(values, 'usesContentOutlines') || false;

  return (
    <>
      <h2 className={pageStyles.subtitle}>Enter or upload the content blueprint details</h2>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <div className={formStyles['form-card']}>
            {/*********************/}
            <fieldset className={classNames({ [formStyles['no-divider']]: !usesContentOutlines })}>
              <legend>Include content blueprint tagging?</legend>
              <RadioGroup aria-label="include content blueprint tagging?" name="usesContentOutlines">
                <Grid container spacing={3}>
                  <Grid item>
                    <FormikBooleanRadioField name="Yes" formikKey="usesContentOutlines" value />
                  </Grid>
                  <Grid item>
                    <FormikBooleanRadioField name="No" formikKey="usesContentOutlines" value={false} />
                  </Grid>
                </Grid>
              </RadioGroup>
            </fieldset>
            {usesContentOutlines && (
              <>
                {/*********************/}
                <fieldset>
                  <legend>
                    Review the content blueprint details below or {/* Leave as HTML5 button */}
                    <button className="link link--hyperlink" onClick={() => dispatch(openModal())} type="button">
                      batch upload
                    </button>
                    .
                  </legend>
                  {contentOutlines?.length ? (
                    <ABAContentOutlines
                      formikKey="contentOutlines.boardMocDetailsSelection"
                      isReadOnly
                      maxSelectionCount={contentOutlines?.length}
                      terms={contentOutlines as IContentOutline[]}
                    />
                  ) : (
                    <NoBlueprintContent />
                  )}
                </fieldset>
                {/*********************/}
                <fieldset className={formStyles['no-divider']}>
                  <legend>Enter the number of allowable content blueprint tags</legend>
                  <Grid container spacing={3}>
                    <Grid item>
                      <FormikNumberField label="Minimum Number of Tags" formikKey="minimumContentOutlines" step={1} />
                    </Grid>
                    <Grid item>
                      <FormikNumberField label="Maximum Number of Tags" formikKey="maximumContentOutlines" step={1} />
                    </Grid>
                  </Grid>
                </fieldset>
              </>
            )}
          </div>
          <ButtonRow
            backLabel="Back"
            ctaLabel="Save and Continue"
            isCtaDisabled={!isValid}
            isSubmitting={isSubmitting}
            isValid={isValid}
            onBackClick={handleBackClick}
            onClick={handleSubmit}
            showBack
          />
          <Modal
            isOpen={isBlueprintModalOpen}
            onClose={() => dispatch(closeModal())}
            title="Batch Upload Content Blueprint"
          >
            <ContentBlueprintDropzone onProceed={() => dispatch(closeModal())} />
          </Modal>
        </form>
      </FormikProvider>
    </>
  );
};
