import { ReactElement, useEffect } from 'react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { taxonomyTabDelimSelector } from 'store/taxonomy/selectors';
import { TAXONOMY_TAB_DELIM_SUPPORT_ID } from 'core/constants';

// Types
interface IProps {
  rootProps: DropzoneRootProps;
  inputProps: DropzoneInputProps;
  showSubTitle: boolean;
}
export const BatchTaxonomyLoader = (): ReactElement => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTaxonomyTermById(TAXONOMY_TAB_DELIM_SUPPORT_ID));
  }, [dispatch]);
  return <></>;
};

export const DropzoneTarget = (props: IProps): ReactElement => {
  const { rootProps, inputProps, showSubTitle } = props;
  const taxonomyTabDelim = useSelector(taxonomyTabDelimSelector);
  return (
    <>
      <BatchTaxonomyLoader />
      <div {...rootProps}>
        <input {...inputProps} />
        <p className="file-uploader-instructions">
          Drop your completed file here, or <span className="link-like">choose file</span>
        </p>
        {showSubTitle && (
          <p className="file-uploader-note">
            Accepts Excel{taxonomyTabDelim?.description === 'On' && ', tab delimited,'} and XML files. Only one batch
            file may be uploaded at a time.
          </p>
        )}
      </div>
    </>
  );
};
