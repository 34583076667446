import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { KeyboardBackspaceRounded } from '@material-ui/icons';

// Components
import { Modal } from 'components/ContinuousImprovement/Modal';
import ShowHide from 'components/ShowHide/ShowHide';
import { DuplicateFileDetected } from 'components/ContinuousImprovement/Modal/templates/duplicate-file-detected';
import { PrepareUploadLearnersModal } from 'components/ContinuousImprovement/Modal/templates/prepare-upload-learners';

// Store + Core
import { LEARNER_TEMPLATE_ID_MAP } from 'store/fileDownload/types';
import { downloadFile } from 'store/fileDownload/actions';

import { BatchLearnerForm } from '../forms/BatchLearnerForm';
import { closeModal } from 'store/modal/actions';
import { isModalOpenSelector } from 'store/modal/selectors';
import { learnerFileSelector } from 'store/learner/selectors';
import { batchLearner, batchLearnerClearAction } from 'store/learner/actions';
import { ManualDownloadLink } from 'components/ContinuousImprovement/ManualDownloadLink';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { taxonomyTabDelimSelector, taxonomyBatchHelpSelector } from 'store/taxonomy/selectors';
import { TAXONOMY_TAB_DELIM_SUPPORT_ID, TAXONOMY_BATCH_HELP_ROOT_ID, TAGS_ENUM } from 'core/constants';
import { rollupOrganizationEnumSelector } from 'store/user/selectors';
import { filterTaxonomyByRollupOrganizationEnum } from 'globals/utils/filterTaxonomyByOrg';

// Types
interface IBatchLearnerParams {
  type?: string;
}

export const BatchLearnerPage = (): ReactElement => {
  const taxonomyTabDelim = useSelector(taxonomyTabDelimSelector);
  const taxonomyBatchHelpTerms = useSelector(taxonomyBatchHelpSelector);
  const { pathname } = useLocation();
  const { type = '' } = useParams<IBatchLearnerParams>();

  const dispatch = useDispatch();
  const isModalOpen: boolean = useSelector(isModalOpenSelector);
  const file: { file: File } = useSelector(learnerFileSelector);

  const downloadRoute = '/activities/learnercompletions/batches/downloadtemplate';

  useEffect(() => {
    dispatch(getTaxonomyTermById(TAXONOMY_TAB_DELIM_SUPPORT_ID));
    dispatch(getTaxonomyTermById(TAXONOMY_BATCH_HELP_ROOT_ID));
  }, [dispatch]);

  useEffect(() => {
    if (pathname?.includes(downloadRoute) && LEARNER_TEMPLATE_ID_MAP[type]) {
      dispatch(downloadFile({ controller: downloadRoute, id: type, matchTemplate: LEARNER_TEMPLATE_ID_MAP }));
    }
  });

  const onProceed = () =>
    dispatch(
      batchLearner({
        file: { file } as { file },
        shouldForce: true,
      }),
    );

  const onCancel = (): void => {
    dispatch(batchLearnerClearAction());
    dispatch(closeModal());
  };

  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  const learnerExcelInstructions = filterTaxonomyByRollupOrganizationEnum(
    taxonomyBatchHelpTerms?.terms,
    rollupOrganizationEnum,
  ).filter((lt) => lt.tag === TAGS_ENUM.BATCH_HELP__LEARNER_EXCEL_INSTRUCTIONS)[0]?.description;
  const learnerXmlInstructions = filterTaxonomyByRollupOrganizationEnum(
    taxonomyBatchHelpTerms?.terms,
    rollupOrganizationEnum,
  ).filter((lt) => lt.tag === TAGS_ENUM.BATCH_HELP__LEARNER_XML_INSTRUCTIONS)[0]?.description;

  return (
    <section className="form-container">
      <div className="form-title-container">
        <Link className="tertiary" to="/learners/batch/history">
          View Upload History
          <KeyboardBackspaceRounded className="tertiary-icon" />
        </Link>
        <h4 className="title">
          <div className="eyebrow">Batch Upload Learner</div>
          Add, update or delete learner
        </h4>
      </div>
      <div className="form-flex-row">
        <div className="form-column">
          <ShowHide title="Help">
            <div className="help-container">
              <h5>The following methods of preparing batch learners are available:</h5>
              <div className="help-list">
                <ul>
                  <li className="help-item help-item--xls">
                    <div className="heading">Excel</div>
                    <p>Upload your Excel file from one of our standard templates.</p>
                    <a
                      className="tertiary tertiary--small"
                      href={learnerExcelInstructions}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Get the Detailed Excel Instructions
                      <KeyboardBackspaceRounded className="tertiary-icon" />
                    </a>
                    <div className="batch-templates">
                      <ul>
                        <li>
                          <ManualDownloadLink to={downloadRoute} id="0" map={LEARNER_TEMPLATE_ID_MAP}>
                            <p>Batch Learner Excel File</p>
                          </ManualDownloadLink>
                          <p>Use for CME and MOC</p>
                        </li>
                        <li>
                          <ManualDownloadLink to={downloadRoute} id="2" map={LEARNER_TEMPLATE_ID_MAP}>
                            <p>Batch Learner Excel File with REMS</p>
                          </ManualDownloadLink>
                          <p>Use for REMS</p>
                        </li>
                      </ul>
                    </div>
                  </li>
                  {taxonomyTabDelim?.description === 'On' && (
                    <li className="help-item help-item--td">
                      <div className="heading">TAB Delimited</div>
                      <p>Upload a tab delimited file generated from an Excel Template.</p>
                      <a
                        className="tertiary tertiary--small"
                        href="https://www.accme.org/publications/PARS/tab-delimited-submission-instructions-learner-template-b"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Get the Detailed Tab Delimited Instructions
                        <KeyboardBackspaceRounded className="tertiary-icon" />
                      </a>
                      <div className="batch-templates">
                        <ul>
                          <li>
                            <ManualDownloadLink to={downloadRoute} id="1" map={LEARNER_TEMPLATE_ID_MAP}>
                              <p>Batch Learner Tab Delimited File</p>
                            </ManualDownloadLink>
                            <p>Use for CME and MOC</p>
                          </li>
                          <li>
                            <ManualDownloadLink to={downloadRoute} id="3" map={LEARNER_TEMPLATE_ID_MAP}>
                              <p>Batch Learner Tab Delimited File with REMS</p>
                            </ManualDownloadLink>
                            <p>Use for REMS</p>
                          </li>
                        </ul>
                      </div>
                    </li>
                  )}
                  <li className="help-item help-item--xml">
                    <div className="heading">XML</div>
                    <p>
                      Upload XML files formatted to conform with the Medical Education Metrics (MEMS) XML standard
                      created by MedBiquitous Consortium. <br />
                      Technical knowledge of XML is required to use this batch upload feature.
                    </p>
                    <a
                      className="tertiary tertiary--small"
                      href={learnerXmlInstructions}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Get the Detailed XML Instructions
                      <KeyboardBackspaceRounded className="tertiary-icon" />
                    </a>
                    <li
                      style={{ color: '#373737', fontSize: '0.75rem', letterSpacing: '0.025rem', lineHeight: 1.66667 }}
                    >
                      Use for CME and MOC
                    </li>
                  </li>
                </ul>
              </div>
            </div>
          </ShowHide>
        </div>
      </div>
      <div className="form-flex-row">
        <div className="form-column grid-4">
          <PrepareUploadLearnersModal />
          <div className="batch-templates">
            <div className="heading">Batch Templates</div>
            <ul>
              <li>
                <ManualDownloadLink to={downloadRoute} id="0" map={LEARNER_TEMPLATE_ID_MAP}>
                  <p>Batch Learner Excel File</p>
                </ManualDownloadLink>
                <p>Use for CME and MOC</p>
              </li>
              {taxonomyTabDelim?.description === 'On' && (
                <li>
                  <ManualDownloadLink to={downloadRoute} id="1" map={LEARNER_TEMPLATE_ID_MAP}>
                    <p>Batch Learner Tab Delimited File</p>
                  </ManualDownloadLink>
                  <p>Use for CME and MOC</p>
                </li>
              )}
              <li>
                <ManualDownloadLink to={downloadRoute} id="2" map={LEARNER_TEMPLATE_ID_MAP}>
                  <p>Batch Learner Excel File with REMS</p>
                </ManualDownloadLink>
                <p>Use for REMS</p>
              </li>
              {taxonomyTabDelim?.description === 'On' && (
                <li>
                  <ManualDownloadLink to={downloadRoute} id="3" map={LEARNER_TEMPLATE_ID_MAP}>
                    <p>Batch Learner Tab with REMS Tab Delimited File</p>
                  </ManualDownloadLink>
                  <p>Use for REMS</p>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="form-column">
          <h5>2&#41; Upload your completed file.</h5>
          <BatchLearnerForm />
        </div>
        <Modal isOpen={isModalOpen} onClose={() => dispatch(closeModal)} title="Potential duplicate file detected">
          <DuplicateFileDetected onCancel={onCancel} onProceed={onProceed} />
        </Modal>
      </div>
    </section>
  );
};
