/* eslint-disable sort-keys */
import {
  Box,
  Grid,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import { FormikProvider, useFormik } from 'formik';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { drop, sortBy, take, truncate } from 'lodash';

import { FormikInputField, FormikRadioField } from 'components/ContinuousImprovement/PureFormElements';
import { HighlightBox } from 'components/HighlightBox';
import { LoadingCards } from 'components/LoadingCard';
import { ButtonVariant } from 'core/enums';
import Dropdown from 'components/Dropdown/Dropdown';
import Button from 'components/Button/Button';

import {
  IAccreditationType,
  IOrganizationStatus,
  RollupOrganizationEnums,
  SearchOrganizationProviderRequest,
  SearchOrganizationProviderResponse,
} from 'core/models';
import { useLoadData } from 'hooks/useLoadData';
import { useAccountManagerInfo } from './hooks';
import { filterItemsByRollupOrganizationEnum } from 'globals/utils/filterItemsByRollupOrgCode';

import styles from './search.module.scss';
import fieldStyles from 'components/ContinuousImprovement/PureFormElements/FormikFields.module.scss';

// selectors
import { userProfileSelector } from 'store/user/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { AccreditationService } from 'services/Accreditation';
import { OrganizationProfileService } from 'services/OrganizationProfileService';
import { popToast } from 'store/toast/actions';
import { handleServerError } from 'globals/utils/handleServerError';
import { errorToastOptions } from 'store/toast/constants';
import { Modal } from 'components/ContinuousImprovement';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'core/constants';
import InputBlock from '../../../components/global/inputBlock/inputBlock';
import CustomPagination from '../../../components/Table/CustomPagination';
import CustomTooltip from '../../../components/Tooltip/Tooltip';

interface ISearchProviderModal extends Omit<SearchOrganizationProviderRequest, 'organizationId'> {}

interface ToggleProviderStatusFormProps {
  organization: SearchOrganizationProviderResponse;
  onClose: VoidFunction;
  onSuccess: VoidFunction;
}

export const ToggleProviderStatusForm = ({
  organization,
  onClose,
  onSuccess,
}: ToggleProviderStatusFormProps): ReactElement => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {},
    onSubmit: async (): Promise<void> => {
      try {
        await OrganizationProfileService.toggleOrganizationStatus(organization.id, organization.isDeleted);
        onClose();
        onSuccess?.();
      } catch (error) {
        dispatch(popToast({ ...errorToastOptions, message: <>{handleServerError({ error }).errorMessage}</> }));
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div>Are you sure you want to {organization?.isDeleted ? 'Activate' : 'Inactivate'}?</div>
        <footer className={styles['modal-footer']}>
          <Button onClick={onClose} variant={ButtonVariant.Secondary}>
            Cancel
          </Button>
          <Button
            disabled={formik.isSubmitting}
            isSubmitting={formik.isSubmitting}
            type="submit"
            variant={ButtonVariant.Primary}
          >
            {organization?.isDeleted ? 'Activate' : 'Inactivate'}
          </Button>
        </footer>
      </form>
    </FormikProvider>
  );
};

const pageSize = 10;

export const ProviderSearch = (): ReactElement => {
  const { organization, isAnccOrganization, isAccreditor } = useAccountManagerInfo();
  const [providerSearch, setProviderSearch] = useState<SearchOrganizationProviderRequest>({
    organizationId: organization.id,
    providerType: '',
    organizationStatusId: '',
    providerName: '',
    businessId: '',
    accreditationTypeId: '',
    accreditorApproverId: '',
    searchDeleted: '2',
  });
  const { data: providerSearchData, error, isLoading, refresh } = useLoadData(
    'ProviderSearch',
    useCallback(async () => {
      return await OrganizationProfileService.searchOrganizationProvider(providerSearch);
    }, [providerSearch]),
  );

  const [toDelete, setToDelete] = useState<SearchOrganizationProviderResponse | null>(null);

  const userProfile = useSelector(userProfileSelector);

  const organizationOptions = useMemo(
    () =>
      sortBy(
        userProfile?.primaryUserOrganizations.filter(
          (org) => org.rollupOrganizationEnum === RollupOrganizationEnums.NARS && org.organizationKind === 'Accreditor',
        ) || [],
        'organizationName',
        'businessId',
      ),
    [userProfile?.primaryUserOrganizations],
  );

  const providerTypeOptions = [
    'Accredited Approver',
    'Accredited Provider',
    'Approved Provider',
    'Approver of Providers',
    'Accredited International Provider',
  ].map((i) => ({
    id: i,
    name: i,
  }));

  const { data: organizationStatus } = useLoadData(
    'OrganizationStatus',
    useCallback(async () => {
      return OrganizationProfileService.getOrganizationStatus();
    }, []),
  );

  const { data: accreditationData } = useLoadData(
    'AccreditationType',
    useCallback(async () => {
      return await AccreditationService.getAccreditationTypes();
    }, []),
  );

  const accreditationTypes: IAccreditationType[] = filterItemsByRollupOrganizationEnum(
    accreditationData,
    organization.rollupOrganizationEnum,
  );

  const filteredOrganizationStatus: IOrganizationStatus[] = filterItemsByRollupOrganizationEnum(
    organizationStatus,
    organization.rollupOrganizationEnum,
  );

  /*
  For the most part, this follows the same logic as 'OrganizationProfileService.ConfirmSecurity' 
  We don't need to look for a matching primary organization since we are disabling/enabling all of the buttons 
  instead of checking each individually.
  */
  const canToggleStatus = useMemo(() => {
    const hasParentPrimaryOrg = userProfile.primaryUserOrganizations.some((o) =>
      o.childOrganizationIds.includes(organization.id),
    );
    return (
      userProfile.staffRollupOrganizationEnums.includes(organization.rollupOrganizationEnum) ||
      hasParentPrimaryOrg ||
      isAccreditor
    );
  }, [userProfile]);

  const [page, setPage] = useState(0);
  const formik = useFormik<ISearchProviderModal>({
    initialValues: {
      searchDeleted: '2',
    } as ISearchProviderModal,
    onSubmit: async (values) => {
      const {
        businessId,
        accreditationTypeId,
        organizationStatusId,
        providerType,
        providerName,
        accreditorApproverId,
        searchDeleted,
      } = values;
      const req: SearchOrganizationProviderRequest = {
        organizationId: organization.id,
        businessId,
        accreditationTypeId,
        organizationStatusId,
        providerType,
        providerName,
        accreditorApproverId,
        searchDeleted,
      };
      setProviderSearch(req);
      setPage(0);
    },
  });
  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="static-form">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <label className={fieldStyles.label} htmlFor="accreditorApproverId">
                  Accreditor/Approver
                </label>
                <Dropdown
                  items={organizationOptions}
                  name="accreditorApproverId"
                  placeholder="Accreditor/Approver"
                  idProp="id"
                  prefixField="businessId"
                  labelProp="organizationName"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormikInputField label="Provider Name" formikKey="providerName" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormikInputField label="Provider Organization Id" formikKey="businessId" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={fieldStyles.label} htmlFor="title">
                  Provider Type
                </label>
                <Dropdown items={providerTypeOptions} name="providerType" placeholder="Provider Type" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={fieldStyles.label} htmlFor="organizationStatusId">
                  Organization Status
                </label>
                <Dropdown
                  items={filteredOrganizationStatus}
                  name="organizationStatusId"
                  placeholder="Organization Status"
                  idProp="id"
                  labelProp="name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={fieldStyles.label} htmlFor="organizationStatusId">
                  Current Accreditation Status
                </label>
                <Dropdown
                  items={accreditationTypes}
                  name="accreditationTypeId"
                  placeholder="Accreditation Status"
                  idProp="id"
                  labelProp="description"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputBlock name="searchDeleted" title="NARS Status:">
                  <RadioGroup aria-label="nars status">
                    <Grid container spacing={3}>
                      <Grid item>
                        <FormikRadioField name="Active" value="0" formikKey="searchDeleted" />
                      </Grid>
                      <Grid item>
                        <FormikRadioField name="Inactive" value="1" formikKey="searchDeleted" />
                      </Grid>
                      <Grid item>
                        <FormikRadioField name="Show All" value="2" formikKey="searchDeleted" />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </InputBlock>
              </Grid>
            </Grid>
          </div>
          <div className={styles['search-buttons']}>
            <Button type="submit" size="small" variant={ButtonVariant.Primary}>
              Search
            </Button>
            <Button
              size="small"
              variant={ButtonVariant.Primary}
              className={styles['search-button']}
              onClick={() => {
                formik.resetForm();
                formik.submitForm();
                setPage(0);
              }}
            >
              Reset
            </Button>
          </div>
        </form>
      </FormikProvider>
      <Modal
        isOpen={!!toDelete}
        title={toDelete?.isDeleted ? 'Activate Organization' : 'Inactivate Organization'}
        onClose={() => setToDelete(null)}
      >
        <ToggleProviderStatusForm organization={toDelete} onClose={() => setToDelete(null)} onSuccess={refresh} />
      </Modal>
      <div>
        {isLoading ? (
          <LoadingCards count={3} />
        ) : error ? (
          <HighlightBox variant="danger">
            <Box display="flex" component="p">
              <WarningRounded />
              <Box ml={2}>{error}</Box>
            </Box>
          </HighlightBox>
        ) : (
          <TableContainer role="grid">
            <Table className={styles.table} size="small">
              <TableHead className={styles.tableHeader}>
                <TableRow>
                  <TableCell component="th">Organization ID</TableCell>
                  <TableCell component="th">Provider Name</TableCell>
                  <TableCell component="th">Provider Type</TableCell>
                  <TableCell component="th">Organization Type</TableCell>
                  <TableCell component="th">Organization Status</TableCell>
                  <TableCell component="th">Accreditation Expiration Date</TableCell>
                  {isAnccOrganization && <TableCell component="th">Accreditor/Approver</TableCell>}
                  <TableCell component="th">NARS Status</TableCell>
                  <TableCell component="th">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {take(drop(providerSearchData, page * pageSize), pageSize).map((provider) => (
                  <TableRow key={provider.organizationId}>
                    <TableCell>{provider.providerOrganizationID}</TableCell>
                    <TableCell className={styles['width-restricted-cell']}>
                      <CustomTooltip tooltipText={provider.providerName}>
                        <div>{truncate(provider.providerName, { length: 22 })}</div>
                      </CustomTooltip>
                    </TableCell>
                    <TableCell className={styles['width-restricted-cell']}>{provider.providerType}</TableCell>
                    <TableCell className={styles['width-restricted-cell']}>
                      <CustomTooltip tooltipText={provider.organizationTypeName}>
                        <div>{truncate(provider.organizationTypeName, { length: 22 })}</div>
                      </CustomTooltip>
                    </TableCell>
                    <TableCell>{provider.organizationStatusName}</TableCell>
                    <TableCell>
                      {moment(provider.accreditationExpirationDate).utc().format(DEFAULT_DATE_FORMAT)}
                    </TableCell>
                    {isAnccOrganization && (
                      <TableCell className={styles['width-restricted-cell']}>
                        <CustomTooltip tooltipText={provider.organizationTypeName}>
                          <div>{truncate(provider.organizationTypeName, { length: 22 })}</div>
                        </CustomTooltip>
                      </TableCell>
                    )}
                    <TableCell>{provider.isDeleted ? 'Inactive' : 'Active'}</TableCell>
                    <TableCell>
                      <div className={styles['action-buttons']}>
                        <Button
                          component={Link}
                          title="Edit User"
                          to={`/account-manager/provider/edit/${provider.id}`}
                          variant={ButtonVariant.Secondary}
                        >
                          Update
                        </Button>
                        <Button
                          title={!provider.isDeleted ? 'Inactivate Organization' : 'Activate Organization'}
                          variant={ButtonVariant.Secondary}
                          onClick={() => setToDelete(provider)}
                          disabled={!canToggleStatus}
                        >
                          {provider.isDeleted ? 'Activate' : 'Inactivate'}
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={7}>
                    <div>
                      Showing {page * pageSize + 1} to {Math.min((page + 1) * pageSize, providerSearchData.length)} of{' '}
                      {providerSearchData.length} matching providers
                    </div>
                    <CustomPagination
                      dataSet={providerSearchData}
                      page={page}
                      onPageChange={(callbackPage) => setPage(callbackPage)}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
};
