import { ReactElement } from 'react';
import { Field } from 'formik';
import { Box, Typography } from '@material-ui/core';

// Types
import { FormikCheckboxFieldProps } from 'components/forms/types';
interface IProps extends FormikCheckboxFieldProps {
  formikKey: string;
  name?: string;
  payloadValue?: string;
  disabled?: boolean;
}

export const FormikCheckboxField = ({
  checked,
  disabled,
  payloadValue,
  formikKey,
  name,
  onChange,
}: IProps): ReactElement => {
  return (
    <Box display="flex">
      <label className="form-input-checkbox">
        <Field
          checked={checked}
          name={formikKey}
          type="checkbox"
          value={payloadValue || formikKey}
          onChange={onChange}
          {...((disabled === true || disabled === false) && { disabled })}
        />
        <span className="checkmark" />
        <Typography variant="srOnly">{name}</Typography>
      </label>
      <div className="label-text-container">
        <div className="label-text">{name}</div>
      </div>
    </Box>
  );
};
