import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ICountry, IStateAndProvince } from 'core/models';
import { countriesSelector, statesAndProvincesSelector } from './selectors';
import { getCountries, getStatesAndProvinces } from './actions';

export const useCountries = (): ICountry[] => {
  const dispatch = useDispatch();
  const countries: ICountry[] = useSelector(countriesSelector);
  useEffect(() => {
    if (!countries) {
      dispatch(getCountries());
    }
  }, [countries, dispatch]);
  return countries;
};

export const useStates = (): IStateAndProvince[] => {
  const dispatch = useDispatch();
  const states: IStateAndProvince[] = useSelector(statesAndProvincesSelector);
  useEffect(() => {
    if (!states) {
      dispatch(getStatesAndProvinces());
    }
  }, [states, dispatch]);
  return states;
};
