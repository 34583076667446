// Core + Store
import { IAccreditationType, Accreditation } from 'core/models';
import { ServerRoute } from 'core/api';
import { API } from 'core';

export class AccreditationService {
  public static async getAccreditationTypes(): Promise<IAccreditationType[]> {
    const route: ServerRoute = ServerRoute.forAction('accreditations', 'accreditationtypes');
    return await API.get(route);
  }

  public static async addAccreditationInformation(req: Accreditation): Promise<{ success: boolean; error: string }> {
    const route: ServerRoute = ServerRoute.forAction('accreditations');
    return await API.post(route, req);
  }

  public static async updateAccreditationInformation(
    accrediationId: string,
    req: Accreditation,
  ): Promise<{ success: boolean; error: string }> {
    const route: ServerRoute = ServerRoute.forAction('accreditations', `${accrediationId}`);
    return await API.put(route, req);
  }

  public static async getAccreditation(accrediationId: string): Promise<Accreditation> {
    const route: ServerRoute = ServerRoute.forAction('accreditations', `${accrediationId}`);
    return await API.get(route);
  }

  public static async searchAccreditationInfoAsync(accrediteeOrganizationId: string): Promise<Accreditation[]> {
    const route: ServerRoute = ServerRoute.forAction('accreditations', `search/${accrediteeOrganizationId}`);
    return await API.get(route);
  }

  public static async deleteAccreditationInformation(
    accrediationId: string,
  ): Promise<{ success: boolean; error: string }> {
    const route: ServerRoute = ServerRoute.forAction('accreditations', `${accrediationId}`);
    return await API.sendDelete(route);
  }
}
