import { ReactElement } from 'react';
import moment from 'moment';
import { ITypeaheadOption } from 'components/ContinuousImprovement/Typeahead';

interface ILearnerValidationToolResultProps {
  titleSummary: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  stateName: ITypeaheadOption;
  licenseId: string;
  boardName: ITypeaheadOption;
  boardId: string;
  npi: string;
  medicalSchoolName: ITypeaheadOption;
}

export const LearnerValidationToolResult = (props: ILearnerValidationToolResultProps): ReactElement => {
  const {
    titleSummary,
    firstName,
    lastName,
    dateOfBirth,
    stateName,
    licenseId,
    boardName,
    boardId,
    npi,
    medicalSchoolName,
  } = props;
  return (
    <div>
      {titleSummary && (
        <p>
          <strong>{titleSummary}</strong>
        </p>
      )}
      {firstName && <p>First Name: {firstName}</p>}
      {lastName && <p>Last Name: {lastName}</p>}
      {dateOfBirth && <p>DOB: {moment(dateOfBirth).format('MM/DD')}</p>}
      {stateName && <p>Licensing State: {stateName.label}</p>}
      {licenseId && <p>License ID: {licenseId}</p>}
      {boardName && <p>Board Name: {boardName.label}</p>}
      {boardId && <p>Board ID: {boardId}</p>}
      {npi && <p>NPI: {npi}</p>}
      {medicalSchoolName && <p>Medical School: {medicalSchoolName.label}</p>}
    </div>
  );
};
