import { ReactElement, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

// Components
import UserControl from 'components/UserControl/UserControl';
import { Nav } from 'components/Nav';
import MobileHeader from 'components/Header/MobileHeader';
import { Subnav } from 'components/Nav/Subnav';

// Styles
import './header.module.scss';

// Hooks
import { useTaxonomyHeaderNavTerms } from 'hooks/useTaxonomyHeaderNavTerms';

// Core + Store
import { navigationSelector } from 'store/taxonomy/selectors';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { userProfileSelector } from 'store/user/selectors';
import { TAXONOMY_NAVIGATION_ROOT_ID } from 'core/constants';
import { ITaxonomyTerm, IUser } from 'core/models';

// Types
interface IProps {
  hideSubnav?: boolean;
}

export const Header = (props: IProps): ReactElement => {
  const { hideSubnav } = props;
  const dispatch = useDispatch();
  const allowedNavItems = useTaxonomyHeaderNavTerms();

  // Selectors
  const navTerm: ITaxonomyTerm = useSelector(navigationSelector);
  const userProfile: IUser = useSelector(userProfileSelector);

  useEffect(() => {
    if (!navTerm && userProfile) {
      dispatch(getTaxonomyTermById(TAXONOMY_NAVIGATION_ROOT_ID));
    }
  }, [dispatch, navTerm, userProfile]);

  return (
    <header className={classNames('header')}>
      <UserControl />
      <div className="globalnav">
        <Nav items={allowedNavItems} />
      </div>
      {!hideSubnav && <Subnav />}
      <MobileHeader items={allowedNavItems} />
    </header>
  );
};
