// Libs
import { keys } from 'lodash';

// Core
import { Activity, ActivityType, IDisabledContext } from 'core/models';
import { IBCTBoard } from 'layouts/pages/bct/types';

export interface IDisabledContextBlueprint {
  activity: Activity;
  activityTypeGroups: ActivityType[][];
  configuredBoards: IBCTBoard[];
  activityTypes: ActivityType[];
}
export const buildDisabledFieldContext = ({
  activity,
  activityTypeGroups,
  configuredBoards,
  activityTypes,
}: IDisabledContextBlueprint): IDisabledContext => {
  if (!activity) {
    return;
  }

  // For reference on the business rules, see the Business Rules List doc
  // There is also a quick reference doc called Disabled Fields
  const {
    typeId,
    hasIndividualLearners,
    hasRemsLearnersReported,
    maximumCertifyingBoardCredits,
    maximumCertifyingBoardCreditTypeCredits,
    mocProgramAttestation,
    boardMocDetails,
  } = activity;

  const disabledFormFields: IDisabledContext = {};
  // Rule 1: Format

  // - If activity type is in one activity type group, disable the opposite group
  const [typeGroupOne, typeGroupTwo] = activityTypeGroups;
  const disabledActivityTypesByGroup: ActivityType[] = typeGroupOne?.find(({ id }) => id === typeId)
    ? typeGroupTwo
    : typeGroupOne;

  // Disable activity types that are not compatible with selected MOC boards
  // for any selected MOC boards...
  const disabledActivityTypesByMoc = Object.keys(boardMocDetails).reduce((accumulator, boardId) => {
    activityTypes // compare all activity types
      .filter(
        (activityType) =>
          !configuredBoards // only return types that are NOT matched
            ?.find((board) => board.id === boardId) // grab the metatadata for this board Id
            ?.boardActivityTypes?.find(({ id }) => activityType.id === id), // see if the activity type is supported by the board
      )
      .forEach(({ id }) => accumulator.push(id)); // add each non-supported activity type to the acumulator
    return accumulator;
  }, []); // empty array is the initial value of the accumulator

  const disabledOptions = Array.from(
    // de-dupe the disabled activity types
    new Set([
      ...disabledActivityTypesByMoc,
      ...(hasIndividualLearners ? disabledActivityTypesByGroup.map(({ id }) => id) : []),
    ]),
  );

  disabledFormFields.typeId = {
    disabledOptions,
    isDisabled: disabledOptions.length,
  };

  const reportedMocBoards: string[] = keys(maximumCertifyingBoardCredits);
  const hasMocLearners = !!keys(maximumCertifyingBoardCredits)?.length;

  // Rule 2: MOC Radio
  // - If MOC learners reported, disabled
  disabledFormFields.isMoc = {
    isDisabled: hasMocLearners,
  };

  // Rule 3: REMS Radio
  // - If REMS learners reported, disabled
  disabledFormFields.isRems = {
    isDisabled: hasRemsLearnersReported,
  };

  // Rule 4: Certifying Board Selections
  // - Each individual board that has learners reported is disabled
  disabledFormFields.boardMocDetailsSelection = {
    disabledOptions: reportedMocBoards,
    isDisabled: hasMocLearners,
  };

  // Rule 5: REMS Selections
  // - REMS options are disabled if learners reported
  // - TODO: Bug 4122 - We do not have a field on the activity to explain which REMS options are disabled; skip for now
  // Fix for Bug 3930
  disabledFormFields.isRems = {
    disabledOptions: ['isRems-false'],
    isDisabled: hasRemsLearnersReported,
  };

  // Rule 6: MOC Credit Type Selection
  // - Each credit option that has learners reported for a given board is disabled
  if (reportedMocBoards?.length) {
    disabledFormFields.boardMocDetails = {};
  }
  reportedMocBoards?.forEach((boardId) => {
    const reportedCredits: string[] = keys(maximumCertifyingBoardCreditTypeCredits?.[boardId]);
    if (reportedCredits?.length) {
      disabledFormFields.boardMocDetails[boardId] = {
        typesOfCreditIds: {
          disabledOptions: reportedCredits,
          isDisabled: true,
        },
      };
    }
  });

  // Rule 7: MOC Attestation
  // - If MOC learners are reported, disable
  disabledFormFields.mocProgramAttestation = {
    disabledOptions: ['mocProgramAttestation'],
    isDisabled: hasMocLearners && mocProgramAttestation,
  };

  // Rule 8: REMS Attestation
  // - TODO: Bug 4122 - We do not have a field on the activity to explain which REMS have been reported; skip for now
  return disabledFormFields;
};
