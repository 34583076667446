import React from 'react';
import * as PARS from 'core';
import { AddRounded, RemoveRounded } from '@material-ui/icons';
import classNames from 'classnames';
import './accordion.module.scss';

export interface IAccordionState {
  open: number;
}

export interface IAccordionProps {
  items: Array<PARS.Models.IAccordionItem>;
}

export default class Accordion extends React.Component<IAccordionProps, IAccordionState> {
  constructor(props) {
    super(props);

    this.state = {
      open: 0,
    };
  }

  private _onClick(open): void {
    this.setState({ open });
  }

  public render(): React.ReactElement<IAccordionProps> {
    const { items } = this.props;
    const { open } = this.state;

    return (
      <ul className="accordion">
        {items.map((item, index) => {
          const isActive: boolean = index === open;
          const itemClass: string = classNames('accordion-item', {
            'accordion-item--active': isActive,
          });
          return (
            <li className={itemClass} key={item.title}>
              <button
                className="accordion-item-heading"
                aria-expanded={isActive}
                aria-controls={`section-${index}`}
                onClick={() => this._onClick(isActive ? null : index)}
              >
                <div className="accordion-item-title">
                  {item.title}
                  {isActive ? <RemoveRounded /> : <AddRounded />}
                </div>
              </button>
              <div id={`section-${index}`} className="accordion-item-content">
                {typeof item.content === 'string' ? (
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                ) : (
                  <div>{item.content}</div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    );
  }
}
