import classNames from 'classnames';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Store.
import {
  currentContextualOrg,
  isBoardUserSelector,
  rollupOrganizationEnumSelector,
  userProfileSelector,
} from 'store/user/selectors';

// Styles.
import styles from './DashboardGreeting.module.scss';
import { RollupOrganizationEnums } from 'core/models';

interface IDashboardGreetingProps {
  className?: string;
}

export const DashboardGreeting = (props: IDashboardGreetingProps): ReactElement => {
  const { className } = props;

  // Selectors.
  const { firstName } = useSelector(userProfileSelector);
  const { businessId, organizationName } = useSelector(currentContextualOrg);
  const isBoardUser: boolean = useSelector(isBoardUserSelector);
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  const isNARS = rollupOrganizationEnum === RollupOrganizationEnums.NARS;

  return (
    <section className={classNames(styles.greeting, className)}>
      <h1 className={styles.title}>Hello, {firstName}</h1>
      <p className={styles.info}>
        {organizationName} | Organization ID: {businessId}
      </p>
      {!isBoardUser && (
        <nav className={styles.navigation}>
          <ul>
            {!isNARS && (
              <ul className={styles.providerUL}>
                <li>
                  <Link to="/dashboard/summary">Program Summary</Link>
                </li>
              </ul>
            )}
            <ul className={styles.providerUL}>
              <li>
                <Link to="/dashboard/agreement">
                  {isNARS ? 'Provider/Approver Agreement(s)' : 'Provider Agreement(s)'}
                </Link>
              </li>
            </ul>
          </ul>
        </nav>
      )}
    </section>
  );
};
