import { ChangeEvent, ReactElement, FocusEvent, useState, useEffect, useMemo } from 'react';
import { CloseRounded } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import { isEqual, uniqueId } from 'lodash';
import { useField, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

// Components
import Button from 'components/Button/Button';
import Dropdown, { IDropdownType } from 'components/Dropdown/Dropdown';
import InputBlock from 'components/global/inputBlock/inputBlock';
import { FastFormikTextField as FormikTextField } from 'components/ContinuousImprovement/FastFormikTextField';
import { FastFormikCheckbox as FormikCheckboxField } from 'components/ContinuousImprovement/FastFormikCheckbox';
import { FormikCheckboxConditionalNumberInput } from 'components/forms/FormikCheckboxConditionalNumberInput';
import { FormikNumberField } from 'components/forms';

// Core + Store
import {
  IFormInputOption,
  IDictionary,
  IAddLearnersFormModel,
  ICertifyingBoardFormModel,
  ILearnerMatchingModel,
  ILearnerIdMatchField,
  IMatchedLearner,
  IStateAndProvince,
} from 'core/models';
import {
  isLearnerMatchingSelector,
  matchedLearnersSelector,
  previousMatchQuerySelector,
} from 'store/learner/selectors';
import { matchLearners } from 'store/learner/actions';
import { statesAndProvincesSelector } from 'store/locations/selectors';

// Constants
import {
  BoardLearnerCollectionFields,
  BoardTypes,
  CollectionTypes,
  IBCTBoard,
  IBCTBoardLearnerCollectionField,
} from 'layouts/pages/bct/types';

// Styles
import styles from 'layouts/pages/learner/forms/AddLearnerForm/AddLearnerForm.module.scss';

// Misc
import { initialCertifyingBoardValues } from '../AddLearnerForm';
import { getMatchPayloadFromFormValues } from 'layouts/pages/learner/add-learner/utils';

interface IProps {
  boardCreditOptions: IDictionary<IFormInputOption[]>;
  callback(isBoardMatched): void;
  config: IBCTBoard;
  deaRegistrationOptions: IFormInputOption[];
  handleRemoveBoard: (index: number) => void;
  hasBoards: boolean;
  hasCollabs: boolean;
  index: number;
  learnerBoards?: ILearnerIdMatchField[];
  mocBoardList: IFormInputOption[];
  name: string;
  practiceAreaOptions: IFormInputOption[];
  professionOptions: IFormInputOption[];
  remsList: IFormInputOption[];
  timeInPracticeOptions: IFormInputOption[];
}

export const CertifyingBoardForm = (props: IProps): ReactElement => {
  const {
    boardCreditOptions,
    callback,
    config,
    deaRegistrationOptions,
    handleRemoveBoard,
    hasBoards,
    hasCollabs,
    index,
    learnerBoards,
    mocBoardList,
    name,
    practiceAreaOptions,
    professionOptions,
    remsList,
    timeInPracticeOptions,
  } = props;

  // Local State.
  const [matchAttempts, setMatchAttempts] = useState<number>(0);

  // Identifier in the parent form for this specific board
  const baseCertBoardFieldName = `${name}.${index}`;
  const learnerFieldConfig: IBCTBoardLearnerCollectionField[] = config?.boardLearnerCollectionFields;
  const boardName: string = config?.name;

  const getFieldConfig = useMemo(
    () => (fieldType: BoardLearnerCollectionFields): IBCTBoardLearnerCollectionField =>
      learnerFieldConfig?.find(
        ({ fieldType: fieldName }: IBCTBoardLearnerCollectionField): boolean => fieldName === fieldType,
      ),
    [learnerFieldConfig],
  );

  // Get required-ness and visibility of each field
  const boardIdConfig: IBCTBoardLearnerCollectionField = getFieldConfig(BoardLearnerCollectionFields.BOARD_ID);
  const learnerIdConfig: IBCTBoardLearnerCollectionField = getFieldConfig(
    BoardLearnerCollectionFields.PROVIDER_LEARNER_ID,
  );
  const totalCreditConfig: IBCTBoardLearnerCollectionField = getFieldConfig(BoardLearnerCollectionFields.CREDIT_TYPE);
  const individualCreditConfig: IBCTBoardLearnerCollectionField = getFieldConfig(
    BoardLearnerCollectionFields.CREDITS_EARNED_PER_CREDIT_TYPE,
  );
  const practiceStateConfig: IBCTBoardLearnerCollectionField = getFieldConfig(
    BoardLearnerCollectionFields.PRACTICE_STATE,
  );
  const professionConfig: IBCTBoardLearnerCollectionField = getFieldConfig(BoardLearnerCollectionFields.PROFESSION);
  const timeInPracticeConfig: IBCTBoardLearnerCollectionField = getFieldConfig(
    BoardLearnerCollectionFields.TIME_IN_PRACTICE,
  );
  const practiceAreaConfig: IBCTBoardLearnerCollectionField = getFieldConfig(
    BoardLearnerCollectionFields.PRACTICE_AREA,
  );
  const deaRegistrationConfig: IBCTBoardLearnerCollectionField = getFieldConfig(
    BoardLearnerCollectionFields.DEA_REGISTRATION,
  );
  const surgicalProceduresConfig: IBCTBoardLearnerCollectionField = getFieldConfig(
    BoardLearnerCollectionFields.SURGICAL_PROCEDURES,
  );
  const prescribedConfig: IBCTBoardLearnerCollectionField = getFieldConfig(
    BoardLearnerCollectionFields.PRESCRIBED_IN_PAST_12_MONTHS,
  );

  // Setup requiredness constants.
  const isBoardIdRequired: boolean = boardIdConfig?.collectionType === CollectionTypes.REQUIRED;
  const isDeaRegistrationRequired: boolean = deaRegistrationConfig?.collectionType === CollectionTypes.REQUIRED;
  const isPracticeAreaRequired: boolean = practiceAreaConfig?.collectionType === CollectionTypes.REQUIRED;
  const isPracticeStateRequired: boolean = practiceStateConfig?.collectionType === CollectionTypes.REQUIRED;
  const isProfessionRequired: boolean = professionConfig?.collectionType === CollectionTypes.REQUIRED;
  const isProviderLearnerRequired: boolean = learnerIdConfig?.collectionType === CollectionTypes.REQUIRED;
  const isTimeInPracticeRequired: boolean = professionConfig?.collectionType === CollectionTypes.REQUIRED;

  // Hooks
  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    resetForm,
    setFieldValue,
    values,
    ...formProps
  } = useFormikContext<IAddLearnersFormModel>();
  const dispatch = useDispatch();
  const [field] = useField<ICertifyingBoardFormModel>(baseCertBoardFieldName);
  const { value } = field;

  // Selectors.
  const matchedLearners: IMatchedLearner[] = useSelector(matchedLearnersSelector);
  const previousMatchQuery: ILearnerMatchingModel = useSelector(previousMatchQuerySelector);
  const isMatchingInProgress: boolean = useSelector(isLearnerMatchingSelector);
  const statesAndProvinces: IDropdownType[] = useSelector(statesAndProvincesSelector)?.map(
    ({ isoStateCode, stateName }: IStateAndProvince): IDropdownType => ({
      Id: isoStateCode,
      Title: stateName,
    }),
  );

  const matchedBoards: ILearnerIdMatchField[] =
    matchedLearners?.length === 1 ? matchedLearners[0]?.learnerMocBoards : [];

  // Get the selected board id from the board dropdown
  const selectedCertifyingBoardId = value?.certifyingBoard || '';
  const isRemsBoard = config?.type === BoardTypes.REMS;

  // Confirm if there is a learner match for this board
  const hasLearnerMatch = !!matchedBoards?.find(({ boardId, learnerId }: ILearnerIdMatchField) => {
    return isBoardIdRequired
      ? selectedCertifyingBoardId === boardId && !!learnerId
      : selectedCertifyingBoardId === boardId;
  });

  // Reset matching attempts on successful match
  useEffect(() => {
    if (hasLearnerMatch && matchAttempts > 0) {
      setMatchAttempts(0);
    }
  }, [hasLearnerMatch, matchAttempts]);

  // Use this callback to keep the form validity in sync. The parent needs to know if we have a learner matched.
  useEffect(() => {
    callback(hasLearnerMatch);
  }, [callback, hasLearnerMatch, values.certifyingBoards]);

  // Derived field names for this certifying board entry
  const boardIdFieldName = `${baseCertBoardFieldName}.boardId`;
  const certifyingBoardFieldName = `${baseCertBoardFieldName}.certifyingBoard`;
  const deaRegistrationFieldName = `${baseCertBoardFieldName}.deaRegistration`;
  const learnerCreditsFieldName = `${baseCertBoardFieldName}.learnerCredits`;
  const practiceAreaFieldName = `${baseCertBoardFieldName}.practiceArea`;
  const practiceStateFieldName = `${baseCertBoardFieldName}.practiceState`;
  const professionFieldName = `${baseCertBoardFieldName}.profession`;
  const providerLearnerIdFieldName = `${baseCertBoardFieldName}.providerLearnerId`;
  const surgicalProceduresFieldName = `${baseCertBoardFieldName}.surgicalProcedures`;
  const recentlyPrescribedFieldName = `${baseCertBoardFieldName}.prescribedInPast12Months`;
  const timeInPracticeFieldName = `${baseCertBoardFieldName}.timeInPractice`;
  const totalCreditsFieldName = `${baseCertBoardFieldName}.totalCredits`;

  // Get the board abbr. for rendering the credit types label
  const selectedBoardAbbreviation = config?.abbreviation || '';

  // Use the selected board id to lookup options for credit types and practice areas
  const selectedCreditTypes: IFormInputOption[] = boardCreditOptions?.[selectedCertifyingBoardId] || [];
  const selectedDeaRegistrations: IFormInputOption[] = deaRegistrationOptions?.filter(
    ({ Id }: IFormInputOption): boolean => deaRegistrationConfig?.taxonomyTermIds?.includes(Id),
  );
  const selectedPracticeAreas: IFormInputOption[] = practiceAreaOptions?.filter(({ Id }: IFormInputOption): boolean =>
    practiceAreaConfig?.taxonomyTermIds?.includes(Id),
  );
  const selectedProfessions: IFormInputOption[] = professionOptions?.filter(({ Id }: IFormInputOption): boolean =>
    professionConfig?.taxonomyTermIds?.includes(Id),
  );
  const selectedTimesInPractice: IFormInputOption[] = timeInPracticeOptions?.filter(
    ({ Id }: IFormInputOption): boolean => timeInPracticeConfig?.taxonomyTermIds?.includes(Id),
  );
  // Force the next form state to include all previous state
  // Except THIS board's form model is reset to only include the current id and the new certifyingBoard id
  // Important because changing a board can completely change some of the options, possibly invalidating previous selections
  const handleBoardChange = (e: ChangeEvent<HTMLInputElement>): void => {
    // Extract all certifyingBoards in the current model
    const tempCertifyingBoards: ICertifyingBoardFormModel[] = [...values?.certifyingBoards];
    const boardSelection: string = e.target.value;

    // Reset data for THIS board, replacing certifyingBoard id with the selected id, and retaining the current id
    tempCertifyingBoards[index] = {
      ...initialCertifyingBoardValues,
      certifyingBoard: boardSelection,
      id: values?.certifyingBoards[index].id || uniqueId('board_'),
    };

    // Check if we need to autofill any fields
    const autofillBoardMatch: ILearnerIdMatchField = learnerBoards?.find(({ boardId }) => boardId === boardSelection);
    if (autofillBoardMatch) {
      // Apply the matched learner's id to the form model
      tempCertifyingBoards[index].boardId = autofillBoardMatch.learnerId;
    }

    // Deep copy values to protect from mutations. This isn't expensive because the model is relatively small
    const tempValues = JSON.parse(JSON.stringify(values));

    // Replace the certifyingBoards with our new boards array
    tempValues.certifyingBoards = tempCertifyingBoards;

    // "Reset" the form state (retaining current form metadata)
    resetForm({ errors, touched, values: tempValues, ...formProps });
  };

  const handleLearnerBoardMatching = (learnerFormValues: IAddLearnersFormModel): void => {
    // Ignore REMS for triggering learner matching
    if (config?.type === BoardTypes.REMS) return;

    if (selectedCertifyingBoardId) {
      const matchingPayload: ILearnerMatchingModel = getMatchPayloadFromFormValues(learnerFormValues, {
        [selectedCertifyingBoardId]: config,
      });

      // If matching is not in transit and the match query hasn't changed, trigger a match request
      if (!isMatchingInProgress && !isEqual(previousMatchQuery, matchingPayload)) {
        dispatch(matchLearners(matchingPayload));
        setMatchAttempts(matchAttempts + 1);
      }
    }
  };

  const onBlurLearnerField = (event: FocusEvent<HTMLInputElement>): void => {
    handleLearnerBoardMatching(values);
    handleBlur(event);
  };

  const isBoardDropdownDisabled = !!selectedCertifyingBoardId;

  // since the checkboxes default to undefined, we need to set them to false if we show them to the user
  const { surgicalProcedures, prescribedInPast12Months } = values.certifyingBoards[index];
  useEffect(() => {
    if (
      surgicalProcedures === undefined &&
      surgicalProceduresConfig?.collectionType !== CollectionTypes.NOT_COLLECTED
    ) {
      setFieldValue(`certifyingBoards[${index}].surgicalProcedures`, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, surgicalProcedures, surgicalProceduresConfig?.collectionType]);
  useEffect(() => {
    if (prescribedInPast12Months === undefined && prescribedConfig?.collectionType !== CollectionTypes.NOT_COLLECTED) {
      setFieldValue(`certifyingBoards[${index}].prescribedInPast12Months`, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, prescribedInPast12Months, prescribedConfig?.collectionType]);

  return (
    <>
      <Grid container>
        <InputBlock errors={errors} touched={touched} name={certifyingBoardFieldName}>
          <Grid container item xs={12} className={styles['form-grid']}>
            {isBoardDropdownDisabled ? (
              <div className={styles['certifying-board-group']}>
                <h5>{boardName}</h5>
                <Button className="remove" title="Remove" type="button" onClick={() => handleRemoveBoard(index)}>
                  <CloseRounded />
                </Button>
              </div>
            ) : (
              <>
                {hasBoards && (
                  <Grid item xs={12} sm={6}>
                    <div className={classNames(styles['certifying-board-group'], styles.input)}>
                      <InputBlock
                        errors={errors}
                        name={certifyingBoardFieldName}
                        suppressLabel
                        title="Certifying board"
                        variant="form-sub-group"
                      >
                        <Dropdown
                          name={certifyingBoardFieldName}
                          items={mocBoardList}
                          onBlur={onBlurLearnerField}
                          onChange={handleBoardChange}
                          labelProp="Title"
                          idProp="Id"
                          placeholder="Select a Board or Collaboration"
                        />
                      </InputBlock>
                      <Button className="remove" title="Remove" type="button" onClick={() => handleRemoveBoard(index)}>
                        <CloseRounded />
                      </Button>
                    </div>
                  </Grid>
                )}
                {hasCollabs && (
                  <Grid item xs={12} sm={6}>
                    <div className={classNames(styles['certifying-board-group'], styles.input)}>
                      <InputBlock
                        errors={errors}
                        name={certifyingBoardFieldName}
                        suppressLabel
                        title="Collaboration"
                        variant="form-sub-group"
                      >
                        <Dropdown
                          isDisabled={isBoardDropdownDisabled}
                          name={certifyingBoardFieldName}
                          items={remsList}
                          onBlur={onBlurLearnerField}
                          onChange={handleBoardChange}
                          labelProp="Title"
                          idProp="Id"
                          placeholder="Select a Board or Collaboration"
                        />
                      </InputBlock>
                      <Button className="remove" title="Remove" type="button" onClick={() => handleRemoveBoard(index)}>
                        <CloseRounded />
                      </Button>
                    </div>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </InputBlock>
      </Grid>
      {selectedCertifyingBoardId ? (
        <>
          <div className="form-group form-group-inline form-sub-group">
            <Grid container className={styles['form-grid']} spacing={2}>
              {boardIdConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
                <Grid item xs={12} sm={6} className={styles.input}>
                  <InputBlock
                    errors={errors}
                    name={boardIdFieldName}
                    required={isBoardIdRequired}
                    suppressLabel
                    title="Board ID"
                    touched={touched}
                    variant="form-sub-group"
                  >
                    <FormikTextField
                      formikKey={boardIdFieldName}
                      helperText=""
                      onBlur={onBlurLearnerField}
                      placeholder="Board ID"
                      required={isBoardIdRequired}
                      variant="outlined"
                    />
                  </InputBlock>
                </Grid>
              )}
              {learnerIdConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
                <Grid item xs={12} sm={6} className={styles.input}>
                  <InputBlock
                    errors={errors}
                    name={providerLearnerIdFieldName}
                    required={isProviderLearnerRequired}
                    suppressLabel
                    title="Provider Learner ID"
                    touched={touched}
                    variant="form-sub-group"
                  >
                    <FormikTextField
                      formikKey={providerLearnerIdFieldName}
                      helperText=""
                      placeholder="Provider Learner ID"
                      required={isProviderLearnerRequired}
                      variant="outlined"
                    />
                  </InputBlock>
                </Grid>
              )}
              {totalCreditConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
                <Grid item xs={12} sm={6} className={styles.input}>
                  <InputBlock
                    errors={errors}
                    name={totalCreditsFieldName}
                    touched={touched}
                    title="Total credits"
                    variant="form-sub-group"
                    suppressLabel
                  >
                    <FormikNumberField
                      formikKey={totalCreditsFieldName}
                      helperText=""
                      variant="outlined"
                      placeholder="0.00"
                      required
                    />
                  </InputBlock>
                </Grid>
              )}
              {practiceStateConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
                <Grid item xs={12} sm={6} className={styles.input}>
                  <InputBlock
                    errors={errors}
                    name={practiceStateFieldName}
                    required={isPracticeStateRequired}
                    suppressLabel
                    title="Practice state"
                    touched={touched}
                    variant="form-sub-group"
                  >
                    <Dropdown
                      idProp="Id"
                      items={statesAndProvinces}
                      labelProp="Title"
                      name={practiceStateFieldName}
                      onChange={handleChange}
                      placeholder="Select a state or province"
                      required={isPracticeStateRequired}
                    />
                  </InputBlock>
                </Grid>
              )}
              {professionConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
                <Grid item xs={12} sm={6} className={styles.input}>
                  <InputBlock
                    errors={errors}
                    name={professionFieldName}
                    required={isProfessionRequired}
                    suppressLabel
                    title="Profession"
                    touched={touched}
                    variant="form-sub-group"
                  >
                    <Dropdown
                      idProp="Id"
                      items={selectedProfessions}
                      labelProp="Title"
                      name={professionFieldName}
                      onChange={handleChange}
                      placeholder="Select a profession"
                      required={isProfessionRequired}
                    />
                  </InputBlock>
                </Grid>
              )}
              {timeInPracticeConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
                <Grid item xs={12} sm={6} className={styles.input}>
                  <InputBlock
                    errors={errors}
                    name={timeInPracticeFieldName}
                    required={isTimeInPracticeRequired}
                    suppressLabel
                    title="Time in practice"
                    touched={touched}
                    variant="form-sub-group"
                  >
                    <Dropdown
                      idProp="Id"
                      items={selectedTimesInPractice}
                      labelProp="Title"
                      name={timeInPracticeFieldName}
                      onChange={handleChange}
                      placeholder="Select a range"
                      required={isTimeInPracticeRequired}
                    />
                  </InputBlock>
                </Grid>
              )}
              {practiceAreaConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
                <Grid item xs={12} sm={6} className={styles.input}>
                  <InputBlock
                    errors={errors}
                    name={practiceAreaFieldName}
                    required={isPracticeAreaRequired}
                    suppressLabel
                    title="Practice area"
                    touched={touched}
                    variant="form-sub-group"
                  >
                    <Dropdown
                      idProp="Id"
                      items={selectedPracticeAreas}
                      labelProp="Title"
                      name={practiceAreaFieldName}
                      onChange={handleChange}
                      placeholder="Select a practice area"
                      required={isPracticeAreaRequired}
                    />
                  </InputBlock>
                </Grid>
              )}
              {deaRegistrationConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
                <Grid item xs={12} sm={6} className={styles.input}>
                  <InputBlock
                    errors={errors}
                    name={deaRegistrationFieldName}
                    required={isDeaRegistrationRequired}
                    suppressLabel
                    title="DEA registration"
                    touched={touched}
                    variant="form-sub-group"
                  >
                    <Dropdown
                      idProp="Id"
                      items={selectedDeaRegistrations}
                      labelProp="Title"
                      name={deaRegistrationFieldName}
                      onChange={handleChange}
                      placeholder="Select a DEA Registration Type"
                      required={isDeaRegistrationRequired}
                    />
                  </InputBlock>
                </Grid>
              )}
              {surgicalProceduresConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
                <Grid item xs={12}>
                  <div className="form-group form-sub-group">
                    <InputBlock errors={errors} touched={touched} name={surgicalProceduresFieldName}>
                      <FormikCheckboxField formikKey={surgicalProceduresFieldName} title="Surgical procedures" />
                    </InputBlock>
                  </div>
                </Grid>
              )}
              {prescribedConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
                <Grid item xs={12}>
                  <div className="form-group form-sub-group">
                    <InputBlock errors={errors} touched={touched} name={recentlyPrescribedFieldName}>
                      <FormikCheckboxField
                        formikKey={recentlyPrescribedFieldName}
                        title="Prescribed in past 12 months?"
                      />
                    </InputBlock>
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
          <div className="form-group form-sub-group">
            {individualCreditConfig.collectionType !== CollectionTypes.NOT_COLLECTED && (
              <>
                {selectedBoardAbbreviation ? (
                  <div className="form-sub-group">
                    <h5 className="form-input-label">
                      {`${selectedBoardAbbreviation} credit types`}
                      <span className="isRequired"> *</span>
                    </h5>
                  </div>
                ) : null}
                <InputBlock errors={errors} touched={touched} name={learnerCreditsFieldName}>
                  <Grid container>
                    {selectedCreditTypes.length ? (
                      selectedCreditTypes.map(
                        (option: IFormInputOption, index: number): JSX.Element => {
                          if (option?.Id) {
                            return (
                              <Grid item xs={12} sm={6} key={option?.Id} className={styles.input}>
                                <InputBlock
                                  errors={errors}
                                  name={`certifyingBoards.${index}.learnerCredits.credits`}
                                  required
                                  touched={touched}
                                >
                                  <FormikCheckboxConditionalNumberInput
                                    checkboxFormikKey={`${learnerCreditsFieldName}.learnerCredits${option.Id}`}
                                    defaultValue={value.totalCredits}
                                    disabled={option?.isDisabled}
                                    forceUpdateDefaultValue
                                    formikKey={learnerCreditsFieldName}
                                    isRequired={false}
                                    numberInputLabel={`of ${value.totalCredits || '0'}`}
                                    option={option}
                                  />
                                </InputBlock>
                              </Grid>
                            );
                          } else {
                            return null;
                          }
                        },
                      )
                    ) : (
                      <p>No credit types available for {selectedBoardAbbreviation} for this completion date.</p>
                    )}
                  </Grid>
                </InputBlock>
              </>
            )}
            <div>
              {hasLearnerMatch && !isRemsBoard && (
                <div className={styles['pill-box']}>
                  <span
                    className={classNames(styles['match-pill'], { [styles['match-pill--success']]: hasLearnerMatch })}
                  >
                    Learner Matched
                  </span>
                </div>
              )}
              {!hasLearnerMatch && !isRemsBoard && (
                <div className={styles['pill-box']}>
                  <span
                    className={classNames(styles['match-pill'], { [styles['match-pill--fail']]: !hasLearnerMatch })}
                  >
                    Learner Not Matched
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
