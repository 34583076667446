import { BaseApiService, IJsonPatchDocumentOperation } from './baseApiService';
import { ContentTag } from './models';

export class ContentTagsApiService extends BaseApiService {
  public async getAllContentTagAsync(): Promise<Array<ContentTag>> {
    return await super.getAsync('/api/ActivitiyContentTag/contenttags');
  }

  public async patchContentTagAsync(id: string, patchRequest: IJsonPatchDocumentOperation[]): Promise<ContentTag> {
    return await super.patchAsync(`/api/ActivitiyContentTag/contenttags/${id}`, patchRequest);
  }

  public async putContentTagAsync(id: string, value: Partial<ContentTag>): Promise<ContentTag> {
    return super.putAsync(`api/ActivitiyContentTag/contenttags/${id}`, value);
  }
}
