import { InputLabel } from '@material-ui/core';
import { Field, FormikProvider, useFormik } from 'formik';
import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';

// Components
import Button from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';
import InputBlock from 'components/global/inputBlock/inputBlock';

// Styles.
import styles from '../AdminPopupStyle/AdminPopupStyle.module.scss';

// Core + Store
import { ButtonVariant } from 'core/enums';
import { processBoardCredit } from 'store/learner/actions';

// Validations.
import { validationSchema } from './validationSchema';

interface IQueueMocActivityFormProps {
  boardId: string;
  lccbId: string;
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
}

export const ProcessBoardCreditForm = (props: IQueueMocActivityFormProps): ReactElement => {
  const { boardId, lccbId, onClose, onSuccess } = props;

  const statusOptions = [
    { id: 'Rejected', name: 'Rejected' },
    { id: 'Accepted', name: 'Accepted' },
  ];
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      boardId: boardId,
      lccbId: lccbId,
      status: '',
      errorCode: '',
      rejectedReason: '',
    },
    onSubmit: async (values): Promise<void> => {
      const result = await dispatch(processBoardCredit({ ...values }));
      if (result) {
        onClose();
        onSuccess?.();
      }
    },
    validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <fieldset className={styles.fieldset}>
          <section className={styles.section}>
            <div>
              <InputLabel>Status</InputLabel>
              <InputBlock name="status">
                <Dropdown className={styles.input} fullWidth items={statusOptions} name="status" required />
              </InputBlock>
            </div>
            {formik.values?.status === 'Rejected' ? (
              <>
                <div>
                  <InputLabel>Error Code</InputLabel>
                  <InputBlock name="errorCode">
                    <Field id="errorCode" name="errorCode" type="number" />
                  </InputBlock>
                </div>
                <div>
                  <InputLabel>Rejected Reason</InputLabel>
                  <InputBlock name="rejectedReason">
                    <Field id="rejectedReason" name="rejectedReason" type="text" />
                  </InputBlock>
                </div>
              </>
            ) : null}
            <footer className={styles.footer}>
              <Button className={styles.button} onClick={onClose} variant={ButtonVariant.Secondary}>
                Cancel
              </Button>
              <Button
                className={styles.button}
                disabled={formik.isSubmitting}
                isSubmitting={formik.isSubmitting}
                type="submit"
                variant={ButtonVariant.Primary}
              >
                Save
              </Button>
            </footer>
          </section>
        </fieldset>
      </form>
    </FormikProvider>
  );
};
