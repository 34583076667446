import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classNames from 'classnames';

// Components
import { LoadingCards } from 'components/LoadingCard';

// Styles
import formStyles from '../FormStyles.module.scss';
import successFormStyles from './SuccessFormStyles.module.scss';

// Store + Core
import { currentBoardSelector, isLoadingBCTSelector } from 'store/bct/selectors';
import { getBoardById } from 'store/bct/actions';

// Types
import { IBCTBoard } from '../../types';

export const SuccessForm = (): ReactElement => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  // Selectors
  const currentBoard: IBCTBoard = useSelector(currentBoardSelector);
  const isLoading: boolean = useSelector(isLoadingBCTSelector);

  // Fetch.
  useEffect(() => {
    dispatch(getBoardById(id));
  }, [dispatch, id]);

  // Show this while data is loading.
  if (isLoading) return <LoadingCards />;

  return (
    <Box component="section">
      <div className={classNames(formStyles['form-card'], formStyles['success-page'])}>
        <h1 className={successFormStyles.title}>Board successfully updated</h1>
        <Box mt={4} mb={4}>
          <Grid container alignItems="center" spacing={4}>
            <Box component={Grid}>
              <CheckCircleIcon />
            </Box>
            <Grid item xs={11}>
              <Box display="flex" flexDirection="column">
                <Box component="div">
                  <strong>
                    {currentBoard.name} ({currentBoard.abbreviation})
                  </strong>
                </Box>
                <Box component="div">
                  ID: {currentBoard.id} | Integration Type {currentBoard.boardIntegrationType}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
      <Box display="flex" flexDirection="row-reverse">
        <Button
          endIcon={<ArrowForwardRoundedIcon />}
          to="/"
          className="tertiary"
          title="go to dashboard"
          component={Link}
        >
          Go to Dashboard
        </Button>
      </Box>
    </Box>
  );
};
