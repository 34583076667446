import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components.
import {
  DashboardBanners,
  DashboardDataWidget,
  DashboardGreeting,
  DashboardMessageWidget,
  DashboardNotifications,
} from 'components/Dashboard';

// Hooks.
import { useLoadEvent } from 'hooks';

// Store.
import { getDashboard, getDashboardBanners, getDashboardMessage } from 'store/dashboard/actions';
import { dashboardSelector } from 'store/dashboard/selectors';
import { currentContextualOrg } from 'store/user/selectors';

// Styles.
import styles from './DashboardPage.module.scss';

export const DashboardPage = (): ReactElement => {
  const dispatch = useDispatch();

  // Selectors.
  const { banners, data, isLoading, isLoadingBanners, isLoadingMessage, message } = useSelector(dashboardSelector);
  const currentOrganization = useSelector(currentContextualOrg);

  const { asOf, blocks, notifications } = data || {};
  const isLoaded = useMemo<boolean>(() => !!banners && !!data && !isLoading && !isLoadingBanners, [
    banners,
    data,
    isLoading,
    isLoadingBanners,
  ]);

  const fireLoadEvent = useLoadEvent({ PageName: 'Dashboard - Home', PageType: 'Dashboard' });

  useEffect(() => {
    if (currentOrganization?.id) {
      dispatch(getDashboard());
      dispatch(getDashboardBanners(currentOrganization.id));
      dispatch(getDashboardMessage());
    }
  }, [currentOrganization?.id]);

  useEffect(() => {
    if (isLoaded) {
      fireLoadEvent({ Notifications: notifications?.length > 0 });
    }
  }, [fireLoadEvent, isLoaded, notifications]);

  if (isLoading || isLoadingBanners || isLoadingMessage) {
    return (
      <div className={styles.loader}>
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <section className={styles.container}>
      <DashboardBanners banners={banners} className={styles.banners} />
      <header className={styles.header}>
        <DashboardGreeting className={styles.greeting} />
        <DashboardNotifications className={styles.notifications} notifications={notifications} />
      </header>
      <section className={styles.content} data-block-count={blocks?.length || 0}>
        {blocks?.map((block, index) => (
          <DashboardDataWidget
            className={classNames(styles.block, { [styles.expand]: !message })}
            data={block}
            key={index}
          />
        ))}
        {message && <DashboardMessageWidget className={styles.message} data={message} />}
      </section>
      {asOf && (
        <footer className={styles.footer}>
          <p>
            Dashboard data as of {moment(asOf).format('MM/DD/YYYY h:mma ')}
            {moment().tz()}
          </p>
        </footer>
      )}
    </section>
  );
};
