export class ProviderService {
  private static _currentOrganizationId: string | null = '';
  private static _currentProviderId: string | null = '';

  public get currentOrganizationId(): string | null {
    return ProviderService._currentOrganizationId;
  }

  public set currentOrganizationId(value: string | null) {
    ProviderService._currentOrganizationId = value;
  }

  public get currentProviderId(): string | null {
    return ProviderService._currentProviderId;
  }

  public set currentProviderId(value: string | null) {
    ProviderService._currentProviderId = value;
  }
}
