import * as yup from 'yup';

export const validationSchema = yup.object({
  boardAbbreviation: yup.string().required('Board is required.'),
  action: yup.string().required('Action is required.'),
  transferStatusCode: yup.string().required('Transfer Status Code is required.'),
  errorCode: yup.number().when('transferStatusCode', {
    is: 'R',
    then: (s) => s.required('Error Code is required when rejecting.'),
  }),
  rejectedReason: yup.string().when('transferStatusCode', {
    is: 'R',
    then: (s) => s.required('Rejected Reason is required when rejecting.'),
  }),
});
