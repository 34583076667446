import {
  BCTSteps,
  CreditTypesErrors,
  IBoardSlim,
  IErrorAndValidationExceptions,
  IValidationException,
  PARSAction,
} from 'core/models';
import { IBCTBoard } from '../../layouts/pages/bct/types';

// Constants
export const POST_BCT_STEP = 'POST_BCT_STEP';

export const POST_BCT_STEP_SUCCESS = 'POST_BCT_STEP_SUCCESS';
export const POST_BCT_STEP_FAILURE = 'POST_BCT_STEP_FAILURE';
export const SET_CURRENT_BCT_STEP = 'SET_CURRENT_BCT_STEP';

export const GET_BOARD_LIST = 'GET_BOARD_LIST';
export const GET_BOARD_LIST_SUCCESS = 'GET_BOARD_LIST_SUCCESS';
export const GET_BOARD_LIST_FAILURE = 'GET_BOARD_LIST_FAILURE';

export const GET_BOARD = 'GET_BOARD';
export const GET_BOARD_SUCCESS = 'GET_BOARD_SUCCESS';
export const GET_BOARD_FAILURE = 'GET_BOARD_FAILURE';

export const POST_BOARD_CONTENT_OUTLINES = 'POST_BOARD_CONTENT_OUTLINES';
export const POST_BOARD_CONTENT_OUTLINES_SUCCESS = 'POST_BOARD_CONTENT_OUTLINES_SUCCESS';
export const POST_BOARD_CONTENT_OUTLINES_FAILURE = 'POST_BOARD_CONTENT_OUTLINES_FAILURE';

export const RESET_BCT = 'RESET_BCT';

export interface BCTState {
  boardList: IBoardSlim[];
  creditTypesErrors: CreditTypesErrors;
  currentBoard: IBCTBoard;
  currentStep: BCTSteps;
  error: Error;
  isFileProcessing: boolean;
  isLoading: boolean;
  validationExceptions: IValidationException[];
}

export interface IPostBCTStep {
  nextStep: BCTSteps;
  values: Partial<IBCTBoard>;
}

interface PostBCTStep extends PARSAction<undefined, typeof POST_BCT_STEP> {
  type: typeof POST_BCT_STEP;
}

interface PostBCTStepSuccess extends PARSAction<undefined, typeof POST_BCT_STEP_SUCCESS> {
  type: typeof POST_BCT_STEP_SUCCESS;
}

interface PostBCTStepFailure extends PARSAction<IErrorAndValidationExceptions, typeof POST_BCT_STEP_FAILURE> {
  payload: IErrorAndValidationExceptions;
  type: typeof POST_BCT_STEP_FAILURE;
}

interface SetCurrentBCTStep extends PARSAction<BCTSteps, typeof SET_CURRENT_BCT_STEP> {
  payload: BCTSteps;
  type: typeof SET_CURRENT_BCT_STEP;
}

interface GetBoardList extends PARSAction<void, typeof GET_BOARD_LIST> {
  type: typeof GET_BOARD_LIST;
}
interface GetBoardListSuccess extends PARSAction<IBoardSlim[], typeof GET_BOARD_LIST_SUCCESS> {
  payload: IBoardSlim[];
  type: typeof GET_BOARD_LIST_SUCCESS;
}
interface GetBoardListFailure extends PARSAction<Error, typeof GET_BOARD_LIST_FAILURE> {
  payload: Error;
  type: typeof GET_BOARD_LIST_FAILURE;
}

interface GetBoard extends PARSAction<void, typeof GET_BOARD> {
  type: typeof GET_BOARD;
}
interface GetBoardSuccess extends PARSAction<IBCTBoard, typeof GET_BOARD_SUCCESS> {
  payload: IBCTBoard;
  type: typeof GET_BOARD_SUCCESS;
}
interface GetBoardFailure extends PARSAction<Error, typeof GET_BOARD_FAILURE> {
  payload: Error;
  type: typeof GET_BOARD_FAILURE;
}

interface PostBoardContentOutlines extends PARSAction<void, typeof POST_BOARD_CONTENT_OUTLINES> {
  type: typeof POST_BOARD_CONTENT_OUTLINES;
}
interface PostBoardContentOutlinesSuccess extends PARSAction<void, typeof POST_BOARD_CONTENT_OUTLINES_SUCCESS> {
  type: typeof POST_BOARD_CONTENT_OUTLINES_SUCCESS;
}
interface PostBoardContentOutlinesFailure
  extends PARSAction<{ error; validationExceptions }, typeof POST_BOARD_CONTENT_OUTLINES_FAILURE> {
  payload: IErrorAndValidationExceptions;
  type: typeof POST_BOARD_CONTENT_OUTLINES_FAILURE;
}

interface ResetBCT extends PARSAction<void, typeof RESET_BCT> {
  type: typeof RESET_BCT;
}

export type BCTTypes =
  | GetBoard
  | GetBoardFailure
  | GetBoardList
  | GetBoardListFailure
  | GetBoardListSuccess
  | GetBoardSuccess
  | PostBCTStep
  | PostBCTStepFailure
  | PostBCTStepSuccess
  | PostBoardContentOutlines
  | PostBoardContentOutlinesSuccess
  | PostBoardContentOutlinesFailure
  | SetCurrentBCTStep
  | ResetBCT;
