// Libs
import { ReactElement } from 'react';
import { isNumber, uniqueId } from 'lodash';
import classNames from 'classnames';
import { Cancel, CheckCircle } from '@material-ui/icons';

// Core
import { AddActivityFormValues, IDictionary } from 'core/models';
import { IBCTBoard, IBCTCreditType } from 'layouts/pages/bct/types';

// Styles
import styles from '../CompletedActivityCard.module.scss';

interface IProps {
  boardKey: string;
  configurationDictionary: IDictionary<IBCTBoard>;
  formValues: AddActivityFormValues;
}
export const CompletedMoc = ({ boardKey, configurationDictionary, formValues }: IProps): ReactElement => {
  const board = formValues.boardMocDetails?.[boardKey];
  const hasClaimDate = !!formValues.mocCreditDeadline;
  const isMocAttested = !!formValues.mocProgramAttestation;
  if (!board || !configurationDictionary?.[boardKey]) return null;
  const { contentOutlines, mocPointsGiven, practiceIds, typesOfCreditIds } = board;
  const creditTypes: string[] = typesOfCreditIds?.map(
    (creditId: string) =>
      configurationDictionary?.[boardKey]?.creditTypes?.find(({ id }: IBCTCreditType): boolean => id === creditId)
        ?.organizationName,
  );
  const hasContentOutlines = configurationDictionary?.[boardKey]?.usesContentOutlines
    ? contentOutlines?.length > 0
    : true;
  // On the form, mocPointsGiven may be shared as a string
  const mocPointsParsed = parseFloat(`${mocPointsGiven}`);
  const hasMinimumPracticeAreas = practiceIds?.length > 0;
  const hasMinimumCreditTypes = typesOfCreditIds?.length > 0;
  const hasMocPointsProvided = isNumber(mocPointsParsed) && mocPointsParsed > 0;

  const hasMocFieldsForActive =
    hasContentOutlines &&
    hasMinimumPracticeAreas &&
    hasMinimumCreditTypes &&
    hasMocPointsProvided &&
    hasClaimDate &&
    isMocAttested;
  return (
    <div
      className={classNames('board', {
        [styles['board-incomplete']]: !hasMocFieldsForActive,
      })}
    >
      <div className="board-name">
        {board && (
          <>
            {hasMocFieldsForActive ? <CheckCircle /> : <Cancel />}
            <span>{`MOC for ${configurationDictionary?.[boardKey]?.name} (${
              configurationDictionary?.[boardKey]?.abbreviation
            }) ${!hasMocFieldsForActive ? '[Missing Required Information]' : ''}`}</span>
          </>
        )}
      </div>
      <ul className={styles['board-credit-list']}>
        {creditTypes?.map(
          (creditType: string): ReactElement => (
            <li key={uniqueId()} className="board-type">
              {creditType}
            </li>
          ),
        )}
      </ul>
    </div>
  );
};
