import { ReactElement, ReactNode, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { HelpRounded } from '@material-ui/icons';
import './tooltip.module.scss';

const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fff',
    borderRadius: 0,
    boxShadow: '1px 3px 8px 2px #00000019',
    color: '#373737',
    fontSize: '0.625rem',
    lineHeight: 1.3,
    margin: 8,
    maxWidth: 220,
    padding: 10,
    whiteSpace: 'initial',
  },
}))(Tooltip);

interface ITooltipProps {
  iconTooltip?: boolean;
  position?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
  tooltipText: string;
  children?: ReactNode;
}

export const CustomTooltip = (props: ITooltipProps): ReactElement => {
  const { iconTooltip, position, tooltipText, children } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <StyledTooltip
        disableTouchListener
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        placement={position || 'top'}
        PopperProps={{
          disablePortal: true,
        }}
        title={tooltipText}
      >
        {iconTooltip ? (
          <button className="toolTipIcon" type="button" onClick={() => setOpen(!open)}>
            <HelpRounded />
          </button>
        ) : (
          // tool tip wraps mock component
          <span onClick={() => setOpen(!open)}>{children}</span>
        )}
      </StyledTooltip>
    </>
  );
};

export default CustomTooltip;
