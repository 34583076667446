import { Reducer } from 'redux';
import {
  BoardActionTypes,
  BoardsState,
  CLEAR_BOARDS,
  GET_BOARD_BY_ID,
  GET_BOARD_BY_ID_SUCCESS,
  GET_BOARDS,
  GET_BOARDS_FAILURE,
  GET_BOARDS_SUCCESS,
} from './types';

const initialState: BoardsState = {
  boards: undefined,
  configuredBoards: undefined,
  specialtyBoards: [],
};

const taxonomyReducer: Reducer<BoardsState, BoardActionTypes> = (
  state = initialState,
  action: BoardActionTypes,
): BoardsState => {
  switch (action.type) {
    case GET_BOARDS:
      return {
        ...state,
      };
    case GET_BOARDS_SUCCESS:
      return {
        ...state,
        boards: [...action.payload],
      };
    case GET_BOARDS_FAILURE:
      return {
        ...state,
      };
    case GET_BOARD_BY_ID:
      return {
        ...state,
      };
    case GET_BOARD_BY_ID_SUCCESS:
      return {
        ...state,
        configuredBoards: !state.configuredBoards
          ? [action.payload]
          : [...state.configuredBoards?.filter(({ id }) => id !== action.payload?.id), action.payload],
      };
    case CLEAR_BOARDS:
      return {
        ...state,
        boards: initialState.boards,
      };
    default:
      return state;
  }
};

export default taxonomyReducer;
