import { ReactElement, useState, FocusEvent } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment-timezone';
import { FieldInputProps, useFormikContext } from 'formik';
import get from 'lodash/get';
import classNames from 'classnames';

// Core + Store
import { DEFAULT_DATE_FORMAT, DEFAULT_TIMEZONE } from 'core/constants';

// Types
enum TimesOfDay {
  Midnight = 'midnight',
  Noon = 'noon',
  EndOfDay = 'endOfDay',
}
type TimeOfDay = 'midnight' | 'noon' | 'endOfDay';

interface IProps {
  className?: string;
  disabled?: boolean;
  disablePast?: boolean;
  field: FieldInputProps<Date>;
  format?: string;
  formikKey: string;
  fullWidth?: boolean;
  label?: string;
  maxDate?: ParsableDate;
  minDate?: ParsableDate;
  onBlur?: (event: FocusEvent) => void;
  onChange?: (name: string, date: string | number) => void;
  required?: boolean;
  timeOfDay?: TimeOfDay;
  style?: unknown;
  views?: Array<'year' | 'date' | 'month'>;
}

const DatePicker = (props: IProps): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    className,
    disabled,
    disablePast = false,
    field,
    format,
    formikKey,
    fullWidth,
    label,
    maxDate,
    minDate,
    onBlur,
    onChange,
    required,
    style,
    timeOfDay,
    views,
    ...rest
  } = props;
  const { values, setFieldValue } = useFormikContext();
  const { name } = field;

  // Disables the currently selected date.
  const shouldDisableSelectedDate = (day: Moment): boolean => {
    const selectedDates: Date[] | Date = get(values, formikKey, []);

    // Dates can be a single date or multiple dates. Always return false when we only have 1 date.
    if (!Array.isArray(selectedDates)) return false;

    // Handle multiple dates.
    return !!(selectedDates as Date[])?.find((date: Date): boolean =>
      moment(moment(date).format(DEFAULT_DATE_FORMAT)).isSame(day.format(DEFAULT_DATE_FORMAT)),
    );
  };

  const handleChange = (date: Moment, rawValue: string): void => {
    let dateValue: string | number;
    let finalDate: Moment = date;

    // Some date pickers are month/day. We need to account for a user typing a leap-year date.
    // This code below will get skipped if a user enters a full date because the value + format won't match.
    if (rawValue === '02/29' && format === 'MM/DD') {
      finalDate = moment.tz('2020-02-29', DEFAULT_TIMEZONE);
    }

    if (views?.includes('year')) {
      dateValue = Number(finalDate?.year());
    } else {
      if (finalDate) {
        // We send/save ISO string to the server, adjusting the time of day as needed
        const dateToSave = moment(finalDate).tz(DEFAULT_TIMEZONE).startOf('day');
        switch (timeOfDay) {
          case TimesOfDay.Noon:
            dateToSave.set('hour', 12);
            break;
          case TimesOfDay.EndOfDay:
            dateToSave.set('hour', 23).set('minute', 59);
            break;
          case TimesOfDay.Midnight:
          default:
            dateToSave.startOf('day');
            break;
        }
        dateValue = dateToSave?.toISOString() || dateToSave?.toString();
      } else {
        dateValue = finalDate?.toString();
      }
    }
    setFieldValue(field?.name, dateValue, true);
    onChange?.(field?.name, dateValue);
  };

  // Convert the date from ISO UTC to `DEFAULT_TIMEZONE` on display.
  const displayValue = (date: Date | undefined): string | number => {
    let dateValue: string | number;

    if (views?.includes('year')) {
      dateValue = date?.toString();
    } else {
      dateValue = moment(date).tz(DEFAULT_TIMEZONE).toString();
    }

    return dateValue;
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        autoOk
        className={classNames('date-picker', className)}
        disabled={disabled}
        disablePast={disablePast}
        disableToolbar
        format={format || DEFAULT_DATE_FORMAT}
        fullWidth={fullWidth}
        InputAdornmentProps={{ position: 'start' }}
        inputVariant="outlined"
        label={label}
        maxDate={maxDate}
        minDate={minDate}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        onClose={(): void => setIsOpen(false)}
        onOpen={(): void => setIsOpen(true)}
        open={isOpen}
        readOnly={disabled}
        required={required}
        shouldDisableDate={shouldDisableSelectedDate}
        style={style}
        value={field?.value ? displayValue(field?.value) : null}
        views={views}
        variant="inline"
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
