import { AZURE_SEARCH_MAX } from 'core/constants';

interface IArgs {
  perPageCount: number;
  totalCount: number;
}

/**
 * @function getPaginationCount
 * @param perPageCount
 * @param totalCount
 * @return number
 * @description - Azure search is only limited capacity-wise by two factors (based on the SKU of the resource)
 *                1. total number of indexed items
 *                2. total size of all indexes in GB.
 *
 *                Our SKU has plenty of space, so that's not the concern here. Azure search's API only lets us page
 *                through up to 100,000 search results.
 */
export const getPaginationCount = ({ perPageCount, totalCount }: IArgs): number => {
  const determinedCount: number = totalCount > AZURE_SEARCH_MAX ? AZURE_SEARCH_MAX : totalCount;

  return perPageCount ? Math.ceil(determinedCount / perPageCount) : 1;
};
