// Libs
import { ReactElement } from 'react';
import { TextFieldProps } from '@material-ui/core';
import { useFormikContext } from 'formik';

// Components
import { FormikTextField } from 'components/forms';

// Core
import { IFormInputOption } from 'core/models';

interface IProps {
  formikKey: string;
  options: IFormInputOption[];
}

export const DictionaryTextField = ({ options, formikKey, ...rest }: IProps & TextFieldProps): ReactElement => {
  const { handleBlur } = useFormikContext();
  return options?.length ? (
    <>
      {options?.map(
        ({ Id, Title }) =>
          !!Id &&
          !!Title && (
            <div key={Id}>
              <h5>{Title}</h5>
              <FormikTextField formikKey={`${formikKey}.${Id}`} onBlur={handleBlur} {...rest} />
            </div>
          ),
      )}
    </>
  ) : (
    <p>No additional fields</p>
  );
};
