import { CircularProgress } from '@material-ui/core';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components.
import Button from 'components/Button/Button';
import { ActivityReviewEntry } from './ActivityReviewEntry';

// Core.
import { TAXONOMY_ACTIVITY_REVIEW_OUTCOMES_ID, TAXONOMY_ACTIVITY_REVIEW_TYPES_ID } from 'core/constants';
import { ButtonVariant } from 'core/enums';

// Store.
import { getActivityReviewHistory } from 'store/activity/actions';
import { activityReviewHistorySelector } from 'store/activity/selectors';
import { getTaxonomyTermById } from 'store/taxonomy/actions';

// Styles.
import styles from './ActivityReviewHistory.module.scss';

interface IActivityReviewHistoryProps {
  activityId: string;
  id: string;
  onClose?: VoidFunction;
  providerName: string;
  title: string;
}

export const ActivityReviewHistory = (props: IActivityReviewHistoryProps): ReactElement => {
  const { activityId, id, onClose, providerName, title } = props;
  const dispatch = useDispatch();
  const { isLoadingReviewHistory, reviewHistory } = useSelector(activityReviewHistorySelector);

  useEffect(() => {
    dispatch(getTaxonomyTermById(TAXONOMY_ACTIVITY_REVIEW_TYPES_ID));
    dispatch(getTaxonomyTermById(TAXONOMY_ACTIVITY_REVIEW_OUTCOMES_ID));
    dispatch(getActivityReviewHistory(id));
  }, [dispatch, id]);

  return (
    <div className={styles.history}>
      <header>
        <h2>{title}</h2>
        <p>Activity ID: {activityId}</p>
      </header>
      {isLoadingReviewHistory ? (
        <div className={styles['loading-wrapper']}>
          <CircularProgress />
        </div>
      ) : reviewHistory.length > 0 ? (
        reviewHistory.map((entry) => (
          <ActivityReviewEntry className={styles.section} key={entry.id} entry={entry} providerName={providerName} />
        ))
      ) : (
        <section className={styles.section}>
          <p>There is no activity review history</p>
        </section>
      )}
      <footer>
        <Button onClick={onClose} variant={ButtonVariant.Primary}>
          Done
        </Button>
      </footer>
    </div>
  );
};
