import { LearnerApiService } from 'core/learnerApiService';
import { batchLearnerProgressAction, batchLearnerValidationProgressAction } from 'store/learner/actions';
import {
  CreateLearnerRequest,
  IActivityLearnerSearchResponse,
  IActivitySearchRequest,
  ILearnerCompletion,
  IBulkLearnerHeader,
  IJsonPatchDocumentOperation,
  ILearnerMatchingModel,
  Learner,
  LearnerType,
  UpdateLearnerRequest,
  IArrayBufferDownload,
  ILearnerMatchResponse,
  IActivityLearnerSummarySearchResponse,
  ICompletionBoard,
  IBulkActivityLearnerCompletions,
  IQueueMocLearnerRequest,
  IProcessBoardCreditRequest,
  IAdminCommandResponse,
  IAdminDeleteLearnerChildCompletionRequest,
} from '../core/models';
import { ServerRoute } from '../core/api';
import { API } from 'core';

export class LearnerService {
  private static learnerApiService: LearnerApiService = new LearnerApiService();

  public static async create(payload: CreateLearnerRequest[]): Promise<Learner[]> {
    console.log('LearnerService.create: %o', payload);
    const responseData = await this.learnerApiService.createLearnerAsync(payload);
    console.log('LearnerService.create: %o', responseData);
    return responseData;
  }

  public static async bulkDeleteLearners({ activityId, payload }: IBulkActivityLearnerCompletions): Promise<void> {
    const route: ServerRoute = ServerRoute.forAction('activities', `${activityId}/learnercompletions/bulkdelete`);
    return await API.post<void>(route, payload);
  }

  public static async patch(id: string, payload: IJsonPatchDocumentOperation[]): Promise<void> {
    await this.learnerApiService.patchLearnerAsync(id, payload);
  }

  public static async update(payload: UpdateLearnerRequest): Promise<void> {
    await this.learnerApiService.updateLearnerAsync(payload);
  }

  public static async getLearnerSummarySearch(
    payload: IActivitySearchRequest,
  ): Promise<IActivityLearnerSummarySearchResponse> {
    const route: ServerRoute = ServerRoute.forAction('learners', 'activitylearnersummarysearch');
    return await API.post<IActivityLearnerSummarySearchResponse>(route, payload);
  }

  public static async getLearnerSearch(payload: IActivitySearchRequest): Promise<IActivityLearnerSearchResponse> {
    const route: ServerRoute = ServerRoute.forAction('learners', 'learnersearch');
    return await API.post<IActivityLearnerSearchResponse>(route, payload);
  }

  public static async batch(payload: { file: File }, shouldForce: boolean): Promise<IBulkLearnerHeader> {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    // If `shouldForce` is true, we don't listen for duplicate files (the user should not see a confirm modal)
    // and we will immediately start the upload process.
    const action: string = shouldForce ? 'batches/uploadforce' : 'batches/upload';
    const route: ServerRoute = ServerRoute.forAction('activities/learnercompletions', action);
    return await API.postFile<IBulkLearnerHeader>(route, payload, batchLearnerProgressAction, config);
  }

  public static async learnervalidationbatch(payload: { file: File }): Promise<IBulkLearnerHeader> {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const route: ServerRoute = ServerRoute.forAction('leaernervalidationtool', 'batches/upload');
    return await API.postFile<IBulkLearnerHeader>(route, payload, batchLearnerValidationProgressAction, config);
  }

  public static async getTypes(): Promise<LearnerType[]> {
    const route = API.ServerRoute.forAction('learner', 'types');
    return await API.get(route);
  }

  public static export(payload: IActivitySearchRequest): void {
    const route: ServerRoute = ServerRoute.forAction('learners', 'activitylearnersummaryexport');
    API.post<IActivitySearchRequest>(route, payload);
  }

  public static exportLearners(payload: IActivitySearchRequest): void {
    const route: ServerRoute = ServerRoute.forAction('learners', 'learnerexport');
    API.post<IActivitySearchRequest>(route, payload);
  }

  public static async getTemplate(id: string): Promise<any> {
    // TODO add type
    const route = API.ServerRoute.forAction('learner', `types/${id}`);
    return await API.get(route);
  }

  public static async queueMocLearner(request: IQueueMocLearnerRequest): Promise<IAdminCommandResponse> {
    const route: ServerRoute = ServerRoute.forAction('admincommands', 'sp_QueueMocLearner');
    return API.post(route, request);
  }

  public static async processBoardCredit(request: IProcessBoardCreditRequest): Promise<IAdminCommandResponse> {
    const route: ServerRoute = ServerRoute.forAction('admincommands', 'sp_ProcessBoardCreditByLccbId');
    return API.post(route, request);
  }

  public static async adminDeleteLearnerChildCompletion(
    request: IAdminDeleteLearnerChildCompletionRequest,
  ): Promise<IAdminCommandResponse> {
    const route: ServerRoute = ServerRoute.forAction('admincommands', 'sp_DeleteChildCompletion');
    return API.post(route, request);
  }

  public static async matchLearners(matchLearnersPayload: ILearnerMatchingModel): Promise<ILearnerMatchResponse[]> {
    const route: ServerRoute = ServerRoute.forAction('learners', 'match');
    return await API.post(route, matchLearnersPayload);
  }

  public static async createLearnerCompletion(
    payload: ILearnerCompletion,
    activityId: string,
  ): Promise<ILearnerCompletion> {
    const route: ServerRoute = ServerRoute.forAction('activities', `${activityId}/learnercompletions`);
    return await API.post(route, payload);
  }

  public static async getRecentLearnerCompletions(activityId: string): Promise<ILearnerCompletion[]> {
    const route: ServerRoute = ServerRoute.forAction('activities', `${activityId}/learnercompletions/recent`);
    return await API.get(route);
  }

  // Note: Since `route` is the fully qualified API, need to avoid letting API.get append to the URL
  public static async getLearnerCompletion(activityId: string, completionId: string): Promise<ICompletionBoard> {
    const route: ServerRoute = ServerRoute.forAction('activities', `${activityId}/learnercompletions/${completionId}`);
    return await API.get(route);
  }

  public static async patchLearnerCompletion(route: string, payload: IJsonPatchDocumentOperation[]): Promise<void> {
    return this.learnerApiService.patchCompletionAsync(route, payload);
  }

  public static async download(id: string): Promise<IArrayBufferDownload> {
    const route: ServerRoute = ServerRoute.forAction('learners', `download/${id}`);
    const result = await API.getFullResponse(route, undefined, { responseType: 'arraybuffer' });
    return {
      file: result.data,
      type: result.headers['Content-Type'],
    };
  }
}
