import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// Styles
import './footer.module.scss';

// Assets
import { ReactComponent as AccmeFooterLogo } from 'globals/images/accme-brand-signature.svg';
import { ReactComponent as AnccLogo } from 'globals/images/ancc-logo.svg';
import { ReactComponent as AcpeLogo } from 'globals/images/acpe-logo.svg';

// Store + Core
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { rollupOrganizationEnumSelector } from 'store/user/selectors';
import { TAXONOMY_FOOTER_ID } from 'core/constants';
import { getFooterTermsSelector } from 'store/taxonomy/selectors';
import { ITaxonomyTerm, RollupOrganizationEnums } from 'core/models';

export const Footer = (): ReactElement => {
  const year = new Date().getFullYear();
  const dispatch = useDispatch();
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  const footerTerms: ITaxonomyTerm[] = useSelector(getFooterTermsSelector);

  useEffect(() => {
    dispatch(getTaxonomyTermById(TAXONOMY_FOOTER_ID));
  }, [dispatch]);

  return (
    <footer className={classNames('footer')}>
      {rollupOrganizationEnum === RollupOrganizationEnums.JA && (
        <div className="footer-top">
          <AccmeFooterLogo />
          <AcpeLogo />
          <AnccLogo />
        </div>
      )}
      <div className="footer-bottom">
        <div className="footer-left">
          © {year}
          {rollupOrganizationEnum === RollupOrganizationEnums.JA
            ? ' Joint Accreditation for Interprofessional Continuing Education'
            : rollupOrganizationEnum === RollupOrganizationEnums.NARS
            ? ' American Nurses Credentialing Center. All rights reserved'
            : ' Accreditation Council for Continuing Medical Education'}
        </div>
        <div className="footer-right">
          {footerTerms.map(
            (term: Pick<ITaxonomyTerm, 'description' | 'name'>): ReactElement => {
              const { description, name } = term;

              // Is external if the route starts with '/' it should be handled by ReactRouter.
              const isExternal = !!description.indexOf('/', 0);

              return isExternal ? (
                <a href={description} key={description} rel="noopener noreferrer" target="_blank">
                  <span>{name}</span>
                </a>
              ) : (
                <Link to={description} key={description}>
                  <span>{name}</span>
                </Link>
              );
            },
          )}
        </div>
      </div>
    </footer>
  );
};
