import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

// Globals.
import error404Image from 'globals/images/error404.png';

// Styles.
import styles from './NotFoundPage.module.scss';

export const NotFoundPage = (): ReactElement => {
  return (
    <section className={styles.container}>
      <h1>Error 404 - Page Not Found</h1>
      <img alt="Sorry, we can't find the page you're looking for." src={error404Image} />
      <h2>Here Are Some Helpful Links:</h2>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/contact-support">Contact Us</Link>
        </li>
      </ul>
    </section>
  );
};
