import { Reducer } from 'redux';
import {
  GET_REPORT,
  GET_REPORT_FAILURE,
  GET_REPORT_SUCCESS,
  GET_REPORTS,
  GET_REPORTS_FAILURE,
  GET_REPORTS_SUCCESS,
  GET_SMS_SUMMARY_REPORT_AUTHORIZATION,
  GET_SMS_SUMMARY_REPORT_AUTHORIZATION_FAILURE,
  GET_SMS_SUMMARY_REPORT_AUTHORIZATION_SUCCESS,
  GET_SMS_SUMMARY_REPORT_FILE_LIST,
  GET_SMS_SUMMARY_REPORT_FILE_LIST_FAILURE,
  GET_SMS_SUMMARY_REPORT_FILE_LIST_SUCCESS,
  ReportActionTypes,
  ReportState,
} from './types';

const initialState: ReportState = {
  isLoadingReport: false,
  isLoadingReports: false,
  isLoadingSmsSummaryReports: false,
  report: null,
  reports: null,
  smsSummaryReports: null,
  isSmsSummaryReportAuthorized: false,
};

const reportReducer: Reducer<ReportState, ReportActionTypes> = (
  state = initialState,
  action: ReportActionTypes,
): ReportState => {
  switch (action.type) {
    case GET_REPORT:
      return {
        ...state,
        isLoadingReport: true,
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingReport: false,
        report: action.payload,
      };
    case GET_REPORT_FAILURE:
      return {
        ...state,
        isLoadingReport: false,
      };
    case GET_REPORTS:
      return { ...state, isLoadingReports: true };
    case GET_REPORTS_FAILURE:
      return { ...state, isLoadingReports: false };
    case GET_REPORTS_SUCCESS:
      return { ...state, isLoadingReports: false, reports: action.payload };
    case GET_SMS_SUMMARY_REPORT_FILE_LIST:
      return { ...state, isLoadingSmsSummaryReports: true };
    case GET_SMS_SUMMARY_REPORT_FILE_LIST_FAILURE:
      return { ...state, isLoadingSmsSummaryReports: false };
    case GET_SMS_SUMMARY_REPORT_FILE_LIST_SUCCESS:
      return { ...state, isLoadingSmsSummaryReports: false, smsSummaryReports: action.payload };
    case GET_SMS_SUMMARY_REPORT_AUTHORIZATION:
      return { ...state, isSmsSummaryReportAuthorized: false };
    case GET_SMS_SUMMARY_REPORT_AUTHORIZATION_SUCCESS:
      return { ...state, isSmsSummaryReportAuthorized: true };
    case GET_SMS_SUMMARY_REPORT_AUTHORIZATION_FAILURE:
      return { ...state, isSmsSummaryReportAuthorized: false };
    default:
      return state;
  }
};

export default reportReducer;
