import moment from 'moment';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';

interface IProps {
  id: string;
  activityId: string;
  learnerId?: string;
  firstName: string;
  lastName: string;
  learnerCompletionDate: string;
}
export const RecentLearnerCard = ({
  id,
  activityId,
  learnerId,
  firstName,
  lastName,
  learnerCompletionDate,
}: IProps): ReactElement => {
  const dateFormat = 'MM/DD/YYYY';

  const route: string = learnerId
    ? `/learners/${activityId}/learnercompletions/bylearner/${learnerId}`
    : `/learners/${activityId}/learnercompletions/bycompletion/${id}`;

  return (
    <>
      <div className={styles['recent-learner-col']}>
        <label className="caption-text" htmlFor="learnerName">
          Learner Name
        </label>
        <Link to={route} id="learnerName">
          {!firstName && !lastName ? (
            '<N/A>'
          ) : (
            <>
              {firstName} {lastName}
            </>
          )}
        </Link>
      </div>
      <div className={styles['recent-learner-col']}>
        <label className="caption-text" htmlFor="learnerCompletionDate">
          Completion
        </label>
        <span id="learnerCompletionDate">{moment(learnerCompletionDate).format(dateFormat)}</span>
      </div>
    </>
  );
};
