// Libraries
import { ReactElement, Fragment, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FormikBag, FormikProvider, setIn, useFormik } from 'formik';
import { get, isNumber, last, noop } from 'lodash';
import { Box, CircularProgress, FormLabel, Grid } from '@material-ui/core';
import classNames from 'classnames';
import { WarningRounded } from '@material-ui/icons';

// Components
import { FormikCheckboxConditionalNumberInput } from 'components/forms/FormikCheckboxConditionalNumberInput';
import DatePicker from 'components/DatePicker/DatePicker';
import { FormikNumberField } from 'components/forms';
import Button from 'components/Button/Button';
import { ErrorLabel } from 'components/ContinuousImprovement/ErrorLabel';
import { HighlightBox } from 'components/HighlightBox';
import { RemsCompletion } from './RemsCompletion';

// Store + Core
import { CreditRouteKeys, TAXONOMY_ACTIVITY_CREDIT_ROOT_ID } from 'core/constants';
import {
  ActivitySearchResultActivity,
  ICompletionBoard,
  IDictionary,
  IEditLearnerCompletionValidationBlueprint,
  IFormInputOption,
  IJsonPatchDocumentOperation,
  ILearnerCompletion,
  ILearnerCredits,
  ILearnerSearchActivity,
  IStateAndProvince,
  ITaxonomyTerm,
  LearnerCreditsBoardTypeEnum,
  IEditCompletionFormModel,
  IActivitySearchResult,
  IActivitySearchRequest,
  ICreditTypes,
} from 'core/models';
import {
  getLearnerCompletion,
  getLearnerCompletionFailureAction,
  resetLearnerCompletion,
  updateLearnerCompletion,
} from 'store/learner/actions';
import { learnerStateSelector } from 'store/learner/selectors';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { taxonomyRootTermsSelector } from 'store/taxonomy/selectors';
import { ButtonVariant } from 'core/enums';
import { configuredBoardsDictionarySelector } from 'store/board/selectors';
import { IBCTBoard } from 'layouts/pages/bct/types';
import { LearnerState } from 'store/learner/types';
import { getBoardById } from 'store/board/actions';
import { getStatesAndProvinces } from 'store/locations/actions';
import { statesAndProvincesSelector } from 'store/locations/selectors';
import { ITypeaheadOption } from 'components/ContinuousImprovement/Typeahead';
import { searchResponseSelector } from 'store/activity/selectors';
import { getActivities, updateActivitySearchRequest } from 'store/activity/actions';

// Utils
import {
  convertBoardCreditsForPatch,
  convertTypeaheadForPatch,
  getCreditsOptions,
  getIsFormModified,
  omitCompletionFields,
  ICreditsTransformPayload,
} from './util';
import { recursivePatch } from 'utils';
import { buildEditCompletionSchema } from './validationSchemas';

// Styles
import styles from './EditCompletionForm.module.scss';

type IProps = {
  completionSearchResult: ILearnerSearchActivity;
  onCloseCallback: () => void;
};

export const EditCompletionForm = ({ completionSearchResult, onCloseCallback }: IProps): ReactElement => {
  const dispatch = useDispatch();
  // Selectors
  const learnerState: LearnerState = useSelector(learnerStateSelector);
  const completion: ILearnerCompletion = learnerState?.currentLearnerCompletion;
  const taxonomyRootTerms: IDictionary<ITaxonomyTerm> = useSelector(taxonomyRootTermsSelector);
  const boardConfigDictionary: IDictionary<IBCTBoard> = useSelector(configuredBoardsDictionarySelector);
  const statesAndProvinces: IStateAndProvince[] = useSelector(statesAndProvincesSelector);
  const stateCreditTerms: ITaxonomyTerm = taxonomyRootTerms?.[TAXONOMY_ACTIVITY_CREDIT_ROOT_ID];
  const activityGuid: string = completionSearchResult?.activityGuid;
  const activitySearchResults: IActivitySearchResult[] = useSelector(searchResponseSelector)?.results;
  const activity: ActivitySearchResultActivity = activitySearchResults?.find(
    ({ document }: IActivitySearchResult) => document?.key === activityGuid,
  )?.document;
  const errorMessage: string = learnerState?.error;

  // Completion Metadata
  const completionDate: string = (completionSearchResult?.completionDate as unknown) as string;
  const completionId: string = completionSearchResult?.completionId;
  const dobDay: number = completionSearchResult?.learnerDOBDay;
  const dobMonth: number = completionSearchResult?.learnerDOBMonth;
  const learnerName: string = completionSearchResult?.learnerName;
  const route: string = completionSearchResult?.route;
  const boardId: string = last(route?.split('/'));

  // Use `route` key to determine completion type
  const lowerRoute: string = route?.toLowerCase();
  const isMocBoard: boolean = lowerRoute?.includes(CreditRouteKeys.CreditsByCertifyingBoard);
  const isStateBoard: boolean = lowerRoute?.includes(CreditRouteKeys.CreditsByStateOrProvince);
  const isRemsCollab: boolean = lowerRoute?.includes(CreditRouteKeys.CreditsByRems);

  // Activity Metadata for Validation
  const cmeCreditTypeTotal: number = activity?.cmeCreditTypeTotal;
  const maxClaimDate: Date = activity?.mocCreditDeadline;
  const startDate: Date = activity?.startDate;

  useEffect(() => {
    // If missing states/provinces, fetch them
    if (!statesAndProvinces || !statesAndProvinces?.length) {
      dispatch(getStatesAndProvinces());
    }
  }, []);

  useEffect(() => {
    // Search Query for Completion's Activity
    const learnerCompletionActivityQuery: IActivitySearchRequest = {
      excludeFilters: true,
      keys: activityGuid ? [activityGuid] : [],
      top: 1,
    };

    // Search for the activity the completion belongs to
    const getActivitySearchResults: () => Promise<void> = async (): Promise<void> => {
      await Promise.all([
        await dispatch(updateActivitySearchRequest(learnerCompletionActivityQuery)),
        await dispatch(getActivities(learnerCompletionActivityQuery, false, true)),
      ]);
    };
    // Search for the activity for this completion if none exist in state
    if (!activity && activityGuid) {
      getActivitySearchResults();
    }
  }, [activity, activityGuid, dispatch]);

  useEffect(() => {
    // Get the Learner Completion record to be edited
    if (route && !route?.includes(completion?.id) && activity && route?.includes(activity?.key)) {
      dispatch(getLearnerCompletion(activity?.key, completionId));
    }
  }, [activity, completion, completionId, dispatch, route]);

  useEffect(() => {
    // Ensure credit taxonomy terms are loaded
    if (!stateCreditTerms) {
      dispatch(getTaxonomyTermById(TAXONOMY_ACTIVITY_CREDIT_ROOT_ID));
    }
  }, [dispatch, stateCreditTerms]);

  useEffect(() => {
    if (boardId && !boardConfigDictionary?.[boardId]) {
      dispatch(getBoardById(boardId));
    }
  }, [boardConfigDictionary, completion, boardId, dispatch]);

  // Clear error message when closing the modal.
  const clearError = useCallback(() => dispatch(getLearnerCompletionFailureAction('')), [dispatch]);
  useEffect(() => clearError, [clearError, dispatch]);

  // Preparing Formik's initialValues for editing the completion
  const creditsByCertifyingBoard: IDictionary<ICompletionBoard> = completion?.[LearnerCreditsBoardTypeEnum.MOC];
  const creditsByRems: IDictionary<ICompletionBoard> = completion?.[LearnerCreditsBoardTypeEnum.REMS];
  const creditsByStateOrProvince: IDictionary<ICompletionBoard> = completion?.[LearnerCreditsBoardTypeEnum.STATE];
  const boardType: LearnerCreditsBoardTypeEnum = isMocBoard
    ? LearnerCreditsBoardTypeEnum.MOC
    : isRemsCollab
    ? LearnerCreditsBoardTypeEnum.REMS
    : LearnerCreditsBoardTypeEnum.STATE;

  const boardConfig: IBCTBoard = boardConfigDictionary?.[boardId];
  const boardState: string = boardConfig?.states[0];

  const boardPath =
    boardType === LearnerCreditsBoardTypeEnum.STATE
      ? `${boardType}.${boardState || completionSearchResult?.state}`
      : `${boardType}.${boardId}`;
  const learnerCreditsKey =
    boardType === LearnerCreditsBoardTypeEnum.STATE
      ? undefined // state credits only have a total
      : `${boardPath}.learnerCredits`;
  // the easiest way to get the ID for "AMA PRA Category 1 Credit" is from the current completion
  const stateCreditTypeId =
    boardType === LearnerCreditsBoardTypeEnum.STATE
      ? Object.values(creditsByStateOrProvince ?? {})
          .map((stateCompletion: ICompletionBoard) => Object.keys(stateCompletion.learnerCredits ?? {})[0])
          .filter((termId) => termId)[0]
      : null;
  const totalCreditsKey: string =
    boardType === LearnerCreditsBoardTypeEnum.STATE
      ? `${boardPath}.learnerCredits.${stateCreditTypeId}.credits`
      : `${boardPath}.totalCredits`;

  const totalAvailableCredits: number = isMocBoard
    ? activity?.moCCreditTypes.find((ct: ICreditTypes) => ct.boardId === boardId)?.credits
    : activity?.creditTypes.find(({ creditTypeId }: ICreditTypes) => creditTypeId === stateCreditTypeId)?.credits;

  const transformCreditsPayload: ICreditsTransformPayload = {
    activity,
    boardConfig,
    boardType,
    completion,
    route,
  };

  const formCreditsOptions: IFormInputOption[] = getCreditsOptions(transformCreditsPayload);

  const getInitialStateOrProvince = (boardId: string): ITypeaheadOption => {
    const completionBoardState = creditsByRems?.[boardId]?.practiceStateOrProvince;
    const storedStateOrProvince = statesAndProvinces?.find(
      ({ isoStateCode }: IStateAndProvince) => isoStateCode === completionBoardState,
    );
    const initialStateOrProvince: ITypeaheadOption = {
      id: storedStateOrProvince?.isoStateCode,
      label: storedStateOrProvince?.stateName,
    };
    return initialStateOrProvince;
  };
  const transformRems = (creditsByRems: IDictionary<ICompletionBoard>): any => {
    const transformedCreditsByRems: any = {};
    if (creditsByRems) {
      for (const key of Object.keys(creditsByRems)) {
        transformedCreditsByRems[key] = {
          ...creditsByRems[key],
          practiceStateOrProvince:
            boardId === key ? getInitialStateOrProvince(key) : creditsByRems[key]?.practiceStateOrProvince,
        };
      }
    }
    return transformedCreditsByRems;
  };

  // Setup Formik Provider
  const initialValues: IEditCompletionFormModel = {
    completionDate,
    creditsByCertifyingBoard,
    creditsByRems: isRemsCollab ? transformRems(creditsByRems) : { ...creditsByRems },
    creditsByStateOrProvince,
  };

  const onSubmit = async (
    submitValues: IEditCompletionFormModel,
    { setSubmitting }: FormikBag<undefined, IEditCompletionFormModel>,
  ) => {
    // Confirm if the form model has changed regardless of touched
    const isFormModified: boolean = getIsFormModified(submitValues, initialValues);
    if (isFormModified) {
      // Prepare the learnerCredits values for submission
      const transformedLearnerCredits: IDictionary<ILearnerCredits> = convertBoardCreditsForPatch(
        submitValues,
        learnerCreditsKey,
      );
      const stateOrProvincePath = `${boardPath}.practiceStateOrProvince`;
      // Get the state ID string, if available
      const stateTypeaheadId: string = convertTypeaheadForPatch(submitValues, stateOrProvincePath);
      // For REMS completion, may include practiceStateOrProvince field. Set just in case; undefined if no value
      const submitValuesOptionalRems: Partial<ILearnerCompletion> = setIn(
        submitValues,
        stateOrProvincePath,
        stateTypeaheadId,
      );
      // Set the learnerCredits value to it's prepared shape
      const submitValuesForPatch: Partial<ILearnerCompletion> = setIn(
        submitValuesOptionalRems,
        learnerCreditsKey,
        transformedLearnerCredits,
      );
      // Omit the uneditable fields from the form/completion
      const transformedSubmitValues: Partial<ILearnerCompletion> = omitCompletionFields(
        submitValuesForPatch,
        boardPath,
      );
      // Omit the uneditable fields from the form/completion
      const transformedCompletion: Partial<ILearnerCompletion> = omitCompletionFields(completion, boardPath);
      // Get the patch list for submission
      const patchValues: IJsonPatchDocumentOperation[] = recursivePatch({
        baseObject: transformedCompletion,
        updatedObject: transformedSubmitValues,
      });
      setSubmitting(true);

      // Convert the route to the base learner completion route
      // ex. /api/activities/:id/learnercompletions/:id
      const submitRoute = route.split('/').slice(0, 6).join('/');
      // Callback?
      await dispatch(updateLearnerCompletion({ payload: patchValues, route: submitRoute }));
    }
    await dispatch(resetLearnerCompletion());
    onCloseCallback();
    setSubmitting(false);
  };

  const validationBlueprint: IEditLearnerCompletionValidationBlueprint = {
    boardActivityDetails: {
      [boardId]: {
        ...activity?.boardMocDetails?.[boardId],
      },
    },
    boardConfigs: boardConfigDictionary,
    boardId,
    boardType,
    creditMaxClaimDate: maxClaimDate,
    isLearnerMatched: true,
    isMoc: isMocBoard,
    isState: isStateBoard,
    maxTotalCredits: cmeCreditTypeTotal,
    startDate,
  };

  const validationSchema = buildEditCompletionSchema(validationBlueprint);

  // Create the Formik Context
  const formik = useFormik<IEditCompletionFormModel>({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validateOnMount: true,
    validationSchema,
  });
  const { errors, isSubmitting, isValid, submitForm, values } = formik;
  const totalCreditsValue = values?.[boardType]?.[boardId]?.totalCredits;

  // Show this with an error message.
  if (errorMessage) {
    return (
      <>
        <HighlightBox variant="danger">
          <Box display="flex" component="p">
            <WarningRounded />
            <Box ml={2}>{errorMessage}</Box>
          </Box>
        </HighlightBox>
        <Box display="flex" justifyContent="flex-end" className={styles['button-row']}>
          <div className={styles['submit-button-container']}>
            <Button variant={ButtonVariant.Primary} onClick={onCloseCallback}>
              Okay
            </Button>
          </div>
        </Box>
      </>
    );
  }

  // Show this while loading.
  if (!completion || !route || !boardConfigDictionary?.[boardId] || !route?.includes(completion?.id))
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress color="inherit" />
      </Box>
    );

  // We have data.
  const errorClasses = classNames(styles.error, 'has_error form-input');

  // Entered credits cannot exceed the allowed credits.
  // Disable the save button if the value entered by the user is greater than the max value.
  const enteredTotalCredits: number = get(values, totalCreditsKey);
  const isCreditsEnteredValid: boolean = enteredTotalCredits <= totalAvailableCredits;

  return (
    <>
      <FormikProvider value={formik}>
        <div>
          <div className={styles.learner}>
            <span>
              {learnerName} {isNumber(dobMonth) && isNumber(dobDay) ? `| DOB: ${dobMonth}/${dobDay}` : null}
            </span>
          </div>
          <form onSubmit={noop} className={styles.form}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={10}>
                <FormLabel component="div">
                  Date completed<sup className={styles.required}>*</sup>
                </FormLabel>
                <Field name="completionDate">
                  {({ field }) => <DatePicker required field={field} formikKey="completionDate" timeOfDay="midnight" />}
                </Field>
                <div className={errorClasses}>
                  <ErrorLabel error={errors?.completionDate} name="completionDate" />
                </div>
              </Grid>
              {!isRemsCollab && (
                <Grid container item xs={12} spacing={1} alignItems="center">
                  <Grid item>
                    <FormikNumberField
                      formikKey={totalCreditsKey}
                      max={totalAvailableCredits}
                      min={0}
                      variant="outlined"
                      className={classNames(styles['completion-input'], styles.learner, 'form-input form-input--small')}
                      FormHelperTextProps={{
                        'aria-hidden': true, // Forcibly hiding this FormHelperText component in favor of manually rendering the errors
                        classes: {
                          root: styles['hide-helper-text'],
                        },
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classNames(styles['credits-input-label'], styles.learner)}>
                      <label htmlFor="totalCredits">
                        total credits awarded<span className={styles.required}> *</span>
                      </label>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={errorClasses}>
                      <ErrorLabel error={get(errors, totalCreditsKey)} name="" />
                    </div>
                  </Grid>
                </Grid>
              )}
              {!!formCreditsOptions?.length &&
                isMocBoard &&
                formCreditsOptions.map((option, idx) => (
                  <Fragment key={idx}>
                    <Grid item xs={12} className={classNames(styles['board-credits-input'])}>
                      <FormikCheckboxConditionalNumberInput
                        checkboxFormikKey={`${learnerCreditsKey}.${option.Id}.selected`}
                        formikKey={`${learnerCreditsKey}.${option.Id}`}
                        numberInputFormikKey={`${learnerCreditsKey}.${option.Id}.credits`}
                        numberInputLabel={`of ${totalCreditsValue || 0}`}
                        option={option}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className={errorClasses}>
                        <ErrorLabel error={errors?.[boardType]?.[boardId]?.learnerCredits} name="" />
                      </div>
                    </Grid>
                  </Fragment>
                ))}
              {isRemsCollab && <RemsCompletion config={boardConfigDictionary?.[boardId]} />}
              <Grid container item xs={12} justify="space-around" className={styles['button-row']}>
                <Grid item xs={12} sm={5}>
                  <div className={styles['submit-button-container']}>
                    <Button variant={ButtonVariant.Secondary} onClick={onCloseCallback}>
                      Cancel
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <div className={styles['submit-button-container']}>
                    <Button
                      disabled={!isValid || isSubmitting || (!isRemsCollab && !isCreditsEnteredValid)}
                      onClick={submitForm}
                      startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
                      variant={ButtonVariant.Primary}
                    >
                      Save Changes
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </FormikProvider>
    </>
  );
};
