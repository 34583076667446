import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from '../ReportPage.module.scss';
import { GetApp, KeyboardBackspace, Notes } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import {
  getSmsSummaryReportFile,
  getSmsSummaryReportFileList,
  getSmsSummaryReportsDirectory,
} from '../../../../store/report/actions';
import { CircularProgress } from '@material-ui/core';
import {
  isLoadingSmsSummaryReportsSelector,
  smsSummaryReportFileListSelector,
} from '../../../../store/report/selectors';

export const SmsSummaryReportPage = (): ReactElement => {
  const dispatch = useDispatch();
  const smsSummaryReports = useSelector(smsSummaryReportFileListSelector);
  const isLoadingSmsReports = useSelector(isLoadingSmsSummaryReportsSelector);

  useEffect(() => {
    dispatch(getSmsSummaryReportFileList());
  }, []);

  const downloadFile = (report: string) => {
    dispatch(getSmsSummaryReportFile(report));
  };

  return (
    <div className={styles.container}>
      <div>
        <Link className={styles.link} to="/reports">
          <KeyboardBackspace /> Back to Reports
        </Link>
      </div>
      <div>
        <section className={classNames(styles.green)}>
          <header className={styles.header}>
            <span className={styles.icon}>
              <Notes />
            </span>
            <h2 className={styles.title}>SMS Summary Reports</h2>
            <Link className="download-all-button" to="#" onClick={() => dispatch(getSmsSummaryReportsDirectory())}>
              <span>Download All &nbsp;</span>
              <GetApp />
            </Link>
          </header>
          <div className={styles.downloadList}>
            <ul className={styles.content}>
              {isLoadingSmsReports ? (
                <div className={styles.flexCenter}>
                  <CircularProgress />
                </div>
              ) : smsSummaryReports?.length > 0 ? (
                smsSummaryReports?.map((report, index) => (
                  <li key={index} onClick={() => downloadFile(report)}>
                    <div className={styles.icon}>
                      <GetApp />
                    </div>
                    <div className={styles.fileName}>{report}</div>
                  </li>
                ))
              ) : (
                <div className={styles.flexCenter}>There are no SMS Summary Reports to show.</div>
              )}
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};
