// Libs
import { ReactElement } from 'react';
import { keys, uniqueId } from 'lodash';
import classNames from 'classnames';
import { Cancel, CheckCircle } from '@material-ui/icons';

// Core
import { AddActivityFormValues, IDictionary } from 'core/models';
import { IBCTBoard } from 'layouts/pages/bct/types';

// Styles
import styles from '../CompletedActivityCard.module.scss';

interface IProps {
  configurationDictionary: IDictionary<IBCTBoard>;
  formValues: AddActivityFormValues;
  remsKey: string;
}

export const CompletedRems = ({ configurationDictionary, formValues, remsKey }: IProps): ReactElement => {
  const collab = formValues?.boardRemsDetails?.[remsKey];
  if (!collab || !configurationDictionary?.[remsKey]) return null;
  const { boardActivityAdditionalFields } = configurationDictionary[remsKey];

  const { additionalQuestionAnswers, isAttested, remsActivityId } = collab;
  const hasRemsForActive = boardActivityAdditionalFields?.length
    ? !!keys(additionalQuestionAnswers).length && isAttested
    : isAttested;
  let hasRpcId = false;
  if (remsActivityId) {
    hasRpcId = true;
  }
  return (
    <div
      className={classNames('board', {
        [styles['board-incomplete']]: !hasRemsForActive || !hasRpcId,
      })}
    >
      <div className="board-name">
        <>
          {hasRemsForActive ? <CheckCircle /> : <Cancel />}
          <span>{`${configurationDictionary?.[remsKey]?.name} ${
            !hasRemsForActive || !hasRpcId ? '[Missing Required Information]' : ''
          }`}</span>
        </>
      </div>
      <ul className={styles['board-credit-list']}>
        {!hasRpcId && (
          <li key={uniqueId()} className="board-name">
            RPC ID
          </li>
        )}
        {!hasRemsForActive && (
          <li key={uniqueId()} className="board-name">
            Attestation Complete
          </li>
        )}
      </ul>
    </div>
  );
};
