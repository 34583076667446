import { IContextualMenuOptionProps } from 'components/ContextualMenu/ContextualMenu';

// Types
interface IOption {
  options: IContextualMenuOptionProps[];
}

interface IArgs {
  onDownloadAllActivities(): void;
  onDownloadFilteredActivities(): void;
}

export const downloadMenuItems = ({ onDownloadAllActivities, onDownloadFilteredActivities }: IArgs): IOption => ({
  options: [
    {
      icon: 'get_app',
      // Keep wording as `Active`. ACCME wants to download all activities with this selection.
      name: 'Download Active Activities',
      onSelectAction: onDownloadAllActivities,
      selected: true,
    },
    {
      icon: 'get_app',
      name: 'Download Filtered Activities',
      onSelectAction: onDownloadFilteredActivities,
    },
  ],
});
