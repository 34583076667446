import orderBy from 'lodash/orderBy';

import { IBCTBoard } from 'layouts/pages/bct/types';
import { learnerCompletionOptions } from '../../constants';
import { sortedBoardLearnerCollectionFields } from './utils/sortedBoardLearnerCollectionFields';

export type ILearnerCompletionDetails = Pick<
  IBCTBoard,
  | 'allowLearnerCompletionDatesAfterCreditClaimDate'
  | 'boardLastLearnerCompletionDates'
  | 'boardLastLearnerCompletionRemovalDates'
  | 'boardLearnerIdRegularExpressions'
  | 'boardLearnerCollectionFields'
>;

export const initialLearnerCompletionDetails = (bctBoard: IBCTBoard): ILearnerCompletionDetails | unknown => {
  if (!bctBoard) return {};
  const {
    allowLearnerCompletionDatesAfterCreditClaimDate,
    boardLastLearnerCompletionDates,
    boardLastLearnerCompletionRemovalDates,
    boardLearnerCollectionFields,
    boardLearnerIdRegularExpressions,
  } = bctBoard;

  return {
    allowLearnerCompletionDatesAfterCreditClaimDate,
    // Start the `boardLastLearnerCompletionDates` ordered by `year`: lowest number at the top.
    boardLastLearnerCompletionDates: orderBy(boardLastLearnerCompletionDates, ['year'], ['asc']),
    // Start the `boardLastLearnerCompletionRemovalDates` ordered by `year`: lowest number at the top.
    boardLastLearnerCompletionRemovalDates: orderBy(boardLastLearnerCompletionRemovalDates, ['year'], ['asc']),
    // BE does not do any sorting. We handle on the FE.
    boardLearnerCollectionFields: sortedBoardLearnerCollectionFields({
      desiredOrder: learnerCompletionOptions,
      preSortedBoardLearnerCollectionFields: boardLearnerCollectionFields,
    }),
    boardLearnerIdRegularExpressions,
  };
};
