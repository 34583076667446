import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import CheckIcon from '@material-ui/icons/Check';

export const navigationMap = {
  add: <AddIcon />,
  check: <CheckIcon />,
  formatListBulleted: <FormatListBulletedIcon />,
  publish: <PublishIcon />,
};
