import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

// Styles.
import styles from './AdminPageStyles.module.scss';

// Component
import CustomTooltip from 'components/Tooltip/Tooltip';

export const AdminPage = (): ReactElement => {
  return (
    <>
      <section className={styles.container}>
        <h2 className={classNames(styles['admin-title'])}>PARS OPERATIONS</h2>
        <div className={classNames(styles['grouped-link'])}>
          <ul>
            <li>
              <CustomTooltip tooltipText="Collaboration configuration tool">
                <Link to="/board-list">CCT</Link>
              </CustomTooltip>
            </li>
            <li>
              <CustomTooltip tooltipText="Configure Search Synonyms - used for things like Alex vs. Alexander">
                <Link to="/admin/search-synonyms">Azure Search Synonym Configuration</Link>
              </CustomTooltip>
            </li>
            <li>
              <Link to="/admin/board-processing-metrics">Board Processing Metrics</Link>
            </li>
            <li>
              <Link to="/admin/provider-submission-logs">Provider Submission Logs</Link>
            </li>
            <li>
              <Link to="/admin/admin-commands">Admin Commands</Link>
            </li>
            <li>
              <Link to="/admin/cme-passport-impersonation">Impersonate CME Passport User</Link>
            </li>
            <li>
              <CustomTooltip tooltipText="Query SendGrid for any actions taken on behalf of a specified email address">
                <Link to="/" onClick={(event) => event.preventDefault()}>
                  Check Email Delivery
                </Link>
              </CustomTooltip>
            </li>
            <li>
              <CustomTooltip tooltipText="flush the database cache that populates drop downs, e.g. country, state, etc.">
                <Link to="/" onClick={(event) => event.preventDefault()}>
                  Refresh Geography
                </Link>
              </CustomTooltip>
            </li>
            <li>
              <Link to="/" onClick={(event) => event.preventDefault()}>
                IdentifyStuckLccbs
              </Link>
            </li>
            <li>
              <Link to="/" onClick={(event) => event.preventDefault()}>
                IdentifyStuckMocs
              </Link>
            </li>
            <li>
              <CustomTooltip tooltipText="front-end for Application Insights(AI) Query Find Specific Error for Specific Provider">
                <Link to="/" onClick={(event) => event.preventDefault()}>
                  PARS Error Lookup
                </Link>
              </CustomTooltip>
            </li>
            <li>
              <CustomTooltip tooltipText="front-end for AI query that finds 999 for a specific provider">
                <Link to="/" onClick={(event) => event.preventDefault()}>
                  PARS Exception Lookup
                </Link>
              </CustomTooltip>
            </li>
          </ul>
        </div>

        <h3 className={classNames(styles['admin-title'])}>PARS User Management</h3>
        <div className={classNames(styles['grouped-link'])}>
          <ul>
            <li>
              <Link to="/admin/create-web-service-account">Create Web Service/Board User</Link>
            </li>
          </ul>
        </div>
        <h3 className={classNames(styles['admin-title'])}>PARS ULID Management</h3>
        <div className={classNames(styles['grouped-link'])}>
          <ul>
            <li>
              <Link to="/" onClick={(event) => event.preventDefault()}>
                Merge ULIDs
              </Link>
            </li>
            <li>
              <Link to="/" onClick={(event) => event.preventDefault()}>
                MigrateULIDs
              </Link>
            </li>
            <li>
              <Link to="/" onClick={(event) => event.preventDefault()}>
                ClaimULID
              </Link>
            </li>
            <li>
              <Link to="/" onClick={(event) => event.preventDefault()}>
                UnboardULID
              </Link>
            </li>
            <li>
              <Link to="/" onClick={(event) => event.preventDefault()}>
                CreateTestLearner
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <aside className={classNames('rail-container', 'rail-container--75')} />
    </>
  );
};
