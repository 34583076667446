import { ReactElement } from 'react';
import classNames from 'classnames';

export interface IStepProps {
  label: string;
  stepStatus?: string;
  onClick?: (...args: any) => void;
  visible: boolean;
}

export const Step = (props: IStepProps): ReactElement => {
  const { label, stepStatus, onClick, visible } = props;

  return (
    visible && (
      <li onClick={onClick} className={classNames('step', stepStatus)}>
        {label}
      </li>
    )
  );
};
