import { UserApiService } from '../core/userApiService';
import * as PARS from '../core';
import { CacheService, TokenService } from '.';
import { IUser, CreateUserRequest } from 'core/models';
import jwtDecode from 'jwt-decode';

export class UserService {
  private userApiService: UserApiService;

  constructor() {
    this.userApiService = new UserApiService();
  }

  public async getCurrentAsync(): Promise<IUser> {
    const cacheKey: string = PARS.Constants.CACHE_USER_CURRENT;
    const cachedUser: IUser = CacheService.get<IUser>(cacheKey);

    if (cachedUser) {
      // make sure the cached user info matches the token
      const accessToken = TokenService.getAccessToken();
      const decodedToken = jwtDecode(accessToken);
      const tokenUser = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
      if (tokenUser && tokenUser?.toString().toLowerCase() !== cachedUser?.email?.toLowerCase()) {
        // the cached user is different than the logged-in user, so we can't trust anything in the cache
        CacheService.clear();
      } else {
        // cache matches (and hasn't expired), so we can use it
        return cachedUser;
      }
    }

    const user: IUser = await this.userApiService.getCurrent();
    CacheService.set(cacheKey, user, PARS.Constants.CACHE_USER_CURRENT_EXPIRY_MINS);
    return user;
  }

  public async createUserAsync(payload: CreateUserRequest): Promise<IUser> {
    return await this.userApiService.createUser(payload);
  }
}
