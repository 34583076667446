import { API } from 'core';
import { ServerRoute } from 'core/api';
import {
  CreateOrganizationUserRequest,
  OrganizationUser,
  SearchOrganizationUserRequest,
  UpdateOrganizationUserRequest,
} from 'core/models';

export class AccountManagerService {
  public static async createOrganizationUser(
    req: CreateOrganizationUserRequest,
  ): Promise<{ success: boolean; error: string }> {
    const route: ServerRoute = ServerRoute.forAction('AccountManager', 'users');
    return await API.put(route, req);
  }

  public static async updateOrganizationUser(
    armaturePersonId: string,
    req: UpdateOrganizationUserRequest,
  ): Promise<{ success: boolean; error: string }> {
    const route: ServerRoute = ServerRoute.forAction('AccountManager', `users/${armaturePersonId}`);
    return await API.post(route, req);
  }

  public static async getOrganizationUser(armaturePersonId: string): Promise<OrganizationUser> {
    const route: ServerRoute = ServerRoute.forAction('AccountManager', `users/${armaturePersonId}`);
    return await API.get(route);
  }

  public static async searchOrganizationUser(req: SearchOrganizationUserRequest): Promise<OrganizationUser[]> {
    const route: ServerRoute = ServerRoute.forAction('AccountManager', `users/search`);
    return await API.post(route, req);
  }

  public static async deleteOrganizationUser(armaturePersonId: string): Promise<{ success: boolean; error: string }> {
    const route: ServerRoute = ServerRoute.forAction('AccountManager', `users/${armaturePersonId}`);
    return await API.sendDelete(route);
  }
}
