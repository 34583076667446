import { PropsWithChildren, ReactElement } from 'react';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Box, Fab, Grid, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';

// Components
import { FormikInputField } from 'components/ContinuousImprovement/PureFormElements';

// Styles
import styles from 'components/TextboxRepeater/TextboxRepeater.module.scss';
import get from 'lodash/get';

// Types
type IProps = PropsWithChildren<{
  formikKey: string;
}>;

export const RegularExpressionsRepeater = (props: IProps): ReactElement => {
  const { formikKey } = props;
  const { setFieldTouched, values } = useFormikContext();
  const formikAccess: string[] = get(values, formikKey);
  const isDisabled: boolean = formikAccess?.includes('');

  return (
    <FieldArray
      name={formikKey}
      render={(arrayHelpers: FieldArrayRenderProps): ReactElement => {
        return (
          <Grid container>
            {formikAccess?.map(
              (_: string, idx: number): ReactElement => {
                return (
                  <Grid container spacing={3} key={idx}>
                    <Grid item sm={5}>
                      <FormikInputField
                        formikKey={`${formikKey}.${idx}.errorMessage`}
                        label="Enter message text field"
                        placeholder="Error message text"
                      />
                    </Grid>
                    <Grid item sm={5}>
                      <FormikInputField
                        formikKey={`${formikKey}.${idx}.regularExpression`}
                        label="Enter Regex validation"
                      />
                    </Grid>
                    <Box component={Grid} alignSelf="center" mt={3}>
                      <IconButton aria-label="remove" onClick={(): void => arrayHelpers.remove(idx)} size="small">
                        <ClearIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  </Grid>
                );
              },
            )}
            <Box display="flex" pt={2} pb={2}>
              <Box mr={2}>
                <Fab
                  aria-label="add another instance"
                  className={classNames(
                    {
                      [styles.disabled]: isDisabled,
                    },
                    styles['add-btn'],
                  )}
                  disabled={isDisabled}
                  onClick={(): void => {
                    arrayHelpers.push('');
                    setFieldTouched(formikKey, true);
                  }}
                  size="small"
                >
                  <AddIcon color="inherit" />
                </Fab>
              </Box>
              <Box pt={0.5}>Add Another Instance</Box>
            </Box>
          </Grid>
        );
      }}
    />
  );
};
