import { IUserOrganization, RollupOrganizationEnums } from 'core/models';

interface IArgs<T extends { rollupOrganizationCode: number }> {
  organization: IUserOrganization;
  items: T[];
}

export const filterItemsByRollupOrganizationEnum = <T extends { rollupOrganizationCode: number }>(
  items: T[],
  rollupOrganizationEnum: RollupOrganizationEnums = RollupOrganizationEnums.PARS,
): T[] => {
  // Null check.
  if (!items || items.length === 0) return [];
  // Filter  items based on rollup org *flag* (PARS = 1, JA = 2, NARS = 8) - ie. 9 is valid for both PARS and NARS
  const rollupOrganizationFlag =
    rollupOrganizationEnum === RollupOrganizationEnums.NARS
      ? 8
      : rollupOrganizationEnum === RollupOrganizationEnums.JA
      ? 2
      : 1;
  return items.filter(
    ({ rollupOrganizationCode }) =>
      (rollupOrganizationCode & rollupOrganizationFlag) === rollupOrganizationFlag || !rollupOrganizationCode,
  );
};

/**
 * @function filterTaxonomyByOrg
 * @param organization
 * @param terms
 * @returns ITaxonomyTerm[]
 *
 * @description - A helper function that returns the correct items by looking at the rollup organization
 */
export const filterItemByRollupOrganizationEnum = <T extends { rollupOrganizationCode: number }>({
  organization,
  items,
}: IArgs<T>): { rollupOrganizationCode: number }[] => {
  return filterItemsByRollupOrganizationEnum(items, organization.rollupOrganizationEnum);
};
