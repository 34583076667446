/* eslint-disable sort-keys */
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import { FormikProvider, useFormik } from 'formik';
import React, { ReactElement, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { drop, take } from 'lodash';

import Button from 'components/Button/Button';
import { Modal } from 'components/ContinuousImprovement';
import { FormikInputField } from 'components/ContinuousImprovement/PureFormElements';
import { HighlightBox } from 'components/HighlightBox';
import { LoadingCards } from 'components/LoadingCard';
import { ButtonVariant } from 'core/enums';
import { OrganizationUser, SearchOrganizationUserRequest } from 'core/models';
import { handleServerError } from 'globals/utils/handleServerError';
import { useLoadData } from 'hooks/useLoadData';
import { AccountManagerService } from 'services/AccountManagerService';
import { popToast } from 'store/toast/actions';
import { errorToastOptions } from 'store/toast/constants';

import { useAccountManagerInfo } from './hooks';
import styles from './search.module.scss';
import CustomPagination from '../../../components/Table/CustomPagination';

interface DeleteUserFormProps {
  user: OrganizationUser;
  onClose: VoidFunction;
  onSuccess: VoidFunction;
}

export const DeleteUserForm = ({ user, onClose, onSuccess }: DeleteUserFormProps): ReactElement => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {},
    onSubmit: async (): Promise<void> => {
      try {
        await AccountManagerService.deleteOrganizationUser(user.armaturePersonId);
        onClose();
        onSuccess?.();
      } catch (error) {
        dispatch(popToast({ ...errorToastOptions, message: <>{handleServerError({ error }).errorMessage}</> }));
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          Are you sure you want to delete {user?.firstName} {user?.lastName}?
        </div>
        <footer className={styles['modal-footer']}>
          <Button onClick={onClose} variant={ButtonVariant.Secondary}>
            Cancel
          </Button>
          <Button
            disabled={formik.isSubmitting}
            isSubmitting={formik.isSubmitting}
            type="submit"
            variant={ButtonVariant.Primary}
          >
            Delete
          </Button>
        </footer>
      </form>
    </FormikProvider>
  );
};

interface ISearchUserModel extends Omit<SearchOrganizationUserRequest, 'organizationId'> {}
const pageSize = 10;

export const AccountManagerSearch = (): ReactElement => {
  const { canEdit, currentUserId, organization } = useAccountManagerInfo();
  const [search, setSearch] = useState<SearchOrganizationUserRequest>({
    organizationId: organization.id,
    isPrimaryContact: false,
  });
  const { data, error, isLoading, refresh } = useLoadData(
    'AccountManagerSearch',
    useCallback(async () => {
      return await AccountManagerService.searchOrganizationUser(search);
    }, [search]),
  );
  const [page, setPage] = useState(0);
  const formik = useFormik<ISearchUserModel>({
    initialValues: {} as ISearchUserModel,
    onSubmit: async (values) => {
      const { firstName, lastName, email, phoneCountryCode, phoneAreaCode, phoneLocal } = values;
      const req: SearchOrganizationUserRequest = {
        organizationId: organization.id,
        firstName,
        lastName,
        email,
        phoneCountryCode,
        phoneAreaCode,
        phoneLocal,
      };
      setSearch(req);
      setPage(0);
    },
  });
  const [toDelete, setToDelete] = useState<OrganizationUser | null>(null);

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="static-form">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormikInputField label="First Name" formikKey="firstName" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormikInputField label="Last Name" formikKey="lastName" />
              </Grid>
              <Grid item xs={12}>
                <FormikInputField label="Email Address" formikKey="email" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormikInputField label="Phone Country Code" formikKey="phoneCountryCode" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormikInputField label="Phone Area Code" formikKey="phoneAreaCode" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormikInputField label="Phone Local" formikKey="phoneLocal" />
              </Grid>
            </Grid>
          </div>
          <div className={styles['search-buttons']}>
            <Button type="submit" size="small" variant={ButtonVariant.Primary}>
              Search
            </Button>
            <Button
              size="small"
              variant={ButtonVariant.Primary}
              className={styles['search-button']}
              onClick={() => {
                formik.resetForm();
                formik.submitForm();
                setPage(0);
              }}
            >
              Reset
            </Button>
          </div>
        </form>
      </FormikProvider>
      <Modal isOpen={!!toDelete} title="Delete User" onClose={() => setToDelete(null)}>
        <DeleteUserForm user={toDelete} onClose={() => setToDelete(null)} onSuccess={refresh} />
      </Modal>
      <div>
        {isLoading ? (
          <LoadingCards count={3} />
        ) : error ? (
          <HighlightBox variant="danger">
            <Box display="flex" component="p">
              <WarningRounded />
              <Box ml={2}>{error}</Box>
            </Box>
          </HighlightBox>
        ) : (
          <TableContainer role="grid">
            <Table className={styles.table} size="small">
              <TableHead className={styles.tableHeader}>
                <TableRow>
                  <TableCell component="th">First Name</TableCell>
                  <TableCell component="th">Last Name</TableCell>
                  <TableCell component="th">Email Address</TableCell>
                  <TableCell component="th">Phone Number</TableCell>
                  <TableCell component="th">Position</TableCell>
                  <TableCell component="th">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {take(drop(data, page * pageSize), pageSize).map((user) => (
                  <TableRow key={user.armaturePersonId}>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.phoneCountryCode}-{user.phoneAreaCode}-{user.phoneLocal}
                    </TableCell>
                    <TableCell>
                      {user.position}
                      {user.isPrimaryContact ? (
                        <>
                          {' '}
                          <div className={styles['is-primary-contact']}>Primary Contact</div>
                        </>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <div className={styles['action-buttons']}>
                        {canEdit || user.userId === currentUserId ? (
                          <Button
                            component={Link}
                            title="Edit User"
                            to={`/account-manager/users/edit/${user.armaturePersonId}`}
                            variant={ButtonVariant.Secondary}
                          >
                            Update
                          </Button>
                        ) : null}
                        {canEdit ? (
                          <Button
                            title="Delete User"
                            variant={ButtonVariant.Secondary}
                            onClick={() => setToDelete(user)}
                            disabled={user.userId === currentUserId}
                          >
                            Delete
                          </Button>
                        ) : null}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={8}>
                    <div>
                      Showing {page * pageSize + 1} to {Math.min((page + 1) * pageSize, data.length)} of {data.length}{' '}
                      matching users
                    </div>
                    <CustomPagination
                      dataSet={data}
                      page={page}
                      onPageChange={(callbackPage) => setPage(callbackPage)}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
};
