import { CloseRounded } from '@material-ui/icons';
import { ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// Hooks.
import { useClickEvent } from 'hooks';

// Store.
import { dismissDashboardBanner } from 'store/dashboard/actions';
import { IDashboardBanner } from 'store/dashboard/types';

// Styles.
import styles from './DashboardBanner.module.scss';

// Utils.
import { isUrlAbsolute } from 'utils';

interface IDashboardBannerProps {
  banner: IDashboardBanner;
}

export const DashboardBanner = (props: IDashboardBannerProps): ReactElement => {
  const { banner = {} as IDashboardBanner } = props;
  const dispatch = useDispatch();
  const hasLink = !!banner.linkUrl && !!banner.linkText;
  const fireDismissEvent = useClickEvent({ Event: 'Banner', EventAction: 'Dismiss', EventCategory: 'Banner' });

  const handleDismissBaner = useCallback(() => {
    fireDismissEvent();
    dispatch(dismissDashboardBanner(banner.id));
  }, [banner, dispatch, fireDismissEvent]);

  return (
    <div className={styles.banner}>
      <div>
        <span>{banner.content}</span>
        {hasLink &&
          (isUrlAbsolute(banner.linkUrl) ? (
            <a href={banner.linkUrl} rel="noreferrer" target="_blank">
              {banner.linkText}
            </a>
          ) : (
            <Link to={banner.linkUrl}>{banner.linkText}</Link>
          ))}
      </div>
      <button onClick={handleDismissBaner} type="button">
        <CloseRounded />
      </button>
    </div>
  );
};
