import { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DeleteRounded } from '@material-ui/icons';
import moment from 'moment';

// Components
import StaticToast from 'components/StaticToast/StaticToast';

// Store + Core
import { searchLearnerSelectedSelector } from 'store/learner/selectors';
import { ToastType } from 'core/enums';
import { CompletionSource, ILearnerSearchActivity, IBoardSlim } from 'core/models';

import { boardSelector } from 'store/board/selectors';

// Types
interface IProps {
  deleteAction(boolean): void;
}

export const ToastActions = (props: IProps): ReactElement => {
  const { deleteAction } = props;

  // Selectors.
  const selectedLearners: ILearnerSearchActivity[] = useSelector(searchLearnerSelectedSelector);
  const boards: IBoardSlim[] = useSelector(boardSelector);

  // Conditions.
  // Find all of the selectedLearners' completionSources and put them into an array.
  const completionsSources: string[] = useMemo(
    () => selectedLearners.map(({ completionSource }: ILearnerSearchActivity): CompletionSource => completionSource),
    [selectedLearners],
  );

  // All completion sources must be the same in order to perform a bulk delete.
  const areAllSameSource: boolean = new Set(completionsSources).size === 1;

  const now = moment();

  let canEditDelete = true;

  selectedLearners.forEach((learner: ILearnerSearchActivity) => {
    // get board CCT data by abbreviation since `item` doesn't have a boardId
    const cctBoard = boards?.find(({ abbreviation }) => abbreviation === (learner.board || learner.stateAbbreviation));

    const completionDateMoment = moment(learner.completionDate);

    const cctLastEditDate = cctBoard?.boardLastLearnerCompletionRemovalDate?.find(
      (i) => i.year === completionDateMoment.year(),
    )?.cutoffDate;
    if (canEditDelete) {
      canEditDelete = !cctLastEditDate || moment(cctLastEditDate).isAfter(now, 'day');
    }
  });

  const defaultToastOptions = {
    centered: true,
    toastType: ToastType.TOAST_INFO,
  };

  const deleteOption = {
    ...defaultToastOptions,
    icon: <DeleteRounded className="icon" />,
    message: <>Delete Selected Learners</>,
    onClick: (): void => deleteAction(true),
  };

  const defaultOption = {
    ...defaultToastOptions,
    message: <>No action available for the combined selected learners - adjust selections.</>,
  };

  const cannotDeleteOption = {
    ...defaultToastOptions,
    message: (
      <>
        Deadline for deleting participant data has passed. ACCME will not accept deletions of participant records past
        the date set by the specialty board.
      </>
    ),
  };

  // Keeping this style in case more options need to be added later on.
  let toastOptions;

  if (!canEditDelete) {
    toastOptions = [cannotDeleteOption];
  } else if (areAllSameSource) {
    toastOptions = [deleteOption];
  } else {
    toastOptions = [defaultOption];
  }

  return <StaticToast isOpen={!!selectedLearners.length} toastOptions={toastOptions.filter(Boolean)} />;
};
