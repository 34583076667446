import { SvgIcon } from '@material-ui/core';
import classNames from 'classnames';
import { ElementType, ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// Hooks.
import { useClickEvent } from 'hooks';

// Store.
import { dismissDashboardNotification } from 'store/dashboard/actions';
import { DashboardNotificationSeverityIcons, IDashboardNotification } from 'store/dashboard/types';

// Styles.
import styles from './DashboardNotification.module.scss';

interface IDashboardNotificationProps {
  notification: IDashboardNotification;
}

export const DashboardNotification = (props: IDashboardNotificationProps): ReactElement => {
  const { notification = {} as IDashboardNotification } = props;
  const { actions, description, id, severity } = notification;
  const dispatch = useDispatch();

  const fireDismissEvent = useClickEvent({ Event: 'Notification', EventAction: 'Dismiss', EventCategory: 'Dismiss' });

  const SeverityIcon: ElementType =
    DashboardNotificationSeverityIcons[severity] ?? (() => <SvgIcon className={styles.icon} />);

  const handleDismiss = useCallback(() => {
    dispatch(dismissDashboardNotification(id));
    fireDismissEvent();
  }, [dispatch, fireDismissEvent, id]);

  return (
    <li className={styles.notification} key={id}>
      <SeverityIcon className={classNames(styles.icon, styles[severity])} />
      <div>
        <span className={classNames(styles.description, styles[severity])}>{description}</span>
        <div className={styles.actions}>
          {actions.map((action, index) => (
            <Link className={styles.action} key={index} to={action.link}>
              {action.text}
            </Link>
          ))}
          <button className={styles.action} onClick={handleDismiss} type="button">
            Dismiss
          </button>
        </div>
      </div>
    </li>
  );
};
