import omit from 'lodash/omit';
import { Reducer } from 'redux';

// Types.
import {
  HelpDeskActionTypes,
  HELP_DESK_ADD_ATTACHMENT,
  HELP_DESK_REMOVE_ATTACHMENT,
  HELP_DESK_RESET_ATTACHMENT,
  IHelpDeskState,
} from './types';

const initialState = {
  attachments: {},
};

export const helpDeskReducer: Reducer<IHelpDeskState, HelpDeskActionTypes> = (
  state = initialState,
  action,
): IHelpDeskState => {
  switch (action.type) {
    case HELP_DESK_ADD_ATTACHMENT:
      return { ...state, attachments: { ...state.attachments, ...action.payload } };
    case HELP_DESK_REMOVE_ATTACHMENT:
      return { ...state, attachments: omit(state.attachments, action.payload) };
    case HELP_DESK_RESET_ATTACHMENT:
      return { ...state, attachments: initialState.attachments };
    default:
      return state;
  }
};
