import { createSelector } from 'reselect';

// Core + Store
import { AppState } from 'store';
import { ContentTag, IDictionary } from 'core/models';

export const contentTagsSelector = ({ contentTagsState }: AppState): ContentTag[] => contentTagsState.childContentTags;

export const configuredContentTagsHierarchySelector = createSelector(
  [contentTagsSelector],
  (contentTags: ContentTag[]): ContentTag[] => {
    const nodesById: IDictionary<ContentTag> = {};
    const rootNodes: ContentTag[] = [];
    contentTags.forEach((tag) => {
      nodesById[tag.id] = { ...tag, childContentTags: [] };
    });

    contentTags.forEach((ctag) => {
      if (ctag.parentContentTagId) {
        if (nodesById[ctag.parentContentTagId]) {
          nodesById[ctag.parentContentTagId].childContentTags.push(nodesById[ctag.id]);
        }
      } else {
        rootNodes.push(nodesById[ctag.id]);
      }
    });

    return rootNodes;
  },
);
