import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { FormikProvider, useFormik } from 'formik';
import { useMediaQuery } from '@material-ui/core';

// Components
import { FacetsAccordion, FiltersSelected } from 'components/ContinuousImprovement/Facets/components';
import Button from 'components/Button/Button';
import { FormikInputFieldWithSearch } from 'components/ContinuousImprovement/FormikInputFieldWithSearch';

// Styles
import styles from '../Facets.module.scss';

// Core + Store
import {
  Facet,
  IActivityLearnerSummarySearch,
  IActivitySearchRequest,
  IUser,
  RollupOrganizationEnums,
} from 'core/models';
import { IInitialSearchFacet } from '../types';
import { ACTIVITY_SEARCH_ACCORDION_TITLES, INDIVIDUAL_ACTIVITY_SEARCH_ACCORDION_TITLES } from 'core/constants';
import {
  activitiesSearchingSelector,
  activitySearchFacetsSelector,
  searchRequestSelector,
} from 'store/activity/selectors';
import { BreakpointsEnum, ButtonVariant } from 'core/enums';
import { getActivities, updateActivitySearchRequestAction } from 'store/activity/actions';
import { closeRail } from 'store/rail/actions';
import { isRailOpenSelector } from 'store/rail/selectors';
import { isBoardUserSelector, userProfileSelector, rollupOrganizationEnumSelector } from 'store/user/selectors';
import { INITIAL_ACTIVITY_SEARCH_FACETS } from './constants';

// Types
interface IProps {
  forcedFilter?: IActivityLearnerSummarySearch;
  handleClose(): void;
  initialValues: IInitialSearchFacet;
}

export const ActivitySearchFacets = (props: IProps): ReactElement => {
  const { handleClose, forcedFilter, initialValues } = props;
  const dispatch = useDispatch();
  const isLgBreakpoint = useMediaQuery(`(max-width:${BreakpointsEnum.lg})`);
  const activitySearchTitles = ACTIVITY_SEARCH_ACCORDION_TITLES;

  // Selectors
  const facets: Facet = useSelector(activitySearchFacetsSelector);
  const isBoardUser: boolean = useSelector(isBoardUserSelector);
  const isLoading: boolean = useSelector(activitiesSearchingSelector);
  const isRailOpen: boolean = useSelector(isRailOpenSelector);
  const searchRequest: IActivitySearchRequest = useSelector(searchRequestSelector);
  const userProfile: IUser = useSelector(userProfileSelector);
  const rollupOrganizationEnums = useSelector(rollupOrganizationEnumSelector);
  const isNars = rollupOrganizationEnums === RollupOrganizationEnums.NARS;

  // Individual Activity information.
  const individualActivityTitles = [...INDIVIDUAL_ACTIVITY_SEARCH_ACCORDION_TITLES];
  const hasSelectedProvider = userProfile.currentProvider !== null;

  useEffect(() => {
    if (shouldShowIndividualActivitySearchFacets()) {
      addIndividualActivitySearchFacetsToAccordion();
    }
  }, [
    isNars,
    userProfile.currentProvider?.isApproverOfActivities,
    userProfile.hasChildApproverOfActivities,
    hasSelectedProvider,
  ]);

  /**
   * Adds the individual activity search titles to the main activity search titles
   * so that they show up in the facet accordion.
   * @function addIndividualActivitySearchFacetsToAccordion
   * @returns {void}
   */
  const addIndividualActivitySearchFacetsToAccordion = (): void => {
    for (const facet of individualActivityTitles) {
      if (activitySearchTitles.indexOf(facet) === -1) {
        activitySearchTitles.push(facet);
      }
    }
  };

  /**
   * Determine if a NARS accreditor has a selected provider, and that provider is an approver of activities.
   * When the NARS accreditor does not have a selected provider, determine if it has a child org that is an approver of an activity.
   * @returns {boolean} - boolean used to indicate if the individual activity search facets should be shown.
   */
  const shouldShowIndividualActivitySearchFacets = (): boolean => {
    if (!isNars) return false;
    if (hasSelectedProvider) return userProfile.currentProvider?.isApproverOfActivities;
    return userProfile.hasChildApproverOfActivities;
  };

  const formik = useFormik({
    initialValues,
    // API call.
    onSubmit: async (values: IActivitySearchRequest): Promise<void> => {
      window.scroll(0, 0);
      await dispatch(updateActivitySearchRequestAction(values));
      await dispatch(getActivities());
      await handleClose();
    },
  });

  const { handleReset, handleSubmit, values, setValues } = formik;

  const onClearAll = async (e): Promise<void> => {
    window.scroll(0, 0);
    await handleReset(e);
    await setValues(INITIAL_ACTIVITY_SEARCH_FACETS);
    await dispatch(updateActivitySearchRequestAction(INITIAL_ACTIVITY_SEARCH_FACETS));
    await dispatch(getActivities(forcedFilter));
  };

  const onClickIsDeleted = async (): Promise<void> => {
    window.scroll(0, 0);
    await dispatch(closeRail());
    await dispatch(getActivities({ ...searchRequest, isDeleted: true }, false));
  };

  const onClickIsViewingActivitiesSelectedForReview = async (): Promise<void> => {
    window.scroll(0, 0);
    await dispatch(closeRail());
    await dispatch(getActivities({ ...searchRequest, isSelectedForReview: true }, false));
  };

  const isDisabled = !facets && isLoading;

  return isLgBreakpoint && !isRailOpen ? null : (
    <FormikProvider value={formik}>
      <form className={styles['summary-refiners']} onSubmit={handleSubmit}>
        {isLgBreakpoint && (
          <Button aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </Button>
        )}
        <FormikInputFieldWithSearch
          disabled={isDisabled}
          formikKey="searchText"
          placeholder="Search Activity"
          title="Search activity"
        />
        <Button
          disabled={isDisabled}
          fullWidth
          variant={ButtonVariant.Primary}
          type="submit"
          className={styles['margin-top']}
        >
          Apply selected filter(s)
        </Button>
        {/* Filter pills */}
        <div className={styles.filters}>
          <div className={styles['filters-header']}>
            Filter By:
            <Button className={styles['link link--hyperlink']} disabled={isDisabled} onClick={onClearAll}>
              Clear All
            </Button>
          </div>

          {/* Facets */}
          <div className={styles['filters-selected']}>
            {values && (
              <div className={styles['filters-selected']}>
                <FiltersSelected defaultFacets={activitySearchTitles} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.refiners}>
          <FacetsAccordion defaultFacets={activitySearchTitles} facets={facets} searchRequest={searchRequest} />
        </div>
        <Button disabled={isDisabled} fullWidth variant={ButtonVariant.Primary} type="submit">
          Apply selected filter(s)
        </Button>
        {!searchRequest?.isDeleted && (
          <>
            <br />
            <Button disabled={isDisabled} onClick={onClickIsDeleted} fullWidth variant={ButtonVariant.Label}>
              Show Deleted Activities
            </Button>
          </>
        )}
        {!isBoardUser && !searchRequest.isSelectedForReview && (
          <>
            <br />
            <Button
              disabled={isDisabled}
              fullWidth
              onClick={onClickIsViewingActivitiesSelectedForReview}
              variant={ButtonVariant.Label}
            >
              View Activities Selected for Review
            </Button>
          </>
        )}
      </form>
    </FormikProvider>
  );
};
