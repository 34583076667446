import { ReactElement } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { useFormikContext } from 'formik';
import uniqueId from 'lodash/uniqueId';
import startCase from 'lodash/startCase';

// Components
import { FormikNumberField } from 'components/ContinuousImprovement/PureFormElements';

// Types
import { IFacetProps, SearchRequest } from 'components/ContinuousImprovement/Facets/types';

export const Range = (props: IFacetProps): ReactElement => {
  const { callback, classes, expandIcon, facet, isDisabled, isExpanded, onToggleExpand } = props;
  const { setFieldValue } = useFormikContext<SearchRequest>();
  const { displayValue, rangeEndValue, rangeStartValue, rangeEndPlaceholderValue, rangeStartPlaceholderValue } = facet;

  const onKeyPress = (e, formikKey): void => {
    const value = e.target.value;
    setFieldValue(formikKey, value);

    // Clicking the enter button should call the callback.
    if (e.which === 13) {
      callback?.();
    }
  };

  const endLabel = rangeEndPlaceholderValue || startCase(rangeEndValue);
  const startLabel = rangeStartPlaceholderValue || startCase(rangeStartValue);

  return (
    <Accordion key={displayValue} className={classes.accordion} expanded={isExpanded} onChange={onToggleExpand}>
      <AccordionSummary className={classes.summary} expandIcon={expandIcon} id={uniqueId()}>
        {displayValue}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <span className="search-box--small" style={{ width: 'auto' }}>
          <FormikNumberField
            disabled={isDisabled}
            formikKey={rangeStartValue}
            label={startLabel}
            onKeyPress={(e) => onKeyPress(e, rangeStartValue)}
            placeholder={startLabel}
          />
        </span>
        <span className="search-box--small" style={{ width: 'auto' }}>
          <FormikNumberField
            disabled={isDisabled}
            formikKey={rangeEndValue}
            label={endLabel}
            onKeyPress={(e) => onKeyPress(e, rangeEndValue)}
            placeholder={endLabel}
          />
        </span>
      </AccordionDetails>
    </Accordion>
  );
};
