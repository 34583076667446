import { ITaxonomyDictionary, ITaxonomyTerm } from 'core/models';
interface IArgs {
  terms: ITaxonomyDictionary;
  rootId: string;
  tag: string;
}
/**
 * @function getTaxonomyChildByTag
 * @param terms
 * @param rootId
 * @param tag
 * @returns ITaxonomyTerm
 *
 * @description - A helper function that retrieves an L2 term by its L1 id and tag.
 */
export const getTaxonomyChildByTag = ({ terms, rootId, tag }: IArgs): ITaxonomyTerm => {
  // Null check
  if (!terms || !rootId || !tag) return;
  // Find child in taxonomy state
  return terms[rootId]?.terms.find((term) => term?.tag?.toLocaleLowerCase() === tag.toLocaleLowerCase());
};
