// Builds a new activity based on the tempDatesAndLocation param.

export const buildNewActivity = (values) => {
  const { tempDatesAndLocation, ...rest } = values;
  return tempDatesAndLocation.map((dateAndLocation) => {
    return {
      ...dateAndLocation,
      ...rest,
    };
  });
};
