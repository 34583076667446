import { BoardLearnerCollectionFields, ILearnerCompletionOption } from './types';
import {
  TAXONOMY_DEA_REGISTRATION_ROOT_ID,
  TAXONOMY_PRACTICE_AREA_ROOT_ID,
  TAXONOMY_PROFESSION_ROOT_ID,
  TAXONOMY_TIME_IN_PRACTICE_ROOT_ID,
} from 'core/constants';

// Keep this order.
export const learnerCompletionOptions: ILearnerCompletionOption[] = [
  {
    fieldType: BoardLearnerCollectionFields.FIRST_NAME,
  },
  {
    fieldType: BoardLearnerCollectionFields.LAST_NAME,
  },
  {
    fieldType: BoardLearnerCollectionFields.DATE_OF_BIRTH,
  },
  {
    fieldType: BoardLearnerCollectionFields.BOARD_NAME,
  },
  {
    fieldType: BoardLearnerCollectionFields.BOARD_ID,
  },
  {
    fieldType: BoardLearnerCollectionFields.STATE_OF_LICENSURE,
  },
  {
    fieldType: BoardLearnerCollectionFields.LICENSE_ID,
  },
  {
    fieldType: BoardLearnerCollectionFields.ACCME_ACTIVITY_ID,
  },
  {
    fieldType: BoardLearnerCollectionFields.CREDIT_TYPE,
  },
  {
    fieldType: BoardLearnerCollectionFields.CREDITS_EARNED_PER_CREDIT_TYPE,
  },
  {
    fieldType: BoardLearnerCollectionFields.COMPLETION_DATE,
  },
  {
    fieldType: BoardLearnerCollectionFields.NPI,
  },
  {
    fieldType: BoardLearnerCollectionFields.ULID,
  },
  {
    fieldType: BoardLearnerCollectionFields.MEDICAL_SCHOOL,
  },
  {
    fieldType: BoardLearnerCollectionFields.EMAIL_ADDRESS,
  },
  {
    fieldType: BoardLearnerCollectionFields.MOBILE_NUMBER,
  },
  {
    fieldType: BoardLearnerCollectionFields.PROVIDER_LEARNER_ID,
  },
  {
    fieldType: BoardLearnerCollectionFields.PRACTICE_STATE,
  },
  {
    fieldType: BoardLearnerCollectionFields.PROFESSION,
    taxonomyTerm: TAXONOMY_PROFESSION_ROOT_ID,
  },
  {
    fieldType: BoardLearnerCollectionFields.TIME_IN_PRACTICE,
    taxonomyTerm: TAXONOMY_TIME_IN_PRACTICE_ROOT_ID,
  },
  {
    fieldType: BoardLearnerCollectionFields.PRACTICE_AREA,
    taxonomyTerm: TAXONOMY_PRACTICE_AREA_ROOT_ID,
  },
  {
    fieldType: BoardLearnerCollectionFields.DEA_REGISTRATION,
    taxonomyTerm: TAXONOMY_DEA_REGISTRATION_ROOT_ID,
  },
  {
    fieldType: BoardLearnerCollectionFields.SURGICAL_PROCEDURES,
  },
  {
    fieldType: BoardLearnerCollectionFields.PRESCRIBED_IN_PAST_12_MONTHS,
  },
];
