import { Action, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store';
import {
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAILURE,
  IDownloadActionProperties,
  IDownloadLvtActionProperties,
} from './types';
import { FileService } from 'services/FileService';
// @ts-ignore: This package isn't typescript-friendly
import { saveAs } from 'filesaver.js-npm';
import { popToast } from 'store/toast/actions';
import { errorToastOptions, infoToastOptions, successToastOptions } from 'store/toast/constants';
import { ManualDownloadLink } from 'core/models';
import { handleServerError } from '../../globals/utils/handleServerError';

export const downloadStartedAction = (): Action => {
  return {
    type: DOWNLOAD_FILE,
  };
};

export const downloadSuccessAction = (): Action => {
  return {
    type: DOWNLOAD_FILE_SUCCESS,
  };
};

export const downloadFailureAction = (errorMessage: string): AnyAction => {
  return {
    payload: errorMessage,
    type: DOWNLOAD_FILE_FAILURE,
  };
};

export const downloadFile = ({
  controller,
  id,
  matchTemplate,
}: IDownloadActionProperties): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {
  if (!matchTemplate[id]) {
    dispatch(downloadFailureAction('Please check the id and filename'));
  }
  await dispatch(downloadStartedAction());

  // Tell the user there we started downloading.
  await dispatch(popToast({ ...infoToastOptions, message: <>Downloading...</> }));

  try {
    const { file, type, filename } = await FileService.download(controller, id);

    if (file) {
      // Setup blob file.
      const userFile = new Blob([file], { type });

      // Save to user's download folder.
      saveAs(userFile, filename || matchTemplate[id]);

      // Success.
      await dispatch(downloadSuccessAction());

      // Tell the user there was success.
      await dispatch(popToast({ ...successToastOptions, message: <>Downloaded successfully.</> }));
    }
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'downloadFile' });
    await dispatch(downloadFailureAction(errorMessage));

    // Tell the user there was an error.
    await dispatch(popToast({ ...errorToastOptions, message: <>{getState().fileDownloadState.error}</> }));
  }
};

export const downloadLearnerValidationFile = ({
  controller,
  matchTemplate,
}: IDownloadLvtActionProperties): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {
  await dispatch(downloadStartedAction());

  // Tell the user there we started downloading.
  await dispatch(popToast({ ...infoToastOptions, message: <>Downloading...</> }));

  try {
    const { file, type, filename } = await FileService.download(controller, '');

    if (file) {
      // Setup blob file.
      const userFile = new Blob([file], { type });

      // Save to user's download folder.
      saveAs(userFile, filename || matchTemplate[0]);

      // Success.
      await dispatch(downloadSuccessAction());

      // Tell the user there was success.
      await dispatch(popToast({ ...successToastOptions, message: <>Downloaded successfully.</> }));
    }
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'downloadFile' });
    await dispatch(downloadFailureAction(errorMessage));

    // Tell the user there was an error.
    await dispatch(popToast({ ...errorToastOptions, message: <>{getState().fileDownloadState.error}</> }));
  }
};

export const downloadProviderFile = ({
  id,
  fileName,
}: ManualDownloadLink): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {
  // Null check.
  if (!id) {
    dispatch(downloadFailureAction('Please provide a file id'));
  }
  // Start the download.
  await dispatch(downloadStartedAction());

  // Tell the user there we started downloading.
  await dispatch(popToast({ ...infoToastOptions, message: <>Downloading...</> }));

  try {
    const { file, type } = await FileService.downloadProviderFiles({ fileName, id });

    if (file) {
      // Setup the blob file.
      const userFile = new Blob([file], { type });

      // Save to user's download folder.
      saveAs(userFile, fileName);

      // Success.
      await dispatch(downloadSuccessAction());

      // Tell the user there was success.
      await dispatch(popToast({ ...successToastOptions, message: <>Downloaded successfully.</> }));
    }
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'downloadProviderFile' });
    // Failure.
    await dispatch(downloadFailureAction(errorMessage));

    // Tell the user there was an error.
    await dispatch(popToast({ ...errorToastOptions, message: <>{getState().fileDownloadState.error}</> }));
  }
};

export const downloadLearnerValidationProviderFile = ({
  id,
  fileName,
}: ManualDownloadLink): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {
  // Null check.
  if (!id) {
    dispatch(downloadFailureAction('Please provide a file id'));
  }
  // Start the download.
  await dispatch(downloadStartedAction());

  // Tell the user there we started downloading.
  await dispatch(popToast({ ...infoToastOptions, message: <>Downloading...</> }));

  try {
    const { file, type } = await FileService.downloadLearnerValidationProviderFiles({ fileName, id });

    if (file) {
      // Setup the blob file.
      const userFile = new Blob([file], { type });

      // Save to user's download folder.
      saveAs(userFile, fileName);

      // Success.
      await dispatch(downloadSuccessAction());

      // Tell the user there was success.
      await dispatch(popToast({ ...successToastOptions, message: <>Downloaded successfully.</> }));
    }
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'downloadProviderFile' });
    // Failure.
    await dispatch(downloadFailureAction(errorMessage));

    // Tell the user there was an error.
    await dispatch(popToast({ ...errorToastOptions, message: <>{getState().fileDownloadState.error}</> }));
  }
};
