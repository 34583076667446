import keys from 'lodash/keys';

// Core
import Configuration from 'core/configuration';

// Types
import { FacetTypeEnum, FileTypesEnum } from './enums';

// Misc
import { FileUpload } from './fileUploads';
import { OrganizationKind, OrganizationKinds, RollupOrganizationEnums } from './models';
import { ITypeaheadOption } from '../components/Typeahead/Typeahead';
import { Selector } from 'reselect';
import { AppState } from '../store';
import {
  activityCountryFacetsSelector,
  activitySearchJointProviderFacetsSelector,
  activitySearchStateFacetsSelector,
} from '../store/activity/selectors';
import {
  learnerCountrySearchFacetsSelector,
  learnerJointProviderSearchFacetsSelector,
  learnerStateSearchFacetsSelector,
} from '../store/learner/selectors';

// Log
export const LOGGED_EVENT = 'logged event';
export const LOGGED_TRACE = 'logged trace';

// Storage
const cachePrefix = 'pars';

export const AZURE_SEARCH_MAX = 100_000;

export const CENTRAL_TIMEZONE = 'America/Chicago'; // certain validation rules target central timesone specifically, and should continue to, even if the default changes
export const DEFAULT_TIMEZONE = CENTRAL_TIMEZONE;
export const DEFAULT_COUNTRY = 'United States of America';
export const DEFAULT_DATE_FORMAT = 'MM/DD/yyyy';
export const ACTIVITY_DISPLAY_DATE_FORMAT = 'MM/DD/YY';

export const CACHE_FORM_TEMPLATE_PREFIX = `${cachePrefix}.formTemplate_`;
export const CACHE_FORM_TEMPLATE_EXPIRY_MINS = 60 * 24;

export const CACHE_PARS_ACCESS_TOKEN = `${cachePrefix}.access_token`;
export const CACHE_TAXONOMY_ROOT_TERMS = `${cachePrefix}.taxonomyRootTerms`;
export const CACHE_TAXONOMY_TERM_PREFIX = `${cachePrefix}.taxonomyTerm_`;
export const CACHE_TAXONOMY_ROOT_TERMS_EXPIRY_MINS = 60 * 24;
export const CACHE_TAXONOMY_TERM_EXPIRY_MINS = 60 * 24;

export const CACHE_USER_CURRENT = `${cachePrefix}.user.current`;
export const CACHE_USER_CURRENT_EXPIRY_MINS = 60 * 24;

export const CACHE_SELECTED_ORGANIZATION = `${cachePrefix}.selectedOrganization`;
export const CACHE_SELECTED_ORGANIZATION_EXPIRY_MINS = 60 * 24;
export const CACHE_SELECTED_PROVIDER = `${cachePrefix}.selectedProvider`;
export const CACHE_SELECTED_PROVIDER_EXPIRY_MINS = 60 * 24;

export const CACHE_DASHBOARD_BANNERS = `${cachePrefix}.dashboard.dismissedBanners`;
export const CACHE_DASHBOARD_BANNERS_EXPIRY_MINS = 60 * 24;

export const CACHE_DASHBOARD_MESSAGES = `${cachePrefix}.dashboard.dismissedMessages`;
export const CACHE_DASHBOARD_MESSAGES_EXPIRY_MINS = 60 * 24;

export const CACHE_DASHBOARD_NOTIFICATIONS = `${cachePrefix}.dashboard.dismissedNotifications`;
export const CACHE_DASHBOARD_NOTIFICATIONS_EXPIRY_MINS = 60 * 24;

// Taxonomy
export const TAXONOMY_ACTIVITY_CERTIFYING_BOARD_ROOT_ID = '5e103c46-9364-44ac-a9e9-b0b9c4437001';
export const TAXONOMY_ACTIVITY_CREDIT_AMA_NAME = 'AMA PRA Category 1™';
export const TAXONOMY_ACTIVITY_CREDIT_ROOT_ID = '5ed0b390-ef93-44c0-aa72-38e65043e28e';
export const TAXONOMY_ACTIVITY_PHARMACY_TOPIC_ID = '91254126-BA90-4D29-AECA-705A76C32F20';
export const TAXONOMY_ACTIVITY_PHARMACY_TYPE_ID = '34E21401-72E3-4C3A-9F2A-CEC6D18A94FC';
export const TAXONOMY_ACTIVITY_PHARMACY_CONTENT_ID = 'E44EE00E-AE50-EE11-9937-6045BD483A53';
export const TAXONOMY_ACTIVITY_PHARMACY_CONTENT_STATE_ID = '59671548-7D8A-49F3-8346-0A66CF983D97';
export const TAXONOMY_ACTIVITY_PHARMACY_CERTIFICATION_ID = 'CE4EE00E-AE50-EE11-9937-6045BD483A53';
export const TAXONOMY_ACTIVITY_CONTENT_TAG_ID = '5ed0b390-ef93-44c0-aa72-38e65043e28e';
export const TAXONOMY_ACTIVITY_REVIEW_OUTCOMES_ID = '36E18BF0-0688-4DC4-BFDE-39D88F004A6E';
export const TAXONOMY_ACTIVITY_REVIEW_TYPES_ID = 'AE6FDFE7-B5E0-4E24-A081-866B1D65757D';
export const TAXONOMY_COMMENDATION_CRITERIA_ROOT_ID = 'd03a72ce-22bf-4655-8cc9-9fa26ae9b9d7';
export const TAXONOMY_DEA_REGISTRATION_ROOT_ID = 'f77bfbc7-608f-4ca4-bea3-4a4e93ddd691';
export const TAXONOMY_FAQ_ROOT_ID = '3d040401-2456-48bb-966e-052e5e47e3b5';
export const TAXONOMY_FOOTER_ID = 'B6DE4A4D-1585-49FB-8089-F64DD44254A5';
export const TAXONOMY_HELP_DESK_ID = '5F6C16BA-A829-490C-B880-AB27249D0BC9';
export const TAXONOMY_LEARNER_TYPES_ROOT_ID = '13dcbe77-5801-4b6e-bfee-f122f2625d63';
export const TAXONOMY_MEDICAL_SCHOOLS_ROOT_ID = 'D65ACB57-5F80-4C29-9EAE-C4F6EAA6D0D0';
export const TAXONOMY_NAVIGATION_ROOT_ID = 'd5f00740-e38d-496c-818f-a7df3d6b6788';
export const TAXONOMY_OUTCOMES_MEASURED_ROOT_ID = '121e7532-2ca5-4550-8d09-a40405c4e3d1';
export const TAXONOMY_PARTICIPATION_FEE_ROOT_ID = '3E304E33-7B6B-4BAA-9CA1-39C284BD439E';
export const TAXONOMY_PRACTICE_AREA_ROOT_ID = 'cca5aea2-46b6-4641-a456-39e78f81f798';
export const TAXONOMY_PROFESSION_ROOT_ID = '14c992b1-d08f-43e3-bfd8-f9afdae1ee72';
export const TAXONOMY_REMS_ROOT_ID = '0DAF88CB-E4FF-46DC-9DC5-4A1FF0F86699';
export const TAXONOMY_SUPPORT_SOURCES_ROOT_ID = '41dde310-1477-45db-9e8a-d8e9fabeab70';
export const TAXONOMY_TIME_IN_PRACTICE_ROOT_ID = '5a16c16a-08b3-451e-ae49-4df15f3b1673';
export const TAXONOMY_BATCH_ROOT_ID = '025e8260-4d67-47cb-95f3-468a1a6e308e';
export const TAXONOMY_BATCH_MESSAGES_ID = '898b5e50-0257-4f32-9a96-6b0ff64dd1d2';
export const TAXONOMY_TAB_DELIM_SUPPORT_ID = 'BEE40EE3-BFC5-43EF-A3CD-86D0A22D591C';
export const TAXONOMY_BATCH_HELP_ROOT_ID = 'FFD46121-0974-48FA-A97D-0BC921AADB8C';

// Content tags
export const CONTENT_TAG_CME_ROOT_ID = 'a9637886-2ca2-47a4-8c65-231f9ecbf393';
export const CONTENT_TAG_PHARMACY_ROOT_ID = '0cbcb34d-2b4b-436d-ac72-ad54216c7f25';

// Search
export const DEFAULT_RESULTS_PER_PAGE = 10;
export const MAX_SEARCH_RESULTS = 100000;
export const SORT_BY_RECENTLY_ADDED = 'Timestamp';
export const SORT_BY_A_Z_TITLE = 'Title';
export const SORT_BY_START_DATE = 'StartDate';
export const SORT_BY_END_DATE = 'EndDate';
export const SORT_BY_ACTIVITY_ID = 'Id';
export const SORT_BY_INTERNAL_ID = 'InternalId';
export const SORT_BY_UAN = 'UANPharmacy';
export const SORT_BY_UAN_TECHNICIAN = 'UANPharmacyTech';

// Learner Search/Summary
export const SORT_BY_ACCEPTED = 'AcceptedCount';
export const SORT_BY_ACTIVITY_END_DATE = 'ActivityEndDate';
export const SORT_BY_ACTIVITY_START_DATE = 'ActivityStartDate';
export const SORT_BY_BOARD = 'Board';
export const SORT_BY_CLAIM_DATE = 'ClaimDate';
export const SORT_BY_LEARNER = 'LearnerCount';
export const SORT_BY_LEARNER_ACTIVITY_ID = 'ActivityId';
export const SORT_BY_LEARNER_ACTIVITY_TITLE = 'ActivityName'; // Leave as ActivityName to match the BE
export const SORT_BY_LEARNER_ID = 'LearnerId';
export const SORT_BY_LEARNER_NAME = 'LastName';
export const SORT_BY_REJECTED = 'RejectedCount';
export const SORT_BY_STATUS = 'LearnerStatus';
export const SORT_BY_SUBMITTED = 'SubmittedCount';
export const SORT_BY_TOTAL_CREDITS = 'TotalCredits';

// Learner Detail By Activity
export const SORT_BY_COMPLETION_DATE = 'CompletionDate';

// Activity close
export const ACTIVITY_RECENTLY_CLOSED_THRESHOLD = 30;

export interface IFacetAccordion {
  asArray?: boolean;
  displayValue: string;
  organizationKinds?: OrganizationKind[];
  rollupOrganizationEnums?: RollupOrganizationEnums[];
  payloadEndValue?: string;
  payloadIdValue?: string;
  payloadNameValue?: string;
  payloadStartValue?: string;
  payloadValue?: string;
  rangeEndPlaceholderValue?: string;
  rangeEndValue?: string;
  rangeStartPlaceholderValue?: string;
  rangeStartValue?: string;
  reduxAction?: VoidFunction;
  reduxSelector?: Selector<AppState, any>;
  responseValue?: string;
  transformTypeaheadData?: (data: any) => ITypeaheadOption[];
  type: FacetTypeEnum;
}

const allOrganizationKinds: OrganizationKinds[] = [
  OrganizationKinds.ACCREDITOR,
  OrganizationKinds.BOARD,
  OrganizationKinds.PROVIDER,
  OrganizationKinds.SPECIAL_ORGANIZATION,
  OrganizationKinds.STAFF,
];
// The given facet will render when the user's `organizationKind` is included in the respective array.
export const withoutBoard: OrganizationKinds[] = [
  OrganizationKinds.ACCREDITOR,
  OrganizationKinds.PROVIDER,
  OrganizationKinds.SPECIAL_ORGANIZATION,
  OrganizationKinds.STAFF,
];
const withoutProvider: OrganizationKinds[] = [
  OrganizationKinds.ACCREDITOR,
  OrganizationKinds.BOARD,
  OrganizationKinds.SPECIAL_ORGANIZATION,
  OrganizationKinds.STAFF,
];

export const accreditorOrStaff: OrganizationKinds[] = [
  OrganizationKinds.ACCREDITOR,
  OrganizationKinds.SPECIAL_ORGANIZATION,
  OrganizationKinds.STAFF,
];

export const staffOnly: OrganizationKinds[] = [OrganizationKinds.SPECIAL_ORGANIZATION, OrganizationKinds.STAFF];

// More privileges CRUD.
export const staffProvider: OrganizationKinds[] = [
  OrganizationKinds.PROVIDER,
  OrganizationKinds.SPECIAL_ORGANIZATION,
  OrganizationKinds.STAFF,
];

// Less privileges CRUD.
export const boardAccreditor = [OrganizationKinds.BOARD, OrganizationKinds.ACCREDITOR];

export const INDIVIDUAL_ACTIVITY_SEARCH_ACCORDION_TITLES: IFacetAccordion[] = [
  {
    displayValue: 'Individual Activity',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'isIndividualActivity',
    responseValue: 'IsIndividualActivity',
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'Individual Activity Organization Name',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'iaOrganizationName',
    responseValue: 'IaOrganizationName',
    type: FacetTypeEnum.Text,
  },
];

// Activity Summary
export const ACTIVITY_SEARCH_ACCORDION_TITLES: IFacetAccordion[] = [
  {
    displayValue: 'Activity Format',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'type', // BE calls it `type`
    responseValue: 'Type',
    type: FacetTypeEnum.Array,
  },
  // These are called `Collaborations` in the spreadsheet.
  // ACCME wants these split out into MOC/MIPS/REMS.
  {
    displayValue: 'MOC',
    organizationKinds: withoutBoard,
    payloadValue: 'isMoc',
    responseValue: 'IsMoc',
    rollupOrganizationEnums: [RollupOrganizationEnums.PARS, RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'MIPS',
    organizationKinds: withoutBoard,
    payloadValue: 'isMips',
    responseValue: 'IsMips',
    rollupOrganizationEnums: [RollupOrganizationEnums.PARS, RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'IPCE',
    organizationKinds: withoutBoard,
    payloadValue: 'isIpce',
    responseValue: 'IsIpce',
    rollupOrganizationEnums: [RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'REMS',
    organizationKinds: withoutBoard,
    payloadValue: 'isRems',
    responseValue: 'IsRems',
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'REMS Program',
    organizationKinds: withoutBoard,
    payloadValue: 'rems',
    responseValue: 'REMS/Name',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Activity Status',
    organizationKinds: withoutBoard,
    payloadValue: 'statuses', // Keep the plural when sending a query.
    responseValue: 'Status',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Activity Date Range',
    organizationKinds: allOrganizationKinds,
    payloadEndValue: 'endDate',
    payloadStartValue: 'startDate',
    type: FacetTypeEnum.Date,
  },
  {
    displayValue: 'Certifying Boards',
    organizationKinds: withoutBoard,
    payloadValue: 'boards',
    responseValue: 'Boards/Name', // BE sends this as the value.
    rollupOrganizationEnums: [RollupOrganizationEnums.PARS, RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Measured Outcomes',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'MeasuredOutcomes',
    responseValue: 'MeasuredOutcomes/Name', // Keep BE name
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Commercial Support Received',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'isCommercialSupport',
    responseValue: 'IsCommercialSupport',
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'Monetary Support Received',
    organizationKinds: withoutBoard,
    payloadValue: 'isMonetarySupport',
    responseValue: 'IsMonetarySupport',
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'In-Kind Support Received',
    organizationKinds: withoutBoard,
    payloadValue: 'isInKindSupport',
    responseValue: 'IsInKindSupport',
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'Monetary Support Amount',
    organizationKinds: withoutBoard,
    payloadValue: 'amount',
    rangeEndPlaceholderValue: 'To',
    rangeEndValue: 'monetarySupportEnd',
    rangeStartPlaceholderValue: 'From',
    rangeStartValue: 'monetarySupportStart',
    type: FacetTypeEnum.Range,
  },
  {
    displayValue: 'Providership',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'Providership',
    responseValue: 'Providership',
    type: FacetTypeEnum.Array,
  },
  {
    asArray: true,
    displayValue: 'Joint Provider Name',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'jointProviders', // Sends an array of one.
    reduxSelector: activitySearchJointProviderFacetsSelector,
    responseValue: 'JointProviders/Name',
    transformTypeaheadData: (data) => keys(data).map((id: string) => ({ id, label: data[id] })),
    type: FacetTypeEnum.Typeahead,
  },
  {
    displayValue: 'Included in CME Passport',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'isCmeFinder',
    responseValue: 'IsCmeFinder',
    rollupOrganizationEnums: [RollupOrganizationEnums.PARS, RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'Registration',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'registration',
    responseValue: 'Registration',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Fee',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'participationFee',
    responseValue: 'ParticipationFee',
    type: FacetTypeEnum.Array,
  },
  // These are called `Locations` in the spreadsheet.
  // ACCME wants these split out into State/City/Country.
  {
    asArray: true,
    displayValue: 'State',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'states', // FE sends plural, Sends an array of one.
    reduxSelector: activitySearchStateFacetsSelector,
    responseValue: 'State', // BE is singular
    transformTypeaheadData: (data) => keys(data).map((id: string) => ({ id, label: data[id] })),
    type: FacetTypeEnum.Typeahead,
  },
  {
    displayValue: 'City',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'cities', // FE sends plural
    responseValue: 'City', // BE is singular
    type: FacetTypeEnum.Text,
  },
  {
    asArray: true,
    displayValue: 'Country',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'countries', // FE sends plural, Sends an array of one.
    reduxSelector: activityCountryFacetsSelector,
    responseValue: 'Country', // BE is singular
    transformTypeaheadData: (data) => keys(data).map((id: string) => ({ id, label: data[id] })),
    type: FacetTypeEnum.Typeahead,
  },
  {
    displayValue: 'Educational Delivery Method', // Learning Method
    organizationKinds: allOrganizationKinds,
    payloadValue: 'locationTypes',
    responseValue: 'LocationType',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Commendation',
    organizationKinds: withoutBoard,
    payloadValue: 'commendationCriteria',
    responseValue: 'CommendationCriteria/Name',
    rollupOrganizationEnums: [RollupOrganizationEnums.PARS, RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Credit Types',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'creditTypes',
    responseValue: 'CreditTypes/Name', // BE sends this as the value.
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Activity Entry Method',
    organizationKinds: withoutBoard,
    payloadValue: 'inputMethod', // BE name differs than display name
    responseValue: 'InputMethod',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Learner Counts Reported',
    organizationKinds: withoutBoard,
    payloadValue: 'hasAggregateLearners',
    responseValue: 'HasAggregateLearners',
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'REMS Program',
    organizationKinds: withoutBoard,
    payloadValue: 'rems',
    responseValue: 'REMSDetails/Name',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'MOC Credit Types',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'creditTypeWithBoards', // Note pluralization
    responseValue: 'MoCCreditTypes/Name', // Keep weird casing
    rollupOrganizationEnums: [RollupOrganizationEnums.PARS, RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Practice Areas',
    organizationKinds: withoutProvider,
    payloadValue: 'practiceAreas',
    responseValue: 'PracticeAreas/Name',
    rollupOrganizationEnums: [RollupOrganizationEnums.PARS, RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Provider Name',
    organizationKinds: withoutProvider,
    payloadValue: 'providerName',
    responseValue: 'ProviderName',
    type: FacetTypeEnum.Text,
  },
  {
    displayValue: 'Provider ID',
    organizationKinds: withoutProvider,
    payloadValue: 'businessId',
    responseValue: 'BusinessId', // Note: use Business Id but show Provider Id to the user
    type: FacetTypeEnum.Text,
  },
  {
    displayValue: 'Invoiced for REMS',
    organizationKinds: staffOnly,
    payloadValue: 'isInvoiced',
    responseValue: 'IsInvoiced',
    type: FacetTypeEnum.Boolean,
  },
];

// Learner Summary
export const LEARNER_ACCORDION_TITLES: IFacetAccordion[] = [
  {
    displayValue: 'Activity Format',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'activityFormats',
    responseValue: 'ActivityFormat', // BE is singular
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Providership',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'providership',
    responseValue: 'Providership',
    type: FacetTypeEnum.Array,
  },
  {
    asArray: true,
    displayValue: 'Joint Provider Name',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'jointProviders',
    reduxSelector: learnerJointProviderSearchFacetsSelector,
    responseValue: 'JointProviders/Name',
    transformTypeaheadData: (data) => keys(data).map((id: string) => ({ id, label: data[id] })),
    type: FacetTypeEnum.Typeahead,
  },
  // These are called `Collaborations` in the spreadsheet.
  // ACCME wants these split out into MOC/MIPS/REMS.
  {
    displayValue: 'MOC',
    organizationKinds: withoutBoard,
    payloadValue: 'isMoc',
    responseValue: 'IsMoc',
    rollupOrganizationEnums: [RollupOrganizationEnums.PARS, RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'MIPS',
    organizationKinds: withoutBoard,
    payloadValue: 'isMips',
    responseValue: 'IsMips',
    rollupOrganizationEnums: [RollupOrganizationEnums.PARS, RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'REMS',
    organizationKinds: withoutBoard,
    payloadValue: 'rems',
    responseValue: 'REMS/Name',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Activity Status',
    organizationKinds: withoutBoard,
    payloadValue: 'activityStatuses', // FE sends plural
    responseValue: 'ActivityStatus', // BE gets singular
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Activity Date Range',
    organizationKinds: allOrganizationKinds,
    payloadEndValue: 'activityEndDate',
    payloadStartValue: 'activityStartDate',
    type: FacetTypeEnum.Date,
  },
  {
    displayValue: 'Learner Submission Dates',
    organizationKinds: allOrganizationKinds,
    payloadEndValue: 'learnerSubmissionEndDate',
    payloadStartValue: 'learnerSubmissionStartDate',
    type: FacetTypeEnum.Date,
  },
  {
    displayValue: 'Learner Completion Dates',
    organizationKinds: allOrganizationKinds,
    payloadEndValue: 'learnerCompletionEndDate',
    payloadStartValue: 'learnerCompletionStartDate',
    type: FacetTypeEnum.Date,
  },
  {
    displayValue: 'Certifying Boards',
    organizationKinds: withoutBoard,
    payloadValue: 'boards',
    responseValue: 'Boards/Name',
    rollupOrganizationEnums: [RollupOrganizationEnums.PARS, RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'State Medical Boards',
    organizationKinds: withoutBoard,
    payloadValue: 'stateBoards',
    responseValue: 'StateBoards/Name',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Learner Status',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'completionStatuses',
    responseValue: 'CompletionStatuses/Name', // Keep naming difference between label and BE value
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Measured Outcomes',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'measuredOutcomes',
    responseValue: 'MeasuredOutcomes/Name',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Commercial Support Received',
    organizationKinds: withoutProvider,
    payloadValue: 'isCommercialSupport',
    responseValue: 'IsCommercialSupport',
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'Included in CME Finder',
    organizationKinds: withoutProvider,
    payloadValue: 'isCmeFinder',
    responseValue: 'IsCmeFinder',
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'Registration',
    organizationKinds: withoutProvider,
    payloadValue: 'registrations', // FE sends plural
    responseValue: 'Registration', // BE gets singular
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Fee',
    organizationKinds: withoutProvider,
    payloadValue: 'participationFee',
    responseValue: 'ParticipationFee',
    type: FacetTypeEnum.Array,
  },
  // These are called `Locations` in the spreadsheet.
  // ACCME wants these split out into State/City/Country.
  {
    asArray: true,
    displayValue: 'State',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'states', // FE sends plural, Sends an array of one.
    reduxSelector: learnerStateSearchFacetsSelector,
    responseValue: 'State', // BE is singular
    transformTypeaheadData: (data) => keys(data).map((id: string) => ({ id, label: data[id] })),
    type: FacetTypeEnum.Typeahead,
  },
  {
    displayValue: 'City',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'cities', // FE sends plural
    responseValue: 'City', // BE is singular
    type: FacetTypeEnum.Text,
  },
  {
    asArray: true,
    displayValue: 'Country',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'countries', // FE sends plural, Sends an array of one.
    reduxSelector: learnerCountrySearchFacetsSelector,
    responseValue: 'Country', // BE is singular
    transformTypeaheadData: (data) => keys(data).map((id: string) => ({ id, label: data[id] })),
    type: FacetTypeEnum.Typeahead,
  },
  {
    displayValue: 'Learner Entry Method',
    organizationKinds: withoutBoard,
    payloadValue: 'completionInputMethods',
    responseValue: 'CompletionInputMethods/Name',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'MOC Credit Types',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'creditTypes',
    responseValue: 'CreditTypes/MocName',
    rollupOrganizationEnums: [RollupOrganizationEnums.PARS, RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Practice Areas',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'practiceAreas',
    responseValue: 'PracticeAreas/Name',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Provider Name',
    organizationKinds: withoutProvider,
    payloadValue: 'providerName',
    responseValue: 'ProviderName',
    type: FacetTypeEnum.Text,
  },
  {
    displayValue: 'Provider ID',
    organizationKinds: withoutProvider,
    payloadValue: 'businessId',
    responseValue: 'BusinessId',
    type: FacetTypeEnum.Text,
  },
];

// Learner Search
export const LEARNER_SEARCH_ACCORDION_TITLES: IFacetAccordion[] = [
  {
    displayValue: 'Activity Format',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'activityFormats',
    responseValue: 'ActivityFormat', // BE is singular
    type: FacetTypeEnum.Array,
  },
  // These are called `Collaborations` in the spreadsheet.
  // ACCME wants these split out into MOC/MIPS/REMS.
  {
    displayValue: 'MOC',
    organizationKinds: withoutBoard,
    payloadValue: 'isMoc',
    responseValue: 'IsMoc',
    rollupOrganizationEnums: [RollupOrganizationEnums.PARS, RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'MIPS',
    organizationKinds: withoutBoard,
    payloadValue: 'isMips',
    responseValue: 'IsMips',
    rollupOrganizationEnums: [RollupOrganizationEnums.PARS, RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'REMS',
    organizationKinds: withoutBoard,
    payloadValue: 'isRems',
    responseValue: 'IsRems',
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'REMS Program',
    organizationKinds: withoutBoard,
    payloadValue: 'rems',
    responseValue: 'REMSName',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Learner Submission Dates',
    organizationKinds: allOrganizationKinds,
    payloadEndValue: 'learnerSubmissionEndDate',
    payloadStartValue: 'learnerSubmissionStartDate',
    type: FacetTypeEnum.Date,
  },
  {
    displayValue: 'Learner Completion Dates',
    organizationKinds: allOrganizationKinds,
    payloadEndValue: 'learnerCompletionEndDate',
    payloadStartValue: 'learnerCompletionStartDate',
    type: FacetTypeEnum.Date,
  },
  {
    displayValue: 'Certifying Boards',
    organizationKinds: withoutBoard,
    payloadValue: 'boards',
    responseValue: 'BoardName', // BE sends this as the value.
    rollupOrganizationEnums: [RollupOrganizationEnums.PARS, RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'State Medical Boards',
    organizationKinds: withoutBoard,
    payloadValue: 'states', // Keep this name
    responseValue: 'StateName',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Learner Status', // Keep display name difference vs BE name
    organizationKinds: allOrganizationKinds,
    payloadValue: 'learnerStatuses',
    responseValue: 'LearnerStatus', // BE is singular
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Learner Entry Method',
    organizationKinds: withoutBoard,
    payloadValue: 'learnerEntryMethods',
    responseValue: 'LearnerEntryMethod', // BE is singular
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'MOC Credit Types',
    organizationKinds: withoutBoard,
    payloadValue: 'creditTypes',
    responseValue: 'Credits/MocName', // Note the naming difference
    rollupOrganizationEnums: [RollupOrganizationEnums.PARS, RollupOrganizationEnums.JA],
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Learner First Name',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'firstName',
    responseValue: 'FirstName',
    type: FacetTypeEnum.Text,
  },
  {
    displayValue: 'Learner Last Name',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'lastName',
    responseValue: 'LastName',
    type: FacetTypeEnum.Text,
  },
  {
    displayValue: 'Learner ID',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'learnerId',
    responseValue: 'LearnerId',
    type: FacetTypeEnum.Text,
  },
  {
    displayValue: 'Activity Title',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'activityName',
    responseValue: 'ActivityTitle',
    type: FacetTypeEnum.Text,
  },
  {
    displayValue: 'Activity ID',
    organizationKinds: allOrganizationKinds,
    payloadValue: 'activityId',
    responseValue: 'ActivityId',
    type: FacetTypeEnum.Text,
  },
  {
    displayValue: 'Internal ID',
    organizationKinds: withoutBoard,
    payloadValue: 'internalId',
    responseValue: 'InternalId',
    type: FacetTypeEnum.Text,
  },
  {
    displayValue: 'Provider Name',
    organizationKinds: withoutProvider,
    payloadValue: 'providerName',
    responseValue: 'ProviderName',
    type: FacetTypeEnum.Text,
  },
  {
    displayValue: 'Provider ID',
    organizationKinds: withoutProvider,
    payloadValue: 'providerId',
    responseValue: 'ProviderId',
    type: FacetTypeEnum.Text,
  },
];

// Upload files.
export const DEFAULT_UPLOAD_TYPES: FileUpload[] = [
  FileTypesEnum.CSV,
  FileTypesEnum.PNG,
  FileTypesEnum.TAB,
  FileTypesEnum.TSV,
  FileTypesEnum.TXT,
  FileTypesEnum.XLS,
  FileTypesEnum.XLSX,
  FileTypesEnum.XML,
];
export const BATCH_UPLOAD_FILE_TYPES: Array<
  FileTypesEnum.XLSX | FileTypesEnum.CSV | FileTypesEnum.TXT | FileTypesEnum.XML | FileTypesEnum.TSV | FileTypesEnum.TAB
> = [FileTypesEnum.XLSX, FileTypesEnum.CSV, FileTypesEnum.TXT, FileTypesEnum.XML, FileTypesEnum.TSV, FileTypesEnum.TAB];

export const LEARNER_VALIDATION_TOOL_UPLOAD_FILE_TYPES: Array<FileTypesEnum.XLSX> = [FileTypesEnum.XLSX];

export const SYNONYM_UPLOAD_FILE_TYPES: Array<FileTypesEnum.XLSX> = [FileTypesEnum.XLSX];

/***
 * Tags
 * @description - These are used as well-known taxonomy ids that do not change.
 *                Taxonomy term ids have the potential to change so we use these instead to rely on a constant lookup.
 */
export enum TAGS_ENUM {
  ACTIVITY_CREDIT_TYPE__AAPA_CATEGORY1 = 'ACTIVITY_CREDIT_TYPE__AAPA_CATEGORY1',
  ACTIVITY_CREDIT_TYPE__AMA_PRA_CATEGORY1 = 'ACTIVITY_CREDIT_TYPE__AMA_PRA_CATEGORY1',
  ACTIVITY_CREDIT_TYPE__CONTROLLED_SUBSTANCE = 'ACTIVITY_CREDIT_TYPE__CONTROLLED_SUBSTANCE',
  ACTIVITY_CREDIT_TYPE__DENTISTRY = 'ACTIVITY_CREDIT_TYPE__DENTISTRY',
  ACTIVITY_CREDIT_TYPE__DIETIC_CPEUS = 'ACTIVITY_CREDIT_TYPE__DIETIC_CPEUS',
  ACTIVITY_CREDIT_TYPE__IPCE_CREDITS = 'ACTIVITY_CREDIT_TYPE__IPCE_CREDITS',
  ACTIVITY_CREDIT_TYPE__NURSING = 'ACTIVITY_CREDIT_TYPE__NURSING',
  ACTIVITY_CREDIT_TYPE__OPTOMETRY = 'ACTIVITY_CREDIT_TYPE__OPTOMETRY',
  ACTIVITY_CREDIT_TYPE__PAIN_MANAGEMENT = 'ACTIVITY_CREDIT_TYPE__PAIN_MANAGEMENT',
  ACTIVITY_CREDIT_TYPE__PHARMACY = 'ACTIVITY_CREDIT_TYPE__PHARMACY',
  ACTIVITY_CREDIT_TYPE__PRESCRIBING_OPIOD_EDUCATION = 'ACTIVITY_CREDIT_TYPE__PRESCRIBING_OPIOD_EDUCATION',
  ACTIVITY_CREDIT_TYPE__PSYCHOLOGY = 'ACTIVITY_CREDIT_TYPE__PSYCHOLOGY',
  ACTIVITY_CREDIT_TYPE__SOCIAL_WORK = 'ACTIVITY_CREDIT_TYPE__SOCIAL_WORK',
  ACTIVITY_CREDIT_TYPE__ANCC_CONTACT_HOURS = 'ACTIVITY_CREDIT_TYPE__ANCC_CONTACT_HOURS',
  ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL1 = 'ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL1',
  ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL2 = 'ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL2',
  ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL3 = 'ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL3',
  ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL4 = 'ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL4',
  ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL5 = 'ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL5',
  BATCH__MESSAGES__AFTER_UPLOAD = 'BATCH__MESSAGES__AFTER_UPLOAD',
  BATCH__MESSAGES__FILE_TOO_BIG = 'BATCH__MESSAGES__FILE_TOO_BIG',
  BATCH__WARN_IF_NO_STATUS_UPDATE_SECONDS = 'BATCH__WARN_IF_NO_STATUS_UPDATE_SECONDS',
  BATCH__MESSAGES__WARN_IF_NO_STATUS_UPDATE_MESSAGE = 'BATCH__MESSAGES__WARN_IF_NO_STATUS_UPDATE_MESSAGE',
  LEARNER_TYPES__ALLIED_DENTAL_STAFF = 'LEARNER_TYPES__ALLIED_DENTAL_STAFF',
  LEARNER_TYPES__DENTISTS = 'LEARNER_TYPES__DENTISTS',
  LEARNER_TYPES__DIETETIC_TECHNICIANS_REGISTERED = 'LEARNER_TYPES__DIETETIC_TECHNICIANS_REGISTERED',
  LEARNER_TYPES__NURSES = 'LEARNER_TYPES__NURSES',
  LEARNER_TYPES__OPTOMETRISTS = 'LEARNER_TYPES__OPTOMETRISTS',
  LEARNER_TYPES__OTHER_LEARNERS = 'LEARNER_TYPES__OTHER_LEARNERS',
  LEARNER_TYPES__PHARMACISTS = 'LEARNER_TYPES__PHARMACISTS',
  LEARNER_TYPES__PHARMACY_TECHNICIANS = 'LEARNER_TYPES__PHARMACY_TECHNICIANS',
  LEARNER_TYPES__PHYSICIAN_ASSISTANTS = 'LEARNER_TYPES__PHYSICIAN_ASSISTANTS',
  LEARNER_TYPES__PHYSICIANS = 'LEARNER_TYPES__PHYSICIANS',
  LEARNER_TYPES__PSYCHOLOGISTS = 'LEARNER_TYPES__PSYCHOLOGISTS',
  LEARNER_TYPES__REGISTERED_DIETITIANS = 'LEARNER_TYPES__REGISTERED_DIETITIANS',
  LEARNER_TYPES__SOCIAL_WORKERS = 'LEARNER_TYPES__SOCIAL_WORKERS',
  MEASURED_OUTCOMES__COMMUNITY_POPULATION_HEALTH = 'MEASURED_OUTCOMES__COMMUNITY_POPULATION_HEALTH',
  MEASURED_OUTCOMES__COMMUNITY_POPULATION_HEALTH__OBJECTIVE = 'MEASURED_OUTCOMES__COMMUNITY_POPULATION_HEALTH__OBJECTIVE',
  MEASURED_OUTCOMES__COMMUNITY_POPULATION_HEALTH__SUBJECTIVE = 'MEASURED_OUTCOMES__COMMUNITY_POPULATION_HEALTH__SUBJECTIVE',
  MEASURED_OUTCOMES__LEARNER_COMPETENCE = 'MEASURED_OUTCOMES__LEARNER_COMPETENCE',
  MEASURED_OUTCOMES__LEARNER_COMPETENCE__OBJECTIVE = 'MEASURED_OUTCOMES__LEARNER_COMPETENCE__OBJECTIVE',
  MEASURED_OUTCOMES__LEARNER_COMPETENCE__SUBJECTIVE = 'MEASURED_OUTCOMES__LEARNER_COMPETENCE__SUBJECTIVE',
  MEASURED_OUTCOMES__LEARNER_KNOWLEDGE = 'MEASURED_OUTCOMES__LEARNER_KNOWLEDGE',
  MEASURED_OUTCOMES__LEARNER_KNOWLEDGE__OBJECTIVE = 'MEASURED_OUTCOMES__LEARNER_KNOWLEDGE__OBJECTIVE',
  MEASURED_OUTCOMES__LEARNER_KNOWLEDGE__SUBJECTIVE = 'MEASURED_OUTCOMES__LEARNER_KNOWLEDGE__SUBJECTIVE',
  MEASURED_OUTCOMES__LEARNER_PERFORMANCE = 'MEASURED_OUTCOMES__LEARNER_PERFORMANCE',
  MEASURED_OUTCOMES__LEARNER_PERFORMANCE__OBJECTIVE = 'MEASURED_OUTCOMES__LEARNER_PERFORMANCE__OBJECTIVE',
  MEASURED_OUTCOMES__LEARNER_PERFORMANCE__SUBJECTIVE = 'MEASURED_OUTCOMES__LEARNER_PERFORMANCE__SUBJECTIVE',
  MEASURED_OUTCOMES__PATIENT_HEALTH = 'MEASURED_OUTCOMES__PATIENT_HEALTH',
  MEASURED_OUTCOMES__PATIENT_HEALTH__OBJECTIVE = 'MEASURED_OUTCOMES__PATIENT_HEALTH__OBJECTIVE',
  MEASURED_OUTCOMES__PATIENT_HEALTH__SUBJECTIVE = 'MEASURED_OUTCOMES__PATIENT_HEALTH__SUBJECTIVE',
  SPECIALTY_BOARD_CREDIT_TYPES__ABPATH_MOC_SAM = 'SPECIALTY_BOARD_CREDIT_TYPES__ABPATH_MOC_SAM',
  SPECIALTY_BOARD_CREDIT_TYPES__ABS_MOC = 'SPECIALTY_BOARD_CREDIT_TYPES__ABS_MOC',
  SPECIALTY_BOARD_CREDIT_TYPES__ACCREDITED_CME = 'SPECIALTY_BOARD_CREDIT_TYPES__ACCREDITED_CME',
  SPECIALTY_BOARD_CREDIT_TYPES__IMPROVEMENT_IN_MEDICAL_PRACTICE = 'SPECIALTY_BOARD_CREDIT_TYPES__IMPROVEMENT_IN_MEDICAL_PRACTICE',
  SPECIALTY_BOARD_CREDIT_TYPES__LIFELONG_LEARNING = 'SPECIALTY_BOARD_CREDIT_TYPES__LIFELONG_LEARNING',
  SPECIALTY_BOARD_CREDIT_TYPES__LIFELONG_LEARNING_AND_SELF_ASSESSMENT = 'SPECIALTY_BOARD_CREDIT_TYPES__LIFELONG_LEARNING_AND_SELF_ASSESSMENT',
  SPECIALTY_BOARD_CREDIT_TYPES__MEDICAL_KNOWLEDGE = 'SPECIALTY_BOARD_CREDIT_TYPES__MEDICAL_KNOWLEDGE',
  SPECIALTY_BOARD_CREDIT_TYPES__PATIENT_SAFETY = 'SPECIALTY_BOARD_CREDIT_TYPES__PATIENT_SAFETY',
  SPECIALTY_BOARD_CREDIT_TYPES__PRACTICE_ASSESSMENT = 'SPECIALTY_BOARD_CREDIT_TYPES__PRACTICE_ASSESSMENT',
  SPECIALTY_BOARD_CREDIT_TYPES__SELF_ASSESSMENT = 'SPECIALTY_BOARD_CREDIT_TYPES__SELF_ASSESSMENT',
  SPECIALTY_BOARD_CREDIT_TYPES__SELF_ASSESSMENT_MODULE_PART2 = 'SPECIALTY_BOARD_CREDIT_TYPES__SELF_ASSESSMENT_MODULE_PART2',
  STAFF = 'STAFF',
  BATCH_HELP__LEARNER_EXCEL_INSTRUCTIONS = 'BATCH_HELP__LEARNER_EXCEL_INSTRUCTIONS',
  BATCH_HELP__LEARNER_XML_INSTRUCTIONS = 'BATCH_HELP__LEARNER_XML_INSTRUCTIONS',
  BATCH_HELP__PARS_ACTIVITY_EXCEL_INSTRUCTIONS = 'BATCH_HELP__PARS_ACTIVITY_EXCEL_INSTRUCTIONS',
  BATCH_HELP__PARS_ACTIVITY_XML_INSTRUCTIONS = 'BATCH_HELP__PARS_ACTIVITY_XML_INSTRUCTIONS',
  BATCH_HELP__JA_ACTIVITY_XML_INSTRUCTIONS = 'BATCH_HELP__JA_ACTIVITY_XML_INSTRUCTIONS',
  BATCH_HELP__JA_ACTIVITY_EXCEL_INSTRUCTIONS = 'BATCH_HELP__JA_ACTIVITY_EXCEL_INSTRUCTIONS',
}

export const ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL_TAGS = [
  TAGS_ENUM.ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL1,
  TAGS_ENUM.ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL2,
  TAGS_ENUM.ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL3,
  TAGS_ENUM.ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL4,
  TAGS_ENUM.ACTIVITY_CREDIT_TYPE__OB_CE_CREDIT_LEVEL5,
];

export enum ACTIVITY_TYPE_ABBREVIATION_ENUM {
  C = 'C', // Live Course
  RSS = 'RSS', // Regularly Scheduled Series
  IL = 'IL', // Internet Live Course
  EM = 'EM', // Enduring Material
  IEM = 'IEM', // Internet Activity Enduring Material
  JN = 'JN', // Journal-based CE
  MR = 'MR', // Manuscript Review
  TIW = 'TIW', // Test-Item Writing
  CML = 'CML', // Committee Learning
  PI = 'PI', // Performance/Quality Improvement
  ISL = 'ISL', // Internet Searching and Learning
  LFT = 'LFT', // Learning from Teaching
  O = 'O', // Other/Blended Learning
}

// Used for Completion Edit to identify the completion record credit type
export enum CreditRouteKeys {
  CreditsByCertifyingBoard = 'creditsbycertifyingboard',
  CreditsByRems = 'creditsbyrems',
  CreditsByStateOrProvince = 'creditsbystateorprovince',
}

export const OrganizationKindPowerBIWorkspaceId = {
  [OrganizationKinds.ACCREDITOR]: Configuration.powerBIAccreditorWorkspaceId,
  [OrganizationKinds.BOARD]: Configuration.powerBIBoardWorkspaceId,
  [OrganizationKinds.PROVIDER]: Configuration.powerBIProviderWorkspaceId,
  [OrganizationKinds.SPECIAL_ORGANIZATION]: Configuration.powerBIStaffWorkspaceId,
};
export const OrganizationKindPowerBIJaWorkspaceId = {
  [OrganizationKinds.ACCREDITOR]: Configuration.powerBIAccreditorWorkspaceId,
  [OrganizationKinds.BOARD]: Configuration.powerBIBoardWorkspaceId,
  [OrganizationKinds.PROVIDER]: Configuration.powerBIJAProviderWorkspaceId,
  [OrganizationKinds.SPECIAL_ORGANIZATION]: Configuration.powerBIStaffWorkspaceId,
};

export const OrganizationKindPowerBINarsWorkspaceId = {
  [OrganizationKinds.ACCREDITOR]: Configuration.powerBiNarsAccreditorsWorkspaceId,
  [OrganizationKinds.PROVIDER]: Configuration.powerBiNarsProvidersWorkspaceId,
};

export const ARMATURE_CUSTOMER_QUERY_PARAM = 'CustomerId';

// Messages
export const MESSAGE_CANNOT_EDIT_DELETE_OLD_COMPLETIONS =
  'Deadline for deleting participant data has passed. PARS will not accept deletions of participant records past the date set by the specialty board.';
export const MESSAGE_DEFAULT_FILE_HAS_TOO_MANY_ROWS_TO_PROCESS = 'File has too many rows to process.';
export const MESSAGE_DEFAULT_UPLOAD_COMPLETE = 'Upload complete.';

export const DELIVERY_METHOD_REQUIRED_ACTIVITY_TYPES: string[] = ['C', 'RSS', 'EM'];

export const MESSAGE_CANNOT_REOPEN_AFTER_30_DAYS =
  'Activities that have been closed for over 30 days cannot be reopened. If you need to edit this activity, please use the Contact Support form. You can still report individual learners for Closed activities.';

export const SP_IDENTIFY_STUCK_LCCS = 'sp_IdentifyStuckLccs';
export const SP_IDENTIFY_STUCK_MOCS = 'sp_IdentifyStuckMocs';
export const SP_QUEUE_MOC_ACTIVITY = 'sp_QueueMocActivity';
export const SP_QUEUE_MOC_LEARNER = 'sp_QueueMocLearner';

export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
