import { ReactElement } from 'react';

export interface IPageTitle {
  eyebrow: string;
  title: string;
}

export const PageTitle = (props: IPageTitle): ReactElement => {
  const { eyebrow, title } = props;
  return (
    <div className="form-title-container">
      <h4 className="title">
        <div className="eyebrow">{eyebrow}</div>
        {title}
      </h4>
    </div>
  );
};
