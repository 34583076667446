import { API } from 'core';
import { ServerRoute } from 'core/api';
import { CommandInfo, CommmandInvokactionResult } from 'core/models';

export class AdminCommandsService {
  public static async listAllCommandsAsync(): Promise<string[]> {
    const route: ServerRoute = ServerRoute.forAction('AdminCommands');
    return await API.get(route);
  }

  public static async getCommandInfoAsync(commandName: string): Promise<CommandInfo> {
    const route: ServerRoute = ServerRoute.forAction('AdminCommands', commandName);
    return await API.get(route);
  }

  public static async invokeCommandAsync(
    commandName: string,
    parameters: Record<string, string | number | boolean>,
  ): Promise<CommmandInvokactionResult> {
    const route: ServerRoute = ServerRoute.forAction('AdminCommands', commandName);
    return await API.post(route, parameters);
  }
}
