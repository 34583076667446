import { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SaveAltRounded } from '@material-ui/icons';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { Dictionary } from 'ts-essentials';
import noop from 'lodash/noop';
import sortBy from 'lodash/sortBy';

// Components
import { LoadingCards } from 'components/LoadingCard';

// Styles
import './learnervalidationuploadhistory.module.scss';

// Store + Core
import { getLeaernerValidationProviderFilesById } from 'store/providerFiles/actions';
import { learnerValidationProviderFilesSelector, providerFilesLoadingSelector } from 'store/providerFiles/selectors';
import { ILeaernerValidationProviderFile, IProviderFileChild } from 'core/models';
import { downloadLearnerValidationProviderFile } from 'store/fileDownload/actions';
import { BatchProcessRefreshTime } from 'core/enums';
import { BatchFileProgress, BatchTaxonomyLoader } from 'components/Batch/BatchFileProgress';

export const BatchLearnerValidationHistoryDetail = (): ReactElement => {
  const [files, setFiles] = useState<ILeaernerValidationProviderFile[]>(null);
  const dispatch = useDispatch();
  const interval = useRef(null);

  // Redux state.
  const learnerValidationFiles: ILeaernerValidationProviderFile[] = useSelector(learnerValidationProviderFilesSelector);
  const isLoading: boolean = useSelector(providerFilesLoadingSelector);

  const GetHistoryData = () => {
    const getAndSetLearnerValidationFiles = async (): Promise<void> => {
      await dispatch(getLeaernerValidationProviderFilesById());
    };
    getAndSetLearnerValidationFiles().then(noop);
  };

  useEffect(() => {
    return () => {
      if (interval.current) {
        clearTimeout(interval.current);
      }
    };
  }, []);

  // API call.
  useEffect(() => {
    if (!interval.current) {
      GetHistoryData();
      interval.current = setInterval(() => {
        GetHistoryData();
      }, BatchProcessRefreshTime.timer);
    } else if (learnerValidationFiles) {
      setFiles(learnerValidationFiles);
      const isFilesProcessed =
        files && files.length && files.filter((file) => file.relatedProviderFiles.length > 0).length === files.length;
      if (isFilesProcessed) {
        clearInterval(interval.current);
      }
    }
  }, [dispatch, files, learnerValidationFiles]);

  // Render something while initially loading.
  if (isLoading && !files?.length) return <LoadingCards />;

  // Null check.
  if (!files?.length) return null;

  // Strip the time off of each date entry for grouping.
  files.forEach(
    (file: ILeaernerValidationProviderFile): string => (file.date = moment(file.updatedDate).format('MM/DD/YYYY')),
  );

  // Group the result by month/date/year.
  const filesByDate: Dictionary<ILeaernerValidationProviderFile[]> = groupBy(files, 'date');

  // Sort them by most recent at the top.
  const orderedDates = {};
  Object.keys(filesByDate)
    .sort(function (a: string, b: string) {
      return new Date(a).valueOf() - new Date(b).valueOf();
    })
    .forEach((key: string): void => {
      orderedDates[key] = filesByDate[key];
    });

  const handleDownload = (fileName, id): void => {
    event.preventDefault();
    dispatch(downloadLearnerValidationProviderFile({ fileName, id }));
  };

  const dateKeys: string[] = Object.keys(orderedDates).reverse();

  return (
    <>
      <BatchTaxonomyLoader />
      {dateKeys.map(
        (date: string): ReactElement => {
          const formattedDate: string = moment(date).format('MMM D, YYYY');

          return (
            <div className="history-upload" key={formattedDate}>
              <h5>{formattedDate}</h5>
              <ul className="uploaded-file-list">
                {/* Sort again by most recent at the top. */}
                {sortBy(filesByDate[date], ({ updatedDate }) => updatedDate)
                  .reverse()
                  .map(
                    (providerFile: ILeaernerValidationProviderFile): ReactElement => {
                      const { relatedProviderFiles, fileName, id } = providerFile;

                      return (
                        <li className="uploaded-file" key={id}>
                          <a
                            download
                            onClick={() => handleDownload(fileName, id)}
                            href={fileName}
                            role="button"
                            style={{ display: 'inline-block' }}
                          >
                            <div className="uploaded-file-name">
                              <SaveAltRounded />
                              {fileName}
                            </div>
                          </a>
                          <>
                            {relatedProviderFiles.length ? (
                              <div className="uploaded-file-meta">
                                {!!relatedProviderFiles && (
                                  <div className="tag tag--added">
                                    <div className="label-text">Validated</div>
                                  </div>
                                )}

                                <div>
                                  {sortBy(relatedProviderFiles, ({ updatedDate }) => updatedDate)
                                    .reverse()
                                    .map(
                                      (file: IProviderFileChild): ReactElement => {
                                        const { id, fileName } = file;

                                        return (
                                          <div className="uploaded-file-download" key={id}>
                                            <a
                                              download
                                              onClick={() => handleDownload(fileName, id)}
                                              href={fileName}
                                              role="button"
                                              style={{ display: 'inline-block' }}
                                            >
                                              {fileName}
                                            </a>
                                          </div>
                                        );
                                      },
                                    )}
                                </div>
                              </div>
                            ) : (
                              <BatchFileProgress file={providerFile} />
                            )}
                          </>
                        </li>
                      );
                    },
                  )}
              </ul>
            </div>
          );
        },
      )}
    </>
  );
};
