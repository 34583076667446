// Store.
import { AppState } from 'store';
import { IReport } from './types';

export const isLoadingReportsSelector = ({ reportState }: AppState): boolean => reportState.isLoadingReports;
export const isLoadingReportSelector = ({ reportState }: AppState): boolean => reportState.isLoadingReport;
export const reportSelector = ({ reportState }: AppState): any => reportState.report;
export const reportsSelector = ({ reportState }: AppState): IReport[] => reportState.reports;
export const smsSummaryReportFileListSelector = ({ reportState }: AppState): Array<string> => {
  return reportState.smsSummaryReports;
};
export const isLoadingSmsSummaryReportsSelector = ({ reportState }: AppState): boolean =>
  reportState.isLoadingSmsSummaryReports;
export const isSmsSummaryReportAuthorizedSelector = ({ reportState }: AppState): boolean =>
  reportState.isSmsSummaryReportAuthorized;
