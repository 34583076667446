// Libs
import { uniqBy, uniqWith } from 'lodash';
import moment, { MomentInput } from 'moment';

// Core
import {
  CommercialSupportSource,
  IBoardActivityType,
  IBoardSlim,
  IInKindSupportSourceOption,
  IMonetarySupportSourceOption,
} from 'core/models';

// Types
import { StatusTypes, IBCTRemsRegularExpression } from 'layouts/pages/bct/types';

import { compareUtcDateTimesAsCentralDates } from 'components/Summary/ActivityActions/activityStateMachine';

/**
 * @function transformCommercialSupportSources
 * @description
 * - Combines monetary and in-kind support sources from the form for submission
 * - If a source appears is both lists, combine into a single, unique support source
 *
 * @param monetaryValues IMonetarySupportSourceOption[]
 * @param inKindValues IInKindSupportSourceOption[]
 *
 * @returns CommercialSupportSource[]
 */
export const transformCommercialSupportSources = (
  monetaryValues: IMonetarySupportSourceOption[] = [],
  inKindValues: IInKindSupportSourceOption[] = [],
): CommercialSupportSource[] => {
  // no values of any kind, return
  if (!monetaryValues?.length && !inKindValues?.length) {
    return [];
  }

  // create an array of monetary sources in the correct format
  // ensure the array is free of objects with duplicate source's
  let monetarySources: CommercialSupportSource[] = uniqBy(
    monetaryValues.map(({ amountGiven, source }) => {
      return {
        amountGiven: amountGiven ?? '',
        source: source ?? '',
      };
    }),
    'source',
  ) as CommercialSupportSource[];

  // preserve sources with an empty source. this allows the
  // state of the "Monetary" checkbox to be preserved in the form
  // however if we have at least one source, there's no need to preserve
  // the empty one, so we'll just remove it from the array
  if (monetarySources.length > 1) {
    monetarySources = monetarySources.filter(({ source }) => source);
  }

  // create an array of in-kind sources in the correct format
  // ensure the array is free of objects with duplicate source's
  let inKindSources: CommercialSupportSource[] = uniqBy(
    inKindValues.map((label) => {
      return {
        hasInKindSupport: true,
        source: label ?? '',
      };
    }),
    'source',
  ) as CommercialSupportSource[];

  // preserve sources with an empty source. this allows the
  // state of the "In Kind" checkbox to be preserved in the form
  // however if we have at least one source, there's no need to preserve
  // the empty one, so we'll just remove it from the array
  if (inKindSources.length > 1) {
    inKindSources = inKindSources.filter(({ source }) => source);
  }

  // If a non empty sourceId appears in both lists, combine into a single monetary source
  const sources = uniqWith([...monetarySources, ...inKindSources], (a, b) => {
    if (!a.source) return; // keep undefined sources from each side to preserve checkboxes
    return a.source === b.source;
  });
  for (const source of sources) {
    if (!source.hasInKindSupport && source.source && inKindValues.includes(source.source)) {
      // we merged a monetary source and an in-kind source - we need to set the in-kind flag on the result
      source.hasInKindSupport = true;
    }
  }
  return sources;
};

export const getAllowedBoardSelections = (
  boards: IBoardSlim[],
  activityTypeId: string,
  alreadySelectedBoardIds?: string[],
): IBoardSlim[] => {
  return boards?.filter(({ activityStatus, boardActivityTypes, id: boardId }: IBoardSlim): boolean => {
    // Avoid filtering until typeId is defined
    if (!activityTypeId) {
      return true;
    }
    // Shortcut to skip filtering the board if it was previously selected - ie. grandfathered
    if (alreadySelectedBoardIds && alreadySelectedBoardIds.includes(boardId)) {
      return true;
    }
    if (activityStatus !== StatusTypes.ACTIVE) {
      return false;
    }
    const now = moment();
    return !!boardActivityTypes?.find(
      ({ id, startDate, endDate }: IBoardActivityType): boolean =>
        id === activityTypeId &&
        (!startDate || !now.isBefore(startDate, 'days')) &&
        (!endDate || !now.isAfter(endDate, 'days')),
    );
  });
};

export const getRemsRegularExpression = (
  boardRemsIdRegularExpressions: IBCTRemsRegularExpression[],
  activityCreatedDate: MomentInput,
) => {
  const createdDate = moment(activityCreatedDate || new Date());

  return boardRemsIdRegularExpressions?.find((boardRemsRegEx: IBCTRemsRegularExpression) => {
    const startDateOk =
      !boardRemsRegEx.startDate || compareUtcDateTimesAsCentralDates(createdDate, moment(boardRemsRegEx.startDate)) > 0;
    const endDateOk =
      !boardRemsRegEx.endDate || compareUtcDateTimesAsCentralDates(createdDate, moment(boardRemsRegEx.endDate)) < 0;

    if (startDateOk && endDateOk) {
      return boardRemsRegEx;
    }
  });
};
