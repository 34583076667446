import { ChangeEvent, Fragment, ReactElement, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import classNames from 'classnames';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import styles from './FormikConditionalCheckboxes.module.scss';
import CustomTooltip from '../../../components/Tooltip/Tooltip';

// Types
import { IDisabledContext, IDisabledState, IFormInputOption, ITaxonomyTerm } from 'core/models';
import { TAGS_ENUM } from 'core/constants';
import { Box, Typography } from '@material-ui/core';

// Context
import { DisabledContext } from '../DisabledContext';
import { filterTaxonomyByOrg } from 'globals/utils/filterTaxonomyByOrg';
import { currentOrganizationContextSelector } from 'store/user/selectors';

interface IConditionalCheckboxesProps {
  options?: IFormInputOption[];
  isRequired?: boolean;
  formikKey?: string;
  showTooltipIcon?: boolean;
}

const FormikConditionalCheckboxes = (props: IConditionalCheckboxesProps): ReactElement => {
  const { options, isRequired, formikKey, showTooltipIcon } = props;
  const currentOrganization = useSelector(currentOrganizationContextSelector);
  const disabledContextShape: IDisabledContext = useContext<IDisabledContext>(DisabledContext);
  const disabledState: IDisabledState = get(disabledContextShape, formikKey) as IDisabledState;
  const { setFieldValue, values } = useFormikContext();
  const { MEASURED_OUTCOMES__LEARNER_KNOWLEDGE } = TAGS_ENUM;
  const shouldFilterTerms = formikKey === 'outcomesMeasured';

  const handleChange = (e: ChangeEvent<HTMLInputElement>, terms: ITaxonomyTerm[]) => {
    // Get the ID being added/removed
    const targetId = e.target.value;
    // Get the current list of IDs
    const currentList = get(values, formikKey) as string[];
    // If no list exists, initialize it with targetId
    if (!currentList) {
      setFieldValue(formikKey, [targetId]);
      return;
    }
    // If targetId isn't in the list, add it
    if (!currentList?.includes?.(targetId)) {
      setFieldValue(formikKey, [...currentList, targetId]);
    } else {
      // If targetId is in the list, remove it and it's child terms
      const childTermIds: string[] = terms?.map(({ id }: ITaxonomyTerm) => id);
      // Check if this checkbox has child terms
      const updatedList = currentList?.filter((entry) => entry !== targetId && !childTermIds?.includes(entry));
      setFieldValue(formikKey, updatedList);
    }
  };

  // 4846 - start with default values selected in Formik
  const defaults = options
    .filter((i) => i.isDefault)
    .map((i) => i.Id)
    .sort()
    .join('///');

  useEffect(() => {
    if (!defaults) return;
    const currentList = (get(values, formikKey) as string[]) || [];
    const defaultIds = uniq(defaults.split('///'));
    setFieldValue(formikKey, uniq([...currentList, ...defaultIds]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaults]);

  return (
    <>
      {options?.map(
        (option: IFormInputOption): ReactElement => {
          const { Id, LabelCaption, isDefault, terms, tag, Tooltip } = option;
          const Title =
            tag === MEASURED_OUTCOMES__LEARNER_KNOWLEDGE
              ? `${option.Title} will also be measured for this activity.`
              : option.Title;
          const isIdChecked: boolean = get(values, formikKey)?.includes(Id);
          const termsToRender = shouldFilterTerms
            ? filterTaxonomyByOrg({ organization: currentOrganization, terms })
            : terms;
          return (
            <Fragment key={Id}>
              {tag === MEASURED_OUTCOMES__LEARNER_KNOWLEDGE ? <hr className={styles['field-separator']} /> : null}
              <Box display="flex" mt={1} mb={1}>
                <label className={classNames('form-input-checkbox')} aria-label={Title}>
                  <Field
                    id={Id}
                    disabled={isDefault || (disabledState?.isDisabled && disabledState?.disabledOptions?.includes(Id))}
                    name={formikKey}
                    type="checkbox"
                    value={Id}
                    checked={isIdChecked || isDefault}
                    required={isRequired}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, terms)}
                  />
                  <span className={classNames('checkmark', { disabled: isDefault })} />
                  <Typography variant="srOnly">{Title}</Typography>
                </label>
                <div className={showTooltipIcon && styles['display-flex']}>
                  <div className="label-text" {...(Tooltip && !showTooltipIcon ? { title: Tooltip } : {})}>
                    {Title}
                  </div>
                  {LabelCaption && <div className="caption-text" dangerouslySetInnerHTML={{ __html: LabelCaption }} />}
                  {Tooltip && showTooltipIcon && (
                    <>
                      &nbsp;
                      <CustomTooltip tooltipText={Tooltip} iconTooltip>
                        <div>{Title}</div>
                      </CustomTooltip>
                    </>
                  )}
                </div>
              </Box>
              {isIdChecked &&
                termsToRender?.map(
                  (subOption: ITaxonomyTerm): ReactElement => {
                    const { id, name } = subOption;
                    return (
                      <Box display="flex" key={id}>
                        <label className={classNames('form-input-checkbox', 'form-input-checkbox-indent')}>
                          <Field name={formikKey} type="checkbox" value={id} required={isRequired} />
                          <span className="checkmark" />
                          <Typography variant="srOnly">{name}</Typography>
                        </label>
                        <div className="label-text-container">
                          <div className="label-text">{name}</div>
                        </div>
                      </Box>
                    );
                  },
                )}
            </Fragment>
          );
        },
      )}
    </>
  );
};

export default FormikConditionalCheckboxes;
