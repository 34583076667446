import { IFormSection } from 'core/models';
import * as PARS from '../../core';

export interface FormTemplateState {
  activityTemplate: Array<IFormSection>;
  activityValidationObject: {};
  error: string;
}

export const GET_ACTIVITY_FORM_TEMPLATE = 'GET_ACTIVITY_FORM_TEMPLATE';
export const GET_ACTIVITY_FORM_TEMPLATE_SUCCESS = 'GET_ACTIVITY_FORM_TEMPLATE_SUCCESS';
export const GET_ACTIVITY_FORM_TEMPLATE_FAILURE = 'GET_ACTIVITY_FORM_TEMPLATE_FAILURE';
export const SET_ACTIVITY_FORM_VALIDATION_OBJECT = 'SET_ACTIVITY_FORM_VALIDATION_OBJECT';

interface GetActivityFormTemplate extends PARS.Models.PARSAction<undefined, typeof GET_ACTIVITY_FORM_TEMPLATE> {
  type: typeof GET_ACTIVITY_FORM_TEMPLATE;
}

interface GetActivityFormTemplateSuccess
  extends PARS.Models.PARSAction<Array<IFormSection>, typeof GET_ACTIVITY_FORM_TEMPLATE_SUCCESS> {
  type: typeof GET_ACTIVITY_FORM_TEMPLATE_SUCCESS;
}

interface GetActivityFormTemplateFailure
  extends PARS.Models.PARSAction<Error, typeof GET_ACTIVITY_FORM_TEMPLATE_FAILURE> {
  type: typeof GET_ACTIVITY_FORM_TEMPLATE_FAILURE;
}

interface SetActivityFormValidationObject
  extends PARS.Models.PARSAction<{}, typeof SET_ACTIVITY_FORM_VALIDATION_OBJECT> {
  type: typeof SET_ACTIVITY_FORM_VALIDATION_OBJECT;
}

export type FormTemplateActionTypes =
  | GetActivityFormTemplate
  | GetActivityFormTemplateSuccess
  | GetActivityFormTemplateFailure
  | SetActivityFormValidationObject;
