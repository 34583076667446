import { ReactElement, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
// @ts-ignore: This package isn't typescript-friendly
import { saveAs } from 'filesaver.js-npm';

// Store
import { learnerActivityDownloadFileSelector } from 'store/learner/selectors';
import { onDownloadLearnerActivities } from 'store/learner/actions';

export const DownloadLearnerFile = (): ReactElement => {
  const { isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search);
  const learnerActivityDownloadFile = useSelector(learnerActivityDownloadFileSelector);

  useEffect(() => {
    // Check query string for download activities flag
    const downloadGuid = query.get('downloadActivityLearnerSummary');
    if (isAuthenticated && downloadGuid) {
      dispatch(onDownloadLearnerActivities(downloadGuid));
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (!learnerActivityDownloadFile) return;
    const { file, type } = learnerActivityDownloadFile;
    if (file) {
      // Convert csv string to blob
      const blob = new Blob([new Uint8Array(file, 0, file.byteLength)], { type });
      saveAs(blob, 'Learner Activity Export.csv');
    }
  }, [learnerActivityDownloadFile]);

  return null;
};
