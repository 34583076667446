/**
 * use instead of Number.isInteger for floating-point precision
 * @param value number to validate
 * @param precision precision factor for rounding
 * @returns boolean
 */
export const isAlmostInteger = (value: number, precision = 5): boolean => {
  const precisionFactor = Math.pow(10, Math.max(1, precision));
  const rounded = Math.round(value * precisionFactor) / precisionFactor;
  return Number.isInteger(rounded);
};
