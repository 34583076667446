// Core + Store
import {
  IOrganizationProvider,
  IOrganizationStatus,
  IOrganizationType,
  SearchOrganizationProviderRequest,
  SearchOrganizationProviderResponse,
} from 'core/models';
import { ServerRoute } from 'core/api';
import { API } from 'core';

export class OrganizationProfileService {
  public static async getOrganizationTypes(): Promise<IOrganizationType[]> {
    const route: ServerRoute = ServerRoute.forAction('organizationprofiles', 'organizationtypes');
    return await API.get(route);
  }

  public static async getOrganizationStatus(): Promise<IOrganizationStatus[]> {
    const route: ServerRoute = ServerRoute.forAction('organizationprofiles', `organizationstatus`);
    return await API.get(route);
  }

  public static async searchOrganizationProvider(
    req: SearchOrganizationProviderRequest,
  ): Promise<SearchOrganizationProviderResponse[]> {
    const route: ServerRoute = ServerRoute.forAction('organizationprofiles', `providers/search`);
    return await API.post(route, req);
  }

  public static async updateOrganizationProfile(
    organizationId: string,
    req: IOrganizationProvider,
  ): Promise<{ success: boolean; error: string }> {
    const route: ServerRoute = ServerRoute.forAction('organizationprofiles', `organizations/${organizationId}`);
    return await API.post(route, req);
  }

  public static async getOrganizationById(organizationId: string): Promise<IOrganizationProvider> {
    const route: ServerRoute = ServerRoute.forAction('organizationprofiles', `${organizationId}`);
    return await API.get(route);
  }

  public static async createOrganization(req: IOrganizationProvider): Promise<{ success: boolean; error: string }> {
    const route: ServerRoute = ServerRoute.forAction('organizationprofiles');
    return await API.post(route, req);
  }

  public static async updateOrganization(
    organizationId: string,
    req: IOrganizationProvider,
  ): Promise<{ success: boolean; error: string }> {
    const route: ServerRoute = ServerRoute.forAction('organizationprofiles', `${organizationId}`);
    return await API.put(route, req);
  }

  public static async toggleOrganizationStatus(
    organizationId: string,
    isDeleted: boolean,
  ): Promise<{ success: boolean; error: string }> {
    const route: ServerRoute = ServerRoute.forAction(
      'organizationprofiles',
      `${organizationId}?isDeleted=${isDeleted}`,
    );
    return await API.sendDelete(route);
  }
}
