import { FocusEvent, Fragment, ReactElement, useEffect, useMemo, useState } from 'react';
import { Field, FieldArray, FieldArrayRenderProps, FormikProvider, useFormik } from 'formik';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isBoolean, isEqual, isNumber, keys, last, noop, omit, set, sortBy, uniqueId } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import { Box, CircularProgress, Fab, Grid } from '@material-ui/core';
import { KeyboardBackspaceRounded, WarningRounded } from '@material-ui/icons';
import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';

// Components
import DatePicker from 'components/DatePicker/DatePicker';
import InputBlock from 'components/global/inputBlock/inputBlock';
import Button from 'components/Button/Button';
import { FormikCheckboxConditionalNumberInput } from 'components/forms/FormikCheckboxConditionalNumberInput';
import { CertifyingBoardForm } from '../CertifyingBoardForm';
import { FormikTextField } from 'components/ContinuousImprovement/FormikTextField';
import { ITypeaheadOption, Typeahead } from 'components/ContinuousImprovement/Typeahead';
import { HighlightBox } from 'components/HighlightBox';
import { LoadingCards } from 'components/LoadingCard';

// Core
import { activityDetailSelector, activityTypesWithCreditLimitSelector } from 'store/activity/selectors';
import { getActivity } from 'store/activity/actions';
import {
  isLearnerCompletionAddedSelector,
  isLearnerMatchingSelector,
  learnerValidationExceptionSelector,
  matchedLearnersSelector,
  previousMatchQuerySelector,
} from 'store/learner/selectors';
import {
  addLearnerCompletion,
  getRecentLearnerCompletions,
  matchLearners,
  resetLearnerMatches,
} from 'store/learner/actions';
import { statesAndProvincesSelector } from 'store/locations/selectors';
import { amaCreditTermIdSelector } from 'store/taxonomy/selectors';

// Types
import {
  Activity,
  IAddLearnersFormModel,
  ICertifyingBoardFormModel,
  IDictionary,
  IFormInputOption,
  ILearnerCompletion,
  ILearnerIdMatchField,
  ILearnerMatchingModel,
  IMatchedLearner,
  IStateAndProvince,
  ITaxonomyTerm,
} from 'core/models';
import { ButtonVariant, StatusEnum } from 'core/enums';
import {
  BoardLearnerCollectionFields,
  BoardTypes,
  CollectionTypes,
  IBCTBoard,
  IBCTBoardLearnerCollectionField,
  StatusTypes,
} from 'layouts/pages/bct/types';

// Styles
import styles from './AddLearnerForm.module.scss';

// Misc
import {
  getMatchPayloadFromFormValues,
  handleAddLearnerServerError,
  ICreditTypeFormOption,
  IFieldError,
  transformAddLearnerFormSubmitValues,
} from 'layouts/pages/learner/add-learner/utils';
import { buildAddLearnerSchema } from '../validationSchemas';
import { learnerMatched } from './utils/learnerMatched';

interface IProps {
  boardConfigs: IDictionary<IBCTBoard>;
  boardCreditOptions: IDictionary<ICreditTypeFormOption[]>;
  deaRegistrationOptions: IFormInputOption[];
  isLoadingForm: boolean;
  medicalSchoolOptions: ITypeaheadOption[];
  mocBoardList: IFormInputOption[];
  practiceAreaOptions: IFormInputOption[];
  professionOptions: IFormInputOption[];
  remsIds: string[];
  remsList: IFormInputOption[];
  specialtyBoardIds: string[];
  timeInPracticeOptions: IFormInputOption[];
}

interface IFieldSearch {
  boardId: string;
  fieldType: BoardLearnerCollectionFields;
}

enum SubmitIntent {
  SubmitAndClose = 'SubmitAndClose',
  SubmitAndContinue = 'SubmitAndContinue',
}

export const initialCertifyingBoardValues: ICertifyingBoardFormModel = {
  certifyingBoard: '',
  deaRegistration: '',
  id: '',
  learnerCredits: {},
  practiceArea: '',
  practiceState: '',
  prescribedInPast12Months: undefined,
  profession: '',
  providerLearnerId: '',
  surgicalProcedures: undefined,
  timeInPractice: '',
  totalCredits: 0,
};

const initialValues: IAddLearnersFormModel = {
  certifyingBoards: [],
  completionDate: undefined,
  credits: {
    cmeCredit: '' as any, // Required since `undefined` creates uncontrolled input error and 0 is not desired as visual default
    creditscmeCredit: false, // Default to unchecked
  },
  dateOfBirth: undefined,
  firstName: '',
  lastName: '',
  licenseId: '',
  medicalSchoolOption: undefined,
  npi: '',
  stateName: undefined,
};

// Create a new entry in the Certifying Boards array with a uniqueId
const generateUniqueCertifyingBoardEntry = (): ICertifyingBoardFormModel => ({
  ...initialCertifyingBoardValues,
  id: uniqueId('board_'),
});

// TODO: The 'visible' & 'required' fields throughout the form may be dependent on the Board type selected
// TODO: Add logic to control visibility with BCT configuration (1886 & 1888)
export const AddLearnerForm = (props: IProps): ReactElement => {
  const {
    boardConfigs,
    medicalSchoolOptions,
    mocBoardList,
    specialtyBoardIds,
    remsIds,
    remsList,
    boardCreditOptions,
  } = props;

  // Local State.
  const [isAutoFillCompleted, setIsAutoFillCompleted] = useState<boolean>(false);
  const [submitIntent, setSubmitIntent] = useState<SubmitIntent>();
  const [isMocBoardMatched, setIsMocBoardMatched] = useState<boolean>();

  // Hooks.
  const { activityId } = useParams<{ activityId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  // Selectors.
  const activity: Activity = useSelector(activityDetailSelector);
  const previousMatchQuery: ILearnerMatchingModel = useSelector(previousMatchQuerySelector);
  const isMatchingInProgress: boolean = useSelector(isLearnerMatchingSelector);
  const matchedLearners: IMatchedLearner[] = useSelector(matchedLearnersSelector);
  const isLearnerCompletionAdded: boolean = useSelector(isLearnerCompletionAddedSelector);
  const statesAndProvinces: IStateAndProvince[] = useSelector(statesAndProvincesSelector);
  const maxCreditActivityTypes: string[] = useSelector(activityTypesWithCreditLimitSelector);
  const amaCreditTerm: ITaxonomyTerm = useSelector(amaCreditTermIdSelector);
  const validationExceptions: AxiosError = useSelector(learnerValidationExceptionSelector);
  const activityType: string = activity?.typeId;
  const isMaxCreditsLimited: boolean = maxCreditActivityTypes?.includes(activityType);
  const hasOneEntry: boolean = matchedLearners?.length === 1;

  const credits = activity?.credits;
  const statesAndProvincesOptions: ITypeaheadOption[] =
    statesAndProvinces?.map(
      ({ isoStateCode, stateName }: IStateAndProvince): ITypeaheadOption => ({
        id: isoStateCode,
        label: stateName,
      }),
    ) || [];

  const sortCredits = useMemo(() => (creditsKey: string): number => credits?.[creditsKey], [credits]);
  const highestCreditKey: string = last(sortBy(keys(credits), sortCredits));
  const allowsCme: boolean = keys(activity?.credits).includes(amaCreditTerm?.id);

  const formik = useFormik<IAddLearnersFormModel>({
    initialValues,
    onSubmit: async (values: IAddLearnersFormModel): Promise<void> => {
      const allSelectedBoardIds: string[] = values?.certifyingBoards?.map(({ certifyingBoard }) => certifyingBoard);
      const isAllBoardsRems: boolean =
        !!allSelectedBoardIds?.length &&
        allSelectedBoardIds?.every((id: string): boolean => boardConfigs?.[id]?.type === BoardTypes.REMS);

      if (matchedLearners?.length !== 1 && !isAllBoardsRems) {
        return;
      }

      const universalLearnerId: string = matchedLearners?.[0]?.ulid;
      const activityCreditKeys: string[] = keys(activity?.credits);
      const amaCreditId: string = amaCreditTerm?.id;
      const cmeCreditId: string = activityCreditKeys.includes(amaCreditId) ? amaCreditId : activityCreditKeys?.[0];

      const submitValues: ILearnerCompletion = transformAddLearnerFormSubmitValues({
        addLearnersFormModel: values,
        creditId: cmeCreditId,
        nonRemsBoardIds: specialtyBoardIds,
        remsBoardIds: remsIds,
        universalLearnerId,
      });
      await dispatch(addLearnerCompletion(submitValues, activityId));
    },
    validateOnChange: true,
    validationSchema:
      activityId === activity?.id
        ? buildAddLearnerSchema({
            boardActivityDetails: activity?.boardMocDetails,
            boardConfigs,
            creditMaxClaimDate: activity?.mocCreditDeadline,
            endDate: activity?.endDate,
            isLearnerMatched: true,
            isMaxCreditsLimited,
            isMoc: activity?.isMoc,
            maxTotalCredits: highestCreditKey ? credits?.[highestCreditKey] : 0,
            startDate: activity?.startDate,
            amaCreditTerm,
          })
        : undefined,
  });

  const {
    dirty,
    errors,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValid,
    resetForm,
    setFieldError,
    setFieldValue,
    submitForm,
    touched,
    validateForm,
    values,
  } = formik;

  // Custom error handling to work with the add learner form model.
  useEffect(() => {
    if (validationExceptions) {
      const fieldErrors: IFieldError[] = handleAddLearnerServerError(validationExceptions, values);

      fieldErrors?.forEach(({ formikKey, message }: IFieldError): void => setFieldError(formikKey, message));
    }
  }, [setFieldError, validationExceptions]);

  const hasError = !!keys(errors).length;

  // If a learner is matched but the learner doesn't belong to any selected certifying boards, disable submit
  const allSelectedBoardIds: string[] = values?.certifyingBoards?.map(
    ({ certifyingBoard }: ICertifyingBoardFormModel): string => certifyingBoard,
  );
  const selectedMocBoards: string[] = allSelectedBoardIds?.filter((id: string): boolean => {
    const config: IBCTBoard = boardConfigs?.[id];

    return config?.type === BoardTypes.CERTIFYING_BOARD;
  });

  const { isMatched, stateLicenseId, matchedStateOption, mocLearnerIds } = learnerMatched({
    matchedLearners,
    selectedMocBoardIds: selectedMocBoards,
    stateName: values?.stateName,
    statesAndProvincesOptions,
  });

  const isLearnerBoardMember: boolean = selectedMocBoards?.every(
    (id: string): ICertifyingBoardFormModel => {
      const matchedLearnerBoard: ILearnerIdMatchField = matchedLearners[0]?.learnerMocBoards?.find(
        ({ boardId }: ILearnerIdMatchField): boolean => boardId === id,
      );

      const isBoardLearnerIdRequired: boolean =
        boardConfigs[id]?.boardLearnerCollectionFields?.find(
          ({ fieldType: fieldName }: IBCTBoardLearnerCollectionField): boolean =>
            fieldName === BoardLearnerCollectionFields.BOARD_ID,
        )?.collectionType === CollectionTypes.REQUIRED;

      // First we match with the correct board.
      // Board learner Id must match
      // Or
      // The board learner ID must be blank and allowed to be blank (AKA not required).
      return values?.certifyingBoards?.find(
        ({ boardId, certifyingBoard }: ICertifyingBoardFormModel): boolean =>
          certifyingBoard === id &&
          (boardId === matchedLearnerBoard?.learnerId || (!boardId && !isBoardLearnerIdRequired)),
      );
    },
  );

  useEffect(() => {
    if (activity?.id !== activityId) {
      dispatch(getActivity(activityId));
    }
  }, [dispatch, activity, activityId]);

  // If there's a match for 1 learner AND all boards, enable submit CTAs
  useEffect(() => {
    // Exactly 1 learner match is a success
    if (hasOneEntry && !isAutoFillCompleted) {
      // We are not done with autofill until we mark as true
      setIsAutoFillCompleted(true);

      const { medicalSchoolName } = matchedLearners[0];
      const matchedLearnerMedicalSchoolOption: ITypeaheadOption = medicalSchoolOptions?.find(
        ({ label }: ITypeaheadOption): boolean => label === medicalSchoolName,
      );

      const matchedData = {
        dateOfBirth: matchedLearners[0].dateOfBirth,
        lastName: matchedLearners[0].lastName,
        licenseId: stateLicenseId,
        medicalSchoolOption: matchedLearnerMedicalSchoolOption,
        npi: matchedLearners[0].npi,
        stateName: matchedStateOption,
        firstName: matchedLearners[0].firstName,
      };

      const matchedLearnersKeptValues = Object.fromEntries(Object.entries(matchedData).filter(([, value]) => !!value));

      // keep the non-falsey user-entered values even if the match has a different value
      const keptValues = Object.fromEntries(Object.entries(values).filter(([, value]) => !!value));

      const updatedFormValues: IAddLearnersFormModel = {
        ...keptValues,
        ...matchedLearnersKeptValues,
      };

      // go through the boards already selected and fill in learner IDs of the person we just matched
      if (updatedFormValues.certifyingBoards && mocLearnerIds) {
        updatedFormValues.certifyingBoards = updatedFormValues.certifyingBoards.map((i) => {
          if (i.certifyingBoard && mocLearnerIds[i.certifyingBoard] && (!i.boardId || i.boardId === '0')) {
            return { ...i, boardId: mocLearnerIds[i.certifyingBoard] };
          }
          return i;
        });
      }

      // Reset the form with values from the matched learner synchronously
      resetForm({ errors, touched, values: updatedFormValues });

      // Trigger validation with the newly matched learner values
      validateForm(updatedFormValues);
    }
  }, [
    errors,
    hasOneEntry,
    isAutoFillCompleted,
    matchedLearners,
    matchedStateOption,
    medicalSchoolOptions,
    mocLearnerIds,
    resetForm,
    stateLicenseId,
    touched,
    validateForm,
    values,
    values.certifyingBoards,
  ]);

  const getIsRequired = useMemo(
    () => ({ boardId, fieldType }: IFieldSearch): boolean =>
      !!boardConfigs[boardId]?.boardLearnerCollectionFields?.find(
        ({ fieldType: fieldName, collectionType }: IBCTBoardLearnerCollectionField): boolean =>
          fieldName === fieldType && collectionType === CollectionTypes.REQUIRED,
      ),
    [boardConfigs],
  );

  // If we have only one entry, set the state license Id to the field for the user.
  useEffect(() => {
    if (stateLicenseId) {
      setFieldValue('licenseId', stateLicenseId);
    }
  }, [setFieldValue, stateLicenseId]);

  const searchIsRequired = useMemo(
    () => (fieldType: BoardLearnerCollectionFields): boolean =>
      !!values?.certifyingBoards?.find(({ certifyingBoard }: ICertifyingBoardFormModel): boolean =>
        getIsRequired({ boardId: certifyingBoard, fieldType }),
      ),
    [values?.certifyingBoards, getIsRequired],
  );

  // Submission Sequencing Hook
  useEffect(() => {
    // If completion was added and there's no errors
    if (isLearnerCompletionAdded && !hasError && !isSubmitting) {
      // If the intent was to Submit and Close
      if (submitIntent === SubmitIntent.SubmitAndClose) {
        history.push(`/learners/summary/detail/${activityId}`);
      }

      // If the intent was to Submit and Add Another Learner
      if (submitIntent === SubmitIntent.SubmitAndContinue) {
        // After submitting, get the latest completions so the UI is the most up-to-date
        dispatch(getRecentLearnerCompletions(activityId));
        resetForm({ errors, touched, values: initialValues });
      }
      dispatch(resetLearnerMatches());
      setSubmitIntent(null);
    }
  }, [submitIntent, hasError, isLearnerCompletionAdded, isSubmitting]);

  // Reset matched learners on unmount.
  useEffect(() => {
    return () => dispatch(resetLearnerMatches());
  }, [dispatch]);

  // Capture to disable add boards if only reporting for CME
  const activityIsMoc: boolean = activity?.isMoc;
  const activityIsRems: boolean = activity?.isRems;
  const isActivityOnlyCme: boolean = !activityIsMoc && !activityIsRems;

  // Input Option for Learner credits (NOTE: these are distinct from MOC credits)
  const cmeCreditOption: IFormInputOption = { Id: 'cmeCredit', Title: 'Report for CME credit' };
  const isCmeCreditsChecked = !!values?.credits?.creditscmeCredit;
  const certifyingBoardIds = (values?.certifyingBoards || [])
    .filter(({ certifyingBoard }) => certifyingBoard && boardConfigs?.[certifyingBoard]?.type !== BoardTypes.REMS)
    .map(({ certifyingBoard }) => certifyingBoard)
    .sort()
    .join('__');

  // If firstName, lastName, dateOfBirth, and stateName are filled in but no learner matched, licenseId is required
  const { dateOfBirth, firstName, lastName, stateName } = values;

  const isStateLicenseIdRequiredToMatch: boolean =
    !!dateOfBirth && !!firstName && !!lastName && !!stateName && !isMatched;

  // If all fields, including license ID are filled an no match exists, show supplemental fields
  const isAllMatchFieldsFailed: boolean = isStateLicenseIdRequiredToMatch && !!values?.licenseId;

  const {
    COMPLETION_DATE,
    DATE_OF_BIRTH,
    FIRST_NAME,
    LAST_NAME,
    LICENSE_ID,
    STATE_OF_LICENSURE,
  } = BoardLearnerCollectionFields;

  const isCompletionDateRequired: boolean = searchIsRequired(COMPLETION_DATE) || isCmeCreditsChecked;
  const isDobRequired: boolean = searchIsRequired(DATE_OF_BIRTH) || isCmeCreditsChecked;
  const isFirstNameRequired: boolean = searchIsRequired(FIRST_NAME) || isCmeCreditsChecked;
  const isLastNameRequired: boolean = searchIsRequired(LAST_NAME) || isCmeCreditsChecked;
  const isLicensedStateRequired: boolean = searchIsRequired(STATE_OF_LICENSURE) || isCmeCreditsChecked;
  const isStateLicenseIdRequired: boolean = searchIsRequired(LICENSE_ID) || isStateLicenseIdRequiredToMatch;

  const handleBaseLearnerMatching = async (
    learnerFormValues: IAddLearnersFormModel,
    forceRunMatch = false,
  ): Promise<void> => {
    const matchingPayload: ILearnerMatchingModel = getMatchPayloadFromFormValues(learnerFormValues, boardConfigs);
    // If matching is not in transit and the match query hasn't changed, trigger a match request
    if (forceRunMatch || (!isMatchingInProgress && !isEqual(previousMatchQuery, matchingPayload))) {
      // Before dispatching a match, we may need to clear out medical school and npi
      // Depending on the form state, these values may not be visible to edit and will cause false negatives
      if (!isAllMatchFieldsFailed) {
        await dispatch(matchLearners(omit(matchingPayload, ['medicalSchoolName', 'npi'])));
      } else {
        await dispatch(matchLearners(matchingPayload));
      }
    }
  };

  const onBlurLearnerField = (event: FocusEvent<HTMLInputElement>): void => {
    handleBaseLearnerMatching(values).then(noop);
    handleBlur(event);
  };

  // fire match when is-cme changes, or when the list of certifying boards does, but only once we've already started matching
  useEffect(() => {
    if (previousMatchQuery) {
      handleBaseLearnerMatching(values).then(noop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCmeCreditsChecked, certifyingBoardIds]);

  const onChangeDatepickerField = (name: string, date: number | string): void => {
    const matchPayload = set(values, name, date);
    handleBaseLearnerMatching(matchPayload).then(noop);
  };

  const onBlurTypeaheadField = (name: string, value: ITypeaheadOption): void => {
    const matchPayload = set(values, name, value);
    handleBaseLearnerMatching(matchPayload).then(noop);

    if (name === 'state') {
      // Clear out the licenseId field whenever the state name changes.
      setFieldValue('licenseId', '');
    }
  };

  const submitAndClose = async (): Promise<void> => {
    setSubmitIntent(SubmitIntent.SubmitAndClose);
    await submitForm();
  };

  const submitAndAddAnother = async (): Promise<void> => {
    setSubmitIntent(SubmitIntent.SubmitAndContinue);
    await submitForm();
  };

  const closeWithoutSaving = (): void => {
    // Before leaving the page, clear learner match history from state
    dispatch(resetLearnerMatches());
    history.goBack();
  };

  // Filter selected REMS and MOC Boards before passing down
  const filterBoardsById = useMemo(
    () => ({ Id }: IFormInputOption): boolean =>
      !values?.certifyingBoards?.find(
        ({ certifyingBoard }: ICertifyingBoardFormModel): boolean => certifyingBoard === Id,
      ),
    [values?.certifyingBoards],
  );
  const filterBoardsActiveForLearners = ({ Id }: IFormInputOption): boolean =>
    boardConfigs?.[Id]?.learnerStatus === StatusTypes.ACTIVE;

  const filterMocBoardHaveAllData = ({ Id }: IFormInputOption): boolean => {
    const board = activity?.boardMocDetails?.[Id];
    return (
      board &&
      activity?.mocCreditDeadline &&
      isNumber(board.mocPointsGiven) &&
      board.mocPointsGiven > 0 &&
      board.practiceIds?.length > 0 &&
      board.typesOfCreditIds?.length > 0
    );
  };

  const filteredMocList: IFormInputOption[] = mocBoardList
    ?.filter(filterBoardsById)
    ?.filter(filterBoardsActiveForLearners)
    ?.filter(filterMocBoardHaveAllData);
  const filteredRemsList: IFormInputOption[] = remsList
    ?.filter(filterBoardsById)
    ?.filter(filterBoardsActiveForLearners);

  // If there's no boards/collabs to select, disable the add options
  const isDisabledAddBoards: boolean = !filteredMocList?.length && !filteredRemsList?.length;

  const isAllBoardsRems: boolean =
    !!allSelectedBoardIds?.length &&
    allSelectedBoardIds?.every((id: string): boolean => boardConfigs?.[id]?.type === BoardTypes.REMS);

  /**
   * Depending on what is selected and what is required, we need to determine what to match on.
   * A general rule is that if there are ANY learner not matched, we disable the submit button.
   */
  const thingsToEvaluate = [];

  if (isLicensedStateRequired) {
    thingsToEvaluate.push(isMatched);
  }

  if (isBoolean(isMocBoardMatched)) {
    thingsToEvaluate.push(isMocBoardMatched);
  }

  const isAllMatched: boolean = thingsToEvaluate.every((val: boolean): boolean => !!val);
  const isRemsSubmitValid: boolean = isValid && isAllBoardsRems && !isSubmitting;
  const isMOCSubmitValid: boolean = isValid && (isMatched || isMocBoardMatched);
  const isSubmitDisabled: boolean = isAllMatched
    ? !(dirty && isValid && !isLearnerBoardMember && isMOCSubmitValid) || isSubmitting
    : true;

  // hot-reload unmounts this component (which fires resetLearnerMatches)
  //   doing the match on load if we have data (which is only possible via hot-reload) improves the reliability of the developer experience here
  useEffect(() => {
    if (values.firstName) {
      // we have data when we're first mounted - ie. this is a hot-reload
      handleBaseLearnerMatching(values, true).then(noop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this ensures that if a match learners board does not match the learner the submit and close buttons will remain disabled
  const checkIfBoardMatchLearner = () => {
    if (!matchedLearners.length) return true;

    const learnerBoards = new Set(matchedLearners[0].learnerMocBoards.map(({ boardId }) => boardId));
    const anyMismatch = allSelectedBoardIds.some((id) => !learnerBoards.has(id));

    return anyMismatch;
  };
  // Theres a case where the allSelectedBoardIds passes in a '' as the first parm to represent a not yet selected board
  const checkIfLearnersMatchIsValid = () => allSelectedBoardIds[0] === '' || checkIfBoardMatchLearner();
  const shouldDisableSubmit =
    isSubmitting || (isSubmitDisabled && !(isRemsSubmitValid || isMOCSubmitValid)) || checkIfLearnersMatchIsValid();

  const filteredBoardCreditOptions: IDictionary<IFormInputOption[]> = useMemo(() => {
    if (!values.completionDate) return boardCreditOptions;
    const completionDate = moment(values.completionDate);
    if (!completionDate.isValid) return boardCreditOptions;

    const result: IDictionary<IFormInputOption[]> = {};
    for (const key in boardCreditOptions) {
      result[key] = boardCreditOptions[key].filter(
        ({ learnerCompletionStartDate, learnerCompletionEndDate }: ICreditTypeFormOption) => {
          const configuredStartDate: Moment = learnerCompletionStartDate && moment(learnerCompletionStartDate);
          const configuredEndDate: Moment = learnerCompletionEndDate && moment(learnerCompletionEndDate);

          if (configuredStartDate && configuredStartDate.isAfter(completionDate, 'day')) return false;
          if (configuredEndDate && configuredEndDate.isBefore(completionDate, 'day')) return false;

          return true;
        },
      );
    }
    return result;
  }, [values.completionDate, boardCreditOptions]);

  // If the activity hasn't loaded yet, render a loading indicator
  if (!activity) return <LoadingCards count={2} />;

  // If the activity is still in DRAFT status, don't render the form
  if (activity?.status === StatusEnum.DRAFT) {
    return (
      <HighlightBox variant="danger">
        <Box display="flex" component="p">
          <WarningRounded />
          <Box ml={2}>Learners cannot be added to this activity at this time</Box>
        </Box>
      </HighlightBox>
    );
  }

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <div className="static-form">
          <div className="form-group">
            <h5>Enter identification information and CME credits for the learner. Add a board to report MOC.</h5>
            <Grid container spacing={2} className={styles['form-grid']}>
              <Grid item xs={12} sm={3} className={styles.input}>
                <InputBlock
                  errors={errors}
                  name="firstName"
                  required={isFirstNameRequired}
                  suppressLabel
                  touched={touched}
                  title="First name"
                >
                  <FormikTextField
                    formikKey="firstName"
                    helperText=""
                    onBlur={onBlurLearnerField}
                    placeholder="First name"
                    required={isFirstNameRequired}
                    variant="outlined"
                  />
                </InputBlock>
              </Grid>
              <Grid item xs={12} sm={3} className={styles.input}>
                <InputBlock
                  errors={errors}
                  name="lastName"
                  required={isLastNameRequired}
                  touched={touched}
                  suppressLabel
                  title="Last name"
                >
                  <FormikTextField
                    formikKey="lastName"
                    helperText=""
                    onBlur={onBlurLearnerField}
                    placeholder="Last name"
                    required={isLastNameRequired}
                    variant="outlined"
                  />
                </InputBlock>
              </Grid>
              <Grid item xs={12} sm={2} className={styles.input}>
                <InputBlock
                  errors={errors}
                  name="dateOfBirth"
                  touched={touched}
                  title="Dob"
                  required={isDobRequired}
                  suppressLabel
                >
                  <Field
                    component={DatePicker}
                    helperText=""
                    id="dateOfBirth"
                    name="dateOfBirth"
                    className="form-input"
                    format="MM/DD"
                    onChange={onChangeDatepickerField}
                    disablePast={false}
                    required={isDobRequired}
                    timeOfDay="midnight"
                  />
                </InputBlock>
              </Grid>
              <Grid item xs={12} sm={4} className={styles.input}>
                <InputBlock
                  errors={errors}
                  name="completionDate"
                  touched={touched}
                  title="Date completed"
                  required={isCompletionDateRequired}
                  suppressLabel
                >
                  <Field
                    component={DatePicker}
                    helperText=""
                    id="completionDate"
                    name="completionDate"
                    className="form-input"
                    onBlur={onBlurLearnerField}
                    disablePast={false}
                    required={isCompletionDateRequired}
                    timeOfDay="midnight"
                  />
                </InputBlock>
              </Grid>
              {allowsCme && (
                <Grid item xs={12}>
                  <InputBlock errors={errors} name="credits" required touched={touched}>
                    <FormikCheckboxConditionalNumberInput
                      helperText=""
                      option={cmeCreditOption}
                      isRequired
                      formikKey="credits"
                      numberInputLabel="total credits"
                      variant="outlined"
                      required={isCmeCreditsChecked}
                    />
                  </InputBlock>
                </Grid>
              )}
              <Grid item xs={12} sm={4} className={styles.input}>
                <InputBlock
                  errors={errors}
                  name="stateName"
                  touched={touched}
                  title="Licensing state"
                  required={isLicensedStateRequired}
                  suppressLabel
                >
                  <Typeahead
                    options={statesAndProvincesOptions}
                    onChange={onBlurTypeaheadField}
                    id="stateName"
                    name="stateName"
                    placeholder="Select a state or province"
                  />
                </InputBlock>
              </Grid>
              <Grid item xs={12} sm={4} className={styles.input}>
                <InputBlock
                  errors={errors}
                  touched={touched}
                  name="licenseId"
                  title="Licensing id"
                  required={isStateLicenseIdRequired}
                  suppressLabel
                >
                  <FormikTextField
                    aria-required={isStateLicenseIdRequired}
                    formikKey="licenseId"
                    helperText=""
                    onBlur={onBlurLearnerField}
                    placeholder="Licensing id"
                    required={isStateLicenseIdRequired}
                    variant="outlined"
                  />
                </InputBlock>
              </Grid>
              {isMatched && (
                <Grid item xs={12}>
                  <div className={styles['pill-box']}>
                    <span className={classNames(styles['match-pill'], { [styles['match-pill--success']]: isMatched })}>
                      Learner Matched
                    </span>
                  </div>
                </Grid>
              )}
              {isAllMatchFieldsFailed && (
                <>
                  <Grid item xs={12}>
                    <div className={styles['pill-box']}>
                      <span className={classNames(styles['match-pill'], { [styles['match-pill--fail']]: !isMatched })}>
                        Learner Not Matched
                      </span>
                    </div>
                    <div className={classNames('caption-text', styles['error-text'])}>
                      Provide additional information below to match this learner.
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} className={styles.input}>
                    <InputBlock errors={errors} touched={touched} name="npi" title="NPI" suppressLabel>
                      <FormikTextField
                        formikKey="npi"
                        helperText=""
                        onBlur={onBlurLearnerField}
                        variant="outlined"
                        placeholder="NPI"
                      />
                    </InputBlock>
                  </Grid>
                  <Grid item xs={12} sm={4} className={styles.input}>
                    <InputBlock
                      errors={errors}
                      touched={touched}
                      name="medicalSchoolOption"
                      title="Medical school"
                      suppressLabel
                    >
                      <Typeahead
                        id="medicalSchoolOption"
                        name="medicalSchoolOption"
                        onChange={onBlurTypeaheadField}
                        options={medicalSchoolOptions}
                        placeholder="Select a medical school"
                      />
                    </InputBlock>
                  </Grid>
                </>
              )}
            </Grid>
            <hr />
            <FieldArray name="certifyingBoards">
              {({ remove, push }: FieldArrayRenderProps): ReactElement => (
                <>
                  {values?.certifyingBoards?.map(
                    ({ certifyingBoard, id }: ICertifyingBoardFormModel, idx: number): ReactElement => (
                      <Fragment key={id}>
                        {idx > 0 ? <hr /> : null}
                        <CertifyingBoardForm
                          {...props}
                          boardCreditOptions={filteredBoardCreditOptions}
                          config={boardConfigs[certifyingBoard]}
                          callback={setIsMocBoardMatched}
                          handleRemoveBoard={() => {
                            // Reset to the initial value of `undefined` so that the Learner Matched can re-evaluate
                            if (values?.certifyingBoards?.length === 1) {
                              setIsMocBoardMatched(undefined);
                            }
                            remove(idx);
                          }}
                          hasBoards={!!mocBoardList?.length}
                          hasCollabs={!!remsList?.length}
                          index={idx}
                          /* 4906 - using `matchedLearners.length === 1` instead of `isMatched` here, as it's possible we're not "matched" due to 0/many state licenses, but yet still have exactly one learner to fill in a boardid  */
                          learnerBoards={matchedLearners.length === 1 ? matchedLearners[0].learnerMocBoards : []}
                          mocBoardList={filteredMocList}
                          name="certifyingBoards"
                          remsList={filteredRemsList}
                        />
                      </Fragment>
                    ),
                  )}
                  <div className={classNames('label-text-container', styles.add)}>
                    <Fab
                      disabled={isActivityOnlyCme || isDisabledAddBoards}
                      aria-label="add another instance"
                      color="inherit"
                      size="small"
                      onClick={(): void => {
                        push(generateUniqueCertifyingBoardEntry());
                      }}
                    >
                      <AddIcon color="inherit" />
                    </Fab>
                    <span
                      className={classNames('label-text', styles.text, {
                        [styles.disabled]: isActivityOnlyCme || isDisabledAddBoards,
                      })}
                    >
                      Add a Certifying Board or Collaboration
                    </span>
                  </div>
                </>
              )}
            </FieldArray>
            {/* This Button should always be visible. It is disabled when a learner is not matched correctly */}
            <div className={styles['button-row']}>
              <Button
                disabled={shouldDisableSubmit}
                variant={ButtonVariant.Secondary}
                onClick={submitAndAddAnother}
                title="Submit and Add Another Learner"
                startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
              >
                Submit and Add Another Learner
              </Button>
            </div>
            {isAllMatchFieldsFailed && !!values?.npi && !!values?.medicalSchoolOption && (
              <Grid container>
                <Grid item xs={12}>
                  <div className={classNames('caption-text', styles['error-text'])}>
                    We were unable to locate a learner with the information provided. Please check with the learner and
                    re-submit with updated information.
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
        <div className={styles['button-row']}>
          <Button variant={ButtonVariant.Tertiary} onClick={closeWithoutSaving}>
            <KeyboardBackspaceRounded className="tertiary-icon-back" />
            Cancel Without Submitting
          </Button>
          <Button
            variant={ButtonVariant.Primary}
            disabled={shouldDisableSubmit}
            onClick={submitAndClose}
            title="Submit and Close"
            startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
          >
            Submit and Close
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};
