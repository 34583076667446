import { BoardMocDetails, IDictionary } from 'core/models';
import keys from 'lodash/keys';
import omit from 'lodash/omit';

// Strips out `optionSelectionId` from the Formik payload before submitting.
export const transformBoardMocDetails = (
  boardMocDetails: IDictionary<BoardMocDetails>,
): IDictionary<BoardMocDetails> => {
  const boardMocKeys = keys(boardMocDetails);

  boardMocKeys.forEach((key: string) => (boardMocDetails[key] = omit(boardMocDetails[key], 'optionSelectionId')));

  return boardMocDetails;
};
