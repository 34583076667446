import { Action, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../index';
import { BCTSteps, IBoardSlim, IErrorAndValidationExceptions, PARSAction } from 'core/models';
import { popToast } from '../toast/actions';
import { errorToastOptions, infoToastOptions, successToastOptions } from '../toast/constants';
import {
  GET_BOARD,
  GET_BOARD_FAILURE,
  GET_BOARD_LIST,
  GET_BOARD_LIST_FAILURE,
  GET_BOARD_LIST_SUCCESS,
  GET_BOARD_SUCCESS,
  IPostBCTStep,
  POST_BCT_STEP,
  POST_BCT_STEP_FAILURE,
  POST_BCT_STEP_SUCCESS,
  POST_BOARD_CONTENT_OUTLINES,
  POST_BOARD_CONTENT_OUTLINES_FAILURE,
  POST_BOARD_CONTENT_OUTLINES_SUCCESS,
  RESET_BCT,
  SET_CURRENT_BCT_STEP,
} from './types';
import { BoardService } from '../../services/BoardService';
import { IBCTBoard } from '../../layouts/pages/bct/types';
import { handleServerError } from '../../globals/utils/handleServerError';
import { clearBoards } from '../board/actions';

// Set step.
export const setBCTStepAction = (label: BCTSteps): AnyAction => ({
  payload: label,
  type: SET_CURRENT_BCT_STEP,
});

// Submit step.
export const postBCTAction = (): Action => ({
  type: POST_BCT_STEP,
});

export const postBCTSuccessAction = (): Action => ({
  type: POST_BCT_STEP_SUCCESS,
});

export const postBCTFailureAction = ({
  creditTypesErrors,
  error,
  validationExceptions,
}: IErrorAndValidationExceptions): AnyAction => ({
  payload: { creditTypesErrors, error, validationExceptions },
  type: POST_BCT_STEP_FAILURE,
});

// Get all boards.
export const getBoardListAction = (): Action => ({
  type: GET_BOARD_LIST,
});

export const getBoardListSuccessAction = (boardList: IBoardSlim[]): AnyAction => ({
  payload: boardList,
  type: GET_BOARD_LIST_SUCCESS,
});

export const getBoardListFailureAction = (error: Error): AnyAction => ({
  payload: error,
  type: GET_BOARD_LIST_FAILURE,
});

// Get single board.
export const getBoardAction = (): Action => ({
  type: GET_BOARD,
});

export const getBoardSuccessAction = (board: IBCTBoard): AnyAction => ({
  payload: board,
  type: GET_BOARD_SUCCESS,
});

export const getBoardFailureAction = (error: Error): AnyAction => ({
  payload: error,
  type: GET_BOARD_FAILURE,
});

// Post board content outlines.
export const postBoardContentOutlines = (): Action => ({
  type: POST_BOARD_CONTENT_OUTLINES,
});

export const postBoardContentOutlinesSuccessAction = (): Action => ({
  type: POST_BOARD_CONTENT_OUTLINES_SUCCESS,
});

export const postBoardContentOutlinesFailureAction = ({
  error,
  validationExceptions,
}: IErrorAndValidationExceptions): AnyAction => ({
  payload: { error, validationExceptions },
  type: POST_BOARD_CONTENT_OUTLINES_FAILURE,
});

// Reset
export const resetBCT = (): Action => ({
  type: RESET_BCT,
});

// thunk actions
export const setBCTStep = (label: BCTSteps): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
) => {
  await dispatch(setBCTStepAction(label));
};

export const postBCTStep = ({
  nextStep,
  values,
}: IPostBCTStep): ThunkAction<Promise<void>, AppState, { appRoute: string }, PARSAction> => async (
  dispatch,
  getState,
) => {
  const { currentBoard } = getState().bctState;
  dispatch(postBCTAction());
  try {
    await BoardService.put(currentBoard.id, values);
    dispatch(postBCTSuccessAction());
    await dispatch(setBCTStep(nextStep));
    dispatch(popToast({ ...successToastOptions, message: <>Successfully Updated</> }));
    dispatch(clearBoards());
  } catch (error) {
    const { creditTypesErrors, errorMessage, validationExceptions } = handleServerError({
      error,
      thunkName: 'postBCTStep',
    });
    dispatch(postBCTFailureAction({ creditTypesErrors, error, validationExceptions }));
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const getBoardList = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(getBoardListAction());
  try {
    const boardService = new BoardService();
    const boardList: IBoardSlim[] = await boardService.getBoards();
    await dispatch(getBoardListSuccessAction(boardList));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getBoardList' });
    await dispatch(getBoardListFailureAction(error));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const getBoardById = (
  id: string,
): ThunkAction<Promise<void>, AppState, { appRoute: string }, PARSAction> => async (dispatch) => {
  await dispatch(getBoardAction());
  try {
    const boardService = new BoardService();
    const board: IBCTBoard = await boardService.getBoardById(id);
    await dispatch(getBoardSuccessAction(board));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getBoardById' });
    await dispatch(getBoardFailureAction(error));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};

export const uploadBoardContentOutlines = (
  id: string,
  file: { file: File[] },
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(postBoardContentOutlines());
  try {
    const boardService = new BoardService();
    await boardService.postBoardContentOutlines(id, file);
    await dispatch(postBoardContentOutlinesSuccessAction());
    await dispatch(
      popToast({
        ...infoToastOptions,
        message: (
          <>We are processing your content outlines offline. You will receive an email when the process is complete.</>
        ),
      }),
    );
  } catch (error) {
    const { errorMessage, validationExceptions } = handleServerError({
      error,
      thunkName: 'uploadBoardContentOutlines',
    });
    await dispatch(postBoardContentOutlinesFailureAction({ error, validationExceptions }));
    await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};
