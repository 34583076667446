import sortBy from 'lodash/sortBy';
import findIndex from 'lodash/findIndex';

// Constants
import { DEFAULT_COUNTRY } from 'core/constants';

// Types
import { IDropdownType } from 'components/Dropdown/Dropdown';

/**
 * @function allSortCountries
 * @param countries
 * @description - Sort all of the countries alphabetically.
 */
export const allSortCountries = (countries: IDropdownType[]): IDropdownType[] => sortBy(countries, 'Title');

/**
 * @function sortCountries
 * @param countries
 * @description - Put the USA country at the top (ACCME requirement).
 */
export const sortCountries = (countries: IDropdownType[]): IDropdownType[] => {
  const sortedCountries: IDropdownType[] = allSortCountries(countries);
  const foundIndex: number = findIndex(sortedCountries, { Title: DEFAULT_COUNTRY });

  let usaCountry = {};

  // We have the index of the USA country.
  if (foundIndex > -1) {
    // Save it before stripping out.
    usaCountry = sortedCountries[foundIndex];

    // Remove the USA country from the sorted array.
    sortedCountries.splice(foundIndex, 1);

    // Add the USA country to the front of the sorted array.
    sortedCountries.unshift(usaCountry as IDropdownType);
  }

  return sortedCountries;
};
