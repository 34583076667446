import { IContextualMenuOptionProps } from 'components/ContextualMenu/ContextualMenu';
import { IMenuProps } from './types';

interface IPerPageMenu {
  label: string;
  options: IContextualMenuOptionProps[];
}

export const perPageMenuItems = ({ perPage, searchRequest }: IMenuProps): IPerPageMenu => ({
  label: 'Show: ',
  options: [
    { name: '10 Per Page', onSelectAction: () => perPage(10), selected: searchRequest.top === 10 },
    { name: '25 Per Page', onSelectAction: () => perPage(25), selected: searchRequest.top === 25 },
    { name: '50 Per Page', onSelectAction: () => perPage(50), selected: searchRequest.top === 50 },
  ],
});
