import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useFormik, FormikProvider } from 'formik';
import { Typography } from '@material-ui/core';

// Components
import { FileUploader } from 'components/global/fileUploader';

// Store + Core
import { BATCH_UPLOAD_FILE_TYPES } from 'core/constants';
import { batchActivity, batchActivityClearAction } from 'store/activity/actions';
import { activityFileSelector, uploadPercentageSelector } from 'store/activity/selectors';
import { rollupOrganizationEnumSelector } from 'store/user/selectors';

// Types
export interface IBatchActivityFormValues {
  file: File;
}

export const BatchActivityForm = (): ReactElement => {
  const dispatch = useDispatch();

  // Redux State.
  const uploadPercentage: number = useSelector(uploadPercentageSelector);
  const uploadedFile = useSelector(activityFileSelector);
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);

  const onClearFile = (): void => {
    dispatch(batchActivityClearAction());
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      // API call - upload file.
      dispatch(batchActivity({ file: values as { file: File }, rollupOrganizationEnum }));
    },
  });
  const { handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <form encType="multipart/form-data" onSubmit={handleSubmit}>
        <Field
          id="batchActivityFile"
          name="file"
          component={FileUploader}
          fileTypes={BATCH_UPLOAD_FILE_TYPES}
          showDropZoneSubTitle
          onClearFile={onClearFile}
          uploadPercentage={uploadPercentage}
          uploadedFile={uploadedFile}
        />
        <Typography variant="srOnly">
          <button tabIndex={-1}>Submit</button>
        </Typography>
      </form>
    </FormikProvider>
  );
};
