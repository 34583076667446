import { useSelector } from 'react-redux';

// Store + Core
import { ITaxonomyTerm, OrganizationKind, OrganizationKinds } from 'core/models';
import { TAGS_ENUM } from 'core/constants';
import { navigationTermsSelector } from 'store/taxonomy/selectors';
import { organizationKindSelector, rollupOrganizationEnumSelector } from 'store/user/selectors';
import { getSite } from 'utils/site';
import { filterTaxonomyByRollupOrganizationEnum } from 'globals/utils/filterTaxonomyByOrg';

export const useTaxonomyHeaderNavTerms = (): ITaxonomyTerm[] => {
  const allNavItems: ITaxonomyTerm[] = useSelector(navigationTermsSelector);
  const defaultOrganization: OrganizationKind = useSelector(organizationKindSelector)?.organizationKind;
  // fallback to the site we're on so we have data based on the site while login is in progress
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector) || getSite();

  // Only show taxonomy items in the nav if permissions are granted.
  return filterTaxonomyByRollupOrganizationEnum(allNavItems, rollupOrganizationEnum).filter((item: ITaxonomyTerm) => {
    if (
      defaultOrganization !== OrganizationKinds.STAFF &&
      defaultOrganization !== OrganizationKinds.SPECIAL_ORGANIZATION
    ) {
      return item.tag !== TAGS_ENUM.STAFF;
    } else return item;
  });
};
