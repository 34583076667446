import { ChangeEvent, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';

// Styles
import styles from './checkboxes.module.scss';

// Core + Store
import { StatusEnum } from 'core/enums';
import { isProviderUserSelector } from 'store/user/selectors';

// Utils
import { calculateStatusTitle } from 'utils';

// Types
import { IFacetProps, SearchRequest } from '../../../types';

export const Checkboxes = (props: IFacetProps): ReactElement => {
  const { callback, classes, facet, facets, isDisabled, isExpanded, expandIcon, onToggleExpand } = props;
  const { values } = useFormikContext<SearchRequest>();
  const isProvider: boolean = useSelector(isProviderUserSelector);

  const { displayValue, payloadValue, responseValue } = facet;

  return (
    <Accordion
      key={payloadValue}
      className={classes.accordion}
      disabled={isDisabled}
      expanded={isExpanded}
      onChange={onToggleExpand}
    >
      <AccordionSummary
        aria-controls={`${payloadValue}-content`}
        className={classes.summary}
        expandIcon={expandIcon}
        id={`${payloadValue}-header`}
      >
        {displayValue}
      </AccordionSummary>
      <AccordionDetails className={classes.details} role="group" aria-labelledby={`${payloadValue}-header`}>
        <FieldArray
          name={payloadValue}
          render={(arrayHelpers: FieldArrayRenderProps) => {
            const facetValues: string[] = facets?.[responseValue];

            return (
              <>
                {facetValues?.sort()?.map(
                  (currentFacet: StatusEnum): ReactElement => {
                    const isPill =
                      responseValue === 'ActivityStatus' ||
                      responseValue === 'CompletionStatuses' ||
                      responseValue === 'CompletionStatuses/Name' ||
                      responseValue === 'LearnerStatus' ||
                      responseValue === 'Status';
                    // We show `Submitted` as a status if the user is a Provider instead of `Not Processed`.
                    // A Provider user should never see `Not Processed`.
                    const facetValue: StatusEnum = calculateStatusTitle({ isProvider, statusTitle: currentFacet });

                    const labelTextClass = classNames(
                      {
                        [styles['--accepted']]: facetValue === StatusEnum.ACCEPTED && isPill,
                        [styles['--active']]: facetValue === StatusEnum.ACTIVE && isPill,
                        [styles['--closed']]: facetValue === StatusEnum.CLOSED && isPill,
                        [styles['--draft']]: facetValue === StatusEnum.DRAFT && isPill,
                        [styles['--ready-to-close']]: facetValue === StatusEnum.READY_TO_CLOSE && isPill,
                        [styles['--rejected']]: facetValue === StatusEnum.REJECTED && isPill,
                        [styles['--submitted']]: facetValue === StatusEnum.SUBMITTED && isPill,
                        [styles['--not-processed']]: facetValue === StatusEnum.NOT_PROCESSED && isPill,
                        [styles['--delete-not-processed']]: facetValue === StatusEnum.DELETE_NOT_PROCESSED && isPill,
                        [styles['--delete-submitted']]: facetValue === StatusEnum.DELETE_SUBMITTED && isPill,
                      },
                      styles['label-text'],
                    );

                    // `facetValue` can be null if we are a Provider and the original `currentFacet` is "Submitted".
                    // In that case, we don't want to render an empty checkbox
                    // so we check that it has value before we try to render.
                    if (facetValue) {
                      return (
                        <Box display="flex" key={uniqueId(facetValue)}>
                          <label className="form-input-checkbox">
                            <input
                              type="checkbox"
                              checked={values?.[payloadValue]?.includes(currentFacet)}
                              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                                if (e.target.checked) {
                                  arrayHelpers.push(currentFacet);
                                } else {
                                  const foundIdx = values?.[payloadValue]?.indexOf(currentFacet);
                                  arrayHelpers.remove(foundIdx);
                                }

                                // API call.
                                callback?.();
                              }}
                            />
                            <span className="checkmark" />
                            <Typography variant="srOnly">{facetValue}</Typography>
                          </label>
                          <div className="label-text-container">
                            <div className={labelTextClass}>{facetValue}</div>
                          </div>
                        </Box>
                      );
                    }

                    return null;
                  },
                )}
              </>
            );
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};
