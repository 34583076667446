import { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import { AddRounded } from '@material-ui/icons';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

// Styles
import styles from './button.module.scss';

// Types
import { ButtonVariant } from 'core/enums';

// Components
import CustomTooltip from 'components/Tooltip/Tooltip';

export interface IButtonProps extends Omit<ButtonProps, 'variant'> {
  component?: ReactNode;
  isSubmitting?: boolean;
  fullWidth?: boolean;
  to?: string;
  variant?: ButtonVariant;
  disabledMessage?: string;
}

export default (props: IButtonProps): ReactElement => {
  const { variant, isSubmitting, children, className, fullWidth, disabledMessage, ...rest } = props;
  const buttonClass: string = classNames(
    'btn',
    {
      danger: variant === ButtonVariant.Danger,
      ghost: variant === ButtonVariant.Ghost,
      icon: variant === ButtonVariant.Icon,
      label: variant === ButtonVariant.Label,
      primary: variant === ButtonVariant.Primary,
      secondary: variant === ButtonVariant.Secondary,
      tertiary: variant === ButtonVariant.Tertiary,
      [styles['full-width']]: fullWidth,
    },
    className,
  );

  const element = (
    <Button
      className={buttonClass}
      startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
      type="button"
      {...rest}
    >
      {variant === ButtonVariant.Icon && <AddRounded />}
      {children}
    </Button>
  );

  if (rest.disabled && disabledMessage) {
    return <CustomTooltip tooltipText={disabledMessage}>{element}</CustomTooltip>;
  }

  return element;
};
