import maxBy from 'lodash/maxBy';

// Types
import { ILearnerIdMatchField } from 'core/models';
import { ITypeaheadOption } from 'components/ContinuousImprovement/Typeahead';

interface IArgs {
  learnerStateBoards: ILearnerIdMatchField[];
  stateName: ITypeaheadOption;
}

/**
 * @function getLicenseId
 * @param {IArgs} args
 * @description - First look at abbr. If we have 1 then use that for the learner id.
 *              - Else look at the name. If we have 1 then use that for the learner id.
 *                - If the name is Virginia, verify that it is not West Virginia.
 *                - If we have more than 1, take the one that is not expired, then most expired recently.
 * @returns {string} learnerId
 */
export const getLicenseId = (args: IArgs): string => {
  const { learnerStateBoards, stateName } = args;
  const { id: stateAbbreviation } = stateName;

  // Split the data into two sections.
  // 1. FSMB
  // 2. DiplomateState
  // We try as hard as we can with FSMB before we try to populate via DiplomateState.

  const fsmbStates = learnerStateBoards.filter(({ source }: ILearnerIdMatchField): boolean => source === 'FSMB');
  const diplomateStates = learnerStateBoards.filter(
    ({ source }: ILearnerIdMatchField): boolean => source === 'DiplomateState',
  );

  /**
   * Generic functions.
   */

  const findStateBoard = ({ abbreviation }: ILearnerIdMatchField): boolean => abbreviation === stateAbbreviation;
  const findLatestExpirationId = ({ expirationDate }: ILearnerIdMatchField): Date => new Date(expirationDate);

  // Search for exact FSMB states.
  const filteredFSMBStates: ILearnerIdMatchField[] = fsmbStates.filter(findStateBoard);

  // If we only have 1 match, we have an answer.
  if (filteredFSMBStates.length === 1) return filteredFSMBStates[0].learnerId;

  // If we have more than 1, we then look at expiration date.
  if (filteredFSMBStates.length > 1) {
    return maxBy(filteredFSMBStates, findLatestExpirationId).learnerId;
  }

  // We have no FSMB records!

  // Now lets check DiplomateState.
  // Search for exact DiplomateState.
  const filteredDiplomateStates: ILearnerIdMatchField[] = diplomateStates.filter(findStateBoard);

  // If we only have 1 match, we have an answer.
  if (filteredDiplomateStates.length === 1) return filteredDiplomateStates[0].learnerId;

  // If we have more than 1, we then look at expiration date.
  if (filteredDiplomateStates.length > 1) {
    return maxBy(filteredDiplomateStates, findLatestExpirationId).learnerId;
  }

  return '';
};
