// Libs
import * as yup from 'yup';
import moment from 'moment-timezone';
import { keys } from 'lodash';
import { VALIDATION_MESSAGES } from './validationMessages';
// Core + Store
import {
  IAddLearnersFormModel,
  ICertifyingBoardFormModel,
  IDictionary,
  ILearnerCompletionValidationBlueprint,
} from 'core/models';
import {
  BoardCreditTypeSubmissionTypes,
  BoardLearnerCollectionFields,
  BoardTypes,
  CollectionTypes,
  IBCTBoard,
  IBCTBoardLearnerCollectionField,
  IBCTCreditType,
} from 'layouts/pages/bct/types';
import { isAlmostInteger } from 'utils';

interface IRequiredFieldSearch {
  fieldConfigs: IBCTBoardLearnerCollectionField[];
  fieldType: BoardLearnerCollectionFields;
}

interface IBoardOrCmeRequiredFieldSearch {
  boardConfigs?: IDictionary<IBCTBoard>;
  boardList?: ICertifyingBoardFormModel[];
  credits: IDictionary<boolean | number>;
  fieldType?: BoardLearnerCollectionFields;
}

// Validate NPI is a 10-digit number
const npiRegex = /^[0-9]{10}$/;

// Check if the requested fieldType is required across configs
const isFieldRequired = ({ fieldConfigs = [], fieldType }: IRequiredFieldSearch): boolean => {
  if (!fieldConfigs?.length) {
    return false;
  }
  if (!fieldType) {
    return false;
  }
  const matchField: IBCTBoardLearnerCollectionField = fieldConfigs?.find(
    ({ fieldType: matchFieldType }): boolean => fieldType === matchFieldType,
  );
  if (!matchField) {
    return false;
  }
  return matchField?.collectionType === CollectionTypes.REQUIRED;
};

const FirstNameRequiredSchema = yup.string().required(VALIDATION_MESSAGES.FIRST_NAME_REQUIRED);
const LastNameRequiredSchema = yup.string().required(VALIDATION_MESSAGES.LAST_NAME_REQUIRED);

export const LearnerValidationToolSchema = (): yup.AnyObjectSchema => {
  return yup.object().shape({
    dateOfBirth: yup
      .date()
      .typeError(VALIDATION_MESSAGES.DOB_FORMAT)
      .test({
        message: VALIDATION_MESSAGES.DOB_FORMAT,
        test: (dateOfBirth: Date) => {
          if (dateOfBirth === undefined) {
            return true;
          }

          return moment(dateOfBirth, 'MM/DD', true).isValid();
        },
      }),
    firstName: FirstNameRequiredSchema,
    lastName: LastNameRequiredSchema,
    npi: yup
      .string()
      .typeError(VALIDATION_MESSAGES.NPI_FORMAT)
      .test({
        message: VALIDATION_MESSAGES.NPI_FORMAT,
        test: (npi: string) => {
          if (npi === undefined) {
            return true;
          } else {
            if (typeof npi === 'string' && npiRegex.test(npi)) {
              return true;
            }
          }
        },
      }),
  });
};

// Check if the requested fieldType is required or if CME credits is checked
const someBoardOrCmeRequired = ({
  boardConfigs,
  boardList,
  credits,
  fieldType,
}: IBoardOrCmeRequiredFieldSearch): boolean =>
  boardList?.some(({ certifyingBoard }: ICertifyingBoardFormModel): boolean =>
    isFieldRequired({
      fieldConfigs: boardConfigs?.[certifyingBoard]?.boardLearnerCollectionFields,
      fieldType,
    }),
  ) || !!credits?.creditscmeCredit; // Derived formikKey for Report for CME credit checkbox

const buildLearnerCreditsSchema = (
  blueprint: ILearnerCompletionValidationBlueprint,
  boardId: string,
  maxCredits: number,
): IDictionary<yup.AnySchema> => {
  const { boardActivityDetails, boardConfigs } = blueprint;
  const boardConfig: IBCTBoard = boardConfigs[boardId];
  const boardCreditTypes: IBCTCreditType[] = boardConfig?.creditTypes;
  const activityBoardCreditKeys: string[] = boardActivityDetails[boardId]?.typesOfCreditIds;

  const creditConfigDictionary: IDictionary<IBCTCreditType> = {};
  boardCreditTypes?.forEach((creditType) => (creditConfigDictionary[creditType.id] = creditType));
  const resultDictionary: IDictionary<yup.AnySchema> = {};
  if (!activityBoardCreditKeys?.length) {
    return resultDictionary;
  }
  activityBoardCreditKeys?.forEach((key) => {
    resultDictionary[key] = yup
      .number()
      .test({
        message: 'Credit type cannot be submitted alone',
        name: 'submissionTypeTest',
        test: (_, { parent, resolve }: yup.TestContext<ICertifyingBoardFormModel>): boolean => {
          const isValChecked: boolean = resolve(yup.ref(`learnerCredits${key}`)) as boolean;
          if (isValChecked) {
            const appliedCreditConfig: IBCTCreditType = creditConfigDictionary[key];
            if (!appliedCreditConfig) {
              return true;
            }
            const submitType: BoardCreditTypeSubmissionTypes = appliedCreditConfig.boardCreditTypeSubmissionType;
            const allOtherBoardCreditKeys: string[] = keys(parent).filter(
              (creditKey) => !creditKey.includes('learner') && creditKey !== key,
            );
            if (
              submitType === BoardCreditTypeSubmissionTypes.WITH_ANY_OTHER_CREDIT_TYPE &&
              allOtherBoardCreditKeys?.length < 1
            ) {
              return false;
            }
          }
          return true;
        },
      })
      .test({
        message: 'Credit type has additional dependencies',
        name: 'dependentCreditTypeTest',
        test: (_, { resolve }: yup.TestContext<ICertifyingBoardFormModel>): boolean => {
          const isValChecked: boolean = resolve(yup.ref(`learnerCredits${key}`)) as boolean;
          if (isValChecked) {
            const appliedCreditConfig: IBCTCreditType = creditConfigDictionary[key];
            if (!appliedCreditConfig) {
              return true;
            }
            const dependencies: string[] = appliedCreditConfig.boardCreditTypeRequiredCreditTypeIds;
            const submitType: BoardCreditTypeSubmissionTypes = appliedCreditConfig.boardCreditTypeSubmissionType;
            if (submitType === BoardCreditTypeSubmissionTypes.WITH_SPECIFIC_CREDIT_TYPES && dependencies?.length > 0) {
              const isDependenciesMet: boolean = dependencies.every((creditKey: string): boolean => {
                const isDependencyChecked: boolean = resolve(yup.ref(`learnerCredits${creditKey}`)) as boolean;
                return typeof isDependencyChecked === 'boolean' && isDependencyChecked;
              });
              return isDependenciesMet;
            }
          }
          return true;
        },
      })
      .test({
        message: 'Missing Credit Type Amount',
        name: 'minCreditsTest',
        test: (val: number, { resolve }: yup.TestContext<ICertifyingBoardFormModel>) => {
          const isValChecked: boolean = resolve(yup.ref(`learnerCredits${key}`)) as boolean;
          if (!isValChecked) {
            return true;
          }
          if (typeof val !== 'number') {
            return false;
          }

          return true;
        },
      })
      .test({
        message: `${
          boardConfig ? `Credits must be in increments of ${boardConfig?.creditIncrement}` : 'Invalid credit increment'
        }`,
        name: 'creditIncrementTest',
        test: (val: number): boolean => {
          const increment: number = boardConfig.creditIncrement;

          if (typeof val === 'number' && typeof increment === 'number') {
            return isAlmostInteger(val / increment);
          }
          return true;
        },
      })
      .test({
        message: 'Credits must be greater than 0',
        name: 'minCreditsTest',
        test: (val: number) => {
          const minCredits: number = boardConfig?.creditIncrement || 0;

          if (typeof val !== 'number') {
            return true;
          }

          return val >= minCredits;
        },
      })
      .test({
        message: `Cannot exceed ${maxCredits} total credits`,
        name: 'maxBoardCreditsTest',
        test: (val: number) => {
          if (typeof val !== 'number') {
            return true;
          }
          return val <= maxCredits;
        },
      });
  });
  return resultDictionary;
};

const buildBoardSchema = (blueprint: ILearnerCompletionValidationBlueprint): yup.AnyObjectSchema => {
  const { boardActivityDetails, boardConfigs, isMaxCreditsLimited, maxTotalCredits } = blueprint;
  let instanceCertifyingBoardId = '';
  let instanceMaxCredits: number;
  return yup.object().shape({
    boardId: yup
      .string()
      .typeError('')
      .when('certifyingBoard', {
        is: (boardId: string): boolean =>
          isFieldRequired({
            fieldConfigs: boardConfigs?.[boardId]?.boardLearnerCollectionFields,
            fieldType: BoardLearnerCollectionFields.BOARD_ID,
          }),
        otherwise: (schema: yup.StringSchema): yup.StringSchema => schema.notRequired(),
        then: (schema: yup.StringSchema): yup.StringSchema => schema.required('Board ID is required'),
      }),
    deaRegistration: yup
      .string()
      .typeError('')
      .when('certifyingBoard', {
        is: (boardId: string): boolean =>
          isFieldRequired({
            fieldConfigs: boardConfigs?.[boardId]?.boardLearnerCollectionFields,
            fieldType: BoardLearnerCollectionFields.DEA_REGISTRATION,
          }),
        otherwise: (schema: yup.StringSchema): yup.StringSchema => schema.notRequired(),
        then: (schema: yup.StringSchema): yup.StringSchema => schema.required('DEA registration is required'),
      }),
    learnerCredits: yup.object().when(['certifyingBoard', 'totalCredits'], {
      is: (certifyingBoard: string, totalCredits: number): boolean => {
        // Check to see if the board is certifying. We only want to run the `then` validation if this is true.
        const currentBoard: IBCTBoard = blueprint?.boardConfigs?.[certifyingBoard];
        const isCertifyingBoard: boolean = currentBoard?.type === BoardTypes.CERTIFYING_BOARD;

        // Check to see if CREDITS_EARNED_PER_CREDIT_TYPE is required.
        const isRequired: boolean =
          currentBoard?.boardLearnerCollectionFields?.find(
            ({ fieldType }: IBCTBoardLearnerCollectionField): boolean =>
              fieldType === BoardLearnerCollectionFields.CREDITS_EARNED_PER_CREDIT_TYPE,
          )?.collectionType === CollectionTypes.REQUIRED;

        instanceMaxCredits = totalCredits;
        instanceCertifyingBoardId = certifyingBoard;

        return !!certifyingBoard && isCertifyingBoard && isRequired;
      },
      otherwise: yup.object().notRequired(),
      then: (schema: yup.AnyObjectSchema): yup.AnyObjectSchema => {
        return schema.shape(buildLearnerCreditsSchema(blueprint, instanceCertifyingBoardId, instanceMaxCredits)).test({
          // TODO: figure out if this has any effect - I don't _think_ it does - it's putting an error on learnerCredits, but that's a dictionary and doesn't appear to be able to have errors itself
          message: 'Missing credit type amount(s).',
          name: 'credits',
          test: (val): boolean => {
            // Sniff out if there are any checked checkboxes.
            if (typeof instanceMaxCredits !== 'undefined') {
              // This statement to check if there is any checkbox is checked.
              const checkedBoxes = Object.entries(val).filter(
                ([key, value]) => value === true && key.includes('learnerCredits'),
              );

              // This statement to check if learner credits are specified.
              const learnerCreditsWithValues = Object.entries(val).filter(
                ([key, value]) => typeof value !== 'undefined' && !key.includes('learnerCredits'),
              );
              // 4655 - If there there any checkbox checked and it has value, then it's valid.
              return !!checkedBoxes.length && !!learnerCreditsWithValues.length;
            } else {
              return true;
            }
          },
        });
      },
    }),
    practiceArea: yup
      .string()
      .typeError('')
      .when('certifyingBoard', {
        is: (boardId: string): boolean =>
          isFieldRequired({
            fieldConfigs: boardConfigs?.[boardId]?.boardLearnerCollectionFields,
            fieldType: BoardLearnerCollectionFields.PRACTICE_AREA,
          }),
        otherwise: (schema: yup.StringSchema): yup.StringSchema => schema.notRequired(),
        then: (schema: yup.StringSchema): yup.StringSchema => schema.required('Practice area is required'),
      }),
    practiceState: yup
      .string()
      .typeError('')
      .when('certifyingBoard', {
        is: (boardId: string): boolean =>
          isFieldRequired({
            fieldConfigs: boardConfigs?.[boardId]?.boardLearnerCollectionFields,
            fieldType: BoardLearnerCollectionFields.PRACTICE_STATE,
          }),
        otherwise: (schema: yup.StringSchema): yup.StringSchema => schema.notRequired(),
        then: (schema: yup.StringSchema): yup.StringSchema => schema.required('Practice state is required'),
      }),
    profession: yup
      .string()
      .typeError('')
      .when('certifyingBoard', {
        is: (boardId: string): boolean =>
          isFieldRequired({
            fieldConfigs: boardConfigs?.[boardId]?.boardLearnerCollectionFields,
            fieldType: BoardLearnerCollectionFields.PROFESSION,
          }),
        otherwise: (schema: yup.StringSchema): yup.StringSchema => schema.notRequired(),
        then: (schema: yup.StringSchema): yup.StringSchema => schema.required('Profession area is required'),
      }),
    providerLearnerId: yup
      .string()
      .typeError('')
      .when('certifyingBoard', {
        is: (boardId: string): boolean =>
          isFieldRequired({
            fieldConfigs: boardConfigs?.[boardId]?.boardLearnerCollectionFields,
            fieldType: BoardLearnerCollectionFields.PROVIDER_LEARNER_ID,
          }),
        otherwise: (schema: yup.StringSchema): yup.StringSchema => schema.notRequired(),
        then: (schema: yup.StringSchema): yup.StringSchema => schema.required('Learner ID is required'),
      }),
    timeInPractice: yup
      .string()
      .typeError('')
      .when('certifyingBoard', {
        is: (boardId: string): boolean =>
          isFieldRequired({
            fieldConfigs: boardConfigs?.[boardId]?.boardLearnerCollectionFields,
            fieldType: BoardLearnerCollectionFields.TIME_IN_PRACTICE,
          }),
        otherwise: (schema: yup.StringSchema): yup.StringSchema => schema.notRequired(),
        then: (schema: yup.StringSchema): yup.StringSchema => schema.required('Time in practice is required'),
      }),
    totalCredits: yup
      .number()
      .typeError('')
      .when('certifyingBoard', {
        is: (boardId: string): boolean =>
          isFieldRequired({
            fieldConfigs: boardConfigs?.[boardId]?.boardLearnerCollectionFields,
            fieldType: BoardLearnerCollectionFields.CREDIT_TYPE,
          }),
        otherwise: (schema) => schema.notRequired(),
        then: (schema: yup.NumberSchema): yup.NumberSchema =>
          schema
            .required('Total credits is required')
            .test({
              message: 'Credits must be greater than 0',
              name: 'minCreditsTest',
              test: (val: number, { resolve }) => {
                const boardId: string = resolve(yup.ref('certifyingBoard')) as string;
                if (!boardId) {
                  return true;
                }
                if (typeof val !== 'number') {
                  return true;
                }
                const minCredits: number = boardConfigs?.[boardId]?.creditIncrement || 0;
                return val >= minCredits;
              },
            })
            .test({
              message: 'Credits cannot exceed max for this activity',
              name: 'creditLimitTest',
              test: (val: number, { resolve }: yup.TestContext<ICertifyingBoardFormModel>): boolean => {
                const boardId: string = resolve(yup.ref('certifyingBoard')) as string;
                if (!boardConfigs?.[boardId]) {
                  return true;
                }
                if (boardConfigs[boardId]?.type === BoardTypes.REMS) {
                  return true;
                }
                if (isMaxCreditsLimited) {
                  return (
                    typeof boardId === 'string' &&
                    typeof val === 'number' &&
                    val <= boardActivityDetails?.[boardId]?.mocPointsGiven
                  );
                }
                return true;
              },
            })
            .test({
              message: 'Sum of individual credits cannot exceed total credits',
              name: 'additiveCreditsTest',
              test: (totalCredits: number, { resolve }: yup.TestContext<ICertifyingBoardFormModel>): boolean => {
                // Skip if no max on total credits for this activity
                if (!maxTotalCredits) {
                  return true;
                }
                const boardId: string = resolve(yup.ref('certifyingBoard')) as string;
                const learnerCredits: IDictionary<boolean | number> = resolve(yup.ref('learnerCredits')) as IDictionary<
                  boolean | number
                >;
                // Skip if board isn't selected
                if (!boardId || !learnerCredits) {
                  return true;
                }
                const boardConfig = boardConfigs?.[boardId];
                // Skip if board config isn't available
                if (!boardConfig) {
                  return true;
                }
                const additiveCreditIds = boardConfig?.creditTypes
                  ?.filter(({ isAdditive }) => isAdditive)
                  ?.map(({ id }) => id);
                // Skip if no credit types for this board are additive
                if (!additiveCreditIds?.length) {
                  return true;
                }
                const selectedAdditiveCreditIds: number[] = additiveCreditIds
                  .filter((id) => typeof learnerCredits[id] === 'number')
                  .map((id) => learnerCredits[id] as number);
                // Skip if no additive credit types are selected
                if (!selectedAdditiveCreditIds?.length) {
                  return true;
                }
                const totalSelectedCredits: number = selectedAdditiveCreditIds.reduce(
                  (accumulator: number, currentValue: number): number => accumulator + currentValue,
                );
                if (totalSelectedCredits > totalCredits) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Invalid credit type amount(s).',
              name: 'additiveCreditsTest2',
              test: (totalCredits: number, { resolve }: yup.TestContext<ICertifyingBoardFormModel>): boolean => {
                // Skip if no max on total credits for this activity
                if (!maxTotalCredits) {
                  return true;
                }
                const boardId: string = resolve(yup.ref('certifyingBoard')) as string;
                const learnerCredits: IDictionary<boolean | number> = resolve(yup.ref('learnerCredits')) as IDictionary<
                  boolean | number
                >;
                // Skip if board isn't selected
                if (!boardId || !learnerCredits) {
                  return true;
                }
                const boardConfig = boardConfigs?.[boardId];
                // Skip if board config isn't available
                if (!boardConfig) {
                  return true;
                }
                const additiveCreditIds = boardConfig?.creditTypes
                  ?.filter(({ isAdditive }) => isAdditive)
                  ?.map(({ id }) => id);
                // Skip if no credit types for this board are additive
                if (!additiveCreditIds?.length) {
                  return true;
                }
                const selectedAdditiveCreditAmount: number[] = additiveCreditIds
                  .filter((id) => typeof learnerCredits[id] === 'number')
                  .map((id) => learnerCredits[id] as number);
                // Skip if no additive credit types are selected
                if (!selectedAdditiveCreditAmount?.length) {
                  return true;
                }
                const totalSelectedCredits: number = selectedAdditiveCreditAmount.reduce(
                  (accumulator: number, currentValue: number): number => accumulator + currentValue,
                );
                // 4655 Sum of the amount(s) entered in the individual credit type does not add up to total credit amount, show error message.
                if (totalSelectedCredits !== totalCredits) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Invalid credit type amount(s).',
              name: 'nonAdditiveCreditsTest',
              test: (totalCredits: number, { resolve }: yup.TestContext<ICertifyingBoardFormModel>): boolean => {
                // Skip if no max on total credits for this activity
                if (!maxTotalCredits) {
                  return true;
                }
                const boardId: string = resolve(yup.ref('certifyingBoard')) as string;
                const learnerCredits: IDictionary<boolean | number> = resolve(yup.ref('learnerCredits')) as IDictionary<
                  boolean | number
                >;
                // Skip if board isn't selected
                if (!boardId || !learnerCredits) {
                  return true;
                }
                const boardConfig = boardConfigs?.[boardId];
                // Skip if board config isn't available
                if (!boardConfig) {
                  return true;
                }
                const nonadditiveCreditIds = boardConfig?.creditTypes
                  ?.filter(({ isAdditive }) => !isAdditive)
                  ?.map(({ id }) => id);
                // Skip if no credit types for this board are non additive
                if (!nonadditiveCreditIds?.length) {
                  return true;
                }
                const selectedNonAdditiveCreditAmount: number[] = nonadditiveCreditIds
                  .filter((id) => typeof learnerCredits[id] === 'number')
                  .map((id) => learnerCredits[id] as number);
                // Skip if no additive credit types are selected
                if (!selectedNonAdditiveCreditAmount?.length) {
                  return true;
                }

                let isValidCreditAmount = false;

                selectedNonAdditiveCreditAmount.forEach(function (nonAdditiveCreditAmount) {
                  if (nonAdditiveCreditAmount === totalCredits) {
                    isValidCreditAmount = true;
                  }
                });
                if (isValidCreditAmount) {
                  return true;
                } else {
                  return false;
                }
              },
            })
            .test({
              message: 'Missing credit type amount(s).',
              name: 'credits',
              test: (_totalCredits: number, { resolve }: yup.TestContext<ICertifyingBoardFormModel>): boolean => {
                // Sniff out if there are any checked checkboxes.
                if (typeof instanceMaxCredits !== 'undefined') {
                  const boardId: string = resolve(yup.ref('certifyingBoard')) as string;
                  const learnerCredits: IDictionary<boolean | number> = resolve(
                    yup.ref('learnerCredits'),
                  ) as IDictionary<boolean | number>;
                  // Skip if board isn't selected
                  if (!boardId || !learnerCredits) {
                    return true;
                  }
                  // This statement to check if there is any checkbox is checked.
                  const checkedBoxes = Object.entries(learnerCredits).filter(
                    ([key, value]) => value === true && key.includes('learnerCredits'),
                  );

                  // This statement to check if learner credits are specified.
                  const learnerCreditsWithValues = Object.entries(learnerCredits).filter(
                    ([key, value]) => typeof value !== 'undefined' && !key.includes('learnerCredits'),
                  );
                  // 4655 - If there there any checkbox checked and the same number have values, it's valid
                  return !!checkedBoxes.length && checkedBoxes.length === learnerCreditsWithValues.length;
                } else {
                  return true;
                }
              },
            }),
      }),
  });
};

export const buildAddLearnerSchema = (blueprint: ILearnerCompletionValidationBlueprint): yup.AnyObjectSchema => {
  const {
    boardConfigs,
    creditMaxClaimDate,
    endDate,
    isLearnerMatched,
    isMaxCreditsLimited,
    maxTotalCredits,
    startDate,
    amaCreditTerm,
  } = blueprint;

  if (!startDate || !endDate) {
    return yup.object().notRequired();
  }
  const increment = amaCreditTerm?.metadataNumber2 || 0.25;
  return yup
    .object()
    .shape({
      certifyingBoards: yup.array().of(buildBoardSchema(blueprint)),
      completionDate: yup
        .date()
        .typeError('Invalid Date Format')
        .test({
          message: 'Completion Date cannot be before activity start date',
          name: 'minCompletionDateTest',
          test: (completionDate: Date) => {
            const momentCompletionDate = moment(completionDate);
            const momentStartDate = moment(startDate);
            return !momentCompletionDate.isBefore(momentStartDate, 'days');
          },
        })
        .test({
          message: 'Completion Date cannot be in the future',
          name: 'maxCompletionDateTest',
          test: (completionDate: Date) => {
            const momentCompletionDate = moment(completionDate);
            const today = moment();
            return !today.isBefore(momentCompletionDate, 'days');
          },
        })
        .when(
          'certifyingBoards',
          (certifyingBoards: { certifyingBoard: string }[], schema: yup.DateSchema): yup.DateSchema => {
            // 4456/4640 - enforce completion date correctly based on the configuration of the selected boards
            const boards = certifyingBoards.map((i) => boardConfigs[i.certifyingBoard]).filter(Boolean);
            // 1. If there are one or more MOC boards on the completion that do not allow credit after the credit claim date, the completion date must be before the activity's credit claim date.
            if (
              boards.some(
                (i) => i.type === BoardTypes.CERTIFYING_BOARD && !i.allowLearnerCompletionDatesAfterCreditClaimDate,
              )
            ) {
              return schema.test({
                message: 'Completion Date must be on or before max credit claim date',
                name: 'completionBeforeActivityEndDate',
                test: (val: Date) =>
                  creditMaxClaimDate ? !moment(val).isAfter(moment(creditMaxClaimDate), 'days') : true,
              });
            }

            // 2. Otherwise, if there are any MOC boards on the completion (which all must allow credit after claim date, or else we'd be in scenario 1), there is no limit to how late the completion date can be
            if (boards.some((i) => i.type === BoardTypes.CERTIFYING_BOARD)) {
              return schema;
            }

            // 3. Otherwise, the completion date must be on or before the activity end date
            return schema.test({
              message: 'Completion Date must be on or before activity end date',
              name: 'completionBeforeActivityEndDate',
              test: (val: Date) => !moment(val).isAfter(moment(endDate), 'days'),
            });
          },
        )
        .when(['certifyingBoards', 'credits'], {
          is: (boardList: ICertifyingBoardFormModel[], credits: IDictionary<boolean | number>): boolean =>
            someBoardOrCmeRequired({
              boardConfigs,
              boardList,
              credits,
              fieldType: BoardLearnerCollectionFields.COMPLETION_DATE,
            }),
          otherwise: (schema: yup.DateSchema): yup.DateSchema => schema.notRequired(),
          then: (schema: yup.DateSchema): yup.DateSchema => schema.required('Date completed is required'),
        }),
      credits: yup.object().shape({
        cmeCredit: yup
          .number()
          .test({
            message: 'Required to report for CME credits',
            name: 'cmeCreditMinTest',
            test: (val: number | boolean, { resolve }: yup.TestContext<IAddLearnersFormModel>): boolean => {
              const isCmeCreditsChecked: boolean = resolve(yup.ref('creditscmeCredit')) as boolean;
              return isCmeCreditsChecked ? typeof val === 'number' && val >= 0 : true;
            },
          })
          .test({
            message: 'CME credits cannot be greater than the maximum reported for this activity',
            name: 'cmeCreditMaxTest',
            test: (val: number | boolean, { resolve }: yup.TestContext<IAddLearnersFormModel>): boolean => {
              const isCmeCreditsChecked: boolean = resolve(yup.ref('creditscmeCredit')) as boolean;
              if (isCmeCreditsChecked && isMaxCreditsLimited) {
                return typeof val === 'number' && val <= maxTotalCredits;
              }
              return true;
            },
          })
          .test({
            message: 'Credits must be in increments of ' + increment,
            name: 'creditIncrementTest',
            test: (val: number, { resolve }: yup.TestContext<IAddLearnersFormModel>): boolean => {
              const isCmeCreditsChecked: boolean = resolve(yup.ref('creditscmeCredit')) as boolean;

              if (isCmeCreditsChecked && typeof val === 'number' && typeof increment === 'number') {
                return isAlmostInteger(val / increment);
              }
              return true;
            },
          }),
      }),
      dateOfBirth: yup
        .date()
        .typeError('Invalid Date Format')
        .when(['certifyingBoards', 'credits'], {
          is: (boardList: ICertifyingBoardFormModel[], credits: IDictionary<boolean | number>): boolean =>
            someBoardOrCmeRequired({
              boardConfigs,
              boardList,
              credits,
              fieldType: BoardLearnerCollectionFields.DATE_OF_BIRTH,
            }),
          otherwise: (schema: yup.DateSchema): yup.DateSchema => schema.notRequired(),
          then: (schema: yup.DateSchema): yup.DateSchema => schema.required('Date of birth is required'),
        }),
      firstName: yup
        .string()
        .typeError('')
        .when(['certifyingBoards', 'credits'], {
          is: (boardList: ICertifyingBoardFormModel[], credits: IDictionary<boolean | number>): boolean =>
            someBoardOrCmeRequired({
              boardConfigs,
              boardList,
              credits,
              fieldType: BoardLearnerCollectionFields.FIRST_NAME,
            }),
          otherwise: (schema: yup.StringSchema): yup.StringSchema => schema.notRequired(),
          then: (schema: yup.StringSchema): yup.StringSchema => schema.required('First name is required'),
        }),
      lastName: yup
        .string()
        .typeError('')
        .when(['certifyingBoards', 'credits'], {
          is: (boardList: ICertifyingBoardFormModel[], credits: IDictionary<boolean | number>): boolean =>
            someBoardOrCmeRequired({
              boardConfigs,
              boardList,
              credits,
              fieldType: BoardLearnerCollectionFields.LAST_NAME,
            }),
          otherwise: (schema: yup.StringSchema): yup.StringSchema => schema.notRequired(),
          then: (schema: yup.StringSchema): yup.StringSchema => schema.required('Last name is required'),
        }),
      licenseId: yup
        .string()
        .typeError('')
        .when(['firstName', 'lastName', 'dateOfBirth', 'stateName'], {
          is: (firstName: string, lastName: string, dateOfBirth: Date, stateName: string): boolean =>
            !isLearnerMatched && !!firstName && !!lastName && !!dateOfBirth && !!stateName,
          otherwise: (schema: yup.StringSchema): yup.StringSchema => schema.notRequired(),
          then: (schema: yup.StringSchema): yup.StringSchema => schema.required('Licensing ID is required'),
        }),
      medicalSchoolOption: yup.mixed().notRequired(),
      npi: yup
        .string()
        .when(['credits'], {
          is: (credits: IDictionary<boolean | number>): boolean =>
            someBoardOrCmeRequired({
              credits,
            }),
          otherwise: (schema: yup.StringSchema): yup.StringSchema => schema.notRequired(),
          then: (schema: yup.StringSchema): yup.StringSchema =>
            schema.test({
              message: 'NPI must be a 10-digit number',
              name: 'npiRegexTest',
              test: (value: string): boolean => (typeof value === 'string' && npiRegex.test(value)) || isLearnerMatched,
            }),
        })
        .typeError('')
        .notRequired(),
      stateName: yup
        .object()
        .typeError('')
        .nullable(true)
        .when(['certifyingBoards', 'credits'], {
          is: (boardList: ICertifyingBoardFormModel[], credits: IDictionary<boolean | number>): boolean =>
            someBoardOrCmeRequired({
              boardConfigs,
              boardList,
              credits,
              fieldType: BoardLearnerCollectionFields.STATE_OF_LICENSURE,
            }),
          otherwise: (schema: yup.AnyObjectSchema): yup.AnyObjectSchema => schema.notRequired(),
          then: (schema: yup.AnyObjectSchema): yup.AnyObjectSchema =>
            schema.typeError('').required('Licensing state is required'),
        }),
    })
    .notRequired();
};
