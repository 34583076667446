import { ReactElement, useState, ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { VisibilityRounded, EditRounded, DeleteRounded, HelpRounded, DoneRounded } from '@material-ui/icons';
import { Typography, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';
import uniqueId from 'lodash/uniqueId';

// Components
import Button from 'components/Button/Button';
import CustomTooltip from '../Tooltip/Tooltip';
import { Modal } from 'components/ContinuousImprovement/Modal';
import { DeleteLearnerRecord } from 'components/ContinuousImprovement/Modal/templates/delete-learner-record';

// Styles
import styles from './learnersearchtable.module.scss';

// Store + Core
import { BreakpointsEnum, ButtonVariant, StatusEnum } from 'core/enums';
import {
  IBoardCredits,
  IBoardSlim,
  ILearnerSearchActivity,
  IUserOrganization,
  OrganizationKind,
  OrganizationKinds,
} from 'core/models';
import { MESSAGE_CANNOT_EDIT_DELETE_OLD_COMPLETIONS, staffProvider } from 'core/constants';
import { closeModal, openModal } from 'store/modal/actions';
import { isModalOpenSelector } from 'store/modal/selectors';
import { isProviderUserSelector, organizationKindSelector } from 'store/user/selectors';
import { updateLearnerSearchStateProps } from 'store/learner/actions';
import { learnerSearchCurrentPageSelector, searchLearnerSelectedSelector } from 'store/learner/selectors';
import { deleteLearnerCompletion } from 'store/activity/actions';
import { getAllBoards } from 'store/board/actions';
import { boardSelector } from 'store/board/selectors';
import { EditCompletionForm } from '../forms/templates/EditCompletionForm';

// Utils
import { calculateStatusTitle } from 'utils';

// Types
interface IProps {
  omitActivityColumn?: boolean;
  results: ILearnerSearchActivity[];
  selectedAll: boolean;
}

export const LearnerSearchTable = (props: IProps): ReactElement => {
  const [activeLearner, setActiveLearner] = useState<ILearnerSearchActivity>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const { omitActivityColumn, results, selectedAll } = props;
  const dispatch = useDispatch();
  const isSmallBreakpoint = useMediaQuery(`(max-width:${BreakpointsEnum.sm})`);

  // Selectors.
  const isModalOpen: boolean = useSelector(isModalOpenSelector);
  const { organizationKind }: IUserOrganization = useSelector(organizationKindSelector);
  const currentPage: number = useSelector(learnerSearchCurrentPageSelector);
  const selectedLearners: ILearnerSearchActivity[] = useSelector(searchLearnerSelectedSelector);
  const isProvider: boolean = useSelector(isProviderUserSelector);
  const boards: IBoardSlim[] = useSelector(boardSelector);

  useEffect(() => {
    if (!boards) {
      dispatch(getAllBoards());
    }
  }, [boards, dispatch]);

  const now = moment();
  const dtAttrFormat = 'YY-M-DD';
  const dtDisplayFormat = 'M/DD/YY';

  const isStaffProvider: boolean = staffProvider.includes((organizationKind as OrganizationKind) as OrganizationKinds);

  const onOpenModal = (learner: ILearnerSearchActivity): void => {
    setActiveLearner(learner);
    dispatch(openModal());
  };
  const onDeleteLearner = async (learner: ILearnerSearchActivity): Promise<void> => {
    const { activityGuid, completionId, completionSource, completionIdentifier } = learner;
    await dispatch(deleteLearnerCompletion({ activityGuid, completionId, completionSource, completionIdentifier }));
    dispatch(closeModal());
  };
  const onOpenEditModal = (learner: ILearnerSearchActivity): void => {
    setActiveLearner(learner);
    setIsEditModalOpen(true);
  };

  const onSelectAll = (): void => {
    if (!selectedAll) {
      dispatch(updateLearnerSearchStateProps({ page: currentPage, selectedLearners: results }));
    } else {
      dispatch(updateLearnerSearchStateProps({ page: currentPage, selectedLearners: [] }));
    }
  };
  const onToggleSingleCheck = ({
    e,
    learner,
  }: {
    e: ChangeEvent<HTMLInputElement>;
    learner: ILearnerSearchActivity;
  }): void => {
    const isChecked: boolean = e.target.checked;
    if (isChecked) {
      dispatch(updateLearnerSearchStateProps({ page: currentPage, selectedLearners: [...selectedLearners, learner] }));
    } else {
      dispatch(
        updateLearnerSearchStateProps({
          page: currentPage,
          selectedLearners: selectedLearners.filter(
            (omitted: ILearnerSearchActivity): boolean => omitted.key !== learner.key,
          ),
        }),
      );
    }
  };

  const activeLearnerRecordLabel = activeLearner?.board ?? activeLearner?.rems ?? activeLearner?.state;

  return (
    <div className={styles['learner-search-container']}>
      <table className={styles['learner-search-table']}>
        <thead className={styles['learner-search-table-thead']}>
          <tr>
            {isStaffProvider && (
              <th className={styles.select}>
                <div className={styles['actions--checkbox']}>
                  <label>
                    <input aria-label="select all" type="checkbox" checked={selectedAll} onChange={onSelectAll} />
                    <span className="checkmark" />
                    <Typography variant="srOnly">toggle all selected</Typography>
                  </label>
                </div>
              </th>
            )}
            <th>Board</th>
            <th className={styles['learner-name']}>Name</th>
            <th>DOB</th>
            <th>Learner ID</th>
            {!omitActivityColumn && <th>Activity</th>}
            <th>Completion</th>
            <th>Submission</th>
            <th>Credits Awarded</th>
            <th>Status</th>
            {isSmallBreakpoint && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {!!results?.length &&
            results?.map(
              (item: ILearnerSearchActivity): ReactElement => {
                const {
                  activityId,
                  activityGuid,
                  activityName,
                  board,
                  boardName,
                  credits,
                  completionDate,
                  submissionDate,
                  completionId,
                  completionSource,
                  key,
                  learnerDOBDay,
                  learnerDOBMonth,
                  learnerId,
                  learnerName,
                  learnerStatus,
                  rejectionReason,
                  state,
                  stateName,
                  rems,
                  remsName,
                  totalCredits,
                  ulid,
                } = item;
                const isChecked = !!selectedLearners?.find(
                  (learner: ILearnerSearchActivity): boolean => learner.key === key,
                );
                const route: string = ulid
                  ? `/learners/${activityGuid}/learnercompletions/bylearner/${ulid}`
                  : `/learners/${activityGuid}/learnercompletions/bycompletion/${completionId}`;
                const completionDateMoment = moment(completionDate);
                const submissionDateMoment = moment(submissionDate);
                // We show `Submitted` as a status if the user is a Provider instead of `Not Processed`.
                // A Provider user should never see `Not Processed`.
                const facetValue: StatusEnum = calculateStatusTitle({ isProvider, statusTitle: learnerStatus });

                // get board CCT data by abbreviation since `item` doesn't have a boardId
                const cctBoard = boards?.find(({ abbreviation }) => abbreviation === board);

                const cctLastEditDate = cctBoard?.boardLastLearnerCompletionRemovalDate?.find(
                  (i) => i.year === completionDateMoment.year(),
                )?.cutoffDate;
                const canEditDelete = !cctLastEditDate || moment(cctLastEditDate).isAfter(now, 'day');

                const UtilityActions = (): ReactElement => (
                  <div className={styles.utility}>
                    <Link className="btn label" to={route}>
                      <VisibilityRounded />
                      View
                    </Link>
                    {isStaffProvider && (
                      <>
                        {!true && (
                          <Button
                            id="editLearner"
                            variant={ButtonVariant.Label}
                            onClick={(): void => onOpenEditModal(item)}
                            disabled={!canEditDelete}
                            disabledMessage={MESSAGE_CANNOT_EDIT_DELETE_OLD_COMPLETIONS}
                          >
                            <EditRounded />
                            Edit
                          </Button>
                        )}
                        <Button
                          variant={ButtonVariant.Label}
                          onClick={(): void => onOpenModal(item)}
                          disabled={!canEditDelete}
                          disabledMessage={MESSAGE_CANNOT_EDIT_DELETE_OLD_COMPLETIONS}
                        >
                          <DeleteRounded />
                          Delete
                        </Button>
                      </>
                    )}
                  </div>
                );

                return (
                  <tr key={key}>
                    {isStaffProvider && (
                      <td className={styles.select}>
                        <div className={styles['actions--checkbox']}>
                          <label>
                            <input
                              aria-label={`select ${board}`}
                              id={key}
                              type="checkbox"
                              checked={isChecked}
                              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                                onToggleSingleCheck({ e, learner: item })
                              }
                            />
                            <span className="checkmark" />
                            <Typography variant="srOnly">{`select ${board}`}</Typography>
                          </label>
                        </div>
                      </td>
                    )}
                    <td title={boardName ?? remsName ?? stateName}>
                      {board ?? rems ?? state}
                      {!isSmallBreakpoint && <UtilityActions />}
                    </td>
                    <td className={styles['learner-name']}>
                      {learnerName.trim() ? (
                        <Link className="link link--hyperlink" to={route}>
                          {learnerName}
                          <Typography variant="srOnly">{`/learners/${activityGuid}/learnercompletions/${ulid}/${activityName}`}</Typography>
                        </Link>
                      ) : (
                        <Typography align="left">--</Typography>
                      )}
                    </td>
                    <td className={styles['learner-dob']}>
                      <time dateTime={`${learnerDOBMonth}/${learnerDOBDay}`}>
                        {learnerDOBDay && learnerDOBMonth ? (
                          <>
                            {learnerDOBMonth}/{learnerDOBDay}
                          </>
                        ) : (
                          <Typography align="left">--</Typography>
                        )}
                      </time>
                    </td>
                    <td className={styles['learner-id']}>{learnerId || <Typography align="left">--</Typography>}</td>
                    {!omitActivityColumn && (
                      <td className={styles.activity}>
                        <div className={styles['activity-name']}>
                          {activityName || <Typography align="left">--</Typography>}
                        </div>
                        <div className={styles['activity-id']}>
                          {activityId ? `Activity ID: ${activityId}` : <Typography align="left">--</Typography>}
                        </div>
                      </td>
                    )}
                    <td className={styles['completion-date']}>
                      <time dateTime={completionDateMoment.format(dtAttrFormat)}>
                        {completionDateMoment.format(dtDisplayFormat) || <Typography align="left">--</Typography>}
                      </time>
                    </td>
                    <td className={styles['completion-date']}>
                      <time dateTime={submissionDateMoment.format(dtAttrFormat)}>
                        {submissionDateMoment.format(dtDisplayFormat) || <Typography align="left">--</Typography>}
                      </time>
                    </td>
                    <td className={styles.credits}>
                      {isNumber(totalCredits) && completionSource === 'Board' && (
                        <div className={styles['credits-total']}>{totalCredits} Total Earned</div>
                      )}
                      {credits?.length ? (
                        credits?.map(
                          (credits: IBoardCredits): ReactElement => {
                            const { name } = credits;
                            const isApproved = false; // TODO: we're not getting this data from the server at all, and I'm not sure if that's a bug
                            const creditsKey: string = uniqueId();

                            return (
                              <div key={creditsKey}>
                                {isApproved ? (
                                  <div className={styles['has-icon']}>
                                    <DoneRounded />
                                    {name}
                                  </div>
                                ) : (
                                  <>
                                    {/* REMS does not have credits and we should render -- for REMS. */}
                                    {rems ? (
                                      <Typography align="left" color="primary">
                                        --
                                      </Typography>
                                    ) : (
                                      <>
                                        {credits.credits} {name}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            );
                          },
                        )
                      ) : (
                        <>No credits for this activity</>
                      )}
                    </td>
                    <td className={styles['credit-status']} style={{ whiteSpace: 'nowrap' }}>
                      {learnerStatus === StatusEnum.REJECTED && rejectionReason ? (
                        <div className={classNames(styles.status, [learnerStatus])}>
                          <CustomTooltip tooltipText={rejectionReason}>
                            {learnerStatus}
                            <HelpRounded />
                          </CustomTooltip>
                        </div>
                      ) : (
                        <div className={classNames(styles.status, [facetValue])}>
                          {facetValue || (
                            <Typography align="left" color="primary">
                              --
                            </Typography>
                          )}
                        </div>
                      )}
                    </td>
                    {isSmallBreakpoint && (
                      <td>
                        <UtilityActions />
                      </td>
                    )}
                  </tr>
                );
              },
            )}
        </tbody>
      </table>
      {activeLearner && (
        <>
          <Modal
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            title={`Edit ${activeLearnerRecordLabel} Record`}
          >
            <EditCompletionForm
              completionSearchResult={activeLearner}
              onCloseCallback={() => setIsEditModalOpen(false)}
            />
          </Modal>
          <Modal
            isOpen={isModalOpen}
            title={`Delete ${activeLearnerRecordLabel} Learner Record`}
            onClose={() => dispatch(closeModal())}
          >
            <DeleteLearnerRecord
              learnerRecord={activeLearner}
              onCancel={() => dispatch(closeModal())}
              onProceed={() => onDeleteLearner(activeLearner)}
            />
          </Modal>
        </>
      )}
      {!results?.length && <div className={styles['no-results']}>No Records to Display</div>}
    </div>
  );
};
