/* eslint-disable sort-keys */
import * as yup from 'yup';
import { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { KeyboardBackspaceRounded } from '@material-ui/icons';
import classNames from 'classnames';

// style
import styles from './AddEditProvider.module.scss';
import fieldStyles from 'components/ContinuousImprovement/PureFormElements/FormikFields.module.scss';
import { FormikProvider, useFormik } from 'formik';
import { CircularProgress, Grid } from '@material-ui/core';
import {
  IOrganizationStatus,
  IOrganizationType,
  IOrganizationProvider,
  RollupOrganizationEnums,
  IUserOrganization,
} from 'core/models';
import { useLoadData } from 'hooks/useLoadData';
import Dropdown from 'components/Dropdown/Dropdown';
import { FormikInputField } from 'components/ContinuousImprovement/PureFormElements';
import { useCountries, useStates } from 'store/locations/hooks';

import Button from 'components/Button/Button';
import { ButtonVariant, OrgEnum } from 'core/enums';
import { useAccountManagerInfo } from '../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { userProfileSelector } from 'store/user/selectors';
import { sortBy } from 'lodash';
import { filterItemsByRollupOrganizationEnum } from 'globals/utils/filterItemsByRollupOrgCode';
import { errorToastOptions, successToastOptions } from 'store/toast/constants';
import { popToast } from 'store/toast/actions';
import { handleServerError } from 'globals/utils/handleServerError';
import { OrganizationProfileService } from 'services/OrganizationProfileService';
import { CacheService } from 'services';
import { Constants } from 'core';
import { FormikNumberRangeInputField } from 'components/ContinuousImprovement/PureFormElements/FormikNumberRangeInputField';

// SchemaOf (not SchemaObject) is used for our version of yup - https://github.com/jquense/yup/blob/v0.32.8/docs/typescript.md
const addEditSchema: yup.SchemaOf<
  Omit<
    IOrganizationProvider,
    | 'businessId'
    | 'id'
    | 'organizationKind'
    | 'childOrganizationIds'
    | 'customerId'
    | 'rollupOrganizationEnum'
    | 'securityGroups'
    | 'isPrimaryContact'
    | 'organizationNotes'
    | 'organizationStatus'
    | 'accreditorOrApprover'
    | 'addressLine2'
    | 'addressCountry'
    | 'phoneNumberCountryCode'
    | 'faxNumberCountryCode'
    | 'faxNumberAreaCode'
    | 'faxNumberLocal'
    | 'organizationType'
    | 'webAddress'
    | 'isApproverOfActivities'
    | 'accreditorName'
    | 'accreditorBusinessId'
  >
> = yup.object({
  organizationName: yup.string().required('Organization Name is required'),
  organizationStatusId: yup.string().required('Organization Status is required'),
  organizationTypeId: yup.string().required('Organization Type is required'),
  providerType: yup.string().required('Provider Type is required'),
  addressLine1: yup.string().required('Address is required'),
  addressCity: yup.string().required('City is required'),
  addressState: yup.string().required('State is required'),
  addressZipCode: yup.string().required('Zipcode is required'),
  phoneNumberAreaCode: yup
    .string()
    .max(3, 'Phone number country area can be maximum 3 characters')
    .required('Phone number country area is required'),
  phoneNumberLocal: yup.string().required('Phone number local is required'),
  accreditorApproverId: yup.string().required('Accreditor/Approver is required'),
});

export const AccountManagerAddEditProviderPage = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const [isAccreditorApproverDisabled, setIsAccreditorApproverDisabled] = useState(false);

  const countries = sortBy(useCountries(), 'countryName');
  const states = useStates();

  const dispatch = useDispatch();
  const history = useHistory();
  const [existingProvider, setExistingProvider] = useState<IOrganizationProvider | null>(null);

  const { canEdit, organization, isAnccOrganization } = useAccountManagerInfo();

  const { data: organizationTypes } = useLoadData(
    'OrganizationTypes',
    useCallback(async () => {
      return await OrganizationProfileService.getOrganizationTypes();
    }, [id]),
  );
  const { data: organizationStatus } = useLoadData(
    'OrganizationStatus',
    useCallback(async () => {
      return OrganizationProfileService.getOrganizationStatus();
    }, [id]),
  );

  const filteredOrganizationTypes: IOrganizationType[] = filterItemsByRollupOrganizationEnum(
    organizationTypes,
    organization.rollupOrganizationEnum,
  );

  let filteredOrganizationStatus: IOrganizationStatus[] = filterItemsByRollupOrganizationEnum(
    organizationStatus,
    organization.rollupOrganizationEnum,
  );

  filteredOrganizationStatus = isAnccOrganization
    ? filteredOrganizationStatus
    : filteredOrganizationStatus.filter((item) => item.name !== 'Accredited' && item.name !== 'Non-Accredited');

  const userProfile = useSelector(userProfileSelector);

  const organizationOptions = useMemo(
    () =>
      sortBy(
        userProfile?.primaryUserOrganizations.filter(
          (org) => org.rollupOrganizationEnum === RollupOrganizationEnums.NARS && org.organizationKind === 'Accreditor',
        ) || [],
        'organizationName',
        'businessId',
      ),
    [userProfile?.primaryUserOrganizations],
  );

  const anccOrganization: IUserOrganization = organizationOptions.find((o) => o.businessId === OrgEnum.ANCC);

  const handleAccreditorApprover = (event: ChangeEvent<HTMLSelectElement>): void => {
    const value: string = event.target.value;
    formik.setFieldValue('providerType', value);

    if (value === 'Approver of Providers') {
      setIsAccreditorApproverDisabled(true);
      formik.setFieldValue('accreditorApproverId', anccOrganization.id);
    } else {
      if (!isAnccOrganization) {
        setAccreditorApproverForNonAncc();
      } else {
        setIsAccreditorApproverDisabled(false);
        formik.setFieldValue('accreditorApproverId', '');
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (!isAnccOrganization) {
        setAccreditorApproverForNonAncc();
      }
      if (id) {
        try {
          const provider = await OrganizationProfileService.getOrganizationById(id);
          setExistingProvider(provider);
        } catch (error) {
          dispatch(popToast({ ...errorToastOptions, message: <>{handleServerError({ error }).errorMessage}</> }));
          history.push('/account-manager');
        }
      }
    })();
  }, [id, dispatch]);

  function setAccreditorApproverForNonAncc() {
    const nonAnccOrganizationId: IUserOrganization = organizationOptions.find((o) => o.id === organization.id);
    if (nonAnccOrganizationId) {
      formik.setFieldValue('accreditorApproverId', nonAnccOrganizationId.id);
      setIsAccreditorApproverDisabled(true);
    }
  }

  const formik = useFormik<IOrganizationProvider>({
    enableReinitialize: true,
    initialValues: { ...existingProvider } as IOrganizationProvider,
    onSubmit: async (values, { resetForm }) => {
      try {
        const {
          businessId,
          organizationKind,
          childOrganizationIds,
          organizationName,
          organizationStatusId,
          organizationTypeId,
          accreditorName,
          accreditorBusinessId,
          providerType,
          addressLine1,
          addressLine2,
          addressCity,
          addressState,
          addressCountry,
          phoneNumberAreaCode,
          phoneNumberLocal,
          addressZipCode,
          accreditorApproverId,
          webAddress,
        } = values;
        const baseReq: Omit<
          IOrganizationProvider,
          | 'customerId'
          | 'rollupOrganizationEnum'
          | 'securityGroups'
          | 'isPrimaryContact'
          | 'id'
          | 'isApproverOfActivities'
        > = {
          businessId,
          organizationKind,
          childOrganizationIds,
          organizationName,
          organizationStatusId,
          organizationTypeId,
          accreditorName,
          accreditorBusinessId,
          providerType,
          addressLine1,
          addressLine2,
          addressCity,
          addressState,
          addressCountry,
          phoneNumberAreaCode,
          phoneNumberLocal,
          addressZipCode,
          accreditorApproverId,
          webAddress,
        };
        const req = {
          ...baseReq,
          id: id,
          organizationId: organization.id,
        };
        if (id) {
          await OrganizationProfileService.updateOrganization(id, req);
          dispatch(popToast({ ...successToastOptions, message: <>Successfully updated.</> }));
          history.push('/account-manager');
        } else {
          await OrganizationProfileService.createOrganization(req);
          dispatch(popToast({ ...successToastOptions, message: <>Successfully created.</> }));
          CacheService.remove(Constants.CACHE_USER_CURRENT);
          resetForm();
        }
      } catch (error) {
        dispatch(popToast({ ...errorToastOptions, message: <>{handleServerError({ error }).errorMessage}</> }));
      }
    },
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: addEditSchema,
  });

  const nonAnccProviderTypeOption = ['Approved Provider'].map((i) => ({
    id: i,
    name: i,
  }));

  const allProviderTypeOptions = [
    'Accredited Approver',
    'Accredited Provider',
    'Approved Provider',
    'Approver of Providers',
    'Accredited International Provider',
  ].map((i) => ({
    id: i,
    name: i,
  }));

  const { isValid, isSubmitting, submitForm } = formik;
  const isDisabledOnEdit = !!id;

  if (!canEdit && !id) {
    return <Redirect to="/account-manager" />;
  }
  if (organization.rollupOrganizationEnum !== RollupOrganizationEnums.NARS) {
    return <Redirect to="/404" />;
  }

  return (
    <div className="form-container">
      <div className="page-navigation">
        <Link className="tertiary" to="/account-manager">
          <KeyboardBackspaceRounded className="tertiary-icon-back" />
          Account Management
        </Link>
      </div>
      <div className="form-title-container">
        <div className={classNames('title', styles.title)}>
          <h4>{id ? 'Edit' : 'Add New'} Provider</h4>
        </div>
      </div>
      <FormikProvider value={formik}>
        <div className="static-form">
          <Grid item xs={12} md={9} lg={6} className={styles.input}>
            <FormikInputField label="Organization Name *" formikKey="organizationName" />
            <label className={fieldStyles.label} htmlFor="organizationTypeId">
              Organization Type *
            </label>
            <Dropdown
              items={filteredOrganizationTypes}
              name="organizationTypeId"
              placeholder="Organization Type"
              idProp="id"
              labelProp="description"
            />
            <div className="MuiFormHelperText-root Mui-error">{formik.errors.organizationTypeId}</div>
            <label className={fieldStyles.label} htmlFor="organizationStatusId">
              Organization Status *
            </label>
            <Dropdown
              items={filteredOrganizationStatus}
              name="organizationStatusId"
              placeholder="Organization Status"
              idProp="id"
              labelProp="name"
            />
            <div className="MuiFormHelperText-root Mui-error">{formik.errors.organizationStatusId}</div>
            <label className={fieldStyles.label} htmlFor="title">
              Provider Type *
            </label>
            <Dropdown
              items={isAnccOrganization ? allProviderTypeOptions : nonAnccProviderTypeOption}
              name="providerType"
              placeholder="Provider Type"
              isDisabled={isDisabledOnEdit}
              onChange={handleAccreditorApprover}
            />
            <div className="MuiFormHelperText-root Mui-error">{formik.errors.providerType}</div>
            <label className={fieldStyles.label} htmlFor="accreditorApproverId">
              Accreditor/Approver *
            </label>
            <Dropdown
              items={organizationOptions}
              name="accreditorApproverId"
              placeholder="Accreditor/Approver"
              idProp="id"
              prefixField="businessId"
              labelProp="organizationName"
              isDisabled={isAccreditorApproverDisabled || isDisabledOnEdit}
            />
            <div className="MuiFormHelperText-root Mui-error">{formik.errors.accreditorApproverId}</div>
          </Grid>
          <Grid item xs={12} md={9} lg={6} className={styles.input}>
            <FormikInputField label="Street *" formikKey="addressLine1" />
            <FormikInputField label="" formikKey="addressLine2" />
            <FormikInputField label="City *" formikKey="addressCity" />
            <label className={fieldStyles.label} htmlFor="addressState">
              State *
            </label>
            <Dropdown
              items={states}
              name="addressState"
              placeholder="Address State"
              idProp="isoStateCode"
              labelProp="stateName"
            />
            <div className="MuiFormHelperText-root Mui-error">{formik.errors.addressState}</div>
            <FormikInputField label="Zip Code *" formikKey="addressZipCode" />
            <label className={fieldStyles.label} htmlFor="title">
              Country
            </label>
            <Dropdown
              items={countries}
              name="addressCountry"
              placeholder="Address Country"
              idProp="isoCountryCode"
              labelProp="countryName"
            />
            <FormikNumberRangeInputField label="Phone Area Code *" formikKey="phoneNumberAreaCode" />
            <FormikInputField label="Phone Local *" formikKey="phoneNumberLocal" />
            <FormikInputField label="Web Address" formikKey="webAddress" />
          </Grid>
          <Grid item xs={12} md={9} lg={6}>
            <div className={styles['submit-button-container']}>
              <Button
                disabled={!isValid || isSubmitting}
                onClick={submitForm}
                startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
                variant={ButtonVariant.Primary}
              >
                Save Changes
              </Button>
            </div>
          </Grid>
        </div>
      </FormikProvider>
    </div>
  );
};
