import { IContextualMenuProps } from 'components/ContextualMenu/ContextualMenu';
import { Constants } from 'core';
import { ISortLearnerSearchMenu } from './types';

/**
 * @function sortLearnerMenu
 * @param args
 * @description Dropdown items specifically for Learners Summary Sorting.
 */
export const sortLearnerMenu = (args: ISortLearnerSearchMenu): IContextualMenuProps => {
  const {
    sortBy,
    sortByBoard,
    sortByLearnerName,
    sortByLearnerId,
    sortByActivityTitle,
    sortByActivityId,
    sortByTotalCredits,
    sortByStatus,
  } = args;
  const sortOptions = [
    {
      name: 'Board',
      onSelectAction: sortByBoard,
      selected: sortBy === Constants.SORT_BY_BOARD,
    },
    {
      name: 'Learner Name',
      onSelectAction: sortByLearnerName,
      selected: sortBy === Constants.SORT_BY_LEARNER_NAME,
    },
    {
      name: 'Learner ID',
      onSelectAction: sortByLearnerId,
      selected: sortBy === Constants.SORT_BY_LEARNER_ID,
    },
    {
      name: 'Activity Title',
      onSelectAction: sortByActivityTitle,
      selected: sortBy === Constants.SORT_BY_LEARNER_ACTIVITY_TITLE,
    },
    {
      name: 'Activity ID',
      onSelectAction: sortByActivityId,
      selected: sortBy === Constants.SORT_BY_LEARNER_ACTIVITY_ID,
    },
    {
      name: 'Total Credits',
      onSelectAction: sortByTotalCredits,
      selected: sortBy === Constants.SORT_BY_TOTAL_CREDITS,
    },
    {
      name: 'Status',
      onSelectAction: sortByStatus,
      selected: sortBy === Constants.SORT_BY_STATUS,
    },
  ];

  return {
    label: 'Sort by: ',
    options: sortOptions,
  };
};
