import { Reducer } from 'redux';
import {
  BCTState,
  BCTTypes,
  POST_BCT_STEP,
  POST_BCT_STEP_FAILURE,
  POST_BCT_STEP_SUCCESS,
  SET_CURRENT_BCT_STEP,
  GET_BOARD_LIST,
  GET_BOARD_LIST_SUCCESS,
  GET_BOARD_LIST_FAILURE,
  GET_BOARD_SUCCESS,
  GET_BOARD,
  GET_BOARD_FAILURE,
  RESET_BCT,
  POST_BOARD_CONTENT_OUTLINES,
  POST_BOARD_CONTENT_OUTLINES_SUCCESS,
  POST_BOARD_CONTENT_OUTLINES_FAILURE,
} from './types';
import { BCTSteps } from 'core/models';

const initialState: BCTState = {
  boardList: undefined,
  creditTypesErrors: undefined,
  currentBoard: undefined,
  currentStep: BCTSteps.ORGANIZATION_DETAILS,
  error: undefined,
  isFileProcessing: false,
  isLoading: true,
  validationExceptions: undefined,
};

const BCTReducer: Reducer<BCTState, BCTTypes> = (state: BCTState = initialState, action: BCTTypes) => {
  switch (action.type) {
    case SET_CURRENT_BCT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };

    // Posting
    case POST_BCT_STEP:
    case POST_BCT_STEP_SUCCESS:
      return {
        ...state,
      };
    case POST_BCT_STEP_FAILURE:
      return {
        ...state,
        creditTypesErrors: action.payload.creditTypesErrors,
        error: action.payload.error,
        validationExceptions: action.payload.validationExceptions,
      };

    // All boards.
    case GET_BOARD_LIST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_BOARD_LIST_SUCCESS:
      return {
        ...state,
        boardList: action.payload,
        isLoading: false,
      };

    case GET_BOARD_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    // Single board.
    case GET_BOARD:
      return {
        ...state,
        isLoading: true,
      };

    case GET_BOARD_SUCCESS:
      return {
        ...state,
        currentBoard: action.payload,
        isLoading: false,
      };

    case GET_BOARD_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    // Uploading content outlines.
    case POST_BOARD_CONTENT_OUTLINES:
      return {
        ...state,
        isFileProcessing: true,
      };

    case POST_BOARD_CONTENT_OUTLINES_SUCCESS:
      return {
        ...state,
        isFileProcessing: false,
      };

    case POST_BOARD_CONTENT_OUTLINES_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFileProcessing: false,
        validationExceptions: action.payload.validationExceptions,
      };

    case RESET_BCT:
      return {
        ...initialState,
        boardList: state.boardList,
        creditTypesErrors: undefined,
        currentBoard: undefined,
        error: undefined,
        validationExceptions: undefined,
      };

    default:
      return state;
  }
};

export default BCTReducer;
