import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { History } from 'services';
import { MenuRounded, CloseRounded, ChevronRightRounded, KeyboardBackspaceRounded } from '@material-ui/icons';
import { Button, Grid } from '@material-ui/core';

// Assets
import { ReactComponent as AccmeLogo } from 'globals/images/accme-logo-mobile.svg';
import { ReactComponent as JALogo } from 'globals/images/ja-logo.svg';

// Components
import LogoutContainer from 'components/login/LogoutContainer';
import { OrganizationSelect } from '../../UserControl/OrganizationSelect';
import { ProviderSelect } from '../../UserControl/ProviderSelect';

// Styles
import styles from './mobileheader.module.scss';

// Core + Store
import {
  auth0UserSelector,
  currentOrganizationContextSelector,
  rollupOrganizationEnumSelector,
  organizationKindSelector,
  userProfileSelector,
} from 'store/user/selectors';
import {
  IAuth0User,
  ITaxonomyTerm,
  IUser,
  IUserOrganization,
  OrganizationKind,
  OrganizationKinds,
  RollupOrganizationEnums,
} from 'core/models';
import { filterTaxonomyByRollupOrganizationEnum } from 'globals/utils/filterTaxonomyByOrg';
import { navigationMap } from 'globals/constants/server-image-map';

const ANCCLogo = () => (
  // ANCC logo has no built-in padding and is much bigger, so add a class to add some in CSS
  <img className="ancc-logo" src={`${process.env.PUBLIC_URL}/logo-ancc-header-transparent.png`} alt="ANCC" />
);

interface MobileHeaderProps {
  items: ITaxonomyTerm[];
}

const MobileHeader = ({ items }: MobileHeaderProps): ReactElement => {
  const organizationKind: OrganizationKind = useSelector(organizationKindSelector)?.organizationKind;
  const isBoardUser: boolean = organizationKind === OrganizationKinds.BOARD;

  const [, setIsClickOutsideDisabled] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [subMenuOpen, setSubMenuOpen] = useState('');
  History.listen(() => {
    setMenuOpen(false);
    setSubMenuOpen('');
  });

  const menuToggle = () => {
    setMenuOpen(!menuOpen);
    setSubMenuOpen('');
  };

  const subMenuToggle = (key) => {
    setSubMenuOpen(key);
  };

  const subMenuBackToggle = () => {
    setSubMenuOpen('');
  };

  // Selectors
  const auth0User: IAuth0User = useSelector(auth0UserSelector);
  const currentOrganization: IUserOrganization = useSelector(currentOrganizationContextSelector);
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  const userProfile: IUser = useSelector(userProfileSelector);

  return (
    <nav className={classNames('mobile-nav')}>
      <div className="mobile-header">
        <div className="mobile-menu-control">
          <button title="menu button" type="button" onClick={menuToggle}>
            {menuOpen ? <CloseRounded /> : <MenuRounded />}
          </button>
        </div>
        <div className="mobile-logo">
          {rollupOrganizationEnum === RollupOrganizationEnums.JA ? (
            <JALogo />
          ) : rollupOrganizationEnum === RollupOrganizationEnums.NARS ? (
            <ANCCLogo />
          ) : (
            <AccmeLogo />
          )}
        </div>
      </div>
      <div className={classNames('mobile-main', { open: menuOpen })}>
        <ul className="menu" role="navigation">
          {(items || []).map((term, ix) => {
            const menu = term.id;
            const children = filterTaxonomyByRollupOrganizationEnum(term.terms ?? [], rollupOrganizationEnum);
            return (
              <li key={ix}>
                <Button
                  className="menu-btn"
                  title="menu expand"
                  type="button"
                  {...(children.length
                    ? {
                        onClick: () => subMenuToggle(menu),
                      }
                    : {
                        component: Link,
                        onClick: menuToggle,
                        to: term.description || '',
                      })}
                >
                  <span>{term.name}</span>
                  <ChevronRightRounded />
                </Button>
                {children.length > 0 && (
                  <CSSTransition in={subMenuOpen === menu} classNames="open" mountOnEnter timeout={300} unmountOnExit>
                    <div className="mobile-sub">
                      <button className="tertiary" title="previous menu" type="button" onClick={subMenuBackToggle}>
                        <KeyboardBackspaceRounded /> Back
                      </button>
                      <div className="menu" role="navigation">
                        <div className="menu-title">{term.name}</div>
                        <ul>
                          {children.map((child, ix) => (
                            <li key={ix}>
                              <Link to={child.description || ''} onClick={menuToggle}>
                                {child.metadataText && navigationMap[child.metadataText]}
                                {child.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </CSSTransition>
                )}
              </li>
            );
          })}
        </ul>
        <div className="user">
          {!isBoardUser && <Link to="/">Back to Accreditation Management</Link>}
          <div className="user-info">
            <div>Logged in as {userProfile?.email || auth0User?.email}</div>
            {currentOrganization && <div>{currentOrganization?.organizationName}</div>}
            {currentOrganization && <div>Organization ID: {currentOrganization?.businessId}</div>}
          </div>
          <div className="user-info dropdown-controls">
            <Grid container>
              <Grid className={styles.dropdown} item xs={12}>
                <OrganizationSelect
                  onSelectClose={() => setIsClickOutsideDisabled(false)}
                  onSelectOpen={() => setIsClickOutsideDisabled(true)}
                />
              </Grid>
              <Grid className={styles.dropdown} item xs={12}>
                <ProviderSelect />
              </Grid>
            </Grid>
          </div>
          <Link to="/contact-support">Contact Support</Link>
          <LogoutContainer />
        </div>
      </div>
    </nav>
  );
};

export default MobileHeader;
