// Types
import { IDropdownType } from 'components/Dropdown/Dropdown';
import { IStateAndProvince } from 'core/models';

/**
 * @function formatStates
 * @param states
 * @description - Formats the states array to work with a dropdown component.
 */
export const formatStates = (states: IStateAndProvince[]): IDropdownType[] =>
  states?.map(
    ({ stateName, isoStateCode }: IStateAndProvince): IDropdownType => ({
      Id: isoStateCode,
      Title: stateName,
    }),
  );
