import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type ReturnType = string[] | string | boolean;

/**
 * @function convertType
 * @param { isArray, val }
 * @description - A helper function to convert to primitive types.
 */
const convertType = ({ isArray, val }: { isArray: boolean; val: string }): ReturnType => {
  // Convert to array.
  if (isArray) {
    return val.split(',').map((encodedVal: string): string => decodeURIComponent(encodedVal));
  }
  // Convert to `true` boolean.
  if (val === 'true') {
    return true;
  }

  // Convert to `false` boolean.
  if (val === 'false') {
    return false;
  }

  // Otherwise it's a string.
  return decodeURIComponent(val);
};

const regex = /([^?=&]+)(=([^&]*))?/g;

/**
 * @function useQueryString
 * @return ReturnType
 * @description - Decodes query string into an object.
 * @example
 * search = ?providerName=Steve&startDate=2021-02-02&boards[]=ABIM,ABA&isMOC=false
 * returns {
 *   providerName: "Steve",
 *   startDate: "2021-02-02",
 *   boards: ["ABIM", "ABA"],
 *   isMOC: false,
 * }
 */
export const useQueryString = <T extends any>(): T => {
  const { search } = useLocation();

  return useMemo(
    () =>
      // Split on `=` and construct a `payload`.
      (search.match(regex) || []).reduce((result: T, each: string): T => {
        const [key, val] = each.split('=');

        // Strip out the `[]` from the key name.
        const validKey: string = key.replace(/\[]/g, '');
        const isArray: boolean = key.includes('[]');

        // Set the value based on the inferred type.
        (result as any)[validKey] = convertType({ isArray, val });

        return result;
      }, {} as T),
    [search],
  );
};
