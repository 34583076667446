/**
 * This mapping helps the UI render server error codes.
 * The keys are generated by the server and the values can be rendered to the UI as overrides.
 */
export const clientErrorMessageMap = {
  BAD_REQUEST__ACTIVITY__ACTIVITY_MOC_REGISTRATION__INVALID:
    'Activity MOC registration is invalid - please fix the activity and submit the learner again.',
  BAD_REQUEST__ACTIVITY__ACTIVITY_REMS_COMPLIANT__INVALID:
    'Activity REMS registration is invalid - please fix the activity and submit the learner again.',
  BAD_REQUEST__LEARNER_COMPLETION__CREDITS__INVALID: 'Total credits cannot be higher than the credits offered.',
};
