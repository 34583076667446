import { ReactElement } from 'react';

// Types
import { ILearnerSearchActivity } from 'core/models';

import { GenericLearnerDetails } from '../GenericLearnerDetails';
import CustomTooltip from 'components/Tooltip/Tooltip';
interface IProps {
  remsDetails: ILearnerSearchActivity;
  refreshLearnerData: () => Promise<void>;
}
export const RemsDetails = (props: IProps): ReactElement => {
  const { remsDetails, refreshLearnerData } = props;

  const {
    learnerId,
    remsName,
    practiceStateOrProvince,
    profession,
    timeInPractice,
    practiceArea,
    deaRegistrationType,
    performsSurgicalProcedures,
    prescribedInPast12Months,
  } = remsDetails;

  // Keep this order.
  const details = [
    practiceStateOrProvince,
    profession,
    timeInPractice,
    practiceArea,
    deaRegistrationType,
    prescribedInPast12Months === null || prescribedInPast12Months === undefined ? null : (
      <CustomTooltip tooltipText="Prescribed in Past 12 Months">
        {prescribedInPast12Months ? 'Yes' : 'No'}
      </CustomTooltip>
    ),
    performsSurgicalProcedures === null || performsSurgicalProcedures === undefined ? null : (
      <CustomTooltip tooltipText="Performs Surgical Procedures">
        {performsSurgicalProcedures ? 'Yes' : 'No'}
      </CustomTooltip>
    ),
  ].filter(Boolean);

  return (
    <GenericLearnerDetails
      learnerDetails={[remsDetails]}
      heading={remsName}
      learnerId={learnerId}
      refreshLearnerData={refreshLearnerData}
      buildCompletionDetails={() => {
        return details.map((i, ix) => <span key={ix}>{i}</span>);
      }}
      buildCompletionLabel={(completion: ILearnerSearchActivity) => completion?.board ?? completion?.rems ?? 'CME'}
    />
  );
};
