import { ReactElement, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';

import classNames from 'classnames';

// Components
import Button from 'components/Button/Button';
import { RollupOrganizationEnums } from 'core/models';

import { useAccountManagerInfo } from '../account-manager/hooks';

// Types
import { ButtonVariant } from 'core/enums';

// style
import styles from './AccountManagerStyles.module.scss';
import { AccountManagerSearch } from './search';
import { AccountManagerService } from 'services/AccountManagerService';
import { useLoadData } from 'hooks/useLoadData';
import { AccreditationInformationSearch } from './AccreditationInformation';
import { useIsProviderSelected } from 'components/global/providerRestriction/ProviderRestriction';
import { ProviderSearch } from './providerSearch';

export const AccountManagerPage = (): ReactElement => {
  const detailGroupClass = styles['detail-group'];
  const detailGroupLabelClass = styles['detail-group-label'];
  const detailContentClass = styles['detail-content'];
  const detailContentLabelClass = styles['detail-content-label'];
  const detailContentValueClass = styles['detail-content-value'];
  const isProviderSelected = useIsProviderSelected();
  const {
    organization,
    canEdit,
    isAccreditor,
    isPrimaryContact,
    anccOrganization,
    isAnccOrganization,
  } = useAccountManagerInfo();

  const organizationId = organization.id;
  const { data } = useLoadData(
    'PrimaryContact',
    useCallback(async () => {
      return await AccountManagerService.searchOrganizationUser({ isPrimaryContact: true, organizationId });
    }, [organizationId]),
  );

  const accreditorApproverName = organization?.accreditorOrApprover
    ? organization.accreditorOrApprover
    : organization?.organizationNotes === 'Approver of Providers' || isAnccOrganization
    ? anccOrganization?.organizationName ?? 'American Nurses Credentialing Center'
    : '';

  if (organization.rollupOrganizationEnum !== RollupOrganizationEnums.NARS) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <div className="form-container">
        <div className={classNames('form-title-container', styles['account-manager-title'])}>
          <div className="title">
            <h4>Account Management</h4>
          </div>
        </div>
        <section>
          <div className={detailGroupClass}>
            <h5 className={detailGroupLabelClass}>My Organization Profile</h5>
            <div className={detailContentClass}>
              <div className={detailContentLabelClass}>Name</div>
              <p className={detailContentValueClass}>{organization?.organizationName}</p>
              <div className={detailContentLabelClass}>Provider Type</div>
              <p className={detailContentValueClass}>
                {organization?.organizationNotes ? organization.organizationNotes : organization.organizationKind}
              </p>
              <div className={detailContentLabelClass}>Organization ID</div>
              <p className={detailContentValueClass}>{organization?.businessId}</p>
              <div className={detailContentLabelClass}>Organization Type</div>
              <p className={detailContentValueClass}>{organization?.organizationType}</p>
              <div className={detailContentLabelClass}>Organization Status</div>
              <p className={detailContentValueClass}>{organization?.organizationStatus}</p>
              <div className={detailContentLabelClass}>Accreditor/Approver</div>
              <p className={detailContentValueClass}>{accreditorApproverName}</p>
              <div className={detailContentLabelClass}>Address</div>
              <p className={detailContentValueClass}>
                {organization?.addressLine1}
                <br />
                {organization?.addressCity}, {organization?.addressState} {organization?.addressZipCode}
              </p>
              <div className={detailContentLabelClass}>Phone</div>
              <p className={detailContentValueClass}>
                {organization?.phoneNumberAreaCode}-{organization?.phoneNumberLocal}
              </p>
              <div className={detailContentLabelClass}>Web Address</div>
              <p className={detailContentValueClass}>{organization?.webAddress}</p>
            </div>
            <h5 className={detailGroupLabelClass}>Primary Contact</h5>
            {data?.map((item) => {
              return (
                <div className={detailContentClass} key={item.armaturePersonId}>
                  <p className={detailContentValueClass}>
                    {item.title} {item.firstName} {item.middleName} {item.lastName}
                    {item.position ? (
                      <>
                        <br /> {item.position}
                      </>
                    ) : null}
                    {item.addressLine1 ? (
                      <>
                        <br /> {item.addressLine1}
                      </>
                    ) : null}
                    {item.addressLine2 ? (
                      <>
                        <br /> {item.addressLine2}
                      </>
                    ) : null}
                    {item.addressCity || item.addressState || item.addressZipCode ? (
                      <>
                        <br /> {item.addressCity} {item.addressState} {item.addressZipCode}
                      </>
                    ) : null}
                    {item.email ? (
                      <>
                        <br /> {item.email}
                      </>
                    ) : null}
                    {item.phoneCountryCode || item.phoneAreaCode || item.phoneLocal ? (
                      <>
                        <br /> {item.phoneCountryCode} {item.phoneAreaCode} {item.phoneLocal}
                      </>
                    ) : null}
                  </p>
                </div>
              );
            })}
            {canEdit && (
              <Grid xs={12} sm={3}>
                <div className={styles.secondary}>
                  <Button
                    component={Link}
                    role="navigation"
                    title="Edit Organization"
                    to={`/account-manager/edit-organization/${organization.id}`}
                    variant={ButtonVariant.Primary}
                  >
                    Edit Organization Profile
                  </Button>
                </div>
              </Grid>
            )}
          </div>
        </section>
        {isProviderSelected && (
          <section>
            <div className={detailGroupClass}>
              <h5 className={detailGroupLabelClass}>Accreditation Information</h5>
              <AccreditationInformationSearch />
              {canEdit && !isPrimaryContact && (
                <Grid item xs={12} sm={3} className={styles.input}>
                  <div className={styles['submit-button-container']}>
                    <Button
                      component={Link}
                      size="small"
                      title="Add New Accreditation Decision"
                      to="/account-manager/accreditations/add"
                      variant={ButtonVariant.Primary}
                    >
                      Add New Accreditation Decision
                    </Button>
                  </div>
                </Grid>
              )}
            </div>
          </section>
        )}
        {canEdit && isAccreditor && (
          <section>
            <div className={detailGroupClass}>
              <h5 className={detailGroupLabelClass}>Find a Provider</h5>
              <Grid item xs={12} sm={3} className={styles.input}>
                <div className={styles['submit-button-container']}>
                  <Button
                    component={Link}
                    size="small"
                    title="Add New Provider"
                    to="/account-manager/provider/add"
                    variant={ButtonVariant.Primary}
                  >
                    Add New Provider
                  </Button>
                </div>
              </Grid>
              <ProviderSearch />
            </div>
          </section>
        )}
        <section>
          <div className={detailGroupClass}>
            <h5 className={detailGroupLabelClass}>User Management</h5>
            {canEdit && (
              <Grid item xs={12} sm={3} className={styles.input}>
                <div className={styles['submit-button-container']}>
                  <Button
                    component={Link}
                    size="small"
                    title="Add User"
                    to="/account-manager/users/add"
                    variant={ButtonVariant.Primary}
                  >
                    Add User
                  </Button>
                </div>
              </Grid>
            )}
            <AccountManagerSearch />
          </div>
        </section>
      </div>
    </>
  );
};
