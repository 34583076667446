// Core.
import { API } from 'core';
import { ServerRoute } from 'core/api';

// Store.
import { IHelpDeskTicketRequest } from 'store/helpDesk/types';

interface IHelpDeskAttachmentResponse {
  value: string;
}

export class HelpDeskService {
  public static async submitTicket(ticket: IHelpDeskTicketRequest): Promise<void> {
    const route: ServerRoute = ServerRoute.forAction('helpdesk', `ticket`);
    return API.post(route, ticket);
  }

  public static async uploadAttachment(file: File): Promise<string> {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const route: ServerRoute = ServerRoute.forAction('HelpDesk', 'attachment');
    const response: IHelpDeskAttachmentResponse = await API.postFile<IHelpDeskAttachmentResponse>(
      route,
      { file: [file] },
      undefined,
      config,
    );

    return response.value;
  }
}
