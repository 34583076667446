import uniqBy from 'lodash/uniqBy';
import maxBy from 'lodash/maxBy';
import { Dictionary } from 'lodash';

// Utils
import { getLicenseId } from './getLicenseId';

// Types
import { ILearnerIdMatchField, IMatchedLearner } from 'core/models';
import { ITypeaheadOption } from 'components/ContinuousImprovement/Typeahead';

export interface IArgs {
  matchedLearners: IMatchedLearner[];
  selectedMocBoardIds?: string[];
  stateName: ITypeaheadOption;
  statesAndProvincesOptions: ITypeaheadOption[];
}
export interface ILearnerMatched {
  isMatched: boolean;
  matchedStateOption?: ITypeaheadOption;
  mocLearnerIds?: Dictionary<string>;
  stateLicenseId?: string;
}
export const learnerMatched = (args: IArgs): ILearnerMatched => {
  const { matchedLearners, selectedMocBoardIds, stateName, statesAndProvincesOptions } = args;

  // Null check.
  if (matchedLearners?.length !== 1) {
    return { isMatched: false };
  }

  const { learnerStateBoards, learnerMocBoards } = matchedLearners[0];

  // Split the data into two sections.
  // 1. FSMB
  // 2. DiplomateState
  // We try as hard as we can with FSMB before we try to populate via DiplomateState.
  const fsmbStates = learnerStateBoards.filter(({ source }: ILearnerIdMatchField): boolean => source === 'FSMB');
  const uniquefsmbStates: ILearnerIdMatchField[] = uniqBy(
    fsmbStates,
    ({ abbreviation }: ILearnerIdMatchField): string => abbreviation,
  );
  const diplomateStates: ILearnerIdMatchField[] = uniqBy(
    learnerStateBoards.filter(({ source }: ILearnerIdMatchField): boolean => source === 'DiplomateState'),
    ({ abbreviation }: ILearnerIdMatchField): string => abbreviation,
  );

  const getLatestExpirationId = ({ expirationDate }: ILearnerIdMatchField): Date => new Date(expirationDate);
  const getStateAbbreviation = (stateAbbreviation) => ({ id }): boolean =>
    !!id && stateAbbreviation === id.toLowerCase();

  let matchedStateOption;
  let stateLicenseId;

  const hasOneUniqueFSMB: boolean = uniquefsmbStates.length === 1;

  if (hasOneUniqueFSMB) {
    // If we have a uniqueFSMB, then we want to select the one (of the greater list) that has the latest expiration date.
    const uniqueState: ILearnerIdMatchField = maxBy(fsmbStates, getLatestExpirationId);
    const stateAbbreviation: string = uniqueState?.abbreviation?.toLowerCase();

    stateLicenseId = uniqueState?.learnerId;
    matchedStateOption = statesAndProvincesOptions?.find(getStateAbbreviation(stateAbbreviation));
  }
  // Check Diplomate States.
  // At this point we know that we only have diplomateStates.
  else if (diplomateStates.length === 1 && fsmbStates.length === 0) {
    const uniqueState: ILearnerIdMatchField = maxBy(diplomateStates, getLatestExpirationId);
    const stateAbbreviation: string = uniqueState?.abbreviation?.toLowerCase();

    stateLicenseId = uniqueState?.learnerId;
    matchedStateOption = statesAndProvincesOptions?.find(getStateAbbreviation(stateAbbreviation));
  } else {
    // Otherwise, we are forcing the user to pick a state to help them narrow down a selection.
    if (stateName) {
      // Determine which license to auto-populate.
      stateLicenseId = getLicenseId({ learnerStateBoards, stateName });
    }
  }

  // find best matches for MOC boards too
  const mocLearnerIds = {};
  if (selectedMocBoardIds) {
    for (const boardId of selectedMocBoardIds) {
      const mocLearnerId = learnerMocBoards.filter((i) => i.boardId === boardId)[0]?.learnerId;
      if (mocLearnerId) {
        mocLearnerIds[boardId] = mocLearnerId;
      }
    }
  }

  return {
    isMatched: !!stateLicenseId,
    matchedStateOption,
    mocLearnerIds,
    stateLicenseId,
  };
};
