import { InputLabel } from '@material-ui/core';
import { FormikProvider, useFormik } from 'formik';
import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';

// Components
import Button from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';
import InputBlock from 'components/global/inputBlock/inputBlock';

// Styles.
import styles from '../AdminPopupStyle/AdminPopupStyle.module.scss';

// Core + Store
import { ButtonVariant } from 'core/enums';
import { queueMocActivity } from 'store/activity/actions';
import { useAdminCommandMocCTypeBoardList } from 'hooks';

// Validations.
import { validationSchema } from './validationSchema';

interface IQueueMocActivityFormProps {
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  activityIds: number;
}

export const QueueMocActivityForm = (props: IQueueMocActivityFormProps): ReactElement => {
  const { activityIds, onClose, onSuccess } = props;
  const BoardList = useAdminCommandMocCTypeBoardList();
  const ActionTypes = [
    { id: 'Add', name: 'Add' },
    { id: 'Update', name: 'Update' },
    { id: 'Delete', name: 'Delete' },
  ];
  const NotProcessOption = [
    { id: true, name: 'Yes' },
    { id: 'No', name: 'No' },
  ];
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      activityLegacyId: activityIds,
      boardAbbreviation: '',
      action: '',
      deleteExisting: true,
      debug: true,
    },
    onSubmit: async (values): Promise<void> => {
      if (values.deleteExisting.toString() === 'No') {
        values.deleteExisting = false;
      } else {
        values.deleteExisting = true;
      }
      const result = await dispatch(queueMocActivity({ ...values }));
      if (result) {
        onClose();
        onSuccess?.();
      }
    },
    validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <fieldset className={styles.fieldset}>
          <section className={styles.section}>
            <div>
              <InputLabel>
                Board <span className={styles.required}>*</span>
              </InputLabel>
              <InputBlock name="boardAbbreviation">
                <Dropdown
                  className={styles.input}
                  fullWidth
                  items={BoardList}
                  name="boardAbbreviation"
                  placeholder="Select Board"
                  required
                />
              </InputBlock>
            </div>
            <div>
              <InputLabel>
                Action <span className={styles.required}>*</span>
              </InputLabel>
              <InputBlock name="action">
                <Dropdown
                  className={styles.input}
                  fullWidth
                  items={ActionTypes}
                  name="action"
                  placeholder="Select Action Type"
                  required
                />
              </InputBlock>
            </div>
            <div>
              <InputLabel>
                Delete Existing Not Processed record <span className={styles.required}>*</span>
              </InputLabel>
              <InputBlock name="deleteExisting">
                <Dropdown
                  className={styles.input}
                  fullWidth
                  items={NotProcessOption}
                  name="deleteExisting"
                  placeholder="Select Option"
                  required
                />
              </InputBlock>
            </div>
            <footer className={styles.footer}>
              <Button className={styles.button} onClick={onClose} variant={ButtonVariant.Secondary}>
                Cancel
              </Button>
              <Button
                className={styles.button}
                disabled={formik.isSubmitting}
                isSubmitting={formik.isSubmitting}
                type="submit"
                variant={ButtonVariant.Primary}
              >
                Save
              </Button>
            </footer>
          </section>
        </fieldset>
      </form>
    </FormikProvider>
  );
};
