import { ReactElement } from 'react';
import { IBoardSlim } from 'core/models';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

// Components
import Button from 'components/Button/Button';

// Styles
import styles from 'components/LearnerSearchTable/learnersearchtable.module.scss';

// Core + Store
import { ButtonVariant } from 'core/enums';

// Types
interface IProps {
  results: IBoardSlim[];
}

export const BoardListTable = (props: IProps): ReactElement => {
  const { results } = props;
  return (
    <div className={styles['learner-search-container']}>
      <table className={styles['learner-search-table']}>
        <thead className={styles['learner-search-table-thead']}>
          <tr>
            <th>Abbreviation</th>
            <th className={styles['learner-name']}>Name</th>
            <th>Type</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {!!results?.length &&
            results?.map(
              (board: IBoardSlim): ReactElement => {
                const { id, name, abbreviation, type } = board;
                return (
                  <tr key={id}>
                    <td>{abbreviation}</td>
                    <td>{name.trim() ? name : <Typography>--</Typography>}</td>
                    <td>{type || <Typography align="left">--</Typography>}</td>
                    <td style={{ width: '200px' }}>
                      <Button
                        component={Link}
                        size="small"
                        title={`Edit ${name}`}
                        to={`/bct/${id}`}
                        variant={ButtonVariant.Primary}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                );
              },
            )}
        </tbody>
      </table>
    </div>
  );
};
