import { IAuth0User, IUser, IUserOrganization } from 'core/models';

export type UserProfile = IUser | null;
export type CurrentOrganization = IUserOrganization | null;
export type Auth0User = IAuth0User | null;

export const USER_AUTH0_LOADING = 'USER_AUTH0_LOADING';
export const USER_AUTH0_SUCCESS = 'USER_AUTH0_SUCCESS';
export const GET_USER_CURRENT = 'GET_USER_CURRENT';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const GET_USER_CURRENT_SUCCESS = 'GET_USER_CURRENT_SUCCESS';
export const GET_USER_CURRENT_FAILURE = 'GET_USER_CURRENT_FAILURE';
export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION';
export const SET_DEFAULT_ORGANIZATION = 'SET_DEFAULT_ORGANIZATION';
export const SET_CURRENT_PROVIDER = 'SET_CURRENT_PROVIDER';

export interface UserState {
  auth0User: Auth0User;
  /**
   * @description - The organization that user chose from Armature.
   */
  currentOrganizationContext: CurrentOrganization;
  /**
   * @description - The provider the user chooses via the UI
   */
  currentProviderContext: CurrentOrganization;
  /**
   * @description - The organization from which all rights flow (home directory).
   */
  defaultOrganizationContext: CurrentOrganization;
  error?: string;
  isAuthenticating: boolean;
  isLoading: boolean;
  userProfile: UserProfile;
}
