import { IContextualMenuOptionProps } from 'components/ContextualMenu/ContextualMenu';

// Types
interface IOption {
  options: IContextualMenuOptionProps[];
}

interface IArgs {
  onDownloadAllLearners(): void;
  onDownloadFilteredLearners(): void;
}

export const DOWNLOAD_ALL_LEARNERS = 'Download All Learners';
export const DOWNLOAD_FILTERED_LEARNERS = 'Download Filtered Learners';

export const downloadMenuItems = ({ onDownloadAllLearners, onDownloadFilteredLearners }: IArgs): IOption => ({
  options: [
    {
      icon: 'get_app',
      name: DOWNLOAD_ALL_LEARNERS,
      onSelectAction: onDownloadAllLearners,
      selected: true,
    },
    {
      icon: 'get_app',
      name: DOWNLOAD_FILTERED_LEARNERS,
      onSelectAction: onDownloadFilteredLearners,
    },
  ],
});
