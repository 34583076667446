// Libraries
import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { keys, uniqueId } from 'lodash';
import { CloseRounded, SaveAltRounded } from '@material-ui/icons';

// Components
import Button from 'components/Button/Button';

// Store + Core
import { ILearnerCreditDetails } from 'core/models';
import { exportLearnerSearch } from 'store/learner/actions';

// Styles
import styles from './creditdetails.module.scss';

export interface IProps {
  creditDetails?: ILearnerCreditDetails[];
  handleClose: () => void;
}

export const CreditDetails = (props: IProps): ReactElement => {
  const dispatch = useDispatch();

  const { creditDetails, handleClose } = props;

  const onClickDownload = () => dispatch(exportLearnerSearch());

  return (
    <div className={styles['credit-details']}>
      <button className="close" aria-label="close" onClick={handleClose}>
        <CloseRounded />
      </button>
      <ul>
        {creditDetails?.map(
          ({ boardAbbr, credits, totalCredits }): ReactElement => {
            const creditKeys = keys(credits);
            const itemKey = uniqueId();
            const hasTotalCredits = !!totalCredits;
            return (
              <li key={itemKey}>
                <h5>{boardAbbr} Credit Totals for this activity</h5>
                <div>Total Credits Earned: {hasTotalCredits ? totalCredits : 0}</div>
                {creditKeys?.map((creditKey) => (
                  <div key={`${boardAbbr}-${creditKey}`}>
                    {creditKey} {credits[creditKey]}
                  </div>
                ))}
              </li>
            );
          },
        )}
      </ul>
      <Button onClick={onClickDownload} title="download">
        <SaveAltRounded />
        Download Learner Data
      </Button>
    </div>
  );
};
