import { Reducer } from 'redux';
import * as PARS from 'core';
import {
  ADD_LEARNER_COMPLETION,
  ADD_LEARNER_COMPLETION_FAILURE,
  ADD_LEARNER_COMPLETION_SUCCESS,
  BATCH_LEARNER,
  BATCH_LEARNER_CLEAR,
  BATCH_LEARNER_FAILURE,
  BATCH_LEARNER_PROGRESS,
  BATCH_LEARNER_SUCCESS,
  BULK_DELETE_LEARNERS,
  BULK_DELETE_LEARNERS_FAILURE,
  BULK_DELETE_LEARNERS_SUCCESS,
  CREATE_LEARNER,
  CREATE_LEARNER_FAILURE,
  CREATE_LEARNER_SUCCESS,
  DOWNLOAD_LEARNER_ACTIVITIES,
  DOWNLOAD_LEARNER_ACTIVITIES_FAILURE,
  DOWNLOAD_LEARNER_ACTIVITIES_SUCCESS,
  DOWNLOAD_LEARNER_SEARCH,
  DOWNLOAD_LEARNER_SEARCH_FAILURE,
  DOWNLOAD_LEARNER_SEARCH_SUCCESS,
  EXPORT_LEARNER_ACTIVITIES,
  EXPORT_LEARNER_SEARCH,
  EXPORT_LEARNER_SEARCH_FAILURE,
  EXPORT_LEARNER_SEARCH_SUCCESS,
  EXPORT_LEARNERS,
  GET_LEARNER_ACTIVITIES,
  GET_LEARNER_ACTIVITIES_FAILURE,
  GET_LEARNER_ACTIVITIES_SUCCESS,
  GET_LEARNER_COMPLETION,
  GET_LEARNER_COMPLETION_FAILURE,
  GET_LEARNER_COMPLETION_SUCCESS,
  GET_LEARNER_SEARCH,
  GET_LEARNER_SEARCH_FAILURE,
  GET_LEARNER_SEARCH_SUCCESS,
  GET_LEARNER_TYPES_SUCCESS,
  GET_RECENT_LEARNER_COMPLETIONS,
  GET_RECENT_LEARNER_COMPLETIONS_FAILURE,
  GET_RECENT_LEARNER_COMPLETIONS_SUCCESS,
  LearnerActionTypes,
  LearnerState,
  MATCH_LEARNER_BOARDS,
  MATCH_LEARNER_BOARDS_FAILURE,
  MATCH_LEARNER_BOARDS_SUCCESS,
  MATCH_LEARNERS,
  MATCH_LEARNERS_FAILURE,
  MATCH_LEARNERS_SUCCESS,
  PATCH_LEARNER,
  PATCH_LEARNER_FAILURE,
  PATCH_LEARNER_SUCCESS,
  RESET_LEARNER_COMPLETION,
  RESET_LEARNER_MATCHES,
  RESET_LEARNER_SEARCH_RESPONSE,
  UPDATE_LEARNER,
  UPDATE_LEARNER_ACTIVITIES_PAGINATION_STATE,
  UPDATE_LEARNER_COMPLETION,
  UPDATE_LEARNER_COMPLETION_FAILURE,
  UPDATE_LEARNER_COMPLETION_SUCCESS,
  UPDATE_LEARNER_FAILURE,
  UPDATE_LEARNER_SEARCH,
  UPDATE_LEARNER_SEARCH_ACTIVITIES,
  UPDATE_LEARNER_SEARCH_STATE_PROPS,
  UPDATE_LEARNER_SUCCESS,
  LearnerValidationState,
  LearnerValidationActionTypes,
  BATCH_LEARNER_VALIDATION_CLEAR,
  BATCH_LEARNER_VALIDATION,
  BATCH_LEARNER_VALIDATION_PROGRESS,
  BATCH_LEARNER_VALIDATION_SUCCESS,
  BATCH_LEARNER_VALIDATION_FAILURE,
  QUEUE_MOC_LEARNER,
} from './types';
import { INITIAL_LEARNER_SEARCH_FACETS } from 'components/ContinuousImprovement/Facets/LearnerSearchFacets/constants';
import { INITIAL_LEARNER_ACTIVITY_SEARCH_FACETS } from 'components/ContinuousImprovement/Facets/FacetSearch/constants';
import { IActivityLearnerSearchStateProps, IActivitySearchStateProps, IArrayBufferDownload } from 'core/models';

const initialState: LearnerState = {
  currentLearner: [],
  currentLearnerCompletion: null,
  error: '',
  file: null,
  isCreating: false,
  isDownloading: false,
  isLearnerCompletionAdded: false,
  isLoading: true,
  isMatchingLearners: false,
  isUpdating: false,
  learnerActivityDownload: null,
  learnerSearchDownload: null,
  learnerSearchRequest: INITIAL_LEARNER_SEARCH_FACETS,
  learnerSearchResponse: null,
  learnerSearchStateProps: {
    page: 1,
  },
  learnerSummarySearchRequest: INITIAL_LEARNER_ACTIVITY_SEARCH_FACETS,
  learnerSummarySearchResponse: null,
  learnerTypes: [],
  matchLearnersQuery: null,
  matchedLearners: [],
  progressPercentage: 0,
  recentLearners: [],
  searchStateProps: {
    page: 1,
  },
  validationErrorResponse: null,
};

const learnerReducer: Reducer<LearnerState, LearnerActionTypes> = (
  state = initialState,
  action: LearnerActionTypes,
): LearnerState => {
  switch (action.type) {
    case CREATE_LEARNER:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_LEARNER_SUCCESS:
      return {
        ...state,
        currentLearner: action.payload as PARS.Models.Learner[],
        isCreating: false,
      };
    case BATCH_LEARNER_CLEAR:
      return {
        ...state,
        error: null,
        file: null,
        isCreating: false,
        progressPercentage: 0,
      };
    case CREATE_LEARNER_FAILURE:
      console.error(action.payload);
      return {
        ...state,
        isCreating: false,
      };
    case BATCH_LEARNER:
      return {
        ...state,
        file: action.payload.file,
        isCreating: true,
      };
    case BATCH_LEARNER_PROGRESS:
      return {
        ...state,
        progressPercentage: action.payload as number,
      };
    case BATCH_LEARNER_SUCCESS:
      return {
        ...state,
        isCreating: false,
      };
    case BATCH_LEARNER_FAILURE:
      return {
        ...state,
        error: PARS.API.getErrorMessage((action.payload || {}) as Error),
        isCreating: false,
      };
    case BULK_DELETE_LEARNERS:
      return {
        ...state,
        isLoading: true,
      };
    case BULK_DELETE_LEARNERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learnerSearchStateProps: {
          ...state.learnerSearchStateProps,
          selectedLearners: undefined,
        },
      };
    case BULK_DELETE_LEARNERS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case DOWNLOAD_LEARNER_ACTIVITIES:
      return {
        ...state,
        isDownloading: true,
      };
    case DOWNLOAD_LEARNER_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isDownloading: false,
        learnerActivityDownload: action.payload as IArrayBufferDownload,
      };
    case DOWNLOAD_LEARNER_ACTIVITIES_FAILURE:
      return {
        ...state,
        isDownloading: false,
      };
    case EXPORT_LEARNER_ACTIVITIES:
      return { ...state };
    case EXPORT_LEARNERS:
      return { ...state };
    case GET_LEARNER_TYPES_SUCCESS:
      return {
        ...state,
        learnerTypes: action.payload as PARS.Models.LearnerType[],
      };
    case PATCH_LEARNER:
      return {
        ...state,
        isUpdating: true,
      };
    case PATCH_LEARNER_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      };
    case PATCH_LEARNER_FAILURE:
      console.error(action.payload);
      return {
        ...state,
        isUpdating: false,
      };
    case UPDATE_LEARNER:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_LEARNER_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      };
    case UPDATE_LEARNER_ACTIVITIES_PAGINATION_STATE:
      return {
        ...state,
        searchStateProps: action.payload as IActivitySearchStateProps,
      };
    case UPDATE_LEARNER_SEARCH_STATE_PROPS:
      return {
        ...state,
        learnerSearchStateProps: action.payload as IActivityLearnerSearchStateProps,
      };
    case UPDATE_LEARNER_FAILURE:
      console.error(action.payload);
      return {
        ...state,
        isUpdating: false,
      };
    case GET_LEARNER_SEARCH:
      return {
        ...state,
        isLoading: true,
        learnerSearchRequest: action.payload,
      };
    case GET_LEARNER_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learnerSearchResponse: action.payload,
      };
    case GET_LEARNER_SEARCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case RESET_LEARNER_SEARCH_RESPONSE:
      return {
        ...state,
        learnerSearchResponse: undefined,
      };

    case UPDATE_LEARNER_SEARCH:
      return {
        ...state,
        learnerSearchRequest: action.payload,
      };

    case GET_LEARNER_ACTIVITIES:
      return {
        ...state,
        isLoading: true,
        learnerSummarySearchRequest: action.payload,
      };

    case GET_LEARNER_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learnerSummarySearchResponse: action.payload,
      };

    case GET_LEARNER_ACTIVITIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case UPDATE_LEARNER_SEARCH_ACTIVITIES:
      return {
        ...state,
        learnerSummarySearchRequest: action.payload,
      };

    case MATCH_LEARNERS:
      return {
        ...state,
        isMatchingLearners: true,
        matchLearnersQuery: action.payload,
        matchedLearners: [],
      };
    case MATCH_LEARNERS_SUCCESS:
      return {
        ...state,
        isMatchingLearners: false,
        matchedLearners: action.payload,
      };
    case MATCH_LEARNERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isMatchingLearners: false,
      };
    case RESET_LEARNER_MATCHES:
      return {
        ...state,
        isLearnerCompletionAdded: false,
        matchLearnersQuery: null,
        matchedLearners: [],
      };
    case MATCH_LEARNER_BOARDS:
      return {
        ...state,
        isMatchingLearners: true,
        matchLearnersQuery: action.payload,
      };
    case MATCH_LEARNER_BOARDS_SUCCESS:
      return {
        ...state,
        isMatchingLearners: false,
        matchedLearners: action.payload,
      };
    case MATCH_LEARNER_BOARDS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isMatchingLearners: false,
      };
    case ADD_LEARNER_COMPLETION:
      return {
        ...state,
        validationErrorResponse: null,
      };
    case ADD_LEARNER_COMPLETION_SUCCESS:
      return {
        ...state,
        isLearnerCompletionAdded: true,
      };
    case ADD_LEARNER_COMPLETION_FAILURE:
      return {
        ...state,
        isLearnerCompletionAdded: false,
        validationErrorResponse: action?.payload,
      };
    case GET_RECENT_LEARNER_COMPLETIONS:
      return {
        ...state,
        error: '',
      };
    case GET_RECENT_LEARNER_COMPLETIONS_SUCCESS:
      return {
        ...state,
        recentLearners: action.payload,
      };
    case GET_RECENT_LEARNER_COMPLETIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_LEARNER_COMPLETION:
      return {
        ...state,
        error: '',
      };
    case GET_LEARNER_COMPLETION_SUCCESS:
      return {
        ...state,
        currentLearnerCompletion: action.payload,
      };
    case GET_LEARNER_COMPLETION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_LEARNER_COMPLETION:
      return {
        ...state,
        currentLearnerCompletion: initialState.currentLearnerCompletion,
      };
    case UPDATE_LEARNER_COMPLETION:
      return {
        ...state,
        error: '',
      };
    case UPDATE_LEARNER_COMPLETION_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_LEARNER_COMPLETION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case EXPORT_LEARNER_SEARCH:
      return {
        ...state,
        error: '',
      };
    case EXPORT_LEARNER_SEARCH_SUCCESS:
      return {
        ...state,
      };
    case EXPORT_LEARNER_SEARCH_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DOWNLOAD_LEARNER_SEARCH:
      return {
        ...state,
        error: '',
        isDownloading: true,
      };
    case DOWNLOAD_LEARNER_SEARCH_SUCCESS:
      return {
        ...state,
        isDownloading: false,
        learnerSearchDownload: action.payload as IArrayBufferDownload,
      };
    case DOWNLOAD_LEARNER_SEARCH_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case QUEUE_MOC_LEARNER:
      return {
        ...state,
        isUpdating: true,
      };
    default:
      return state;
  }
};

const learnerValidationInitialState: LearnerValidationState = {
  error: '',
  file: null,
  isCreating: false,
  progressPercentage: 0,
};

export const learnerValidationReducer: Reducer<LearnerValidationState, LearnerValidationActionTypes> = (
  state = learnerValidationInitialState,
  action: LearnerValidationActionTypes,
): LearnerValidationState => {
  switch (action.type) {
    case BATCH_LEARNER_VALIDATION_CLEAR:
      return {
        ...state,
        error: null,
        file: null,
        isCreating: false,
        progressPercentage: 0,
      };

    case BATCH_LEARNER_VALIDATION:
      return {
        ...state,
        file: action.payload.file,
        isCreating: true,
      };
    case BATCH_LEARNER_VALIDATION_PROGRESS:
      return {
        ...state,
        progressPercentage: action.payload as number,
      };
    case BATCH_LEARNER_VALIDATION_SUCCESS:
      return {
        ...state,
        isCreating: false,
      };
    case BATCH_LEARNER_VALIDATION_FAILURE:
      return {
        ...state,
        error: PARS.API.getErrorMessage((action.payload || {}) as Error),
        isCreating: false,
      };

    default:
      return state;
  }
};

export default learnerReducer;
