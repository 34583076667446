import * as PARS from 'core';

export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const DOWNLOAD_FILE_FAILURE = 'DOWNLOAD_FILE_FAILURE';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';

export const LEARNER_TEMPLATE_ID_MAP = {
  '0': '917_Learner_Excel_CME_MOC_Template.xlsx',
  '1': '735_Learner_TabDelimited_Template.xlsx',
  '2': '920_Learner_Excel_CME_MOC_REMS_Template.xlsx',
  '3': '806_Learner_TabDelimited_REMS_Template.xlsx',
};

export const LEARNER_VALIDATION_TOOL_TEMPLATE_ID_MAP = {
  0: '919_Learner_Excel_PreValidation_Template.xlsx',
};

export const ACTIVITY_TEMPLATE_ID_MAP = {
  '0': '915_ACCME_Activity_Excel_MOC_Template.xlsx',
  '1': '913_ACCME_Activity_Excel_Template.xlsx',
  '2': '635_ACCME_Activity_TabDelimited_Template.xlsx',
};

export const ACTIVITY_TEMPLATE_JA_ID_MAP = {
  '3': '928_JA_Activity_Excel_MOC_Template.xlsx',
  '4': '927_JA_Activity_Excel_Template.xlsx',
  '5': '769_JA_Activity_TabDelimited_Template.xlsx',
};

export const ACTIVITY_TEMPLATE_NARS_ID_MAP = {
  '6': '1033_ANCC_Activity_Excel_Template.xlsx',
};

export const ACTIVITY_TEMPLATE_NARS_IA_ID_MAP = {
  '6': '1033_ANCC_Activity_Excel_Template.xlsx',
  '7': '1043_ANCC_IA_Activity_Excel_Template.xlsx',
};

export type MatchTemplate =
  | typeof LEARNER_TEMPLATE_ID_MAP
  | typeof ACTIVITY_TEMPLATE_ID_MAP
  | typeof ACTIVITY_TEMPLATE_JA_ID_MAP
  | typeof LEARNER_VALIDATION_TOOL_TEMPLATE_ID_MAP
  | typeof ACTIVITY_TEMPLATE_NARS_ID_MAP;

export interface IDownloadActionProperties {
  controller: string;
  id: string;
  matchTemplate: MatchTemplate;
}

export interface IDownloadLvtActionProperties {
  controller: string;
  matchTemplate: MatchTemplate;
}

interface DownloadFile extends PARS.Models.PARSAction<string, typeof DOWNLOAD_FILE> {
  type: typeof DOWNLOAD_FILE;
}

interface DownloadFileFailure extends PARS.Models.PARSAction<string, typeof DOWNLOAD_FILE_FAILURE> {
  type: typeof DOWNLOAD_FILE_FAILURE;
}

interface DownloadFileSuccess extends PARS.Models.PARSAction<string, typeof DOWNLOAD_FILE_SUCCESS> {
  type: typeof DOWNLOAD_FILE_SUCCESS;
}

export type DownloadFileActionTypes = DownloadFile | DownloadFileFailure | DownloadFileSuccess;
