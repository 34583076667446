import { BaseApiService } from './baseApiService';
import { IUser, CreateUserRequest } from './models';

export interface IUserOrganization {
  id: string;
  businessId: string;
  organizationName: string;
  organizationKind: 'Accreditor' | 'Board' | 'Provider' | 'SpecialOrganization';
  childOrganizationIds: Array<string>;
  isJa?: boolean;
}

export class UserApiService extends BaseApiService {
  public async getCurrent(): Promise<IUser> {
    return await super.getAsync('/api/users/current');
  }

  public async createUser(request: CreateUserRequest): Promise<IUser> {
    return await super.postAsync('/api/users/create', request);
  }
}
