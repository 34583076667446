import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PersonAddRounded, VisibilityRounded } from '@material-ui/icons';
import { Grid, useMediaQuery } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import { sumBy } from 'lodash';

// Components
import Button from 'components/Button/Button';

// Hooks.
import { useClickEvent } from 'hooks';

// Styles
import styles from './learnerresultcard.module.scss';

// Core + Store
import { IBoard, ILearnerActivity, ITaxonomyTerm, OrganizationKinds, RollupOrganizationEnums } from 'core/models';
import { organizationKindSelector, rollupOrganizationEnumSelector } from 'store/user/selectors';
import { BreakpointsEnum } from 'core/enums';

interface IProps {
  learnerActivity: ILearnerActivity;
  amaCreditTerm: ITaxonomyTerm;
}

const VIEW_LABEL = 'View';
const ADD_LEARNERS_LABEL = 'Add Learners';

export const LearnerResultCard = (props: IProps): ReactElement => {
  const { learnerActivity, amaCreditTerm } = props;

  const fireEvent = useClickEvent({ Event: 'learner', EventCategory: 'Learner' });
  const isSmBreakpoint = useMediaQuery(`(min-width:${BreakpointsEnum.sm})`);
  const isXsBreakpoint = useMediaQuery(`(min-width:${BreakpointsEnum.xs})`);
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);

  const isRollupOrganizationNars: boolean = rollupOrganizationEnum === RollupOrganizationEnums.NARS;

  // Selectors.
  const organization = useSelector(organizationKindSelector);
  const organizationKind = organization?.organizationKind;
  const {
    activityEndDate,
    activityFormat,
    activityStartDate,
    activityCreditTypes,
    boards,
    businessId,
    claimDate,
    id,
    internalId,
    isMips,
    isMoc,
    isRems,
    key,
    providerName,
    statusCountsByBoard,
    title,
  } = learnerActivity;
  const collaborations: string[] = [isMoc && 'MOC', isRems && 'REMS', isMips && 'MIPS'].filter(Boolean);
  const certifyingBoards: string[] = boards.map(
    ({ abbreviation }: Pick<IBoard, 'abbreviation'>): string => abbreviation,
  );
  const formattedDate = (date) => moment(date).format('M/D/YYYY');
  const activityDates = (): string => {
    if (activityStartDate && activityEndDate)
      return `${formattedDate(activityStartDate)} | ${formattedDate(activityEndDate)}`;
    return null;
  };
  const hasAmaCredits = !!activityCreditTypes?.find(({ creditTypeId }) => creditTypeId === amaCreditTerm?.id);
  const canAddLearners = hasAmaCredits || isMoc || isRems;

  // if we're a board, only include data for our board
  let statusCounts = statusCountsByBoard;
  if (organizationKind === OrganizationKinds.BOARD && organization?.id) {
    statusCounts = statusCounts.filter((i) => i.boardId === organization?.id);
  }

  const submittedCount = sumBy(
    (statusCounts || []).filter((i) => i.status === 'Submitted'),
    (i) => i.count,
  );
  const acceptedCount = sumBy(
    (statusCounts || []).filter((i) => i.status === 'Accepted'),
    (i) => i.count,
  );
  const rejectedCount = sumBy(
    (statusCounts || []).filter((i) => i.status === 'Rejected'),
    (i) => i.count,
  );

  return (
    <Grid className={styles['learner-results-card']}>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <Grid container>
            <Grid item className={styles['learner-results-card-title']} xs={12}>
              <Link
                aria-label={`View details of ${title}`}
                className="btn label"
                onClick={() => fireEvent({ EventAction: VIEW_LABEL, EventLabel: `/learners/summary/detail/${key}` })}
                to={`/learners/summary/detail/${key}`}
              >
                {title}
              </Link>
            </Grid>
            <Grid item>
              <Grid container className={styles['learner-results-card-info-container']} xs={12}>
                <Grid item className={styles.info}>
                  <Grid item className={styles['org-info']}>
                    <Grid xs="auto">{providerName}</Grid>
                    <Grid xs="auto">{businessId && <span>Organization ID: {businessId}</span>}</Grid>
                  </Grid>
                  <Grid container className={styles['activity-info']} xs={12}>
                    {activityFormat && (
                      <Grid item className={styles['meta-activityFormat']}>
                        {activityFormat} &nbsp;
                      </Grid>
                    )}
                    {id && (
                      <Grid item className={styles['meta-id']}>
                        Activity ID: {id}
                      </Grid>
                    )}
                    {internalId && (
                      <Grid item className={styles['meta-id']}>
                        Internal ID: {internalId}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={styles['learner-results-card-cta']} xs={12} sm={4}>
          {!isRollupOrganizationNars && (
            <Grid container justify={isXsBreakpoint ? 'flex-end' : null} spacing={2}>
              <Grid item xs="auto">
                <Link
                  aria-label={`View details of ${title}`}
                  className="btn label"
                  onClick={() => fireEvent({ EventAction: VIEW_LABEL, EventLabel: `/learners/summary/detail/${key}` })}
                  to={`/learners/summary/detail/${key}`}
                >
                  <VisibilityRounded />
                  {VIEW_LABEL}
                </Link>
              </Grid>
              <Grid item xs="auto">
                {canAddLearners && organizationKind !== OrganizationKinds.BOARD && (
                  <Button
                    aria-label={`Add Learners to ${title}`}
                    className={classNames(styles.button, 'btn label')}
                    component={Link}
                    onClick={() =>
                      fireEvent({ EventAction: ADD_LEARNERS_LABEL, EventLabel: `/activities/${key}/add-learner` })
                    }
                    to={`/activities/${key}/add-learner`}
                  >
                    <PersonAddRounded />
                    {ADD_LEARNERS_LABEL}
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Grid container className={styles.records} justify={isSmBreakpoint ? 'flex-end' : null} spacing={1}>
                  <Grid item className={styles['record-status']}>
                    Submitted: <span>{submittedCount || 0}</span>
                  </Grid>
                  <Grid item className={styles['record-status']}>
                    Accepted: <span>{acceptedCount || 0}</span>
                  </Grid>
                  <Grid item className={styles['record-status']}>
                    Rejected: <span>{rejectedCount || 0}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <div className={styles['learner-results-card-utility']}>
        <div className={styles.meta}>
          <div className={styles['meta-date']}>{activityDates()}</div>
          {claimDate && !isRollupOrganizationNars && (
            <div className={styles['meta-date']}>Credit Claim Date: {formattedDate(claimDate)}</div>
          )}
        </div>
        {!isRollupOrganizationNars && (
          <div className={styles.meta}>
            {!!collaborations.length && (
              <div className={styles['meta-other']}>Collaborations: {collaborations.join(', ')} </div>
            )}
            {!!certifyingBoards.length && (
              <div className={styles['meta-other']}>Certifying Boards: {certifyingBoards.join(', ')}</div>
            )}
          </div>
        )}
      </div>
    </Grid>
  );
};
