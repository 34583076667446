import { BaseApiService } from './baseApiService';
import { IGetAttestationResponse } from './models';

export class AttestationApiService extends BaseApiService {
  public async get(): Promise<IGetAttestationResponse> {
    return await super.getAsync('/api/ProgramSummaries/attestation');
  }

  public async attest(): Promise<void> {
    return await super.postAsync('/api/ProgramSummaries/attest', null);
  }
}
