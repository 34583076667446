import { ICountry, IStateAndProvince, PARSAction } from 'core/models';

export interface LocationsState {
  countries: ICountry[];
  error: Error;
  isLoading: boolean;
  statesAndProvinces: IStateAndProvince[];
}

export const GET_STATES_AND_PROVINCES = 'GET_STATES_AND_PROVINCES';
export const GET_STATES_AND_PROVINCES_SUCCESS = 'GET_STATES_AND_PROVINCES_SUCCESS';
export const GET_STATES_AND_PROVINCES_FAILURE = 'GET_STATES_AND_PROVINCES_FAILURE';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE';

interface GetStatesAndProvinces extends PARSAction<undefined, typeof GET_STATES_AND_PROVINCES> {
  type: typeof GET_STATES_AND_PROVINCES;
}

interface GetStatesAndProvincesSuccess
  extends PARSAction<IStateAndProvince[], typeof GET_STATES_AND_PROVINCES_SUCCESS> {
  payload: IStateAndProvince[];
  type: typeof GET_STATES_AND_PROVINCES_SUCCESS;
}

interface GetStatesAndProvincesFailure extends PARSAction<undefined, typeof GET_STATES_AND_PROVINCES_FAILURE> {
  type: typeof GET_STATES_AND_PROVINCES_FAILURE;
}

interface GetCountries extends PARSAction<undefined, typeof GET_COUNTRIES> {
  type: typeof GET_COUNTRIES;
}
interface GetCountriesSuccess extends PARSAction<ICountry[], typeof GET_COUNTRIES_SUCCESS> {
  payload: ICountry[];
  type: typeof GET_COUNTRIES_SUCCESS;
}
interface GetCountriesFailure extends PARSAction<Error, typeof GET_COUNTRIES_FAILURE> {
  payload: Error;
  type: typeof GET_COUNTRIES_FAILURE;
}

export type LocationsActionTypes =
  | GetCountries
  | GetCountriesFailure
  | GetCountriesSuccess
  | GetStatesAndProvinces
  | GetStatesAndProvincesSuccess
  | GetStatesAndProvincesFailure;
