import { ReactElement, useEffect } from 'react';
import { omit, get, set, cloneDeep } from 'lodash';
import { Field, useField, useFormikContext } from 'formik';
import { BaseTextFieldProps, Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

// Components
import { FormikNumberField } from '../FormikNumberField';
import { FieldCaption } from 'components/FieldCaption';

// Styles
import styles from './FormikCheckboxConditionalNumberInput.module.scss';

// Store + Core
import { IFormInputOption } from 'core/models';
import { rssSelector } from 'store/activity/selectors';
import { amaCreditTermIdSelector } from 'store/taxonomy/selectors';
import { IActivity } from 'core/activitiesApiService';

// Types
interface IProps extends BaseTextFieldProps {
  alwaysZero?: boolean;
  checkboxFormikKey?: string; // Optional override for checkbox name
  defaultValue?: number;
  forceUpdateDefaultValue?: boolean;
  formikKey: string;
  isRequired?: boolean;
  numberInputFormikKey?: string; // Optional override for number input name
  numberInputLabel?: string;
  option?: IFormInputOption;
}

export const FormikCheckboxConditionalNumberInput = (props: IProps): ReactElement => {
  const {
    alwaysZero,
    checkboxFormikKey,
    defaultValue,
    forceUpdateDefaultValue,
    formikKey,
    isRequired = false,
    numberInputFormikKey,
    numberInputLabel,
    option,
    variant = 'outlined',
  } = props;
  const { Id, Title, LabelCaption, Watermark } = option;
  const { setFieldValue, values, setTouched, touched } = useFormikContext<IActivity>();
  const derivedFieldName = checkboxFormikKey || `${formikKey}.${formikKey}${Id}`;
  const numberInputFieldName = numberInputFormikKey || `${formikKey}.${Id}`;
  // Special case: rendering a conditional message when these things are true.
  const rssTypeId = useSelector(rssSelector)?.id;
  const amaCreditId = useSelector(amaCreditTermIdSelector)?.id;

  // Conditional rules to show soft validations specifically for AMA_PRA_Cat1 being <= 3 and RSS type is selected.
  const isTypeIdRSS = values?.typeId === rssTypeId;
  const isCreditsUnderFour = values?.credits?.[amaCreditId] <= 3;
  const isAMAPRA1WarningKey = `credits.credits${amaCreditId}` === derivedFieldName;
  const shouldShowAMAPRA1Warning = !!isTypeIdRSS && isCreditsUnderFour && isAMAPRA1WarningKey;

  const [field] = useField<boolean>(derivedFieldName);

  const isDefault = option?.isDefault;
  const isChecked = field?.value || isDefault;

  // 4812 - need to fire this for a default-checked field so that the formik state has the is-checked flag and the default numeric value
  useEffect(() => {
    if (isDefault) {
      setFieldValue(derivedFieldName, true);
    }
  }, [derivedFieldName, isDefault, setFieldValue]);

  if (!option) {
    return null;
  }

  const onChangeCheckbox = (): void => {
    if (isChecked) {
      // 4655 - we can't assume formikKey doesn't have any dots in it, so values[formikKey] can't be used
      const oldValue = get(values, formikKey);
      const newValue = omit({ ...oldValue }, `${Id}`);
      setFieldValue(`${formikKey}`, newValue);
    } else {
      if (alwaysZero) {
        setFieldValue(numberInputFieldName, 0);
      }
      // 4812 - we're checking the box - trigger validation on the newly-visible number field
      const newTouched = set(cloneDeep(touched), numberInputFieldName, true);
      setTouched(newTouched, true);
    }
    setFieldValue(derivedFieldName, !isChecked);
  };

  return (
    <>
      <Box display="flex">
        <label className="form-input-checkbox">
          <Field name={derivedFieldName}>
            {({ field }) => (
              <input
                {...field}
                aria-required={isRequired}
                checked={isChecked}
                disabled={option?.isDisabled}
                onChange={onChangeCheckbox}
                type="checkbox"
              />
            )}
          </Field>
          <span className="checkmark" />
          <Typography variant="srOnly">{Title}</Typography>
        </label>
        <div className="label-text-container">
          <div className="label-text">{Title}</div>
          {LabelCaption && <div className="caption-text">{LabelCaption}</div>}
        </div>
      </Box>
      {!alwaysZero && isChecked && (
        <div className={styles.indent}>
          {/* Special case messaging for AMA PRA 1 */}
          {shouldShowAMAPRA1Warning && (
            <FieldCaption
              htmlContent="This Regularly Scheduled Series is registered with an unusually low credit value. The number of CME credits available for the activity should be listed as the maximum amount of credit a learner could earn for the entire series, not per individual session. If this amount is correct please disregard this message."
              formikKey={formikKey}
            />
          )}
          <div className={classNames({ [styles['labelled-input']]: !!numberInputLabel })}>
            <FormikNumberField
              aria-required={isRequired}
              ariaLabel={Title}
              defaultValue={defaultValue}
              forceUpdateDefaultValue={forceUpdateDefaultValue}
              formikKey={numberInputFieldName}
              min={0}
              name={numberInputFieldName}
              variant={variant}
              watermark={Watermark}
            />
            {numberInputLabel ? (
              <label className={styles['labelled-input--label']} htmlFor={numberInputFieldName}>
                {numberInputLabel} {isRequired ? <span className="isRequired"> *</span> : null}
              </label>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};
