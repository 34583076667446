import { IContextualMenuOptionProps } from 'components/ContextualMenu/ContextualMenu';

// Types
interface IOption {
  options: IContextualMenuOptionProps[];
}

interface IArgs {
  onExportFilteredLearnerActivities(): void;
  onExportActiveLearnerActivities(): void;
}

export const DOWNLOAD_LEARNERS = 'Download Learners';
export const DOWNLOAD_FILTERED_LEARNERS = 'Download Filtered Learners';

export const exportMenuItems = ({
  onExportActiveLearnerActivities,
  onExportFilteredLearnerActivities,
}: IArgs): IOption => ({
  options: [
    {
      icon: 'get_app',
      name: DOWNLOAD_LEARNERS,
      onSelectAction: onExportActiveLearnerActivities,
      selected: true,
    },
    {
      icon: 'get_app',
      name: DOWNLOAD_FILTERED_LEARNERS,
      onSelectAction: onExportFilteredLearnerActivities,
    },
  ],
});
