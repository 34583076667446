import { IUserOrganization, OrganizationKinds } from 'core/models';
import { useSelector } from 'react-redux';
import { currentOrganizationContextSelector, currentProviderContextSelector } from 'store/user/selectors';

export function useIsProviderSelected() {
  // Selectors
  const currentOrganization: IUserOrganization = useSelector(currentOrganizationContextSelector);
  const currentProvider: IUserOrganization = useSelector(currentProviderContextSelector);
  const isProviderSelected = !!currentProvider || currentOrganization?.organizationKind === OrganizationKinds.PROVIDER;

  return isProviderSelected;
}
