import { ITaxonomyTerm, IUserOrganization, RollupOrganizationEnums } from 'core/models';

interface IArgs {
  organization: IUserOrganization;
  terms: ITaxonomyTerm[];
}

export const filterTaxonomyByRollupOrganizationEnum = (
  terms: ITaxonomyTerm[],
  rollupOrganizationEnum: RollupOrganizationEnums = RollupOrganizationEnums.PARS,
): ITaxonomyTerm[] => {
  // Null check.
  if (!terms || terms.length === 0) return [];

  // Filter taxonomy terms based on rollup org *flag* (PARS = 1, JA = 2, NARS = 8) - ie. 9 is valid for both PARS and NARS
  const metadataNumberFlag =
    rollupOrganizationEnum === RollupOrganizationEnums.NARS
      ? 8
      : rollupOrganizationEnum === RollupOrganizationEnums.JA
      ? 2
      : 1;
  return terms.filter(
    ({ metadataNumber }: ITaxonomyTerm) =>
      (metadataNumber & metadataNumberFlag) === metadataNumberFlag || !metadataNumber,
  );
};

/**
 * @function filterTaxonomyByOrg
 * @param organization
 * @param terms
 * @returns ITaxonomyTerm[]
 *
 * @description - A helper function that returns the correct terms by looking at the metadataNumber
 *                based on the rollup organization of the activity.
 */
export const filterTaxonomyByOrg = ({ organization, terms }: IArgs): ITaxonomyTerm[] => {
  return filterTaxonomyByRollupOrganizationEnum(terms, organization.rollupOrganizationEnum);
};
