import { ReactElement, useEffect } from 'react';
import { FieldArray, FieldArrayRenderProps, Field, useFormikContext } from 'formik';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import { Fab, IconButton } from '@material-ui/core';

// Components
import DatePicker from '../DatePicker';

// Styles
import styles from './index.module.scss';

// Types
interface IProps {
  formikKey: string;
}
export const DatePickerRepeater = (props: IProps): ReactElement => {
  const { formikKey } = props;
  const { setFieldTouched, values } = useFormikContext();
  useEffect(() => {
    if (formikKey) {
      if (values?.[formikKey]?.length === 0) {
        values?.[formikKey].push('');
        setFieldTouched(formikKey, true);
      }
    }
  }, []);
  return (
    <div className={styles.container}>
      <FieldArray
        name={formikKey}
        render={(arrayHelpers: FieldArrayRenderProps) => {
          return (
            <>
              {values?.[formikKey]?.map(
                (_: ReactElement, idx: number): ReactElement => {
                  return (
                    <div className={styles.wrapper} key={idx}>
                      <span>
                        <Field name={`${formikKey}.${idx}`}>
                          {({ field }) => <DatePicker field={field} formikKey={formikKey} timeOfDay="midnight" />}
                        </Field>
                        <IconButton aria-label="remove date" onClick={() => arrayHelpers.remove(idx)}>
                          <ClearIcon />
                        </IconButton>
                      </span>
                    </div>
                  );
                },
              )}
              <div className={styles.add}>
                <Fab
                  aria-label="add another instance"
                  color="inherit"
                  onClick={() => {
                    arrayHelpers.push('');
                    setFieldTouched(formikKey, true);
                  }}
                  size="small"
                >
                  <AddIcon color="inherit" />
                </Fab>
                <span className={styles.text}>Add another live date</span>
              </div>
            </>
          );
        }}
      />
    </div>
  );
};
