import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { CheckRounded, DeleteRounded, Description } from '@material-ui/icons';
import every from 'lodash/every';
import sumBy from 'lodash/sumBy';
import moment from 'moment';

// Components
import StaticToast from 'components/StaticToast/StaticToast';
// Store + Core
import { selectedActivitiesSelector } from 'store/activity/selectors';
import { StatusEnum, ToastType } from 'core/enums';
import {
  ActivitySearchResultActivity,
  OrganizationKind,
  OrganizationKinds,
  RollupOrganizationEnums,
} from 'core/models';
import {
  isStaffUserSelector,
  organizationKindSelector,
  currentOrganizationContextSelector,
  staffRollupOrganizationEnumsSelector,
} from 'store/user/selectors';
import {
  ACTIVITY_RECENTLY_CLOSED_THRESHOLD,
  accreditorOrStaff,
  staffProvider,
  withoutBoard,
  MESSAGE_CANNOT_REOPEN_AFTER_30_DAYS,
} from 'core/constants';

// Types
interface IProps {
  closeAction(boolean): void;
  deleteAction(boolean): void;
  selectForReviewAction(boolean): void;
  uncloseAction(boolean): void;
}

export const ToastActions = (props: IProps): ReactElement => {
  const { closeAction, deleteAction, selectForReviewAction, uncloseAction } = props;

  // Selectors.
  const selectedActivities: ActivitySearchResultActivity[] = useSelector(selectedActivitiesSelector);
  const organizationKind: OrganizationKind = useSelector(organizationKindSelector)?.organizationKind;
  const isStaffUser: boolean = useSelector(isStaffUserSelector);
  const currentOrganizationKind = useSelector(currentOrganizationContextSelector)?.organizationKind;
  const staffRollupOrganizationEnums = useSelector(staffRollupOrganizationEnumsSelector);
  const currentOrganizationEnum = useSelector(currentOrganizationContextSelector)?.rollupOrganizationEnum;
  const activitiesExist: boolean = selectedActivities.length > 0;

  // An activity must have both physician learners and total other learners in order to be closed. AB#3869
  const hasLearners: boolean =
    activitiesExist &&
    every(
      // NARS TODO: I think there's at least one other place where this logic about how many learner counts are required for close, so we should create a shared method for it
      selectedActivities.map(
        ({ learnerCounts, rollupOrganizationEnum }: ActivitySearchResultActivity): number =>
          learnerCounts.length >= (rollupOrganizationEnum === RollupOrganizationEnums.JA ? 1 : 2) &&
          sumBy(learnerCounts, 'count'),
      ),
    );

  const isStaffRollupOrganizationEnums = staffRollupOrganizationEnums.includes(currentOrganizationEnum);

  const areAllClosed: boolean = activitiesExist && every(selectedActivities, ['status', StatusEnum.CLOSED]);
  const areAllReadyToClose: boolean =
    activitiesExist && every(selectedActivities, ['status', StatusEnum.READY_TO_CLOSE]);
  const allStatuses: StatusEnum[] =
    activitiesExist && selectedActivities?.map(({ status }: ActivitySearchResultActivity) => status);
  const areTheSameStatus: boolean =
    activitiesExist && allStatuses.every((status: StatusEnum) => status === allStatuses[0]);

  // You can only delete activities that don't have learners.
  const areAllWithoutLearners: boolean = activitiesExist && every(selectedActivities, ['hasLearners', false]);

  const showClose = areAllReadyToClose && staffProvider.includes(organizationKind as OrganizationKinds) && hasLearners;
  const showReopen = areAllClosed && withoutBoard.includes(currentOrganizationKind as OrganizationKinds);
  const hasBeenClosedRecently: boolean =
    activitiesExist &&
    every(
      selectedActivities.map(
        ({ closeDate }: ActivitySearchResultActivity): boolean =>
          !!closeDate &&
          moment(closeDate).isSameOrAfter(moment().subtract(ACTIVITY_RECENTLY_CLOSED_THRESHOLD, 'days'), 'day'),
      ),
    );
  const canReopen = hasBeenClosedRecently || accreditorOrStaff.includes(currentOrganizationKind as OrganizationKinds);

  const defaultToastOptions = {
    centered: true,
    toastType: ToastType.TOAST_INFO,
  };

  const deleteOption = areAllWithoutLearners
    ? {
        ...defaultToastOptions,
        icon: <DeleteRounded className="icon" />,
        message: <>Delete Selected Activities</>,
        onClick: (): void => deleteAction(true),
      }
    : null;

  const closeOption = {
    ...defaultToastOptions,
    icon: <CheckRounded className="icon" />,
    message: <>Mark Activities As Closed</>,
    onClick: (): void => closeAction(true),
  };

  const uncloseOption = {
    ...defaultToastOptions,
    disabled: !canReopen,
    disabledText: MESSAGE_CANNOT_REOPEN_AFTER_30_DAYS,
    icon: <CheckRounded className="icon" />,
    message: <>Reopen Selected Activities</>,
    onClick: (): void => uncloseAction(true),
  };

  const selectForReview =
    isStaffUser || isStaffRollupOrganizationEnums
      ? {
          ...defaultToastOptions,
          icon: <Description className="icon" />,
          message: <>Select for Review</>,
          onClick: (): void => selectForReviewAction(true),
        }
      : null;

  const defaultOption = {
    ...defaultToastOptions,
    message: <>No action available for the combined selected activities - adjust selections.</>,
  };

  let toastOptions;

  if (areAllClosed) {
    toastOptions = [showReopen && uncloseOption, selectForReview];
  } else if (areAllReadyToClose) {
    toastOptions = [showClose && closeOption, deleteOption, selectForReview];
  } else if (areTheSameStatus) {
    toastOptions = [deleteOption, selectForReview];
  } else {
    toastOptions = activitiesExist ? [defaultOption] : [];
  }

  toastOptions = toastOptions.filter(Boolean);
  if (toastOptions.length === 0 && activitiesExist) {
    toastOptions = [defaultOption];
  }

  return <StaticToast isOpen={!!selectedActivities.length} toastOptions={toastOptions} />;
};
