import { PropsWithChildren, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { IUserOrganization, OrganizationKinds } from 'core/models';
import { currentOrganizationContextSelector } from 'store/user/selectors';

/**
 * Checks if the current User is Admin/Staff and renders it's child elements if they are.
 * @param props
 * @constructor
 */
export const AuthenticatedAdminElement = (props: PropsWithChildren<{}>): ReactElement => {
  const currentOrganization: IUserOrganization = useSelector(currentOrganizationContextSelector);
  const isStaff = currentOrganization?.securityGroups?.includes(OrganizationKinds.STAFF);

  return isStaff ? <>{props?.children}</> : null;
};
