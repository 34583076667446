import { IAttestation } from 'core/models';
import { Reducer } from 'redux';
import {
  ATTEST,
  AttestationState,
  AttestationTypes,
  ATTEST_FAILURE,
  ATTEST_SUCCESS,
  GET_ATTESTATION,
  GET_ATTESTATION_FAILURE,
  GET_ATTESTATION_SUCCESS,
} from './types';

const initialState: AttestationState = {
  // Tracks the loading status of the Attest API call
  isAttestLoading: false,
  // Tracks the retrieval status of the attestation object from the API
  isAttestationLoading: false,
};

const attestationReducer: Reducer<AttestationState, AttestationTypes> = (
  state = initialState,
  action: AttestationTypes,
) => {
  switch (action.type) {
    case GET_ATTESTATION:
      return {
        ...state,
        isAttestationLoading: true,
      };
    case GET_ATTESTATION_SUCCESS:
      return {
        ...state,
        attestation: action.payload as IAttestation,
        isAttestationLoading: false,
      };
    case GET_ATTESTATION_FAILURE:
      return {
        ...state,
        isAttestLoading: false,
      };
    case ATTEST:
      return {
        ...state,
        isAttestLoading: true,
      };
    case ATTEST_FAILURE:
      console.error(action.payload);
      return { ...state, isAttestLoading: false };
    case ATTEST_SUCCESS:
      return { ...state, isAttestLoading: false };
    default:
      return state;
  }
};

export default attestationReducer;
