import { ReactElement } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Box, IconButton } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import classNames from 'classnames';

// Components
import { FormikInputField, FormikInputFieldProps } from '../PureFormElements';

// Styles
import styles from '../Facets/Facets.module.scss';

export const FormikInputFieldWithSearch = (props: FormikInputFieldProps): ReactElement => {
  const { formikKey, placeholder, title, disabled } = props;

  return (
    <Box display="flex" alignItems="center">
      <FormikInputField
        formikKey={formikKey}
        noOffset
        placeholder={placeholder}
        startIcon={<SearchIcon />}
        title={title}
      />
      <Box ml={-5}>
        <IconButton
          aria-label="search"
          className={classNames(styles['search-icon'])}
          disabled={disabled}
          size="medium"
          type="submit"
        >
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
