import { Reducer } from 'redux';
import { ToastActionTypes, TOAST_OPEN, TOAST_CLOSE, ToastState } from './types';
import * as PARS from '../../core';

const initialState: ToastState = {
  toastOpen: false,
  toastOptions: undefined,
};

const toastReducer: Reducer<ToastState, ToastActionTypes> = (
  state = initialState,
  action: ToastActionTypes,
): ToastState => {
  switch (action.type) {
    case TOAST_OPEN:
      return {
        ...state,
        toastOpen: true,
        toastOptions: action.payload as PARS.Models.IToastOptions,
      };
    case TOAST_CLOSE:
      return {
        ...state,
        toastOpen: false,
      };
    default:
      return state;
  }
};

export default toastReducer;
