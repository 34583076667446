export default Object.freeze({
  api: process.env.REACT_APP_API || '',
  applicationInsightsKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY || '',
  armatureUrl: process.env.REACT_APP_ARMATURE_URL || '',
  authAudience: process.env.REACT_APP_AUTH_AUDIENCE || '',
  authDomain: process.env.REACT_APP_AUTH_DOMAIN || '',
  authRedirectUri: window.location.origin,
  authScope: process.env.REACT_APP_AUTH_SCOPE || '',
  disableAnalytics: process.env.REACT_APP_DISABLE_ANALYTICS || true,
  gtmId: process.env.REACT_APP_GTM_ID || '',
  narsAuthClientId: process.env.REACT_APP_NARS_CLIENT_ID || '',
  parsAuthClientId: process.env.REACT_APP_PARS_CLIENT_ID || '',
  parsUiVersion: process.env.REACT_APP_PARS_UI_VERSION || 'local',
  powerBIAccreditorWorkspaceId: process.env.REACT_APP_POWER_BI_ACCREDITOR_WORKSPACE_ID || '',
  powerBIBoardWorkspaceId: process.env.REACT_APP_POWER_BI_BOARD_WORKSPACE_ID || '',
  powerBIJAProviderWorkspaceId: process.env.REACT_APP_POWER_BI_PROVIDER_JA_WORKSPACE_ID || '',
  powerBIProviderWorkspaceId: process.env.REACT_APP_POWER_BI_PROVIDER_WORKSPACE_ID || '',
  powerBIStaffWorkspaceId: process.env.REACT_APP_POWER_BI_STAFF_WORKSPACE_ID || '',
  powerBiNarsAnccWorkspaceId: process.env.REACT_APP_POWER_BI_NARS_ANCC_WORKSPACE_ID || '',
  powerBiNarsProvidersWorkspaceId: process.env.REACT_APP_POWER_BI_NARS_PROVIDERS_WORKSPACE_ID || '',
  powerBiNarsAccreditorsWorkspaceId: process.env.REACT_APP_POWER_BI_NARS_ACCREDITORS_WORKSPACE_ID || '',
  sendToAppInsightsThreshold: process.env.REACT_APP_INSIGHT_THRESHOLD || 100,
  sendToConsoleThreshold: process.env.REACT_APP_CONSOLE_THRESHOLD || 100,
  sendToToastThreshold: process.env.REACT_APP_TOAST_THRESHOLD || 100,
  storage: process.env.REACT_APP_STORAGE || 'localStorage',
});
