import { ChangeEvent, ReactElement, useMemo } from 'react';
import { InputAdornment, TextField, makeStyles } from '@material-ui/core';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { Close, ExpandMoreRounded, SearchRounded } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import { findIndex, get } from 'lodash';

// Components
import Button from 'components/Button/Button';
import { InputOption } from '../InputOption';

// Styles
import styles from '../TypeaheadMulti.module.scss';

const useStyles = makeStyles({
  listbox: {
    borderRadius: 0,
    boxShadow: 'none',
    padding: 0,
  },
  option: {
    backgroundColor: '#fff',
    borderBottom: '0.03125rem solid #dedede',
    borderRadius: 0,
    padding: '0.5rem 1rem',
    '&[aria-selected="true"]': {
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '&:last-child': {
      borderBottom: 0,
    },
  },
  paper: {
    borderRadius: 0,
    boxShadow: 'none',
    margin: 0,
    padding: 0,
  },
  popper: {
    backgroundColor: '#fff',
    border: '0.03125rem solid #dedede',
    position: 'relative',
  },
});

// Types
export interface ILabelType {
  id: string;
  name: string;
  description?: string;
}
interface IProps {
  formikKey: string;
  options: ILabelType[];
  placeholder: string;
}

export const MultiSelectObject = (props: IProps): ReactElement => {
  const { formikKey, options, placeholder } = props;
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext<[]>();
  const selectedOptions =
    get(values, formikKey)?.map(({ id, name, ...rest }): ILabelType => ({ id, name, ...rest })) || [];

  const onRemove = ({ id }) => {
    setFieldValue(
      formikKey,
      selectedOptions.filter((item): boolean => item.id !== id),
    );
  };

  const onClickDropdownOption = (_event: ChangeEvent<never>, newValues): void => {
    // `newValues` gives us all of the items of what is selected.
    // We only need the last one in the array, and only the `name` and `id. Append it to the list of selectedOptions.
    const { id, name } = newValues.slice(-1)[0];

    setFieldValue(formikKey, [...selectedOptions, { id, name }]);
  };

  const renderOption = (option, { selected }): ReactElement => <InputOption option={option} selected={selected} />;
  const optionLabel = ({ name }: ILabelType): string => name;

  // Display the selected options at the top of the options.
  const sortedOptions: ILabelType[] = useMemo(
    () =>
      [...options].sort((a: ILabelType, b: ILabelType): number => {
        let ai: number = findIndex(selectedOptions, ({ name }: ILabelType): boolean => name === a.name);
        ai = ai === -1 ? selectedOptions.length + options?.indexOf(a) : ai;
        let bi: number = findIndex(selectedOptions, ({ name }: ILabelType): boolean => name === b.name);
        bi = bi === -1 ? selectedOptions.length + options?.indexOf(b) : bi;
        return ai - bi;
      }),
    [options],
  );

  return (
    <div className={styles['multi-autocomplete']}>
      <Autocomplete
        aria-label={placeholder}
        classes={{
          listbox: classes.listbox,
          option: classes.option,
          paper: classes.paper,
          popperDisablePortal: classes.popper,
        }}
        disableCloseOnSelect
        disablePortal
        getOptionLabel={optionLabel}
        getOptionSelected={(option: ILabelType, value: ILabelType) => option?.id === value?.id}
        multiple
        noOptionsText="No options"
        onChange={onClickDropdownOption}
        openOnFocus
        options={sortedOptions}
        renderInput={(params: AutocompleteRenderInputParams): ReactElement => {
          params.InputProps.startAdornment = (
            <InputAdornment position="start">
              <SearchRounded />
            </InputAdornment>
          );
          params.InputProps.endAdornment = (
            <InputAdornment position="end">
              <ExpandMoreRounded />
            </InputAdornment>
          );
          return (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, 'aria-label': placeholder }}
              placeholder={placeholder}
              variant="outlined"
            />
          );
        }}
        renderOption={renderOption}
        value={selectedOptions}
      />

      {/* List of selected items. */}
      {!!selectedOptions?.length && (
        <div className={styles['selected-summary']}>
          {selectedOptions.map(
            (item: ILabelType): ReactElement => {
              const { id, name } = item;

              return (
                <div className={styles['selected-summary-item']} key={id}>
                  <div>{name}</div>
                  <Button onClick={(): unknown => onRemove(item)} title="remove">
                    <Close />
                  </Button>
                </div>
              );
            },
          )}
        </div>
      )}
    </div>
  );
};
