import { Fragment, ReactElement, ReactNode, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardBackspaceRounded } from '@material-ui/icons';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';
import { isBoolean, isNumber, kebabCase, keys, orderBy, sortBy, uniqueId } from 'lodash';

// Store + Core
import { ButtonVariant, StatusEnum } from 'core/enums';
import {
  ACTIVITY_TYPE_ABBREVIATION_ENUM,
  TAGS_ENUM,
  TAXONOMY_LEARNER_TYPES_ROOT_ID,
  TAXONOMY_REMS_ROOT_ID,
  DELIVERY_METHOD_REQUIRED_ACTIVITY_TYPES,
  TAXONOMY_ACTIVITY_PHARMACY_CERTIFICATION_ID,
} from 'core/constants';
import {
  Activity,
  ActivityType,
  BoardMocDetails,
  CommercialSupportSource,
  IActivitySearchRequest,
  IActivitySearchStateProps,
  IBCTSpecialty,
  IBoardRemsDetails,
  IContentOutline,
  IDictionary,
  ITaxonomyTerm,
  LocationTypeStringEnum,
  ICountry,
  RollupOrganizationEnums,
  ContentTag,
  HasStateContentTags,
  HasPharmacyRecertifications,
  HasPharmacyContentTags,
  IUserOrganization,
  OrganizationKinds,
} from 'core/models';
import { eatToast, popToast } from 'store/toast/actions';
import {
  clearActivityDetail,
  getActivities,
  getActivity,
  getActivityTypes,
  setActivityEditIndexAction,
  updateActivitySearchRequest,
  updateSearchStateProps,
} from 'store/activity/actions';
import { getCountries } from 'store/locations/actions';
import { closeRail, openRail } from 'store/rail/actions';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import {
  activityDetailSelector,
  activityErrorSelector,
  activityTypesSelector,
  getParticipationFeeNameSelector,
  searchRequestSelector,
  searchStatePropsSelector,
  learnerCountsSelector,
} from 'store/activity/selectors';
import { configuredBoardsDictionarySelector } from 'store/board/selectors';
import {
  allCommendationCriterionSelector,
  allOutcomesMeasuredSelector,
  amaCreditTermIdSelector,
  creditChildTermsSelector,
  creditTypesDictionarySelector,
  pharmacyActivityTypesDictionarySelector,
  pharmacyTopicsDictionarySelector,
  taxonomyRootTermsSelector,
  allLearnerTypeTermsSelector,
  learnerKnowledgeMeasuredOutcomeSelector,
} from 'store/taxonomy/selectors';
import { isRailOpenSelector } from 'store/rail/selectors';
import { getBoardById } from 'store/board/actions';
import { errorStickyToastOptions, infoToastOptions } from 'store/toast/constants';
import { isBoardUserSelector, organizationKindSelector } from 'store/user/selectors';
import { countriesSelector } from 'store/locations/selectors';

// Components
import { ActivityActions } from 'components/Summary/ActivityActions';
import { Modal } from 'components/ContinuousImprovement';
import Button from 'components/Button/Button';
import { DetailLoading } from './detail-loading';

// Forms
import { DeleteActivityForm, UpdateLearnersForm, CopyActivityForm } from 'components/forms';

import styles from './detail.module.scss';

// Constants
import { termSetIds } from 'layouts/pages/activity/constants';
import { IBCTBoard, IBCTCreditType } from 'layouts/pages/bct/types';

// Utils
import { findParents } from 'components/ABAContentOutlines/utils/findParents';
import { EditButton } from './components';
import { findTaxonomyNameById } from 'utils';

import { filterTaxonomyByRollupOrganizationEnum } from 'globals/utils/filterTaxonomyByOrg';
import { useIsProviderSelected } from 'components/global/providerRestriction/ProviderRestriction';
import { getRemsRegularExpression } from '../utils';
import { ActivityService } from 'services/ActivityService';
import { configuredContentTagsHierarchySelector, contentTagsSelector } from 'store/contentTags/selectors';
import { getAllContentTags } from 'store/contentTags/actions';

type BoardDescription = { activityBoard: BoardMocDetails; boardId: string };
type RemsDescription = { remId: string; rem: IBoardRemsDetails };

const canAddIndividualLearners = (
  isUpdateTotalLearnersDisabled: boolean,
  status: StatusEnum,
  hasAmaCredits: boolean,
  hasMocBoards: boolean,
  hasRems: boolean,
): [true, string] | [false, undefined] => {
  if (!hasAmaCredits && !hasMocBoards && !hasRems) {
    return [true, 'Cannot report learners for this activity'];
  }
  if (isUpdateTotalLearnersDisabled || status === StatusEnum.DRAFT) {
    return [true, 'Cannot add learners before activity start date or in DRAFT status.'];
  }
  return [false, undefined];
};

const ActivityDetailPage = (): ReactElement => {
  const detailContentClass = styles['detail-content'];
  const detailGroupClass = styles['detail-group'];
  const detailGroupLabelClass = styles['detail-group-label'];
  const detailContentLabelClass = styles['detail-content-label'];
  const detailContentTagClass = styles['detail-content-tag-class'];
  const detailContentTagClassFont = styles['detail-content-tag-class-font'];
  const detailContentValueClass = styles['detail-content-value'];
  const incompleteFieldMessage = 'Incomplete field - required to set Activity as ';
  const registrationErrorMessage = 'Incomplete field - required for program registration';
  const dispatch = useDispatch();
  const history = useHistory();
  const returnToSummaryPage = () => history.push('/activities/summary');

  const [isUpdateLearnersModalOpen, setIsUpdateLearnersModalOpen] = useState<boolean>(false);
  const [isDeleteActivityModalOpen, setIsDeleteActivityModalOpen] = useState<boolean>(false);
  const [isCopyActivityModalOpen, setIsCopyActivityModalOpen] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { isAuthenticated } = useAuth0();
  const isProviderSelected = useIsProviderSelected();

  // Selectors.
  const activityDetail: Activity = useSelector(activityDetailSelector);
  const activityTypes: ActivityType[] = useSelector(activityTypesSelector);
  const contentTagsDetail: ContentTag[] = useSelector(contentTagsSelector);
  const contentTagsParentDetails: ContentTag[] = useSelector(configuredContentTagsHierarchySelector);
  const outcomesTerms: ITaxonomyTerm[] = useSelector(allOutcomesMeasuredSelector) || [];
  const learnerKnowledgeMeasuredOutcome: ITaxonomyTerm = useSelector(learnerKnowledgeMeasuredOutcomeSelector);
  const amaCreditTerm: ITaxonomyTerm = useSelector(amaCreditTermIdSelector);
  const baseTerms: IDictionary<ITaxonomyTerm> = useSelector(taxonomyRootTermsSelector);
  const allCommendationCriterion: ITaxonomyTerm[] = useSelector(allCommendationCriterionSelector);
  const creditChildTerms: ITaxonomyTerm[] = useSelector(creditChildTermsSelector);
  const creditTypesDictionary: IDictionary<ITaxonomyTerm> = useSelector(creditTypesDictionarySelector);
  const hasError = !!useSelector(activityErrorSelector);
  const rollupOrganizationEnum = activityDetail?.rollupOrganizationEnum;
  const isJa = rollupOrganizationEnum === RollupOrganizationEnums.JA;
  const isNars = rollupOrganizationEnum === RollupOrganizationEnums.NARS;
  const isOpen: boolean = useSelector(isRailOpenSelector);
  const isBoardUser: boolean = useSelector(isBoardUserSelector);
  const participationFeeName: string = useSelector(getParticipationFeeNameSelector);
  const pharmacyActivityTypesDictionary: IDictionary<ITaxonomyTerm> = useSelector(
    pharmacyActivityTypesDictionarySelector,
  );
  const pharmacyTopicsDictionary: IDictionary<ITaxonomyTerm> = useSelector(pharmacyTopicsDictionarySelector);
  const searchRequest: IActivitySearchRequest = useSelector(searchRequestSelector);
  const searchStateProps: IActivitySearchStateProps = useSelector(searchStatePropsSelector);
  const boardConfigurations: IDictionary<IBCTBoard> = useSelector(configuredBoardsDictionarySelector);
  const pharmacyCreditTerm = creditChildTerms?.find(({ tag }) => tag === TAGS_ENUM.ACTIVITY_CREDIT_TYPE__PHARMACY);
  const countries: ICountry[] = useSelector(countriesSelector);
  const currentUserOrganization: IUserOrganization = useSelector(organizationKindSelector);

  const isOrganizationAccreditor = currentUserOrganization.organizationKind === OrganizationKinds.ACCREDITOR;

  const onCloseUpdateLearnersModal = (): void => setIsUpdateLearnersModalOpen(false);
  const onCloseDeleteActivityModal = (): void => setIsDeleteActivityModalOpen(false);
  const onCloseCopyActivityModal = (): void => setIsCopyActivityModalOpen(false);

  const toggleDeletedActivities = async (): Promise<void> => {
    // Show the deleted items.
    await dispatch(
      updateActivitySearchRequest({
        ...searchRequest,
        isDeleted: true,
      }),
    );

    // Reset paging.
    await dispatch(
      updateSearchStateProps({
        ...searchStateProps,
        page: 1,
      }),
    );

    // Close the toast.
    await dispatch(eatToast());
  };

  const onDeleteActivityCallback = async (): Promise<void> => {
    // Refetch all activities.
    await dispatch(getActivities());

    // Reset paging.
    await dispatch(
      updateSearchStateProps({
        ...searchStateProps,
        page: 1,
      }),
    );

    // Go back to summary page.
    await history.push('/activities/summary');

    // Show a toast to the user.
    await dispatch(
      popToast({
        ...infoToastOptions,
        message: (
          <>
            Activity Deleted. See &nbsp;
            <Button variant={ButtonVariant.Label} onClick={toggleDeletedActivities}>
              Deleted Activities
            </Button>
          </>
        ),
      }),
    );
  };

  const onCopyCallback = async (): Promise<void> => {
    const act = await ActivityService.getById(activityDetail?.id);
    if (act != null) history.push('/activities/add-activity/new', { params: act });
  };

  // Fetch countries.
  useEffect(() => {
    if (!countries) {
      dispatch(getCountries());
    }
  }, [countries, dispatch]);

  // Get the current activity detail and REMS taxonomy
  useEffect(() => {
    setIsUpdateLearnersModalOpen(false);
    if (activityDetail?.id?.toLowerCase() !== id.toLowerCase()) {
      dispatch(getActivity(id));
    }
    if (activityDetail?.id === id) {
      dispatch(getTaxonomyTermById(TAXONOMY_REMS_ROOT_ID));

      // Get the learnerTypes taxonomy terms.
      dispatch(getTaxonomyTermById(TAXONOMY_LEARNER_TYPES_ROOT_ID));
    }
    if (activityDetail) {
      const combinedPharmacyIds = [
        ...activityDetail?.pharmacyContentTagStateTaxonomyTerms,
        ...activityDetail?.pharmacyContentTagTaxonomyTerms,
        ...activityDetail?.pharmacyRecertificationTaxonomyTerms,
      ];
      const uniquePharmacyIds = new Set(combinedPharmacyIds);
      uniquePharmacyIds.forEach((id: string) => {
        dispatch(getTaxonomyTermById(id));
      });
    }
  }, [activityDetail, dispatch, id, isAuthenticated]);

  useEffect(() => {
    // Clear out any error states when leaving this page.
    return () => {
      dispatch(clearActivityDetail());
    };
  }, []);

  useEffect(() => {
    if (!contentTagsDetail?.length || contentTagsDetail?.length < 1) {
      dispatch(getAllContentTags());
    }
  }, [contentTagsDetail, dispatch, isAuthenticated]);

  useEffect(() => {
    if (!activityTypes || activityTypes?.length < 1) {
      dispatch(getActivityTypes());
    }
  }, [activityTypes, dispatch, isAuthenticated]);

  // Get detailed board config
  useEffect(() => {
    const selectedBoardIds = [...keys(activityDetail?.boardMocDetails), ...keys(activityDetail?.boardRemsDetails)];
    const missingBoardIds: string[] = selectedBoardIds.filter((boardId) => !boardConfigurations?.[boardId]);

    if (missingBoardIds?.length) {
      Promise.all(missingBoardIds.map((boardId) => dispatch(getBoardById(boardId))));
    }
  }, [activityDetail?.boardMocDetails, activityDetail?.boardRemsDetails]);

  // Taxonomy Initialization Hook
  useEffect(() => {
    termSetIds.forEach((id) => {
      if (!baseTerms[id] && isAuthenticated) {
        dispatch(getTaxonomyTermById(id));
      }
    });
  }, []);
  // Get all the learnerTypeTerms from local storage.
  const allLearnerTypeTerms: ITaxonomyTerm[] = useSelector(allLearnerTypeTermsSelector);

  // Get only the terms from the learnerTypes based on the activity's rollup.
  const terms: ITaxonomyTerm[] = filterTaxonomyByRollupOrganizationEnum(allLearnerTypeTerms, rollupOrganizationEnum);

  // Get the leaner counts from the activity detail to pre-populate the form.
  const learnerCounts: IDictionary<number> = useSelector(learnerCountsSelector);

  // Render nothing until we have data.
  if (!activityDetail) return <DetailLoading isOpen={isOpen} hasError={hasError} />;

  const {
    boardMocDetails,
    boardRemsDetails,
    city = '',
    contentTags,
    country: countryCode,
    closeDate,
    commendationCriteriaIds,
    commercialSupportSources,
    credits,
    description,
    detailsUrl,
    endDate,
    hasCommercialSupport,
    hasPharmacyContentTags,
    includeInCmeFinder,
    internalId,
    isJointlyProvided,
    isMips,
    isMoc,
    isPharmacyCertificateProgram,
    isRems,
    isRestrictedAudience,
    jointProviders,
    locationType,
    mocCreditDeadline,
    mocProgramAttestation,
    outcomesMeasured,
    pharmacyLiveDates,
    pharmacySequenceNumber,
    pharmacyTopicId,
    pharmacyTypeId,
    postalCode = '',
    printableId,
    startDate,
    stateOrProvince = '',
    status,
    targetAudience,
    title,
    typeId,
    uanPharmacy,
    uanPharmacyTech,
    hasStateContentTags,
    hasPharmacyRecertifications,
    pharmacyContentTagStateTaxonomyTerms,
    pharmacyContentTagTaxonomyTerms,
    pharmacyRecertificationTaxonomyTerms,
    isIndividualActivity,
    iaOrganizationName,
  } = activityDetail;

  // Get the error message for missing or incomplete fields based on status
  const getIncompleteFieldMessage = ({
    message,
    nextStatus,
  }: {
    message?: string;
    nextStatus?: StatusEnum;
  }): ReactElement => {
    return (
      <span role="status" className={styles['field-error']}>
        [{message || incompleteFieldMessage}
        {`${nextStatus ? ` "${nextStatus.toString()}"` : ''}]`}
      </span>
    );
  };

  // Error message for DRAFT to ACTIVE transition
  const draftToActiveError = getIncompleteFieldMessage({
    message: incompleteFieldMessage,
    nextStatus: StatusEnum.ACTIVE,
  });
  const toCloseError = getIncompleteFieldMessage({
    message: incompleteFieldMessage,
    nextStatus: StatusEnum.CLOSED,
  });
  // Error message for Program Registration incomplete
  const registrationError = getIncompleteFieldMessage({
    message: registrationErrorMessage,
  });
  const errorTotalLearnerReported = () => {
    const itemsWithValue = terms?.filter((item) => learnerCounts[item.id] !== undefined);
    if (isJa || isNars) {
      if (
        !activityDetail.providerAutoCloseActivities &&
        itemsWithValue.length === 0 &&
        status.toLowerCase() === StatusEnum.READY_TO_CLOSE.toLowerCase()
      )
        return toCloseError;
      else return null;
    }
    if (
      !activityDetail.providerAutoCloseActivities &&
      itemsWithValue.length !== terms?.length &&
      status.toLowerCase() === StatusEnum.READY_TO_CLOSE.toLowerCase()
    ) {
      return toCloseError;
    }
    return null;
  };

  const renderToCloseError = status === StatusEnum.READY_TO_CLOSE ? toCloseError : '';

  const activityType = activityTypes?.find(({ id }: ActivityType) => id === typeId);
  const liveDateActivityTypeList = activityTypes
    ?.filter(({ abbreviation }: ActivityType) =>
      [
        ACTIVITY_TYPE_ABBREVIATION_ENUM.C,
        ACTIVITY_TYPE_ABBREVIATION_ENUM.RSS,
        ACTIVITY_TYPE_ABBREVIATION_ENUM.LFT,
        ACTIVITY_TYPE_ABBREVIATION_ENUM.CML,
      ].includes(abbreviation),
    )
    .map(({ id }: ActivityType): string => id);
  const isActivityLiveType = liveDateActivityTypeList?.includes(typeId);

  const uanPharmacyString = `${uanPharmacy ? `UAN ${uanPharmacy}` : ''}`;
  const uanPharmacyTechString = `${uanPharmacyTech ? `UAN ${uanPharmacyTech}` : ''}`;

  const dateFormat = 'MM/DD/YY';
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const formattedStartDate = startDateMoment.format(dateFormat);
  const formattedEndDate = endDateMoment.format(dateFormat);
  const activityDatesString = `${formattedStartDate} - ${formattedEndDate}`;
  const today = moment();

  const countryObj = (countries ?? []).filter((_) => _.isoCountryCode && _.isoCountryCode === countryCode)[0];
  const country = countryObj?.countryName ?? countryCode;

  const combinedLocationString = `${city}${stateOrProvince ? `, ${stateOrProvince}` : ''}${
    postalCode ? ` ${postalCode}` : ''
  }${country ? ` - ${country}` : ''}`;

  const mocOrRemsRegistration: boolean = isMoc || isRems;

  const activityLocationString =
    locationType?.includes(LocationTypeStringEnum.InPerson) &&
    (!city || (countryCode === 'US' && !stateOrProvince) || !country) ? (
      <>
        {combinedLocationString} {draftToActiveError}
      </>
    ) : (
      combinedLocationString
    );

  const includeInCmeFinderString = includeInCmeFinder ? 'Yes' : 'No';
  const activityCmeFinderString =
    status === StatusEnum.DRAFT
      ? isBoolean(includeInCmeFinder)
        ? includeInCmeFinderString
        : draftToActiveError
      : includeInCmeFinderString;

  const hasAmaCredits: boolean = isNumber(credits?.[amaCreditTerm?.id]);
  const hasPharmacyCredits: boolean = isNumber(credits?.[pharmacyCreditTerm?.id]);

  const hasPharmacyTopicDesignator = !!pharmacyTopicsDictionary?.[pharmacyTopicId];
  const pharmacyTopicDesignator: string | ReactElement =
    isJa && hasPharmacyCredits && status === StatusEnum.DRAFT && !hasPharmacyTopicDesignator
      ? draftToActiveError
      : pharmacyTopicsDictionary[pharmacyTopicId]?.name;

  /**
   * Builds the activity description.
   * If NARS, the description can be optional so no error should be shown when it is empty.
   * @returns {ReactElement | string} - The activity description.
   */
  const buildActivityDescription = (): ReactElement | string => {
    if (!isNars) {
      return status === StatusEnum.DRAFT && !description ? draftToActiveError : description;
    } else {
      if (!description) return 'None';
      else return description;
    }
  };

  const activityDescriptionString = buildActivityDescription();

  const cmeFinderFieldsMessage = () => {
    if (status === StatusEnum.DRAFT && includeInCmeFinder) {
      return draftToActiveError;
    } else if (status === StatusEnum.DRAFT && mocOrRemsRegistration && !isNars) {
      return registrationError;
    } else {
      return 'None';
    }
  };

  const participationFeeString = participationFeeName || cmeFinderFieldsMessage();

  const audienceString = isRestrictedAudience ? 'Limited to certain participants' : 'Open to all';
  const registrationAudienceString = isBoolean(isRestrictedAudience) ? audienceString : cmeFinderFieldsMessage();

  const detailsUrlString = detailsUrl || cmeFinderFieldsMessage();

  const supportedMocBoards: IBCTBoard[] = keys(boardConfigurations)
    .filter((boardId: string) => !!boardMocDetails?.[boardId])
    .map((mocId: string): IBCTBoard => boardConfigurations[mocId]);
  const supportedRems: IBCTBoard[] = keys(boardConfigurations)
    .filter((boardId: string) => !!boardRemsDetails?.[boardId])
    .map((remsId: string): IBCTBoard => boardConfigurations[remsId]);
  const supportedTags = contentTagsDetail.filter((obj) => contentTags.includes(obj.id));
  const allContentTags = Object.values(contentTagsParentDetails)
    .flat()
    .map((tag) => tag);
  const boardIds: string[] = keys(boardMocDetails);
  const remsIds: string[] = keys(boardRemsDetails);

  const formattedMocCreditDeadline = mocCreditDeadline
    ? moment(mocCreditDeadline).format(dateFormat)
    : draftToActiveError;

  const isUpdateTotalLearnersDisabled: boolean = today.isBefore(startDate, 'day');
  const [isAddLearnersDisabled, addLearnersDisabledMessage] = canAddIndividualLearners(
    isUpdateTotalLearnersDisabled,
    status,
    hasAmaCredits,
    supportedMocBoards.length > 0,
    supportedRems.length > 0,
  );

  const creditTypeKeys: string[] = keys(credits);

  const internalIdString =
    isMoc && boardIds?.some((boardKey) => boardConfigurations?.[boardKey]?.providerActivityIdRequired) && !internalId
      ? registrationError
      : internalId || 'None';

  const monetarySupportSources =
    commercialSupportSources?.filter(
      ({ amountGiven, hasInKindSupport }) => isNumber(amountGiven) || !hasInKindSupport,
    ) || [];
  const inKindSupportSources = commercialSupportSources?.filter(({ hasInKindSupport }) => hasInKindSupport) || [];
  const hasMonetarySupportSources = !!monetarySupportSources?.length;
  const hasInKindSupportSources = !!inKindSupportSources?.length;
  const isCommercialSupportSourcesEmpty: boolean =
    !hasMonetarySupportSources && !hasInKindSupportSources && hasCommercialSupport;

  const commendationCriteriaList: string = commendationCriteriaIds
    .map((id: string): string => findTaxonomyNameById(allCommendationCriterion, id))
    .filter((id: string): boolean => !!id)
    .join(', ');

  const loadEditPage = (index: number) => {
    if (index >= 0) {
      const { id } = activityDetail;
      dispatch(setActivityEditIndexAction(index));
      history.push(`/activities/edit/${id}`);
    }
  };
  const isTargetAudiencePharmacy = targetAudience?.includes('IsTargetAudiencePharmacy');
  const isTargetAudiencePharmacyTech = targetAudience?.includes('IsTargetAudiencePharmacyTech');
  const combinedTargetAudienceString = `${isTargetAudiencePharmacy ? 'Pharmacists' : ''}${
    isTargetAudiencePharmacyTech && isTargetAudiencePharmacy ? ', ' : ''
  }${isTargetAudiencePharmacyTech ? 'Pharmacy Technicians' : ''}`;

  const targetAudienceString =
    isJa &&
    hasPharmacyCredits &&
    status === StatusEnum.DRAFT &&
    !isTargetAudiencePharmacy &&
    !isTargetAudiencePharmacyTech
      ? draftToActiveError
      : combinedTargetAudienceString;
  const pharmacySequenceNumberString =
    isJa && hasPharmacyCredits && status === StatusEnum.DRAFT && !pharmacySequenceNumber
      ? draftToActiveError
      : pharmacySequenceNumber;

  const pharmacyActivityTypeString =
    isJa && status === StatusEnum.DRAFT && !pharmacyActivityTypesDictionary[pharmacyTypeId]?.name
      ? draftToActiveError
      : pharmacyActivityTypesDictionary[pharmacyTypeId]?.name;
  const pharmacyContentTagStateTaxonomyTermsString =
    isJa && status === StatusEnum.DRAFT && pharmacyContentTagStateTaxonomyTerms.length === 0
      ? draftToActiveError
      : null;
  const pharmacyContentTagTaxonomyTermsString =
    isJa && status === StatusEnum.DRAFT && pharmacyContentTagTaxonomyTerms.length === 0 ? draftToActiveError : null;
  const pharmacyRecertificationTaxonomyTermsString = isJa && status === StatusEnum.DRAFT ? draftToActiveError : null;

  const renderIndividualActivityOrganizationNameComponent = (): ReactElement => {
    if (isIndividualActivity) {
      return (
        <>
          <div className={detailContentLabelClass}>Individual Activity Organization Name</div>
          {iaOrganizationName ? (
            <p className={detailContentValueClass}>{iaOrganizationName}</p>
          ) : (
            <p className={detailContentValueClass}>{draftToActiveError}</p>
          )}
        </>
      );
    }
  };

  const renderCreditTypes = (): ReactElement[] | ReactElement => {
    // At least one credit - PARS must have AMA credits
    if (status === StatusEnum.DRAFT) {
      if (!creditTypeKeys?.length || (rollupOrganizationEnum === RollupOrganizationEnums.PARS && !hasAmaCredits)) {
        return <li className={detailContentValueClass}>{draftToActiveError}</li>;
      }
    }

    return creditTypeKeys.length ? (
      Object.entries(creditTypesDictionary).map(
        ([key, term]): ReactElement =>
          creditTypeKeys.includes(key) && (
            <li className={detailContentValueClass} key={key}>
              {/** NARS TODO: should we show the grouping here? */}
              {term?.metadataText ? `${term?.metadataText} - ` : ''}
              {term?.name}
              {term?.metadataNumber2 !== 0 && ` - ${credits[key]} Credits`}
            </li>
          ),
      )
    ) : (
      <li className={detailContentValueClass}>None</li>
    );
  };

  /**
   * Renders the CME Passport component only if the rollup org is not NARS.
   * @returns {React.ReactElement} The CME Passport component.
   */
  const renderCmePassport = (): ReactElement => (
    <>
      {!isNars && (
        <>
          <div className={detailContentLabelClass}>Include on CME Passport</div>
          <p className={detailContentValueClass}>{activityCmeFinderString}</p>
        </>
      )}
    </>
  );

  const renderRegisteredProgramsList = (): ReactElement => (
    <ul>
      {isMoc && <li className={detailContentValueClass}>Maintenance of Certification (MOC)</li>}
      {isMips && <li className={detailContentValueClass}>Merit-based Incentive Payment System (MIPS)</li>}
      {isRems && <li className={detailContentValueClass}>Risk Evaluation and Mitigation Strategy (REMS)</li>}
      {!isMoc && !isMips && !isRems && <li className={detailContentValueClass}>None</li>}
    </ul>
  );

  /**
   * Renders the Certifying Boards component only if the rollup org is not NARS.
   * @returns {ReactElement} The Certifying Boards component.
   */
  const renderCertifyingBoards = (): ReactElement => (
    <>
      {!isNars && (
        <>
          <div className={detailContentLabelClass}>Certifying Boards</div>
          {boardIds.length ? renderSpecialtyBoardsList() : <p className={detailContentValueClass}>None</p>}
        </>
      )}
    </>
  );

  const renderSpecialtyBoardsList = (): ReactElement => (
    <ul>
      {supportedMocBoards?.length ? (
        supportedMocBoards
          ?.sort((a, b) => (a.name > b.name ? 1 : -1))
          .map(({ id, name }) => (
            <li className={detailContentValueClass} key={id}>
              {name}
            </li>
          ))
      ) : isMoc ? (
        draftToActiveError
      ) : (
        <li className={detailContentValueClass}>None</li>
      )}
    </ul>
  );

  interface TermResults {
    [key: string]: string[];
  }

  const sortTermsByTitles = (
    pharmacyRecertificationTaxonomyTerms: Array<string>,
    pharmacyRecertificationTerms: ITaxonomyTerm[],
  ): { results: TermResults; isEmpty: boolean } => {
    const results: TermResults = {};

    const findTerms = (terms: ITaxonomyTerm[], termName: string): void => {
      terms.forEach((term) => {
        const matchingTerms = term.terms.filter((nestedTerm) =>
          pharmacyRecertificationTaxonomyTerms.includes(nestedTerm.id),
        );

        if (matchingTerms.length > 0) {
          results[termName] = matchingTerms.map((matchingTerm) => matchingTerm.name);
        }

        if (term.terms.length > 0) {
          findTerms(term.terms, termName);
        }
      });
    };

    pharmacyRecertificationTerms.forEach((term) => {
      results[term.name] = [];
      findTerms([term], term.name);
    });

    const isEmpty = Object.values(results).every((terms) => terms.length === 0);

    return { results, isEmpty };
  };
  const renderRecertifications = () => {
    const selectedRecertification = pharmacyRecertificationTaxonomyTerms.map((id: string) => id);
    const pharmacyRecertificationTerms = baseTerms[TAXONOMY_ACTIVITY_PHARMACY_CERTIFICATION_ID].terms;

    const { results: sortedResults } = sortTermsByTitles(selectedRecertification, pharmacyRecertificationTerms);

    /**
     * Renders a list of target audience terms.
     *
     * @param {string} title - The title of the list.
     * @param {string[]} values - The list of taxonomy terms.
     * @returns {React.Component} - The target audience terms list.
     */
    const renderTargetAudienceTerms = (title: string, values: string[]) => {
      return (
        <div key={title}>
          <div>
            <div className={detailContentLabelClass}>{title}</div>
            <ul>
              {sortBy(values).map((name) => (
                <li key={name}>{name}</li>
              ))}
            </ul>
          </div>
          <div>&nbsp;</div>
        </div>
      );
    };

    // Determine if an audience has been selected and call the render function.
    // If there are no terms and that audience has been selected, a validation error should be shown.
    return (
      <div>
        {Object.entries(sortedResults).map(([title, values]) => (
          <div>
            {title === 'Pharmacists' && isTargetAudiencePharmacy && (
              <div>
                {values?.length > 0 && renderTargetAudienceTerms(title, values)}
                {values?.length === 0 && (
                  <div>
                    <div className={detailContentLabelClass}>{title}</div>
                    <p className={detailContentValueClass}>{pharmacyRecertificationTaxonomyTermsString}</p>
                  </div>
                )}
              </div>
            )}

            {title === 'Pharmacists Prescriptive Authority' && isTargetAudiencePharmacy && (
              <div>
                {values?.length > 0 && renderTargetAudienceTerms(title, values)}
                {values?.length === 0 && (
                  <div>
                    <div className={detailContentLabelClass}>{title}</div>
                    <p className={detailContentValueClass}>{pharmacyRecertificationTaxonomyTermsString}</p>
                  </div>
                )}
              </div>
            )}

            {title === 'Pharmacy Technicians' && isTargetAudiencePharmacyTech && (
              <div>
                {values?.length > 0 && renderTargetAudienceTerms(title, values)}
                {values?.length === 0 && (
                  <div>
                    <div className={detailContentLabelClass}>{title}</div>
                    <p className={detailContentValueClass}>{pharmacyRecertificationTaxonomyTermsString}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderSupportedRems = (): ReactElement => (
    <ul>
      {supportedRems?.map(
        ({ id, name }: IBCTBoard): ReactElement => (
          <li className={detailContentValueClass} key={id}>
            {name}
          </li>
        ),
      )}
    </ul>
  );

  const renderProvidership = (): ReactElement => {
    return isJointlyProvided ? (
      <ul>
        {jointProviders?.length ? (
          jointProviders?.map((provider) => (
            <li className={detailContentValueClass} key={uniqueId(provider)}>
              Jointly provided - {provider}
            </li>
          ))
        ) : (
          <li className={detailContentValueClass} key={id}>
            Jointly provided {renderToCloseError}
          </li>
        )}
      </ul>
    ) : (
      <p className={detailContentValueClass}>Directly provided</p>
    );
  };

  const renderOutcomesMeasured = (): ReactNode => {
    // go in order set by taxonomy...
    const termElements = Object.values(outcomesTerms).map((outcomesTerm: ITaxonomyTerm) => {
      // for each child outcomesTerms...
      const childTerms = outcomesTerm?.terms
        .map((term: ITaxonomyTerm) => {
          // see if child term corresponds to a chosen value
          // and return a list item
          return outcomesMeasured.find((value) => value === term.id) ? (
            <li className={detailContentValueClass} key={term.id}>
              {outcomesTerm.name} - {term.name}
            </li>
          ) : null; // or nothing
        })
        .filter(Boolean);

      // if there are any children, don't show the parent
      if (childTerms.length) return childTerms;

      // no children - try the parent
      return outcomesMeasured.find((value) => value === outcomesTerm.id) ? (
        <li className={detailContentValueClass} key={outcomesTerm.id}>
          {outcomesTerm.name}
        </li>
      ) : null; // or nothing
    });

    if (
      status === StatusEnum.READY_TO_CLOSE &&
      outcomesMeasured.length > 0 &&
      outcomesMeasured.every(
        (_) =>
          _ === learnerKnowledgeMeasuredOutcome?.id ||
          (learnerKnowledgeMeasuredOutcome?.terms || []).some((childTerm) => childTerm.id === _),
      )
    ) {
      return (
        <>
          {termElements}
          <li className={detailContentValueClass}>
            {getIncompleteFieldMessage({
              message: `${learnerKnowledgeMeasuredOutcome.name} cannot be the only Outcome selected to set Activity as `,
              nextStatus: StatusEnum.CLOSED,
            })}
          </li>
        </>
      );
    }

    return <>{termElements}</>;
  };

  const renderValidWithCommercialSupport = (commercialSupport: string): ReactElement => (
    <p className={detailContentValueClass}>
      {hasCommercialSupport ? commercialSupport : 'No'} {!!isCommercialSupportSourcesEmpty && renderToCloseError}
    </p>
  );

  const renderCommercialSupport = (): ReactElement => {
    const monetarySupport = `${hasMonetarySupportSources ? 'Monetary' : ''}`;
    const inKindSupport = `${hasInKindSupportSources ? 'In-Kind (non-monetary)' : ''}`;
    const commercialSupport = [monetarySupport, inKindSupport].filter(Boolean).join(', ');
    return renderValidWithCommercialSupport(
      !isCommercialSupportSourcesEmpty ? 'Yes - ' + commercialSupport : 'Yes - No sources',
    );
  };

  const validateSupportSourceForRender = ({
    source,
    amountGiven,
    isInKind,
  }: CommercialSupportSource & { isInKind: boolean }): ReactElement => {
    if (isInKind) {
      return (
        <li className={detailContentValueClass} key={uniqueId(source)}>
          {source || 'Unknown Source'} {!source && renderToCloseError}
        </li>
      );
    } else {
      if (isNumber(amountGiven)) {
        return (
          <li className={detailContentValueClass} key={uniqueId(source)}>
            {`${source || 'Unknown Source'} - $${amountGiven?.toFixed(2) || 0}`}{' '}
          </li>
        );
      } else {
        return (
          <li className={detailContentValueClass} key={uniqueId(source)}>
            {`${source || 'Unknown Source'} `} {renderToCloseError}
          </li>
        );
      }
    }
  };

  const renderSupportSources = (
    supportSources: CommercialSupportSource[],
    isInKind?: boolean,
  ): ReactElement[] | ReactElement =>
    supportSources?.map(
      (supportSource): ReactElement => validateSupportSourceForRender({ ...supportSource, isInKind }),
    );

  /**
   * Renders the Marked for Commendation component only if the rollup org is not NARS.
   * @returns {ReactElement} - The Marked for Commendation component.
   */
  const renderMarkedForCommendation = (): ReactElement => (
    <>
      {!isNars && (
        <>
          <div className={detailContentLabelClass}>Marked for Commendation</div>
          <p className={detailContentValueClass}>
            {commendationCriteriaIds?.length ? `Yes - ${commendationCriteriaList}` : 'No'}
          </p>
        </>
      )}
    </>
  );

  const renderSingleMocBoard = ({ activityBoard = {}, boardId = '' }: BoardDescription): ReactElement => {
    const { contentOutlines, mocPointsGiven, practiceIds, typesOfCreditIds } = activityBoard;

    if (!boardConfigurations?.[boardId]) {
      return null;
    }
    // Find the Specialty Board matching `boardId`
    const {
      contentOutlines: bctContentOutlines,
      usesContentOutlines,
      minimumContentOutlines,
      maximumContentOutlines,
      creditTypes,
      name,
      specialties,
    } = boardConfigurations?.[boardId];

    // Build a comma-separated list of matching content outlines
    const contentOutlineList: string[] = contentOutlines?.map(({ id, otherText }: IContentOutline) => {
      if (otherText) return findParents({ terms: bctContentOutlines }, id)?.concat(otherText)?.join(' > ');
      return findParents({ terms: bctContentOutlines }, id)?.join(' > ');
    });

    // Use the config to confirm if content outlines should be reported
    const isContentOutlineValid: boolean = usesContentOutlines
      ? contentOutlines?.length >= minimumContentOutlines && contentOutlines?.length <= maximumContentOutlines
      : true;

    const amountOfCredit: string | ReactElement =
      isNumber(mocPointsGiven) && mocPointsGiven > 0 ? `${mocPointsGiven} points` : registrationError;

    // Build a comma-separated list of matching practice areas
    const practiceAreasList: string = practiceIds
      ?.map((practiceId: string) => specialties?.find(({ id }: IBCTSpecialty) => practiceId === id)?.name || '')
      ?.filter((practiceArea: string) => !!practiceArea)
      ?.join(', ');

    const practiceAreaOutput: string | ReactElement = practiceIds?.length ? practiceAreasList : registrationError;

    // Build a list of credit types
    const creditTypesList: ReactElement | ReactElement[] = typesOfCreditIds?.length ? (
      typesOfCreditIds.map((creditId) => {
        const creditType: IBCTCreditType = creditTypes?.find(({ id }) => creditId === id);

        return creditType?.organizationName ? (
          <li className={detailContentValueClass} key={creditId}>
            {creditType.organizationName}
          </li>
        ) : null;
      })
    ) : (
      <li className={detailContentValueClass}>{registrationError}</li>
    );

    return (
      <>
        <h5 className={styles['card-title']}>{name}</h5>
        <div className={detailContentLabelClass}>Practice Areas</div>
        <p className={detailContentValueClass}>{practiceAreaOutput}</p>
        <div className={detailContentLabelClass}>Credit Type</div>
        <ul>{creditTypesList}</ul>
        <div className={detailContentLabelClass}>Amount of Credit</div>
        <p className={detailContentValueClass}>{amountOfCredit}</p>
        {usesContentOutlines && (
          <>
            <div className={detailContentLabelClass}>Content Areas</div>
            {isContentOutlineValid && !!contentOutlineList?.length ? (
              <ul>
                {contentOutlineList.map((contentOutline, index) => (
                  <li key={index} className={detailContentValueClass}>
                    {contentOutline}
                  </li>
                ))}
              </ul>
            ) : (
              <p className={detailContentValueClass}>{registrationError}</p>
            )}
          </>
        )}
        <div className={detailContentLabelClass}>Program Attestation</div>
        <p className={detailContentValueClass}>{mocProgramAttestation ? 'Yes' : registrationError}</p>
      </>
    );
  };

  const renderMocBoards = (): ReactElement[] =>
    boardIds.map(
      (boardId: string): ReactElement => (
        <div className={classNames(detailContentClass, styles.card)} key={boardId}>
          {renderSingleMocBoard({ activityBoard: boardMocDetails[boardId], boardId })}
        </div>
      ),
    );

  const renderSingleRem = ({ rem, remId }: RemsDescription): ReactElement => {
    const { additionalQuestionAnswers, isAttested, remsActivityId } = rem;
    const attestationMessage: string | ReactElement = isAttested ? 'Yes' : registrationError;
    const remsActivityIdMessage: string | ReactElement = remsActivityId || registrationError;
    const matchedRems: IBCTBoard = boardConfigurations?.[remId];
    const sortedRemsQuestions = orderBy(matchedRems?.boardActivityAdditionalFields, ['sortOrder'], ['asc']);

    const questionsOutput: ReactElement[] = sortedRemsQuestions?.map(
      ({ id, question }): ReactElement => {
        return (
          <Fragment key={id}>
            <div className={detailContentLabelClass}>{question || null}</div>
            <p className={detailContentValueClass}>{additionalQuestionAnswers[id] || renderToCloseError}</p>
          </Fragment>
        );
      },
    );

    return (
      <>
        <h5 className={styles['card-title']}>{boardConfigurations[remId]?.name}</h5>
        <div className={detailContentLabelClass}>
          {getRemsRegularExpression(matchedRems?.boardRemsIdRegularExpressions, moment(activityDetail?.createdDate))
            ?.label || 'Id'}
        </div>
        <p className={detailContentValueClass}>{remsActivityIdMessage}</p>
        {questionsOutput}
        <div className={detailContentLabelClass}>Attestation Complete</div>
        <p className={detailContentValueClass}>{attestationMessage}</p>
      </>
    );
  };

  const getParentTagName = (allContentTags: ContentTag[], supportedTags: ContentTag[]) => {
    const parentContentTagId = supportedTags[0].parentContentTagId;
    const parentContentTag = allContentTags[0].childContentTags.find(({ id }) => id === parentContentTagId);
    return parentContentTag ? parentContentTag.contentTagName : null;
  };

  const renderSingleContentTag = (): ReactElement => {
    let parentTagName = '';
    if (supportedTags.length > 0) {
      parentTagName = getParentTagName(allContentTags, supportedTags);
    }

    return (
      <>
        <h5 className={styles['card-title']}> AMA PRA Category 1 Credit™️</h5>
        <div className={detailContentLabelClass}>
          Content Area(s)
          {parentTagName && <h6>{parentTagName}</h6>}
          {supportedTags.length === 0 ? (
            <p />
          ) : (
            <ul>
              {sortBy(supportedTags, 'contentTagName').map(
                (tag: ContentTag): ReactElement => (
                  <li key={tag.id}>{tag.contentTagName}</li>
                ),
              )}
            </ul>
          )}
        </div>
      </>
    );
  };

  const renderSinglePharmacyTag = (): ReactElement => {
    const pharmacyCreditTagNames = pharmacyContentTagTaxonomyTerms
      .filter((id: string) => baseTerms?.[id])
      .map((id: string) => baseTerms[id]?.name);

    const pharmacyStateTagNames = pharmacyContentTagStateTaxonomyTerms
      .filter((id: string) => baseTerms?.[id])
      .map((id: string) => baseTerms[id]?.name);

    return (
      <>
        <h5 className={styles['card-title']}>Pharmacy Credit</h5>
        <div className={detailContentLabelClass}>
          Content Area(s)
          <ul>
            {pharmacyCreditTagNames.length === 0 ? (
              <p className={detailContentValueClass}>{pharmacyContentTagTaxonomyTermsString}</p>
            ) : (
              sortBy(pharmacyCreditTagNames).map(
                (name: string, ix: number): ReactElement => (
                  <div key={ix}>
                    <h6>{name}</h6>
                  </div>
                ),
              )
            )}
          </ul>
        </div>
        <div className={detailContentLabelClass}>
          <div>&nbsp;</div>
          State(s)
          <ul>
            {pharmacyStateTagNames.length === 0 ? (
              <p className={detailContentValueClass}>{pharmacyContentTagStateTaxonomyTermsString}</p>
            ) : (
              sortBy(pharmacyStateTagNames).map(
                (name, index): ReactElement => (
                  <span key={index} className={detailContentTagClass}>
                    <span className={detailContentTagClassFont}>{name.trim()}</span>
                    {index < pharmacyStateTagNames.length - 1 && ','}
                  </span>
                ),
              )
            )}
          </ul>
        </div>
      </>
    );
  };

  const renderRemsDetails = (): ReactElement[] =>
    remsIds.map(
      (remId: string): ReactElement => (
        <div className={classNames(detailContentClass, styles.card)} key={remId}>
          {renderSingleRem({ rem: boardRemsDetails[remId], remId })}
        </div>
      ),
    );

  const renderContentTags = (): ReactElement => (
    <div className={classNames(detailContentClass, styles.card)}>{renderSingleContentTag()}</div>
  );
  const renderPharmacyTags = (): ReactElement => (
    <div className={classNames(detailContentClass, styles.card)}>{renderSinglePharmacyTag()}</div>
  );

  const canEdit = status !== StatusEnum.CLOSED && !isBoardUser;

  const openUpdateTotalLearnersMOdal = () => {
    if (!isProviderSelected) {
      showUserRestrictionToast();
      return;
    }
    setIsUpdateLearnersModalOpen(true);
  };

  const showUserRestrictionToast = () => {
    dispatch(popToast({ ...errorStickyToastOptions, message: <>Please select a provider to continue</> }));
  };

  const showStateContentTags = hasAmaCredits && hasStateContentTags === HasStateContentTags.Yes;
  const showPharmacyContentTags = isJa && hasPharmacyCredits && hasPharmacyContentTags === HasPharmacyContentTags.Yes;

  return (
    <>
      {/* Begin Detail Page Heading */}
      <div className="form-container">
        <div className="page-navigation">
          <Link className="tertiary" to="/activities/summary">
            <KeyboardBackspaceRounded className="tertiary-icon-back" />
            Activity Summary
          </Link>
        </div>
        <div className={classNames('form-title-container', styles['activity-detail-title'])}>
          <div className="title">
            <div className="eyebrow">Activity Details</div>
            <h4>{title}</h4>
            <div className={classNames('caption-text', styles['activity-subheader'])}>
              <Button className="btn-actions" onClick={() => dispatch(openRail())}>
                Actions
              </Button>
              <ul className={styles['activity-id-list']}>
                <li className="caption-text">Activity ID {printableId}</li>
                {isJa && uanPharmacy && <li className="caption-text">{uanPharmacyString}</li>}
                {isJa && uanPharmacyTech && <li className="caption-text">{uanPharmacyTechString}</li>}
              </ul>
              <div
                className={classNames(
                  'status',
                  `status--${kebabCase(status?.toLowerCase())}`,
                  styles['status-indicator'],
                )}
              >
                {status}
              </div>
            </div>
          </div>
        </div>
        {/* End Detail Page Heading */}
        {/* Begin Basics Section */}
        <section>
          <div className={detailGroupClass}>
            <h5 className={detailGroupLabelClass}>Basics</h5>
            <div className={detailContentClass}>
              {isNars && (currentUserOrganization.isApproverOfActivities || isOrganizationAccreditor) && (
                <>
                  <div className={detailContentLabelClass}>Individual Activity</div>
                  <p className={detailContentValueClass}>{isIndividualActivity ? 'Yes' : 'No'}</p>
                  {renderIndividualActivityOrganizationNameComponent()}
                </>
              )}
              <div className={detailContentLabelClass}>Title</div>
              <p className={detailContentValueClass}>{title || 'None'}</p>
              <div className={detailContentLabelClass}>Activity Format</div>
              <p className={detailContentValueClass}>{activityType?.title || 'Unknown'}</p>
              <div className={detailContentLabelClass}>Date</div>
              <p className={detailContentValueClass}>{activityDatesString}</p>
              {!locationType?.length && DELIVERY_METHOD_REQUIRED_ACTIVITY_TYPES.includes(activityType?.abbreviation) ? (
                <>
                  <div className={detailContentLabelClass}>Location Type</div>
                  <p className={detailContentValueClass}>{draftToActiveError}</p>
                </>
              ) : null}
              {locationType?.includes(LocationTypeStringEnum.InPerson) &&
              (activityType?.abbreviation?.includes(ACTIVITY_TYPE_ABBREVIATION_ENUM.C) ||
                activityType?.abbreviation?.includes(ACTIVITY_TYPE_ABBREVIATION_ENUM.RSS)) ? (
                <>
                  <div className={detailContentLabelClass}>Location</div>
                  <p className={detailContentValueClass}>{activityLocationString}</p>
                </>
              ) : null}
            </div>
            {canEdit && <EditButton onClick={() => loadEditPage(0)} />}
          </div>
        </section>
        {/* End Basics Section */}
        {/* Begin Information for Learners Section */}
        <section>
          <div className={detailGroupClass}>
            <h5 className={detailGroupLabelClass}>Information for Learners</h5>
            <div className={detailContentClass}>
              {isJa && hasPharmacyCredits && (
                <>
                  <div className={detailContentLabelClass}>Target Audience</div>
                  <p className={detailContentValueClass}>{targetAudienceString || 'None'}</p>
                  <div className={detailContentLabelClass}>Pharmacy Sequence Number</div>
                  <p className={detailContentValueClass}>{pharmacySequenceNumberString || 'None'}</p>
                  <div className={detailContentLabelClass}>Is Pharmacy Certificate Program</div>
                  <p className={detailContentValueClass}>{isPharmacyCertificateProgram ? 'Yes' : 'No'}</p>
                  <div className={detailContentLabelClass}>Pharmacy Activity Type</div>
                  <p className={detailContentValueClass}>{pharmacyActivityTypeString || 'None'}</p>
                  <div className={detailContentLabelClass}>Pharmacy Topic Designator</div>
                  <p className={detailContentValueClass}>{pharmacyTopicDesignator}</p>
                  {isActivityLiveType ? (
                    <>
                      <div className={detailContentLabelClass}>Pharmacy Live Events Dates</div>
                      <ul>
                        {pharmacyLiveDates?.length > 0 ? (
                          orderBy(pharmacyLiveDates, (date) => moment(date).toDate().getTime()).map((date) => (
                            <li className={detailContentValueClass} key={uniqueId()}>
                              {moment(date).format(dateFormat)}
                            </li>
                          ))
                        ) : status === StatusEnum.DRAFT ? (
                          <li className={detailContentValueClass}>{draftToActiveError}</li>
                        ) : (
                          <li className={detailContentValueClass}>None</li>
                        )}
                      </ul>
                    </>
                  ) : null}
                </>
              )}
              <div className={detailContentLabelClass}>Credit Types</div>
              <ul>{renderCreditTypes()}</ul>
              {renderCmePassport()}
              <div className={detailContentLabelClass}>Activity Description</div>
              <p className={detailContentValueClass}>{activityDescriptionString}</p>
              <div className={detailContentLabelClass}>Registration</div>
              <p className={detailContentValueClass}>{registrationAudienceString}</p>
              <div className={detailContentLabelClass}>Required Fee</div>
              <p className={detailContentValueClass}>{participationFeeString}</p>
              <div className={detailContentLabelClass}>Activity URL</div>
              <p className={detailContentValueClass}>{detailsUrlString}</p>
              <div className={detailContentLabelClass}>Registered Programs</div>
              {renderRegisteredProgramsList()}
              {renderCertifyingBoards()}
              <div className={detailContentLabelClass}>REMS</div>
              {remsIds.length ? renderSupportedRems() : <p className={detailContentValueClass}>None</p>}
              {hasAmaCredits && (
                <>
                  <div className={detailContentLabelClass}>Include State CME Content Tagging</div>
                  {(() => {
                    if (hasStateContentTags === HasStateContentTags.Yes) {
                      return <p className={detailContentValueClass}>Yes, Manually Assigned</p>;
                    } else {
                      return <p className={detailContentValueClass}>No</p>;
                    }
                  })()}
                </>
              )}
              {isJa && hasPharmacyCredits && (
                <>
                  <div className={detailContentLabelClass}>Designed to meet State Pharmacy Regulatory Requirement</div>
                  {(() => {
                    if (hasPharmacyContentTags === HasPharmacyContentTags.Yes) {
                      return <p className={detailContentValueClass}>Yes</p>;
                    } else if (hasPharmacyContentTags === HasPharmacyContentTags.NotSure) {
                      return <p className={detailContentValueClass}>Not Sure</p>;
                    } else {
                      return <p className={detailContentValueClass}>No</p>;
                    }
                  })()}
                </>
              )}
              {isJa && hasPharmacyCredits && (
                <>
                  <div className={detailContentLabelClass}>
                    Designed to meet State Pharmacy Re-certification Requirement
                  </div>
                  {(() => {
                    if (hasPharmacyRecertifications === HasPharmacyRecertifications.Yes) {
                      return <p className={detailContentValueClass}>Yes</p>;
                    } else if (hasPharmacyRecertifications === HasPharmacyRecertifications.NotSure) {
                      return <p className={detailContentValueClass}>Not Sure</p>;
                    } else {
                      return <p className={detailContentValueClass}>No</p>;
                    }
                  })()}
                  {(() => {
                    if (hasPharmacyRecertifications === HasPharmacyRecertifications.Yes) {
                      return renderRecertifications();
                    }
                    return null;
                  })()}
                </>
              )}
            </div>
            {canEdit && <EditButton onClick={() => loadEditPage(1)} />}
          </div>
        </section>
        {/* End Information for Learners Section */}
        {/* Begin Accreditation Details Section */}
        <section>
          <div className={detailGroupClass}>
            <h5 className={detailGroupLabelClass}>Accreditation Details</h5>
            <div className={detailContentClass}>
              <div className={detailContentLabelClass}>Internal ID</div>
              <p className={detailContentValueClass}>{internalIdString}</p>
              <div className={detailContentLabelClass}>Providership</div>
              {isBoolean(isJointlyProvided) ? (
                renderProvidership()
              ) : isNars ? (
                <p className={detailContentValueClass}>Unknown</p>
              ) : (
                <p className={detailContentValueClass}>{draftToActiveError}</p>
              )}
              <div className={detailContentLabelClass}>Outcomes</div>
              <ul>
                {outcomesMeasured?.length ? (
                  renderOutcomesMeasured()
                ) : (
                  <li className={detailContentValueClass}>None {isNars || renderToCloseError}</li>
                )}
              </ul>
              <div className={detailContentLabelClass}>Commercial Support</div>
              {hasCommercialSupport === true ? (
                <>
                  {renderCommercialSupport()}
                  <div className={detailContentLabelClass}>Monetary Support Sources</div>
                  <ul>
                    {monetarySupportSources?.length ? (
                      renderSupportSources(monetarySupportSources, false)
                    ) : (
                      <li className={detailContentValueClass}>None</li>
                    )}
                  </ul>
                  <div className={detailContentLabelClass}>In-Kind Support Sources</div>
                  <ul>
                    {inKindSupportSources?.length ? (
                      renderSupportSources(inKindSupportSources, true)
                    ) : (
                      <li className={detailContentValueClass}>None</li>
                    )}
                  </ul>
                </>
              ) : hasCommercialSupport === false ? (
                <p className={detailContentValueClass}>No</p>
              ) : (
                <p className={detailContentValueClass}>{renderToCloseError}</p>
              )}
              {renderMarkedForCommendation()}
            </div>
            {canEdit && <EditButton onClick={() => loadEditPage(2)} />}
          </div>
        </section>
        {/* End Accreditation Details Section */}
        {/* Begin Content Tagging Section */}
        {(showStateContentTags || showPharmacyContentTags) && (
          <section>
            <div className={detailGroupClass}>
              <h5 className={styles['detail-group-rems']}>Content Tagging</h5>
              {showStateContentTags && <div className={detailContentClass}>{renderContentTags()}</div>}
              {showStateContentTags && showPharmacyContentTags && <div>&nbsp;</div>}
              {showPharmacyContentTags && <div className={detailContentClass}>{renderPharmacyTags()}</div>}
              {canEdit && <EditButton onClick={() => loadEditPage(3)} />}
            </div>
          </section>
        )}
        {/* End Content Tagging Section */}
        {/* Begin Maintenance of Certification (MOC) Section */}
        {isMoc && (
          <section>
            <div className={detailGroupClass}>
              <h5 className={detailGroupLabelClass}>
                Maintenance of Certification (MOC)
                <div className="caption-text">MOC Credit Deadline | {formattedMocCreditDeadline}</div>
              </h5>
              <div className={detailContentClass}>{renderMocBoards()}</div>
              {canEdit && <EditButton onClick={() => loadEditPage(4)} />}
            </div>
          </section>
        )}
        {/* End Maintenance of Certification (MOC) Section */}
        {/* Begin Additional FDA/REMS Information Section */}
        {isRems && (
          <section>
            <div className={detailGroupClass}>
              <h5 className={styles['detail-group-rems']}>Additional FDA/REMS Information</h5>
              <div className={detailContentClass}>{renderRemsDetails()}</div>
              {canEdit && <EditButton onClick={() => loadEditPage(isNars ? 4 : 5)} />}
            </div>
          </section>
        )}
        {/* End Additional FDA/REMS Information Section */}

        {/* Begin Total Learners Reported Section */}
        <section>
          <div className={detailGroupClass}>
            <div style={{ display: 'flex' }}>
              <h5 className={styles['detail-group-rems']}>Total Learners Reported &nbsp;</h5>
              {errorTotalLearnerReported()}
            </div>
            {canEdit && <EditButton onClick={() => openUpdateTotalLearnersMOdal()} />}
            <div className={detailContentClass}>
              {terms &&
                terms.map((item) => (
                  <div key={item.id} className={detailContentLabelClass}>
                    {item.name}: {learnerCounts[item.id]}
                  </div>
                ))}
            </div>
          </div>
        </section>
        {/* End Total Learners Reported Section */}
        <div className="button-row">
          <div className="cta-container">
            <Button className="primary" title="Return to summary page" onClick={returnToSummaryPage}>
              Done
            </Button>
          </div>
        </div>
      </div>
      {/* Side content */}
      <aside className={classNames('rail-container rail-container--75', { open: isOpen })}>
        <ActivityActions
          closeDate={closeDate}
          setIsCopyActivityModalOpen={setIsCopyActivityModalOpen}
          setIsDeleteActivityModalOpen={setIsDeleteActivityModalOpen}
          setIsUpdateLearnersModalOpen={setIsUpdateLearnersModalOpen}
          handleClose={() => dispatch(closeRail())}
          isAddLearnersDisabled={isAddLearnersDisabled}
          addLearnersDisabledMessage={addLearnersDisabledMessage}
          isUpdateLearnersDisabled={isUpdateTotalLearnersDisabled}
          refreshActivity={() => dispatch(getActivity(id))}
        />
      </aside>
      {/* Modals */}
      <Modal isOpen={isUpdateLearnersModalOpen} onClose={onCloseUpdateLearnersModal} title="Total Learners">
        <UpdateLearnersForm
          activityId={printableId}
          endDate={endDate}
          id={id}
          onClose={onCloseUpdateLearnersModal}
          startDate={startDate}
          title={title}
        />
      </Modal>
      <Modal isOpen={isDeleteActivityModalOpen} onClose={onCloseDeleteActivityModal} title="Delete Activity">
        <DeleteActivityForm
          activityId={printableId}
          callback={onDeleteActivityCallback}
          endDate={endDate}
          id={id}
          onClose={onCloseDeleteActivityModal}
          startDate={startDate}
          title={title}
        />
      </Modal>

      <Modal isOpen={isCopyActivityModalOpen} onClose={onCloseCopyActivityModal} title="Copy Activity">
        <CopyActivityForm
          activityId={printableId}
          callback={onCopyCallback}
          endDate={endDate}
          id={id}
          onClose={onCloseCopyActivityModal}
          startDate={startDate}
          title={title}
        />
      </Modal>
    </>
  );
};

export default ActivityDetailPage;
