import { ReactElement } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import uniqueId from 'lodash/uniqueId';

// Components
import DatePicker from 'components/DatePicker/DatePicker';

// Types
import { IFacetProps, SearchRequest } from '../../../types';

export const Date = (props: IFacetProps): ReactElement => {
  const { classes, facet, isDisabled, isExpanded, expandIcon, onToggleExpand } = props;
  const { values } = useFormikContext<SearchRequest>();
  const { displayValue, payloadEndValue, payloadStartValue } = facet;

  const minDate = values?.[payloadStartValue];
  const maxDate = values?.[payloadEndValue];

  return (
    <Accordion key={displayValue} className={classes.accordion} expanded={isExpanded} onChange={onToggleExpand}>
      <AccordionSummary className={classes.summary} expandIcon={expandIcon} id={uniqueId()}>
        {displayValue}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <div className="eyebrow">From: </div>
        <Field name={payloadStartValue}>
          {({ field }) => (
            <DatePicker
              disabled={isDisabled}
              field={field}
              formikKey={payloadStartValue}
              maxDate={maxDate}
              timeOfDay="midnight"
            />
          )}
        </Field>

        <div className="eyebrow">To: </div>
        <Field name={payloadEndValue}>
          {({ field }) => (
            <DatePicker
              disabled={isDisabled}
              field={field}
              formikKey={payloadEndValue}
              minDate={minDate}
              timeOfDay="endOfDay"
            />
          )}
        </Field>
      </AccordionDetails>
    </Accordion>
  );
};
