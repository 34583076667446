// Types
import { ICountry } from 'core/models';
import { IDropdownType } from 'components/Dropdown/Dropdown';

/**
 * @function formatCountries
 * @param countries
 * @description - Formats the countries array to work with a dropdown component.
 */
export const formatCountries = (countries: ICountry[]): IDropdownType[] =>
  countries?.map(
    ({ countryName, isoCountryCode }: ICountry): IDropdownType => ({
      Id: isoCountryCode,
      Title: countryName,
    }),
  );
