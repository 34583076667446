import { ChangeEvent, Fragment, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

// Components
import { FormikCheckboxField } from 'components/ContinuousImprovement/PureFormElements';
import { FormatDetails } from '../FormatDetails';

// Store + Core
import { activityTypesSelector } from 'store/activity/selectors';
import { getActivityTypes } from 'store/activity/actions';
import { ActivityType } from 'core/models';

const FORMIK_KEY = 'boardActivityTypes';

export const ActivityFormats = (): ReactElement => {
  const dispatch = useDispatch();
  const activityTypes = useSelector(activityTypesSelector);
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    dispatch(getActivityTypes());
  }, []);

  useEffect(() => {
    if (activityTypes.length) {
      const board: ActivityType[] = activityTypes.map(
        (type: ActivityType): ActivityType => {
          const foundIndex: number = values?.[FORMIK_KEY]?.findIndex(({ id }: ActivityType): boolean => id === type.id);

          // If we have the activityBoardType saved, use it as-is (it'll already have isChecked set)
          if (foundIndex > -1) {
            return values?.[FORMIK_KEY]?.[foundIndex];
          }

          // for safety, clone the activity type object so we don't risk editing the one from redux
          return { ...type };
        },
      );

      // Reset the formik model to include the other activity types
      setFieldValue(FORMIK_KEY, board);
    }
  }, [activityTypes.length]);

  const onChange = ({ e, id, idx }: { e: ChangeEvent<HTMLInputElement>; idx: number; id: string }): void => {
    setFieldValue(`${FORMIK_KEY}.[${idx}].isChecked`, e.target.checked);
    setFieldValue(`${FORMIK_KEY}.[${idx}].id`, id);
  };

  return (
    <>
      {activityTypes.map(
        (activity: ActivityType, idx: number): ReactElement => {
          const { id, title } = activity;
          const isChecked = !!values?.[FORMIK_KEY]?.[idx]?.isChecked;

          return (
            <Fragment key={id}>
              <FormikCheckboxField
                checked={isChecked}
                formikKey={`${FORMIK_KEY}.${idx}`}
                name={title}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => onChange({ e, id, idx })}
              />
              {isChecked && <FormatDetails formikKey={`${FORMIK_KEY}.${idx}`} />}
            </Fragment>
          );
        },
      )}
    </>
  );
};
