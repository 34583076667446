import { ChangeEvent, Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { FilterListRounded, Restore } from '@material-ui/icons';
import { Fab } from '@material-ui/core';

// Components
import { ActivitySearchFacets } from 'components/ContinuousImprovement/Facets';
import { Modal } from 'components/ContinuousImprovement';
import Button from 'components/Button/Button';
import { ActivityResultCard } from 'components/Summary/ActivityResultCard';
import { LoadingCards } from 'components/LoadingCard';
import BulkActions from 'components/Summary/BulkActions';
import { NoResultsCard } from 'components/NoResultsCard';
import { perPageMenuItems } from '../../learner/summary/perPageMenuItems';
import { sortActivityMenu } from '../../learner/summary/sortMenuItems';
import { downloadMenuItems } from './downloadMenuItems';
import { BulkCloseActivitiesForm } from 'components/forms/templates/BulkCloseActivitiesForm';
import StaticToast from 'components/StaticToast/StaticToast';
import { BulkUncloseActivitiesForm } from 'components/forms/templates/BulkUncloseActivitiesForm';
import { BulkDeleteActivitiesForm, SelectForReviewForm } from 'components/forms';
import { Banner } from 'components/Banner';
import { SearchPagination } from 'components/SearchPagination';

// Hooks.
import { useLoadEvent, useQueryString } from 'hooks';

// Store + Core
import { closeRail, openRail } from 'store/rail/actions';
import { isRailOpenSelector } from 'store/rail/selectors';
import {
  exportActivities,
  getActivities,
  onUpdateActivitiesPaginationState,
  resetActivitiesSearchStatePropsAction,
  undeleteActivities,
  updateSearchStatePropsAction,
} from 'store/activity/actions';
import {
  activitySearchCurrentPageSelector,
  activitiesSearchingSelector,
  searchRequestSelector,
  searchResponseSelector,
  searchTotalCountSelector,
  selectedActivitiesIdsSelector,
  selectedActivitiesSelector,
  isDeletedActivitiesShowingSelector,
} from 'store/activity/selectors';
import { ActivitySearchResultActivity } from 'core/models';
import { INITIAL_ACTIVITY_SEARCH_FACETS } from 'components/ContinuousImprovement/Facets/ActivitySearchFacets/constants';
import { isBoardUserSelector, rollupOrganizationEnumSelector } from 'store/user/selectors';
import { ToastActions } from './ToastActions';
import { infoToastOptions } from 'store/toast/constants';
import {
  SORT_BY_A_Z_TITLE,
  SORT_BY_ACTIVITY_ID,
  SORT_BY_END_DATE,
  SORT_BY_INTERNAL_ID,
  SORT_BY_RECENTLY_ADDED,
  SORT_BY_START_DATE,
  SORT_BY_UAN,
  SORT_BY_UAN_TECHNICIAN,
} from 'core/constants';
import { popToast } from 'store/toast/actions';
import { ButtonVariant } from 'core/enums';

// Utils
import { getPaginationCount } from 'globals/utils/getPaginationCount';

// Types
import { IInitialSearchFacet, SearchRequest } from 'components/ContinuousImprovement/Facets/types';

export const ActivitySummaryPage = (): ReactElement => {
  const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState<boolean>(false);
  const [isBulkCloseModalOpen, setIsBulkCloseModalOpen] = useState<boolean>(false);
  const [isBulkUncloseModalOpen, setIsBulkUncloseModalOpen] = useState<boolean>(false);
  const [isSelectForReviewModalOpen, setIsSelectForReviewModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const urlSearchPayload = useQueryString<SearchRequest>();
  const replacePayload = urlSearchPayload && Object.keys(urlSearchPayload).length > 0;

  // Selectors.
  const activities: ActivitySearchResultActivity[] = useSelector(searchResponseSelector)?.results?.map(
    ({ document }) => document,
  );
  const currentPage = useSelector(activitySearchCurrentPageSelector);
  const isBoardUser: boolean = useSelector(isBoardUserSelector);
  const isDeletedActivitiesShowing = useSelector(isDeletedActivitiesShowingSelector);
  const isFacetsOpen: boolean = useSelector(isRailOpenSelector);
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  const isLoading: boolean = useSelector(activitiesSearchingSelector);
  const searchActivityTotalCount: number = useSelector(searchTotalCountSelector);
  const searchRequest = useSelector(searchRequestSelector);
  const searchResponse = useSelector(searchResponseSelector);
  const selectedActivities = useSelector(selectedActivitiesSelector);
  const selectedActivityIds = useSelector(selectedActivitiesIdsSelector);

  // Sorting functions.
  const perPage = (top: number) => dispatch(getActivities({ top }));
  const sortByActivityId = () => dispatch(getActivities({ sortBy: SORT_BY_ACTIVITY_ID }));
  const sortByEndDate = () => dispatch(getActivities({ sortBy: SORT_BY_END_DATE }));
  const sortByInternalId = () => dispatch(getActivities({ sortBy: SORT_BY_INTERNAL_ID }));
  const sortByStartDate = () => dispatch(getActivities({ sortBy: SORT_BY_START_DATE }));
  const sortByTimestamp = () => dispatch(getActivities({ sortBy: SORT_BY_RECENTLY_ADDED }));
  const sortByTitle = () => dispatch(getActivities({ sortBy: SORT_BY_A_Z_TITLE }));
  const sortByUAN = () => dispatch(getActivities({ sortBy: SORT_BY_UAN }));
  const sortByUANTechnician = () => dispatch(getActivities({ sortBy: SORT_BY_UAN_TECHNICIAN }));
  const toggleSortAscending = () => dispatch(getActivities({ sortAscending: !searchRequest.sortAscending }));

  // Pagination functions.
  const goToPage = (_, newPage: number) => {
    window.scroll(0, 0);
    dispatch(onUpdateActivitiesPaginationState({ page: newPage }));
  };

  // Download functions (previously named `export`).
  const onDownloadAllActivities = () => dispatch(exportActivities({}));
  const onDownloadFilteredActivities = () => dispatch(exportActivities(searchRequest));

  // Always fetch when we visit this page.
  useEffect(() => {
    urlSearchPayload.isDeleted = false;
    dispatch(getActivities(urlSearchPayload, true, false, replacePayload));
  }, [dispatch, urlSearchPayload]);

  // On unmount reset the search state props.
  useEffect(() => {
    return () => dispatch(resetActivitiesSearchStatePropsAction());
  }, [dispatch]);

  // This is the action to take once the user has made a deletion.
  // We also give them the option in the toast to see all deleted activities.
  const onDeleteCallback = async (): Promise<void> => {
    await dispatch(getActivities());
    await dispatch(
      popToast({
        ...infoToastOptions,
        message: (
          <>
            Activity Deleted. See &nbsp;
            <Button
              variant={ButtonVariant.Label}
              onClick={async () => await dispatch(getActivities({ isDeleted: true }, true))}
            >
              Deleted Activities
            </Button>
          </>
        ),
      }),
    );
  };
  const onReviewCallback = () => dispatch(getActivities());

  const onToggleSelect = ({
    e,
    activity,
  }: {
    e: ChangeEvent<HTMLInputElement>;
    activity: ActivitySearchResultActivity;
  }): void => {
    const isChecked: boolean = e.target.checked;
    if (isChecked) {
      dispatch(
        updateSearchStatePropsAction({ page: currentPage, selectedActivities: [...selectedActivities, activity] }),
      );
    } else {
      dispatch(
        updateSearchStatePropsAction({
          page: currentPage,
          selectedActivities: selectedActivities.filter(
            (omitted: ActivitySearchResultActivity): boolean => omitted.key !== activity.key,
          ),
        }),
      );
    }
  };

  const isAllSelected: boolean = selectedActivities?.length !== searchResponse?.results?.length;

  const toggleSelectAll = (): void => {
    if (isAllSelected) {
      dispatch(updateSearchStatePropsAction({ page: currentPage, selectedActivities: activities }));
    } else {
      dispatch(updateSearchStatePropsAction({ page: currentPage, selectedActivities: [] }));
    }
  };

  // Show this when we are searching.
  const isPending = useMemo(() => isLoading && <LoadingCards />, [isLoading]);

  // Show this when we have results.
  const hasResults =
    !!activities?.length &&
    activities?.map(
      (activity: ActivitySearchResultActivity): ReactElement => {
        const isSelected = !!selectedActivities?.find(
          ({ id }: ActivitySearchResultActivity): boolean => id === activity.id,
        );

        return (
          <Fragment key={activity.id}>
            <ActivityResultCard
              activity={activity}
              onDelete={onDeleteCallback}
              onReview={onReviewCallback}
              onSelect={onToggleSelect}
              isSelected={isSelected}
            />
          </Fragment>
        );
      },
    );

  // Show this when we don't have any results.
  const noResults: ReactElement = !activities?.length && <NoResultsCard />;

  // Pagination count selected.
  const paginationCount: number = getPaginationCount({
    perPageCount: searchRequest.top,
    totalCount: searchActivityTotalCount,
  });

  // Click handlers.
  const onCloseBulkDeleteActivitiesModal = (): void => setIsBulkDeleteModalOpen(false);
  const onCloseBulkCloseActivitiesModal = (): void => setIsBulkCloseModalOpen(false);
  const onCloseBulkUncloseActivitiesModal = (): void => setIsBulkUncloseModalOpen(false);
  const onCloseRail = () => dispatch(closeRail());
  const onOpenRail = () => dispatch(openRail());
  const showNonDeletedActivities = (): void => {
    dispatch(getActivities({ isDeleted: false }, false));
  };
  const showActivitiesNotSelectedForReview = (): void => {
    dispatch(getActivities({ isSelectedForReview: false }, false));
  };
  const refetch = useCallback(() => dispatch(getActivities()), [dispatch]);

  const onCloseSelectForReviewModal = (): void => {
    setIsSelectForReviewModalOpen(false);
  };

  const fireLoadEvent = useLoadEvent({ PageName: 'Activity Summary', PageType: 'Activity' });

  useEffect(() => {
    if (!isPending) {
      fireLoadEvent({ SearchResultsNum: searchActivityTotalCount });
    }
  }, [fireLoadEvent, isPending, searchActivityTotalCount]);

  const searchFacet: IInitialSearchFacet = replacePayload
    ? { ...INITIAL_ACTIVITY_SEARCH_FACETS, ...urlSearchPayload }
    : { ...INITIAL_ACTIVITY_SEARCH_FACETS, ...searchRequest, ...urlSearchPayload };

  return (
    <>
      {/* Facets */}
      <aside className={classNames('rail-container rail-container--full', { open: isFacetsOpen })}>
        <ActivitySearchFacets handleClose={onCloseRail} initialValues={searchFacet} />
      </aside>

      {/* Main content */}
      <section className="summary-container">
        <div className="form-title-container">
          <h4 className="title">Provider Activity Summary</h4>
        </div>

        {/* Showing deleted banner. */}
        {searchRequest.isDeleted && (
          <Banner
            onClick={showNonDeletedActivities}
            text="Showing Deleted Activities"
            btnText="Show full activity summary"
          />
        )}

        {/* Showing is selected for review banner. */}
        {searchRequest.isSelectedForReview && (
          <Banner
            onClick={showActivitiesNotSelectedForReview}
            text="Showing Activities Selected For Review"
            btnText="Show full activity summary"
          />
        )}

        {/* Render the facets for mobile. */}
        <div className="summary-refiners mobile">
          <Button className="btn-addfilter" onClick={onOpenRail}>
            <FilterListRounded /> Add Filters
          </Button>
        </div>

        {/* Filters the main content. */}
        <BulkActions
          count={searchActivityTotalCount}
          countLabel="Activities"
          downloadMenuProps={downloadMenuItems({
            onDownloadAllActivities,
            onDownloadFilteredActivities,
          })}
          hasDownloadMenu
          hasSelectAll
          isSingleOrNoResult={searchActivityTotalCount < 2}
          isNoResults={searchActivityTotalCount < 1}
          perPageMenuProps={perPageMenuItems({ perPage, searchRequest })}
          selectedAll={!isAllSelected}
          selectAllAction={toggleSelectAll}
          sortAscending={searchRequest.sortAscending}
          sortAscendingAction={toggleSortAscending}
          sortMenuProps={sortActivityMenu({
            rollupOrganizationEnum,
            sortBy: searchRequest.sortBy,
            sortByActivityId,
            sortByEndDate,
            sortByInternalId,
            sortByStartDate,
            sortByTimestamp,
            sortByTitle,
            sortByUAN,
            sortByUANTechnician,
          })}
        />

        {/* Render the main content. */}
        {isPending || hasResults || noResults}

        {/* Pagination */}
        <SearchPagination
          currentPage={currentPage}
          goToPage={goToPage}
          paginationCount={paginationCount}
          totalCount={searchActivityTotalCount}
        />

        {/* Modals - no need to render for Board users */}
        {!isBoardUser && (
          <>
            <Modal
              isOpen={isBulkDeleteModalOpen}
              onClose={onCloseBulkDeleteActivitiesModal}
              title="Bulk Delete Activities"
            >
              <BulkDeleteActivitiesForm
                onClose={onCloseBulkDeleteActivitiesModal}
                callback={refetch}
                selectedActivities={selectedActivities}
              />
            </Modal>
            <Modal
              isOpen={isBulkCloseModalOpen}
              onClose={onCloseBulkCloseActivitiesModal}
              title="Bulk Close Activities"
            >
              <BulkCloseActivitiesForm
                onClose={onCloseBulkCloseActivitiesModal}
                selectedActivities={selectedActivities}
              />
            </Modal>
            <Modal
              isOpen={isSelectForReviewModalOpen}
              onClose={onCloseSelectForReviewModal}
              title="Select Activity For Review"
            >
              <SelectForReviewForm
                activityIds={selectedActivityIds}
                onClose={onCloseSelectForReviewModal}
                onSuccess={onReviewCallback}
              />
            </Modal>
            <Modal
              isOpen={isBulkUncloseModalOpen}
              onClose={onCloseBulkUncloseActivitiesModal}
              title="Bulk Reopen Activities"
            >
              <BulkUncloseActivitiesForm
                onClose={onCloseBulkUncloseActivitiesModal}
                callback={refetch}
                selectedActivities={selectedActivities}
              />
            </Modal>

            {/* Toasts */}
            {isDeletedActivitiesShowing ? (
              <StaticToast
                isOpen={!!selectedActivities?.length}
                toastOptions={[
                  {
                    ...infoToastOptions,
                    icon: (
                      <Fab className="restore" size="small">
                        <Restore />
                      </Fab>
                    ),
                    message: <>Restore Selected Activities</>,
                    onClick: () => dispatch(undeleteActivities(selectedActivityIds)),
                  },
                ]}
              />
            ) : (
              <ToastActions
                deleteAction={setIsBulkDeleteModalOpen}
                closeAction={setIsBulkCloseModalOpen}
                selectForReviewAction={setIsSelectForReviewModalOpen}
                uncloseAction={setIsBulkUncloseModalOpen}
              />
            )}
          </>
        )}
      </section>
    </>
  );
};
