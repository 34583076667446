import { CloudDownload, KeyboardBackspaceRounded } from '@material-ui/icons';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// Hooks.
import { useClickEvent } from 'hooks';

// Store.
import { IReport } from 'store/report/types';

// Styles.
import styles from './ReportBlock.module.scss';
import { IReportDateRange, ReportDateModal } from './ReportDateModal';

// Utils.
import { isUrlAbsolute } from 'utils';

interface IReportBlockProps {
  report: IReport;
}

export const ReportBlock = (props: IReportBlockProps): ReactElement => {
  const history = useHistory();
  const [isSelectingDates, setIsSelectingDates] = useState<boolean>(false);
  const { report } = props;
  const { description, group = 'Reports', link, name, showDateParameters, reportId, workspaceId } = report;
  const href = useMemo(() => link || `/Reports/view/${workspaceId}/${reportId}`, [link, reportId, workspaceId]);

  const fireEvent = useClickEvent({ Event: 'Reports', EventCategory: group });
  const fireModalEvent = useClickEvent({ Event: 'Activity Report Modal', EventCategory: 'Activity Report' });

  const handleView = useCallback(
    (e): void => {
      fireEvent({ EventAction: name, EventLabel: e.target.href });
      if (showDateParameters) {
        e.preventDefault();
        setIsSelectingDates(true);
      }
    },
    [fireEvent, name, showDateParameters],
  );

  const handleDateRange = useCallback(
    ({ startDate, endDate }: IReportDateRange): void => {
      const encodedStartDate = encodeURIComponent(new Date(startDate).toISOString());
      const encodedEndDate = encodeURIComponent(new Date(endDate).toISOString());
      const url = `${href}?startDate=${encodedStartDate}&endDate=${encodedEndDate}`;
      fireModalEvent({ EventAction: url });
      history.push(url);
    },
    [fireModalEvent, history, href],
  );

  return (
    <div className={styles.report}>
      <h3 className={styles.name}>{name}</h3>
      <p>{description}</p>
      <footer className={styles.footer}>
        {isUrlAbsolute(href) ? (
          <a download className={styles.cta} href={href}>
            <CloudDownload className={styles.download} /> Download
          </a>
        ) : (
          <Link className={styles.cta} onClick={handleView} to={href}>
            View <KeyboardBackspaceRounded className={styles.backspace} />
          </Link>
        )}
      </footer>
      {showDateParameters && isSelectingDates && (
        <ReportDateModal onClose={() => setIsSelectingDates(false)} onSubmit={handleDateRange} report={report} />
      )}
    </div>
  );
};
