import { IContextualMenuProps } from 'components/ContextualMenu/ContextualMenu';
import { Constants } from 'core';
import { ISortActivityMenu, ISortLearnerMenu } from './types';
import { RollupOrganizationEnums } from 'core/models';

/**
 * @function sortLearnerMenu
 * @param args
 * @description Dropdown items specifically for Learners Summary Sorting.
 */
export const sortLearnerMenu = (args: ISortLearnerMenu): IContextualMenuProps => {
  const {
    isBoard,
    rollupOrganizationEnum,
    sortBy,
    sortByAcceptedCount,
    sortByActivityId,
    sortByClaimDate,
    sortByLearnerCount,
    sortByRejectedCount,
    sortByStartDate,
    sortBySubmittedCount,
    sortByTitle,
    sortByUAN,
    sortByUANTechnician,
  } = args;
  let sortOptions = [
    {
      name: 'A-Z Title',
      onSelectAction: sortByTitle,
      selected: sortBy === Constants.SORT_BY_A_Z_TITLE,
    },
    {
      name: 'Start Date',
      onSelectAction: sortByStartDate,
      selected: sortBy === Constants.SORT_BY_START_DATE,
    },
    {
      name: 'Claim Date',
      onSelectAction: sortByClaimDate,
      selected: sortBy === Constants.SORT_BY_CLAIM_DATE,
    },
    {
      name: 'Activity ID',
      onSelectAction: sortByActivityId,
      selected: sortBy === Constants.SORT_BY_ACTIVITY_ID,
    },
    {
      name: 'Learner Count',
      onSelectAction: sortByLearnerCount,
      selected: sortBy === Constants.SORT_BY_LEARNER,
    },
  ];
  if (!isBoard) {
    sortOptions = sortOptions.concat([
      {
        name: 'Learners Submitted',
        onSelectAction: sortBySubmittedCount,
        selected: sortBy === Constants.SORT_BY_SUBMITTED,
      },
      {
        name: 'Learners Accepted',
        onSelectAction: sortByAcceptedCount,
        selected: sortBy === Constants.SORT_BY_ACCEPTED,
      },
      {
        name: 'Learners Rejected',
        onSelectAction: sortByRejectedCount,
        selected: sortBy === Constants.SORT_BY_REJECTED,
      },
    ]);
  }
  if (rollupOrganizationEnum === RollupOrganizationEnums.JA) {
    sortOptions = sortOptions.concat([
      {
        name: 'UAN',
        onSelectAction: sortByUAN,
        selected: sortBy === Constants.SORT_BY_UAN,
      },
      {
        name: 'UAN Technician',
        onSelectAction: sortByUANTechnician,
        selected: sortBy === Constants.SORT_BY_UAN_TECHNICIAN,
      },
    ]);
  }

  return {
    label: 'Sort by: ',
    options: sortOptions,
  };
};

/**
 * @function sortActivityMenu
 * @param args
 * @description Dropdown items specifically for Activity Summary Sorting.
 */
export const sortActivityMenu = (args: ISortActivityMenu): IContextualMenuProps => {
  const {
    rollupOrganizationEnum,
    sortBy,
    sortByActivityId,
    sortByEndDate,
    sortByInternalId,
    sortByStartDate,
    sortByTimestamp,
    sortByTitle,
    sortByUAN,
    sortByUANTechnician,
  } = args;
  let sortOptions = [
    {
      name: 'Recently Added',
      onSelectAction: sortByTimestamp,
      selected: sortBy === Constants.SORT_BY_RECENTLY_ADDED,
    },
    {
      name: 'A-Z Title',
      onSelectAction: sortByTitle,
      selected: sortBy === Constants.SORT_BY_A_Z_TITLE,
    },
    {
      name: 'Start Date',
      onSelectAction: sortByStartDate,
      selected: sortBy === Constants.SORT_BY_START_DATE,
    },
    {
      name: 'End Date',
      onSelectAction: sortByEndDate,
      selected: sortBy === Constants.SORT_BY_END_DATE,
    },
    {
      name: 'Activity ID',
      onSelectAction: sortByActivityId,
      selected: sortBy === Constants.SORT_BY_ACTIVITY_ID,
    },
    {
      name: 'Internal ID',
      onSelectAction: sortByInternalId,
      selected: sortBy === Constants.SORT_BY_INTERNAL_ID,
    },
  ];

  if (rollupOrganizationEnum === RollupOrganizationEnums.JA) {
    sortOptions = sortOptions.concat([
      {
        name: 'UAN',
        onSelectAction: sortByUAN,
        selected: sortBy === Constants.SORT_BY_UAN,
      },
      {
        name: 'UAN Technician',
        onSelectAction: sortByUANTechnician,
        selected: sortBy === Constants.SORT_BY_UAN_TECHNICIAN,
      },
    ]);
  }

  return {
    label: 'Sort by: ',
    options: sortOptions,
  };
};
