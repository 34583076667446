import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContentTag } from 'core/models';
import { getAllContentTags } from 'store/contentTags/actions';
import { contentTagsSelector } from 'store/contentTags/selectors';

export const useContentTags = () => {
  const dispatch = useDispatch();
  const allContentTags: ContentTag[] = useSelector(contentTagsSelector);
  useEffect(() => {
    if (!allContentTags?.length) {
      dispatch(getAllContentTags());
    }
  }, [allContentTags, dispatch]);

  // allContentTags is flat, index by id
  const contentTagsById = useMemo(() => {
    return allContentTags.reduce((acc, tag) => {
      acc[tag.id] = tag;
      return acc;
    }, {} as { [k: string]: ContentTag });
  }, [allContentTags]);

  // allContentTags is flat, group by parent id
  const contentTagsByParentId = useMemo(() => {
    return allContentTags.reduce((acc, tag) => {
      if (!tag.parentContentTagId) {
        return acc;
      }
      if (!acc[tag.parentContentTagId]) {
        acc[tag.parentContentTagId] = [];
      }
      acc[tag.parentContentTagId].push(tag);
      return acc;
    }, {} as { [k: string]: ContentTag[] });
  }, [allContentTags]);

  return { allContentTags, contentTagsById, contentTagsByParentId };
};
