export const VALIDATION_MESSAGES = {
  ACTIVITY_LENGTH_REQUIREMENT:
    'Invalid Activity Length: 3 year maximum for Enduring Materials and Journal-based CE. 1 year maximum for all other formats.',
  CERTIFYING_BOARD_MIN: 'Please select a certifying board.',
  CREDIT_MAX:
    'Credit value cannot exceed 999. Please see credit calculator for more information on designating credits.',
  CREDIT_MIN: 'Credit value cannot be 0.',
  CREDIT_MIN_WITH_LEARNERS: 'Credit value cannot be less than the amount already submitted for individual learners.',
  DESCRIPTION_MAX_CHARS:
    'Activity description exceeds the 2,500 character limit. Please edit the description to meet this limit.',
  END_DATE_AFTER_START_DATE: 'Activity end date cannot fall before the activity start date.',
  END_DATE_THREE_YEAR_MAX: 'Activity end date cannot be more than 3 years after the activity start date. ',
  INTERNAL_ID_DUPLICATE_FOUND:
    'Duplicate activity found. An activity already exists with Start Date: [mm/dd/yyyy], End Date: [mm/dd/yyyy], Activity Format: [activity format], and Internal ID: [internal ID]. Please review the activity information or enter a unique Internal ID.', // TODO: THIS IS A NEW RULE
  MOC_CLAIM_DATE_AFTER_END_DATE: 'MOC credit claim date cannot be before the activity end date.',
  MOC_CLAIM_DATE_WITH_LEARNERS:
    'MOC credit claim date cannot fall before the completion date of any individual learners reported for this activity. This date must be later than or equal to the latest completion date reported.',
  MOC_CREDIT_BELOW_CME: 'MOC credit value must be less than CME value listed.',
  MOC_CREDIT_EQUAL_CME: 'MOC credit value must equal the CME value listed.',
  MOC_CREDIT_MIN_WITH_LEARNERS:
    'MOC credit value cannot be less than the amount already submitted for individual learners. ',
  MOC_CREDIT_WITH_OTHER: 'Selected MOC credit type cannot be submitted alone.',
  MOC_REQUIRES_AMA:
    'Activity must offer AMA PRA Category 1 credit™ and have a filled out credit amount value before MOC can be selected.', // TODO: THIS IS A NEW RULE
  REMS_PROGRAM_REQUIRED: 'Please select a REMS program.',
  REMS_QUESTIONS_REQUIRED: 'Please fill out all additional questions',
  REQUIRED_TO_CONTINUE: 'Required to save activity and continue.',
  RPC_ID_FORMAT: 'RPC ID is in an invalid format. RPC ID must be formatted as EG-######-### (ex: EG-000123-001).',
  SEQUENCE_NUMBER_INVALID: 'Please eneter a value between 0-999',
  SEQUENCE_UNAVAILABLE: 'The entered sequence number is unavailable. The next available sequence number is [###].', // TODO: THIS IS A NEW RULE
  START_DATE_BEFORE_COMPLETION_DATE:
    'Activity start date cannot fall after the completion date of any individual learners reported for this activity. This date must be earlier than or equal to the first completion date reported.',
  START_DATE_BEFORE_END_DATE: 'Activity start date cannot fall past the activity end date.',
  TITLE_MAX_CHARS: 'Activity title exceeds the 256 character limit. Please edit the title to meet this limit.', // TODO: NOT IN THE DOCUMENT, BUT WAS AGREED TO BE A RULE
  TYPE_CHANGE_RESTRICTED: 'Cannot change activity type. Please contact support for more details',
  URL_FORMAT: 'URL must be a valid format.',
  IA_ORGANIZATION_NAME_MAX_CHARS:
    'Individual Activity Organization Name exceeds the 256 character limit. Please edit the name to meet this limit.',
};
