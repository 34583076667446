import * as yup from 'yup';

export const validationSchema = yup.object({
  status: yup.string().required('Status is required.'),
  errorCode: yup.number().when('status', {
    is: 'Rejected',
    then: (s) => s.required('Error Code is required when rejecting.'),
  }),
  rejectedReason: yup.string().when('status', {
    is: 'Rejected',
    then: (s) => s.required('Rejected Reason is required when rejecting.'),
  }),
});
